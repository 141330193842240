import React, { useContext, useEffect, useState } from "react";
import {
  HEADER_TEXT_ALIGN,
  MARKS_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../../../styles/DataGridTableStyles";
import useStudentTestMarks, {
  AcdStdMarksQueryType,
} from "../../../hooks/useStudentTestMarks";
import {
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../../../utils/constants";
import {
  Direction,
  InstitutionType,
  PageFor,
  SortBy,
  TableHeaders,
} from "../../../../../utils/Enum.types";
import {
  GridCellParams,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";

import useTestDetails from "../../../hooks/useTestDetails";
import {
  PrintModalStyles,
  StudentModalStyles,
} from "../../../../../styles/ModalStyles";
import { responseType } from "../../../../../utils/Form.types";
import { useNavigate, useParams } from "react-router-dom";
import useTestStatus, {
  AcdTestMarksStatusQueryType,
} from "../../../hooks/useTestStatus";
import Modal from "react-modal";
import Close from "../../../../../images/Close.svg";
import { payloadTypes } from "../../../../../context/reducer";
import { AppContext } from "../../../../../context/context";
import ParticularStudentResults from "../../Test/Modal/ParticularStudentResults";
import { Button } from "../../../../../stories/Button/Button";
import ProgressReport from "../../../../Print/ProgressReport/templates/testwise/Print";
import { getModifiedScrollHeight } from "../../../../../utils/UtilFunctions";
import { GetAcdComboSubjectOrderWithMarksDetails } from "../../../../../queries/customallocation/combination/queries";
import {
  GetAcdComboSubjectOrderWithMarksDetailsData,
  GetAcdComboSubjectOrderWithMarksDetailsVars,
} from "../../../../../Types/Combinations/queries";
import { useLazyQuery } from "@apollo/client";
import useToken from "../../../../../customhooks/useToken";
import { GetStdTestMarksBySubjId } from "../../../queries/test/query";
import {
  GetStdTestMarksBySubjIdData,
  GetStdTestMarksBySubjIdEdges,
  GetStdTestMarksBySubjIdVars,
} from "../../../DailyActivities/Exam/UpdateMarks";
import useAcdLevel from "../../../../Accounts/hooks/useAcdLevel";
import useCheckAllocationType from "../../../hooks/useCheckAllocationType";
import useSwConfigInstType from "../../../hooks/useSwConfigInstType";

export type GridAlignment = "left" | "center" | "right";
interface Props {
  testConductId: responseType | null;
  pageType: PageFor;
  selectedCombination: responseType | null;
  forCombination?: boolean;
}

const ViewParticularTestBySubjectReportTable = ({
  testConductId,
  pageType,
  selectedCombination,
  forCombination,
}: Props) => {
  const { dispatch, state } = useContext(AppContext);
  const { token } = useToken();
  const [selectedStudentDetails, setSelectedStudentDetails] =
    useState<any>(null);
  const { institutionType } = useSwConfigInstType();

  const { testId, InstId, entryId, subjectId } = useParams();
  const navigate = useNavigate();
  const [printPreview, setPrintPreview] = useState(false);
  const [testConductDetailsId, setTestConductDetailsId] =
    useState<responseType | null>(null);

  const [selectedStudents, setSelectedStudents] = useState<number[]>([]);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [students, setStudents] = useState<GetStdTestMarksBySubjIdEdges[]>([]);
  const [rows, setRows] = useState<GridValidRowModel[]>([]);

  const [selectAll, setSelectAll] = useState(false);

  const [studentIdSet, setStudentIdSet] = useState<Set<number>>(new Set());
  const { acdLevel } = useAcdLevel(entryId!);
  const { flag } = useCheckAllocationType();
  const [
    GetStdTestMarks,
    { data: studentsData, loading: studentsLoading, fetchMore },
  ] = useLazyQuery<GetStdTestMarksBySubjIdData, GetStdTestMarksBySubjIdVars>(
    GetStdTestMarksBySubjId,
    {
      variables: {
        token,
        acd_test_class_id: testId!,
        acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
        after: null,
        entry_id: entryId!,
        entry_level: acdLevel,
        first: ROWS_PER_PAGE,
        inst_id: InstId!,
        subj_master_id: subjectId!,
        name: EMPTY_STRING,
        per_std_subj_allocation: flag!,
        orderBy: {
          direction: Direction.ASC,
          field: SortBy.STUDENT_ID,
        },
      },
    }
  );

  const { statusOfSubject } = useTestStatus(
    AcdTestMarksStatusQueryType.ALL_TESTS,
    Number(entryId)
  );
  const {
    testMarks: { loading },
  } = useStudentTestMarks(
    forCombination
      ? AcdStdMarksQueryType.TEST_MARKS_BY_COMBO_ID_AND_TEST_CLASS_ID
      : AcdStdMarksQueryType.TEST_MARKS_AT_ENTRY_LEVEL,
    null,
    EMPTY_STRING
  );

  const { testConductDetails } = useTestDetails();

  const [GetAcdSubjects, { data: comboSubjectsList }] = useLazyQuery<
    GetAcdComboSubjectOrderWithMarksDetailsData,
    GetAcdComboSubjectOrderWithMarksDetailsVars
  >(GetAcdComboSubjectOrderWithMarksDetails, {
    variables: {
      token,
      inst_id: InstId!,
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      acd_test_class_id: testId!,
      acd_subj_combo_id: selectedCombination ? selectedCombination.value : 0,
    },
  });

  //Headers

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: TableHeaders.SLNO,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-sl-no",
      align: SLNO_TEXT_ALIGN,
    },
    {
      field: "std_adm_no",
      headerName: TableHeaders.ADMISSION_NUMBER,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-adm-no",
    },
    ...(institutionType !== InstitutionType.SCHOOL
      ? [
          {
            headerName: TableHeaders.REGISTER_NUMBER,
            field: "std_reg_no",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
            cellClassName: "td-adm-no",
          },
        ]
      : []),
    {
      field: "std_name",
      headerName: TableHeaders.STUDENT_NAME,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-onClick td-name",
      flex: 1,
      hideable: false,
    },
    {
      field: `std_marks`,
      headerName: `${
        studentsData && studentsData.GetStdTestMarksBySubjId.edges.length
          ? studentsData.GetStdTestMarksBySubjId.edges[0].node.subject_details
              .subj_desc
          : ""
      }`,
      headerAlign: HEADER_TEXT_ALIGN as GridAlignment,
      align: MARKS_TEXT_ALIGN as GridAlignment,
      cellClassName: "td-marks",
      renderCell: (params: any) => {
        return (
          <div
            className={params.row[`std_marks`] === "-" ? `bg-grey` : ""}
            style={{ width: "100%" }}>
            {params.row[`std_marks`]}
          </div>
        );
      },
    },
  ];

  // To view Particular Student Details
  const handleCellClick = (params: GridCellParams) => {
    if (params.field === "std_name") {
      const selectedRow = rows.find((row) => row.id === params.id);

      if (selectedRow) {
        const stdId = selectedRow.std_id;

        setSelectedStudentDetails(selectedRow);
        dispatch({
          type: payloadTypes.SET_STUDENT_ID,
          payload: {
            studentId: stdId!,
          },
        });
      }
    }
  };

  const handleSelectAll = () => {
    if (!selectAll) {
      const allStudentIds = rows
        .map((student) => student.std_id)
        .filter((id) => Number.isInteger(id)) as number[];
      setSelectedStudents(allStudentIds);
      setSelectAll(true);
    } else {
      setSelectedStudents([]);
      setSelectAll(false);
    }
  };
  useEffect(() => {
    if (testId && testConductDetailsId === null) {
      setTestConductDetailsId(
        statusOfSubject.classTests.find(
          ({ value }) => value === Number(testId)
        ) ?? null
      );
    }
  }, [testId, statusOfSubject.classTests]);
  useEffect(() => {
    if (token && flag !== undefined) {
      GetStdTestMarks();
    }
  }, [
    GetStdTestMarks,
    token,
    testId,
    state.ActiveAcdYr,
    entryId,
    acdLevel,
    InstId,
    subjectId,
    flag,
  ]);
  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;
      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: FETCH_MORE_DATA,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetStdTestMarksBySubjId.edges;
                const pageInfo =
                  fetchMoreResult.GetStdTestMarksBySubjId.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck =
                  prevResult.GetStdTestMarksBySubjId.edges.filter(
                    ({ node: { id } }) =>
                      newEdges.findIndex(
                        ({ node: { id: newId } }) => newId === id
                      ) !== -1
                  );

                if (duplicateCheck?.length > 0) return prevResult;

                return {
                  GetStdTestMarksBySubjId: {
                    edges: [...students, ...newEdges],
                    pageInfo,
                    totalCount: studentsData
                      ? studentsData.GetStdTestMarksBySubjId.totalCount!
                      : 0,
                  },
                };
              },
            });
          }
        }
      };
      if (scrollTable && rows && rows?.length > 0)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    [rows]
  );

  useEffect(() => {
    if (studentsData && !studentsLoading) {
      const newData = studentsData.GetStdTestMarksBySubjId.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newRow) => {
          const filteredStudent = rows.find(
            (row) => row.stdId && row.stdId === newRow.node.id
          );
          if (filteredStudent) {
            return {
              ...newRow,
              node: {
                ...newRow.node,
              },
            };
          }
          return newRow;
        });
        setStudents(updatedNewData);
        setRows(
          updatedNewData?.map(({ node }, index) => ({
            id: index + 1,
            std_adm_no: node.student_details.std_adm_no,
            std_reg_no: node.student_details.std_reg_no,
            std_name: node.student_details.first_name,
            std_id: node.student_details.id,
            std_marks: node.marks_scored_tot,
          }))
        );
      } else {
        setStudents(newData);
        setRows(
          newData?.map(({ node }, index) => ({
            id: index + 1,
            std_adm_no: node.student_details.std_adm_no,
            std_reg_no: node.student_details.std_reg_no,
            std_name: node.student_details.first_name,
            std_id: node.student_details.id,
            std_marks: node.marks_scored_tot,
          }))
        );
      }
      setEndCursor(studentsData.GetStdTestMarksBySubjId?.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [
    studentsData,
    loading,
    testConductDetails.data,
    forCombination,
    comboSubjectsList,
  ]);
  useEffect(() => {
    if (token) {
      GetAcdSubjects();
    }
  }, [InstId, selectedCombination, state.ActiveAcdYr, token, GetAcdSubjects]);
  return (
    <>
      <div className={`acd-reports__per-test--tableblock`}>
        <StyledDatagrid
          columns={columns}
          rows={rows}
          rowHeight={TABLE_ROW_HEIGHT}
          onCellClick={(params: GridCellParams, studentId) => {
            handleCellClick(params);
          }}
          onColumnHeaderClick={() => handleSelectAll()}
          disableRowSelectionOnClick
        />
      </div>

      {pageType === PageFor.GENERAL ? (
        <Button mode="back" onClick={() => navigate(-1)} />
      ) : // <Button mode="cancel" onClick={() => setModal?.(false)} />
      null}

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={selectedStudentDetails}
        ariaHideApp={false}
        style={StudentModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ParticularStudentResults
              setModalFlag={setSelectedStudentDetails}
              testConductId={testConductId}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setSelectedStudentDetails(!selectedStudentDetails)}
            />
          </div>
        </div>
      </Modal>

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={printPreview}
        style={PrintModalStyles}
        ariaHideApp={false}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ProgressReport
              testConductId={testConductId}
              setModalFlag={setPrintPreview}
              selectedStudents={selectedStudents}
              setStudentIdSet={setStudentIdSet}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt=""
              onClick={() => {
                setPrintPreview(!printPreview);
                setStudentIdSet(new Set());
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ViewParticularTestBySubjectReportTable;
