import { useLazyQuery, useMutation } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import {
  GetInstMasterDataDetails,
  GetInstMasterDataVars,
} from "../../../../customhooks/useInstMasterDataByInstId";
import useToken from "../../../../customhooks/useToken";
import { Button } from "../../../../stories/Button/Button";
import Home from "../../../Accounts/Home/Index";
import { GetInstMasterData } from "../../../../queries/institution/masterdata";
import TotalStudents from "../../../../images/TotalMale.svg";
import ReadyToImport from "../../../../images/Present.svg";
import ContainsError from "../../../../images/Absent.svg";
import Info from "../../../../images/Information.svg";
import DownArrow from "../../../../images/DownArrow.svg";
import FileAttach from "../../../../images/BrowseFiles.svg";
import Close from "../../../../images/Close.svg";
import ExcelJS from "exceljs";
import { Cell } from "exceljs";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ValidateOldStdReceipts } from "../../../../queries/xls";
import { Title } from "../../../../stories/Title/Title";
import { ImportStdFeeDemand } from "../../../../queries/xls/mutation";
import {
  toIsoDate,
  convertToDateObject_MMDDYYYYFormat,
  getHeaderRowStyle,
  toStandardDate,
  handleClear,
  removeMoreSpace,
} from "../../../../utils/UtilFunctions";
import Eduate from "../../../../images/Eduate_Logo_image.png";
import {
  A2_CELL,
  A3_CELL,
  A4_CELL,
  ACC_HEADER_FONT,
  ADDRESS_ALIGNMENT,
  ADDRESS_FONT,
  BLOB_TYPE,
  BORDER_DATA,
  C4_CELL,
  DOWNLOAD,
  E4_CELL,
  EDUATE_IMG_FORMAT,
  ELEMENT,
  HEADER_ALIGNMENT_LEFT,
  FILENAME,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  FIN_YEAR_FONT,
  FIRST_CELL,
  FIRST_INDEX,
  FROZEN_CELLS,
  HEADER_ALIGNMENT,
  HEADER_CSS,
  TABLE_HEADER_CSS,
} from "../../../Library/Constants";
import {
  ExcelAlignment,
  ExcelPageHeader,
  FileUploadParams,
  Operation,
  TableHeaders,
} from "../../../../utils/Enum.types";
import Modal from "react-modal";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import { HEADER_ALIGNMENT_CENTER } from "../../../Library/Constants";
import useMstInstTree from "../../../../customhooks/useMstInstTree";
import useInstLogoDetails from "../../../../customhooks/useInstLogoDetails";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import { AppContext } from "../../../../context/context";

import { DEFAULT_TIME, EMPTY_STRING } from "../../../../utils/constants";
import MessageModal from "../../../../pages/MessageModal";
import { msgType, responseType } from "../../../../utils/Form.types";
import ExcelLoading from "../../../../pages/ExcelLoading";
import {
  EditModalCustomStyles,
  LoadingStyles,
} from "../../../../styles/ModalStyles";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import { CustomTooltip } from "../../../../styles/TooltipStyles";
import { userDetails } from "../../../../Types/Accounting/other";
import useAcctLedgerData from "../../../Accounts/hooks/useAcctLedgerData";
import { AcctLedgerQueryType } from "../../../Accounts/common/QueryTypes";

interface ValidateStudentDataForImportDataInput {
  std_details: {
    first_name: string;
    middle_name: string;
    last_name: string;
    std_adm_no: string | number;
    dept_name: string;
    branch_name: string;
    class_name: string;
    semester_name: string;
    section_name: string;
    category_name: string;
  };
  receipt_details: {
    v_no: string;
    v_date: string;
    cr_details: [
      {
        vo_cr_ldgr_desc: string;
        vo_cr_amount: number;
      }
    ];
    vo_db_ldgr_desc: string;
    total_amount: string | number;
    v_transcation_type: string;
    v_transcation_no: string;
    v_transcation_narration: string;
  };
}
interface ValidateStudentDataForImportVars {
  token: string;
  inst_id: string;
  input: ValidateStudentDataForImportDataInput;
  user_details: userDetails;
}
interface ValidateStudentDataForImportData {
  ValidateOldStdReceipts: boolean;
}
interface dr {
  Class: string;
  Department: string;
  Branch: string;
  Semester: string;
  Section: string;
  Category: string;
}

const ImportStudentFeeDEmand = () => {
  const { token } = useToken();
  const { InstId } = useParams();
  const navigate = useNavigate();
  const { serverDate } = useServerDateandTime();

  const { LogoOrSign } = useInstLogoDetails({
    filetype: FileUploadParams.INST_LOGO,
  });
  const [successCount, setSuccessCount] = useState(0);
  const [errorCount, setErrorCount] = useState(0);

  const [expanded, setExpanded] = useState(false);
  const [schema, setSchema] = useState<any>();
  const [importModal, setImportModal] = useState(false);
  const [stdConfig, setStdConfig] = useState(false);
  const { acctLedgers } = useAcctLedgerData(
    EMPTY_STRING,
    AcctLedgerQueryType.STD_FEE_ACCT_LDGRS,
    null
  );

  const [selectedLedgers, setSelectedLedgers] = useState<responseType[]>([]);

  const [records, setRecords] = useState<any[]>([]);

  const [GetMasterDetails, { data }] = useLazyQuery<
    GetInstMasterDataDetails,
    GetInstMasterDataVars
  >(GetInstMasterData, {
    variables: {
      token,
      inst_id: InstId!,
    },
  });
  const totalRecords = records.filter(
    (record) => !record.isValidatedAndError
  ).length;
  const [message, setMessage] = useState<msgType>({
    message: EMPTY_STRING,
    flag: false,
    operation: Operation.NONE,
  });
  const handleLedgerChange = (ledger: responseType) => {
    setSelectedLedgers((prevSelected) => {
      if (prevSelected.some((s) => s.value === ledger.value)) {
        return prevSelected.filter((s) => s.value !== ledger.value);
      } else {
        return [...prevSelected, ledger];
      }
    });
  };
  const handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setSelectedLedgers(acctLedgers.responseType.map((ledger) => ledger));
    } else {
      setSelectedLedgers([]);
    }
  };
  const allSelected =
    selectedLedgers.length === acctLedgers.responseType.length;
  const isIndeterminate =
    selectedLedgers.length > 0 &&
    selectedLedgers.length < acctLedgers.responseType.length;
  const { user_details } = useLoggedInUserDetails();
  const { state } = useContext(AppContext);
  const { data: treeData } = useMstInstTree();

  const [ValidateEachRow] = useLazyQuery<
    ValidateStudentDataForImportData,
    ValidateStudentDataForImportVars
  >(ValidateOldStdReceipts);
  const [AddStudent, { loading }] = useMutation(ImportStdFeeDemand, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const { InstDetails } = useInstDetails(1);
  const {
    USE_CLASS_KEY,
    USE_BRANCH_KEY,
    USE_DEPARTMENT_KEY,
    USE_SEMESTER_KEY,
    USE_CATEGORY_KEY,
    USE_SECTION_KEY,
  } = useInstitutionConfiguration();

  const classDetails = (data && data.GetInstMasterData.class_details) || [];
  const deptDetails = (data && data.GetInstMasterData.dept_details) || [];
  const branchDetails = (data && data.GetInstMasterData.branch_details) || [];
  const sectionDetails = (data && data.GetInstMasterData.section_details) || [];
  const semesterDetails =
    (data && data.GetInstMasterData.semester_details) || [];
  const categoryDetails =
    (data && data.GetInstMasterData.category_details) || [];
  const maxLength = Math.max(
    classDetails.length,
    deptDetails.length,
    branchDetails.length,
    sectionDetails.length,
    semesterDetails.length,
    categoryDetails.length
  );
  let arrayTreeMapedData: dr[] = [];
  for (let index = 0; index < maxLength; index++) {
    const classe = classDetails[index]?.class_desc;
    const dept = deptDetails[index]?.dept_desc;
    const branche = branchDetails[index]?.branch_desc;
    const sectione = sectionDetails[index]?.section_desc;
    const semestere = semesterDetails[index]?.sem_desc;
    const categorye = categoryDetails[index]?.cat_desc;

    arrayTreeMapedData.push({
      Class: classe || "",
      Department: dept || "",
      Branch: branche || "",
      Semester: semestere || "",
      Section: sectione || "",
      Category: categorye || "",
    });
  }

  const { departmentLabel, semesterLabel, sectionLabel, categoryLabel } =
    useInstLabels();

  const instConfig = [
    {
      Headers: `${departmentLabel}`,
      bool: USE_DEPARTMENT_KEY,
      key: "Department",
      width: 25,
    },
    {
      Headers: "Branch",
      bool: USE_BRANCH_KEY,
      key: "Branch",
      width: 25,
    },

    {
      Headers: "Class",
      bool: USE_CLASS_KEY,
      key: "Class",
      width: 25,
    },
    {
      Headers: `${semesterLabel}`,
      bool: USE_SEMESTER_KEY,
      key: "Semester",
      width: 25,
    },
    {
      Headers: `${sectionLabel}`,
      bool: USE_SECTION_KEY,
      key: "Section",
      width: 25,
    },
    {
      Headers: `${categoryLabel}`,
      bool: USE_CATEGORY_KEY,
      key: "Category",
      width: 25,
    },
  ];
  const studentData = [
    {
      Headers: "AdmissionNumber",
      key: "adm_no",
      width: 50,
      required: false,
      use_in_sheet: true,
    },

    {
      Headers: "StudentName",
      key: "StudentName",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "Branch",
      bool: USE_BRANCH_KEY,
      key: "Branch",
      width: 25,
      use_in_sheet: USE_BRANCH_KEY,
    },
    {
      Headers: "Class",
      width: 50,
      required: USE_CLASS_KEY,
      type: String,
      key: "Class",
      use_in_sheet: USE_CLASS_KEY,
    },
    ...(selectedLedgers || []).map((res) => ({
      Headers: `Ledger - ${res.label}`,
      key: res.value,
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    })),
  ];
  const student_schema = {
    AdmissionNumber: {
      name: "adm_no",
    },

    StudentName: {
      name: "StudentName",
      required: true,
    },

    Branch: {
      name: "Branch",
      required: USE_BRANCH_KEY,
    },

    Class: {
      name: "Class",
      required: USE_CLASS_KEY,
    },
  };

  const dynamicLedgers = records.reduce((acc, res) => {
    Object.keys(res).forEach((key) => {
      if (key.startsWith("Ledger -")) {
        acc[key] = {
          Headers: key,
          key: res[key],
          width: 50,
          required: false,
          use_in_sheet: true,
        };
      }
    });
    return acc;
  }, {} as Record<string, { Headers: string; key: string | number; width: number; required: boolean; use_in_sheet: boolean }>);

  const combinedSchema = {
    ...student_schema,
    ...dynamicLedgers,
  };

  const schemaKeys = Object.keys(combinedSchema);

  const InstName = InstDetails.data?.nodes[0]?.inst_name!;
  const errorData = records
    .filter((record) => record?.errors)
    .map((f) => ({
      adm_no: f.AdmissionNumber,
      firstname: f.StudentName,
      middlename: f.MiddleName,
      lastname: f.LastName,
      email: f.Email,
      contactnumber: f.ContactNumber,
      fathername: f.FatherName,
      dob: f.DateOfBirth,
      sex: f.Gender,
      fresher: f.Fresher,
      ...(USE_DEPARTMENT_KEY ? { Department: f.Department } : {}),
      ...(USE_BRANCH_KEY ? { Branch: f.Branch } : {}),
      ...(USE_CLASS_KEY ? { class: f.class } : {}),
      ...(USE_SEMESTER_KEY ? { Semester: f.Semester } : {}),
      ...(USE_CATEGORY_KEY ? { category: f.category } : {}),
      ...(USE_SECTION_KEY ? { Section: f.Section } : {}),
    }));

  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(ExcelPageHeader.INST_CONFIG_DATA);
    const worksheet1 = workbook.addWorksheet("StudentInfo");
    worksheet.views = FROZEN_CELLS;
    const headerStyle = getHeaderRowStyle();
    worksheet.getRow(1).height = 35;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 23;
    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 30;
    worksheet.getColumn(4).width = 30;
    worksheet.getColumn(5).width = 30;
    worksheet.getColumn(6).width = 20;
    worksheet.protect("the123", {
      formatCells: false,
      formatColumns: true,
      formatRows: true,
      insertRows: true,
      insertColumns: false,
      deleteRows: true,
      deleteColumns: false,
      sort: true,
      autoFilter: true,
    });

    const getBase64 = (blob: Blob, cb: (a: string) => void) => {
      const file = new File([blob], FILENAME, {
        lastModified: Date.now(),
        type: blob.type,
      });
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });

          worksheet.addImage(imageV, "F1:F3");
          workbook.xlsx.writeBuffer().then(() => {
            fetch(LogoOrSign.defaultLogo)
              .then((response) => {
                return response.blob();
              })
              .then((blob) => {
                getBase64(blob, (result) => {
                  const imageB = workbook.addImage({
                    base64: result,
                    extension: EDUATE_IMG_FORMAT,
                  });

                  worksheet.addImage(imageB, "A1:A3");
                  worksheet.mergeCells(1, 1, 1, 6);

                  const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
                  mergedCell.value = InstDetails.data?.nodes[0]?.inst_name;
                  mergedCell.fill = HEADER_CSS;
                  mergedCell.font = headerStyle[0].font;
                  mergedCell.alignment = HEADER_ALIGNMENT;

                  const mergedAddress: Cell = worksheet.getCell(A2_CELL);
                  mergedAddress.value =
                    InstDetails.data?.nodes[0]?.inst_address;
                  mergedAddress.fill = HEADER_CSS;
                  mergedAddress.font = ADDRESS_FONT;
                  mergedAddress.alignment = HEADER_ALIGNMENT;
                  worksheet.mergeCells("A2:F2");

                  const mergedPlace: Cell = worksheet.getCell(A3_CELL);
                  mergedPlace.value =
                    InstDetails.data?.nodes[0]?.inst_place +
                    "-" +
                    InstDetails.data?.nodes[0]?.inst_pin;

                  mergedPlace.fill = HEADER_CSS;
                  mergedPlace.font = ADDRESS_FONT;
                  mergedPlace.alignment = ADDRESS_ALIGNMENT;
                  worksheet.mergeCells("A3:F3");

                  const mergedHeader: Cell = worksheet.getCell(C4_CELL);
                  mergedHeader.value = "InstConfigdata";
                  mergedHeader.fill = FILE_NAME_CSS;
                  mergedHeader.font = FILE_NAME_FONT;
                  mergedHeader.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("C4:D4");
                  const mergedDate: Cell = worksheet.getCell(E4_CELL);
                  mergedDate.value =
                    ExcelPageHeader.DATE + toStandardDate(serverDate);
                  mergedDate.fill = FILE_NAME_CSS;
                  mergedDate.font = FIN_YEAR_FONT;
                  mergedDate.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("E4:F4");
                  const mergedYear: Cell = worksheet.getCell(A4_CELL);
                  mergedYear.value = state.ActiveFinYr
                    ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
                    : ExcelPageHeader.YEAR;
                  mergedYear.fill = FILE_NAME_CSS;
                  mergedYear.font = FIN_YEAR_FONT;
                  mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("A4:B4");

                  let Char = FIRST_INDEX;
                  let headers = instConfig
                    .filter((d) => d.bool)
                    .map((f) => f.Headers);

                  for (let i = 0; i < headers.length; i++) {
                    Char = String.fromCharCode(Char.charCodeAt(0) + 1);
                    const rowData: Cell = worksheet.getCell(Char + 5);
                    rowData.value = headers[i];
                    rowData.fill = TABLE_HEADER_CSS;
                    rowData.border = BORDER_DATA;
                    rowData.font = ACC_HEADER_FONT;
                    rowData.alignment = { horizontal: ExcelAlignment.CENTER };
                  }
                  const headerRow = worksheet1.getRow(1);
                  headerRow.height = 25;
                  headerRow.font = ACC_HEADER_FONT;
                  const filteredStudentExcelData = studentData.filter(
                    (column) => column.use_in_sheet
                  );

                  let studentInfoHeader = filteredStudentExcelData
                    .filter((student) => student.use_in_sheet)
                    .map((f) => f.Headers);
                  for (let i = 0; i < filteredStudentExcelData.length; i++) {
                    const cell = headerRow.getCell(i + 1);

                    cell.value = studentInfoHeader[i];
                    cell.fill = TABLE_HEADER_CSS;
                    cell.border = BORDER_DATA;

                    cell.alignment = { horizontal: ExcelAlignment.CENTER };
                    worksheet1.columns.forEach((column) => {
                      column.width = 20;
                    });
                  }

                  let dataRow = 6;
                  treeData?.GetMstInstTree.forEach((level1_data) => {
                    let level1_nxt_index = 0;
                    if (
                      level1_data.level_2_details &&
                      level1_data.level_2_details.length > 0
                    ) {
                      let level2_dataRow = dataRow;
                      level1_data.level_2_details.forEach((level2_data) => {
                        let level2_nxt_index = 0;
                        if (
                          level2_data.level_3_details &&
                          level2_data.level_3_details.length > 0
                        ) {
                          let level3_dataRow = level2_dataRow;
                          level2_data.level_3_details.forEach((level3_data) => {
                            let level3_nxt_index = 0;
                            if (
                              level3_data.level_4_details &&
                              level3_data.level_4_details.length > 0
                            ) {
                              let level4_dataRow = level3_dataRow;
                              level3_data.level_4_details.forEach(
                                (level4_data) => {
                                  let level4_nxt_index = 0;
                                  if (
                                    level4_data.level_5_details &&
                                    level4_data.level_5_details.length > 0
                                  ) {
                                    level4_nxt_index +=
                                      level4_data.level_5_details.length;
                                    level3_nxt_index +=
                                      level4_data.level_5_details.length;
                                    level1_nxt_index +=
                                      level4_data.level_5_details.length;
                                    level2_nxt_index +=
                                      level4_data.level_5_details.length;
                                    let level5_dataRow = level4_dataRow;
                                    level4_data.level_5_details.forEach(
                                      (level5_data, index) => {
                                        const level5_cell = worksheet.getCell(
                                          `E${level5_dataRow}`
                                        );
                                        level5_cell.value =
                                          level5_data.level_5_desc;

                                        level5_cell.style = {
                                          alignment: HEADER_ALIGNMENT_CENTER,
                                        };
                                        level5_dataRow += index + 1;
                                      }
                                    );
                                  } else {
                                    level4_nxt_index = 1;
                                    level3_nxt_index += 1;
                                    level2_nxt_index += 1;
                                    level1_nxt_index += 1;
                                  }
                                  const level4_cell = worksheet.getCell(
                                    `D${level4_dataRow}`
                                  );
                                  level4_cell.value = level4_data.level_4_desc;
                                  worksheet.mergeCells(
                                    `D${level4_dataRow}:D${
                                      level4_dataRow + level4_nxt_index - 1
                                    }`
                                  );
                                  level4_cell.style = {
                                    alignment: HEADER_ALIGNMENT_CENTER,
                                  };
                                  level4_dataRow += level4_nxt_index;
                                }
                              );
                            } else {
                              level3_nxt_index = 1;
                              level2_nxt_index += 1;
                              level1_nxt_index += 1;
                            }
                            const level3_cell = worksheet.getCell(
                              `C${level3_dataRow}`
                            );
                            level3_cell.value = level3_data.level_3_desc;
                            worksheet.mergeCells(
                              `C${level3_dataRow}:C${
                                level3_dataRow + level3_nxt_index - 1
                              }`
                            );
                            level3_cell.style = {
                              alignment: HEADER_ALIGNMENT_CENTER,
                            };
                            level3_dataRow += level3_nxt_index;
                          });
                        } else {
                          level2_nxt_index = 1;
                          level1_nxt_index += 1;
                        }

                        const level2_cell = worksheet.getCell(
                          `B${level2_dataRow}`
                        );
                        level2_cell.value = level2_data.level_2_desc;
                        worksheet.mergeCells(
                          `B${level2_dataRow}:B${
                            level2_dataRow + level2_nxt_index - 1
                          }`
                        );
                        level2_cell.style = {
                          alignment: HEADER_ALIGNMENT_CENTER,
                        };
                        level2_dataRow += level2_nxt_index;
                      });
                    } else {
                      level1_nxt_index = 1;
                    }

                    const level1_cell = worksheet.getCell(`A${dataRow}`);
                    level1_cell.value = level1_data.level_1_desc;
                    worksheet.mergeCells(
                      `A${dataRow}:A${dataRow + level1_nxt_index - 1}`
                    );
                    level1_cell.style = {
                      alignment: HEADER_ALIGNMENT_CENTER,
                    };
                    dataRow += level1_nxt_index;
                  });
                  let count = 1;
                  if (USE_DEPARTMENT_KEY) {
                    count++;
                  }
                  if (USE_BRANCH_KEY) {
                    count++;
                  }
                  if (USE_CLASS_KEY) {
                    count++;
                  }
                  if (USE_SEMESTER_KEY) {
                    count++;
                  }
                  if (USE_SECTION_KEY) {
                    count++;
                  }
                  const cat_cell = String.fromCharCode(
                    FIRST_INDEX.charCodeAt(0) + count
                  );

                  categoryDetails?.forEach((cat, index) => {
                    const categoryCell = worksheet.getCell(
                      `${cat_cell + Number(index + 6)}`
                    );
                    categoryCell.value = cat.cat_desc;
                  });

                  workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
                    const blob = new Blob([buffer], {
                      type: BLOB_TYPE,
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement(ELEMENT);
                    link.href = url;
                    link.setAttribute(DOWNLOAD, "Fee Receipt Details");
                    document.body.appendChild(link);
                    link.click();
                  });
                });
              });
          });
        });
      });
  };

  const downloadExcelContainsError = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet1 = workbook.addWorksheet("StudentInfo");
    worksheet1.views = [{ state: "frozen", xSplit: 1, ySplit: 1 }];
    if (records.length === 0) {
      console.warn("No data available to create the Excel file.");
      return;
    }

    const errorColumns = ["errors"]; // Add any other error columns you want to include
    const headers = Object.keys(records[0]).filter(
      (header) =>
        !["isChecked", "isValidated", "isValidatedAndError", "id"].includes(
          header
        )
    );

    // Add error columns if they are not present
    errorColumns.forEach((col) => {
      if (!headers.includes(col)) {
        headers.push(col);
      }
    });
    worksheet1.columns = headers.map((header) => ({
      header,
      key: header,
      width: 20,
    }));

    const headerRow = worksheet1.getRow(1);
    headerRow.height = 25;
    headerRow.font = { name: "Arial", size: 12, bold: true };
    headerRow.alignment = { vertical: "middle", horizontal: "center" };
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFE0B2" },
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    records
      .filter((record) => record?.errors)
      .forEach((entry, rowIndex) => {
        const row = worksheet1.getRow(rowIndex + 2);
        headers.forEach((header, colIndex) => {
          const cell = row.getCell(colIndex + 1);
          cell.value = entry[header];
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
          cell.alignment = { vertical: "middle", horizontal: "center" };
        });
      });

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "StudentInfo.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const processExcel = (data: any) => {
    const workbook = XLSX.read(data, { type: "binary" });
    const firstSheet = workbook.SheetNames[1];

    var records = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet]);
    // eslint-disable-next-line
    records.map((record: any) => {
      let errors: any = [];
      schema &&
        // eslint-disable-next-line
        Object.keys(schema).map((key) => {
          // @ts-ignore
          let keySchema = combinedSchema[key];
          let keyValue = record[key];
          if (
            keySchema.required &&
            (keyValue === null || keyValue === undefined)
          ) {
            let errorObj = {
              column: key,
              error: "Required",
            };
            errors.push(errorObj);
          }
        });
      if (errors.length > 0) {
        record.errors = errors;
      }
    });
    return records;
  };

  const handleButtonClick = async () => {
    try {
      await Promise.all(records.map((record) => handleClick(record.id)));
    } catch (error) {
      console.error(error);
    }
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setRecords([]);
    }
    setMessage({
      flag: false,
      message: EMPTY_STRING,
      operation: Operation.NONE,
    });
  };

  const handleClick = (id: number) => {
    return new Promise((resolve, reject) => {
      const filteredData = records.find((record) => record.id === id);
      if (filteredData) {
        const ledgerKeys = Object.keys(filteredData).filter((key) =>
          key.startsWith("Ledger - ")
        );
        const ledgerDetails = ledgerKeys.map((ledgerKey) => {
          const ledgerDesc = ledgerKey.replace("Ledger - ", "");
          return {
            vo_cr_ldgr_desc: ledgerDesc,
            vo_cr_amount: filteredData[ledgerKey] ?? 0,
          };
        });

        ValidateEachRow({
          variables: {
            inst_id: InstId!,
            token,
            user_details,
            input: {
              std_details: {
                first_name: filteredData.StudentName,
                middle_name: "",
                last_name: "",
                std_adm_no: filteredData.AdmissionNumber,
                dept_name: "",
                branch_name: filteredData.Branch,
                class_name: filteredData.Class,
                semester_name: "",
                section_name: "",
                category_name: "",
              },
              receipt_details: {
                v_no: filteredData.ReceiptNumber,
                v_date: filteredData.ReceiptDate
                  ? toIsoDate(
                      convertToDateObject_MMDDYYYYFormat(
                        filteredData.ReceiptDate
                      )
                    )
                  : toIsoDate(DEFAULT_TIME),
                //@ts-ignore
                cr_details: ledgerDetails,
                vo_db_ldgr_desc: filteredData.BankorCashName
                  ? filteredData.BankorCashName
                  : EMPTY_STRING,
                total_amount: filteredData.TotalAmount,
                v_transcation_type: filteredData.TransactionType
                  ? filteredData.TransactionType
                  : EMPTY_STRING,
                v_transcation_no: filteredData.TransactionNumber,
                v_transcation_narration: filteredData.Remarks
                  ? filteredData.Remarks
                  : EMPTY_STRING,
              },
            },
          },
        }).then(({ data, error }) => {
          if (data) {
            const newData = records.map((record) => {
              if (id === record.id) {
                return {
                  ...record,
                  isChecked: !record?.isChecked,
                  isValidated: !record.isValidated,
                  isValidatedAndError: false,
                };
              }
              return record;
            });
            setRecords(newData);
            resolve(data);
          }
          if (error) {
            const newData = records.map((record) => {
              if (record.id === id) {
                record.errors = record.errors ? record.errors : [];
                record.errors.push(error && error.message);
                return {
                  ...record,
                  isChecked: !record?.isChecked,
                  isValidated: true,
                  isValidatedAndError: true,
                };
              }
              return record;
            });
            setRecords(newData);
            reject(error);
          }
        });
      }
    });
  };

  const handleAddStudent = async () => {
    setSuccessCount(0);
    setErrorCount(0);
    setImportModal(true);

    for (const record of records.filter(
      (record) => !record.isValidatedAndError
    )) {
      try {
        const ledgerKeys = Object.keys(record).filter((key) =>
          key.startsWith("Ledger - ")
        );
        const ledgerDetails = ledgerKeys.map((ledgerKey) => {
          const ledgerDesc = ledgerKey.replace("Ledger - ", "");
          return {
            ldgr_desc: ledgerDesc,
            fee_amount: record[ledgerKey] ?? 0,
          };
        });
        await AddStudent({
          variables: {
            token,
            inst_id: InstId,
            user_details,
            input: {
              std_details: {
                first_name: removeMoreSpace(record.StudentName),
                middle_name: "",
                last_name: "",
                std_sex: "",
                std_reg_no: "",
                std_adm_no: record.AdmissionNumber ?? "",
                dept_name: "",
                branch_name: record.Branch ?? "",
                class_name: record.Class ?? "",
                semester_name: "",
                category_name: "",
                section_name: "",
              },
              demand_details: ledgerDetails,
            },
          },
          onError: (err) => {
            record.errors = record.errors ? record.errors : [];
            record.errors.push(err.message);
            setMessage({
              flag: true,
              message: err.message,
              operation: Operation.NONE,
            });
            setErrorCount((prev) => prev + 1);
          },
        }).then(({ data }) => {
          if (data) {
            setSuccessCount((prev) => prev + 1);
          }
        });
      } catch (error) {
        console.error(error);
        setErrorCount((prev) => prev + 1);
      }
    }
  };

  const displayFileName = (): void => {
    const inputElement = document.getElementById("input") as HTMLInputElement;
    const fileName = inputElement.value;
    const fileLabel = document.getElementById("file-name") as HTMLSpanElement;
    fileLabel.innerHTML = fileName.split("\\").pop()!;
  };
  useEffect(() => {
    const input = document.getElementById("input") as HTMLInputElement;
    if (input) {
      input.addEventListener("change", () => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const res = processExcel(reader.result);
          setRecords(
            res.map((f: any, index: number) => {
              return {
                ...f,
                id: index + 1,
                isChecked: false,
                isValidated: false,
                isValidatedAndError: false,
              };
            })
          );
        };
        reader.readAsBinaryString(input.files![0]);
      });
    }
  });

  useEffect(() => {
    if (
      USE_CLASS_KEY ||
      USE_DEPARTMENT_KEY ||
      USE_SEMESTER_KEY ||
      USE_CATEGORY_KEY ||
      USE_BRANCH_KEY
    ) {
      setSchema(combinedSchema);
    } // eslint-disable-next-line
  }, [
    USE_CLASS_KEY,
    USE_DEPARTMENT_KEY,
    USE_SEMESTER_KEY,
    USE_CATEGORY_KEY,
    USE_BRANCH_KEY,
  ]);

  useEffect(() => {
    if (token) {
      GetMasterDetails();
    }
  }, [token, GetMasterDetails]);

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Import Student Fee Demand</Title>
      <div className="import-excel-data">
        <div className="row g-0 import-excel-data__blocks">
          <div className="col-6">
            <div className="import-excel-data__file-upload">
              <div className="import-excel-data__file-upload--file-name">
                <span id="file-name"></span>
              </div>
              <div className="import-excel-data__file-upload--browse">
                <label htmlFor="input">
                  <img src={FileAttach} alt="/" /> Choose Excel File
                </label>
                <input
                  id="input"
                  type="file"
                  name="file"
                  onChange={() => displayFileName()}
                  className="import-excel-data__file-upload--input"
                />
              </div>
            </div>
          </div>

          <div className="col import-excel-data__blocks--cards">
            <b>
              <img src={TotalStudents} alt="/" />
              Total Students
            </b>
            <span className="import-excel-data__blocks--cards--total-students">
              {records.length}
            </span>
          </div>
          <div
            className="col import-excel-data__blocks--cards"
            onClick={() => setExpanded(!expanded)}>
            <b>
              <img src={ReadyToImport} alt="/" />
              Ready to Import
            </b>
            <span className="import-excel-data__blocks--cards--ready-to-import">
              {records.filter((record) => !record.isValidatedAndError).length}
            </span>
          </div>
          <div
            className="col import-excel-data__blocks--cards"
            onClick={() => setExpanded(!expanded)}>
            <b>
              <img src={ContainsError} alt="/" />
              Contains Error
            </b>
            <span className="import-excel-data__blocks--cards--contains-error">
              {records.filter((record) => record?.errors).length}
            </span>
          </div>
        </div>
        <div className="import-excel-data__datablock">
          {records.length > 0 && schema ? (
            <>
              <div
                className="import-excel-data__datablock--title"
                onClick={() => setExpanded(!expanded)}>
                <div className="import-excel-data__datablock--records-count">
                  <span>
                    {
                      records.filter((record) => !record.isValidatedAndError)
                        .length
                    }
                  </span>
                  <b> Records Found to Import</b>
                </div>
                <img src={DownArrow} alt="/" />
              </div>
              {!expanded && (
                <div className="import-excel-data__datablock--tableblock">
                  <TableContainer className="import-excel-data__datablock--table">
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell id="td-center">
                            {TableHeaders.SLNO}
                          </TableCell>
                          {schemaKeys?.map((key, index) => {
                            // @ts-ignore

                            return <TableCell key={index}>{key}</TableCell>;
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {records
                          .filter((record) => !record?.errors)
                          .map((record, index) => (
                            <TableRow key={index}>
                              <TableCell id="td-center">{index + 1}</TableCell>
                              {/* @ts-ignore */}
                              {/* eslint-disable-next-line */}
                              {schemaKeys.map((key, colIndex) => {
                                // @ts-ignore
                                const fieldSchema = combinedSchema[key];

                                if (fieldSchema) {
                                  return (
                                    <TableCell key={colIndex}>
                                      {record[key]}
                                    </TableCell>
                                  );
                                }
                              })}
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}

              <div
                className="import-excel-data__datablock--title"
                onClick={() => setExpanded(!expanded)}>
                <div className="import-excel-data__datablock--errors-count">
                  <span>{records.filter((d) => d?.errors).length}</span>
                  <b>Records Which Contains error(s)</b>
                </div>
                <img src={DownArrow} alt="/" />
              </div>
              {expanded && (
                <div className="import-excel-data__datablock--tableblock">
                  <TableContainer className="import-excel-data__datablock--table">
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell id="td-center">
                            {TableHeaders.SLNO}
                          </TableCell>
                          {/* eslint-disable-next-line */}
                          {schemaKeys.map((key, index) => {
                            // @ts-ignore
                            const fieldSchema = combinedSchema[key];

                            return <TableCell key={index}>{key}</TableCell>;
                          })}
                          <TableCell id="td-center">Error Details</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {records
                          .filter((record) => record?.errors)
                          .map((record, index) => {
                            const filteredHeaders: string[] = [];
                            return (
                              <TableRow key={index}>
                                <TableCell id="td-center">
                                  {index + 1}
                                </TableCell>
                                {Object.keys(schema).map((key, colIndex) => {
                                  // @ts-ignore
                                  const fieldSchema = combinedSchema[key];
                                  if (fieldSchema) {
                                    filteredHeaders.push(key);

                                    return (
                                      <TableCell key={colIndex}>
                                        {record[key]}
                                      </TableCell>
                                    );
                                  }
                                  return null;
                                })}

                                <TableCell className="import-excel-data__datablock--error">
                                  <CustomTooltip
                                    title={
                                      <ul>
                                        {record?.errors
                                          ? record?.errors?.map(
                                              (error: any, index: number) => {
                                                return (
                                                  <li key={index}>
                                                    {index + 1})&nbsp;
                                                    {typeof error === "string"
                                                      ? error
                                                      : "Error message = " +
                                                        error.error +
                                                        ", column = " +
                                                        error.column}
                                                  </li>
                                                );
                                              }
                                            )
                                          : null}
                                      </ul>
                                    }
                                    arrow
                                    placement="bottom-start">
                                    <div className="import-excel-data__datablock--tableblock--error-message">
                                      <span>Errors</span>
                                      <img src={Info} alt="/" />
                                    </div>
                                  </CustomTooltip>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
            </>
          ) : null}
        </div>
        <div className="row ">
          <div className="col">
            <Button
              onClick={() => {
                setImportModal(true);
                handleAddStudent();
              }}
              // disabled={
              //   !records.filter((record) => record?.isChecked).length ||
              //   !records.length
              //     ? true
              //     : false
              // }
              mode="excel">
              Import Students
            </Button>
            {/* <Button onClick={handleButtonClick}>
              <img src={Validate} alt="" />
              Validate
            </Button> */}
            <Button onClick={handleClear} mode="clear" />

            <Button onClick={downloadExcelContainsError} mode="excel">
              Export error Data
            </Button>
            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
          <div className="col import-excel-data__button">
            <Button mode="excel" onClick={() => setStdConfig(!stdConfig)}>
              Generate Template XLS File
            </Button>
          </div>
        </div>
      </div>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={stdConfig}
        style={EditModalCustomStyles}
        ariaHideApp={false}>
        <div className="import-excel-data__marks--modal h100">
          <div className="import-excel-data__marks--modal--title">
            <Title variant="subtitle1">Choose Ledgers </Title>
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStdConfig(!stdConfig)}
            />
          </div>
          <div className="row g-0">
            <div className="col">
              <div className="import-excel-data__marks--modal--block">
                <div className="select-all">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="allSelect"
                          className="select-all--checkbox"
                          checked={allSelected}
                          indeterminate={isIndeterminate}
                          onChange={handleSelectAllChange}
                        />
                      }
                      label="Select All"
                    />
                  </FormGroup>
                </div>
                {acctLedgers.responseType.map((ledger, index) => {
                  return (
                    <FormGroup className="select-all__checkboxes" key={index}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={ledger.label}
                            checked={selectedLedgers.some(
                              (s) => s.value === ledger.value
                            )}
                            onChange={() => handleLedgerChange(ledger)}
                          />
                        }
                        label={
                          <>
                            &nbsp; {index + 1}) &nbsp; {ledger.label}
                          </>
                        }
                      />
                    </FormGroup>
                  );
                })}
              </div>
            </div>
          </div>
          <Button mode="excel" onClick={downloadExcel}>
            Download To Excel
          </Button>
          <Button mode="cancel" onClick={() => setStdConfig(!stdConfig)} />
        </div>
      </Modal>

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={importModal}
        style={LoadingStyles}
        ariaHideApp={false}>
        <ExcelLoading
          total={totalRecords}
          success={successCount}
          errors={errorCount}
          loading={loading}
        />
        <div className="modal-flex__image">
          <img
            onClick={() => setImportModal(!importModal)}
            src={Close}
            alt="/"
            className="modal-close-icon"
          />
        </div>
      </Modal>
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default ImportStudentFeeDEmand;
