import { useContext, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { StatesContext } from "./GlobalStates/StatesProvider";
import { AppContext } from "../../../../context/context";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import { useParams } from "react-router-dom";
import { SortBy } from "./GlobalStates/types";
import useToken from "../../../../customhooks/useToken";
import { msgType } from "../../../../utils/Form.types";
import { ExportModuleType, Operation } from "../../../../utils/Enum.types";
import {
  ExportAcctStdtoExcel,
  ExportAdmStdtoExcel,
} from "../../../../queries/xls";
import { getDownloadUrl } from "../../../../utils/DownloadFileWithPromise";
import { toIsoDate } from "../../../../utils/UtilFunctions";
import { DEFAULT_TIME, EMPTY_STRING } from "../../../../utils/constants";
import { AcctLedgerQueryType } from "../../../Accounts/common/QueryTypes";
import { VoucherQueryTypes } from "../../../Accounts/common/Enum.types";

export enum ExcelAdmQueryType {
  ADM_STUDENT_LIST = "ADM_STUDENT_LIST",
  AGE_STUDENT_LIST = "AGE_STUDENT_LIST",
  RELIGION_STUDENT_LIST = "RELIGION_STUDENT_LIST",
  CASTE_STUDENT_LIST = "CASTE_STUDENT_LIST",
  SC_ST_STUDENT_LIST = "SC_ST_STUDENT_LIST",
  BULK_ALTER_LIST = "BULK_ALTER_LIST",
}

export interface ExcelVoucherQueryData {
  acct_ldgr_id: number;
  vo_start_date: string;
  vo_end_date: string;
  vo_type: string;
  v_book_type: string;
}

interface CommonVars {
  token: string;
  inst_id: string;
  query_type:
    | VoucherQueryTypes
    | ExcelAdmQueryType
    | AcctLedgerQueryType
    | string;
  input: {
    ids: number[];
    std_status: string[];
    str_data: string[];
    int_data: number[];
    float_data: number[];
    acct_ldgr_ids?: number[] | string[];
  };
  parameters: string[];
  sort_by: SortBy;
}
export interface ExportAcctStdtoExcelVars extends CommonVars {
  fin_yr_id: number;
  voucher_input: ExcelVoucherQueryData;
}
interface ExportAdmStdtoExcelData {
  ExportAdmStdtoExcel: string;
}
interface ExportAdmStdtoExcelVars extends CommonVars {
  acd_yr_id: number;
}

export interface ExportAcctStdtoExcelData {
  ExportAcctStdtoExcel: string;
}

const useExportMutation = (
  query_type:
    | ExcelAdmQueryType
    | VoucherQueryTypes
    | AcctLedgerQueryType
    | string,
  endDate: string
) => {
  const { token } = useToken();
  const { state } = useContext(StatesContext);

  const { state: globalState } = useContext(AppContext);
  const { InstId, ledgerId } = useParams();

  const {
    USE_BRANCH_KEY,
    USE_CATEGORY_KEY,
    USE_CLASS_KEY,
    USE_DEPARTMENT_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();

  const [entryId, setEntryId] = useState(0);

  const [ExportAdm] = useLazyQuery<
    ExportAdmStdtoExcelData,
    ExportAdmStdtoExcelVars
  >(ExportAdmStdtoExcel);

  const [ExportAcct] = useLazyQuery<
    ExportAcctStdtoExcelData,
    ExportAcctStdtoExcelVars
  >(ExportAcctStdtoExcel);

  useEffect(() => {
    if (state.section && USE_SECTION_KEY) {
      setEntryId(state.section.value);
    } else if (state.sem && USE_SEMESTER_KEY) {
      setEntryId(state.sem.value);
    } else if (state.class && USE_CLASS_KEY) {
      setEntryId(state.class.value);
    } else if (state.branch && USE_BRANCH_KEY) {
      setEntryId(state.branch.value);
    } else if (state.dept && USE_DEPARTMENT_KEY) {
      setEntryId(state.dept.value);
    } else {
      setEntryId(Number(InstId));
    }
  }, [
    state.section,
    state.sem,
    state.class,
    state.branch,
    state.dept,
    USE_SEMESTER_KEY,
    USE_CLASS_KEY,
    USE_BRANCH_KEY,
    USE_DEPARTMENT_KEY,
    USE_SECTION_KEY,
    InstId,
  ]);

  const isVouchers = window.location.pathname.includes("voucher");

  const handleSubmit = () => {
    return new Promise<msgType>((resolve, reject) => {
      if (state.selectedFields.length || isVouchers) {
        if (window.location.pathname.includes(ExportModuleType.ADMISSIONS)) {
          try {
            ExportAdm({
              variables: {
                acd_yr_id: globalState.ActiveAcdYr
                  ? globalState.ActiveAcdYr.id
                  : 0,
                input: {
                  float_data: [],
                  ids: [
                    entryId,
                    USE_CATEGORY_KEY && state.category
                      ? state.category.value
                      : 0,
                  ],
                  int_data:
                    query_type === ExcelAdmQueryType.AGE_STUDENT_LIST
                      ? [state.aboveAge, state.belowAge]
                      : [],
                  std_status: state.selectedStatus,
                  str_data:
                    query_type === ExcelAdmQueryType.RELIGION_STUDENT_LIST &&
                    state.religion
                      ? [state.religion.value]
                      : query_type === ExcelAdmQueryType.CASTE_STUDENT_LIST &&
                        state.caste
                      ? [state.caste.value]
                      : [],
                },
                inst_id: InstId!,
                parameters: state.selectedFields.map(({ value }) => value),
                query_type,
                sort_by: state.selectedSortBy.value,
                token,
              },
            }).then(async ({ data, error }) => {
              if (data && data.ExportAdmStdtoExcel) {
                const res = await getDownloadUrl(
                  data.ExportAdmStdtoExcel,
                  true
                );
                if (res) {
                  resolve({
                    flag: true,
                    message: "Successfully downloaded file",
                    operation: Operation.CREATE,
                  });
                }
              }
              if (error && error.message) {
                reject({
                  flag: true,
                  message: error.message,
                  operation: Operation.NONE,
                });
              }
            });
          } catch (e) {
            reject({
              flag: true,
              message: "Failed to download",
              operation: Operation.NONE,
            });
          }
        } else {
          var ids =
            query_type === VoucherQueryTypes.ACCT_STD_FEE_BY_AGENT_REPORT
              ? [
                  entryId,
                  USE_CATEGORY_KEY && state.category ? state.category.value : 0,
                  globalState.agentId,
                ]
              : [
                  entryId,
                  USE_CATEGORY_KEY && state.category ? state.category.value : 0,
                ];
          var modified_q_type = query_type;
          if (window.location.pathname.includes("voucher")) {
            ids = [Number(InstId), 0];
            modified_q_type = VoucherQueryTypes.BY_DATES;
          }

          try {
            ExportAcct({
              variables: {
                fin_yr_id: state.selectedFinYr
                  ? state.selectedFinYr.id
                  : globalState.ActiveFinYr
                  ? globalState.ActiveFinYr.id
                  : 0,
                input: {
                  float_data: [],
                  ids,
                  int_data: [],
                  std_status: state.selectedStatus,
                  str_data: [],
                  acct_ldgr_ids: state.selectedFields.filter(
                    (field) => typeof field.value === "number"
                  )
                    ? state.selectedFields
                        .filter((field) => typeof field.value === "number")
                        .map((field) => field.value)
                    : [],
                },
                voucher_input: {
                  acct_ldgr_id: ledgerId ? Number(ledgerId) : 0,
                  vo_start_date: state.startDate
                    ? toIsoDate(state.startDate)
                    : toIsoDate(DEFAULT_TIME),
                  vo_end_date: endDate
                    ? toIsoDate(endDate)
                    : state.endDate
                    ? toIsoDate(state.endDate)
                    : toIsoDate(DEFAULT_TIME),
                  vo_type: EMPTY_STRING,
                  v_book_type: EMPTY_STRING,
                },
                inst_id: InstId!,
                parameters: state.selectedFields
                  .filter((field) => typeof field.value === "string")
                  .map(({ value }) => value),
                query_type: modified_q_type,
                sort_by: state.selectedSortBy.value,
                token,
              },
            }).then(async ({ data, error }) => {
              if (data && data.ExportAcctStdtoExcel) {
                const res = await getDownloadUrl(
                  data.ExportAcctStdtoExcel,
                  true
                );
                if (res) {
                  resolve({
                    flag: true,
                    message: "Successfully downloaded file",
                    operation: Operation.CREATE,
                  });
                }
              } else {
                reject({
                  flag: true,
                  message: "Failed downloading file",
                  operation: Operation.NONE,
                });
              }

              if (error && error.message) {
                reject({
                  flag: true,
                  message: error.message,
                  operation: Operation.NONE,
                });
              }
            });
          } catch (e) {
            reject({
              flag: true,
              message: "Failed to download",
              operation: Operation.NONE,
            });
          }
        }
      } else {
        reject({
          flag: true,
          message: "Fields not found",
          operation: Operation.NONE,
        });
      }
    });
  };
  return {
    handleSubmit,
  };
};

export default useExportMutation;
