import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import {
  Direction,
  SortBy,
  StudentReportType,
} from "../../../utils/Enum.types";
import { responseType } from "../../../utils/Form.types";
import { StudentAcdType } from "../../../utils/studentAcdEnum.types";
import { GetAcdStudents } from "../queries/students/Index";
import useAcademicYear from "./useActiveAcademicYear";
import { AcdStudentsvars, GetStudentAcdData } from "./useAcdStudentsData";
import { AppContext } from "../../../context/context";
import { EMPTY_STRING } from "../../../utils/constants";

const useAcdStudentsAdmNumber = (
  admNo: string,
  rowsPerPage: number,
  queryType: StudentReportType
) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { state } = useContext(AppContext);
  const { activeAcademicYearData } = useAcademicYear();
  const [studentAddmissionNumber, setAdmNumbers] = useState<responseType[]>([]);
  const [acd_std_query_type, setacd_std_query_type] = useState("");
  const [GetStudentsData] = useLazyQuery<GetStudentAcdData, AcdStudentsvars>(
    GetAcdStudents,
    {
      variables: {
        after: null,
        acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
        first: rowsPerPage,
        name: admNo,
        token,
        orderBy: [
          {
            direction: Direction.ASC,
            field: SortBy.FIRST_NAME,
          },
        ],

        input: {
          ids: [Number(InstId!)],
          acd_std_query_type: StudentAcdType.BY_ACD_INST_ID,
          str_data: [""],
        },
      },
    }
  );

  useEffect(() => {
    switch (queryType) {
      case StudentReportType.GENERAL:
        if (InstId) {
          setacd_std_query_type(StudentAcdType.BY_ACD_INST_ID);
        }
        break;

      default:
        break;
    }
  }, [InstId, queryType]);

  useEffect(() => {
    if (
      ((!activeAcademicYearData.loading &&
        activeAcademicYearData.data &&
        state.ActiveAcdYr) ||
        admNo) &&
      token &&
      queryType.length
    ) {
      GetStudentsData().then(({ data }) => {
        if (data) {
          setAdmNumbers(
            data.GetAcdStudents.edges.map((edge) => ({
              label: `(${edge.node.std_adm_no}) ${edge.node.first_name}`,
              value: edge.node.id,
              isChecked: false,
            }))
          );
        }
      });
    }
  }, [
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
    queryType,
    GetStudentsData,
    state.ActiveAcdYr,
    admNo,
    token,
  ]);
  return { studentAddmissionNumber };
};

export default useAcdStudentsAdmNumber;
