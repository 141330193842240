import React, { useContext, useEffect, useRef, useState } from "react";
import { Title } from "../../../stories/Title/Title";
import { FeeDemandTitleProps } from "../../../Types/Titles";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../styles/AutocompleteListStyles";
import useInstMasterDataByInstId from "../../../customhooks/useInstMasterDataByInstId";
import { TextField } from "@mui/material";
import useInstLabels from "../../../customhooks/general/useInstLabels";
import { Button } from "../../../stories/Button/Button";
import { msgType, responseType } from "../../../utils/Form.types";
import {
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../styles/DataGridTableStyles";
import {
  GridAlignment,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { Operation } from "../../../utils/Enum.types";
import useAcctTableJson from "../json/useAcctTableJson";
import { TableHeaderProps } from "../../../utils/types";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  FeeDetails,
  ListAccountLedgerNameData,
  ListAccountLedgerNameVars,
  ListDemandDetailsData,
  ListDemandDetailsVars,
} from "../../../Types/Accounting";
import {
  GetAccountLedgerNameById,
  GetAcctDemandDetails,
} from "../queries/FeeLedgers/query/Byid";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import useToken from "../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import useNotAlloactedIdFromInst from "../../../customhooks/useNotAlloactedIdFromInst";
import {
  isOptionEqualToValue,
  RefsByTagName,
  toInputStandardDate,
  toIsoDate,
} from "../../../utils/UtilFunctions";
import { AppContext } from "../../../context/context";
import { Keys } from "../../../utils/Enum.keys";
import { EMPTY_STRING } from "../../../utils/constants";
import dayjs from "dayjs";
import { UpdateAcctStdDemandDetailsDueDate } from "../queries/FeeLedgers/mutation/Index";
import useLoggedInUserDetails from "../hooks/useLoggedInUserDetails";
import MessageModal from "../../../pages/MessageModal";
import LoadingModal from "../../../pages/LoadingModal";
import useDropdownData from "../../../customhooks/useDropdownData";
const { AccountsTitles } = require("../json/title.json");

interface Props {
  setExtendDateForLateFeeModal: React.Dispatch<React.SetStateAction<boolean>>;
  extendDateForLateFeeModal: boolean;
}
const ExtendDueDate = ({
  setExtendDateForLateFeeModal,
  extendDateForLateFeeModal,
}: Props) => {
  const { categoryDropDown, branchDropDown } = useInstMasterDataByInstId();

  const { token } = useToken();
  const { InstId } = useParams();
  const [items, setItems] = useState<FeeDetails[]>([]);
  const editClassRef = useRef<HTMLSelectElement>(null);
  const { state } = useContext(AppContext);
  const fetchButtonRef = useRef<HTMLButtonElement>(null);
  const [newDueDates, setNewDueDates] = useState<Record<number, string>>({});
  const editClassInputRef = RefsByTagName(editClassRef);
  const [fetchFlag, setFetchFlag] = useState(false);
  const feeDescRef = useRef<HTMLSelectElement>(null);
  const handleNewDueDateChange = (ledgerId: number, date: string) => {
    setNewDueDates((prev) => ({
      ...prev,
      [ledgerId]: date,
    }));
  };
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const feeDescInputRef = RefsByTagName(feeDescRef);
  const classAllSelectRef = useRef<HTMLInputElement>(null);
  const branchRef = useRef<HTMLSelectElement>(null);
  const classRef = useRef<HTMLSelectElement>(null);
  const categoryRef = useRef<HTMLSelectElement>(null);
  const [branchId, setBranchId] = useState<responseType | null>(null);
  const [classId, setClassId] = useState<responseType | null>(null);
  const [categoryId, setCategoryId] = useState<responseType | null>(null);
  const { USE_CATEGORY_KEY } = useInstitutionConfiguration();
  const { NotAllocatedCategoryId } = useNotAlloactedIdFromInst();
  const branchInput = RefsByTagName(branchRef);
  const categoryInputRef = RefsByTagName(categoryRef);
  let totalsum = 0;
  let freshersum = 0;
  let presentsum = 0;
  items.forEach(({ fee_for_all, fee_for_exs_std, fee_for_new_std }) => {
    totalsum += fee_for_all;
    freshersum += fee_for_new_std;
    presentsum += fee_for_exs_std;
  });
  const { classDropDown } = useDropdownData(
    0,
    branchId ? branchId.value : 0,
    classId ? classId.value : 0,
    0
  );
  const [GetAcctLdgrsName, { data: AccountLedgerNameData }] = useLazyQuery<
    ListAccountLedgerNameData,
    ListAccountLedgerNameVars
  >(GetAccountLedgerNameById, {
    variables: {
      token,
      acct_ldgr_ids: items?.map((item) => item.acct_ldgr_id!),
    },
  });
  const { user_details } = useLoggedInUserDetails();

  const [UpdateDueDate, { loading: creationLoading }] = useMutation(
    UpdateAcctStdDemandDetailsDueDate,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const handleSubmit = () => {
    UpdateDueDate({
      variables: {
        token,
        inst_id: InstId!,
        fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
        branch_id: branchId?.value,
        class_id: classId?.value,
        category_id: USE_CATEGORY_KEY
          ? categoryId && categoryId.value
          : NotAllocatedCategoryId,
        user_details,
        input: items.map((item, index) => ({
          fee_due_date: toIsoDate(newDueDates[item.acct_ldgr_id]),
          acct_ldgr_id: item.acct_ldgr_id,
        })),
      },
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Fee Due-Date Updated Successfully",
          operation: Operation.CREATE,
        });
      }
    });
  };

  useEffect(() => {
    if (items.length) {
      GetAcctLdgrsName();
    }
  }, [GetAcctLdgrsName, items]);

  const { branchLabel, classLabel, categoryLabel } = useInstLabels();
  const { Accounts_Table } = useAcctTableJson();
  const [AccountDemandDetails] = useLazyQuery<
    ListDemandDetailsData,
    ListDemandDetailsVars
  >(GetAcctDemandDetails);

  const HandleExistingItems = async () => {
    if (branchId && classId) {
      const response = await AccountDemandDetails({
        variables: {
          token,
          branch_id: branchId.value,
          category_id: USE_CATEGORY_KEY
            ? categoryId && categoryId.value
            : NotAllocatedCategoryId,
          class_id: classId.value,
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          inst_id: InstId!,
        },
      });

      if (response.data && !response.error) {
        setItems(response.data?.GetAcctDemandDetails!);
      } else {
        setItems([]);
        branchInput?.focus();
        setBranchId(null);
        setClassId(null);
        setCategoryId(null);
      }
      if (response.error && !response.data) {
        setItems([]);
      }
    } else {
      if (branchId === null) {
        branchInput.focus();
      } else if (classId === null) {
        editClassInputRef.focus();
      } else if (USE_CATEGORY_KEY && categoryId === null) {
        categoryInputRef.focus();
      }
    }
  };

  const handleClear = () => {
    setBranchId(null);
    setClassId(null);
    setCategoryId(null);
  };

  const dynamicHeaders: TableHeaderProps[] =
    Accounts_Table.FeeDemand.ExtendDueDate.Table_Headers.map((header) => ({
      headerName: header.headerName,
      cellClassName: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
      hideable: header.hideable,
      renderCell: (params: GridRenderCellParams) => {
        if (header.field === "new_due_date") {
          const ledgerId = params.row.acct_ldgr_id;
          return (
            <div className="extend-feedemand__table--input">
              <TextField
                type="date"
                value={newDueDates[ledgerId] || ""}
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                  input: {
                    inputProps: {
                      min: state.ActiveFinYr
                        ? toInputStandardDate(state.ActiveFinYr.fin_st_date)
                        : 0,

                      max: state.ActiveFinYr
                        ? toInputStandardDate(state.ActiveFinYr.fin_end_date)
                        : 0,
                    },
                  },
                }}
                onChange={(e) =>
                  handleNewDueDateChange(ledgerId, e.target.value)
                }
              />
            </div>
          );
        } else {
          return params.value;
        }
      },
    }));

  const columns: GridColDef[] = [...dynamicHeaders];
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") return;

    if (message.operation !== Operation.NONE && message.flag) {
      setBranchId(null);
      setCategoryId(null);
      setClassId(null);
      setItems([]);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  const rows: GridValidRowModel[] =
    (items &&
      items.map((item, index) => {
        return {
          id: index + 1,
          fee_desc:
            AccountLedgerNameData?.GetAcctLdgrNamesByIds[index]?.id ===
            item.acct_ldgr_id
              ? AccountLedgerNameData?.GetAcctLdgrNamesByIds[index]?.ldgr_desc
              : EMPTY_STRING,
          fee_all: item.fee_for_all,
          fee_fresher: item.fee_for_new_std,
          fee_existing: item.fee_for_exs_std,
          due_date:
            item?.fee_due_date &&
            dayjs(item?.fee_due_date)?.format("DD-MM-YYYY"),
          new_due_date: newDueDates[item.acct_ldgr_id] || "",
          acct_ldgr_id: item.acct_ldgr_id,
        };
      })) ||
    [];

  const getRow = () => {
    if (items.length) {
      return [
        {
          id: [],
          fee_desc: "Total",
          fee_all: totalsum,
          fee_fresher: freshersum,
          fee_existing: presentsum,
          due_date: [],
          new_due_date: [],
        },
      ];
    }
    return [];
  };

  const pinnedRows = {
    bottom: getRow(),
  };
  useEffect(() => {
    if (items && items.length > 0) {
      const initialDueDates = items.reduce((acc, item) => {
        if (item.fee_due_date) {
          acc[item.acct_ldgr_id] = dayjs(item.fee_due_date).format(
            "YYYY-MM-DD"
          );
        }
        return acc;
      }, {} as Record<number, string>);
      setNewDueDates(initialDueDates);
    }
  }, [items]);

  const getCellClassName = (params: GridCellParams) => {
    const pinnedRowIds = pinnedRows.bottom
      ? pinnedRows.bottom.map((row) => row.id)
      : [];

    const isPinnedRow = pinnedRowIds.includes(params.row.id);

    if (isPinnedRow) {
      switch (params.field) {
        case "fee_all":
        case "fee_fresher":
        case "fee_existing":
          return "totalcount";
        case "new_due_date":
          return "hide-td";
        default:
          return "total";
      }
    }

    return "";
  };
  return (
    <>
      <Title>
        {AccountsTitles.FeeDemand.Titles.map(
          (title: FeeDemandTitleProps, index: React.Key) => {
            return (
              <React.Fragment key={index}>
                {title.Extend_Fee_Demand}
              </React.Fragment>
            );
          }
        )}
      </Title>
      <div className="extend-feedemand ">
        <div className="row g-0 extend-feedemand__options">
          <div className="col">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={branchDropDown}
              openOnFocus
              forcePopupIcon
              ref={branchRef}
              value={branchId}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option as responseType, branchId)
              }
              disabled={items?.length >= 1}
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === Keys.ENTER) {
                  if (branchId) {
                    USE_CATEGORY_KEY
                      ? categoryInputRef?.focus()
                      : classAllSelectRef?.current?.focus();
                  }
                  if (branchId) {
                    editClassInputRef?.focus();
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  setBranchId(null);
                }
              }}
              onChange={(e, newValue) => {
                if (newValue) {
                  setBranchId({
                    label: (newValue as responseType).label,
                    value: (newValue as responseType).value,
                    isChecked: true,
                  });
                } else {
                  setBranchId(null);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  label={branchLabel}
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
          <div className="col">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              ref={classRef}
              options={classDropDown}
              openOnFocus
              forcePopupIcon
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option as responseType, classId)
              }
              value={classId}
              onChange={(e, newValue) => {
                if (newValue) {
                  setClassId({
                    label: (newValue as responseType).label,
                    value: (newValue as responseType).value,
                    isChecked: true,
                  });
                } else {
                  setClassId(null);
                }
              }}
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === Keys.ENTER) {
                  if (classId) {
                    USE_CATEGORY_KEY
                      ? categoryInputRef?.focus()
                      : fetchButtonRef.current?.focus();
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  setClassId(null);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  label={classLabel}
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
          <div className="col">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={categoryDropDown}
              openOnFocus
              forcePopupIcon
              ref={categoryRef}
              value={categoryId}
              onChange={(e, newValue) => {
                if (newValue) {
                  setCategoryId({
                    label: (newValue as responseType).label,
                    value: (newValue as responseType).value,
                    isChecked: true,
                  });
                } else {
                  setCategoryId(null);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={labelClasses.formControlRoot}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  label={categoryLabel}
                />
              )}
            />
          </div>
          <div className="col extend-feedemand__button">
            <Button
              mode="fetch"
              onClick={() => {
                setFetchFlag(!fetchFlag);
                HandleExistingItems();
              }}
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === Keys.ENTER) {
                  HandleExistingItems();
                  feeDescInputRef?.focus();
                }
              }}
              buttonref={fetchButtonRef}
            />{" "}
          </div>
        </div>

        <div className="extend-feedemand__table">
          <StyledDatagrid
            columns={columns}
            rows={rows}
            rowHeight={TABLE_ROW_HEIGHT}
            pinnedRows={pinnedRows}
            getCellClassName={getCellClassName}
            hideFooter
          />
        </div>
        <Button mode="save" onClick={handleSubmit} />
        <Button mode="clear" onClick={handleClear} />
        <Button
          mode="cancel"
          onClick={() =>
            setExtendDateForLateFeeModal(!extendDateForLateFeeModal)
          }
        />
        <MessageModal
          modalFlag={message.flag!}
          value={message.message!}
          handleClose={handleClose}
          operation={message.operation!}
        />
        <LoadingModal flag={creationLoading} />
      </div>
    </>
  );
};

export default ExtendDueDate;
