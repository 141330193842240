import React, { useContext, useEffect, useState } from "react";
import { Title } from "../../../../stories/Title/Title";
import Home from "../../Home/Index";
import Close from "../../../../images/Close.svg";
import More from "../../../../images/More.svg";
import { Button } from "../../../../stories/Button/Button";

import List from "./List";

import { TableFooter, TextField } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { TableHeaderProps } from "../../../../Types/Tables";
import Modal from "react-modal";
import {
  ApprovedStatusModal,
  StudentModalStyles,
} from "../../../../styles/ModalStyles";
import {
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
  TODAY_DATE,
} from "../../../../utils/constants";
import Eduate from "../../../../images/Eduate_Logo_image.png";
import ExcelJS from "exceljs";
import VoucherModal from "../AccountLedgerBook/Modals/VoucherModal";
import {
  DateRange,
  formatter,
  getHeaderRowStyle,
  getModifiedScrollHeight,
  toInputStandardDate,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import { useNavigate } from "react-router-dom";
import {
  BankOrCash,
  DebitOrCredit,
  ExcelAlignment,
  ExcelFont,
  ExcelFooterHeader,
  ExcelPageHeader,
  ExcelSheetsNames,
  PageFor,
  VoucherPageType,
} from "../../../../utils/Enum.types";
import useAcctVoucherDetailsByDates, {
  GetAcctVouchersEdges,
  voucherMasterDetails,
} from "../../hooks/useAcctVoucherDetailsByDates";
import { AppContext } from "../../../../context/context";
import { Cell } from "exceljs";
import { payloadTypes } from "../../../../context/reducer";
import {
  A2_CELL,
  A3_CELL,
  A4_CELL,
  ACC_HEADER_FONT,
  ADDRESS_ALIGNMENT,
  ADDRESS_FONT,
  BLOB_TYPE,
  BORDER_DATA,
  D4_CELL,
  DOWNLOAD,
  EDUATE_IMG_FORMAT,
  ELEMENT,
  HEADER_ALIGNMENT_LEFT,
  FILENAME,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  FIN_YEAR_FONT,
  FIRST_CELL,
  FIRST_INDEX,
  FOOTER_CSS,
  FROZEN_CELLS,
  G4_CELL,
  HEADER_ALIGNMENT,
  HEADER_ALIGNMENT_CENTER,
  HEADER_CSS,
  TABLE_HEADER_CSS,
} from "../../../Library/Constants";

import useToken from "../../../../customhooks/useToken";
import { voucherHeader } from "../../common/HeaderConsts";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import { VoucherQueryTypes } from "../../common/Enum.types";
import Status from "../../Voucher/MakerAndChecker/Status";
import useAcctTableJson from "../../json/useAcctTableJson";

interface Props {
  type: VoucherQueryTypes;
  pageType: PageFor;
  vtype?: VoucherPageType;
  setModalFlag?: React.Dispatch<React.SetStateAction<boolean>>;
}

const Index = ({ type, pageType, vtype, setModalFlag }: Props) => {
  const { token } = useToken();
  const { dispatch, state } = useContext(AppContext);
  const { format } = formatter;
  const navigate = useNavigate();
  const { serverDate } = useServerDateandTime();
  const [voucherModal, setVoucherModal] = useState(false);
  const [vouchers, setVouchers] = useState<GetAcctVouchersEdges[]>([]);
  const { Accounts_Table } = useAcctTableJson();
  const [voucherStatusModal, setVoucherStatusModal] = useState(false);

  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const dates = DateRange(TODAY_DATE.toString());
  const [startDate, setStartDate] = useState(dates?.firstDay!);
  const [endDate, setEndDate] = useState(dates?.lastDay!);
  const [v_no, setV_no] = useState("");
  const [partyname, setPaid_to] = useState("");
  const [transaction_no, setTransaction_no] = useState("");
  const [amount, setAmount] = useState(0);
  const [narration, setnarration] = useState("");
  const [ledgername, setLedgerName] = useState("");
  const { VoucherDetails } = useAcctVoucherDetailsByDates(
    ROWS_PER_PAGE,
    endDate,
    startDate,
    type,
    v_no,
    // deposit flag
    false,
    // bank or cash account flag
    EMPTY_STRING,
    partyname,
    transaction_no,
    narration,
    ledgername,
    amount
  );
  const { data, fetchMore, loading } = VoucherDetails;

  const { InstDetails } = useInstDetails(1);

  const totals = data?.GetAcctVouchers.edges.reduce(
    (acc, item) => {
      acc.totalAmount += item.node.v_total_cr_amt;
      return acc;
    },
    { totalAmount: 0 }
  );
  const FetchParticulars = (edge: voucherMasterDetails) => {
    switch (type) {
      case VoucherQueryTypes.PAYMENT_VOUCHERS:
        const filteredLedgers = edge.acct_voucher_details.filter(
          (d) => d.vo_cr_db === DebitOrCredit.DEBIT
        );
        if (filteredLedgers.length === 1) {
          return filteredLedgers[0].acct_ldgr.ldgr_desc;
        } else
          return (
            <ul>
              {filteredLedgers.map((d, index) => {
                return (
                  <li
                    key={index}
                    className="payment-general-register__tableblock--table--li"
                  >
                    <span>{d.acct_ldgr.ldgr_desc}</span>
                    <span>
                      {format(d.vo_db)}
                      {` `}
                      {DebitOrCredit.DEBIT}
                    </span>
                  </li>
                );
              })}
            </ul>
          );
      case VoucherQueryTypes.RECEIPTS_VOUCHERS:
        const filteredLedgersForRcpt = edge.acct_voucher_details.filter(
          (d) => d.vo_cr_db === DebitOrCredit.CREDIT
        );
        if (filteredLedgersForRcpt.length === 1) {
          return filteredLedgersForRcpt[0].acct_ldgr.ldgr_desc;
        } else
          return (
            <ul>
              {filteredLedgersForRcpt.map((d, index) => {
                return (
                  <li
                    key={index}
                    className="payment-general-register__tableblock--table--li"
                  >
                    <span>{d.acct_ldgr.ldgr_desc}</span>
                    <span>
                      {format(d.vo_db)}
                      {` `}
                      {DebitOrCredit.DEBIT}
                    </span>
                  </li>
                );
              })}
            </ul>
          );
      default:
        break;
    }
  };

  const particularsDetails = (edge: voucherMasterDetails) => {
    switch (type) {
      case VoucherQueryTypes.PAYMENT_VOUCHERS:
        const filteredLedgers = edge.acct_voucher_details.filter(
          (d) => d.vo_cr_db === DebitOrCredit.DEBIT
        );
        if (filteredLedgers.length === 1) {
          return [filteredLedgers[0].acct_ldgr.ldgr_desc];
        } else {
          return filteredLedgers.map((d) => {
            return `${d.acct_ldgr.ldgr_desc} ${format(d.vo_db)} ${
              DebitOrCredit.DEBIT
            }`;
          });
        }
      case VoucherQueryTypes.RECEIPTS_VOUCHERS:
        const filteredLedgersForRcpt = edge.acct_voucher_details.filter(
          (d) => d.vo_cr_db === DebitOrCredit.CREDIT
        );
        if (filteredLedgersForRcpt.length === 1) {
          return [filteredLedgersForRcpt[0].acct_ldgr.ldgr_desc];
        } else {
          return filteredLedgersForRcpt.map((d) => {
            return `${d.acct_ldgr.ldgr_desc} ${format(d.vo_db)} ${
              DebitOrCredit.CREDIT
            }`;
          });
        }
      default:
        return [];
    }
  };

  const voucherData = vouchers?.flatMap((edge) => {
    const particularsData = particularsDetails(edge.node);

    if (particularsData.length === 1) {
      return {
        Date: toStandardDate(edge.node.v_date),
        VoucherNo: edge.node.v_no,
        Particulars: particularsData[0],
        PaidTo: edge.node.party_name,
        BillNo: edge.node.party_bill_no,
        Narration: edge.node.v_transcation_narration,
        Amount: format(edge.node.v_total_cr_amt),
        Cash:
          type === VoucherQueryTypes.PAYMENT_VOUCHERS
            ? edge.node.acct_voucher_details.filter(
                (f) => f.vo_cr_db === DebitOrCredit.CREDIT
              )[0].acct_ldgr.ldgr_desc
            : edge.node.acct_voucher_details.filter(
                (f) => f.vo_cr_db === DebitOrCredit.DEBIT
              )[0].acct_ldgr.ldgr_desc,
        Cheque: edge.node.v_transcation_no,
      };
    } else {
      return particularsData.map((particular) => ({
        Date: toStandardDate(edge.node.v_date),
        VoucherNo: edge.node.v_no,
        Particulars: particular,
        PaidTo: edge.node.party_name,
        BillNo: edge.node.party_bill_no,
        Narration: edge.node.v_transcation_narration,
        Amount: format(edge.node.v_total_cr_amt),
        Cash:
          type === VoucherQueryTypes.PAYMENT_VOUCHERS
            ? edge.node.acct_voucher_details.filter(
                (f) => f.vo_cr_db === DebitOrCredit.CREDIT
              )[0].acct_ldgr.ldgr_desc
            : edge.node.acct_voucher_details.filter(
                (f) => f.vo_cr_db === DebitOrCredit.DEBIT
              )[0].acct_ldgr.ldgr_desc,
        Cheque: edge.node.v_transcation_no,
      }));
    }
  });

  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(ExcelPageHeader.PAYMENT_VOUCHER);

    const dataRows = voucherData
      ? voucherData?.map((item) => [
          item.Date,
          item.VoucherNo,
          item.Particulars,
          item.PaidTo,
          item.BillNo,
          item.Narration,
          item.Amount,
          item.Cash,
          item.Cheque,
        ])
      : [];
    worksheet.views = FROZEN_CELLS;

    const headerStyle = getHeaderRowStyle();
    worksheet.getRow(1).height = 33;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 23;
    worksheet.getColumn(1).width = 15;
    worksheet.getColumn(2).width = 15;
    worksheet.getColumn(3).width = 30;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 20;
    worksheet.getColumn(7).width = 20;
    worksheet.getColumn(8).width = 20;
    worksheet.getColumn(9).width = 20;

    const getBase64 = (blob: Blob, cb: (a: string) => void) => {
      const file = new File([blob], FILENAME, {
        lastModified: Date.now(),
        type: blob.type,
      });
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });

          worksheet.addImage(imageV, "I1:I3");

          worksheet.mergeCells(1, 1, 1, dataRows[0]!.length);

          const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
          mergedCell.value = InstDetails.data?.nodes[0]?.inst_name;
          mergedCell.fill = HEADER_CSS;
          mergedCell.font = headerStyle[0].font;
          mergedCell.alignment = HEADER_ALIGNMENT;
          const mergedAddress: Cell = worksheet.getCell(A2_CELL);
          mergedAddress.value = InstDetails.data?.nodes[0]?.inst_address;
          mergedAddress.fill = HEADER_CSS;
          mergedAddress.font = ADDRESS_FONT;
          mergedAddress.alignment = HEADER_ALIGNMENT;
          worksheet.mergeCells("A2:I2");

          const mergedPlace: Cell = worksheet.getCell(A3_CELL);
          mergedPlace.value =
            InstDetails.data?.nodes[0]?.inst_place +
            "-" +
            InstDetails.data?.nodes[0]?.inst_pin;

          mergedPlace.fill = HEADER_CSS;
          mergedPlace.font = ADDRESS_FONT;
          mergedPlace.alignment = ADDRESS_ALIGNMENT;
          worksheet.mergeCells("A3:I3");

          const mergedHeader: Cell = worksheet.getCell(D4_CELL);
          mergedHeader.value = ExcelPageHeader.PAYMENT_VOUCHER;
          mergedHeader.fill = FILE_NAME_CSS;
          mergedHeader.font = FILE_NAME_FONT;
          mergedHeader.alignment = HEADER_ALIGNMENT_CENTER;
          worksheet.mergeCells("D4:F4");
          const mergedDate: Cell = worksheet.getCell(G4_CELL);
          mergedDate.value = ExcelPageHeader.DATE + toStandardDate(serverDate);
          mergedDate.fill = FILE_NAME_CSS;
          mergedDate.font = FIN_YEAR_FONT;
          mergedDate.alignment = HEADER_ALIGNMENT_CENTER;
          worksheet.mergeCells("G4:I4");
          const mergedYear: Cell = worksheet.getCell(A4_CELL);
          mergedYear.value = state.ActiveFinYr
            ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
            : ExcelPageHeader.YEAR;
          mergedYear.fill = FILE_NAME_CSS;
          mergedYear.font = FIN_YEAR_FONT;
          mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
          worksheet.mergeCells("A4:C4");
          let Char = FIRST_INDEX;

          for (let i = 0; i < voucherHeader.length; i++) {
            Char = String.fromCharCode(Char.charCodeAt(0) + 1);

            const rowData: Cell = worksheet.getCell(Char + 5);
            rowData.value = voucherHeader[i];
            rowData.fill = TABLE_HEADER_CSS;
            rowData.border = BORDER_DATA;
            rowData.font = ACC_HEADER_FONT;
            rowData.alignment = { horizontal: ExcelAlignment.CENTER };
          }

          dataRows!.forEach((rowData) => {
            const row = worksheet.addRow(rowData);
            row.eachCell({ includeEmpty: true }, (cell) => {
              cell.alignment = { horizontal: ExcelAlignment.LEFT };
              cell.font = { name: ExcelFont.COURIER_NEW, size: 9 };
              row.getCell(7).font = { name: ExcelFont.CENTURY_GOTHIC, size: 9 };
              row.getCell(7).alignment = {
                horizontal: ExcelAlignment.RIGHT,
              };
            });
          });
          const footerRow = worksheet.addRow([]);
          footerRow.getCell(6).value = ExcelFooterHeader.TOTAL;
          footerRow.getCell(6).font = {
            name: ExcelFont.ARIAL,
            size: 10,
          };
          footerRow.getCell(6).alignment = { horizontal: ExcelAlignment.RIGHT };
          footerRow.getCell(7).value = format(totals?.totalAmount || 0);
          footerRow.getCell(7).alignment = {
            horizontal: ExcelAlignment.RIGHT,
          };

          worksheet.addConditionalFormatting({
            ref: `G${footerRow.number}`,
            rules: FOOTER_CSS,
          });

          workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
            const blob = new Blob([buffer], {
              type: BLOB_TYPE,
            });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement(ELEMENT);
            link.href = url;
            link.setAttribute(DOWNLOAD, ExcelSheetsNames.PAYMENT_VOUCHER);
            document.body.appendChild(link);
            link.click();
          });
        });
      });
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop + 1;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;
    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !loading) {
        fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetAcctVouchers.edges;
            const pageInfo = fetchMoreResult.GetAcctVouchers.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetAcctVouchers.edges.filter(
              ({ node: { id } }) =>
                newEdges.findIndex(
                  ({ node: { id: newId } }) => newId === id
                ) !== -1
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetAcctVouchers: {
                edges: [...vouchers, ...newEdges],
                pageInfo,
                totalCount: data?.GetAcctVouchers.totalCount!,
              },
            };
          },
        });
      }
    }
  };
  useEffect(() => {
    if (data && !loading && token) {
      const newData = data.GetAcctVouchers.edges;

      if (endCursor) {
        // If we have already fetched some data, we need to check if there
        // are any duplicates in the new data, and update their isChecked
        // property based on the existing data.
        // const filteredStudents = students.filter(
        //   (student) => !student.node.isChecked
        // );

        const updatedNewData = newData.map((newVoucher) => {
          const filteredStudent = vouchers.find(
            (voucher) => voucher.node.id === newVoucher.node.id
          );
          if (filteredStudent) {
            return {
              ...newVoucher,
              node: {
                ...newVoucher.node,
                // isChecked: filteredStudent.node.isChecked,
              },
            };
          }
          return newVoucher;
        });
        setVouchers(updatedNewData);
      } else {
        setVouchers(newData);
      }
      setEndCursor(data.GetAcctVouchers.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading, token]);
  return (
    <>
      {pageType === PageFor.GENERAL && <Home DashBoardRequired={false} />}

      <Title>
        {pageType === PageFor.GENERAL
          ? type === VoucherQueryTypes.PAYMENT_VOUCHERS
            ? "Payment Voucher Register"
            : "General Receipt Voucher Register"
          : "Total Payments"}
      </Title>

      <div
        className={
          pageType === PageFor.GENERAL
            ? "payment-general-register"
            : "payment-general-register--modal"
        }
      >
        {pageType === PageFor.GENERAL && (
          <div className="payment-general-register__tableblock">
            <List />
          </div>
        )}

        <div
          className={
            pageType === PageFor.GENERAL
              ? "payment-general-register__reportblock"
              : "payment-general-register__reportblock--modal"
          }
        >
          {pageType === PageFor.GENERAL && (
            <div className="row g-0 payment-general-register__reportblock--select-options">
              <div className="col">
                <TextField
                  fullWidth
                  label="Paid to"
                  className="payment-general-register__reportblock--textfield"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  onChange={(e) => setPaid_to(e.target.value)}
                />
              </div>
              <div className="col">
                <TextField
                  fullWidth
                  label="Cheque No."
                  className="payment-general-register__reportblock--textfield"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  onChange={(e) => setTransaction_no(e.target.value)}
                />
              </div>
              <div className="col">
                <TextField
                  fullWidth
                  label="Value"
                  id="outlined Value"
                  className="payment-general-register__reportblock--textfield"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  onChange={(e) => setAmount(Number(e.target.value))}
                />
              </div>
              <div className="col">
                <TextField
                  fullWidth
                  label="Narration"
                  id="outlined Narration"
                  className="payment-general-register__reportblock--textfield"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  onChange={(e) => setnarration(e.target.value)}
                />
              </div>

              <div className="col">
                <TextField
                  fullWidth
                  label="Through (Ledger)"
                  id="outlined Ledger"
                  className="payment-general-register__reportblock--textfield"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  onChange={(e) => setLedgerName(e.target.value)}
                />
              </div>
              <div className="col">
                <TextField
                  fullWidth
                  label="V.No"
                  id="outlined Any Search"
                  className="payment-general-register__reportblock--textfield"
                  onChange={(e) => setV_no(e.target.value)}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                />
              </div>
              <div className="col">
                <TextField
                  type="date"
                  fullWidth
                  label="From"
                  value={toInputStandardDate(startDate)}
                  onChange={(e) => setStartDate(e.target.value)}
                  className="payment-general-register__reportblock--textfield--date"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                    input: {
                      inputProps: {
                        min: state.ActiveFinYr
                          ? toInputStandardDate(state.ActiveFinYr.fin_st_date!)
                          : EMPTY_STRING,
                        max: toInputStandardDate(TODAY_DATE),
                      },
                    },
                  }}
                />
              </div>
              <div className="col">
                <TextField
                  type="date"
                  fullWidth
                  value={toInputStandardDate(endDate)}
                  onChange={(e) => setEndDate(e.target.value)}
                  label="To"
                  className="payment-general-register__reportblock--textfield--date"
                  id="outlined Cheque No."
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                    input: {
                      inputProps: {
                        min: state.ActiveFinYr
                          ? toInputStandardDate(state.ActiveFinYr.fin_st_date!)
                          : EMPTY_STRING,
                        max: toInputStandardDate(TODAY_DATE),
                      },
                    },
                  }}
                />
              </div>
            </div>
          )}

          <div
            className={
              pageType === PageFor.GENERAL
                ? "payment-general-register__reportblock--details"
                : "payment-general-register__reportblock--details--modal"
            }
          >
            <TableContainer
              className="payment-general-register__reportblock--table"
              onScroll={handleScroll}
            >
              <Table stickyHeader>
                <TableHead>
                  {/* <TableRow>
                    {type === VoucherQueryTypes.PAYMENT_VOUCHERS
                      ? Accounts_Table.Reports.PaymentVoucherRegister.Table_Headers.map(
                          (th: TableHeaderProps, index: React.Key) => {
                            return (
                              <TableCell key={index} className={th.className}>
                                {th.labelName}
                              </TableCell>
                            );
                          }
                        )
                      : Accounts_Table.Reports.GeneralReceiptVoucher.Table_Headers.map(
                          (th: TableHeaderProps, index: React.Key) => {
                            return (
                              <TableCell key={index} className={th.className}>
                                {th.labelName}
                              </TableCell>
                            );
                          }
                        )}
                  </TableRow> */}
                  <TableRow>
                    {type === VoucherQueryTypes.PAYMENT_VOUCHERS ? (
                      <>
                        {Accounts_Table.Reports.PaymentVoucherRegister.Table_Headers.map(
                          (th: TableHeaderProps, index: React.Key) => (
                            <TableCell key={index} className={th.className}>
                              {th.labelName}
                            </TableCell>
                          )
                        )}
                        {/* Additional cell for PAYMENT_VOUCHERS */}
                        <TableCell>View Status</TableCell>
                      </>
                    ) : (
                      Accounts_Table.Reports.GeneralReceiptVoucher.Table_Headers.map(
                        (th: TableHeaderProps, index: React.Key) => (
                          <TableCell key={index} className={th.className}>
                            {th.labelName}
                          </TableCell>
                        )
                      )
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {vouchers.map((data, index) => {
                    let ldgrName = "";
                    if (type === VoucherQueryTypes.PAYMENT_VOUCHERS) {
                      let bbb = data.node.acct_voucher_details.filter(
                        (c) => c.vo_cr_db === DebitOrCredit.CREDIT
                      );
                      if (bbb.length === 1) {
                        ldgrName = bbb[0].acct_ldgr.ldgr_desc;
                      } else
                        ldgrName = data.node.acct_voucher_details.filter(
                          (d) =>
                            d.acct_ldgr.ldgr_cash_bank_other !==
                            BankOrCash.OTHERS
                        )[0].acct_ldgr.ldgr_desc;
                    }

                    return (
                      <TableRow
                        key={index}
                        onClick={() => {
                          setVoucherModal(!voucherModal);

                          dispatch({
                            type: payloadTypes.SET_RECEPIT_ID,
                            payload: { receiptId: data.node.id },
                          });
                        }}
                      >
                        <TableCell
                          className="payment-general-register__tableblock--table--date"
                          id="td-center"
                        >
                          {toStandardDate(data.node.v_date)}
                        </TableCell>
                        <TableCell className="payment-general-register__tableblock--table--number">
                          {data.node.v_no}
                        </TableCell>
                        <TableCell
                          className="payment-general-register__tableblock--table--paidto"
                          onClick={() => {
                            setVoucherModal(!voucherModal);

                            dispatch({
                              type: payloadTypes.SET_RECEPIT_ID,
                              payload: { receiptId: data.node.id },
                            });
                          }}
                        >
                          {FetchParticulars(data.node)}
                        </TableCell>
                        <TableCell className="payment-general-register__tableblock--table--number">
                          {data.node.party_name}
                        </TableCell>
                        <TableCell className="payment-general-register__tableblock--table--number">
                          {data.node.party_bill_no}
                        </TableCell>
                        <TableCell>
                          {data.node.v_transcation_narration}
                        </TableCell>
                        <TableCell
                          className="payment-general-register__tableblock--table--amount"
                          id="td-right"
                        >
                          {format(data.node.v_total_cr_amt)}
                        </TableCell>
                        <TableCell>
                          {type === VoucherQueryTypes.PAYMENT_VOUCHERS
                            ? ldgrName
                            : data.node.acct_voucher_details.filter(
                                (f) => f.vo_cr_db === DebitOrCredit.DEBIT
                              )[0].acct_ldgr.ldgr_desc}
                        </TableCell>
                        <TableCell className="payment-general-register__tableblock--table--number">
                          {data.node.v_transcation_no}
                        </TableCell>
                        <TableCell>
                          {data.node.mkck_voucher_id ? (
                            <img
                              src={More}
                              alt="/"
                              onClick={() => {
                                dispatch({
                                  type: payloadTypes.SET_MK_CK_ID,
                                  payload: {
                                    mkckId: data.node.mkck_voucher_id,
                                  },
                                });
                                setVoucherStatusModal(!voucherStatusModal);
                              }}
                            />
                          ) : null}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={6}></TableCell>
                    <TableCell
                      className="payment-general-register__tableblock--table--amount totalcount"
                      id="td-right"
                    >
                      {format(totals?.totalAmount || 0)}
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
      <div className="row g-0">
        <div className="col payment-general-register__buttons">
          <Button onClick={() => downloadExcel()} mode="export" />
          {pageType === PageFor.GENERAL ? (
            <Button mode="back" onClick={() => navigate(-1)} />
          ) : (
            <Button mode="cancel" onClick={() => setModalFlag?.(false)} />
          )}
        </div>
        <div className="col payment-general-register__footer--total">
          <div className="student-total-count">
            Total Receipts :&nbsp;
            <b>{data?.GetAcctVouchers.totalCount!}</b>
          </div>
        </div>
      </div>

      {/* receipt entry modal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={voucherModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <VoucherModal setModalFlag={setVoucherModal} />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setVoucherModal(!voucherModal)}
            />
          </div>
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={voucherStatusModal}
        style={ApprovedStatusModal}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Status />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setVoucherStatusModal(!voucherStatusModal)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Index;
