import Typography from "@mui/material/Typography";

import { FormControlLabel, FormGroup, TextField } from "@mui/material";

import Input from "../../../../stories/Input/Input";
import {
  InstConfigKey,
  Operation,
  SeparatorTypes,
} from "../../../../utils/Enum.types";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GetAccessionNumberConfig,
  GetBranchAdmNumberByBranchId,
  GetInstAdmNumberByInstId,
  GetPayRollEmpIdConfig,
} from "../../../../queries/institution/configuration/query/index";

import { useNavigate, useParams } from "react-router-dom";
import {
  UpdateAccessionNumberConfig,
  UpdateBranchWiseAdmissionNumberById,
  UpdateInstWiseAdmissionNumberById,
  UpdatePayRollEmpIdConfig,
} from "../../../../queries/institution/configuration/mutation";
import { handleZeros, removeMoreSpace } from "../../../../utils/UtilFunctions";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../../../../styles/Accordion";

import React, {
  SyntheticEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Button } from "../../../../stories/Button/Button";
import Home from "../Home/Index";
import { AntSwitch } from "../../../../pages/Switch";
import { Label } from "../../../../stories/Label/Label";
import { Title } from "../../../../stories/Title/Title";
import { GetSwConfigReferenceDataByKey } from "../../../../queries/institution/configuration/query/SoftwreConfig";
import {
  SoftwareConfigList,
  SoftwareVars,
} from "../../../../Types/configtypes";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import {
  AccesionListVarsByInstId,
  BranchListVarsByInstId,
} from "../../../../Types/Student";
import useToken from "../../../../customhooks/useToken";
import {
  AUTOCOMPLETE_INPUT_CLASSNAME,
  EMPTY_OPTIONSTYPE_OBJECT,
  EMPTY_RESPONSETYPE_OBJECT,
  EMPTY_STRING,
  width,
} from "../../../../utils/constants";

import MessageModal from "../../../../pages/MessageModal";
import {
  msgType,
  optionsType,
  responseType,
} from "../../../../utils/Form.types";
import LoadingModal from "../../../../pages/LoadingModal";

import useInstMasterDataByInstId from "../../../../customhooks/useInstMasterDataByInstId";
import { Keys } from "../../../../utils/Enum.keys";
import useLibMasterDataOptions, {
  LibMasterData,
} from "../../../Library/customHooks/useLibMasterDataOptions";
import { InstIdVars } from "../../../HR/Types/masterDataTypes";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import { AppContext } from "../../../../context/context";
import {
  FormAutocomplete,
  formClasses,
} from "../../../../styles/AutocompleteStyles";

export interface AddmisonConfigDetails {
  id: number;
  adm_start_no: string;
  is_prefixed: boolean;
  prefix_str: string;
  suffix_str: string;
  separator: string;
  prefill_with_zero: boolean;
  prefill_width: number;
  adm_current_no: string;
}

export interface ConfigDetails {
  id: number;
  is_prefixed: boolean;
  prefix_str: string;
  suffix_str: string;
  separator: string;
  prefill_with_zero: boolean;
  prefill_width: number;
}

interface AccessionConFigDetails extends ConfigDetails {
  acc_start_no: string;
}
interface EmpIdConFigDetails extends ConfigDetails {
  emp_id_start_no: string;
}

export interface AddmisonConfigDataByBranchId {
  GetBranchAdmNumberByBranchId: AddmisonConfigDetails;
}
export interface AddmisonConfigDataByInstId {
  GetInstAdmNumberByInstId: AddmisonConfigDetails;
}
export interface AccessionNumberConfig {
  GetAccessionNumberConfig: AccessionConFigDetails;
}
export interface AddmissionNumberByBranchId {
  GetBranchWiseAdmissionNumber: string;
}
export interface AddmissionNumberByInstId {
  GetInstWiseAdmissionNumber: string;
}

interface PayRollEmpIdConfig extends EmpIdConFigDetails, ConfigDetails {
  emp_id_current_no: string;
}
export interface GetPayRollEmpIdConfigData {
  GetPayRollEmpIdConfig: PayRollEmpIdConfig;
}
enum HandleChangeType {
  AddmissionNumber = "A_NO",
  AccessionNumber = "AC_NO",
  EmployeeId = "EMP_ID",
}
const InstitutionNeeds = () => {
  const { InstId } = useParams();
  const { token } = useToken();
  const { user_details } = useLoggedInUserDetails();
  const { state } = useContext(AppContext);
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState<string | false>("panel8");

  const [searchClassification, setSearchClassification] = useState("");

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [admissonNumberConfigType, setAdmissionNumberConfigType] = useState(
    EMPTY_OPTIONSTYPE_OBJECT
  );
  const { branchDropDown } = useInstMasterDataByInstId();
  const [admmisonNumberData, setAdmissonNumberData] =
    useState<AddmisonConfigDetails>({
      id: 0,
      is_prefixed: false,
      prefill_width: 0,
      prefill_with_zero: false,
      separator: "",
      suffix_str: "",
      prefix_str: "",
      adm_start_no: "",
      adm_current_no: "",
    });
  const [classificationData, setclassificationData] =
    useState<AccessionConFigDetails>({
      id: 0,
      is_prefixed: false,
      prefill_width: 0,
      prefill_with_zero: false,
      separator: "",
      suffix_str: "",
      prefix_str: "",
      acc_start_no: "",
    });

  const [employeeIdData, setEmployeeIdData] = useState<EmpIdConFigDetails>({
    id: 0,
    is_prefixed: false,
    prefill_width: 0,
    prefill_with_zero: false,
    separator: "",
    suffix_str: "",
    prefix_str: "",
    emp_id_start_no: "",
  });

  //useRefs
  const bookNoRef = useRef<HTMLInputElement>(null);
  const libPrefixRef = useRef<HTMLInputElement>(null);
  const libSeparatorRef = useRef<HTMLSelectElement>(null);
  const libInputRef = libSeparatorRef.current?.getElementsByClassName(
    AUTOCOMPLETE_INPUT_CLASSNAME
  )[0] as HTMLInputElement;
  const libSuffixRef = useRef<HTMLInputElement>(null);
  const libSwitchRef = useRef<HTMLButtonElement>(null);

  const saveRef = useRef<HTMLButtonElement>(null);

  const [isPrefixed, setIsPrefixed] = useState(true);

  const separatorType = Object.values(SeparatorTypes).map((value) => {
    return value;
  });
  const { USE_BRANCH_KEY } = useInstitutionConfiguration();

  //mutations

  const [updateBranchAdmissionNumber, { loading: branchloading }] = useMutation(
    UpdateBranchWiseAdmissionNumberById,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [updateInstAdmissionNumber, { loading: creationloading }] = useMutation(
    UpdateInstWiseAdmissionNumberById,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [UpdateAccessionNumber] = useMutation(UpdateAccessionNumberConfig, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const [UpdateEmpIdConfig] = useMutation(UpdatePayRollEmpIdConfig, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const handleValueChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: HandleChangeType
  ) => {
    if (type === HandleChangeType.AddmissionNumber) {
      setAdmissonNumberData((prevValues) => ({
        ...prevValues,
        [e.target.name]: removeMoreSpace(e.target.value),
      }));
    }
    if (type === HandleChangeType.AccessionNumber) {
      setclassificationData((prevValues) => ({
        ...prevValues,
        [e.target.name]: removeMoreSpace(e.target.value),
      }));
    }
    if (type === HandleChangeType.EmployeeId) {
      setEmployeeIdData((prevValues) => ({
        ...prevValues,
        [e.target.name]: removeMoreSpace(e.target.value),
      }));
    }
  };

  const handleChange =
    (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const handleAdmissionNumberUpdate = (type: string) => {
    if (type === "B") {
      updateBranchAdmissionNumber({
        variables: {
          token,
          id: admmisonNumberData.id,
          inst_id: InstId,
          user_details,
          input: {
            adm_start_no: admmisonNumberData.adm_start_no,
            is_prefixed: admmisonNumberData.is_prefixed,
            prefix_str: admmisonNumberData.prefix_str,
            suffix_str: admmisonNumberData.suffix_str,
            separator: admmisonNumberData.separator,
            prefill_with_zero: admmisonNumberData.prefill_with_zero,
            prefill_width: admmisonNumberData.prefill_width,
            adm_current_no: admmisonNumberData.adm_current_no,
          },
        },
        refetchQueries: [
          {
            query: GetBranchAdmNumberByBranchId,
            variables: {
              inst_id: InstId!,
              token,
              branch_id: branchSelected.value,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            message: "Branch Level Configuration Made Successfully",
            flag: true,
            operation: Operation.UPDATE,
          });
        }
      });
    }
    if (type === "I") {
      updateInstAdmissionNumber({
        variables: {
          token,
          id: admmisonNumberData.id,
          inst_id: InstId,
          user_details,
          input: {
            adm_start_no: admmisonNumberData.adm_start_no,
            is_prefixed: admmisonNumberData.is_prefixed,
            prefix_str: admmisonNumberData.prefix_str,
            suffix_str: admmisonNumberData.suffix_str,
            separator: admmisonNumberData.separator,
            prefill_with_zero: admmisonNumberData.prefill_with_zero,
            prefill_width: admmisonNumberData.prefill_width,
            adm_current_no: admmisonNumberData.adm_current_no,
          },
        },
        refetchQueries: [
          {
            query: GetInstAdmNumberByInstId,
            variables: {
              inst_id: InstId!,
              token,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            message: "Institution Level Configuration Made Successfully",
            flag: true,
            operation: Operation.UPDATE,
          });
        }
      });
    }
  };
  const handleAccessionNumberUpdate = () => {
    UpdateAccessionNumber({
      variables: {
        token,
        id: classificationData.id,
        user_details,
        input: {
          acc_start_no: classificationData.acc_start_no,
          is_prefixed: classificationData.is_prefixed,
          prefix_str: classificationData.prefix_str,
          suffix_str: classificationData.suffix_str,
          separator: classificationData.separator,
          prefill_with_zero: classificationData.prefill_with_zero,
          prefill_width: classificationData.prefill_width,
        },
      },
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Book Entry List Config made Successfully",
          flag: true,
          operation: Operation.CREATE,
        });
      }
    });
  };
  const handleEmpIdConfig = () => {
    let { id, ...other_data } = employeeIdData;
    UpdateEmpIdConfig({
      variables: {
        token,
        id: PREmpId,
        inst_id: InstId,
        user_details,
        input: {
          ...other_data,
        },
      },
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Employee Config made Successfully",
          flag: true,
          operation: Operation.CREATE,
        });
      }
    });
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  // admission config type
  const [branchSelected, setBranchSelected] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );

  const [mediaSelected, setMediaSelected] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );

  const [GetBranchAdmissionDetails] = useLazyQuery<
    AddmisonConfigDataByBranchId,
    BranchListVarsByInstId
  >(GetBranchAdmNumberByBranchId, {
    variables: { inst_id: InstId!, token, branch_id: branchSelected.value },
  });
  const [GetInstAdmissionDetails] = useLazyQuery<
    AddmisonConfigDataByInstId,
    BranchListVarsByInstId
  >(GetInstAdmNumberByInstId, {
    variables: { inst_id: InstId!, token },
  });
  const [GetAccessionNumber] = useLazyQuery<
    AccessionNumberConfig,
    AccesionListVarsByInstId
  >(GetAccessionNumberConfig, {
    variables: {
      inst_id: InstId!,
      token,
      lib_classfication_id: mediaSelected.value,
    },
  });
  const [GetPayRollEmpId, { data }] = useLazyQuery<
    GetPayRollEmpIdConfigData,
    InstIdVars
  >(GetPayRollEmpIdConfig, {
    variables: {
      inst_id: InstId!,
      token,
    },
  });
  const PREmpId = data && data.GetPayRollEmpIdConfig.id;
  const [GetConfigData, { data: InstitutionAdmissionModalData }] = useLazyQuery<
    SoftwareConfigList,
    SoftwareVars
  >(GetSwConfigReferenceDataByKey, {
    variables: {
      config_key: InstConfigKey.INSTITUTION_ADMISSION_NUMBER_MODEL,
      token,
    },
  });

  const { classifications } = useLibMasterDataOptions(
    LibMasterData.CLASSIFICATION,
    searchClassification
  );

  const academicConfigType =
    // eslint-disable-next-line
    InstitutionAdmissionModalData?.GetSwConfigReferenceDataByKey.list_item_list
      .split(";")
      .map((d) => ({
        label: d.split(":")[0],
        value: d.split(":")[1],
      })) ?? [EMPTY_OPTIONSTYPE_OBJECT];

  useEffect(() => {
    if (branchSelected) {
      GetBranchAdmissionDetails().then(({ data }) => {
        if (data) {
          const {
            id,
            is_prefixed,
            prefill_width,
            prefill_with_zero,
            separator,
            suffix_str,
            prefix_str,
            adm_start_no,
            adm_current_no,
          } = data.GetBranchAdmNumberByBranchId;
          setAdmissonNumberData({
            is_prefixed,
            id,
            prefill_width,
            prefill_with_zero,
            separator,
            suffix_str,
            prefix_str,
            adm_start_no,
            adm_current_no,
          });
        }
      });
    }

    if (admissonNumberConfigType.value === "I") {
      GetInstAdmissionDetails().then(({ data }) => {
        if (data) {
          const {
            id,
            is_prefixed,
            prefill_width,
            prefill_with_zero,
            separator,
            suffix_str,
            prefix_str,
            adm_start_no,
            adm_current_no,
          } = data.GetInstAdmNumberByInstId;
          setAdmissonNumberData({
            is_prefixed,
            id,
            prefill_width,
            prefill_with_zero,
            separator,
            suffix_str,
            prefix_str,
            adm_start_no,
            adm_current_no,
          });
        }
      });
    } // eslint-disable-next-line
  }, [
    branchSelected,
    GetBranchAdmissionDetails,
    admissonNumberConfigType,
    GetInstAdmissionDetails,
  ]);

  useEffect(() => {
    if (mediaSelected) {
      if (isPrefixed) {
        GetAccessionNumber().then(({ data }) => {
          if (data) {
            const {
              id,
              is_prefixed,
              prefill_width,
              prefill_with_zero,
              separator,
              suffix_str,
              prefix_str,
              acc_start_no,
            } = data.GetAccessionNumberConfig;
            setclassificationData({
              id,
              is_prefixed,
              prefill_width,
              prefill_with_zero,
              separator,
              suffix_str,
              prefix_str,
              acc_start_no,
            });
          }
        });
      } else {
        setclassificationData({
          ...classificationData,
          separator: "",
          suffix_str: "",
          prefix_str: "",
        });
      }
    }
    // eslint-disable-next-line
  }, [mediaSelected, GetAccessionNumber, isPrefixed]);

  //EmpId Configuration
  useEffect(() => {
    GetPayRollEmpId().then(({ data }) => {
      if (data) {
        const { emp_id_current_no, ...emp_data } = data.GetPayRollEmpIdConfig;

        setEmployeeIdData(emp_data);
      }
    });

    // eslint-disable-next-line
  }, [mediaSelected, GetAccessionNumber, isPrefixed]);

  const handleClear = () => {
    setclassificationData({
      id: 0,
      is_prefixed: false,
      prefill_width: 0,
      prefill_with_zero: false,
      separator: "",
      suffix_str: "",
      prefix_str: "",
      acc_start_no: "",
    });
    setMediaSelected(EMPTY_RESPONSETYPE_OBJECT);
    setAdmissonNumberData({
      id: 0,
      is_prefixed: false,
      prefill_width: 0,
      prefill_with_zero: false,
      separator: "",
      suffix_str: "",
      prefix_str: "",
      adm_start_no: "",
      adm_current_no: "",
    });
    setAdmissionNumberConfigType(EMPTY_OPTIONSTYPE_OBJECT);
  };

  useEffect(() => {
    if (token) {
      GetConfigData();
    }
  }, [token, GetConfigData]);
  const { branchLabel } = useInstLabels();

  return (
    <>
      <div className="userneeds">
        <Home DashBoardRequired={false} />
        <Title>Configure User Needs</Title>
        <div className="row g-0 userneeds__frame">
          <div className="col h-100">
            <div className="userneeds__table--option">
              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}>
                <AccordionSummary
                  aria-controls="panel3d-content"
                  id="panel3d-header">
                  &nbsp;
                  <Typography component="span" variant="body2">
                    Admission Number Config
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography component="span" variant="body2">
                    <div className="userneeds__frame--label-grid">
                      <Label>Admission Number Type</Label>
                      <FormAutocomplete
                        className={formClasses.inputRoot}
                        options={academicConfigType!}
                        openOnFocus
                        value={admissonNumberConfigType}
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setAdmissionNumberConfigType(
                              newValue as optionsType
                            );
                          } else {
                            setAdmissionNumberConfigType(
                              EMPTY_OPTIONSTYPE_OBJECT
                            );
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === Keys.BACKSPACE) {
                            setAdmissionNumberConfigType(
                              EMPTY_OPTIONSTYPE_OBJECT
                            );
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className={formClasses.formControlRoot}
                          />
                        )}
                      />

                      {USE_BRANCH_KEY &&
                      admissonNumberConfigType.value === "B" ? (
                        <>
                          <Label>{branchLabel}</Label>
                          <FormAutocomplete
                            className={formClasses.inputRoot}
                            options={branchDropDown}
                            openOnFocus
                            value={branchSelected}
                            onChange={(e, newValue) => {
                              if (newValue) {
                                setBranchSelected(newValue as responseType);
                              } else {
                                setBranchSelected(EMPTY_RESPONSETYPE_OBJECT);
                              }
                            }}
                            onKeyDown={(e) => {
                              if (e.key === Keys.BACKSPACE) {
                                setBranchSelected(EMPTY_RESPONSETYPE_OBJECT);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                className={formClasses.formControlRoot}
                              />
                            )}
                          />
                        </>
                      ) : null}
                    </div>

                    <div className="userneeds__frame--label-grid">
                      <Label>Admission No. Start Number</Label>
                      <Input
                        value={admmisonNumberData.adm_start_no}
                        name="adm_start_no"
                        disabled
                      />
                      <Label>Current Admission No. </Label>
                      <Input
                        value={admmisonNumberData.adm_current_no}
                        name="adm_current_no"
                        onChange={(e) =>
                          handleValueChange(
                            e,
                            HandleChangeType.AddmissionNumber
                          )
                        }
                      />
                    </div>
                    <div className="row g-0">
                      <div className="col userneeds__switch-grid">
                        <FormGroup>
                          <FormControlLabel
                            label="Is Prefixed"
                            labelPlacement="start"
                            control={
                              <AntSwitch
                                checked={
                                  admmisonNumberData?.is_prefixed ? true : false
                                }
                                onClick={() => {
                                  const newConfigValue =
                                    admmisonNumberData?.is_prefixed
                                      ? false
                                      : true;
                                  admmisonNumberData &&
                                    setAdmissonNumberData({
                                      ...admmisonNumberData,
                                      prefix_str: "",
                                      separator: "",
                                      suffix_str: "",
                                      is_prefixed: newConfigValue,
                                    });
                                }}
                              />
                            }
                          />
                        </FormGroup>
                      </div>
                      <div className="col userneeds__voucher-flex">
                        {admmisonNumberData?.is_prefixed && (
                          <>
                            <div>
                              <Label>Prefix</Label>
                              <Input
                                name="prefix_str"
                                value={admmisonNumberData.prefix_str}
                                onChange={(e) =>
                                  handleValueChange(
                                    e,
                                    HandleChangeType.AddmissionNumber
                                  )
                                }
                              />
                            </div>
                            &nbsp;
                            <div>
                              <Label>Separator</Label>
                              <FormAutocomplete
                                className={formClasses.inputRoot}
                                options={separatorType}
                                openOnFocus
                                forcePopupIcon
                                value={admmisonNumberData.separator}
                                onChange={(e, newValue) => {
                                  if (newValue) {
                                    setAdmissonNumberData((prev) => ({
                                      ...prev,
                                      separator: newValue as SeparatorTypes,
                                    }));
                                  } else {
                                    setAdmissonNumberData((prev) => ({
                                      ...prev,
                                      separator: EMPTY_STRING,
                                    }));
                                  }
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === Keys.BACKSPACE) {
                                    setAdmissonNumberData((prev) => ({
                                      ...prev,
                                      separator: EMPTY_STRING,
                                    }));
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    className={formClasses.formControlRoot}
                                  />
                                )}
                              />
                            </div>
                            &nbsp;
                            <div>
                              <Label>Suffix</Label>
                              <Input
                                value={admmisonNumberData.suffix_str}
                                name="suffix_str"
                                onChange={(e) =>
                                  handleValueChange(
                                    e,
                                    HandleChangeType.AddmissionNumber
                                  )
                                }
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="row g-0">
                      <div className="col userneeds__switch-grid">
                        <FormGroup>
                          <FormControlLabel
                            label="Prefill With Zero"
                            labelPlacement="start"
                            control={
                              <AntSwitch
                                checked={
                                  admmisonNumberData.prefill_with_zero
                                    ? true
                                    : false
                                }
                                onClick={() => {
                                  const newConfigValue =
                                    admmisonNumberData?.prefill_with_zero
                                      ? false
                                      : true;
                                  admmisonNumberData &&
                                    setAdmissonNumberData({
                                      ...admmisonNumberData,
                                      prefill_with_zero: newConfigValue,
                                    });
                                }}
                              />
                            }
                          />
                        </FormGroup>
                      </div>
                      <div className="col userneeds__prefill-grid">
                        {admmisonNumberData.prefill_with_zero && (
                          <>
                            <Label>Width</Label>

                            <FormAutocomplete
                              className={formClasses.inputRoot}
                              options={width}
                              openOnFocus
                              forcePopupIcon
                              value={admmisonNumberData.prefill_width}
                              onChange={(e, newValue) => {
                                if (newValue) {
                                  setAdmissonNumberData((prev) => ({
                                    ...prev,
                                    prefill_width: (newValue as number) ?? 0,
                                  }));
                                } else {
                                  setAdmissonNumberData((prev) => ({
                                    ...prev,
                                    prefill_width: 0,
                                  }));
                                }
                              }}
                              onKeyDown={(e) => {
                                if (e.key === Keys.BACKSPACE) {
                                  setAdmissonNumberData((prev) => ({
                                    ...prev,
                                    prefill_width: 0,
                                  }));
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  className={formClasses.formControlRoot}
                                />
                              )}
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <div className="row g-0">
                      <div className="col"></div>
                      <div className="col userneeds__preview-data">
                        <Input
                          disabled
                          value={
                            admmisonNumberData.prefix_str +
                            admmisonNumberData.separator +
                            handleZeros(
                              admmisonNumberData.prefill_width.toString()
                            ) +
                            admmisonNumberData.adm_current_no +
                            admmisonNumberData.separator +
                            admmisonNumberData.suffix_str
                          }
                          placeholder="Preview"
                        />
                      </div>

                      <div>
                        <Button
                          mode="save"
                          onClick={() => {
                            handleAdmissionNumberUpdate(
                              admissonNumberConfigType.value
                            );
                            setMessage({
                              message: "",
                              flag: false,
                              operation: Operation.NONE,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}>
                <AccordionSummary
                  aria-controls="panel4d-content"
                  id="panel4d-header">
                  &nbsp;
                  <Typography component="span" variant="body2">
                    Book Entry List Config
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography component="span" variant="body2">
                    <div className="userneeds__frame--label-grid">
                      <Label>Classification Desc</Label>
                      <FormAutocomplete
                        className={formClasses.inputRoot}
                        options={classifications || []}
                        openOnFocus
                        value={mediaSelected}
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setMediaSelected(newValue as responseType);
                          } else {
                            setMediaSelected(EMPTY_RESPONSETYPE_OBJECT);
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === Keys.ENTER) {
                            bookNoRef?.current?.focus();
                          }
                          if (e.key === Keys.BACKSPACE) {
                            setMediaSelected(EMPTY_RESPONSETYPE_OBJECT);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            onChange={(e) => {
                              setSearchClassification(e.target.value);
                            }}
                            className={formClasses.formControlRoot}
                          />
                        )}
                      />
                      <Label>Book Start Number</Label>
                      <Input
                        name="acc_start_no"
                        inputRef={bookNoRef!}
                        onKeyDown={(e) => {
                          if (e.key === Keys.ENTER) {
                            libPrefixRef?.current?.focus();
                          }
                        }}
                        value={classificationData.acc_start_no}
                        onChange={(e) => {
                          setclassificationData((prevValues) => ({
                            ...prevValues,
                            acc_start_no: e.target.value!,
                          }));
                        }}
                      />
                    </div>

                    <div className="row g-0">
                      <div className="col userneeds__switch-grid">
                        <FormGroup>
                          <FormControlLabel
                            label="Is Prefixed"
                            labelPlacement="start"
                            control={
                              <AntSwitch
                                checked={isPrefixed ? true : false}
                                onClick={() => {
                                  const newConfigValue = isPrefixed
                                    ? false
                                    : true;
                                  setIsPrefixed(newConfigValue);
                                }}
                              />
                            }
                          />
                        </FormGroup>
                      </div>
                      <div className="col userneeds__voucher-flex">
                        {classificationData?.is_prefixed && (
                          <>
                            <div>
                              <Label>Prefix</Label>
                              <Input
                                name="prefix_str"
                                inputRef={libPrefixRef!}
                                onKeyDown={(e: React.KeyboardEvent) => {
                                  if (e.key === Keys.ENTER) {
                                    libInputRef?.focus();
                                  }
                                }}
                                value={classificationData.prefix_str}
                                onChange={(e) =>
                                  handleValueChange(
                                    e,
                                    HandleChangeType.AccessionNumber
                                  )
                                }
                              />
                            </div>
                            &nbsp;
                            <div>
                              <Label>Separator</Label>
                              <FormAutocomplete
                                className={formClasses.inputRoot}
                                options={separatorType ?? [""]}
                                openOnFocus
                                forcePopupIcon
                                value={classificationData.separator}
                                ref={libSeparatorRef!}
                                onChange={(e, newValue) => {
                                  if (newValue) {
                                    setclassificationData((prev) => ({
                                      ...prev,
                                      separator: newValue as SeparatorTypes,
                                    }));
                                  } else {
                                    setclassificationData((prev) => ({
                                      ...prev,
                                      separator: EMPTY_STRING,
                                    }));
                                  }
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === Keys.ENTER) {
                                    libSuffixRef?.current?.focus();
                                  }
                                  if (e.key === Keys.BACKSPACE) {
                                    setclassificationData((prev) => ({
                                      ...prev,
                                      separator: EMPTY_STRING,
                                    }));
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    className={formClasses.formControlRoot}
                                  />
                                )}
                              />
                            </div>
                            &nbsp;
                            <div>
                              <Label>Suffix</Label>
                              <Input
                                inputRef={libSuffixRef!}
                                onKeyDown={(e: React.KeyboardEvent) => {
                                  if (e.key === Keys.ENTER) {
                                    libSwitchRef?.current?.focus();
                                  }
                                }}
                                value={classificationData.suffix_str}
                                name="suffix_str"
                                onChange={(e) =>
                                  handleValueChange(
                                    e,
                                    HandleChangeType.AccessionNumber
                                  )
                                }
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="row g-0">
                      <div className="col userneeds__switch-grid">
                        <FormGroup>
                          <FormControlLabel
                            label="Prefill With Zero"
                            labelPlacement="start"
                            control={
                              <AntSwitch
                                checked={
                                  classificationData.prefill_with_zero
                                    ? true
                                    : false
                                }
                                ref={libSwitchRef}
                                onClick={() => {
                                  const newConfigValue =
                                    classificationData?.prefill_with_zero
                                      ? false
                                      : true;
                                  classificationData &&
                                    setclassificationData({
                                      ...classificationData,
                                      prefill_with_zero: newConfigValue,
                                    });
                                }}
                              />
                            }
                          />
                        </FormGroup>
                      </div>
                      <div className="col userneeds__prefill-grid">
                        {classificationData.prefill_with_zero && (
                          <>
                            <Label>Width</Label>

                            <FormAutocomplete
                              className={formClasses.inputRoot}
                              options={width}
                              openOnFocus
                              forcePopupIcon
                              value={classificationData.prefill_width}
                              onChange={(e, newValue) => {
                                if (newValue) {
                                  setclassificationData((prev) => ({
                                    ...prev,
                                    prefill_width: (newValue as number) ?? 0,
                                  }));
                                } else {
                                  setclassificationData((prev) => ({
                                    ...prev,
                                    prefill_width: 0,
                                  }));
                                }
                              }}
                              onKeyDown={(e) => {
                                if (e.key === Keys.BACKSPACE) {
                                  setclassificationData((prev) => ({
                                    ...prev,
                                    prefill_width: 0,
                                  }));
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  className={formClasses.formControlRoot}
                                />
                              )}
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <div className="row g-0">
                      <div className="col"></div>
                      <div className="col userneeds__preview-data">
                        <Input
                          disabled
                          value={
                            classificationData.prefix_str +
                            classificationData.separator +
                            handleZeros(
                              classificationData.prefill_width.toString()
                            ) +
                            classificationData.acc_start_no +
                            classificationData.separator +
                            classificationData.suffix_str
                          }
                          placeholder="Preview"
                        />
                      </div>
                      <div>
                        <Button
                          mode="save"
                          buttonref={saveRef!}
                          onClick={() => {
                            setMessage({
                              message: "",
                              flag: false,
                              operation: Operation.NONE,
                            });
                            handleAccessionNumberUpdate();
                          }}
                        />
                      </div>
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>

          <div className="col h-100">
            <div className="userneeds__table--option">
              <Accordion
                expanded={expanded === "panel9"}
                onChange={handleChange("panel9")}>
                <AccordionSummary
                  aria-controls="panel3d-content"
                  id="panel3d-header">
                  &nbsp;
                  <Typography component="span" variant="body2">
                    Employee Id Configuration
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography component="span" variant="body2">
                    <div className="userneeds__frame--label-grid">
                      <Label>Employee Id Start Number</Label>
                      <Input
                        value={employeeIdData.emp_id_start_no}
                        name="emp_id_start_no"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleValueChange(e, HandleChangeType.EmployeeId);
                        }}
                      />
                    </div>
                    <div className="row g-0">
                      <div className="col userneeds__switch-grid">
                        <FormGroup>
                          <FormControlLabel
                            label="Is Prefixed"
                            labelPlacement="start"
                            control={
                              <AntSwitch
                                checked={
                                  employeeIdData?.is_prefixed ? true : false
                                }
                                onClick={() => {
                                  const newConfigValue =
                                    employeeIdData?.is_prefixed ? false : true;
                                  employeeIdData &&
                                    setEmployeeIdData({
                                      ...employeeIdData,
                                      prefix_str: EMPTY_STRING,
                                      separator: EMPTY_STRING,
                                      suffix_str: EMPTY_STRING,
                                      is_prefixed: newConfigValue,
                                    });
                                }}
                              />
                            }
                          />
                        </FormGroup>
                      </div>
                      <div className="col userneeds__voucher-flex">
                        {employeeIdData?.is_prefixed && (
                          <>
                            <div>
                              <Label>Prefix</Label>
                              <Input
                                name="prefix_str"
                                value={employeeIdData.prefix_str}
                                onChange={(e) =>
                                  handleValueChange(
                                    e,
                                    HandleChangeType.EmployeeId
                                  )
                                }
                              />
                            </div>
                            &nbsp;
                            <div>
                              <Label>Separator</Label>
                              <FormAutocomplete
                                className={formClasses.inputRoot}
                                options={separatorType ?? [EMPTY_STRING]}
                                openOnFocus
                                forcePopupIcon
                                value={employeeIdData.separator}
                                onChange={(e, newValue) => {
                                  if (newValue) {
                                    setEmployeeIdData((prev) => ({
                                      ...prev,
                                      separator:
                                        (newValue as string) ?? EMPTY_STRING,
                                    }));
                                  } else {
                                    setEmployeeIdData((prev) => ({
                                      ...prev,
                                      separator: EMPTY_STRING,
                                    }));
                                  }
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === Keys.BACKSPACE) {
                                    setEmployeeIdData((prev) => ({
                                      ...prev,
                                      separator: EMPTY_STRING,
                                    }));
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    className={formClasses.formControlRoot}
                                  />
                                )}
                              />
                            </div>
                            &nbsp;
                            <div>
                              <Label>Suffix</Label>
                              <Input
                                value={employeeIdData.suffix_str}
                                name="suffix_str"
                                onChange={(e) =>
                                  handleValueChange(
                                    e,
                                    HandleChangeType.EmployeeId
                                  )
                                }
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="row g-0">
                      <div className="col userneeds__switch-grid">
                        <FormGroup>
                          <FormControlLabel
                            label="Prefill With Zero"
                            labelPlacement="start"
                            control={
                              <AntSwitch
                                checked={
                                  employeeIdData.prefill_with_zero
                                    ? true
                                    : false
                                }
                                onClick={() => {
                                  const newConfigValue =
                                    employeeIdData?.prefill_with_zero
                                      ? false
                                      : true;
                                  employeeIdData &&
                                    setEmployeeIdData({
                                      ...employeeIdData,
                                      prefill_with_zero: newConfigValue,
                                    });
                                }}
                              />
                            }
                          />
                        </FormGroup>
                      </div>
                      <div className="col userneeds__prefill-grid">
                        {employeeIdData.prefill_with_zero && (
                          <>
                            <Label>Width</Label>

                            <FormAutocomplete
                              className={formClasses.inputRoot}
                              options={width}
                              openOnFocus
                              forcePopupIcon
                              value={employeeIdData.prefill_width}
                              onChange={(e, newValue) => {
                                if (newValue) {
                                  setEmployeeIdData((prev) => ({
                                    ...prev,
                                    prefill_width: newValue as number,
                                  }));
                                } else {
                                  setEmployeeIdData((prev) => ({
                                    ...prev,
                                    prefill_width: 0,
                                  }));
                                }
                              }}
                              onKeyDown={(e) => {
                                if (e.key === Keys.BACKSPACE) {
                                  setEmployeeIdData((prev) => ({
                                    ...prev,
                                    prefill_width: 0,
                                  }));
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  className={formClasses.formControlRoot}
                                />
                              )}
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <div className="row g-0">
                      <div className="col"></div>
                      <div className="col userneeds__preview-data">
                        <Input
                          disabled
                          value={
                            employeeIdData.prefix_str +
                            employeeIdData.separator +
                            handleZeros(
                              employeeIdData.prefill_width.toString()
                            ) +
                            employeeIdData.emp_id_start_no +
                            employeeIdData.separator +
                            employeeIdData.suffix_str
                          }
                          placeholder="Preview"
                        />
                      </div>
                      <div>
                        <Button
                          mode="save"
                          onClick={() => {
                            handleEmpIdConfig();
                            setMessage({
                              message: "",
                              flag: false,
                              operation: Operation.NONE,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
        <div className="button-left">
          <Button mode="back" onClick={() => navigate(-1)} />
        </div>
      </div>
      <LoadingModal flag={branchloading || creationloading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};
export default InstitutionNeeds;
