import { useContext, useEffect, useState } from "react";
import { Title } from "../../../../stories/Title/Title";
import MarksEntryCompleted from "../../../../images/MarksEntryCompleted.svg";
import PendingMarksEntry from "../../../../images/PendingMarksEntry.svg";
import Close from "../../../../images/Close.svg";
import TestConducted from "../../../../images/TestConducted.svg";
import EnterMarks from "../../../../images/EnterMarks.svg";
import { Button } from "../../../../stories/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-modal";
import { DeleteLedgerModalStyles } from "../../../../styles/ModalStyles";
import ChooseSubjects from "./ChooseSubjects";
import { TextField } from "@mui/material";
import {
  CustomTooltip,
  TOOLTIP_COLORS,
} from "../../../../styles/TooltipStyles";
import {
  toInputStandardDate,
  toIsoDate,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import useTestStatus, {
  AcdTestMarksStatusQueryType,
} from "../../hooks/useTestStatus";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  FinalizeTestMarks,
  PublishTestMarks,
} from "../../queries/test/mutation";
import { msgType } from "../../../../utils/Form.types";
import {
  Direction,
  InstitutionConfigurationTypes,
  Operation,
  TeacherType,
} from "../../../../utils/Enum.types";
import useToken from "../../../../customhooks/useToken";
import useActiveAcademicYear from "../../hooks/useActiveAcademicYear";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import MessageModal from "../../../../pages/MessageModal";
import LoadingModal from "../../../../pages/LoadingModal";
import {
  GetAcdTestMarksStatus,
  GetStdTestMarks,
} from "../../queries/test/query";
import Edit from "../../../../images/ChatEdit.svg";
import View from "../../../../images/View_Test.svg";
import Finalize from "../../../../images/Finalize_Marks.svg";
import Compare from "../../../../images/Compare.svg";
import Input from "../../../../stories/Input/Input";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import { EMPTY_STRING, TODAY_DATE } from "../../../../utils/constants";
import DerivativeLogo from "../../../../images/DerivativeLogo.svg";
import useCheckAllocationType from "../../hooks/useCheckAllocationType";
import useTeacherType from "../../../../customhooks/useAccess/useTeacherType";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import useUserRightsByEmpId from "../../../UserRights/hooks/useUserRightsByEmpId";
import { GetAcdIsClassTeacher } from "../../queries/subjects/List.tsx/Index";
import {
  AcdTeacherClassQueryType,
  GetAcdTeacherClassAssociationsVars,
} from "../../types/subject/Subject";
import { AppContext } from "../../../../context/context";

const Index = () => {
  const navigate = useNavigate();

  const { InstId, entryId, subjectId } = useParams();

  const { state } = useContext(AppContext);

  const { userRights } = useUserRightsByEmpId();

  const TeacherPerType = useTeacherType();
  const { user_details } = useLoggedInUserDetails();
  const { token } = useToken();
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [CheckTeacherIsClassTeacher, { data: IsClassTeacherData }] =
    useLazyQuery<
      {
        GetAcdTeacherClassAssociations: {
          is_class_teacher: boolean;
          pr_emp_id: number;
        }[];
      },
      GetAcdTeacherClassAssociationsVars
    >(GetAcdIsClassTeacher);

  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.ENABLE_TEACHER_TO_EDIT_TEST_MARKS
  );
  const enableTestMarksEdit =
    configData.data &&
    configData.data.GetSwConfigVariables.length &&
    configData.data.GetSwConfigVariables[0].config_boolean_value
      ? true
      : false;

  const isGlobalUser =
    userRights.data && userRights.data.GetUserRightsByEmpId.is_inst_sysadmin
      ? true
      : false;
  const { statusOfSubject } = useTestStatus(
    AcdTestMarksStatusQueryType.ALL_TESTS,
    Number(entryId)!
  );

  const { entry_level } = useInstitutionConfiguration();
  const { activeAcademicYearData } = useActiveAcademicYear();

  const [FinalizeMarks, { loading }] = useMutation(FinalizeTestMarks, {
    onError: (e) =>
      setMessage({
        message: e.message,
        flag: true,
        operation: Operation.NONE,
      }),
  });
  const [PublishResults, { loading: publishLoading }] = useMutation(
    PublishTestMarks,
    {
      onError: (e) =>
        setMessage({
          message: e.message,
          flag: true,
          operation: Operation.NONE,
        }),
    }
  );
  const { flag } = useCheckAllocationType();
  const [chooseSubjects, setChooseSubjects] = useState(false);
  const handleFinalize = (test_class: number, test_class_name: string) => {
    FinalizeMarks({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id,
        date_of_finalize: toIsoDate(TODAY_DATE),
        input: {
          inst_id: InstId,
          acd_test_class_id: test_class,
          entry_id: entryId,
          entry_level,
          test_type_id: 0,
        },
        per_std_subj_allocation: flag,
      },
      refetchQueries: [
        {
          query: GetAcdTestMarksStatus,
          variables: {
            acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
            input: {
              acd_test_class_id: 0,
              entry_id: entryId,
              entry_level,
              inst_id: InstId!,
              test_type_id: 0,
            },
            token,
            query_type: AcdTestMarksStatusQueryType.ALL_TESTS,
            per_std_subj_allocation: flag,
          },
        },
        {
          query: GetStdTestMarks,
          variables: {
            acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
            admNo: EMPTY_STRING,

            after: null,
            first: null,
            input: {
              entry_id: entryId ? Number(entryId)! : 0,
              entry_level,

              ids: [Number(InstId!), test_class],
              marks_query_type: "TEST_MARKS_AT_ENTRY_LEVEL",
            },
            orderBy: { direction: Direction.ASC, field: "STD_ROLL_NO" },
            per_std_subj_allocation: flag,
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (data && data.FinalizeTestMarks) {
        setMessage({
          message: `Test: ${test_class_name}  finalized`,
          flag: true,
          operation: Operation.CREATE,
        });
      }
    });
  };
  const handlePublish = (test_class: number, test_class_name: string) => {
    PublishResults({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id,
        input: {
          inst_id: InstId,
          acd_test_class_id: test_class,
          entry_id: entryId,
          entry_level,
          test_type_id: 0,
        },
        per_std_subj_allocation: flag,
      },
      refetchQueries: [
        {
          query: GetAcdTestMarksStatus,
          variables: {
            acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
            input: {
              acd_test_class_id: 0,
              entry_id: entryId,
              entry_level,
              inst_id: InstId!,
              test_type_id: 0,
            },
            token,
            query_type: AcdTestMarksStatusQueryType.ALL_TESTS,
            per_std_subj_allocation: flag,
          },
        },
        {
          query: GetStdTestMarks,
          variables: {
            acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
            admNo: EMPTY_STRING,

            after: null,
            first: null,
            input: {
              entry_id: entryId ? Number(entryId)! : 0,
              entry_level,

              ids: [Number(InstId!), test_class],
              marks_query_type: "TEST_MARKS_AT_ENTRY_LEVEL",
            },
            orderBy: { direction: Direction.ASC, field: "STD_ROLL_NO" },
            per_std_subj_allocation: flag,
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: `Test: ${test_class_name}  Published`,
          flag: true,
          operation: Operation.CREATE,
        });
      }
    });
  };
  const handleClose = () =>
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  useEffect(() => {
    if (flag !== undefined)
      CheckTeacherIsClassTeacher({
        variables: {
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          input: {
            entry_id: Number(entryId)!,
            entry_level,
            pr_emp_id: state.empLoginId,
            query_type: AcdTeacherClassQueryType.All_TEACHERS_BY_ENTRY_ID,
            subj_master_id: 0,
          },
          inst_id: InstId!,
          per_std_subj_allocation: flag,
          token,
        },
      });
  }, [
    CheckTeacherIsClassTeacher,
    state,
    entryId,
    state.empLoginId,
    InstId,
    flag,
    entry_level,
    token,
  ]);
  const findClassTeacher = IsClassTeacherData
    ? IsClassTeacherData.GetAcdTeacherClassAssociations.filter(
        (itm) => itm.pr_emp_id === state.empLoginId
      )
    : [];
  const isClassTeacher = IsClassTeacherData
    ? findClassTeacher.length && findClassTeacher[0].is_class_teacher
      ? true
      : false
    : false;

  return (
    <>
      <div className="academics__exam">
        <div className="academics__channel--title">
          <Title>Test Overview</Title>
        </div>
        <div className="academics__exam--constants">
          <div className="academics__exam--constants--cards">
            <Title variant="subtitle1">
              <img src={TestConducted} alt="/" />
              Test Conducted
            </Title>
            <span className="academics__exam--constants--cards--test-conducted">
              {statusOfSubject.data
                ? statusOfSubject.data.GetAcdTestMarksStatus.length
                : 0}
            </span>
          </div>
          <div className="academics__exam--constants--cards">
            <Title variant="subtitle1">
              <img src={MarksEntryCompleted} alt="/" />
              Marks Entry Completed
            </Title>
            <span className="academics__exam--constants--cards--me-completed">
              {statusOfSubject.data
                ? statusOfSubject.data.GetAcdTestMarksStatus.filter(
                    (edge) => edge.is_marks_entry_completed
                  ).length
                : 0}
            </span>
          </div>
          <div className="academics__exam--constants--cards">
            <Title variant="subtitle1">
              <img src={PendingMarksEntry} alt="/" />
              Pending Marks Entry
            </Title>
            <span className="academics__exam--constants--cards--pending-me">
              {statusOfSubject.data
                ? statusOfSubject.data.GetAcdTestMarksStatus.length! -
                  statusOfSubject.data.GetAcdTestMarksStatus.filter(
                    (edge) => edge.is_marks_entry_completed
                  ).length!
                : 0}
            </span>
          </div>
        </div>
        <div className="row g-0 academics__exam--filters">
          <div className="col-2">
            <Input id="search" placeholder="Search..." />
          </div>
          <div className="col"></div>
          <div className="col-4 academics__exam--filters--flex">
            <TextField
              className="academics__exam--filters--textfield"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
                input: {
                  inputProps: {
                    min: activeAcademicYearData.data
                      ? toInputStandardDate(
                          activeAcademicYearData.data.GetAcdYrActiveByInstId
                            .acd_st_date
                        )
                      : EMPTY_STRING,
                    max: activeAcademicYearData.data
                      ? toInputStandardDate(
                          activeAcademicYearData.data.GetAcdYrActiveByInstId
                            .acd_end_date
                        )
                      : EMPTY_STRING,
                  },
                },
              }}
              label="Start Date"
              type="date"
            />
            <img src={Compare} alt="" />

            <TextField
              className="academics__exam--filters--textfield"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              label="End Date"
              type="date"
            />
          </div>
        </div>
        <div className="academics__exam--details">
          <div className="academics__exam--details--li">
            {statusOfSubject.data
              ? statusOfSubject.data.GetAcdTestMarksStatus.filter(
                  (itm) =>
                    (itm.is_non_academic && (isClassTeacher || isGlobalUser)) ||
                    itm.is_non_academic === false
                ).map((edge, index) => {
                  const isDerivative =
                    edge.test_class_details.test_name_details.is_marks_derived;
                  const isNonAcademic = edge.is_non_academic;

                  return (
                    <>
                      <div
                        className={`academics__exam--details--cards${
                          isDerivative && isNonAcademic
                            ? ""
                            : edge.is_marks_entry_completed &&
                              edge.is_report_generated
                            ? "--bg-green"
                            : "--bg-red"
                        }`}
                        key={index}>
                        <b className="academics__exam--details--cards--title">
                          {edge.test_class_details.test_name_details.test_name}
                        </b>
                        {isNonAcademic ? null : (
                          <>
                            <span className="academics__exam--details--cards--sub-code">
                              {isDerivative ? (
                                edge.test_class_details.derived_method
                              ) : (
                                <>
                                  (
                                  {
                                    edge.test_class_details.test_name_details
                                      .test_type_details.test_type_name
                                  }
                                  )
                                </>
                              )}
                            </span>
                          </>
                        )}

                        {edge.test_class_details.test_name_details
                          .is_marks_derived ? (
                          <>
                            <img
                              src={DerivativeLogo}
                              alt="/"
                              className="derivative-logo"
                            />
                            <span className="academics__exam--details--cards--derivative">
                              Derivative
                            </span>
                          </>
                        ) : edge.is_non_academic ? (
                          <>
                            <span className="academics__exam--details--cards--nonacademic">
                              Non Academic
                            </span>
                          </>
                        ) : null}
                        <div className="academics__exam--details--cards--flex">
                          <div className="academics__exam--details--cards--subjects">
                            <span className="academics__exam--details--cards--subjects--total">
                              {isDerivative
                                ? "Total Test Selected"
                                : "Test Conducted Subjects"}
                            </span>
                            <span className="academics__exam--details--cards--subjects--number">
                              {isDerivative
                                ? edge.test_class_details.derived_test_count
                                : edge.no_subjects}
                            </span>
                          </div>
                          <div className="academics__exam--details--cards--marks">
                            <span className="academics__exam--details--cards--subjects--total">
                              {isDerivative
                                ? "Scored Scale To"
                                : "Marks Entry Completed"}
                            </span>
                            <span className="academics__exam--details--cards--marks--number">
                              {isDerivative
                                ? edge.test_class_details.out_of
                                : "-"}
                            </span>
                          </div>
                        </div>
                        {isDerivative ? (
                          <div className="academics__exam--details--cards--button">
                            <Button
                              onClick={() =>
                                navigate(
                                  `/${InstId}/academics/dailyactivities/${edge.acd_test_class_id}/exams/${entryId}/derivatives`
                                )
                              }
                              mode="view">
                              &nbsp;Marks
                            </Button>
                          </div>
                        ) : (
                          <>
                            <div className="academics__exam--details--cards--flex">
                              <div className="academics__exam--details--cards--date">
                                <span className="academics__exam--details--cards--subjects--total">
                                  Start date
                                </span>
                                <span className="academics__exam--details--cards--subjects--number">
                                  {toStandardDate(
                                    edge.test_class_details.test_start_date
                                  )}
                                </span>
                              </div>
                              <img src={Compare} alt="/" />
                              <div className="academics__exam--details--cards--date">
                                <span className="academics__exam--details--cards--subjects--total">
                                  End Date
                                </span>
                                <span className="academics__exam--details--cards--subjects--number">
                                  {toStandardDate(
                                    edge.test_class_details.test_end_date
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="academics__exam--details--cards--button">
                              <div>
                                <span className="academics__exam--details--cards--subjects--total">
                                  {edge.is_report_generated
                                    ? ""
                                    : "Pending Marks Entry"}
                                </span>
                                <span className="academics__exam--details--cards--subjects--number font-red">
                                  -
                                </span>
                              </div>
                            </div>
                            {edge.is_marks_entry_started ? (
                              <>
                                <div className="academics__exam--details--cards--icons">
                                  <CustomTooltip
                                    title="View Details"
                                    arrow
                                    placement="top"
                                    slotProps={{
                                      tooltip: {
                                        sx: {
                                          bgcolor: TOOLTIP_COLORS.VARIABLE,
                                          "& .MuiTooltip-arrow": {
                                            color: TOOLTIP_COLORS.VARIABLE,
                                          },
                                        },
                                      },
                                    }}>
                                    <img
                                      onClick={() => {
                                        navigate(
                                          `/${InstId}/academics/dailyactivities/${edge.acd_test_class_id}/exams/${entryId}/viewmarks`
                                        );
                                      }}
                                      src={View}
                                      alt="/"
                                    />
                                  </CustomTooltip>
                                  {(enableTestMarksEdit &&
                                    TeacherPerType ===
                                      TeacherType.ONLY_TEACHER &&
                                    edge.is_report_generated === false) ||
                                  isGlobalUser ? (
                                    <CustomTooltip
                                      title="Edit Details"
                                      arrow
                                      placement="top"
                                      slotProps={{
                                        tooltip: {
                                          sx: {
                                            bgcolor: TOOLTIP_COLORS.VARIABLE,
                                            "& .MuiTooltip-arrow": {
                                              color: TOOLTIP_COLORS.VARIABLE,
                                            },
                                          },
                                        },
                                      }}>
                                      <img
                                        onClick={() => {
                                          navigate(
                                            `/${InstId}/academics/dailyactivities/${edge.acd_test_class_id}/exams/${entryId}/updatemarks`
                                          );
                                        }}
                                        src={Edit}
                                        alt="/"
                                      />
                                    </CustomTooltip>
                                  ) : null}
                                </div>
                                <div className="academics__exam--details--cards--button">
                                  {userRights.data &&
                                  userRights.data.GetUserRightsByEmpId
                                    .is_inst_sysadmin &&
                                  edge.is_report_generated &&
                                  !edge.publish_results ? (
                                    <Button
                                      onClick={() =>
                                        handlePublish(
                                          edge.acd_test_class_id,
                                          edge.test_class_details
                                            .test_name_details.test_name
                                        )
                                      }>
                                      <img src={Finalize} alt="/" />
                                      Publish Marks
                                    </Button>
                                  ) : null}
                                  {!edge.is_report_generated ? (
                                    edge.is_marks_entry_completed ? (
                                      <>
                                        {edge.is_test_remarks_added ? (
                                          userRights.data &&
                                          userRights.data.GetUserRightsByEmpId
                                            .is_inst_sysadmin ? (
                                            <Button
                                              onClick={() =>
                                                handleFinalize(
                                                  edge.acd_test_class_id,
                                                  edge.test_class_details
                                                    .test_name_details.test_name
                                                )
                                              }>
                                              <img src={Finalize} alt="/" />
                                              Finalize Marks
                                            </Button>
                                          ) : null
                                        ) : edge.test_class_details
                                            .test_name_details
                                            .add_test_remarks &&
                                          isClassTeacher ? (
                                          <Button
                                            onClick={() =>
                                              navigate(
                                                `/${InstId}/academics/dailyactivities/${edge.acd_test_class_id}/exams/${entryId}/updateremarks`
                                              )
                                            }>
                                            <img src={Finalize} alt="/" />
                                            Enter Remarks
                                          </Button>
                                        ) : (
                                          <>
                                            {userRights.data &&
                                            userRights.data.GetUserRightsByEmpId
                                              .is_inst_sysadmin ? (
                                              <Button
                                                onClick={() =>
                                                  handleFinalize(
                                                    edge.acd_test_class_id,
                                                    edge.test_class_details
                                                      .test_name_details
                                                      .test_name
                                                  )
                                                }>
                                                <img src={Finalize} alt="/" />
                                                Finalize Marks
                                              </Button>
                                            ) : null}
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        <Button
                                          onClick={() =>
                                            navigate(
                                              `/${InstId}/academics/dailyactivities/${edge.acd_test_class_id}/${subjectId}/exams/${entryId}/entermarks`
                                            )
                                          }>
                                          <img src={EnterMarks} alt="/" />
                                          Enter Marks
                                        </Button>
                                        {edge.is_test_remarks_added === false &&
                                        edge.test_class_details
                                          .test_name_details.add_test_remarks &&
                                        isClassTeacher ? (
                                          <Button
                                            onClick={() =>
                                              navigate(
                                                `/${InstId}/academics/dailyactivities/${edge.acd_test_class_id}/exams/${entryId}/updateremarks`
                                              )
                                            }>
                                            <img src={EnterMarks} alt="/" />
                                            Enter Remarks
                                          </Button>
                                        ) : null}
                                      </>
                                    )
                                  ) : null}
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="academics__exam--details--cards--icons"></div>
                                <div className="academics__exam--details--cards--button">
                                  <Button
                                    onClick={() =>
                                      navigate(
                                        `/${InstId}/academics/dailyactivities/${edge.acd_test_class_id}/${subjectId}/exams/${entryId}/entermarks`
                                      )
                                    }>
                                    <img src={EnterMarks} alt="/" />
                                    Enter Marks
                                  </Button>
                                  {edge.is_test_remarks_added === false &&
                                  edge.test_class_details.test_name_details
                                    .add_test_remarks &&
                                  isClassTeacher ? (
                                    <Button
                                      onClick={() =>
                                        navigate(
                                          `/${InstId}/academics/dailyactivities/${edge.acd_test_class_id}/exams/${entryId}/updateremarks`
                                        )
                                      }>
                                      <img src={EnterMarks} alt="/" />
                                      Enter Remarks
                                    </Button>
                                  ) : null}
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  );
                })
              : null}
          </div>
        </div>
        <Button mode="back" onClick={() => navigate(-1)} />
        <Modal
          shouldCloseOnOverlayClick={true}
          isOpen={chooseSubjects}
          style={DeleteLedgerModalStyles}
          ariaHideApp={false}>
          <div className="modal-flex h-100">
            <div className="modal-flex__data h-100">
              <ChooseSubjects setModalFlag={setChooseSubjects} />
            </div>
            <div className="modal-flex__image">
              <img
                src={Close}
                alt="/"
                onClick={() => setChooseSubjects(!chooseSubjects)}
              />
            </div>
          </div>
        </Modal>
      </div>
      <LoadingModal flag={loading || publishLoading} />
      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default Index;
