import React from "react";
import useSwConfigData from "../../../../../customhooks/useSwConfigData";
import {
  InstitutionPrintConfig,
  NonDemandPrintReceipts,
} from "../../../Enum.types";
import { GlobalPageConfigData } from "../../../../../Types/configtypes";
export type ReceiptSettings = {
  printInstOrBranchName: string;
  nonDemandReceiptInstOrBranchName: string;

  nonDemandReceiptAdmissionNumber: boolean;
  nonDemandReceiptCustomerName: boolean;
  nonDemandReceiptInstLogo: boolean;
  nonDemandReceiptInstName: boolean;
  nonDemandReceiptInstAddress: boolean;
  nonDemandReceiptHeader: boolean;
  nonDemandReceiptFooter: boolean;
  nonDemandReceiptFooter2: string;
  printAdmissionNumber: boolean;
  printCashBankDetails: boolean;
  printClassName: boolean;
  printCustomerName: boolean;
  printFatherName: boolean;
  printFeeBalance: boolean;
  printInstLogo: boolean;
  printInstName: boolean;
  printInstAddress: boolean;
  printNarrations: boolean;
  printRegisterNumber: boolean;
  printSectionName: boolean;
  printReceiptHeader: boolean;
  printReceiptFooter: boolean;
  printTransactionNumber: boolean;
  printReceiptFooter2: boolean;
  printNumberCopies: boolean;
  printCommonTitle: boolean;
  printInstStdLabel: boolean;
  printReceiptTopMargin: boolean;
  printReceiptBottomMargin: boolean;
  printReceiptLeftMargin: boolean;
  printReceiptRightMargin: boolean;
  printReceiptGeneratedBy: boolean;
  printSimplifiedByMyEduate: boolean;
  printReceiptTitle: boolean;
  nonDemandReceiptCashBankDetails: boolean;
  nonDemandReceiptClassName: boolean;
  nonDemandReceiptFatherName: boolean;
  nonDemandReceiptNarrations: boolean;
  nonDemandReceiptRegisterNumber: boolean;
  nonDemandReceiptSectionName: boolean;
  nonDemandReceiptTransactionNumber: boolean;
  nonDemandReceiptNumberCopies: number;
  nonDemandReceiptTopMargin: number;
  nonDemandReceiptBottomMargin: number;
  nonDemandReceiptLeftMargin: number;
  nonDemandReceiptRightMargin: number;
  nonDemandReceiptGeneratedBy: boolean;
  nonDemandReceiptInstStdLabel: boolean;
  nonDemandSimplifiedByMyEduate: boolean;
  nonDemandReceiptTitle: string;
};

const useInstDetailsConfigs = ():ReceiptSettings => {
  const { configData } = useSwConfigData([
    InstitutionPrintConfig.PRINT_INST_OR_BRANCH_NAME,
    NonDemandPrintReceipts.NON_DEMAND_RECEIPT_ADMISSION_NUMBER,
    NonDemandPrintReceipts.NON_DEMAND_RECEIPT_CUSTOMER_NAME,
    NonDemandPrintReceipts.NON_DEMAND_RECEIPT_INST_LOGO,
    NonDemandPrintReceipts.NON_DEMAND_RECEIPT_INST_NAME,
    NonDemandPrintReceipts.NON_DEMAND_RECEIPT_INST_ADDRESS,
    NonDemandPrintReceipts.NON_DEMAND_RECEIPT_HEADER,
    NonDemandPrintReceipts.NON_DEMAND_RECEIPT_FOOTER,
    NonDemandPrintReceipts.NON_DEMAND_RECEIPT_FOOTER_2,
    NonDemandPrintReceipts.NON_DEMAND_RECEIPT_INST_OR_BRANCH_NAME,
    InstitutionPrintConfig.PRINT_ADMISSION_NUMBER,
    InstitutionPrintConfig.PRINT_CASH_BANK_DETAILS,
    InstitutionPrintConfig.PRINT_CLASS_NAME,
    InstitutionPrintConfig.PRINT_CUSTOMER_NAME,
    InstitutionPrintConfig.PRINT_FATHER_NAME,
    InstitutionPrintConfig.PRINT_FEE_BALANCE,
    InstitutionPrintConfig.PRINT_INST_LOGO,
    InstitutionPrintConfig.PRINT_INST_NAME,
    InstitutionPrintConfig.PRINT_INST_ADDRESS,
    InstitutionPrintConfig.PRINT_NARRATIONS,
    InstitutionPrintConfig.PRINT_REGISTER_NUMBER,
    InstitutionPrintConfig.PRINT_SECTION_NAME,
    InstitutionPrintConfig.PRINT_RECEIPT_HEADER,
    InstitutionPrintConfig.PRINT_RECEIPT_FOOTER,
    InstitutionPrintConfig.PRINT_TRANSACTION_NUMBER,
    InstitutionPrintConfig.PRINT_RECEIPT_FOOTER_2,
    InstitutionPrintConfig.PRINT_NUMBER_COPIES,
    InstitutionPrintConfig.PRINT_COMMON_TITLE,
    InstitutionPrintConfig.PRINT_INST_STD_LABEL,
    InstitutionPrintConfig.PRINT_RECEIPT_TOP_MARGIN,
    InstitutionPrintConfig.PRINT_RECEIPT_BOTTOM_MARGIN,
    InstitutionPrintConfig.PRINT_RECEIPT_LEFT_MARGIN,
    InstitutionPrintConfig.PRINT_RECEIPT_RIGHT_MARGIN,
    InstitutionPrintConfig.PRINT_RECEIPT_GENERATED_BY,
    InstitutionPrintConfig.PRINT_SIMPLIPIED_BY_MYEDUATE,
    InstitutionPrintConfig.PRINT_RECEIPT_TITLE,

    //Check
    NonDemandPrintReceipts.NON_DEMAND_RECEIPT_CASH_BANK_DETAILS,
    NonDemandPrintReceipts.NON_DEMAND_RECEIPT_CLASS_NAME,
    NonDemandPrintReceipts.NON_DEMAND_RECEIPT_FATHER_NAME,
    NonDemandPrintReceipts.NON_DEMAND_RECEIPT_NARRATIONS,
    NonDemandPrintReceipts.NON_DEMAND_RECEIPT_REGISTER_NUMBER,
    NonDemandPrintReceipts.NON_DEMAND_RECEIPT_SECTION_NAME,
    NonDemandPrintReceipts.NON_DEMAND_RECEIPT_TRANSACTION_NUMBER,
    NonDemandPrintReceipts.NON_DEMAND_RECEIPT_NUMBER_COPIES,
    NonDemandPrintReceipts.NON_DEMAND_RECEIPT_TOP_MARGIN,
    NonDemandPrintReceipts.NON_DEMAND_RECEIPT_BOTTOM_MARGIN,
    NonDemandPrintReceipts.NON_DEMAND_RECEIPT_LEFT_MARGIN,
    NonDemandPrintReceipts.NON_DEMAND_RECEIPT_RIGHT_MARGIN,
    NonDemandPrintReceipts.NON_DEMAND_RECEIPT_GENERATED_BY,
    NonDemandPrintReceipts.NON_DEMAND_RECEIPT_INST_STD_LABEL,
    NonDemandPrintReceipts.NON_DEMAND_SIMPLIPIED_BY_MYEDUATE,
    NonDemandPrintReceipts.NON_DEMAND_RECEIPT_TITLE,
  ]);
  const filterDataByConfigKey = (data: GlobalPageConfigData[]) : ReceiptSettings => {
    // Initialize an object to hold the results
    const result: ReceiptSettings = {
      printInstOrBranchName: "",
      nonDemandReceiptInstOrBranchName : "",
      nonDemandReceiptAdmissionNumber: false,
      nonDemandReceiptCustomerName: false,
      nonDemandReceiptInstLogo: false,
      nonDemandReceiptInstName: false,
      nonDemandReceiptInstAddress: false,
      nonDemandReceiptHeader: false,
      nonDemandReceiptFooter: false,
      nonDemandReceiptFooter2: "",
      printAdmissionNumber: false,
      printCashBankDetails: false,
      printClassName: false,
      printCustomerName: false,
      printFatherName: false,
      printFeeBalance: false,
      printInstLogo: false,
      printInstName: false,
      printInstAddress: false,
      printNarrations: false,
      printRegisterNumber: false,
      printSectionName: false,
      printReceiptHeader: false,
      printReceiptFooter: false,
      printTransactionNumber: false,
      printReceiptFooter2: false,
      printNumberCopies: false,
      printCommonTitle: false,
      printInstStdLabel: false,
      printReceiptTopMargin: false,
      printReceiptBottomMargin: false,
      printReceiptLeftMargin: false,
      printReceiptRightMargin: false,
      printReceiptGeneratedBy: false,
      printSimplifiedByMyEduate: false,
      printReceiptTitle: false,
      nonDemandReceiptCashBankDetails: false,
      nonDemandReceiptClassName: false,
      nonDemandReceiptFatherName: false,
      nonDemandReceiptNarrations: false,
      nonDemandReceiptRegisterNumber: false,
      nonDemandReceiptSectionName: false,
      nonDemandReceiptTransactionNumber: false,
      nonDemandReceiptNumberCopies: 0,
      nonDemandReceiptTopMargin: 0,
      nonDemandReceiptBottomMargin: 0,
      nonDemandReceiptLeftMargin: 0,
      nonDemandReceiptRightMargin: 0,
      nonDemandReceiptGeneratedBy: false,
      nonDemandReceiptInstStdLabel: false,
      nonDemandSimplifiedByMyEduate: false,
      nonDemandReceiptTitle: "",
    };

    if (data) {
      data.forEach((item) => {
        const { config_key, config_boolean_value,config_integer_value, config_string_value } = item;

        switch (config_key) {
          case InstitutionPrintConfig.PRINT_INST_OR_BRANCH_NAME:
            result.printInstOrBranchName = config_string_value;
            break;
          case NonDemandPrintReceipts.NON_DEMAND_RECEIPT_INST_OR_BRANCH_NAME:
            result.nonDemandReceiptInstOrBranchName = config_string_value;
            break;
          case NonDemandPrintReceipts.NON_DEMAND_RECEIPT_ADMISSION_NUMBER:
            result.nonDemandReceiptAdmissionNumber = config_boolean_value;
            break;
          case NonDemandPrintReceipts.NON_DEMAND_RECEIPT_CUSTOMER_NAME:
            result.nonDemandReceiptCustomerName = config_boolean_value;
            break;
          case NonDemandPrintReceipts.NON_DEMAND_RECEIPT_INST_LOGO:
            result.nonDemandReceiptInstLogo = config_boolean_value;
            break;
          case NonDemandPrintReceipts.NON_DEMAND_RECEIPT_INST_NAME:
            result.nonDemandReceiptInstName = config_boolean_value;
            break;
          case NonDemandPrintReceipts.NON_DEMAND_RECEIPT_INST_ADDRESS:
            result.nonDemandReceiptInstAddress = config_boolean_value;
            break;
          case NonDemandPrintReceipts.NON_DEMAND_RECEIPT_HEADER:
            result.nonDemandReceiptHeader = config_boolean_value;
            break;
          case NonDemandPrintReceipts.NON_DEMAND_RECEIPT_FOOTER:
            result.nonDemandReceiptFooter = config_boolean_value;
            break;
          case NonDemandPrintReceipts.NON_DEMAND_RECEIPT_FOOTER_2:
            result.nonDemandReceiptFooter2 = config_string_value;
            break;
          case InstitutionPrintConfig.PRINT_ADMISSION_NUMBER:
            result.printAdmissionNumber = config_boolean_value;
            break;
          case InstitutionPrintConfig.PRINT_CASH_BANK_DETAILS:
            result.printCashBankDetails = config_boolean_value;
            break;
          case InstitutionPrintConfig.PRINT_CLASS_NAME:
            result.printClassName = config_boolean_value;
            break;
          case InstitutionPrintConfig.PRINT_CUSTOMER_NAME:
            result.printCustomerName = config_boolean_value;
            break;
          case InstitutionPrintConfig.PRINT_FATHER_NAME:
            result.printFatherName = config_boolean_value;
            break;
          case InstitutionPrintConfig.PRINT_FEE_BALANCE:
            result.printFeeBalance = config_boolean_value;
            break;
          case InstitutionPrintConfig.PRINT_INST_LOGO:
            result.printInstLogo = config_boolean_value;
            break;
          case InstitutionPrintConfig.PRINT_INST_NAME:
            result.printInstName = config_boolean_value;
            break;
          case InstitutionPrintConfig.PRINT_INST_ADDRESS:
            result.printInstAddress = config_boolean_value;
            break;
          case InstitutionPrintConfig.PRINT_NARRATIONS:
            result.printNarrations = config_boolean_value;
            break;
          case InstitutionPrintConfig.PRINT_REGISTER_NUMBER:
            result.printRegisterNumber = config_boolean_value;
            break;
          case InstitutionPrintConfig.PRINT_SECTION_NAME:
            result.printSectionName = config_boolean_value;
            break;
          case InstitutionPrintConfig.PRINT_RECEIPT_HEADER:
            result.printReceiptHeader = config_boolean_value;
            break;
          case InstitutionPrintConfig.PRINT_RECEIPT_FOOTER:
            result.printReceiptFooter = config_boolean_value;
            break;
          case InstitutionPrintConfig.PRINT_TRANSACTION_NUMBER:
            result.printTransactionNumber = config_boolean_value;
            break;
          case InstitutionPrintConfig.PRINT_RECEIPT_FOOTER_2:
            result.printReceiptFooter2 = config_boolean_value;
            break;
          case InstitutionPrintConfig.PRINT_NUMBER_COPIES:
            result.printNumberCopies = config_boolean_value;
            break;
          case InstitutionPrintConfig.PRINT_COMMON_TITLE:
            result.printCommonTitle = config_boolean_value;
            break;
          case InstitutionPrintConfig.PRINT_INST_STD_LABEL:
            result.printInstStdLabel = config_boolean_value;
            break;
          case InstitutionPrintConfig.PRINT_RECEIPT_TOP_MARGIN:
            result.printReceiptTopMargin = config_boolean_value;
            break;
          case InstitutionPrintConfig.PRINT_RECEIPT_BOTTOM_MARGIN:
            result.printReceiptBottomMargin = config_boolean_value;
            break;
          case InstitutionPrintConfig.PRINT_RECEIPT_LEFT_MARGIN:
            result.printReceiptLeftMargin = config_boolean_value;
            break;
          case InstitutionPrintConfig.PRINT_RECEIPT_RIGHT_MARGIN:
            result.printReceiptRightMargin = config_boolean_value;
            break;
          case InstitutionPrintConfig.PRINT_RECEIPT_GENERATED_BY:
            result.printReceiptGeneratedBy = config_boolean_value;
            break;
          case InstitutionPrintConfig.PRINT_SIMPLIPIED_BY_MYEDUATE:
            result.printSimplifiedByMyEduate = config_boolean_value;
            break;
          case InstitutionPrintConfig.PRINT_RECEIPT_TITLE:
            result.printReceiptTitle = config_boolean_value;
            break;
          case NonDemandPrintReceipts.NON_DEMAND_RECEIPT_CASH_BANK_DETAILS:
            result.nonDemandReceiptCashBankDetails = config_boolean_value;
            break;
          case NonDemandPrintReceipts.NON_DEMAND_RECEIPT_CLASS_NAME:
            result.nonDemandReceiptClassName = config_boolean_value;
            break;
          case NonDemandPrintReceipts.NON_DEMAND_RECEIPT_FATHER_NAME:
            result.nonDemandReceiptFatherName = config_boolean_value;
            break;
          case NonDemandPrintReceipts.NON_DEMAND_RECEIPT_NARRATIONS:
            result.nonDemandReceiptNarrations = config_boolean_value;
            break;
          case NonDemandPrintReceipts.NON_DEMAND_RECEIPT_REGISTER_NUMBER:
            result.nonDemandReceiptRegisterNumber = config_boolean_value;
            break;
          case NonDemandPrintReceipts.NON_DEMAND_RECEIPT_SECTION_NAME:
            result.nonDemandReceiptSectionName = config_boolean_value;
            break;
          case NonDemandPrintReceipts.NON_DEMAND_RECEIPT_TRANSACTION_NUMBER:
            result.nonDemandReceiptTransactionNumber = config_boolean_value;
            break;
          case NonDemandPrintReceipts.NON_DEMAND_RECEIPT_NUMBER_COPIES:
            result.nonDemandReceiptNumberCopies = Number(config_string_value);
            break;
          case NonDemandPrintReceipts.NON_DEMAND_RECEIPT_TOP_MARGIN:
            result.nonDemandReceiptTopMargin = config_integer_value;
            break;
          case NonDemandPrintReceipts.NON_DEMAND_RECEIPT_BOTTOM_MARGIN:
            result.nonDemandReceiptBottomMargin = config_integer_value;
            break;
          case NonDemandPrintReceipts.NON_DEMAND_RECEIPT_LEFT_MARGIN:
            result.nonDemandReceiptLeftMargin = config_integer_value;
            break;
          case NonDemandPrintReceipts.NON_DEMAND_RECEIPT_RIGHT_MARGIN:
            result.nonDemandReceiptRightMargin = config_integer_value;
            break;
          case NonDemandPrintReceipts.NON_DEMAND_RECEIPT_GENERATED_BY:
            result.nonDemandReceiptGeneratedBy = config_boolean_value;
            break;
          case NonDemandPrintReceipts.NON_DEMAND_RECEIPT_INST_STD_LABEL:
            result.nonDemandReceiptInstStdLabel = config_boolean_value;
            break;
          case NonDemandPrintReceipts.NON_DEMAND_SIMPLIPIED_BY_MYEDUATE:
            result.nonDemandSimplifiedByMyEduate = config_boolean_value;
            break;
          case NonDemandPrintReceipts.NON_DEMAND_RECEIPT_TITLE:
            result.nonDemandReceiptTitle = config_string_value;
            break;

          default:
            break;
        }
      });
    }

    return result;
  };

  const configs = filterDataByConfigKey(
    configData.data ? configData.data.GetSwConfigVariables : []
  );

  return configs;
};

export default useInstDetailsConfigs;
