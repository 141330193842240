import React from "react";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
} from "../../../styles/DataGridTableStyles";
import usePayRollMastersConfig from "../hooks/usePayRollMastersConfig";

const useHRtable = () => {
  const {
    USE_HR_CATEGORY,
    USE_HR_DEPARTMENT,
    USE_HR_DESIGNATION,
    USE_HR_JOBTYPE,
  } = usePayRollMastersConfig();
  const tableJson = {
    HR_Table: {
      LoginUser: [
        {
          headerName: "Sl",
          cellClassName: "td-sl-no",
          field: "id",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
        {
          headerName: "Name",
          cellClassName: "td-name td-onClick",
          field: "name",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
          flex: 1,
          hideable: false,
        },
        {
          headerName: "Mobile",
          cellClassName: "td-mobile",
          field: "mobile",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
        },
        {
          headerName: "Email",
          cellClassName: "td-email",
          field: "email",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
        },
        {
          headerName: "User Type",
          cellClassName: "td-desc",
          field: "user_type",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
      ],
      EnableLoginUserList: [
        {
          headerName: "Sl",
          cellClassName: "td-sl-no",
          field: "id",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
        {
          headerName: "Name",
          cellClassName: "td-name td-onClick",
          field: "name",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
          flex: 1,
          hideable: false,
        },
        {
          headerName: "Emp-Type",
          cellClassName: "td-desc",
          field: "emp_type",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
        {
          headerName: "Mobile",
          cellClassName: "td-mobile",
          field: "mobile",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
        },
        {
          headerName: "Email",
          cellClassName: "td-email",
          field: "email",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
        },
      ],
      Attendance: {
        Monthly: [
          {
            headerName: "Sl",
            cellClassName: "td-sl-no",
            field: "id",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Month's",
            cellClassName: "td-name td-onClick",
            field: "months",
            headerAlign: HEADER_TEXT_ALIGN,
            flex: 1,
            hideable: false,
          },
          {
            headerName: "Start Date",
            cellClassName: "td-date",
            field: "s_date",
            headerAlign: HEADER_TEXT_ALIGN,
          },
          {
            headerName: "End Date",
            cellClassName: "td-date",
            field: "end_date",
            headerAlign: HEADER_TEXT_ALIGN,
          },
          {
            headerName: "Status",
            cellClassName: "td-status",
            field: "status",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "General Holidays",
            cellClassName: "td-number",
            field: "general_holidays",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Unexcepted Holidays",
            cellClassName: "td-number",
            field: "unexcepected_holidays",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Vacation Holidays",
            cellClassName: "td-number",
            field: "vacation_holidays",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Weekend Holidays",
            cellClassName: "td-number",
            field: "weekend_holidays",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
        ],
      },
      LeaveLedger: [
        {
          headerName: "Sl",
          cellClassName: "td-sl-no",
          field: "id",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
        {
          headerName: "Description",
          cellClassName: "td-name",
          field: "description",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
          flex: 1,
          hideable: false,
        },
        {
          headerName: "Short Code",
          cellClassName: "td-desc",
          field: "short_code",
          headerAlign: HEADER_TEXT_ALIGN,
        },
        {
          headerName: "Type",
          cellClassName: "td-desc",
          field: "leave_type",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
        {
          headerName: "Only for Women",
          cellClassName: "td-desc",
          field: "only_for_women",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
        {
          headerName: "Is Credited",
          cellClassName: "td-desc",
          field: "is_credited",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
        {
          headerName: "Is Carry forwarded",
          cellClassName: "td-desc",
          field: "is_carry_forwarded",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
        {
          headerName: "Leave is Earned",
          cellClassName: "td-desc",
          field: "leave_is_earned",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
      ],
      EmployeeStatus: {
        ActiveEmployee: [
          {
            headerName: "Sl",
            cellClassName: "td-sl-no",
            field: "id",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Employee ID",
            field: "emp_id",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Name",
            field: "emp_name",
            headerAlign: HEADER_TEXT_ALIGN,
            flex: 1,
            hideable: false,
          },
          {
            headerName: "Status",
            field: "emp_status",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Job Type",
            field: "job_type",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Experience",
            field: "experience",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Date of Joining",
            field: "doj",
            headerAlign: HEADER_TEXT_ALIGN,
          },
        ],
        SelectedEmployees: [
          {
            headerName: "Sl",
            cellClassName: "td-sl-no",
            field: "id",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Employee ID",
            field: "emp_id",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Name",
            field: "emp_name",
            headerAlign: HEADER_TEXT_ALIGN,
            flex: 1,
          },
        ],
      },
      EmployeeList: {
        Table_Headers: [
          {
            field: "id",
            align: SLNO_TEXT_ALIGN,
            headerAlign: HEADER_TEXT_ALIGN,
            headerName: "Sl",
            cellClassName: "employee-list__tableblock--table--th-slno",
          },
          {
            field: "emp_id",
            align: SLNO_TEXT_ALIGN,
            headerAlign: HEADER_TEXT_ALIGN,
            headerName: "Employee ID",
            cellClassName: "employee-list__tableblock--table--desc",
          },
          {
            field: "name",
            align: SLNO_TEXT_ALIGN,
            headerAlign: HEADER_TEXT_ALIGN,
            headerName: "Name",
            flex: 1,
            hideable: false,
          },
          ...(USE_HR_DEPARTMENT
            ? [
                {
                  field: "dept",
                  align: SLNO_TEXT_ALIGN,
                  headerAlign: HEADER_TEXT_ALIGN,
                  headerName: "Department",
                  cellClassName: "employee-list__tableblock--table--desc",
                },
              ]
            : []),

          ...(USE_HR_JOBTYPE
            ? [
                {
                  field: "job_type",
                  align: SLNO_TEXT_ALIGN,
                  headerAlign: HEADER_TEXT_ALIGN,
                  headerName: "Job Type",
                  cellClassName: "employee-list__tableblock--table--desc",
                },
              ]
            : []),

          {
            field: "experience",
            align: SLNO_TEXT_ALIGN,
            headerAlign: HEADER_TEXT_ALIGN,
            headerName: "Experience",
            cellClassName: "employee-list__tableblock--table--th-number",
          },
          ...(USE_HR_DESIGNATION
            ? [
                {
                  field: "designation",
                  align: SLNO_TEXT_ALIGN,
                  headerAlign: HEADER_TEXT_ALIGN,
                  headerName: "Designation",
                  cellClassName: "employee-list__tableblock--table--desc",
                },
              ]
            : []),

          ...(USE_HR_CATEGORY
            ? [
                {
                  field: "category",
                  align: SLNO_TEXT_ALIGN,
                  headerAlign: HEADER_TEXT_ALIGN,
                  headerName: "Category",
                  cellClassName: "employee-list__tableblock--table--desc",
                },
              ]
            : []),

          {
            field: "date_of_joining",
            align: SLNO_TEXT_ALIGN,
            headerAlign: HEADER_TEXT_ALIGN,
            headerName: "Date of Joining",
            cellClassName: "employee-list__tableblock--table--th-date",
          },
          {
            field: "status",
            align: SLNO_TEXT_ALIGN,
            headerAlign: HEADER_TEXT_ALIGN,
            headerName: "Status",
            cellClassName: "employee-list__tableblock--table--th-date",
          },
        ],
        Login_Page: [
          {
            labelName: "Sl",
            className: "employee-list__tableblock--table--th-slno",
          },
          {
            labelName: "Employee ID",
            className: "employee-list__tableblock--table--desc",
          },
          {
            labelName: "Name",
          },
          {
            labelName: "Department",
            className: "employee-list__tableblock--table--desc",
          },

          {
            labelName: "Designation",
            className: "employee-list__tableblock--table--desc",
          },
          {
            labelName: "E-Mail",
            className: "employee-list__tableblock--table--desc",
          },

          {
            labelName: "Login Status",
            className: "employee-list__tableblock--table--actions",
          },
        ],
      },
    },
  };
  return tableJson;
};

export default useHRtable;
