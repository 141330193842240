import React, { useContext, useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import Avatar from "../../../../images/Avatar.svg";
import { TextField } from "@mui/material";
import StudentList from "../List";
import {
  Direction,
  InstitutionConfigurationTypes,
  InstitutionType,
  Operation,
  PageFor,
  PageLabel,
  SortBy,
  StdStatusQueryType,
  StudentListFor,
  StudentReportType,
  StudentStatus,
  StudentType,
} from "../../../../utils/Enum.types";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import {
  AUTOCOMPLETE_CLEAR_CLASSNAME,
  EMPTY_STRING,
  NumberOfAdmissionNumberToBeDisplayed,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";
import {
  isOptionEqualToValue,
  NameOfTheDay,
  toInputStandardDate,
} from "../../../../utils/UtilFunctions";
import { Label } from "../../../../stories/Label/Label";
import { Button } from "../../../../stories/Button/Button";
import ViewNotEligibleList from "./ViewNotElgibleList";
import useStudentDatabyId from "../../../../customhooks/useStudentDatabyId";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  ChangeStudentStatusToNE,
  StudentNEStatusCancel,
} from "../../../../queries/students/mutations/update";
import { AppContext } from "../../../../context/context";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import {
  GetStudentNEActiveByStudentId,
  GetStudentsNeByInstId,
} from "../../../../queries/students/list/byId";
import {
  studentNe,
  studentNeData,
  studentNeVars,
} from "../../../../Types/Student";
import useToken from "../../../../customhooks/useToken";
import Close from "../../../../images/Close.svg";
import LoadingModal from "../../../../pages/LoadingModal";
import {
  msgType,
  optionsType,
  responseType,
} from "../../../../utils/Form.types";
import { Keys } from "../../../../utils/Enum.keys";
import useStudentAdmissionNumber from "../../../Accounts/hooks/UseStudentAdmissionNumber";
import { TODAY_DATE } from "../../../../utils/constants";
import DownArrow from "../../../../images/DownArrow.svg";
import Edit from "../../../../images/Edit.svg";
import { payloadTypes } from "../../../../context/reducer";
import useActiveAcademicYear from "../../../Academics/hooks/useActiveAcademicYear";
import TempMessageModal from "../../../../pages/TempMessageModal";
import { GetStudentsAdmno } from "../../../../queries/students/list/newApi";
import { studentQueryTypes } from "../../../../utils/studentqueryEnum.types";
import TextArea from "../../../../stories/TextArea/TextArea";
import { getDownloadUrl } from "../../../../utils/DownloadFile";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import { studentStatusDropDown } from "../../../Accounts/Reports/BalanceReports/Reports";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import {
  FormAutocomplete,
  formClasses,
} from "../../../../styles/AutocompleteStyles";
import {
  GetStudentsNeByInstIdData,
  GetStudentsNeByInstIdVars,
  MstStudentNeEdge,
} from "../../../../Types/Student/paginationTypes";
import useSwConfigInstType from "../../../Academics/hooks/useSwConfigInstType";
import useSwConfigData from "../../../../customhooks/useSwConfigData";

interface Props {
  eligiblePagetype: StudentReportType;
}

const Index = ({ eligiblePagetype }: Props) => {
  const { dispatch, state } = useContext(AppContext);
  const { institutionType } = useSwConfigInstType();

  const { token } = useToken();
  const { InstId } = useParams();
  const navigate = useNavigate();
  const { activeAcademicYearData } = useActiveAcademicYear();
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [date, setDate] = useState(toInputStandardDate(TODAY_DATE));
  const { configData: SearchFlagData } = useSwConfigData(
    InstitutionConfigurationTypes.OPTION_SEARCH_STD
  );
  const searchFlag =
    SearchFlagData &&
    SearchFlagData.data &&
    SearchFlagData.data.GetSwConfigVariables.length &&
    SearchFlagData.data.GetSwConfigVariables[0].config_string_value;

  const { InstDetails } = useInstDetails(1);
  const [studentModal, setStudentModal] = useState(false);
  const [viewNotEligible, setViewNotEligible] = useState(false);
  const [notEligibleModal, setNotEligibleModal] = useState(false);
  const [sortBy] = useState(SortBy.STD_NE_DATE);
  const [status, setStatus] = useState<optionsType | null>(null);
  const [remarks, setRemarks] = useState("");
  const [eligibleRemarks, setEligibleRemarks] = useState("");
  const [imageString, setImageString] = useState("");

  const [studentNeDetails, setStudentNeDetails] = useState<studentNe>();
  const { studentData } = useStudentDatabyId();

  const [studentType, SetStudentType] = useState(StudentType.ELIGIBLE);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [admNo, setAdmNo] = useState("");
  //UserRefs
  const admNoRef = useRef<HTMLSelectElement>(null);
  const admNoClearRef = admNoRef?.current?.getElementsByClassName(
    AUTOCOMPLETE_CLEAR_CLASSNAME
  )[0] as HTMLButtonElement;
  const statusRef = useRef<HTMLSelectElement>(null);
  const statusInputRef = statusRef?.current?.childNodes[0]?.childNodes[0]
    ?.childNodes[0] as HTMLInputElement;
  const saveRef = useRef<HTMLButtonElement>(null);
  const eligibleRef = useRef<HTMLButtonElement>(null);

  const [neStudents, setNeStudents] = useState<MstStudentNeEdge[]>([]);

  const remarksRef = useRef<HTMLTextAreaElement>(null);
  const { user_details } = useLoggedInUserDetails();
  const [GetStudentNeData, { data }] = useLazyQuery<
    studentNeData,
    studentNeVars
  >(GetStudentNEActiveByStudentId, {
    variables: { token, studentId: state.studentId },
  });

  const [GetNeStudents, { data: neStudentsData, loading: neStudentsLoading }] =
    useLazyQuery<GetStudentsNeByInstIdData, GetStudentsNeByInstIdVars>(
      GetStudentsNeByInstId,
      {
        variables: {
          token,
          inst_id: InstId!,
          query_type:
            studentType === StudentType.ELIGIBLE
              ? StdStatusQueryType.NE_STATUS_FALSE
              : StdStatusQueryType.NE_STATUS_TRUE,
          name: admNo,
          first: NumberOfAdmissionNumberToBeDisplayed,
          after: null,
          orderBy: sortBy,
          direction: Direction.ASC,
        },
      }
    );

  const [UpdateStudentStatus, { loading: updationloading }] = useMutation(
    ChangeStudentStatusToNE,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [CancelNEstudentStatus, { loading }] = useMutation(
    StudentNEStatusCancel,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const { studentAddmissionNumber } = useStudentAdmissionNumber(
    admNo,
    NumberOfAdmissionNumberToBeDisplayed,
    StudentReportType.ELIGIBLE
  );

  const handleUpdate = () => {
    if (eligiblePagetype === StudentReportType.ELIGIBLE) {
      if (status && remarks && studentData.data) {
        UpdateStudentStatus({
          variables: {
            token,
            inst_id: InstId!,
            user_details,
            input: {
              std_still_ne: false,
              std_ne_type: status.value,
              std_ne_reason: remarks,
              std_ne_date: dayjs(date).format(),
              std_ne_acd_year:
                activeAcademicYearData.data?.GetAcdYrActiveByInstId.acd_yr!,
              std_ne_made_by_staff_id: state.empLoginId,
              student_id: studentData.data?.nodes[0].id,
              inst_id: InstId,
            },
          },
          refetchQueries: [
            {
              query: GetStudentsNeByInstId,
              variables: {
                token,
                inst_id: InstId!,
                query_type: StdStatusQueryType.NE_STATUS_FALSE,
                name: EMPTY_STRING,
                first: ROWS_PER_PAGE,
                after: null,
                orderBy: sortBy,
                direction: Direction.ASC,
              },
            },
            {
              query: GetStudentsAdmno,
              variables: {
                after: null,
                fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
                first: ROWS_PER_PAGE,
                name: EMPTY_STRING,
                token,
                direction: Direction.ASC,
                sortBy: SortBy.FIRST_NAME,
                input: {
                  ids: [Number(InstId)!],
                  std_query_type: studentQueryTypes.STATUS_EQ_CUR_BY_INST_ID,
                },
              },
            },
            {
              query: GetStudentsAdmno,
              variables: {
                after: null,
                fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
                first: ROWS_PER_PAGE,
                name: EMPTY_STRING,
                token,
                direction: Direction.ASC,
                sortBy: SortBy.FIRST_NAME,
                input: {
                  ids: [Number(InstId)!],
                  std_query_type: studentQueryTypes.STATUS_NEQ_CUR_BY_INST_ID,
                },
              },
            },
          ],
        }).then(({ data }) => {
          handleClear();
          if (data)
            setMessage({
              message: "Successfully made Student Not Eligible",
              flag: true,
              operation: Operation.CREATE,
            });
        });
      } else {
        if (studentData.data === undefined) {
          alert("Student details not found");
        } else alert("Please fill all the fields");
      }
    } else {
      CancelNEstudentStatus({
        variables: {
          token,
          id: data?.GetStudentNEActiveByStudentId.id,
          inst_id: InstId!,
          user_details,
          input: {
            std_still_ne: true,
            std_ne_type: StudentStatus.CUR,
            std_ne_cancel_reason: eligibleRemarks,
            std_ne_cancel_date: dayjs(date).format(),
            std_ne_cancel_acd_year:
              activeAcademicYearData.data?.GetAcdYrActiveByInstId.acd_yr!,
            std_ne_cancel_made_by_staff_id: state.empLoginId,
          },
        },
        refetchQueries: [
          {
            query: GetStudentsNeByInstId,
            variables: {
              token,
              inst_id: InstId!,
              query_type: StdStatusQueryType.NE_STATUS_TRUE,
              name: EMPTY_STRING,
              first: ROWS_PER_PAGE,
              after: null,
              orderBy: sortBy,
              direction: Direction.ASC,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          handleClear();
          setMessage({
            message: "Successfully made Student Eligible",
            flag: true,
            operation: Operation.CREATE,
          });
        }
      });
    }
  };

  useEffect(() => {
    if (
      eligiblePagetype === StudentReportType.NON_ELIGIBLE &&
      state.studentId
    ) {
      GetStudentNeData().then(({ data }) => {
        if (data) {
          setStudentNeDetails(data.GetStudentNEActiveByStudentId);
        }
      });
    }
  }, [eligiblePagetype, GetStudentNeData, state.studentId, token]);

  useEffect(() => {
    if (eligiblePagetype === StudentReportType.NON_ELIGIBLE && token) {
      GetNeStudents();
    }
  }, [
    eligiblePagetype,
    GetNeStudents,
    InstId,
    token,
    admNo,
    NumberOfAdmissionNumberToBeDisplayed,
  ]);

  const handleClear = () => {
    dispatch({
      type: payloadTypes.SET_STUDENT_ID,
      payload: {
        studentId: 0,
      },
    });
    setStatus(null);
    setRemarks(EMPTY_STRING);
    setEligibleRemarks(EMPTY_STRING);
    setImageString(EMPTY_STRING);
    admNoClearRef?.click();
  };

  const handleClose = () =>
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  useEffect(() => {
    if (
      studentData.data &&
      studentData.data?.nodes[0].std_profile_filename !== "" &&
      InstDetails.data
    ) {
      const studentProfiePicUrl = `${InstDetails.data?.nodes[0]?.inst_name}/students/${state.studentId}/std_profile_pic/std_profile_pic`;

      // eslint-disable-next-line
      const ers = getDownloadUrl(studentProfiePicUrl, false, setImageString);
    }
  }, [studentData.data, InstDetails.data, state.studentId]);

  useEffect(() => {
    if (neStudentsData && !neStudentsLoading) {
      const newData = neStudentsData.GetStudentsNeByInstId.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = neStudents.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
              },
            };
          }
          return newStudent;
        });

        setNeStudents(updatedNewData);
      } else {
        setNeStudents(newData);
      }
      setEndCursor(neStudentsData.GetStudentsNeByInstId.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [neStudentsLoading, neStudentsData]);

  const { branchLabel, classLabel, categoryLabel } = useInstLabels();

  const neStudentsOption = neStudentsData
    ? neStudentsData.GetStudentsNeByInstId.edges.reduce((acc, { node }) => {
        if (acc.map(({ value }) => value).includes(node.student_id) === false) {
          acc = [
            ...acc,
            {
              label: `(${
                searchFlag === "REG"
                  ? node.mst_student.std_reg_no
                  : node.mst_student.std_adm_no
              } ) ${node.mst_student.first_name} ${
                node.mst_student.middle_name
              } ${node.mst_student.last_name}
             `,
              value: node.student_id,
            },
          ];
        }
        return acc;
      }, [] as responseType[])
    : [];

  return (
    <>
      <div className="row g-0 student-status__details">
        <div className="col">
          <div className="student-status__details--imagefield">
            {eligiblePagetype === StudentReportType.NON_ELIGIBLE ? (
              <FormAutocomplete
                className={formClasses.inputRoot}
                options={neStudentsOption}
                ref={admNoRef!}
                value={
                  neStudentsOption.find(
                    ({ value }) => value === state.studentId
                  )! ?? null
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    dispatch({
                      type: payloadTypes.SET_STUDENT_ID,
                      payload: {
                        studentId: Number((newValue as responseType)?.value),
                      },
                    });
                  } else {
                    dispatch({
                      type: payloadTypes.SET_STUDENT_ID,
                      payload: {
                        studentId: 0,
                      },
                    });
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    eligiblePagetype === StudentReportType.NON_ELIGIBLE
                      ? remarksRef?.current?.focus()
                      : statusInputRef?.focus();
                  }
                  if (e.key === Keys.BACKSPACE) {
                    dispatch({
                      type: payloadTypes.SET_STUDENT_ID,
                      payload: { studentId: 0 },
                    });
                  }
                }}
                popupIcon={
                  <img
                    src={DownArrow}
                    alt="/"
                    className="down-arrow-autocomplete"
                  />
                }
                forcePopupIcon
                freeSolo
                autoHighlight
                renderInput={(params) => (
                  <TextField
                    {...params}
                    autoFocus
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setAdmNo(e.target.value)
                    }
                    label="Admission Number"
                    fullWidth
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={formClasses.formControlRoot}
                  />
                )}
              />
            ) : (
              <FormAutocomplete
                className={formClasses.inputRoot}
                options={studentAddmissionNumber}
                ref={admNoRef!}
                value={
                  studentAddmissionNumber.find(({ label }) =>
                    studentData.data
                      ? label === studentData.data.nodes[0].std_adm_no
                      : label === admNo
                  )! ?? null
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    dispatch({
                      type: payloadTypes.SET_STUDENT_ID,
                      payload: {
                        studentId: Number((newValue as responseType)?.value),
                      },
                    });
                  } else {
                    dispatch({
                      type: payloadTypes.SET_STUDENT_ID,
                      payload: {
                        studentId: 0,
                      },
                    });
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    statusInputRef?.focus();
                  }
                  if (e.key === Keys.BACKSPACE) {
                    dispatch({
                      type: payloadTypes.SET_STUDENT_ID,
                      payload: { studentId: 0 },
                    });
                  }
                }}
                popupIcon={
                  <img
                    src={DownArrow}
                    alt="/"
                    className="down-arrow-autocomplete"
                  />
                }
                forcePopupIcon
                freeSolo
                autoHighlight
                renderInput={(params) => (
                  <TextField
                    {...params}
                    autoFocus
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setAdmNo(e.target.value)
                    }
                    label="Admission Number"
                    fullWidth
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={formClasses.formControlRoot}
                  />
                )}
              />
            )}
            <img
              className="data-fetch-icon"
              src={Edit}
              alt="/"
              onClick={() => {
                eligiblePagetype === StudentReportType.NON_ELIGIBLE
                  ? setNotEligibleModal(!notEligibleModal)
                  : setStudentModal(!studentModal);
              }}
            />
          </div>
          {institutionType !== InstitutionType.SCHOOL && (
            <TextField
              label="Register Number"
              className="student-status__details--textfield"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              value={
                state.studentId && studentData.data
                  ? studentData.data.nodes[0].std_reg_no
                  : EMPTY_STRING
              }
              disabled
            />
          )}
          <TextField
            label={branchLabel}
            className="student-status__details--textfield"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            value={
              state.studentId && studentData.data
                ? studentData.data.nodes[0].branch.branch_desc
                : EMPTY_STRING
            }
            disabled
          />
        </div>
        <div className="col">
          <TextField
            label="Name"
            className="student-status__details--textfield"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            value={
              state.studentId && studentData.data
                ? studentData.data.nodes[0].first_name +
                  " " +
                  studentData.data.nodes[0].middle_name +
                  " " +
                  studentData.data.nodes[0].last_name
                : EMPTY_STRING
            }
            disabled
          />
          <TextField
            label="Father Name"
            className="student-status__details--textfield"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            value={
              state.studentId && studentData.data
                ? studentData.data.nodes[0].std_father_name
                : EMPTY_STRING
            }
            disabled
          />
          <TextField
            label={classLabel}
            className="student-status__details--textfield"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            value={
              state.studentId && studentData.data
                ? studentData.data.nodes[0].class.class_desc
                : EMPTY_STRING
            }
            disabled
          />
        </div>
        <div className="col">
          <TextField
            type="date"
            label="Date"
            className="student-status__details--textfield--date"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
              input: {
                inputProps: {
                  min: { TODAY_DATE },
                  max: { TODAY_DATE },
                },
              },
            }}
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
          <Label variant="present-day">{NameOfTheDay(date)}</Label>

          <TextField
            label="Academic Year"
            className="student-status__details--textfield"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            value={
              state.studentId && studentData.data
                ? studentData.data.nodes[0].acd_yr.acd_yr
                : EMPTY_STRING
            }
            disabled
          />
          <TextField
            label={categoryLabel}
            className="student-status__details--textfield"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            value={
              state.studentId && studentData.data
                ? studentData.data?.nodes[0].category.cat_desc
                : EMPTY_STRING
            }
            disabled
          />
        </div>
        <div className="col-1 student-status--image h-100">
          {imageString === EMPTY_STRING ? (
            <img src={Avatar} alt="/" />
          ) : (
            <img src={imageString} alt="/" />
          )}
        </div>
      </div>
      <div
        className={
          eligiblePagetype === StudentReportType.NON_ELIGIBLE
            ? "row g-0 student-status__block--make-elligible"
            : "row g-0 student-status__block--not-elligible"
        }>
        {eligiblePagetype === StudentReportType.NON_ELIGIBLE &&
        state.studentId ? (
          <>
            <div className="col">
              <TextField
                type="date"
                label="Not Eligible Date"
                className="student-status__details--textfield--date"
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                  input: {
                    inputProps: {
                      min: { TODAY_DATE },
                      max: { TODAY_DATE },
                    },
                  },
                }}
                value={
                  studentNeDetails
                    ? studentNeDetails?.std_ne_date.split("T")[0]
                    : EMPTY_STRING
                }
                disabled
              />
              <TextField
                label="Student Status"
                className="student-status__details--textfield"
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                value={
                  state.studentId && studentData.data
                    ? studentData.data.nodes[0].std_status
                    : EMPTY_STRING
                }
                disabled
              />
            </div>
            <div className="col">
              <Label>Remarks</Label>
              <TextArea
                value={
                  state.studentId && studentNeDetails
                    ? studentNeDetails.std_ne_reason
                    : EMPTY_STRING
                }
                disabled
                rows={2}
              />
            </div>
            <div className="col"></div>
            <div className="col-1"></div>
          </>
        ) : null}
      </div>
      <div
        className={
          eligiblePagetype === StudentReportType.NON_ELIGIBLE
            ? "row g-0 student-status__remarks--make-elligible"
            : "row g-0 student-status__remarks--not-elligible"
        }>
        <div className="col account-frames">
          {eligiblePagetype === StudentReportType.NON_ELIGIBLE ? null : (
            <div className="label-grid">
              <Label> Student Status</Label>
              <FormAutocomplete
                className={formClasses.inputRoot}
                options={studentStatusDropDown!.filter(
                  (res) => res.label !== "Current"
                )}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as optionsType, status)
                }
                ref={statusRef!}
                value={status}
                openOnFocus
                onChange={(e, newValue) => {
                  if (newValue) {
                    setStatus(newValue as optionsType);
                  } else {
                    setStatus(null);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    remarksRef?.current?.focus();
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setStatus(null);
                  }
                }}
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    className={formClasses.formControlRoot}
                    {...params}
                    onChange={(e) => {}}
                    fullWidth
                    required
                  />
                )}
              />
            </div>
          )}
          <div className="label-grid">
            <Label>Remarks</Label>
            <TextArea
              textAreaRef={remarksRef!}
              value={
                eligiblePagetype === StudentReportType.ELIGIBLE
                  ? remarks
                  : eligibleRemarks
              }
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === Keys.ENTER && !e.shiftKey) {
                  e.preventDefault();
                  eligiblePagetype === StudentReportType.ELIGIBLE
                    ? eligibleRef?.current?.focus()
                    : saveRef?.current?.focus();
                }
              }}
              required
              onChange={(e) => {
                eligiblePagetype === StudentReportType.ELIGIBLE
                  ? setRemarks(e.target.value)
                  : setEligibleRemarks(e.target.value);
              }}
              rows={3}
            />
          </div>
        </div>
        <div className="col"></div>
      </div>

      {eligiblePagetype === StudentReportType.NON_ELIGIBLE ? (
        <>
          <Button
            mode="save"
            buttonref={saveRef!}
            disabled={!state.studentId}
            onClick={handleUpdate}
          />

          <Button
            mode="view"
            onClick={() => {
              setViewNotEligible(!viewNotEligible);
              SetStudentType(StudentType.ELIGIBLE);
            }}>
            {" "}
            (Eligible)
          </Button>
          <Button mode="clear" onClick={handleClear} />
        </>
      ) : (
        <>
          <Button
            mode="save"
            buttonref={eligibleRef!}
            disabled={!state.studentId}
            onClick={() => {
              handleUpdate();
            }}
          />
          <Button
            mode="view"
            onClick={() => {
              setViewNotEligible(!viewNotEligible);
              SetStudentType(StudentType.NOT_ELIGIBLE);
            }}>
            {" "}
            (Not Eligible)
          </Button>
          <Button mode="clear" onClick={handleClear} />
        </>
      )}
      <Button mode="back" onClick={() => navigate(-1)} />
      {/* studentmodal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={studentModal}
        style={StudentModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentList
              pageType={PageFor.MODAL}
              studentListFor={StudentListFor.GENERAL}
              queryType={StudentReportType.CURR_STUDENTS}
              setStudentModal={setStudentModal}
              studentModal={studentModal}
              label={PageLabel.TRUE}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStudentModal(!studentModal)}
            />
          </div>
        </div>
      </Modal>

      {/* view make Not elligible */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={viewNotEligible}
        style={StudentModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ViewNotEligibleList
              studentType={studentType}
              pageType={PageFor.GENERAL}
              setNotEligibleModal={setViewNotEligible}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setViewNotEligible(!viewNotEligible)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={notEligibleModal}
        style={StudentModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ViewNotEligibleList
              studentType={StudentType.NOT_ELIGIBLE}
              pageType={PageFor.MODAL}
              setNotEligibleModal={setNotEligibleModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setNotEligibleModal(!notEligibleModal)}
            />
          </div>
        </div>
      </Modal>
      <LoadingModal flag={updationloading || loading} />

      <TempMessageModal
        modalFlag={message.flag}
        value={message.message}
        handleClose={handleClose}
        operation={message.operation}
      />
    </>
  );
};

export default Index;
