import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../context/context";
import { GetMstInstLogoAndSigns } from "../queries/institution/list";
import {
  GetMstInstLogoAndSignsData,
  GetMstInstLogoAndSignsVars,
} from "../Types/Student";
import { getDownloadUrl } from "../utils/DownloadFile";
import useToken from "./useToken";
import { FileUploadParams } from "../utils/Enum.types";
interface props {
  filetype: FileUploadParams;
}
const useInstLogoDetails = ({ filetype }: props) => {
  const { InstId } = useParams();
  const { state } = useContext(AppContext);
  const { token } = useToken();
  const [imageString, setImageString] = useState("");

  const [GetLogoData, { data, loading }] = useLazyQuery<
    GetMstInstLogoAndSignsData,
    GetMstInstLogoAndSignsVars
  >(GetMstInstLogoAndSigns, {
    variables: { token, inst_id: state.InstId || InstId!, file_type: filetype },
  });
  useEffect(() => {
    if (data && !loading && filetype) {
      const filename = data.GetMstInstLogoAndSigns;
      if (filename) {
        // eslint-disable-next-line
        const ers = getDownloadUrl(filename, false, setImageString);
      }
    }
  }, [data, loading, filetype]);

  useEffect(() => {

    if (token && (InstId || state.InstId) && filetype) {
      GetLogoData();
    }
  }, [token, InstId, state.InstId, filetype, GetLogoData]);


  return { LogoOrSign: { data, loading, defaultLogo: imageString } };
};

export default useInstLogoDetails;
