import { TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Title } from "../../../../../stories/Title/Title";
import {
  EMPTY_STRING,
  emptyMessageType,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE_30,
  TODAY_DATE,
} from "../../../../../utils/constants";
import {
  BankOrCash,
  DebitOrCredit,
  Operation,
  ReceiptTypes,
  studentData,
} from "../../../../../utils/Enum.types";
import {
  filterVouchersByType,
  formatter,
  getModifiedScrollHeight,
  toInputStandardDate,
  toIsoDate,
  toStandardDate,
} from "../../../../../utils/UtilFunctions";
import Close from "../../../../../images/Close.svg";
import Modal from "react-modal";

import { nodevars, VouchernodeData } from "../../../../../Types/Accounting";

import {
  EnquiryStudentDetailsByMasterVoucherId,
  PassedOutStudentDetailsByMasterVoucherId,
  StudentDetailsByMasterVoucherId,
} from "../../../../../queries/common";
import { useLazyQuery } from "@apollo/client";
import useToken from "../../../../../customhooks/useToken";
import StudentDemandList from "../../AccountLedgerBook/Modals/StudentVoucherView";
import { StudentModalStyles } from "../../../../../styles/ModalStyles";
import useAcctVoucherDetailsByDates, {
  GetAcctVouchersEdges,
  voucherMasterDetails,
} from "../../../hooks/useAcctVoucherDetailsByDates";
import { AppContext } from "../../../../../context/context";
import { payloadTypes } from "../../../../../context/reducer";
import { Button } from "../../../../../stories/Button/Button";

import { optionsType } from "../../../../../utils/Form.types";
import { VoucherQueryTypes } from "../../../common/Enum.types";
import useAcctTableJson from "../../../json/useAcctTableJson";
import {
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../../../styles/DataGridTableStyles";
import {
  GridCellParams,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { TableHeaderProps } from "../../../../../utils/types";
import { GridAlignment } from "../../../../Academics/Reports/CompleteReports/Test/ViewParticularTestReportTable";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../../styles/AutocompleteListStyles";
import { ExportAcctStdtoExcel } from "../../../../../queries/xls";
import {
  ExcelVoucherQueryData,
  ExportAcctStdtoExcelData,
} from "../../../../Master/Reports/InstitutionLevel/useExportMutation";
import { useParams } from "react-router-dom";
import { SortBy } from "../../../../Master/Reports/InstitutionLevel/GlobalStates/types";
import { getDownloadUrl } from "../../../../../utils/DownloadFile";
import MessageModal from "../../../../../pages/MessageModal";
import LoadingModal from "../../../../../pages/LoadingModal";
import { GetAcctLdgrForDailyFeeCollection } from "../../../queries/FeeLedgers/query/Byid";
import {
  GetAcctLdgrForDailyFeeCollectionData,
  GetAcctLdgrForDailyFeeCollectionVars,
} from "../../DailyReports/LedgerWiseReport";
import dayjs from "dayjs";

interface props {
  queryType: VoucherQueryTypes;
}

export const filterOptions: optionsType[] = [
  {
    label: "Cash Receipts",
    value: BankOrCash.CASH,
  },
  {
    label: "Bank Receipts",
    value: BankOrCash.BANK,
  },
  {
    label: "Adjustment Receipts",
    value: BankOrCash.ADJ,
  },
];

interface ExportAcctStdtoExcelVars {
  token: string;
  inst_id: string;
  query_type: string;
  input: {
    ids: number[];
    std_status: string[];
    str_data: string[];
    int_data: number[];
    float_data: number[];
  };
  parameters: string[];
  sort_by: SortBy;
  fin_yr_id: number;
  voucher_input: ExcelVoucherQueryData;
}
const Index = ({ queryType }: props) => {
  const { Accounts_Table } = useAcctTableJson();

  let totalrcptAmount = 0;
  const { state, dispatch } = useContext(AppContext);
  const [rows, setRows] = useState<GridValidRowModel[]>([]);

  const { InstId } = useParams();

  const [ExportAcct, { loading: exportloading }] = useLazyQuery<
    ExportAcctStdtoExcelData,
    ExportAcctStdtoExcelVars
  >(ExportAcctStdtoExcel);

  const [selectedVtype, setSelectedVtype] = useState<optionsType | null>(null);

  const [voucher_query_type, set_voucher_query_type] = useState("");

  const { format } = formatter;
  const { token } = useToken();
  const [message, setMessage] = useState(emptyMessageType);

  const [vouchers, setVouchers] = useState<GetAcctVouchersEdges[]>([]);

  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);

  const [studentDetails, setStudentDetails] = useState<voucherMasterDetails[]>(
    []
  );
  const [passedOutStudentDetails, setPassedStudentDetails] = useState<
    voucherMasterDetails[]
  >([]);

  const [enquiryStudentDetails, setEnquiryStudentDetails] = useState<
    voucherMasterDetails[]
  >([]);

  const [filter, setFilter] = useState("");
  const [adjustmentFlag, setAdjustmentFlag] = useState<boolean>(false);
  const [startDate, setStartDate] = useState(
    toInputStandardDate(dayjs(TODAY_DATE).subtract(3, "month").toString())
  );
  const [endDate, setEndDate] = useState(toInputStandardDate(TODAY_DATE));

  const [GetLedgers, { data: acctLedgers }] = useLazyQuery<
    GetAcctLdgrForDailyFeeCollectionData,
    GetAcctLdgrForDailyFeeCollectionVars
  >(GetAcctLdgrForDailyFeeCollection, {
    variables: {
      fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
      inst_id: InstId!,
      token,
      vo_end_date: toIsoDate(endDate),
      vo_start_date: toIsoDate(startDate),
    },
    nextFetchPolicy: "network-only",
  });
  const [GetStudentDetails, { loading: studentsDataLoading }] = useLazyQuery<
    VouchernodeData,
    nodevars
  >(StudentDetailsByMasterVoucherId);
  const [GetPassedStudentDetails] = useLazyQuery<VouchernodeData, nodevars>(
    PassedOutStudentDetailsByMasterVoucherId
  );

  const [GetEnqStudentDetails] = useLazyQuery<VouchernodeData, nodevars>(
    EnquiryStudentDetailsByMasterVoucherId
  );

  const [studentReceiptModal, setStudentReceiptModal] = useState(false);

  const [v_no, setV_no] = useState("");
  const { VoucherDetails } = useAcctVoucherDetailsByDates(
    ROWS_PER_PAGE_30,
    endDate,
    startDate,
    voucher_query_type,
    v_no,
    adjustmentFlag,
    filter
  );
  const { data, fetchMore, loading } = VoucherDetails;

  const totalCount = data ? data.GetAcctVouchers.totalCount : 0;
  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newStartDate = e.target.value;
    const maxEndDate = dayjs(newStartDate).add(3, "month");

    if (dayjs(endDate).isAfter(maxEndDate)) {
      alert("End date cannot be more than 3 months after the start date.");
    } else {
      setStartDate(newStartDate);
    }
  };
  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEndDate = e.target.value;
    const minStartDate = dayjs(newEndDate).subtract(3, "month");

    if (dayjs(startDate).isBefore(minStartDate)) {
      alert("Start date cannot be more than 3 months before the end date.");
    } else {
      setEndDate(newEndDate);
    }
  };

  // eslint-disable-next-line
  vouchers.map((edge) => {
    totalrcptAmount += edge.node.v_total_cr_amt;
  });

  const FetchStudentDetails = (
    voucher: voucherMasterDetails,
    type: studentData
  ) => {
    const student = studentDetails?.find(
      (studentDetail) => studentDetail?.id === voucher.id
    );
    const enqStudent = enquiryStudentDetails.find(
      (enqStudentDetails) => enqStudentDetails?.id === voucher.id
    );
    const passedOutStudent = passedOutStudentDetails.find(
      (passedOutStudentDetails) => passedOutStudentDetails?.id === voucher.id
    );

    if (
      student &&
      voucher.student_id &&
      voucher.v_std_enquiry === false &&
      voucher.v_std_passout_receipt === false
    ) {
      switch (type) {
        case studentData.name:
          return (
            student?.mst_student.first_name +
            " " +
            student?.mst_student.middle_name +
            " " +
            student?.mst_student.last_name +
            " " +
            `(${voucher.branch_details.branch_desc} / ${voucher.class_details.class_desc} / ${student?.acct_voucher_details[0].acct_ldgr.ldgr_desc})`
          );
        case studentData.admNo:
          return student?.mst_student.std_adm_no!;
        case studentData.parent:
          return student?.mst_student.std_father_name!;
        case studentData.reg:
          return student?.mst_student.std_reg_no!;
        case studentData.id:
          return student?.mst_student.id!;
        default:
          break;
      }
    }
    if (
      enqStudent &&
      voucher.student_id === 0 &&
      voucher.v_std_enquiry &&
      voucher.v_std_passout_receipt === false
    ) {
      switch (type) {
        case studentData.name:
          return (
            enqStudent?.enq_std_details.first_name +
            " " +
            enqStudent?.enq_std_details.middle_name +
            " " +
            enqStudent?.enq_std_details.last_name +
            " " +
            `(${voucher.branch_details.branch_desc} / ${voucher.class_details.class_desc} / ${enqStudent?.enq_std_details.class_details.class_desc} / ${enqStudent?.acct_voucher_details[0].acct_ldgr.ldgr_desc})`
          );
        case studentData.admNo:
          return "-";
        case studentData.parent:
          return enqStudent?.enq_std_details.std_father_name!;
        case studentData.reg:
          return "-";
        case studentData.id:
          return enqStudent?.enq_std_details.id!;
        default:
          break;
      }
    }
    if (
      passedOutStudent &&
      voucher.student_id === 0 &&
      voucher.v_std_enquiry === false &&
      voucher.v_std_passout_receipt
    ) {
      switch (type) {
        case studentData.name:
          return (
            passedOutStudent?.passout_std_details.std_name +
            " " +
            `(${voucher.branch_details.branch_desc} / ${voucher.class_details.class_desc} / ${passedOutStudent?.passout_std_details.class_details.class_desc} / ${passedOutStudent?.acct_voucher_details[0].acct_ldgr.ldgr_desc})`
          );
        case studentData.admNo:
          return "-";
        case studentData.parent:
          return "-";
        case studentData.reg:
          return "-";
        case studentData.id:
          return passedOutStudent?.passout_std_details.id!;
        default:
          break;
      }
    }
  };

  const filteredStudentsReceipts = filterVouchersByType(
    ReceiptTypes.STUDENT_RECEIPT,
    VoucherDetails.data!
  );

  const filteredPassedStudentReceipts = VoucherDetails.data
    ? VoucherDetails.data.GetAcctVouchers.edges.filter(
        ({ node }) =>
          node.v_type === ReceiptTypes.STUDENT_RECEIPT &&
          node.v_std_passout_receipt
      )
    : [];

  const filteredEnquiryStudentReceipts = VoucherDetails.data
    ? VoucherDetails.data.GetAcctVouchers.edges.filter(
        ({ node }) =>
          node.v_type === ReceiptTypes.STUDENT_RECEIPT && node.v_std_enquiry
      )
    : [];

  const getVoucherType = (q_type: BankOrCash) => {
    switch (q_type) {
      case BankOrCash.BANK:
        switch (queryType) {
          case VoucherQueryTypes.STUDENT_ALL_RECEIPTS_WITHOUT_REFUNDS:
            set_voucher_query_type(
              VoucherQueryTypes.STUDENT_ALL_RECEIPTS_WITHOUT_REFUNDS_ONLY_BANK
            );
            break;
          case VoucherQueryTypes.STUDENT_DEMAND_RECEIPTS:
            set_voucher_query_type(
              VoucherQueryTypes.STUDENT_DEMAND_RECEIPTS_ONLY_BANK
            );
            break;
          case VoucherQueryTypes.STD_NON_DEMAND_RECEIPTS:
            set_voucher_query_type(
              VoucherQueryTypes.STD_NON_DEMAND_RECEIPTS_ONLY_BANK
            );
            break;
          case VoucherQueryTypes.PASSOUT_STD_VOUCHERS:
            set_voucher_query_type(
              VoucherQueryTypes.PASSOUT_STD_VOUCHERS_ONLY_BANK
            );
            break;
          case VoucherQueryTypes.ENQ_STD_VOUCHERS:
            set_voucher_query_type(
              VoucherQueryTypes.ENQ_STD_VOUCHERS_ONLY_BANK
            );
            break;
          default:
            break;
        }
        break;
      case BankOrCash.CASH:
        switch (queryType) {
          case VoucherQueryTypes.STUDENT_ALL_RECEIPTS_WITHOUT_REFUNDS:
            set_voucher_query_type(
              VoucherQueryTypes.STUDENT_ALL_RECEIPTS_WITHOUT_REFUNDS_ONLY_CASH
            );
            break;
          case VoucherQueryTypes.STUDENT_DEMAND_RECEIPTS:
            set_voucher_query_type(
              VoucherQueryTypes.STUDENT_DEMAND_RECEIPTS_ONLY_CASH
            );
            break;
          case VoucherQueryTypes.STD_NON_DEMAND_RECEIPTS:
            set_voucher_query_type(
              VoucherQueryTypes.STD_NON_DEMAND_RECEIPTS_ONLY_CASH
            );
            break;
          case VoucherQueryTypes.PASSOUT_STD_VOUCHERS:
            set_voucher_query_type(
              VoucherQueryTypes.PASSOUT_STD_VOUCHERS_ONLY_CASH
            );
            break;
          case VoucherQueryTypes.ENQ_STD_VOUCHERS:
            set_voucher_query_type(
              VoucherQueryTypes.ENQ_STD_VOUCHERS_ONLY_CASH
            );
            break;
          default:
            break;
        }
        break;
      case BankOrCash.ADJ:
        switch (queryType) {
          case VoucherQueryTypes.STUDENT_ALL_RECEIPTS_WITHOUT_REFUNDS:
            set_voucher_query_type(
              VoucherQueryTypes.STUDENT_ALL_RECEIPTS_WITHOUT_REFUNDS_ONLY_ADJ
            );
            break;
          case VoucherQueryTypes.STUDENT_DEMAND_RECEIPTS:
            set_voucher_query_type(
              VoucherQueryTypes.STUDENT_DEMAND_RECEIPTS_ONLY_ADJ
            );
            break;
          case VoucherQueryTypes.STD_NON_DEMAND_RECEIPTS:
            set_voucher_query_type(
              VoucherQueryTypes.STD_NON_DEMAND_RECEIPTS_ONLY_ADJ
            );
            break;
          default:
            break;
        }
        break;

      default:
        break;
    }
  };

  const hanldeExport = () => {
    try {
      ExportAcct({
        variables: {
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          input: {
            float_data: [],
            ids: [Number(InstId), 0],
            int_data: [],
            std_status: [],
            str_data: [],
          },
          voucher_input: {
            acct_ldgr_id: 0,
            vo_start_date: toIsoDate(startDate),
            vo_end_date: toIsoDate(endDate),
            vo_type: EMPTY_STRING,
            v_book_type: EMPTY_STRING,
          },
          inst_id: InstId!,
          parameters: [],
          query_type: voucher_query_type,
          sort_by: SortBy.STD_NAME,
          token,
        },
      }).then(async ({ data, error }) => {
        if (data && data.ExportAcctStdtoExcel) {
          const res = await getDownloadUrl(data.ExportAcctStdtoExcel, true);
          if (res) {
            setMessage({
              flag: true,
              message: "Downloading...",
              operation: Operation.CREATE,
            });
          }
        } else {
          setMessage({
            flag: true,
            message: "Failed downloading file",
            operation: Operation.NONE,
          });
        }

        if (error && error.message) {
          setMessage({
            flag: true,
            message: error.message,
            operation: Operation.NONE,
          });
        }
      });
    } catch (e) {
      setMessage({
        flag: true,
        message: "Failed to download",
        operation: Operation.NONE,
      });
    }
  };

  useEffect(() => {
    if (filteredStudentsReceipts?.length) {
      GetStudentDetails({
        variables: {
          token,
          ids: filteredStudentsReceipts?.map((data) => data.node.id)!,
        },
      }).then(({ data }) => {
        if (data && data.nodes) setStudentDetails(data?.nodes!);
      });
    } // eslint-disable-next-line
  }, [GetStudentDetails, filteredStudentsReceipts?.length, token]);
  useEffect(() => {
    if (filteredPassedStudentReceipts?.length) {
      GetPassedStudentDetails({
        variables: {
          token,
          ids: filteredPassedStudentReceipts?.map((data) => data.node.id)!,
        },
      }).then(({ data }) => {
        if (data && data.nodes) setPassedStudentDetails(data.nodes);
      });
    } // eslint-disable-next-line
  }, [GetPassedStudentDetails, filteredPassedStudentReceipts?.length, token]);
  useEffect(() => {
    if (filteredEnquiryStudentReceipts.length) {
      GetEnqStudentDetails({
        variables: {
          token,
          ids: filteredEnquiryStudentReceipts.map((data) => data.node.id)!,
        },
      }).then(({ data }) => {
        if (data && data.nodes) setEnquiryStudentDetails(data.nodes);
      });
    } // eslint-disable-next-line
  }, [GetEnqStudentDetails, filteredEnquiryStudentReceipts.length, token]);

  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;

      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: FETCH_MORE_DATA,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetAcctVouchers.edges;
                const pageInfo = fetchMoreResult.GetAcctVouchers.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck = prevResult.GetAcctVouchers.edges.filter(
                  ({ node: { id } }) =>
                    newEdges.findIndex(
                      ({ node: { id: newId } }) => newId === id
                    ) !== -1
                );

                if (duplicateCheck.length > 0) return prevResult;

                return {
                  GetAcctVouchers: {
                    edges: [...vouchers, ...newEdges],
                    pageInfo,
                    totalCount: data ? data.GetAcctVouchers.totalCount! : 0,
                  },
                };
              },
            });
          }
        }
      };

      if (scrollTable && rows.length)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    [rows]
  );

  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetAcctVouchers.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newRow) => {
          const filteredStudent = vouchers.find(
            (row) => row.node.id === newRow.node.id
          );
          if (filteredStudent) {
            return {
              ...newRow,
              node: {
                ...newRow.node,
              },
            };
          }
          return newRow;
        });
        setVouchers(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            date: toStandardDate(node.v_date),
            receipt_no: node.v_no,
            admission_no: FetchStudentDetails(node, studentData.admNo),
            reg_no: FetchStudentDetails(node, studentData.reg),
            name: FetchStudentDetails(node, studentData.name),
            received_from: node.v_transcation_type,
            receipt_amt: format(node.v_total_cr_amt),
            v_std_enquiry: node.v_std_enquiry,
            v_std_passout_receipt: node.v_std_passout_receipt,
            passout_student_id: node.passout_student_id,
            v_std_receipt: node.v_std_receipt,
            student_id: node.student_id,
            enquiry_student_id: node.enquiry_student_id,
            voucher_id: node.id,
            ...(acctLedgers
              ? acctLedgers.GetAcctLdgrForDailyFeeCollection?.reduce(
                  (acc, banks) => {
                    const voucherDetail = node.acct_voucher_details.find(
                      (d) =>
                        d.vo_cr_db === DebitOrCredit.CREDIT &&
                        d.acct_ldgr.id === banks.id
                    );
                    return {
                      ...acc,
                      [banks.id]: voucherDetail
                        ? format(voucherDetail.vo_cr)
                        : "-",
                    };
                  },
                  {}
                )
              : []),
          }))
        );
      } else {
        setVouchers(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            date: toStandardDate(node.v_date),
            receipt_no: node.v_no,
            admission_no: FetchStudentDetails(node, studentData.admNo),
            reg_no: FetchStudentDetails(node, studentData.reg),
            name: FetchStudentDetails(node, studentData.name),
            received_from: node.v_transcation_type,
            receipt_amt: format(node.v_total_cr_amt),
            v_std_enquiry: node.v_std_enquiry,
            v_std_passout_receipt: node.v_std_passout_receipt,
            passout_student_id: node.passout_student_id,
            v_std_receipt: node.v_std_receipt,
            student_id: node.student_id,
            enquiry_student_id: node.enquiry_student_id,
            voucher_id: node.id,
            ...(acctLedgers
              ? acctLedgers.GetAcctLdgrForDailyFeeCollection?.reduce(
                  (acc, banks) => {
                    const voucherDetail = node.acct_voucher_details.find(
                      (d) =>
                        d.vo_cr_db === DebitOrCredit.CREDIT &&
                        d.acct_ldgr.id === banks.id
                    );
                    return {
                      ...acc,
                      [banks.id]: voucherDetail
                        ? format(voucherDetail.vo_cr)
                        : "-",
                    };
                  },
                  {}
                )
              : []),
          }))
        );
      }
      setEndCursor(data.GetAcctVouchers.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [
    loading,
    data,
    studentDetails,
    enquiryStudentDetails,
    passedOutStudentDetails,
  ]);

  useEffect(() => {
    set_voucher_query_type(queryType);
    setSelectedVtype(null);
  }, [queryType]);
  const dynamicHeaders: TableHeaderProps[] =
    Accounts_Table.Reports.FeeReceiptBook.Table_Headers.map((header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
      hideable: header.hideable,
    }));
  const columns: GridColDef[] = [
    ...dynamicHeaders,
    ...(acctLedgers?.GetAcctLdgrForDailyFeeCollection.map((acctledgrs) => ({
      headerName: acctledgrs.ldgr_desc,
      cellClassName: "td-desc",
      headerAlign: "center" as GridAlignment,
      align: "right" as GridAlignment,
      field: acctledgrs.id.toString(),
    })) || []),
  ];
  const getRow = () => {
    if (vouchers.length) {
      return [
        {
          id: [],
          date: [],
          admission_no: [],
          receipt_no: [],
          reg_no: [],
          name: [],
          received_from: "Total:",
          receipt_amt: format(totalrcptAmount),
        },
      ];
    }
    return [];
  };
  const pinnedRows = {
    bottom: getRow(),
  };

  const getCellClassName = (params: GridCellParams) => {
    const pinnedRowIds = pinnedRows.bottom
      ? pinnedRows.bottom.map((row) => row.id)
      : [];

    const isPinnedRow = pinnedRowIds.includes(params.row.id);

    if (isPinnedRow) {
      switch (params.field) {
        case "received_from":
          return "total";

        case "receipt_amt":
          return "totalcount";
        default:
          return "total";
      }
    }

    return "";
  };

  useEffect(() => {
    if (state.ActiveFinYr && token) {
      GetLedgers();
    }
  }, [state.ActiveFinYr, GetLedgers, startDate, endDate, token]);

  return (
    <>
      <div className="row g-0 fee-receipt-book__tableblock--title">
        <div className="col-2 ">
          <TextField
            id="Search"
            label="Receipt Number"
            className="fee-receipt-book__tableblock--title--textfield"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            placeholder="Search..."
            onChange={(e) => setV_no(e.target.value)}
          />
        </div>
        <div className="col-2">
          <LabeledAutocomplete
            className={labelClasses.inputRoot}
            options={filterOptions.filter(
              ({ value }) =>
                ((queryType === VoucherQueryTypes.PASSOUT_STD_VOUCHERS ||
                  queryType === VoucherQueryTypes.ENQ_STD_VOUCHERS) &&
                  value !== BankOrCash.ADJ) ||
                (queryType !== VoucherQueryTypes.PASSOUT_STD_VOUCHERS &&
                  queryType !== VoucherQueryTypes.ENQ_STD_VOUCHERS)
            )}
            openOnFocus
            autoHighlight
            value={selectedVtype}
            onChange={(e, newValue) => {
              if (newValue) {
                const data = newValue as optionsType;
                getVoucherType(data.value as BankOrCash);
                setSelectedVtype(newValue as optionsType);
              } else {
                setFilter(EMPTY_STRING);
                set_voucher_query_type(queryType);
                setSelectedVtype(null);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                id="outlined-uncontrolled"
                label="Receipt Types"
                autoFocus
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                fullWidth
                className={labelClasses.formControlRoot}
              />
            )}
          />
        </div>
        <div className="col">
          <Title variant="subtitle1">{state.receiptBookName}</Title>
        </div>
        <div className="col-3 fee-receipt-book__tableblock--title--datefield">
          <TextField
            id="outlined-uncontrolled"
            label="From"
            className="fee-receipt-book__tableblock--title--textfield--date"
            type="date"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
              input: {
                inputProps: {
                  max: toInputStandardDate(TODAY_DATE),
                },
              },
            }}
            value={startDate}
            onChange={handleStartDateChange}
          />

          <TextField
            id="outlined-uncontrolled"
            label="To"
            className="fee-receipt-book__tableblock--title--textfield--date"
            type="date"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
              input: {
                inputProps: {
                  max: toInputStandardDate(TODAY_DATE),
                },
              },
            }}
            value={endDate}
            onChange={handleEndDateChange}
          />
        </div>
      </div>
      <div className={`fee-receipt-book__tableblock--table`}>
        <StyledDatagrid
          columns={columns}
          rows={rows}
          onCellClick={(params) => {
            if (params.field === "name")
              if (params.row.v_std_enquiry) {
                dispatch({
                  type: payloadTypes.SET_STD_ENQUIRY_ID,
                  payload: {
                    studentEnquiryId: params.row.enquiry_student_id,
                  },
                });
                dispatch({
                  type: payloadTypes.SET_STUDENT_ID,
                  payload: {
                    studentId: 0,
                  },
                });
                dispatch({
                  type: payloadTypes.SET_ALUMINI_STUDENT_ID,
                  payload: {
                    aluminiStudentId: 0,
                  },
                });
              }
            if (params.row.v_std_passout_receipt) {
              dispatch({
                type: payloadTypes.SET_ALUMINI_STUDENT_ID,
                payload: {
                  aluminiStudentId: params.row.passout_student_id,
                },
              });
              dispatch({
                type: payloadTypes.SET_STD_ENQUIRY_ID,
                payload: {
                  studentEnquiryId: 0,
                },
              });
              dispatch({
                type: payloadTypes.SET_STUDENT_ID,
                payload: {
                  studentId: 0,
                },
              });
            }
            if (params.row.v_std_receipt) {
              dispatch({
                type: payloadTypes.SET_STUDENT_ID,
                payload: {
                  studentId: params.row.student_id,
                },
              });
              dispatch({
                type: payloadTypes.SET_ALUMINI_STUDENT_ID,
                payload: {
                  aluminiStudentId: 0,
                },
              });
              dispatch({
                type: payloadTypes.SET_STD_ENQUIRY_ID,
                payload: {
                  studentEnquiryId: 0,
                },
              });
            }
            dispatch({
              type: payloadTypes.SET_RECEPIT_ID,
              payload: {
                receiptId: params.row.voucher_id,
              },
            });

            setStudentReceiptModal(!studentReceiptModal);
          }}
          disableRowSelectionOnClick
          disableChildrenSorting
          rowHeight={TABLE_ROW_HEIGHT}
          hideFooter
          pinnedRows={pinnedRows}
          getCellClassName={getCellClassName}
        />
      </div>

      <div className="row g-0">
        <div className="col">
          <Button onClick={hanldeExport} mode="export" />
        </div>
        <div className="col fee-receipt-book__footer--total">
          <div className="student-total-count">
            Total Records:&nbsp; <b>{totalCount ? totalCount : 0} </b>
          </div>
        </div>
      </div>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={studentReceiptModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentDemandList setModalFlag={setStudentReceiptModal} />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStudentReceiptModal(!studentReceiptModal)}
            />
          </div>
        </div>
      </Modal>
      <MessageModal
        handleClose={() => setMessage(emptyMessageType)}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
      <LoadingModal flag={exportloading} />
    </>
  );
};

export default Index;
