import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GetAcctStdDemand } from "../../../queries/students/list/newApi";
import { GetAcctLdgrsByTypeDetails } from "../../../Types/Accounting";
import { Direction, StudentAcctReportType } from "../../../utils/Enum.types";

import {
  StudentNode,
  StudentPageInfo,
} from "../../../customhooks/useStudentsbyNewApi";
import useToken from "../../../customhooks/useToken";
import { AcctStudentQueryType } from "../common/QueryTypes";
import { EMPTY_STRING } from "../../../utils/constants";
import { AppContext } from "../../../context/context";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import {
  branch_details,
  category_details,
  class_details,
  dept_details,
  section_details,
  semester_details,
} from "../../../utils/Form.types";

export interface acct_std_demand {
  id: number;
  std_demand_amt: number;
  std_demand_bal: number;
  std_demand_concession: number;
  std_demand_ob: number;
  std_demand_receivable: number;
  std_demand_received: number;
  std_demand_refunds: number;
  std_deposit_adjusted: number;
  std_deposit_amt: number;
  std_deposit_bal: number;
  std_deposit_ob: number;
  std_deposit_refunded: number;
  std_deposit_total: number;
  no_of_letters: number;
  new_due_date: string;
  remarks: string;
  std_non_demand_collected: number;
}

export interface acct_std_demand_details {
  id: number;
  fee_ob: number;
  fee_demand: number;
  fee_concession: number;
  fee_receivable: number;
  fee_received: number;
  fee_refunds: number;
  fee_bal: number;
  acct_ldgr_details: GetAcctLdgrsByTypeDetails;
  fee_due_date: number;
  acct_ldgr_id: number;
}

export interface StudentDemandNode extends acct_std_demand {
  demand_details: acct_std_demand_details[];
  mst_student: StudentNode;
  isChecked?: boolean;
  class: class_details;
  branch: branch_details;
  semester: semester_details;
  dept: dept_details;
  section: section_details;
  category: category_details;
  std_status: string;
}
export interface StudentDemandEdges {
  node: StudentDemandNode;
}
interface GetAcctStdDemandList {
  edges: StudentDemandEdges[];
  pageInfo: StudentPageInfo;
  totalCount: number;
}
export interface GetAcctStdDemandData {
  GetAcctStdDemand: GetAcctStdDemandList;
}
interface input {
  std_demand_query_type: string;
  ids: number[];
  std_status: string[];
  str_data?: string[];
  int_data?: number[];
  float_data?: string[];
}

export interface vars {
  name: string;
  inst_id: string | number;
  after: string | null;
  sortBy?: string;
  direction: string;
  first: number | null;
  token: string;
  status: string;
  balance: number | null | undefined;
  fin_yr_id: number;
  stdFresher: boolean | null | undefined;
  input: input;
}
const useStudentsbyDemandAmount = (
  departmentId: number,
  branchId: number,
  classId: number,
  semesterId: number,
  sectionId: number,
  categoryId: number,
  name: string,
  rowsPerPage: number | null,
  queryType: StudentAcctReportType,
  acctLdgrId?: number,
  status?: string,
  balance?: number | null,
  stdFresher?: boolean | null,
  float_data?: string
) => {
  const [ids, setId] = useState<number[]>([]);
  const [std_demand_query_type, setstd_query_type] = useState("");
  const { state } = useContext(AppContext);
  const [entryId, setEntryId] = useState(0);
  const { InstId } = useParams();
  const { token } = useToken();
  const [GetStudents, { data, loading, error, fetchMore }] = useLazyQuery<
    GetAcctStdDemandData,
    vars
  >(GetAcctStdDemand, {
    fetchPolicy: "network-only",
  });

  const {
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_DEPARTMENT_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();
  useEffect(() => {
    if (sectionId && USE_SECTION_KEY) {
      setEntryId(sectionId);
    } else if (semesterId && USE_SEMESTER_KEY) {
      setEntryId(semesterId);
    } else if (classId && USE_CLASS_KEY) {
      setEntryId(classId);
    } else if (branchId && USE_BRANCH_KEY) {
      setEntryId(branchId);
    } else if (departmentId && USE_DEPARTMENT_KEY) {
      setEntryId(departmentId);
    } else {
      setEntryId(Number(InstId));
    }
  }, [
    sectionId,
    USE_SECTION_KEY,
    semesterId,
    USE_SEMESTER_KEY,
    classId,
    USE_CLASS_KEY,
    branchId,
    USE_BRANCH_KEY,
    departmentId,
    USE_DEPARTMENT_KEY,
    InstId,
  ]);

  useEffect(() => {
    switch (queryType) {
      case StudentAcctReportType.GENERAL:
        setId([entryId, categoryId]);
        setstd_query_type(AcctStudentQueryType.ACCT_STD_DEMAND);

        break;

      case StudentAcctReportType.PARTIALLY_PAID:
        setId([entryId, categoryId]);
        setstd_query_type(AcctStudentQueryType.PARTIALLY_PAID);

        break;
      case StudentAcctReportType.FEE_ADVANCE:
        setId([entryId, categoryId]);
        setstd_query_type(AcctStudentQueryType.DEPOSIT_BAL);

        break;

      case StudentAcctReportType.DEMAND_RAISED_NOT_PAID:
        setId([entryId, categoryId]);
        setstd_query_type(AcctStudentQueryType.DEMAND_RAISED_NOT_PAID);

        break;
      case StudentAcctReportType.REFUNDS:
        setId([entryId, categoryId]);
        setstd_query_type(AcctStudentQueryType.REFUND);

        break;

      case StudentAcctReportType.DEMAND_RAISED:
        setId([entryId, categoryId]);
        setstd_query_type(AcctStudentQueryType.DEMAND_RAISED);

        break;
      case StudentAcctReportType.PARTIALLY_OR_COMPLETELY_PAID:
        setId([entryId, categoryId]);
        setstd_query_type(AcctStudentQueryType.PARTIALLY_OR_COMPLETELY_PAID);

        break;
      case StudentAcctReportType.CONCESSION:
        setId([entryId, categoryId]);
        setstd_query_type(AcctStudentQueryType.CONCESSION);

        break;
      case StudentAcctReportType.COMPLETELY_PAID:
        setId([entryId, categoryId]);
        setstd_query_type(AcctStudentQueryType.COMPLETELY_PAID);
        break;

      case StudentAcctReportType.FEE_RECEIPT:
        setId([entryId, categoryId]);
        setstd_query_type(AcctStudentQueryType.DEMAND_RAISED_ONLY_BAL);

        break;
      case StudentAcctReportType.BY_NEW_DATE:
        setId([entryId, categoryId]);
        setstd_query_type(AcctStudentQueryType.NEW_DUE_DATE);

        break;

      case StudentAcctReportType.ALL_ANONYMOUS_STUDENTS:
        setstd_query_type(AcctStudentQueryType.ALL_ANONYMOUS_STUDENTS);
        setId([entryId, categoryId]);
        break;
      case StudentAcctReportType.ANONYMOUS_STUDENT:
        setstd_query_type(AcctStudentQueryType.ANONYMOUS_STUDENT);
        setId([entryId, categoryId]);

        break;
      case StudentAcctReportType.SOCIAL_WELFARE_STUDENT:
        setstd_query_type(AcctStudentQueryType.SOCIAL_WELFARE_STUDENT);
        setId([entryId, categoryId]);

        break;
      case StudentAcctReportType.AGENT_STUDENT:
        setstd_query_type(AcctStudentQueryType.AGENT_STUDENT);
        setId([entryId, categoryId]);
        break;

      case StudentAcctReportType.DEMAND_FRESHER:
        setId([entryId, categoryId]);
        setstd_query_type(AcctStudentQueryType.FRESHER_DEMAND_RAISED);

        break;
      case StudentAcctReportType.DEMAND_EXISTING:
        setId([entryId, categoryId]);
        setstd_query_type(AcctStudentQueryType.EXISTING_DEMAND_RAISED);

        break;
      case StudentAcctReportType.DEMAND_NOT_RAISED:
        setId([entryId, categoryId]);
        setstd_query_type(AcctStudentQueryType.DEMAND_NOT_RAISED);

        break;
      case StudentAcctReportType.BY_AGENT:
        setId([entryId, categoryId, state.agentId]);
        setstd_query_type(AcctStudentQueryType.BY_AGENT_ID);
        break;
      case StudentAcctReportType.DEPOSIT_BAL_GT_REQUESTED_AMT:
        setId([entryId, categoryId, acctLdgrId!]);
        setstd_query_type(AcctStudentQueryType.DEPOSIT_BAL_GT_REQUESTED_AMT);
        break;
      default:
        break;
    }
  }, [
    InstId,
    departmentId,
    branchId,
    classId,
    semesterId,
    sectionId,
    categoryId,
    queryType,
    acctLdgrId,
    entryId,
    state.agentId,
  ]);
  useEffect(() => {
    if (state.ActiveFinYr && std_demand_query_type) {
      GetStudents({
        variables: {
          after: null,
          inst_id: InstId!,
          direction: Direction.ASC,
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          first: rowsPerPage === null ? null : rowsPerPage,
          input: {
            ids,
            std_demand_query_type,
            std_status: status ? [status] : [],
            float_data: float_data ? [float_data] : [],
          },
          name,
          token,
          status: EMPTY_STRING,
          balance: balance && balance <= 0 ? null : balance,
          stdFresher: stdFresher != null ? stdFresher : null,
        },
      });
    }
  }, [
    state.ActiveFinYr,
    balance,
    GetStudents,
    stdFresher,
    status,
    name,
    ids,
    rowsPerPage,
    std_demand_query_type,
    token,
    InstId,
    float_data,
  ]);

  return { students: { data, loading, error, fetchMore } };
};

export default useStudentsbyDemandAmount;
