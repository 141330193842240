import React, { useContext, useEffect, useRef, useState } from "react";
import Input from "../../../../stories/Input/Input";
import { Label } from "../../../../stories/Label/Label";
import {
  DebitOrCredit,
  ReceiptTypes,
  VoucherBookKey,
  BankOrCash,
  PredefinedDataTypes,
  Direction,
  SortBy,
  Operation,
  BookReturnType,
  ModuleName,
  PageFor,
  InstitutionType
} from "../../../../utils/Enum.types";

import { Title } from "../../../../stories/Title/Title";
import { Button } from "../../../../stories/Button/Button";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField
} from "@mui/material";
import { useParams } from "react-router-dom";

import { useMutation } from "@apollo/client";
import { AppContext } from "../../../../context/context";
import {
  DateRange,
  formatter,
  handleMUISelectEvent,
  NameOfTheDay,
  toInputStandardDate,
  toIsoDate
} from "../../../../utils/UtilFunctions";
import {
  DEFAULT_TIME,
  EMPTY_OPTIONSTYPE_OBJECT,
  EMPTY_RESPONSETYPE_OBJECT,
  EMPTY_STRING,
  ROWS_PER_PAGE,
  TODAY_DATE
} from "../../../../utils/constants";
import { AddAcctVoucherMasterAndDetails } from "../../queries/receipts/mutation/Index";
import dayjs from "dayjs";

import { TableHeaderProps } from "../../../../Types/Tables";

import Avatar from "../../../../images/Avatar.svg";

import useStudentDatabyId from "../../../../customhooks/useStudentDatabyId";
import DownArrow from "../../../../images/DownArrow.svg";
import {
  msgType,
  optionsType,
  responseType
} from "../../../../utils/Form.types";
import useAcctLedgerData from "../../hooks/useAcctLedgerData";
import useToken from "../../../../customhooks/useToken";
import { Keys } from "../../../../utils/Enum.keys";
import usePredefinedDataByType from "../../../../customhooks/usePredefinedDataByType";
import { getDownloadUrl } from "../../../../utils/DownloadFile";
import { payloadTypes } from "../../../../context/reducer";
import useVoucherNumber from "../../hooks/useVoucherNumber";
import {
  GetAcctVouchers,
  GetVoucherNumber
} from "../../queries/Vouchers/query";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import { AcctLedgerQueryType } from "../../common/QueryTypes";
import useAcctLdgrsForCaptation from "../../hooks/useAcctLdgrsForCaptation";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import FeeReceiptPrint from "../../../Print/Accounts/FeeReceipts/Standard/FeeReceiptPrint";
import {
  InstitutionAcctConfigurationTypes,
  VoucherQueryTypes
} from "../../common/Enum.types";
import { SwConfigQueryType } from "../../../HR/enums/Enum.types";
import Configurations from "../../../Configurations/PerModuleConfiguration";
import Modal from "react-modal";
import {
  PrintConfigModalStyles,
  PrintModalStyles
} from "../../../../styles/ModalStyles";
import Close from "../../../../images/Close.svg";
import Settings from "../../../../images/Settings.svg";
import MessageModal from "../../../../pages/MessageModal";
import { GlobalPageConfigData } from "../../../../Types/configtypes";
import TextArea from "../../../../stories/TextArea/TextArea";
import useAcctTableJson from "../../json/useAcctTableJson";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import useLoggedInUserDetails from "../../hooks/useLoggedInUserDetails";
import {
  AddAcctVoucherMasterAndDetailsData,
  AddAcctVoucherMasterAndDetailsVars
} from "../../../../Types/Accounting/mutations";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import {
  FormAutocomplete,
  formClasses
} from "../../../../styles/AutocompleteStyles";
import useSwConfigInstType from "../../../Academics/hooks/useSwConfigInstType";
interface Props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  hanldeBookReturn: (type: string, voucher_master_id: number) => void;
  totalFineAmt: number;
}

const ledgerOptions: optionsType[] = [
  { label: "Cash Book", value: BankOrCash.CASH },

  { label: "Bank Book", value: BankOrCash.BANK }
];

const CollectLibraryFine = ({
  setModalFlag,
  hanldeBookReturn,
  totalFineAmt
}: Props) => {
  const { format } = formatter;
  let DefaultDate = new Date();
  const { institutionType } = useSwConfigInstType();

  const dates = DateRange(DefaultDate.toString());

  const { Accounts_Table } = useAcctTableJson();
  const { InstId } = useParams<{ InstId: string }>();
  const { dispatch, state } = useContext(AppContext);
  const { token } = useToken();
  const [imageString, setImageString] = useState("");
  const [printModal, SetPrintModal] = useState(false);
  const [printConfig, setPrintConfigModal] = useState(false);

  const { InstDetails } = useInstDetails(1);
  //use States for modal
  const [feeLedgerModal, setFeeledgerModal] = useState(false);
  const [voucher_date, set_v_Date] = useState("");
  const [voucher_no, set_v_no] = useState("");
  //configuration modal
  const [referenceNumber, setReferenceNumber] = useState("");
  const [rcptDate, setRcptDate] = useState("");

  const [ledgerType, setLedgerType] = useState(EMPTY_OPTIONSTYPE_OBJECT);
  const [cashAccount, setCashAccount] = useState(false);
  const [bankAccount, setBankAccount] = useState(false);
  const [bankTransactionType, setBankTransactionType] = useState<optionsType>(
    EMPTY_OPTIONSTYPE_OBJECT
  );
  const [searchData, setsearchData] = useState("");

  const feeDescRef = useRef<HTMLSelectElement>(null);
  const modeOfTransactionRef = useRef<HTMLSelectElement>(null);
  const modeOfTransactionInputRef = modeOfTransactionRef.current?.childNodes[0]
    .childNodes[0].childNodes[0] as HTMLInputElement;

  const transactionRefNumberRef = useRef<HTMLInputElement>(null);
  const remarksRef = useRef<HTMLTextAreaElement>(null);
  const saveButtonRef = useRef<HTMLButtonElement>(null);
  const [transactionBookLedgerId, setTransactionLedgerId] =
    useState<responseType>(EMPTY_RESPONSETYPE_OBJECT);
  const [remarks, setRemarks] = useState("");
  const [account_ledger_id, SetAccountLedgerId] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE
  });
  const [ledgerList, setLedgerList] = useState<responseType[]>([]);
  const { user_details } = useLoggedInUserDetails();
  let totalAmount = 0;
  const { configData: configKeys } = useSwConfigData([
    InstitutionAcctConfigurationTypes.STD_RECEIPT_EDIT_DATE,
    InstitutionAcctConfigurationTypes.HIDE_CAPITATION_FEE
  ]);
  const filterDataByConfigKey = (data: GlobalPageConfigData[]) => {
    let editDate = false;
    let hideCaptationFee = false;
    if (data) {
      data.forEach((item) => {
        switch (item.config_key) {
          case InstitutionAcctConfigurationTypes.STD_RECEIPT_EDIT_DATE:
            editDate = item.config_boolean_value;
            break;
          case InstitutionAcctConfigurationTypes.HIDE_CAPITATION_FEE:
            hideCaptationFee = item.config_boolean_value;
            break;

          default:
            break;
        }
      });
    }
    return {
      editDate,
      hideCaptationFee
    };
  };
  const { hideCaptationFee, editDate } = filterDataByConfigKey(
    configKeys.data?.GetSwConfigVariables!
  );
  //use queries
  const { studentData, studentFormData } = useStudentDatabyId();
  const { NonDemandCapLedgers } = useAcctLdgrsForCaptation(
    hideCaptationFee
      ? AcctLedgerQueryType.NON_CAPITATION_FEE_STD_LDGRS
      : AcctLedgerQueryType.ALL_STD_FEE_ACCT_LDGRS_FOR_NON_DEMAND
  );
  const { acctLedgers: CashLedgers } = useAcctLedgerData(
    searchData,
    AcctLedgerQueryType.ACCT_LDGRS_CASH,
    ROWS_PER_PAGE
  );
  const { acctLedgers: BankLedgers } = useAcctLedgerData(
    searchData,
    AcctLedgerQueryType.ACCT_LDGRS_BANK,
    ROWS_PER_PAGE
  );
  const {
    PredefinedData: { dropDown: bankTransactionTypes }
  } = usePredefinedDataByType(
    PredefinedDataTypes.BANK_COLLECTION,
    EMPTY_STRING
  );
  const { data: serverDateData, loading: serverDateLoading } =
    useServerDateandTime();

  const { voucherNumber } = useVoucherNumber(
    VoucherBookKey.STUDENT_COMMAN_RECEIPT_BOOK
  );
  const { USE_CATEGORY_KEY } = useInstitutionConfiguration();

  //use mutation
  const [GenerateStudentNonDemandReceipts] = useMutation<
    AddAcctVoucherMasterAndDetailsData,
    AddAcctVoucherMasterAndDetailsVars
  >(AddAcctVoucherMasterAndDetails, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE
      })
  });

  // eslint-disable-next-line

  const handleStudentReceipts = (type: BookReturnType) => {
    const res = bankTransactionTypes.find(
      ({ value }) => value === bankTransactionType.value
    );
    GenerateStudentNonDemandReceipts({
      variables: {
        token,
        inst_id: InstId!,
        user_details,
        fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
        input: [
          {
            acct_voucher_master: {
              fin_yr: state.ActiveFinYr
                ? state.ActiveFinYr.fin_yr
                : EMPTY_STRING,

              v_type: ReceiptTypes.STUDENT_RECEIPT,
              v_no: voucherNumber.data?.GetVoucherNumber.vo_number!,
              v_date: toIsoDate(rcptDate),
              v_total_cr_amt: totalFineAmt,
              v_total_db_amt: totalFineAmt,
              v_std_deposit_adjusted: false,
              v_reconciled: false,
              v_std_anonymous_deposit_adjusted: false,

              v_reconciled_date: dayjs(DEFAULT_TIME).format(),
              v_std_receipt: true,
              v_std_enquiry: false,
              enquiry_student_id: 0,
              v_std_non_demand_receipt: true,
              v_std_refund: false,
              v_std_receipt_ob: false,
              v_book_type: VoucherBookKey.STUDENT_COMMAN_RECEIPT_BOOK,
              v_std_deposit: false,
              v_std_demand_receipt: false,
              student_id: state.studentId ? state.studentId : 0,
              passout_student_id: state.aluminiStudentId
                ? state.aluminiStudentId
                : 0,
              v_std_scholarship_deposit: false,

              v_std_passout_receipt: false,
              class_id: studentData.data?.nodes[0].class.id,
              semester_id: studentData.data?.nodes[0].semester.id,
              v_std_amt_receipt: totalAmount,
              v_std_amt_deposit: 0,
              v_std_amt_fine: 0,
              v_std_amt_total: totalAmount,
              v_std_amt_refunded: 0,
              v_std_amt_adjusted: 0,
              v_transcation_type: res?.label ?? "Cash Book",
              v_transcation_cash_or_bank: res
                ? BankOrCash.BANK
                : BankOrCash.CASH,
              v_std_refund_deposit: false,
              v_std_receipt_anonymous: false,
              v_transcation_no: referenceNumber,
              v_transcation_date: dayjs(rcptDate).format(),
              v_transcation_narration: remarks,
              // paid_party_id: "",
              // party_bill_no: "",
              party_bill_date: dayjs(DEFAULT_TIME).format(),
              // party_name: "",
              annx_yesno: false,
              // annx_id: Math.random() * 1000,
              is_vouch_multi_entry: false,
              acct_ldgr_id_cr: account_ledger_id.value,
              acct_ldgr_id_db: transactionBookLedgerId.value
            },
            acct_voucher_db: [
              {
                vo_cr_db: DebitOrCredit.DEBIT,
                vo_sl_no: 0,
                vo_cr: 0,
                vo_db: totalFineAmt,
                vo_cr_total: 0,
                vo_db_total: totalFineAmt,
                acct_ldgr_id: transactionBookLedgerId.value
              }
            ],
            acct_voucher_cr: [
              {
                vo_cr_db: DebitOrCredit.CREDIT,
                vo_sl_no: 0,
                vo_cr: totalFineAmt,
                vo_db: 0,
                vo_cr_total: totalFineAmt,
                vo_db_total: 0,
                acct_ldgr_id: account_ledger_id.value
              }
            ]
          }
        ]
      },
      refetchQueries: [
        {
          query: GetAcctVouchers,
          variables: {
            after: null,
            direction: Direction.ASC,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            first: ROWS_PER_PAGE,
            sortBy: SortBy.V_DATE,
            token,
            name: EMPTY_STRING,
            input: {
              inst_id: InstId!,
              voucher_query_type: VoucherQueryTypes.STD_NON_DEMAND_RECEIPTS,
              vo_end_date: dayjs(dates?.lastDay!).format(),
              acct_ldgr_id: 0,
              vo_start_date: dayjs(dates?.firstDay!).format(),
              vo_type: EMPTY_STRING
            }
          }
        },
        {
          query: GetVoucherNumber,
          variables: {
            token,
            inst_id: InstId!,
            vo_book_key: VoucherBookKey.STUDENT_COMMAN_RECEIPT_BOOK,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0
          }
        }
      ]
    }).then(({ data }) => {
      if (data) {
        const { v_no, v_date, id } = data.AddAcctVoucherMasterAndDetails[0];
        hanldeBookReturn(type, id);
        dispatch({
          type: payloadTypes.SET_RECEPIT_ID,
          payload: { receiptId: id }
        });
        set_v_Date(v_date);
        set_v_no(v_no);
        !printModal ? SetPrintModal(!printModal) : handleClear();
      }
    });
  };

  const handleClear = () => {
    dispatch({
      type: payloadTypes.SET_STUDENT_ID,
      payload: {
        studentId: 0
      }
    });
    setLedgerType(EMPTY_OPTIONSTYPE_OBJECT);
    setBankTransactionType(EMPTY_OPTIONSTYPE_OBJECT);
    setReferenceNumber(EMPTY_STRING);
    setTransactionLedgerId(EMPTY_RESPONSETYPE_OBJECT);
  };

  useEffect(() => {
    if (NonDemandCapLedgers.data) {
      setLedgerList(NonDemandCapLedgers.responseType);
    }
  }, [NonDemandCapLedgers]);
  useEffect(() => {
    if (
      studentData.data &&
      studentData.data?.nodes[0].std_profile_filename !== "" &&
      InstDetails.data
    ) {
      // eslint-disable-next-line
      const studentProfiePicUrl = `${InstDetails.data?.nodes[0]?.inst_name}/students/${state.studentId}/std_profile_pic/std_profile_pic`;
      // eslint-disable-next-line
      const ers = getDownloadUrl(studentProfiePicUrl, false, setImageString);
    }
  }, [studentData.data, InstDetails.data, state.studentId]);
  useEffect(() => {
    if (serverDateData && !serverDateLoading) {
      setRcptDate(serverDateData.GetServerDateAndTime);
    }
  }, [serverDateData, serverDateLoading]);
  const { branchLabel, classLabel, categoryLabel } = useInstLabels();
  return (
    <>
      <div className="row g-0">
        <div className="col">
          <Title>Library Receipt</Title>
        </div>
      </div>
      <div className="non-demandfee-payable--modal">
        <div className={"row g-0 non-demandfee-payable__select"}>
          <div className="col-4 ">
            <div className="non-demandfee-payable__grid">
              <TextField
                label="Admission No."
                className="demand-fee__textfield"
                slotProps={{
                  inputLabel: {
                    shrink: true
                  }
                }}
                value={studentFormData?.adm_no ?? EMPTY_STRING}
                disabled
              />
            </div>
            {institutionType !== InstitutionType.SCHOOL && (
              <TextField
                label="Register Number"
                className="demand-fee__textfield"
                slotProps={{
                  inputLabel: {
                    shrink: true
                  }
                }}
                value={studentFormData?.reg_number ?? EMPTY_STRING}
                disabled
              />
            )}
            {USE_CATEGORY_KEY && (
              <TextField
                label={categoryLabel}
                className="demand-fee__textfield"
                slotProps={{
                  inputLabel: {
                    shrink: true
                  }
                }}
                value={studentFormData?.category ?? EMPTY_STRING}
                disabled
              />
            )}
          </div>

          <div className="col">
            <TextField
              label="Name"
              className="demand-fee__textfield"
              slotProps={{
                inputLabel: {
                  shrink: true
                }
              }}
              value={studentFormData?.std_name}
              disabled
            />

            <TextField
              label={branchLabel}
              className="demand-fee__textfield"
              slotProps={{
                inputLabel: {
                  shrink: true
                }
              }}
              value={studentFormData?.branch}
              disabled
            />
            <TextField
              label={classLabel}
              className="demand-fee__textfield"
              slotProps={{
                inputLabel: {
                  shrink: true
                }
              }}
              value={studentFormData?.class}
              disabled
            />
          </div>
          <div className="col-3 ">
            <div className="non-demandfee-payable__label-grid">
              <TextField
                type="date"
                label="Date"
                className="demand-fee__textfield--date"
                slotProps={{
                  inputLabel: {
                    shrink: true
                  },
                  input: {
                    inputProps: {
                      min: state.ActiveFinYr
                        ? toInputStandardDate(state.ActiveFinYr.fin_st_date!)
                        : EMPTY_STRING,
                      max: toInputStandardDate(TODAY_DATE)
                    }
                  }
                }}
                value={toInputStandardDate(rcptDate)}
                onChange={(e) => setRcptDate(e.target.value)}
                disabled={editDate ? false : true}
              />
              <Label variant="present-day">{NameOfTheDay(rcptDate)}</Label>
            </div>
            <div className="non-demandfee-payable__label-gridbtn">
              <Button
                onClick={() => setFeeledgerModal(!feeLedgerModal)}
                mode="ledger"
              />
            </div>
          </div>
          <div className="col-1 non-demandfee-payable__image h-100">
            {imageString === EMPTY_STRING ? (
              <img src={Avatar} alt="/" />
            ) : (
              <img src={imageString} alt="/" />
            )}
          </div>
        </div>
        <div className={"row g-0 non-demandfee-payable__tableblock"}>
          <TableContainer className="non-demandfee-payable__table g-0">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Accounts_Table.Receipts.NonDemand.Table_Headers.filter(
                    (th: TableHeaderProps) =>
                      th.labelName !== "Sl" && th.labelName !== "Actions"
                  ).map((th: TableHeaderProps, index: React.Key) => {
                    return <TableCell key={index}>{th.labelName}</TableCell>;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow className="non-demandfee-payable__table--select-row">
                  <TableCell className="non-demandfee-payable__table--desc">
                    <FormAutocomplete
                      className={formClasses.inputRoot}
                      options={
                        ledgerList?.filter(({ isChecked }) => !isChecked)! || []
                      }
                      openOnFocus
                      autoHighlight
                      ref={feeDescRef}
                      disabled={
                        !state.studentId || !state.aluminiStudentId
                          ? false
                          : true
                      }
                      onKeyDown={(e: React.KeyboardEvent) => {
                        if (e.key === Keys.ENTER) {
                          if (account_ledger_id.value) {
                            modeOfTransactionInputRef?.focus();
                          }
                        }
                        if (e.key === Keys.BACKSPACE) {
                          SetAccountLedgerId(EMPTY_RESPONSETYPE_OBJECT);
                        }
                      }}
                      value={account_ledger_id}
                      onChange={(e, newValue) => {
                        if (newValue) {
                          const data = newValue as responseType;
                          SetAccountLedgerId({
                            label: data.label,
                            value: data.value,
                            isChecked: true
                          });
                        } else {
                          SetAccountLedgerId(EMPTY_RESPONSETYPE_OBJECT);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          autoFocus
                          onChange={(e) => setsearchData(e.target.value)}
                          className={formClasses.formControlRoot}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell
                    id="td-right"
                    className="non-demandfee-payable__table--balance"
                  >
                    {format(totalFineAmt)}
                  </TableCell>
                </TableRow>
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell className="total">Total :</TableCell>
                  <TableCell id="td-right" className="totalcount">
                    {format(totalFineAmt)}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
        <form className={"row g-0 non-demandfee-payable__receiptblock"}>
          <div className="col account-frames h-100">
            <div className="label-grid">
              <Label>Receipt No.</Label>
              <Input
                value={voucherNumber.data?.GetVoucherNumber.vo_number}
                disabled
              />
            </div>
            <div className="label-grid">
              <Label> Mode of Transaction</Label>
              <FormAutocomplete
                className={formClasses.inputRoot}
                options={ledgerOptions || []}
                openOnFocus
                ref={modeOfTransactionRef}
                autoHighlight
                value={ledgerType}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setLedgerType(newValue as optionsType);
                    setTransactionLedgerId(EMPTY_RESPONSETYPE_OBJECT);
                    if ((newValue as optionsType).value === BankOrCash.BANK) {
                      setBankAccount(true);
                      setCashAccount(false);
                    } else {
                      setCashAccount(true);
                      setBankAccount(false);
                    }
                  } else {
                    setLedgerType(EMPTY_OPTIONSTYPE_OBJECT);
                  }
                }}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === Keys.ENTER && ledgerType.value) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setLedgerType(EMPTY_OPTIONSTYPE_OBJECT);
                  }
                }}
                popupIcon={<img src={DownArrow} alt="/" />}
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    className={formClasses.formControlRoot}
                  />
                )}
              />
            </div>
          </div>
          {cashAccount ? (
            <div className="col account-frames label-grid h-100">
              <Label> Cash Ledgers</Label>
              <FormAutocomplete
                className={formClasses.inputRoot}
                options={CashLedgers.responseType! || []}
                openOnFocus
                autoHighlight
                // ref={cashAccount ? cashLedgerRef : null}
                value={transactionBookLedgerId}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setTransactionLedgerId(newValue as responseType);
                    setBankTransactionType(EMPTY_OPTIONSTYPE_OBJECT);
                  } else {
                    setTransactionLedgerId(EMPTY_RESPONSETYPE_OBJECT);
                  }
                }}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (transactionBookLedgerId.value && e.key === Keys.ENTER) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setTransactionLedgerId(EMPTY_RESPONSETYPE_OBJECT);
                  }
                }}
                popupIcon={<img src={DownArrow} alt="/" />}
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    onChange={(e) => setsearchData(e.target.value)}
                    className={formClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {bankAccount ? (
            <div className="col account-frames h-100">
              <div className="student-fee-receipt__receiptblock--frame--bankdetails">
                <Label> Bank Transaction Type</Label>
                <FormAutocomplete
                  className={formClasses.inputRoot}
                  options={bankTransactionTypes!}
                  openOnFocus
                  autoHighlight
                  value={bankTransactionType}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setBankTransactionType(newValue as optionsType);
                    } else {
                      setBankTransactionType(EMPTY_OPTIONSTYPE_OBJECT);
                    }
                  }}
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (ledgerType && e.key === Keys.ENTER) {
                      handleMUISelectEvent(e);
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setBankTransactionType(EMPTY_OPTIONSTYPE_OBJECT);
                    }
                  }}
                  popupIcon={<img src={DownArrow} alt="/" />}
                  forcePopupIcon
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      className={formClasses.formControlRoot}
                    />
                  )}
                />
              </div>
              <div className="student-fee-receipt__receiptblock--frame--bankdetails">
                <Label> Bank Ledgers</Label>
                <FormAutocomplete
                  className={formClasses.inputRoot}
                  options={BankLedgers.responseType!}
                  openOnFocus
                  autoHighlight
                  value={transactionBookLedgerId}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setTransactionLedgerId(newValue as responseType);
                    } else {
                      setTransactionLedgerId(EMPTY_RESPONSETYPE_OBJECT);
                    }
                  }}
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (transactionBookLedgerId.value && e.key === Keys.ENTER) {
                      handleMUISelectEvent(e);
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setTransactionLedgerId(EMPTY_RESPONSETYPE_OBJECT);
                    }
                  }}
                  popupIcon={<img src={DownArrow} alt="/" />}
                  forcePopupIcon
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      onChange={(e) => setsearchData(e.target.value)}
                      className={formClasses.formControlRoot}
                    />
                  )}
                />
              </div>
              <div className="student-fee-receipt__receiptblock--frame--bankdetails">
                <Label>Transaction Reference Number</Label>
                <Input
                  inputRef={transactionRefNumberRef}
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (e.key === Keys.ENTER) {
                      handleMUISelectEvent(e);
                    }
                  }}
                  type="text"
                  value={referenceNumber}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setReferenceNumber(e.target.value)
                  }
                />
              </div>
            </div>
          ) : null}
          <div className="col account-frames label-grid h-100">
            <Label>Remarks :</Label>
            <TextArea
              textAreaRef={remarksRef}
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === Keys.ENTER) {
                  saveButtonRef.current?.focus();
                }
              }}
              onChange={(e) => setRemarks(e.target.value)}
              rows={3}
            />
          </div>
          <div>
            <Button
              mode="return-book"
              disabled={!transactionBookLedgerId.value}
              onClick={(e: React.FormEvent) => {
                e.preventDefault();
                handleStudentReceipts(BookReturnType.RETURN);
              }}
              buttonref={saveButtonRef}
            />
            <Button
              mode="renewal-book"
              disabled={!transactionBookLedgerId.value}
              onClick={(e: React.FormEvent) => {
                e.preventDefault();
                handleStudentReceipts(BookReturnType.RENEWAL);
              }}
            />
            <Button mode="clear" onClick={handleClear} />
            <Button
              mode="cancel"
              type="button"
              onClick={() => setModalFlag(false)}
            />
          </div>
        </form>
        <Modal
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          isOpen={printModal}
          style={PrintModalStyles}
        >
          <div className="modal-flex h-100">
            <div className="modal-flex__data h-100">
              <FeeReceiptPrint
                tableData={[
                  {
                    amount: totalFineAmt,
                    particular: account_ledger_id.label
                  }
                ]}
                v_date={voucher_date}
                v_no={voucher_no}
                transaction_no={referenceNumber}
              />
            </div>
            <div className="modal-flex__image">
              <img
                src={Close}
                alt="/"
                className="modal-close-icon"
                onClick={() => {
                  SetPrintModal(!printModal);
                  setModalFlag(false);
                  handleClear();
                }}
              />
              <img
                src={Settings}
                alt="/"
                id="settings-icon"
                onClick={() => setPrintConfigModal(!printConfig)}
              />
            </div>
          </div>
        </Modal>
        <Modal
          shouldCloseOnOverlayClick={true}
          isOpen={printConfig}
          style={PrintConfigModalStyles}
          ariaHideApp={false}
        >
          <div className="modal-flex h-100">
            <div className="modal-flex__data h-100">
              <Configurations
                config_query_type={SwConfigQueryType.INST_BY_MODULE}
                str_value={ModuleName.RECEIPT_PRINT}
                int_value={0}
                setModalFlag={setPrintConfigModal}
                pageType={PageFor.MODAL}
              />
            </div>
            <div className="modal-flex__image">
              <img
                src={Close}
                alt="/"
                onClick={() => setPrintConfigModal(!printConfig)}
              />
            </div>
          </div>
        </Modal>
        <MessageModal
          handleClose={() => {
            setMessage({
              flag: false,
              message: "",
              operation: Operation.NONE
            });
          }}
          modalFlag={message.flag}
          operation={message.operation}
          value={message.message}
        />
      </div>
    </>
  );
};
export default CollectLibraryFine;
