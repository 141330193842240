import React, { useContext, useEffect, useRef, useState } from "react";
import { Title } from "../../../../stories/Title/Title";
import Home from "../../Home/Index";
import Edit from "../../../../images/Edit.svg";
import {
  PrintModalStyles,
  StudentModalStyles,
} from "../../../../styles/ModalStyles";

import Modal from "react-modal";
import {
  BankOrCash,
  DebitOrCredit,
  Direction,
  Operation,
  PageFor,
  PredefinedDataTypes,
  ReceiptTypes,
  SortBy,
  StudentReportType,
  VoucherBookKey,
} from "../../../../utils/Enum.types";
import Close from "../../../../images/Close.svg";
import TransportStdList from "../../../Transport/MasterData/AssignRoute/List";
import { GetStudentEnquiresByEnquiryId } from "../../../../queries/students/list/newApi";
import { useLazyQuery, useMutation } from "@apollo/client";
import useToken from "../../../../customhooks/useToken";
import {
  DEFAULT_TIME,
  EMPTY_OPTIONSTYPE_OBJECT,
  EMPTY_RESPONSETYPE_OBJECT,
  EMPTY_STRING,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";
import useVoucherNumber from "../../hooks/useVoucherNumber";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import DownArrow from "../../../../images/DownArrow.svg";
import {
  msgType,
  optionsType,
  responseType,
} from "../../../../utils/Form.types";
import { useParams } from "react-router-dom";
import { Keys } from "../../../../utils/Enum.keys";

import { Label } from "../../../../stories/Label/Label";
import Input from "../../../../stories/Input/Input";
import { ledgerOptions } from "./Index";
import {
  formClasses,
  RequiredAutoComplete,
} from "../../../../styles/AutocompleteStyles";
import useAcctLedgerData from "../../hooks/useAcctLedgerData";
import usePredefinedDataByType from "../../../../customhooks/usePredefinedDataByType";
import { Button } from "../../../../stories/Button/Button";
import { GetAcctVouchers } from "../../queries/Vouchers/query";
import { AddAcctVoucherMasterAndDetails } from "../../queries/receipts/mutation/Index";
import dayjs from "dayjs";
import LoadingModal from "../../../../pages/LoadingModal";
import MessageModal from "../../../../pages/MessageModal";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import { AppContext } from "../../../../context/context";
import { payloadTypes } from "../../../../context/reducer";
import {
  handleMUISelectEvent,
  toInputStandardDate,
} from "../../../../utils/UtilFunctions";
import { AcctLedgerQueryType } from "../../common/QueryTypes";
import Print from "../../../Print/Accounts/FeeReceipts/Standard/FeeReceiptPrint";

import {
  GetStudentTransportByData,
  GetTransportStudentByVars,
} from "../../../Transport/paginationTypes";
import { GetTransportStudent } from "../../../Transport/queries/list";
import useTransStudentData from "../../../Academics/hooks/useTransStudentData";
import { TableHeaderProps } from "../../../../Types/Tables";
import { Data } from "../../../Print/Accounts/FeeReceipts/templates/Index";
import { VoucherQueryTypes } from "../../common/Enum.types";
import TextArea from "../../../../stories/TextArea/TextArea";
import useAcctTableJson from "../../json/useAcctTableJson";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import useLoggedInUserDetails from "../../hooks/useLoggedInUserDetails";
import {
  AddAcctVoucherMasterAndDetailsData,
  AddAcctVoucherMasterAndDetailsVars,
} from "../../../../Types/Accounting/mutations";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";

interface IObjectKeys {
  [key: string]: string | number;
}
interface formData extends IObjectKeys {
  receiptnumber: string;
  studentname: string;
  applicationFee: number;
  classId: number;
  semesterId: number;
  paidThrough: string;
  dd_chq_no: string;
  narration: string;
}
interface Props {
  pageType: PageFor;
  setFeeReceiptModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const TransportStudentReceipt = ({ pageType, setFeeReceiptModal }: Props) => {
  const { Accounts_Table } = useAcctTableJson();
  const { InstId } = useParams();
  const { token } = useToken();
  const { dispatch, state } = useContext(AppContext);
  const [studentModal, setStudentModal] = useState(false);
  const [searchData, setSearchData] = useState("");

  const { serverDate } = useServerDateandTime();
  const { voucherNumber } = useVoucherNumber(
    VoucherBookKey.STUDENT_COMMAN_RECEIPT_BOOK
  );

  //use states for receipts

  const [narration, setNarration] = useState("");
  const [formData] = useState<formData>({
    receiptnumber: voucherNumber.data?.GetVoucherNumber.vo_number!,
    applicationFee: 0,
    studentname: "",
    classId: 0,
    semesterId: 0,
    paidThrough: "",
    dd_chq_no: "",
    narration: "",
  });

  const [ledgerType, setLedgerType] = useState(EMPTY_OPTIONSTYPE_OBJECT);
  const [cashAccount, setCashAccount] = useState(false);
  const [bankAccount, setBankAccount] = useState(false);

  const [bankTransactionType, setBankTransactionType] = useState<optionsType>(
    EMPTY_OPTIONSTYPE_OBJECT
  );

  const [referenceNumber, setReferenceNumber] = useState("");
  const [transactionBookLedgerId, setTransactionLedgerId] =
    useState<responseType>(EMPTY_RESPONSETYPE_OBJECT);

  const modeOfTransactionRef = useRef<HTMLSelectElement>(null);

  const { TransportStudentData } = useTransStudentData(
    0,
    0,
    0,
    0,
    0,
    ROWS_PER_PAGE,
    searchData,
    StudentReportType.TRANSPORT_ASSIGNED
  );
  const studentDropDown = TransportStudentData?.data?.GetTransportStd.edges.map(
    (res) => ({
      label: `(${res.node.student_details.std_adm_no}) ${res.node.student_details.first_name}`,
      value: res.node.student_id,
    })
  );
  const [GetTransportStudentData, { data }] = useLazyQuery<
    GetStudentTransportByData,
    GetTransportStudentByVars
  >(GetTransportStudent, {
    variables: {
      token,
      student_id: state.studentId,
    },
  });
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const { user_details } = useLoggedInUserDetails();

  const { acctLedgers: CashLedgers } = useAcctLedgerData(
    searchData,
    AcctLedgerQueryType.ACCT_LDGRS_CASH,
    ROWS_PER_PAGE
  );
  const { acctLedgers: BankLedgers } = useAcctLedgerData(
    searchData,
    AcctLedgerQueryType.ACCT_LDGRS_BANK,
    ROWS_PER_PAGE
  );
  const { acctLedgers: OnlyApplicationLedger } = useAcctLedgerData(
    searchData,
    AcctLedgerQueryType.ONLY_APPLICATION_FEE_LDGR,
    ROWS_PER_PAGE
  );

  const {
    PredefinedData: { dropDown: bankTransactionTypes },
  } = usePredefinedDataByType(
    PredefinedDataTypes.BANK_COLLECTION,
    EMPTY_STRING
  );

  const saveButtonRef = useRef<HTMLButtonElement>(null);

  const [GenerateStudentReceipts, { loading: GenerateStudentReceiptsLoading }] =
    useMutation<
      AddAcctVoucherMasterAndDetailsData,
      AddAcctVoucherMasterAndDetailsVars
    >(AddAcctVoucherMasterAndDetails, {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    });
  const ApplicationData = [formData];

  const [printModal, SetPrintModal] = useState(false);

  const [tableData, setTableData] = useState<Data[]>([]);
  const [v_no, set_vo_no] = useState("");
  const [v_date, set_v_Date] = useState("");
  const handleStudentReceipts = () => {
    const res = bankTransactionTypes.find(
      ({ value }) => value === bankTransactionType.value
    );
    const amount = data
      ? data?.GetTransportStudent.route_fee_details.route_fee
      : 0;
    GenerateStudentReceipts({
      variables: {
        token,
        inst_id: InstId!,
        user_details,
        fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
        input: [
          {
            acct_voucher_master: {
              fin_yr: state.ActiveFinYr
                ? state.ActiveFinYr.fin_yr
                : EMPTY_STRING,

              v_type: ReceiptTypes.STUDENT_RECEIPT,
              v_no: voucherNumber.data?.GetVoucherNumber.vo_number!,
              v_date: dayjs(serverDate).format(),
              v_total_cr_amt: amount,
              v_total_db_amt: amount,
              v_reconciled: false,

              v_reconciled_date: dayjs(DEFAULT_TIME).format(),
              v_std_receipt: true,
              v_std_non_demand_receipt: false,
              v_std_anonymous_deposit_adjusted: false,
              v_std_refund_deposit: false,
              v_std_refund: false,
              v_std_enquiry: true,
              v_std_receipt_ob: false,
              enquiry_student_id: state.studentEnquiryId,
              v_std_deposit_adjusted: false,
              v_book_type: VoucherBookKey.STUDENT_COMMAN_RECEIPT_BOOK,
              v_std_deposit: false,
              v_std_passout_receipt: false,
              v_std_demand_receipt: false,
              v_std_scholarship_deposit: false,
              v_std_receipt_anonymous: false,
              student_id: 0,
              class_id: 0,
              semester_id: 0,
              v_std_amt_receipt: amount,
              v_std_amt_deposit: 0,
              v_std_amt_fine: 0,
              v_std_amt_total: amount,
              v_std_amt_refunded: 0,
              v_std_amt_adjusted: 0,
              v_transcation_type: res?.label ?? "Cash Book",
              v_transcation_cash_or_bank: res
                ? BankOrCash.BANK
                : BankOrCash.CASH,
              v_transcation_no: referenceNumber,
              v_transcation_date: dayjs(serverDate).format(),
              v_transcation_narration: narration,
              // paid_party_id: "",
              // party_bill_no: "",
              party_bill_date: dayjs(DEFAULT_TIME).format(),
              // party_name: "",
              annx_yesno: false,
              // annx_id: Math.random() * 1000,
              is_vouch_multi_entry: false,
              acct_ldgr_id_cr:
                OnlyApplicationLedger.data?.GetAcctLdgrs.edges[0].node.id,
              acct_ldgr_id_db: transactionBookLedgerId.value,
            },
            acct_voucher_db: ApplicationData.map((receipt, index) => ({
              vo_cr_db: DebitOrCredit.DEBIT,
              vo_sl_no: index + 1,
              vo_cr: 0,
              vo_db: amount,
              vo_cr_total: 0,
              vo_db_total: amount,
              acct_ldgr_id: transactionBookLedgerId.value,
            })),
            acct_voucher_cr: ApplicationData.map((item, index) => ({
              vo_sl_no: index + 1,
              vo_cr_db: DebitOrCredit.CREDIT,
              vo_cr: amount,
              vo_db: 0,
              vo_cr_total: amount,
              vo_db_total: 0,
              acct_ldgr_id:
                OnlyApplicationLedger.data?.GetAcctLdgrs.edges[0].node.id,
            })),
          },
        ],
      },
      refetchQueries: [
        {
          query: GetAcctVouchers,
          variables: {
            after: null,
            direction: Direction.ASC,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            first: ROWS_PER_PAGE,
            sortBy: SortBy.V_DATE,
            token,
            name: EMPTY_STRING,
            input: {
              inst_id: InstId!,
              voucher_query_type: VoucherQueryTypes.STUDENT_ALL_RECEIPTS,
            },
          },
        },
        {
          query: GetStudentEnquiresByEnquiryId,
          variables: {
            token,
            id: state.studentEnquiryId,
          },
          fetchPolicy: "network-only",
        },
      ],
    }).then(({ data }) => {
      if (data) {
        const { v_no, v_date } = data.AddAcctVoucherMasterAndDetails[0];
        setTableData([
          {
            particular:
              OnlyApplicationLedger.data?.GetAcctLdgrs.edges[0].node.ldgr_desc!,
            amount: amount!,
          },
        ]);
        set_v_Date(v_date);
        set_vo_no(v_no);

        SetPrintModal(true);

        setMessage({
          message: "Application Fee added",
          flag: true,
          operation: Operation.CREATE,
        });
      }
    });
  };
  const handleClear = () => {
    setTransactionLedgerId(EMPTY_RESPONSETYPE_OBJECT);
    setLedgerType(EMPTY_OPTIONSTYPE_OBJECT);
    setBankTransactionType(EMPTY_OPTIONSTYPE_OBJECT);
    setNarration("");
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  useEffect(() => {
    if (state.studentId) {
      GetTransportStudentData({
        variables: {
          token,
          student_id: state.studentId,
        },
      });
    }
  }, [GetTransportStudentData, state.studentId, token]);
  const { branchLabel, classLabel } = useInstLabels();
  return (
    <>
      {pageType === PageFor.GENERAL ? <Home DashBoardRequired={false} /> : null}
      <div className="row">
        <div className="col">
          <Title> Fee Receipt for Student Transport</Title>
        </div>
      </div>
      <div
        className={
          pageType === PageFor.GENERAL
            ? "student-fee-enquiry"
            : "student-fee-enquiry--modal"
        }
      >
        <div className="student-fee-enquiry__details">
          <div className="row g-0 student-fee-enquiry__details--select-options">
            <div className="col">
              <div className="student-fee-enquiry__details--imagefield">
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={studentDropDown!}
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (e.key === Keys.BACKSPACE) {
                      dispatch({
                        type: payloadTypes.SET_STUDENT_ID,
                        payload: {
                          studentId: 0,
                        },
                      });
                      setSearchData(EMPTY_STRING);
                    }
                  }}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      dispatch({
                        type: payloadTypes.SET_STUDENT_ID,
                        payload: {
                          studentId: (newValue as responseType)?.value,
                        },
                      });
                    } else {
                      dispatch({
                        type: payloadTypes.SET_STUDENT_ID,
                        payload: {
                          studentId: 0,
                        },
                      });
                    }
                  }}
                  autoHighlight
                  forcePopupIcon
                  popupIcon={<img src={DownArrow} alt="/" />}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onChange={(e) => setSearchData(e.target.value)}
                      label="Admission No"
                      autoFocus
                             slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                      fullWidth
                      className={labelClasses.formControlRoot}
                    />
                  )}
                />
                {pageType === PageFor.GENERAL && (
                  <img
                    className="data-fetch-icon"
                    src={Edit}
                    alt="/"
                    onClick={() => setStudentModal(!studentModal)}
                  />
                )}
              </div>

              <TextField
                label={branchLabel}
                className="student-fee-enquiry__details--textfield"
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                value={
                  data?.GetTransportStudent.student_details.branch
                    .branch_desc ?? EMPTY_STRING
                }
                disabled
              />

              <TextField
                label={classLabel}
                className="student-fee-enquiry__details--textfield"
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                value={
                  data?.GetTransportStudent.student_details.class.class_desc ??
                  EMPTY_STRING
                }
                disabled
              />
            </div>

            <div className="col">
              <TextField
                label="Student Name"
                className="student-fee-enquiry__details--textfield"
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                value={`${
                  data?.GetTransportStudent.student_details.first_name ??
                  EMPTY_STRING
                } ${
                  data?.GetTransportStudent.student_details.middle_name ??
                  EMPTY_STRING
                } ${
                  data?.GetTransportStudent.student_details.last_name ??
                  EMPTY_STRING
                }`}
                disabled
              />

              <TextField
                label="Email"
                className="student-fee-enquiry__details--textfield"
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                value={
                  data?.GetTransportStudent.student_details.std_email ??
                  EMPTY_STRING
                }
                disabled
              />
            </div>
            <div className="col">
              <TextField
                label="Father Name"
                className="student-fee-enquiry__details--textfield"
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                value={
                  data?.GetTransportStudent.student_details.std_father_name ??
                  EMPTY_STRING
                }
                disabled
              />
              <TextField
                label="Date"
                className="student-fee-enquiry__details--textfield--date"
                disabled
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                type="date"
                value={toInputStandardDate(serverDate)}
              />
            </div>
          </div>

          <div className="student-fee-enquiry__data">
            <TableContainer className="transport-route-details__table">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {Accounts_Table.Receipts.NonDemand.Table_Headers.filter(
                      (th: TableHeaderProps) => th.labelName !== "Actions"
                    ).map((th: TableHeaderProps, index: React.Key) => {
                      return <TableCell key={index}>{th.labelName}</TableCell>;
                    })}
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow className="non-demandfee-payable__table--select-row">
                    <TableCell
                      id="td-center"
                      className="transport-route-details__table--slno"
                    >
                      1
                    </TableCell>
                    <TableCell>
                      {data?.GetTransportStudent.acct_ldgr_details.ldgr_desc}
                    </TableCell>
                    <TableCell>
                      {data?.GetTransportStudent.route_fee_details.route_fee}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <form className="row g-0 student-fee-enquiry__receiptblock">
            <div className="col student-fee-enquiry__receiptblock--frame">
              <div className="label-grid">
                <Label>Receipt No.</Label>
                <Input
                  disabled
                  value={
                    voucherNumber.data?.GetVoucherNumber.vo_number ??
                    EMPTY_STRING
                  }
                />
                <Label> Mode of Transaction</Label>
                <RequiredAutoComplete
                  className={labelClasses.inputRoot}
                  options={ledgerOptions}
                  openOnFocus
                  autoHighlight
                  value={ledgerType}
                  ref={modeOfTransactionRef}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setLedgerType(newValue as optionsType);
                      if ((newValue as optionsType).value === BankOrCash.BANK) {
                        setBankAccount(true);
                        setCashAccount(false);
                      } else {
                        setCashAccount(true);
                        setBankAccount(false);
                      }
                    } else {
                      setLedgerType(EMPTY_OPTIONSTYPE_OBJECT);
                    }
                  }}
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (e.key === Keys.ENTER && ledgerType.value) {
                      handleMUISelectEvent(e);
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setLedgerType(EMPTY_OPTIONSTYPE_OBJECT);
                    }
                  }}
                  popupIcon={<img src={DownArrow} alt="/" />}
                  forcePopupIcon
                  renderInput={(params) => (
                    <TextField
                      autoFocus
                      required
                      {...params}
                      fullWidth
                      className={labelClasses.formControlRoot}
                    />
                  )}
                />
              </div>
            </div>
            {cashAccount ? (
              <div className="col student-fee-enquiry__receiptblock--frame label-grid">
                <Label> Cash Ledgers</Label>
                <RequiredAutoComplete
                  className={formClasses.inputRoot}
                  options={CashLedgers.responseType!}
                  openOnFocus
                  autoHighlight
                  value={transactionBookLedgerId}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setTransactionLedgerId(newValue as responseType);
                    } else {
                      setTransactionLedgerId(EMPTY_RESPONSETYPE_OBJECT);
                    }
                  }}
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (transactionBookLedgerId.value && e.key === Keys.ENTER) {
                      handleMUISelectEvent(e);
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setTransactionLedgerId(EMPTY_RESPONSETYPE_OBJECT);
                    }
                  }}
                  popupIcon={<img src={DownArrow} alt="/" />}
                  forcePopupIcon
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      required
                      onChange={(e) => setSearchData(e.target.value)}
                      className={labelClasses.formControlRoot}
                    />
                  )}
                />
              </div>
            ) : null}
            {bankAccount ? (
              <div className="col student-fee-enquiry__receiptblock--frame">
                <div className="student-fee-enquiry__receiptblock--frame--bankdetails">
                  <Label>Bank Transaction Type</Label>
                  <RequiredAutoComplete
                    className={labelClasses.inputRoot}
                    options={bankTransactionTypes!}
                    openOnFocus
                    autoHighlight
                    value={bankTransactionType}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setBankTransactionType(newValue as optionsType);
                      } else {
                        setBankTransactionType(EMPTY_OPTIONSTYPE_OBJECT);
                      }
                    }}
                    onKeyDown={(e: React.KeyboardEvent) => {
                      if (ledgerType && e.key === Keys.ENTER) {
                        handleMUISelectEvent(e);
                      }
                      if (e.key === Keys.BACKSPACE) {
                        setBankTransactionType(EMPTY_OPTIONSTYPE_OBJECT);
                      }
                    }}
                    popupIcon={<img src={DownArrow} alt="/" />}
                    forcePopupIcon
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        className={labelClasses.formControlRoot}
                      />
                    )}
                  />
                </div>
                <div className="student-fee-enquiry__receiptblock--frame--bankdetails">
                  <Label> Bank Ledgers</Label>
                  <RequiredAutoComplete
                    className={labelClasses.inputRoot}
                    options={BankLedgers.responseType!}
                    openOnFocus
                    autoHighlight
                    value={transactionBookLedgerId}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setTransactionLedgerId(newValue as responseType);
                      } else {
                        setTransactionLedgerId(EMPTY_RESPONSETYPE_OBJECT);
                      }
                    }}
                    onKeyDown={(e: React.KeyboardEvent) => {
                      if (
                        transactionBookLedgerId.value &&
                        e.key === Keys.ENTER
                      ) {
                        handleMUISelectEvent(e);
                      }
                      if (e.key === Keys.BACKSPACE) {
                        setTransactionLedgerId(EMPTY_RESPONSETYPE_OBJECT);
                      }
                    }}
                    popupIcon={<img src={DownArrow} alt="/" />}
                    forcePopupIcon
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        onChange={(e) => setSearchData(e.target.value)}
                        className={formClasses.formControlRoot}
                      />
                    )}
                  />
                </div>
                <div className="student-fee-enquiry__receiptblock--frame--bankdetails">
                  <Label>Transaction Reference Number</Label>
                  <Input
                    required
                    onKeyDown={(e: React.KeyboardEvent) => {
                      if (e.key === Keys.ENTER) {
                        handleMUISelectEvent(e);
                      }
                    }}
                    value={referenceNumber}
                    type="text"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setReferenceNumber(e.target.value)
                    }
                  />
                </div>
              </div>
            ) : null}
            <div className="col student-fee-enquiry__receiptblock--frame label-grid">
              <Label>Remarks :</Label>
              <TextArea
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === Keys.ENTER) {
                    saveButtonRef.current?.focus();
                  }
                }}
                onChange={(e) => setNarration(e.target.value)}
                rows={3}
              />
            </div>
          </form>
        </div>
        <Button
          mode="save"
          disabled={!transactionBookLedgerId.value}
          buttonref={saveButtonRef}
          onClick={handleStudentReceipts}
        />

        <Button mode="cancel" onClick={() => setFeeReceiptModal(false)} />
      </div>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={studentModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <TransportStdList
              pageType={PageFor.MODAL}
              setStudentModal={setStudentModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStudentModal(!studentModal)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={printModal}
        style={PrintModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Print
              tableData={tableData}
              v_date={v_date}
              v_no={v_no}
              transaction_no={referenceNumber}
            />
            <Button
              mode="cancel"
              onClick={() => {
                SetPrintModal(!printModal);
                setFeeReceiptModal(false);
              }}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => {
                SetPrintModal(!printModal);
                setFeeReceiptModal(false);
              }}
            />
          </div>
        </div>
      </Modal>
      <LoadingModal flag={GenerateStudentReceiptsLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default TransportStudentReceipt;
