import React, { useEffect } from "react";

import useToken from "../../../../customhooks/useToken";
import { useLazyQuery } from "@apollo/client";
import {
  StudentAcademicData,
  StudentAcademicVars
} from "../../../Academics/hooks/useAcdStudentDetails";
import { GetAcdStudentDetails } from "../../../Academics/queries/student";
import { SelectedStudentsProps } from "../hooks/useStudentMarksStatistics";
import useReportCardConfig from "../../../Academics/Reports/CompleteReports/ReportCards/hooks/useReportCardConfig";
import useInstLabels from "../../../../customhooks/general/useInstLabels";

import InstInformation from "../common/InstDetails";
import MarksDetails, { MarksDetailsTableFor } from "../common/MarksDetails";
import StudentDetails from "../common/StudentDetails";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import useInstLogoDetails from "../../../../customhooks/useInstLogoDetails";
import { FileUploadParams } from "../../../../utils/Enum.types";
import GradingScale from "../common/GradingScale";
const RPTemplate2 = ({
  selectedStudents,
  setStudentIdSet
}: SelectedStudentsProps) => {
  const { token } = useToken();
  const [GetStudentDetailsByIds, { data: studentData }] = useLazyQuery<
    StudentAcademicData,
    StudentAcademicVars
  >(GetAcdStudentDetails, {
    variables: { token, ids: selectedStudents },
    fetchPolicy: "network-only"
  });
  useEffect(() => {
    if (token && selectedStudents) {
      GetStudentDetailsByIds();
    }
  }, [token, GetStudentDetailsByIds, selectedStudents]);
  const {
    annualResults,
    otherDetails,
    attendanceDetails,
    parentSign,
    signatureFromInst,
    classTeacherSign,
    title,
    signatureType,
    gradeScale
  } = useReportCardConfig();
  const { LogoOrSign: PrincipalSign } = useInstLogoDetails({
    filetype: FileUploadParams.PRINCIPAL_SIGNATURE
  });
  const { classLabel } = useInstLabels();

  return (
    <>
      {studentData
        ? studentData.nodes.map((std, index) => {
            return (
              <>
                <div className="bgs-pn-pr__block--data" key={index}>
                  <InstInformation />
                  <StudentDetails
                    setStudentIdSet={setStudentIdSet}
                    selectedStudents={[std.id]}
                  />

                  {title?.config_boolean_value && (
                    <b className="progress-report__title">
                      {title.config_string_value}
                    </b>
                  )}

                  <div className="bgs-pn-pr__acd-details">
                    <MarksDetails
                      studentData={std}
                      selectedStudents={[std.id]}
                      setStudentIdSet={setStudentIdSet}
                      type={MarksDetailsTableFor.ACADEMIC}
                    />
                  </div>
                  <br />
                  <div className="bgs-pn-pr__non-acd">
                    <MarksDetails
                      studentData={std}
                      selectedStudents={[std.id]}
                      setStudentIdSet={setStudentIdSet}
                      type={MarksDetailsTableFor.NON_ACADEMIC}
                    />
                  </div>
                  <div className="row g-0 gap-4">
                    <div className="col">
                      {" "}
                      {otherDetails?.config_boolean_value ? (
                        <>
                          <div className="bgs-pn-pr__non-acd">
                            <MarksDetails
                              studentData={std}
                              selectedStudents={[std.id]}
                              setStudentIdSet={setStudentIdSet}
                              type={MarksDetailsTableFor.OTHER_DETAILS}
                            />
                          </div>
                        </>
                      ) : null}
                      {attendanceDetails?.config_boolean_value ? (
                        <>
                          <br />
                          <div className="bgs-pn-pr__non-acd">
                            <MarksDetails
                              studentData={std}
                              selectedStudents={[std.id]}
                              setStudentIdSet={setStudentIdSet}
                              type={MarksDetailsTableFor.ATTENDANCE}
                            />
                          </div>
                        </>
                      ) : null}
                    </div>

                    {gradeScale?.config_boolean_value && (
                      <div className="rp-template-3__page2--grade col">
                        <br />
                        <GradingScale />
                      </div>
                    )}
                  </div>
                    <>
                      
                      <br />
                      <div className="bgs-pn-pr__non-acd">
                        <MarksDetails
                          studentData={std}
                          selectedStudents={[std.id]}
                          setStudentIdSet={setStudentIdSet}
                          type={MarksDetailsTableFor.SIGNATURES}
                        />
                      </div>
                    </>
                  

                  {annualResults?.config_boolean_value && (
                    <>
                      <div className="bgs-pn-pr__annual-results">
                        <b>Annual Result's</b>
                        <div className="bgs-pn-pr__annual-results--status">
                          Passed and Promoted
                        </div>
                        <div className="bgs-pn-pr__annual-results--to">
                          <label>To {classLabel}</label>
                          <input type="text" value="" />
                        </div>
                      </div>
                    </>
                  )}
                  <br />
                  <div className="page-break"></div>
                </div>
              </>
            );
          })
        : null}
    </>
  );
};

export default RPTemplate2;
