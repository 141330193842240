import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { GetAcctStdDemandSummation } from "../queries/demands/query";

import { StudentAcctReportType } from "../../../utils/Enum.types";
import useToken from "../../../customhooks/useToken";
import { AcctStudentQueryType } from "../common/QueryTypes";
import { AppContext } from "../../../context/context";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import { EMPTY_STRING } from "../../../utils/constants";

interface input {
  std_demand_query_type: string;
  ids: number[];
  std_status: string[];
  str_data?: string[];
  int_data?: number[];
  float_data?: number[];
}
interface vars {
  token: string;
  fin_yr_id: number;
  input: input;
  inst_id: string | number;
}

export interface GetAcctStdDemandSummationDetails {
  std_demand_ob: number;
  std_demand_amt: number;
  std_demand_concession: number;
  std_demand_receivable: number;
  std_demand_received: number;
  std_demand_refunds: number;
  std_demand_bal: number;
}
export interface GetSummationData {
  GetAcctStdDemandSummation: GetAcctStdDemandSummationDetails[];
}

const useSummationDatabyNewApi = (
  departmentId: number,
  branchId: number,
  classId: number,
  semesterId: number,
  sectionId: number,
  categoryId: number,
  queryType: StudentAcctReportType,
  student_status: string
) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const [std_demand_query_type, setstd_query_type] = useState("");
  const [entryId, setEntryId] = useState(0);

  const { state } = useContext(AppContext);

  const [ids, setId] = useState<number[]>([]);

  const {
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_DEPARTMENT_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();
  useEffect(() => {
    if (sectionId && USE_SECTION_KEY) {
      setEntryId(sectionId);
    } else if (semesterId && USE_SEMESTER_KEY) {
      setEntryId(semesterId);
    } else if (classId && USE_CLASS_KEY) {
      setEntryId(classId);
    } else if (branchId && USE_BRANCH_KEY) {
      setEntryId(branchId);
    } else if (departmentId && USE_DEPARTMENT_KEY) {
      setEntryId(departmentId);
    } else {
      setEntryId(Number(InstId));
    }
  }, [
    sectionId,
    USE_SECTION_KEY,
    semesterId,
    USE_SEMESTER_KEY,
    classId,
    USE_CLASS_KEY,
    branchId,
    USE_BRANCH_KEY,
    departmentId,
    USE_DEPARTMENT_KEY,
    InstId,
  ]);

  useEffect(() => {
    switch (queryType) {
      case StudentAcctReportType.GENERAL:
        setId([entryId, categoryId]);
        setstd_query_type(AcctStudentQueryType.ACCT_STD_DEMAND);
        break;
      case StudentAcctReportType.COMPLETELY_PAID:
        setId([entryId, categoryId]);
        setstd_query_type(AcctStudentQueryType.COMPLETELY_PAID);

        break;
      case StudentAcctReportType.PARTIALLY_PAID:
        setId([entryId, categoryId]);
        setstd_query_type(AcctStudentQueryType.PARTIALLY_PAID);

        break;

      case StudentAcctReportType.DEMAND_RAISED_NOT_PAID:
        setId([entryId, categoryId]);
        setstd_query_type(AcctStudentQueryType.DEMAND_RAISED_NOT_PAID);

        break;
      case StudentAcctReportType.REFUNDS:
        setId([entryId, categoryId]);
        setstd_query_type(AcctStudentQueryType.REFUND);

        break;
      case StudentAcctReportType.CONCESSION:
        setId([entryId, categoryId]);
        setstd_query_type(AcctStudentQueryType.CONCESSION);

        break;

      case StudentAcctReportType.DEMAND_RAISED:
        setId([entryId, categoryId]);
        setstd_query_type(AcctStudentQueryType.DEMAND_RAISED);

        break;
      case StudentAcctReportType.BY_AGENT:
        setId([entryId, categoryId, state.agentId]);
        setstd_query_type(AcctStudentQueryType.BY_AGENT_ID);
        break;
      case StudentAcctReportType.DEMAND_RAISED_ONLY_BAL:
        setId([entryId, categoryId]);
        setstd_query_type(AcctStudentQueryType.DEMAND_RAISED_ONLY_BAL);
        break;
      default:
        break;
    }
  }, [
    departmentId,
    branchId,
    classId,
    semesterId,
    sectionId,
    categoryId,
    queryType,
    state.agentId,
    entryId,
    InstId,
  ]);

  const [GetStudentsData, { data, loading, error, fetchMore }] = useLazyQuery<
    GetSummationData,
    vars
  >(GetAcctStdDemandSummation, {
    variables: {
      fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
      token,
      inst_id: InstId!,
      input: {
        ids,
        std_demand_query_type,
        std_status: student_status !== EMPTY_STRING ? [student_status] : [],
      },
    },
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (state.ActiveFinYr && token && std_demand_query_type !== "") {
      GetStudentsData();
    }
  }, [GetStudentsData, state.ActiveFinYr, token, std_demand_query_type]);
  return { StudentsSummationData: { data, loading, error, fetchMore } };
};

export default useSummationDatabyNewApi;
