import React, { useContext, useEffect, useState } from "react";
import { toStandardDate } from "../../../../../../utils/UtilFunctions";
import usePerTestReportConfig from "../../../hooks/usePerTestReportConfig";
import {
  StudentAcademicData,
  StudentAcademicVars,
} from "../../../../../Academics/hooks/useAcdStudentDetails";
import { useLazyQuery } from "@apollo/client";
import { GetAcdStudentDetails } from "../../../../../Academics/queries/student";
import useToken from "../../../../../../customhooks/useToken";
import { EMPTY_STRING } from "../../../../../../utils/constants";
import useInstDetails from "../../../../../../customhooks/general/useInstDetails";
import { getDownloadUrl } from "../../../../../../utils/DownloadFile";
import { AppContext } from "../../../../../../context/context";
import Avatar from "../../../../../../images/Avatar.svg";
import useActiveAcademicYear from "../../../../../Academics/hooks/useActiveAcademicYear";

interface Props {
  selectedStudents: number[];
  setStudentIdSet: React.Dispatch<React.SetStateAction<Set<number>>>;
}
const StudentDetails = ({ setStudentIdSet, selectedStudents }: Props) => {
  const {
    stdImagePerTest,
    stdNamePerTest,
    rollNoPerTest,
    fatherNamePerTest,

    motherNamePerTest,
    dobPerTest,
    sectionPerTest,
  } = usePerTestReportConfig();
  const { activeAcademicYearData } = useActiveAcademicYear();

  const [imageString, setImageString] = useState("");
  const { InstDetails } = useInstDetails(1);
  const { state } = useContext(AppContext);

  const { token } = useToken();
  const [GetStudentDetailsByIds, { data: studentData }] = useLazyQuery<
    StudentAcademicData,
    StudentAcademicVars
  >(GetAcdStudentDetails, {
    variables: { token, ids: selectedStudents },
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (token && selectedStudents) {
      GetStudentDetailsByIds();
    }
  }, [token, GetStudentDetailsByIds, selectedStudents]);
  useEffect(() => {
    if (studentData) {
      if (
        studentData?.nodes[0].std_profile_filename !== EMPTY_STRING &&
        InstDetails.data
      ) {
        const studentProfiePicUrl = `${InstDetails.data?.nodes[0]?.inst_name}/students/${selectedStudents[0]}/std_profile_pic/std_profile_pic`;

        getDownloadUrl(studentProfiePicUrl, false, setImageString);
      }
    }
  }, [studentData, InstDetails.data, selectedStudents[0]]);
  return (
    <>
      <div className="bgs-pn-pr__std-details">
        <div style={{ display: "flex", columnGap: 10 }}>
          {stdImagePerTest && stdImagePerTest.config_boolean_value ? (
            <div className="bgs-pn-pr__std-details--image">
              {imageString ? (
                <img
                  src={imageString}
                  alt=""
                  className="bgs-pn-pr__inst-details--logo"
                />
              ) : (
                <img
                  src={Avatar}
                  alt=""
                  className="bgs-pn-pr__inst-details--logo"
                />
              )}
            </div>
          ) : null}

          <div>
            {stdNamePerTest?.config_boolean_value && (
              <>
                <div className="bgs-pn-pr__std-details--grid">
                  <label>{stdNamePerTest.config_string_value}</label>
                  <span>:</span>
                  <input
                    type="text"
                    value={
                      studentData?.nodes[0].first_name +
                      " " +
                      studentData?.nodes[0].middle_name +
                      " " +
                      studentData?.nodes[0].last_name
                    }
                  />
                </div>
              </>
            )}

            {rollNoPerTest?.config_boolean_value && (
              <div className="bgs-pn-pr__std-details--grid">
                <label>{rollNoPerTest.config_string_value}</label>
                <span>:</span>
                <input type="text" value={studentData?.nodes[0].std_adm_no} />
              </div>
            )}
            {fatherNamePerTest?.config_boolean_value && (
              <div className="bgs-pn-pr__std-details--grid">
                <label>{fatherNamePerTest.config_string_value}</label>
                <span>:</span>
                <input
                  type="text"
                  value={studentData?.nodes[0].std_father_name}
                />
              </div>
            )}
            {motherNamePerTest?.config_boolean_value && (
              <div className="bgs-pn-pr__std-details--grid">
                <label>{motherNamePerTest.config_string_value}</label>
                <span>:</span>
                <input
                  type="text"
                  value={studentData?.nodes[0].std_mother_name}
                />
              </div>
            )}
          </div>
        </div>
        <div>
          {sectionPerTest?.config_boolean_value && (
            <>
              <div className="bgs-pn-pr__std-details--grid">
                <label>{sectionPerTest.config_string_value}</label>
                <span>:</span>
                <input
                  type="text"
                  value={
                    studentData?.nodes[0].acd_class.class_desc +
                    "/" +
                    studentData?.nodes[0].acd_section.section_desc
                  }
                />
              </div>
            </>
          )}
          <div className="bgs-pn-pr__std-details--grid">
            <label>Year</label>
            <span>:</span>
            <input
              type="text"
              value={activeAcademicYearData.data?.GetAcdYrActiveByInstId.acd_yr}
            />
          </div>
          {dobPerTest?.config_boolean_value && (
            <div className="bgs-pn-pr__std-details--grid">
              <label>{dobPerTest.config_string_value}</label>
              <span>:</span>
              <input
                type="text"
                value={toStandardDate(studentData?.nodes[0].std_dob!)}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default StudentDetails;
