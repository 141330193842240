import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  PayRollConfigKeys,
  SwConfigQueryType
} from "../Modules/HR/enums/Enum.types";

import { GetSwConfigVariables } from "../queries/institution/configuration/query/SoftwreConfig";
import {
  ChatConfigKeys,
  DailyDiaryQuestions,
  InstDetails,
  InstitutionConfigurationTypes,
  LibraryConfigKey
} from "../utils/Enum.types";
import useToken from "./useToken";
import {
  GetSwConfigVariablesData,
  GetSwConfigVariablesVars
} from "../Types/configtypes";
import {
  ChallanPrintTypes,
  IdCardPrintTypes,
  InstitutionPrintConfig,
  InstitutionVoucherPrintConfig,
  NonDemandPrintReceipts,
  StudentEnquiryFormConfig
} from "../Modules/Print/Enum.types";
import { InstitutionAcctConfigurationTypes } from "../Modules/Accounts/common/Enum.types";
import { AppContext } from "../context/context";

export type ConfigKey =
  | InstitutionConfigurationTypes
  | LibraryConfigKey
  | PayRollConfigKeys
  | InstitutionPrintConfig
  | InstitutionAcctConfigurationTypes
  | InstitutionVoucherPrintConfig
  | StudentEnquiryFormConfig
  | InstDetails
  | ChallanPrintTypes
  | IdCardPrintTypes
  | DailyDiaryQuestions
  | NonDemandPrintReceipts
  | ChatConfigKeys;

const useSwConfigData = (configKey: ConfigKey | ConfigKey[]) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { state } = useContext(AppContext);
  const [GetConfigDetails, { data, loading, error }] = useLazyQuery<
    GetSwConfigVariablesData,
    GetSwConfigVariablesVars
  >(GetSwConfigVariables, {
    variables: {
      token,
      inst_id: InstId! || state.InstId,
      input: {
        config_query_type: SwConfigQueryType.BY_CONFIG_KEY,
        str_value: configKey,
        int_value: 0
      }
    }
  });

  useEffect(() => {
    if (configKey && token && (Number(InstId) || state.InstId)) {
      GetConfigDetails();
    } // eslint-disable-next-line
  }, [GetConfigDetails, token, InstId, state.InstId]);

  return { configData: { data, loading, error } };
};

export default useSwConfigData;
