import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useMutation } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import {
  getModifiedScrollHeight,
  handleFormEvent,
  handleMUISelectEvent,
  isOptionEqualToValue,
} from "../../../../utils/UtilFunctions";
import { Title } from "../../../../stories/Title/Title";
import { Button } from "../../../../stories/Button/Button";
import { Checkbox, TextField } from "@mui/material";
import Input from "../../../../stories/Input/Input";
import {
  Direction,
  InstitutionDetails,
  Operation,
  SortBy,
  StudentAcctReportType,
  TableHeaders,
} from "../../../../utils/Enum.types";

import {
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";
import { UpdateStudentsCategory } from "../../../../queries/students/mutations/update";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";

import {
  GetAcctStdDemand,
  GetStudents,
} from "../../../../queries/students/list/newApi";
import { studentQueryTypes } from "../../../../utils/studentqueryEnum.types";
import useToken from "../../../../customhooks/useToken";
import useDropdownData from "../../../../customhooks/useDropdownData";
import { Keys } from "../../../../utils/Enum.keys";
import LoadingModal from "../../../../pages/LoadingModal";
import { msgType, responseType } from "../../../../utils/Form.types";
import { useNavigate, useParams } from "react-router-dom";

import Home from "../../Home/Index";

import Close from "../../../../images/Close.svg";
import useActiveFinancialYear from "../../../Accounts/hooks/useActiveFinancialYear";
import StudentTotalCount from "../Components/StudentTotalCount";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import UseStudentsbyDemandAmount, {
  StudentDemandEdges,
} from "../../../Accounts/hooks/useStudentsbyDemandAmount";
import useInstMasterDataByInstId from "../../../../customhooks/useInstMasterDataByInstId";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";
import { SectionAllocationDrawer } from "../../../../styles/DrawerStyles";
import { AppContext } from "../../../../context/context";
import TempMessageModal from "../../../../pages/TempMessageModal";
interface EnhancedTableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
}

const InterChangeCategory = () => {
  const navigate = useNavigate();
  //Styles
  //usestates
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const { state } = useContext(AppContext);
  const [students, setStudents] = useState<StudentDemandEdges[]>([]);
  const [categoryStudents, setCategoryStudents] = useState<
    StudentDemandEdges[]
  >([]);
  const EnhancedTableHead = (props: EnhancedTableProps) => {
    const { onSelectAllClick, numSelected, rowCount } = props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          <TableCell>{TableHeaders.SLNO}</TableCell>
          <TableCell>{TableHeaders.ADMISSION_NUMBER}</TableCell>
          <TableCell>{TableHeaders.REGISTER_NUMBER}</TableCell>
          <TableCell>{TableHeaders.STUDENT_NAME}</TableCell>

          <TableCell className="studentlist__table--status">
            {categoryLabel}
          </TableCell>
        </TableRow>
      </TableHead>
    );
  };

  //useStates
  const { token } = useToken();
  const { InstId } = useParams();
  const [interchangeSection, setInterchangeSection] = useState(false);

  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [sectionEndCursor, setSectionEndCursor] = useState<string | null>(null);
  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [classSelected, setClassSelected] = useState<responseType | null>(null);
  const [semesterSelected, setSemesterSelected] = useState<responseType | null>(
    null
  );
  const [leftCategorySelected, setLeftCategorySelected] =
    useState<responseType | null>(null);
  const [rightCategorySelected, setRightCategorySelected] =
    useState<responseType | null>(null);
  const [sectionSelected, setSectionSelected] = useState<responseType | null>(
    null
  );

  const [searchData, setSearchData] = useState("");
  //useRefs
  const { ActiveFinancicalYear } = useActiveFinancialYear();

  const { user_details } = useLoggedInUserDetails();
  //useMutations
  const [UpdateStdCategory, { loading: updationLoading }] = useMutation(
    UpdateStudentsCategory,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
    USE_CATEGORY_KEY,
  } = useInstitutionConfiguration();

  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useDropdownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0
  );

  const { categoryDropDown } = useInstMasterDataByInstId();

  const { students: RightStudentsData } = UseStudentsbyDemandAmount(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0,
    sectionSelected ? sectionSelected.value : 0,
    rightCategorySelected ? rightCategorySelected.value : 0,
    searchData,
    ROWS_PER_PAGE,
    StudentAcctReportType.DEMAND_NOT_RAISED
  );
  // Right table
  const { students: LeftStudentsData } = UseStudentsbyDemandAmount(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0,
    sectionSelected ? sectionSelected.value : 0,
    leftCategorySelected ? leftCategorySelected.value : 0,
    searchData,
    ROWS_PER_PAGE,
    StudentAcctReportType.DEMAND_NOT_RAISED
  );

  useEffect(() => {
    if (LeftStudentsData.data && !LeftStudentsData.loading && token) {
      const newData = LeftStudentsData.data.GetAcctStdDemand.edges.map(
        (edge) => ({
          ...edge,
          node: {
            ...edge.node,
            isChecked: false,
          },
        })
      );

      if (endCursor) {
        const filteredStudents = students.filter(
          (student) => !student.node.isChecked
        );

        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = filteredStudents.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
                isChecked: false,
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData);
      } else {
        setStudents(newData);
      }
      setEndCursor(LeftStudentsData.data.GetAcctStdDemand.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [LeftStudentsData.data, LeftStudentsData.loading, token]);
  useEffect(() => {
    if (RightStudentsData.data && !RightStudentsData.loading && token) {
      const newData = RightStudentsData.data.GetAcctStdDemand.edges.map(
        (edge) => ({
          ...edge,
          node: {
            ...edge.node,
            isChecked: false, // set default value of isChecked to true
          },
        })
      );

      if (sectionEndCursor) {
        // If we have already fetched some data, we need to check if there
        // are any duplicates in the new data, and update their isChecked
        // property based on the existing data.
        const filteredStudents = categoryStudents.filter(
          (student) => !student.node.isChecked
        );

        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = filteredStudents.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
                isChecked: false,
              },
            };
          }
          return newStudent;
        });
        setCategoryStudents(updatedNewData);
      } else {
        setCategoryStudents(newData);
      }
      setSectionEndCursor(
        RightStudentsData.data.GetAcctStdDemand.pageInfo.endCursor
      );
    } // eslint-disable-next-line
  }, [RightStudentsData.data, RightStudentsData.loading, token]);

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setStudents([]);
      setInterchangeSection(false);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !LeftStudentsData.loading) {
        LeftStudentsData.fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetAcctStdDemand.edges;
            const pageInfo = fetchMoreResult.GetAcctStdDemand.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetAcctStdDemand.edges.filter(
              ({ node: { id } }) =>
                newEdges.findIndex(
                  ({ node: { id: newId } }) => newId === id
                ) !== -1
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetAcctStdDemand: {
                edges: [...students!, ...newEdges],
                pageInfo,
                totalCount: LeftStudentsData.data?.GetAcctStdDemand.totalCount!,
              },
            };
          },
        });
      }
    }
  };

  const handleClick = (
    data: StudentDemandEdges,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const mappedDataLeft = students?.map((row) => {
      if (row.node.id === data.node.id) {
        return {
          ...row,
          node: {
            ...row.node,
            isChecked: (e.target as HTMLInputElement).checked ? true : false,
          },
        };
      } else {
        return row;
      }
    });

    setStudents(mappedDataLeft);
  };
  const clear = () => {
    setDepartmentSelected(null);
    setBranchSelected(null);
    setClassSelected(null);
    setSemesterSelected(null);
    setLeftCategorySelected(null);
    setRightCategorySelected(null);
    setSectionSelected(null);
    setSearchData("");
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelecteds = students?.map((edge) => ({
      ...edge,
      node: {
        ...edge.node,
        isChecked: event.target.checked,
      },
    }));
    setStudents(newSelecteds);
  };

  const handleAllocationOfStudentsToSection = () => {
    setInterchangeSection(false);
    UpdateStdCategory({
      variables: {
        token,
        inst_id: InstId!,
        user_details,
        student_ids: students
          .filter((student) => student.node.isChecked)
          ?.map(({ node }) => node.mst_student.id),
        category_id: rightCategorySelected?.value,
        fin_yr_id: ActiveFinancicalYear.data?.GetFinYrActiveByInstId.id!,
        acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      },
      refetchQueries: [
        {
          query: GetAcctStdDemand,
          variables: {
            EMPTY_STRING,
            after: null,
            direction: Direction.ASC,
            fin_yr_id: ActiveFinancicalYear.data?.GetFinYrActiveByInstId.id!,
            first: ROWS_PER_PAGE,
            input: {
              inst_id: Number(InstId)!,
              ids: InstId,
              std_demand_query_type: StudentAcctReportType.DEMAND_NOT_RAISED,
            },
            name: EMPTY_STRING,
            token,
          },
        },
        // {
        //   query: GetStudents,
        //   variables: {
        //     admNo: EMPTY_STRING,
        //     after: null,
        //     fin_yr_id: ActiveFinancicalYear.data?.GetFinYrActiveByInstId.id!,
        //     first: ROWS_PER_PAGE,
        //     name: EMPTY_STRING,
        //     token,
        //     direction: Direction.ASC,
        //     sortBy: SortBy.FIRST_NAME,
        //     input: {
        //       ids: [leftSectionSelected?.value],
        //       std_query_type: studentQueryTypes.STATUS_EQ_CUR_BY_SECTION_ID,
        //     },
        //   },
        // },
        {
          query: GetStudents,
          variables: {
            admNo: EMPTY_STRING,
            after: null,
            fin_yr_id: ActiveFinancicalYear.data?.GetFinYrActiveByInstId.id!,
            first: ROWS_PER_PAGE,
            name: EMPTY_STRING,
            token,
            direction: Direction.ASC,
            sortBy: SortBy.FIRST_NAME,
            input: {
              ids: [InstId],
              std_query_type: studentQueryTypes.BY_SECTION_ID,
            },
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: `Students ${categoryLabel} Interchanged Successfully`,
          flag: true,
          operation: Operation.CREATE,
        });
        clear();
      }
    });
  };
  const clearRestInstDetails = (type: InstitutionDetails) => {
    switch (type) {
      case InstitutionDetails.DEPARTMENT:
        setBranchSelected(null);
        setClassSelected(null);
        setSemesterSelected(null);
        setSectionSelected(null);
        break;
      case InstitutionDetails.BRANCH:
        setClassSelected(null);
        setSemesterSelected(null);
        setSectionSelected(null);
        break;
      case InstitutionDetails.CLASS:
        setSemesterSelected(null);
        setSectionSelected(null);
        break;
      case InstitutionDetails.SEMESTER:
        setSectionSelected(null);
        break;
      default:
        break;
    }
  };
  const {
    departmentLabel,
    branchLabel,
    classLabel,
    semesterLabel,
    sectionLabel,
    categoryLabel,
  } = useInstLabels();
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title> {`Interchange ${categoryLabel}`} </Title>
      <div className="interchange-category">
        <div className="row g-0 interchange-category--select">
          <div className="col-1 studentlist__search">
            <Input
              id="search"
              type="text"
              placeholder="Search..."
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchData(e.target.value);
              }}
              value={searchData}
              onKeyDown={handleFormEvent}
            />
          </div>

          {USE_DEPARTMENT_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={departmentDropDown!}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(
                    option as responseType,
                    departmentSelected
                  )
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER && departmentSelected) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setDepartmentSelected(null);
                  }
                }}
                openOnFocus
                value={departmentSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setDepartmentSelected(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setDepartmentSelected(null);
                  }
                  clearRestInstDetails(InstitutionDetails.DEPARTMENT);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    label={departmentLabel}
                    id="outlined Departments"
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}

          {USE_BRANCH_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, branchSelected)
                }
                options={branchDropDown}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER && branchSelected) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setBranchSelected(null);
                  }
                }}
                openOnFocus
                value={branchSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setBranchSelected(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setBranchSelected(null);
                  }
                  clearRestInstDetails(InstitutionDetails.BRANCH);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    label={branchLabel}
                    id="outlined Branches"
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_CLASS_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, classSelected)
                }
                options={classDropDown}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER && classSelected) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setClassSelected(null);
                  }
                }}
                openOnFocus
                value={classSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setClassSelected(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setClassSelected(null);
                  }
                  clearRestInstDetails(InstitutionDetails.CLASS);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    label={classLabel}
                    id="outlined Classes"
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SEMESTER_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, semesterSelected)
                }
                options={semesterDropDown}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER && semesterSelected) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSemesterSelected(null);
                  }
                }}
                openOnFocus
                value={semesterSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSemesterSelected(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setSemesterSelected(null);
                  }
                  clearRestInstDetails(InstitutionDetails.SEMESTER);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    label={semesterLabel}
                    id="outlined Semesters"
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SECTION_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, sectionSelected)
                }
                options={sectionDropDown}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER && classSelected) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSectionSelected(null);
                  }
                }}
                openOnFocus
                value={sectionSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSectionSelected(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setSectionSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    label={sectionLabel}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_CATEGORY_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(
                    option as responseType,
                    leftCategorySelected
                  )
                }
                options={categoryDropDown}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER && classSelected) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setLeftCategorySelected(null);
                  }
                }}
                openOnFocus
                value={leftCategorySelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setLeftCategorySelected(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setLeftCategorySelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    label={categoryLabel}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
        </div>
        <div className="interchange-category--tableblock">
          {!students?.length ? (
            <b className="nodata">Sorry no students found </b>
          ) : (
            <>
              <TableContainer
                className="interchange-category--table"
                onScroll={handleScroll}>
                <Table stickyHeader>
                  <EnhancedTableHead
                    numSelected={
                      students?.filter((data) => data.node.isChecked).length
                    }
                    onSelectAllClick={(event) => handleSelectAllClick(event)}
                    rowCount={students?.length}
                  />
                  <TableBody>
                    {students?.map((response, index: number) => {
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={students[index]?.node.isChecked}
                          tabIndex={-1}
                          key={response.node.id}
                          selected={students[index]?.node.isChecked}>
                          <TableCell
                            padding="checkbox"
                            id="td-center"
                            className="section-allocation__table--slno">
                            <Checkbox
                              checked={students[index]?.node.isChecked ?? false}
                              onClick={(event) => handleClick(response, event)}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell>
                          <TableCell
                            id="td-center"
                            className="section-allocation__table--slno">
                            {index + 1}
                          </TableCell>
                          <TableCell className="section-allocation__table--admno">
                            {response.node.mst_student.std_adm_no}
                          </TableCell>
                          <TableCell className="section-allocation__table--admno">
                            {response.node.mst_student.std_reg_no}
                          </TableCell>

                          <TableCell id={labelId}>
                            {response.node.mst_student.first_name +
                              " " +
                              response.node.mst_student.middle_name +
                              " " +
                              response.node.mst_student.last_name}
                          </TableCell>

                          {USE_CATEGORY_KEY ? (
                            <TableCell className="section-allocation__table--desc">
                              {response.node.mst_student.category.cat_desc}
                            </TableCell>
                          ) : null}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </div>
        <div className="row g-0">
          <div className="col">
            <Button
              onClick={() => setInterchangeSection(!interchangeSection)}
              disabled={
                !(students?.filter((data) => data.node.isChecked).length > 0)
              }
              mode="move-to"
            />

            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
          <div className="col-2 section-allocation__total">
            <StudentTotalCount
              totalCount={LeftStudentsData.data?.GetAcctStdDemand?.totalCount!}
            />
          </div>
        </div>
      </div>

      <SectionAllocationDrawer
        anchor="right"
        open={interchangeSection}
        onClose={() => setInterchangeSection(!interchangeSection)}>
        <div className="interchange-category--drawer">
          <div className="interchange-category--drawer--title">
            <Title>Selected Students</Title>
            <img
              src={Close}
              alt=""
              onClick={() => setInterchangeSection(!interchangeSection)}
            />
          </div>

          <div className="row g-0">
            <div className="col-4">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={
                  categoryDropDown?.filter(
                    (data) => data.value !== rightCategorySelected?.value
                  )!
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setRightCategorySelected(null);
                  }
                }}
                openOnFocus
                value={
                  categoryDropDown?.find(
                    ({ value }) =>
                      Number(value) === rightCategorySelected?.value
                  )! ?? null
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    setRightCategorySelected(newValue as responseType);
                  } else {
                    setRightCategorySelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    label={`Move To ${categoryLabel}`}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          </div>

          <div className="interchange-category--drawer--tableblock">
            <TableContainer
              className="section-allocation__table"
              onScroll={handleScroll}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>{TableHeaders.SLNO}</TableCell>
                    <TableCell>{TableHeaders.ADMISSION_NUMBER}</TableCell>
                    <TableCell>{TableHeaders.REGISTER_NUMBER}</TableCell>
                    <TableCell>{TableHeaders.STUDENT_NAME}</TableCell>

                    <TableCell className="studentlist__table--status">
                      {categoryLabel}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {students &&
                    students
                      .filter((data) => data.node.isChecked)
                      .map((response, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            key={response.node.id}
                            hover
                            aria-checked={response.node.isChecked}
                            tabIndex={-1}
                            selected={response.node.isChecked}>
                            <TableCell
                              id="td-center"
                              className="section-allocation__table--slno">
                              {index + 1}
                            </TableCell>
                            <TableCell className="section-allocation__table--admno">
                              {response.node.mst_student.std_adm_no}
                            </TableCell>
                            <TableCell className="section-allocation__table--admno">
                              {response.node.mst_student.std_reg_no}
                            </TableCell>

                            <TableCell id={labelId}>
                              {response.node.mst_student.first_name +
                                " " +
                                response.node.mst_student.middle_name +
                                " " +
                                response.node.mst_student.last_name}
                            </TableCell>

                            <TableCell className="section-allocation__table--desc">
                              {response.node.mst_student.category.cat_desc
                                ? response.node.mst_student.category.cat_desc
                                : EMPTY_STRING}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <Button mode="save" onClick={handleAllocationOfStudentsToSection} />
          <Button
            mode="cancel"
            onClick={() => {
              setInterchangeSection(!interchangeSection);
            }}
          />
        </div>
      </SectionAllocationDrawer>
      <LoadingModal flag={updationLoading} />
      <TempMessageModal
        modalFlag={message.flag}
        value={message.message}
        handleClose={handleClose}
        operation={message.operation}
      />
    </>
  );
};

export default InterChangeCategory;
