import React, { useEffect, useRef, useState } from "react";
import { Title } from "../../../stories/Title/Title";
import { Label } from "../../../stories/Label/Label";
import TextArea from "../../../stories/TextArea/TextArea";

import { Button } from "../../../stories/Button/Button";
import Close from "../../../images/Close.svg";
import {
  InstitutionConfigurationTypes,
  Operation,
} from "../../../utils/Enum.types";
import { useLazyQuery, useMutation } from "@apollo/client";
import FileAttach from "../../../images/BrowseFiles.svg";
import {
  AddAcdLessonPlannerDetails,
  UpdateAcdLessonPlannerDetails,
} from "../queries/lesson_planner/mutation";
import {
  AddAcdLessonPlannerDetailsData,
  AddAcdLessonPlannerDetailsVars,
  UpdateAcdLessonPlannerDetailsData,
  UpdateAcdLessonPlannerDetailsVars,
} from "../types/lessonplanner/mutations";
import useToken from "../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import useActiveAcademicYear from "../hooks/useActiveAcademicYear";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import useInstConfigByEntryId from "../hooks/useInstConfigByEntryId";
import { EMPTY_STRING, emptyMessageType } from "../../../utils/constants";
import DownArrow from "../../../images/DownArrow.svg";
import {
  handleFormEvent,
  handleMUISelectEvent,
  isOptionEqualToValue,
  removeMoreSpace,
} from "../../../utils/UtilFunctions";
import MessageModal from "../../../pages/MessageModal";
import { defaultEditSubChapter, editSubChapterType } from "./List";
import { GetAcdLessonDetailsByNodeId } from "../queries/lesson_planner/list/byNode";
import { CommonNodeVars } from "../../HR/Types/masterDataTypes";
import { GetAcdLessonPlannerMain } from "../queries/lesson_planner/list";
import { SubjectType } from "../types/subject/Subject";
import Time from "../../../components/common/Time/Index";
import { FormType, SubTopicForm, optionsType } from "../../../utils/Form.types";
import { GetSwConfigReferenceDataByKey } from "../../../queries/institution/configuration/query/SoftwreConfig";
import {
  GlobalPageConfigData,
  SoftwareConfigList,
  SoftwareVars,
} from "../../../Types/configtypes";
import { TextField } from "@mui/material";
import { Keys } from "../../../utils/Enum.keys";
import { AcdLessonPlannerDetailsNode } from "../types/lessonplanner/queries";
import {
  FormAutocomplete,
  formClasses,
} from "../../../styles/AutocompleteStyles";
import { handleUploadAndDownloadFile } from "../../../utils/Upload";
import useInstDetails from "../../../customhooks/general/useInstDetails";
import useSwConfigData from "../../../customhooks/useSwConfigData";
const { AcademicsDetails } = require("../json/formLabels.json");
interface Props {
  setDrawer: React.Dispatch<React.SetStateAction<editSubChapterType>>;
  details: editSubChapterType;
  selectedSubject: SubjectType | null;
}

const CreateSubChapter = ({
  setDrawer,
  details: { lesson_main_id, operation, sub_topic_id },
  selectedSubject,
}: Props) => {
  const { token } = useToken();
  const { InstId, entryId } = useParams();
  const { user_details } = useLoggedInUserDetails();
  const [file, setFile] = useState<File[]>([]);
  const imageRef = useRef<HTMLInputElement>(null);
  const { InstFormData } = useInstDetails(1);

  const { activeAcademicYearData } = useActiveAcademicYear();
  const {
    InstConfigDetails: { alloted },
  } = useInstConfigByEntryId(Number(entryId));

  const [formData, setFormData] = useState<SubTopicForm>({
    sub_topic_name: "",
    classroom_process: "",
    learning_objectives: "",
    resources_required: "",
    teacher_reflection: "",
    techniques: "",
    hyper_links: "",
    ref_notes: "",
  });

  const [hrs, setHrs] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [mins, setMinutes] = useState("");
  const [message, setMessage] = useState(emptyMessageType);
  const [progress, setProgress] = useState(0);
  const [fiveEOptions, set5EOptions] = useState<optionsType[]>([]);
  const [selected5E, setSelected5E] = useState<optionsType | null>(null);
  const { configData: sizeLimits } = useSwConfigData([
    InstitutionConfigurationTypes.ATTACHMENT_FILE_SIZE,
    InstitutionConfigurationTypes.ATTACHMENT_MEDIA_SIZE,
  ]);
  const [GetConfigData, { data: configData }] = useLazyQuery<
    SoftwareConfigList,
    SoftwareVars
  >(GetSwConfigReferenceDataByKey, {
    variables: {
      token,
      config_key: InstitutionConfigurationTypes.FIVE_ES,
      inst_id: InstId!,
      page_number: 0,
    },
  });

  useEffect(() => {
    if (token) {
      GetConfigData().then(({ data }) => {
        if (data && data.GetSwConfigReferenceDataByKey) {
          const { list_item_list } = data.GetSwConfigReferenceDataByKey;
          const separatedItems = list_item_list
            .split(";")
            .filter((item) => item !== "");
          set5EOptions(
            separatedItems.map((item) => {
              const separateColon = item.split(":");
              return {
                label: separateColon[0],
                value: separateColon[1],
              };
            })
          );
        }
      });
    }
  }, [GetConfigData, configData, InstId, token]);
  const [AddAcdLessonDetail] = useMutation<
    AddAcdLessonPlannerDetailsData,
    AddAcdLessonPlannerDetailsVars
  >(AddAcdLessonPlannerDetails);

  const [UpdateAcdLessonDetail] = useMutation<
    UpdateAcdLessonPlannerDetailsData,
    UpdateAcdLessonPlannerDetailsVars
  >(UpdateAcdLessonPlannerDetails);
  const [GetAcdLesson, { data: LessonData }] = useLazyQuery<
    { node: AcdLessonPlannerDetailsNode },
    CommonNodeVars
  >(GetAcdLessonDetailsByNodeId, {
    variables: {
      token,
      id: sub_topic_id,
    },
  });

  useEffect(() => {
    if (operation === Operation.UPDATE && token && sub_topic_id) {
      GetAcdLesson().then(({ data }) => {
        if (data && data.node) {
          setFormData({
            classroom_process: data.node.classroom_process,
            learning_objectives: data.node.learning_objectives,
            resources_required: data.node.resources_required,
            sub_topic_name: data.node.sub_topic_name,
            teacher_reflection: data.node.teacher_reflection,
            techniques: data.node.techniques,
          });
          setSelected5E(
            fiveEOptions.find(({ value }) => value === data.node.five_es) ??
              null
          );
          setHrs(Math.trunc(data.node.duration_in_mins / 60).toString());
          setMinutes((data.node.duration_in_mins % 60).toString());
        }
      });
    }
  }, [GetAcdLesson, operation, LessonData, token, sub_topic_id, fiveEOptions]);

  const filterDataByConfigKey = (data: GlobalPageConfigData[]) => {
    let imageSize = 0;
    let videoSize = 0;
    if (data) {
      data.forEach((item) => {
        switch (item.config_key) {
          case InstitutionConfigurationTypes.ATTACHMENT_FILE_SIZE:
            imageSize = item.config_integer_value;
            break;
          case InstitutionConfigurationTypes.ATTACHMENT_MEDIA_SIZE:
            videoSize = item.config_integer_value;
            break;

          default:
            break;
        }
      });
    }
    return {
      imageSize,
      videoSize,
    };
  };
  const { imageSize, videoSize } = filterDataByConfigKey(
    sizeLimits.data?.GetSwConfigVariables!
  );
  let filename =
    file.length > 1
      ? file
          .map(
            (f) =>
              `${InstFormData.inst_name}/main_topic/${lesson_main_id}/${f.name}`
          )
          .join(",")
      : `${InstFormData.inst_name}/main_topic/${lesson_main_id}/${
          file.length ? file[0].name : EMPTY_STRING
        }`;
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    let flag = true;

    // Handle multiple files
    for (const f of file) {
      if (f.type.includes("image/") && f.size > imageSize! * 1024 * 1024) {
        alert("Image file size exceeds the maximum limit.");
        flag = false;
        break;
      }

      if (f.type === "video/") {
        alert("Video file is not allowed");
        flag = false;
        break;
      }
    }

    if (flag && operation === Operation.CREATE) {
      const sendMsgPromise = AddAcdLessonDetail({
        variables: {
          token,
          inst_id: InstId!,
          lesson_main_id,
          user_details,
          input: {
            sub_topic_name: removeMoreSpace(formData.sub_topic_name),
            duration_in_mins: Number(hrs) ? Number(mins) + Number(hrs) * 60 : 0,
            classroom_process: removeMoreSpace(formData.classroom_process),
            five_es: selected5E ? selected5E.value : EMPTY_STRING,
            resources_required: removeMoreSpace(formData.resources_required),
            teacher_reflection: removeMoreSpace(formData.teacher_reflection),
            techniques: removeMoreSpace(formData.techniques),
            hyper_links: formData.hyper_links
              ? formData.hyper_links
                  .split(",")
                  .map((link) => link.trim())
                  .join("\n")
              : EMPTY_STRING,
            ref_notes: file ? filename : "",
          },
        },
        refetchQueries: [
          {
            query: GetAcdLessonPlannerMain,
            variables: {
              token,
              acd_yr_id: activeAcademicYearData.data
                ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
                : 0,
              allotted_id: alloted.id,
              allotted_level: alloted.level,
              inst_id: InstId!,
              subj_master_id: selectedSubject ? selectedSubject.id : 0,
            },
          },
        ],
      });
      sendMsgPromise.then(({ data }: any) => {
        if (data) {
          const uploadFilePromise = file
            ? Promise.all(
                file.map((f, index) =>
                  handleUploadAndDownloadFile(
                    f,
                    file.length > 1
                      ? `${
                          InstFormData.inst_name
                        }/main_topic/${lesson_main_id}/sub_topic/${
                          data.AddAcdLessonPlannerDetails.id
                        }_${index}.${
                          f.name.split(".")[f.name.split(".").length - 1]
                        }`
                      : `${
                          InstFormData.inst_name
                        }/main_topic/${lesson_main_id}/sub_topic/${
                          data.AddAcdLessonPlannerDetails.id
                        }.${f.name.split(".")[f.name.split(".").length - 1]}`,
                    setProgress,
                    false
                  )
                )
              )
            : false;

          Promise.all([sendMsgPromise, uploadFilePromise])
            .then(([sendMsgData]) => {
              //@ts-ignore
              if (sendMsgData) {
                // setSent?.(!sent);
                setFile([]);
                setMessage({
                  flag: true,
                  message: "Sub Topic Created Successfully",
                  operation: Operation.CREATE,
                });
                setIsDisabled(true);
              }
            })

            .catch((error) => {
              console.log("Error occurred:", error);
            });
        }
      });
    } else if (flag && operation === Operation.UPDATE) {
      const sendMsgPromise = UpdateAcdLessonDetail({
        variables: {
          token,
          inst_id: InstId!,
          user_details,
          lesson_details_id: sub_topic_id,
          input: {
            duration_in_mins:
              Number(hrs) && Number(mins) ? Number(mins) + Number(hrs) * 60 : 0,
            sub_topic_name: removeMoreSpace(formData.sub_topic_name),
            classroom_process: removeMoreSpace(formData.classroom_process),
            five_es: selected5E ? selected5E.value : EMPTY_STRING,
            resources_required: removeMoreSpace(formData.resources_required),
            teacher_reflection: removeMoreSpace(formData.teacher_reflection),
            learning_objectives: removeMoreSpace(formData.learning_objectives),
            techniques: removeMoreSpace(formData.techniques),
            hyper_links: formData.hyper_links
              ? formData.hyper_links
                  .split(",")
                  .map((link) => link.trim())
                  .join("\n")
              : EMPTY_STRING,
            ref_notes: file ? filename : "",
          },
        },
        refetchQueries: [
          {
            query: GetAcdLessonPlannerMain,
            variables: {
              token,
              acd_yr_id: activeAcademicYearData.data
                ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
                : 0,
              allotted_id: alloted.id,
              allotted_level: alloted.level,
              inst_id: InstId!,
              subj_master_id: selectedSubject
                ? selectedSubject.subj_master_id
                : 0,
            },
          },
        ],
      });
      sendMsgPromise.then(({ data }: any) => {
        if (data) {
          const uploadFilePromise = file
            ? Promise.all(
                file.map((f, index) =>
                  handleUploadAndDownloadFile(
                    f,
                    `${
                      InstFormData.inst_name
                    }/main_topic/${lesson_main_id}/sub_topic/${
                      data.UpdateAcdLessonPlannerDetails.id
                    }_${index}.${
                      f.name.split(".")[f.name.split(".").length - 1]
                    }`,
                    setProgress,
                    false
                  )
                )
              )
            : false;

          Promise.all([sendMsgPromise, uploadFilePromise])
            .then(([sendMsgData]) => {
              //@ts-ignore
              if (sendMsgData) {
                // setSent?.(!sent);
                // setChannelMessage(EMPTY_STRING);
                setFile([]);
                setMessage({
                  flag: true,
                  message: "Sub Topic Updated Successfully",
                  operation: Operation.CREATE,
                });
                setIsDisabled(true);
              }
            })

            .catch((error) => {
              console.log("Error occurred:", error);
            });
        }
      });
    }
  };
  const handleClear = () => {
    setFormData({
      classroom_process: "",
      learning_objectives: "",
      resources_required: "",
      sub_topic_name: "",
      teacher_reflection: "",
      techniques: "",
    });
    setHrs("");
    setMinutes("");
  };

  const handleClose = () => {
    if (message.flag && message.operation !== Operation.NONE) {
      setDrawer(defaultEditSubChapter);
      handleClear();
    }
    setMessage(emptyMessageType);
  };
  const handleValueChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value, name } = e.target as HTMLInputElement;

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const removeFile = (indexToRemove: number) => {
    const fileteredFiles = file.filter((_, index) => index !== indexToRemove);
    //@ts-ignore
    setFile(fileteredFiles);
  };
  const handleImagePreview = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);
      //@ts-ignore
      setFile(files);
    }
  };

  return (
    <>
      <form className="create-main-chapter">
        <div className="create-main-chapter__title">
          <Title>
            {operation === Operation.UPDATE
              ? "Update Sub Chapter"
              : "Create Sub Chapter"}
          </Title>
          <img
            src={Close}
            alt=""
            onClick={() => {
              handleClear();
              setDrawer(defaultEditSubChapter);
            }}
          />
        </div>
        
          <div className="create-main-chapter--frame">
            <div className="label-grid">
              <Label>Description</Label>
              <TextArea
                value={formData.sub_topic_name}
                name="sub_topic_name"
                rows={3}
                onChange={handleValueChange}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER && e.shiftKey === false) {
                    handleFormEvent(e);
                  }

                  if (e.shiftKey && e.key === Keys.ENTER) {
                    setFormData((prev) => ({
                      ...prev,
                      sub_topic_name: `${formData.sub_topic_name} \n`,
                    }));
                  }
                }}
                autoFocus
              />
            </div>
            <div className="label-grid">
              <Label>Set Duration</Label>
              <Time
                onChangeHrs={setHrs}
                onChangeMins={setMinutes}
                hrs={hrs}
                mins={mins}
              />
            </div>

            <div className="label-grid">
              <Label>5 E's</Label>
              <FormAutocomplete
                className={formClasses.inputRoot}
                options={fiveEOptions}
                value={selected5E}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as optionsType, selected5E)
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSelected5E(newValue as optionsType);
                  } else {
                    setSelected5E(null);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setSelected5E(null);
                  }
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    handleMUISelectEvent(e);
                  }
                }}
                popupIcon={<img src={DownArrow} alt="/" />}
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    fullWidth
                    className={formClasses.formControlRoot}
                  />
                )}
              />
            </div>
            {AcademicsDetails.SubTopicsForm.map(
              (item: FormType, index: number) => {
                return (
                  <div className="label-grid">
                    <Label>{item.LabelName}</Label>
                    <TextArea
                      value={formData[item.inputName]}
                      rows={3}
                      name={item.inputName}
                      onChange={handleValueChange}
                      onKeyDown={(e) => {
                        if (e.key === Keys.ENTER && e.shiftKey === false) {
                          handleFormEvent(e);
                        }

                        if (e.shiftKey && e.key === Keys.ENTER) {
                          setFormData((prev) => ({
                            ...prev,
                            [item.inputName]: `${formData[item.inputName]} \n`,
                          }));
                        }
                      }}
                    />
                  </div>
                );
              }
            )}
            <div className="label-grid">
              <Label>Links</Label>
              <TextArea
                value={formData.hyper_links}
                rows={3}
                name="hyper_links"
                onChange={handleValueChange}
              />
              {/* <div
                className="label-grid"
                dangerouslySetInnerHTML={{ __html: formData.hyper_links! }}
              /> */}
            </div>
            <div className="label-grid">
              <Label>Attachments</Label>
              {file && (
                <ul className="assignments__creation--attachments">
                  {file.map((f, index) => (
                    <li key={index}>
                      {f.name}
                      <img
                        src={Close}
                        alt="Close"
                        onClick={() => removeFile(index)}
                        className="close-icon"
                      />
                    </li>
                  ))}
                </ul>
              )}
              <label className="assignments__creation--browse">
                <img src={FileAttach} alt="/" className="browse-icon" />
                Browse
                <input
                  id="assignments__creation--browse--inputTag"
                  type="file"
                  className="assignments__creation--browse--input"
                  ref={imageRef!}
                  multiple
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleImagePreview(e)
                  }
                  accept="image/png, image/jpeg, application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, video/*"
                />
              </label>
            </div>
          </div>
          <Button mode="save" onClick={handleSubmit} />
          <Button
            mode="cancel"
            onClick={() => {
              handleClear();
              setDrawer(defaultEditSubChapter);
            }}
          />
      </form>

      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default CreateSubChapter;
