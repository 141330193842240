import React, { useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import { Button } from "../../../../stories/Button/Button";
import Modal from "react-modal";
import { PrintConfigModalStyles } from "../../../../styles/ModalStyles";
import Configurations from "../../../Academics/Reports/Progress/Configurations";
import Settings from "../../../../images/Settings.svg";
import { Title } from "../../../../stories/Title/Title";
import RPTemplate3 from "../templates/RPTemplate3";
import { RPTemplates } from "../../../../utils/Enum.types";
import useReportCardConfig from "../../../Academics/Reports/CompleteReports/ReportCards/hooks/useReportCardConfig";
import RPTemplate2 from "../templates/RPTemplate2";
export interface studentPRProps {
  selectedStudents: number[];
  setStudentIdSet: React.Dispatch<React.SetStateAction<Set<number>>>;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProgressReport = ({
  selectedStudents,
  setStudentIdSet,
  setModalFlag,
}: studentPRProps) => {
  const componentRef = useRef<HTMLDivElement>(null);
  const [configuration, setConfiguration] = useState(false);
  const { template, marginLeft, marginRight, marginTop, marginBottom } =
    useReportCardConfig();

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Title>Print Report Cards</Title>
        <img
          src={Settings}
          alt="/"
          onClick={() => setConfiguration(!configuration)}
        />
      </div>
      <div className="pr-temp1">
        <div className="pr-temp1--block" ref={componentRef}>
          <style type="text/css" media="print" scoped>
            {`
                
                body {
                  height: 100% !important;
                       overflow: initial !important;
                       -webkit-print-color-adjust: exact;
                       margin:0;
                      
                   }
            @page {
       
        width:100%;
        height:100%;
padding-left: ${marginLeft?.config_integer_value}px;
              padding-right: ${marginRight?.config_integer_value}px;
              padding-top: ${marginTop?.config_integer_value}px;
              padding-bottom: ${marginBottom?.config_integer_value}px;                      
       
            }
        .rp-template-3 {
         

          height:100% !important;

        }  

         .page-break {
            page-break-after: always !important;
            margin: 0px;
            padding: 0px;
        }

        .rp-template-3__page1{

        height:calc(100% - 40px);
        }
 

          `}
          </style>
          <div className="h-100">
            {template?.config_string_value === RPTemplates.TEMPLATE_2 ? (
              <RPTemplate3
                selectedStudents={selectedStudents}
                setStudentIdSet={setStudentIdSet}
              />
            ) : (
              <RPTemplate2
                selectedStudents={selectedStudents}
                setStudentIdSet={setStudentIdSet}
              />
            )}
          </div>
        </div>
      </div>

      <ReactToPrint
        trigger={() => <Button mode="print" />}
        content={() => componentRef.current}
      />
      <Button mode="cancel" onClick={() => setModalFlag(false)} />
      <Modal
        ariaHideApp={false}
        style={PrintConfigModalStyles}
        isOpen={configuration}
      >
        <Configurations setModalFlag={setConfiguration} />
      </Modal>
    </>
  );
};

export default ProgressReport;
