import React, { useContext, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import usePerTestReportConfig from "../../../hooks/usePerTestReportConfig";
import {
  GetAcdStudentsMarksStatisticsAtEntryLevelData,
  GetAcdStudentsMarksStatisticsAtEntryLevelVars,
  stdDataType,
} from "../PerTestTemplate1";
import { useLazyQuery } from "@apollo/client";
import { GetAcdStudentsMarksStatisticsAtEntryLevel } from "../../../../../Academics/queries/test/query";
import useToken from "../../../../../../customhooks/useToken";
import useInstitutionConfiguration from "../../../../../../customhooks/useInstitutionConfiguration";
import { formatter } from "../../../../../../utils/UtilFunctions";
import { useParams } from "react-router-dom";
import { responseType } from "../../../../../../utils/Form.types";
import useActiveAcademicYear from "../../../../../Academics/hooks/useActiveAcademicYear";
import { AppContext } from "../../../../../../context/context";
import useInstDetails from "../../../../../../customhooks/general/useInstDetails";
import { getDownloadUrl } from "../../../../../../utils/DownloadFile";
import SecuredPercentage from "../../../../../../images/Conversion.svg";
import useInstLogoDetails from "../../../../../../customhooks/useInstLogoDetails";
import { FileUploadParams } from "../../../../../../utils/Enum.types";

export const enum PerTestTableFor {
  SIGNATURE = "SIGNATURE",
  MARKS_DETAILS = "MARKS_DETAILS",
  ATTENDANCE_STATS = "ATTENDANCE_STATS",
  OTHER_DETAILS = "OTHER_DETAILS",
  OVERALL_PERFORMANCE = "OVERALL_PERFORMANCE",
}
interface Props {
  selectedStudents: number[];
  testConductId: responseType | null;
  forCombination?: boolean;
  pageType: PerTestTableFor;
}

const TableDetails = ({
  selectedStudents,
  testConductId,
  forCombination,
  pageType,
}: Props) => {
  const { InstId, entryId } = useParams();
  const { LogoOrSign: PrincipalSign } = useInstLogoDetails({
    filetype: FileUploadParams.PRINCIPAL_SIGNATURE,
  });

  const {
    gradeForEachSubPerTest,
    overAllPercentagePerTest,
    parentSignPerTest,
    signatureFromInstPerTest,
    classTeacherSignPerTest,
    otherDetailsPerTest,
    attendanceDetailsPerTest,
    heightWeightPerTest,
    attendanceStatsPerTest,
    remarksPerTest,
    ConductPerTest,
  } = usePerTestReportConfig();
  const [stdData, setStdData] = useState<stdDataType[]>([]);
  const { token } = useToken();
  const { entry_level } = useInstitutionConfiguration();
  const { format } = formatter;
  const { activeAcademicYearData } = useActiveAcademicYear();
  const { state } = useContext(AppContext);
  const { InstFormData } = useInstDetails(1);

  const [GetStats, { data: statsData, error }] = useLazyQuery<
    GetAcdStudentsMarksStatisticsAtEntryLevelData,
    GetAcdStudentsMarksStatisticsAtEntryLevelVars
  >(GetAcdStudentsMarksStatisticsAtEntryLevel);

  useEffect(() => {
    if (
      token &&
      testConductId &&
      entryId &&
      selectedStudents &&
      entry_level &&
      activeAcademicYearData.data &&
      !activeAcademicYearData.loading &&
      state.ActiveAcdYr
    ) {
      GetStats({
        variables: {
          token,
          inst_id: InstId!,
          student_ids: selectedStudents,
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          input: {
            inst_id: InstId!,
            acd_test_class_id: testConductId ? testConductId.value : 0,
            entry_level: entry_level,
            entry_id: entryId ? Number(entryId) : 0,
            test_type_id: 0,
          },
          acd_subj_combo_id:
            forCombination && state.acdCombinationId
              ? state.acdCombinationId
              : 0,
        },
      });
    } // eslint-disable-next-line
  }, [
    token,
    testConductId,
    entryId,
    selectedStudents,
    entry_level,
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
    GetStats,
    state.ActiveAcdYr,
    forCombination,
    statsData,
  ]);
  useEffect(() => {
    const fetchData = async () => {
      if (statsData) {
        const promise: Promise<stdDataType[]> = new Promise(
          async (res, rej) => {
            let tempStudentList: stdDataType[] = [];
            for (
              let index = 0;
              index <
              statsData.GetAcdStudentsMarksStatisticsAtEntryLevel.length;
              index++
            ) {
              const res =
                statsData.GetAcdStudentsMarksStatisticsAtEntryLevel[index];

              if (res.student_details.std_profile_filename) {
                const filename = `${InstFormData.inst_name}/students/${res.student_id}/std_profile_pic/std_profile_pic`;
                const url = await getDownloadUrl(filename, false);

                if (url) {
                  tempStudentList.push({
                    ...res,
                    profile_pic: url,
                  } as stdDataType);
                } else {
                  tempStudentList.push({ ...res, profile_pic: "" });
                }
              } else {
                tempStudentList.push({ ...res, profile_pic: "" });
              }
            }
            if (
              statsData.GetAcdStudentsMarksStatisticsAtEntryLevel.length ===
              tempStudentList.length
            ) {
              res(tempStudentList);
            }
          }
        );
        promise.then((res) => {
          if (res.length) {
            setStdData(res);
          }
        });
      }
    };
    fetchData();
  }, [statsData]);
  return (
    <>
      {statsData
        ? statsData.GetAcdStudentsMarksStatisticsAtEntryLevel.map(
            (data, index) => {
              const marksDetails = data.marks_details;
              return (
                <>
                  {pageType === PerTestTableFor.MARKS_DETAILS ? (
                    <TableContainer className="progress-report__table">
                      <Table>
                        <TableHead>
                          <TableRow className="progress-report__table--group-header">
                            <TableCell rowSpan={2}>Sl</TableCell>
                            <TableCell rowSpan={2}>Subject Name</TableCell>
                            <TableCell colSpan={2}>Total Marks</TableCell>
                            <TableCell rowSpan={2}>Marks Obtained</TableCell>
                            {gradeForEachSubPerTest?.config_boolean_value ? (
                              <TableCell rowSpan={2}>Marks Obtained</TableCell>
                            ) : null}
                          </TableRow>
                          <TableRow className="progress-report__table--sub-header">
                            <TableCell>Max</TableCell>
                            <TableCell>Min</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {marksDetails.map((res, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell
                                  id="td-center"
                                  className="progress-report__table--slno">
                                  {index + 1}
                                </TableCell>

                                <TableCell>{res.subj_desc}</TableCell>
                                <TableCell id="td-center">
                                  {res.subj_total_max_marks}
                                </TableCell>
                                <TableCell id="td-center">
                                  {res.subj_total_min_marks}
                                </TableCell>
                                <TableCell id="td-center">
                                  {res.is_std_present
                                    ? res.std_marks_scored
                                    : "A"}
                                </TableCell>
                                {gradeForEachSubPerTest?.config_boolean_value ? (
                                  <>
                                    <TableCell id="td-center">
                                      {res.scored_grade}
                                    </TableCell>
                                  </>
                                ) : null}
                              </TableRow>
                            );
                          })}
                        </TableBody>

                        <TableFooter>
                          <TableRow>
                            <TableCell colSpan={2} className="total">
                              Total :
                            </TableCell>
                            <TableCell id="td-center">
                              {data.test_total_max_marks}{" "}
                            </TableCell>
                            <TableCell id="td-center">
                              {data.test_total_min_marks}
                            </TableCell>
                            <TableCell align="center">
                              {data.test_std_total_marks_scored}
                            </TableCell>
                            {gradeForEachSubPerTest?.config_boolean_value ? (
                              <TableCell></TableCell>
                            ) : null}
                          </TableRow>
                          {remarksPerTest?.config_boolean_value && (
                            <TableRow>
                              <TableCell colSpan={2} className="total">
                                {remarksPerTest.config_string_value}
                              </TableCell>
                              <TableCell
                                id="td-center"
                                colSpan={
                                  gradeForEachSubPerTest?.config_boolean_value
                                    ? 4
                                    : 3
                                }>
                                {data.test_remarks}
                              </TableCell>
                            </TableRow>
                          )}
                          {ConductPerTest?.config_boolean_value && (
                            <TableRow>
                              <TableCell colSpan={2} className="total">
                                {ConductPerTest.config_string_value}
                              </TableCell>
                              <TableCell
                                id="td-center"
                                colSpan={
                                  gradeForEachSubPerTest?.config_boolean_value
                                    ? 4
                                    : 3
                                }></TableCell>
                            </TableRow>
                          )}
                        </TableFooter>
                      </Table>
                    </TableContainer>
                  ) : pageType === PerTestTableFor.OVERALL_PERFORMANCE ? (
                    <>
                      {overAllPercentagePerTest?.config_boolean_value && (
                        <div className="progress-report__percentage--details">
                          <div>
                            <img src={SecuredPercentage} alt="" />
                            <span> Secured Percentage</span>
                          </div>
                          <b>: {data.test_std_total_percentage}</b>%
                        </div>
                      )}
                    </>
                  ) : pageType === PerTestTableFor.SIGNATURE ? (
                    <>
                      <div className="progress-report__signature">
                        <div>
                          {PrincipalSign.defaultLogo ? (
                            <img src={PrincipalSign.defaultLogo} alt="/" />
                          ) : null}
                          <div className="progress-report__signature--text">
                            PRINCIPAL SIGN
                          </div>
                        </div>

                        {signatureFromInstPerTest?.config_boolean_value && (
                          <div>
                            <div className="progress-report__signature--text">
                              {signatureFromInstPerTest.config_string_value}
                            </div>
                          </div>
                        )}
                        {classTeacherSignPerTest?.config_boolean_value && (
                          <div>
                            <div className="progress-report__signature--text">
                              {classTeacherSignPerTest.config_string_value}
                            </div>
                          </div>
                        )}
                        {parentSignPerTest?.config_boolean_value && (
                          <div>
                            <div className="progress-report__signature--text">
                              {parentSignPerTest.config_string_value}
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  ) : pageType === PerTestTableFor.OTHER_DETAILS ? (
                    <TableContainer className="bgs-pn-pr__non-acd--table">
                      <Table>
                        <TableHead>
                          <TableRow className="bgs-pn-pr__non-acd--row">
                            <TableCell
                              key={index}
                              id="td-center"
                              colSpan={4}
                              style={{ height: "25px" }}>
                              {otherDetailsPerTest?.config_string_value}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {heightWeightPerTest?.config_boolean_value && (
                            <TableRow>
                              <TableCell>Height</TableCell>
                              <TableCell id="td-center">-</TableCell>
                              <TableCell>Weight</TableCell>
                              <TableCell id="td-center">-</TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : pageType === PerTestTableFor.ATTENDANCE_STATS ? (
                    <TableContainer className="bgs-pn-pr__non-acd--table">
                      <Table>
                        <TableHead>
                          <TableRow className="bgs-pn-pr__non-acd--row">
                            <TableCell
                              key={index}
                              id="td-center"
                              colSpan={4}
                              style={{ height: "25px" }}>
                              {attendanceDetailsPerTest?.config_string_value}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {attendanceStatsPerTest?.config_boolean_value && (
                            <>
                              <TableRow>
                                <TableCell rowSpan={2}>
                                  Attendance Percentage
                                </TableCell>
                                <TableCell id="td-center">
                                  {attendanceStatsPerTest.config_string_value ===
                                  "Auto Fill" ? (
                                    <>{format(data.att_percentage)}</>
                                  ) : (
                                    <></>
                                  )}
                                </TableCell>
                              </TableRow>
                              {/* <TableRow>
                                 <TableCell>No. of Absent Days</TableCell>
                                 <TableCell id="td-center">
                                   {std_absent_days}
                                 </TableCell>
                               </TableRow> */}
                            </>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : null}
                </>
              );
            }
          )
        : null}
    </>
  );
};

export default TableDetails;
