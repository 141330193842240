import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/context";

import useToken from "../../../customhooks/useToken";
import { GetAcctStdDeposits } from "../queries/FeeLedgers/query/Byid";
import { GetAcctLdgrsByTypeDetails } from "../../../Types/Accounting";

import { DepositLedgerQueryType } from "../common/QueryTypes";
import { Direction } from "../../../utils/Enum.types";
import { useParams } from "react-router-dom";
import { EMPTY_STRING } from "../../../utils/constants";
import { StudentAcdNode } from "../../Academics/hooks/useAcdStudentsForAdmission";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
export interface DepositDetailNode {
  id: number;
  deposit_ob: number;
  deposit_amt: number;
  deposit_total: number;
  deposit_adjusted: number;
  deposit_refunded: number;
  is_scholarship: boolean;

  deposit_bal: number;
  acct_ldgr_id: number;
  acct_ldgr_details: GetAcctLdgrsByTypeDetails;
  deposit_refund: number;
  student_details: StudentAcdNode;
  branch: {
    branch_desc: string;
  };
  class: {
    class_desc: string;
  };
}
export interface DepositEdge {
  node: DepositDetailNode;
}
interface DepositPageInfo {
  endCursor: string;
  hasNextPage: boolean;
}
interface DepositDetails {
  edges: DepositEdge[];
  pageInfo: DepositPageInfo;
  totalCount: number;
}

export interface GetAcctStdDepositsData {
  GetAcctStdDeposits: DepositDetails;
}

export interface GetAcctStdDepositsVars {
  token: string;
  inst_id: string;
  fin_yr_id: number;
  after: string | null;
  input: {
    query_type: string;
    student_id: number;
    ids: number[];
    str_data: string[];
    int_data: number[];
    float_data: string[];
    std_status?: string[];
  };
  orderBy: { direction: Direction; field: string };
  deptID: number | null;
  branchID: number | null;
  classID: number | null;
  semesterID: number | null;
  sectionID: number | null;
  categoryID: number | null;
  name: string;
  ldgrDesc: string;
}

const useStudentDepositDetails = (
  type: DepositLedgerQueryType,
  name?: string,
  ldgrDesc?: string,
  deptID?: number,
  branchID?: number,
  classID?: number,
  semesterID?: number,
  sectionID?: number,
  categoryID?: number,
  status?: string,
  float_data?: string,
  acctLdgrId?: number
) => {
  const { state } = useContext(AppContext);
  const { InstId } = useParams();
  const { token } = useToken();
  const [ids, setId] = useState<number[]>([]);
  const [std_demand_query_type, setstd_query_type] = useState("");

  const [GetStudentDepositLedgers, { data, loading, error, fetchMore }] =
    useLazyQuery<GetAcctStdDepositsData, GetAcctStdDepositsVars>(
      GetAcctStdDeposits,
      { fetchPolicy: "network-only" }
    );
  const [entryId, setEntryId] = useState(0);

  const {
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_DEPARTMENT_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();
  useEffect(() => {
    if (sectionID && USE_SECTION_KEY) {
      setEntryId(sectionID);
    } else if (semesterID && USE_SEMESTER_KEY) {
      setEntryId(semesterID);
    } else if (classID && USE_CLASS_KEY) {
      setEntryId(classID);
    } else if (branchID && USE_BRANCH_KEY) {
      setEntryId(branchID);
    } else if (deptID && USE_DEPARTMENT_KEY) {
      setEntryId(deptID);
    } else {
      setEntryId(Number(InstId));
    }
  }, [
    sectionID,
    USE_SECTION_KEY,
    semesterID,
    USE_SEMESTER_KEY,
    classID,
    USE_CLASS_KEY,
    branchID,
    USE_BRANCH_KEY,
    deptID,
    USE_DEPARTMENT_KEY,
    InstId,
  ]);
  useEffect(() => {
    switch (type) {
      case DepositLedgerQueryType.DEPOSIT_BAL_GT_REQUESTED_AMT:
        setId([entryId, categoryID ? categoryID : 0, acctLdgrId!]);
        setstd_query_type(DepositLedgerQueryType.DEPOSIT_BAL_GT_REQUESTED_AMT);
        break;
      case DepositLedgerQueryType.ALL_DEPOSIT_LDGRS_FOR_ALL_STDS:
        setId([
          entryId,
          categoryID ? categoryID : 0,
          acctLdgrId ? acctLdgrId : 0,
        ]);
        setstd_query_type(
          DepositLedgerQueryType.ALL_DEPOSIT_LDGRS_FOR_ALL_STDS
        );
        break;
      case DepositLedgerQueryType.DEPOSIT_GT_0_BY_STD_ID:
        setId([
          entryId,
          categoryID ? categoryID : 0,
          acctLdgrId ? acctLdgrId : 0,
        ]);
        setstd_query_type(DepositLedgerQueryType.DEPOSIT_GT_0_BY_STD_ID);
        break;
      default:
        break;
    }
  }, [
    InstId,
    deptID,
    branchID,
    classID,
    semesterID,
    sectionID,
    categoryID,
    type,
    acctLdgrId,
    entryId,
  ]);
  useEffect(() => {
    if (std_demand_query_type && state.ActiveFinYr) {
      GetStudentDepositLedgers({
        variables: {
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          token,
          after: null,
          ldgrDesc: ldgrDesc ? ldgrDesc : EMPTY_STRING,
          input: {
            query_type: std_demand_query_type,
            student_id: state.studentId,
            ids,
            int_data: [],
            str_data: [],
            float_data: float_data ? [float_data] : [],
          },
          inst_id: InstId! ? InstId : state.InstId.toString(),
          orderBy: { direction: Direction.ASC, field: "STUDENT_ID" },
          deptID: deptID ? deptID : null,
          branchID: branchID ? branchID : null,
          classID: classID ? classID : null,
          semesterID: semesterID ? semesterID : null,
          sectionID: sectionID ? sectionID : null,
          categoryID: categoryID ? categoryID : null,
          name: name ? name : EMPTY_STRING,
        },
      });
    }
  }, [
    state.ActiveFinYr,
    token,
    state.studentId,
    InstId,
    GetStudentDepositLedgers,
    deptID,
    branchID,
    classID,
    semesterID,
    sectionID,
    name,
    ldgrDesc,
    categoryID,
    state.InstId,
    float_data,
    status,
    ids,
    std_demand_query_type,
  ]);
  return { StudentDepositData: { data, loading, error, fetchMore } };
};

export default useStudentDepositDetails;
