import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { PageInfo } from "../../Channel/Types";
import {
  DebitOrCredit,
  Direction,
  Operation,
  SortBy,
} from "../../../utils/Enum.types";
import { GetAcctOnlinePayments } from "../queries/Payments";
import useToken from "../../../customhooks/useToken";
import {
  E000,
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ONLINE_TRANSACTION_ROWS_PER_PAGE,
  ROWS_PER_PAGE,
  emptyMessageType,
} from "../../../utils/constants";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../context/context";

import useAcctTableJson from "../json/useAcctTableJson";
import Modal from "react-modal";

import {
  formatter,
  getModifiedScrollHeight,
  toStandardDate,
} from "../../../utils/UtilFunctions";
import {
  PrintModalStyles,
  StudentModalStyles,
} from "../../../styles/ModalStyles";
import Print from "../../Print/Accounts/FeeReceipts/Standard/FeeReceiptPrint";

import Close from "../../../images/Close.svg";
import { msgType, optionsType } from "../../../utils/Form.types";
import { Data } from "../../Print/Accounts/FeeReceipts/templates/Index";
import { StudentDetailsByMasterVoucherId } from "../../../queries/common";
import { VouchernodeData, nodevars } from "../../../Types/Accounting";
import LogData from "./LogData";
import { AddAcctVoucherMasterOnlineStdReceipt } from "../queries/receipts/mutation/Index";
import { userDetails } from "../../../Types/Accounting/other";
import useLoggedInUserDetails from "../hooks/useLoggedInUserDetails";
import MessageModal from "../../../pages/MessageModal";
import { StudentDetails } from "../../../customhooks/useStudentDatabyId";
import useUserRightsByEmpId from "../../UserRights/hooks/useUserRightsByEmpId";
import { TableHeaderProps } from "../../../utils/types";
import {
  GridAlignment,
  GridColDef,
  GridRenderCellParams,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../styles/DataGridTableStyles";
import { payloadTypes } from "../../../context/reducer";

export enum AcctOnlinePaymentQueryType {
  ON_PY_RECORDS = "ON_PY_RECORDS",
  ON_PY_SUCCESS_RECORDS = "ON_PY_SUCCESS_RECORDS",
  ON_PY_FAILED_RECORDS = "ON_PY_FAILED_RECORDS",
  ON_PY_IN_COMPLETE_RECORDS = "ON_PY_IN_COMPLETE_RECORDS",
}
export interface AcctOnlinePaymentsNode {
  id: number;
  uuid: string;
  response_code: string;
  unique_ref_number: string;
  service_tax_amount: string;
  processing_fee_amount: string;
  total_amount: string;
  transaction_amount: string;
  transaction_date: string;
  response_code_str: string;
  payment_mode: string;
  sub_merchant_id: string;
  reference_no: string;
  merchant_eazypay_id: string;
  mandatory_fields: string;
  optional_fields: string;
  voucher_no: string;
  voucher_master_id: number;
  created_at: string;
}
interface AcctOnlinePaymentsEdge {
  node: AcctOnlinePaymentsNode;
  cursor: string;
}
interface GetAcctOnlinePaymentsData {
  GetAcctOnlinePayments: {
    totalCount: number;
    pageInfo: PageInfo;
    edges: AcctOnlinePaymentsEdge[];
  };
}
interface GetAcctOnlinePaymentsVars {
  token: string;
  inst_id: string;
  first: number | null;
  after: string | null;
  query_type: AcctOnlinePaymentQueryType;
  student_id: number;
  fin_yr_id: number;
  orderBy: { direction: Direction; field?: string };
  // responseCodeNEQ: string;
  transactionDateContainsFold: string;
  searchString: String;
}

interface Props {
  showLogDataBtn: boolean;
  queryType: optionsType;
  transactionDateContainsFold: string;
  statusCode: string;
  searchString: string;
  setCount: React.Dispatch<React.SetStateAction<number>>;
}
interface AddAcctVoucherMasterOnlineStdReceiptData {
  AddAcctVoucherMasterOnlineStdReceipt: {
    id: number;
  }[];
}
interface AddAcctVoucherMasterOnlineStdReceiptVars {
  token: string;
  inst_id: number;
  fin_yr_id: number;
  user_details: userDetails;
  online_payment_id: number;
}
const TransactionTable = ({
  showLogDataBtn,
  queryType,
  transactionDateContainsFold,
  statusCode,
  searchString,
  setCount,
}: Props) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { state, dispatch } = useContext(AppContext);
  const [logData, setLogData] = useState(false);
  const { user_details } = useLoggedInUserDetails();
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [message, setMessage] = useState<msgType>(emptyMessageType);
  const [transactions, setTransactions] = useState<AcctOnlinePaymentsEdge[]>(
    []
  );
  const [rows, setRows] = useState<GridValidRowModel[]>([]);

  const [studentDetails, setStudentDetails] = useState<StudentDetails | null>(
    null
  );
  const [transactionData, setTransactionData] =
    useState<AcctOnlinePaymentsNode | null>(null);
  const [GetAcctVoucher] = useLazyQuery<VouchernodeData, nodevars>(
    StudentDetailsByMasterVoucherId
  );
  const [tableData, setTableData] = useState<Data[]>([]);
  const [v_no, set_vo_no] = useState("");
  const [v_date, set_v_Date] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("");
  const { Accounts_Table } = useAcctTableJson();
  const [printModal, setPrintModal] = useState(false);

  const { format } = formatter;
  const { USE_ACCOUNTS } = useUserRightsByEmpId();
  const [GetAcctPayments, { data, error, loading, fetchMore }] = useLazyQuery<
    GetAcctOnlinePaymentsData,
    GetAcctOnlinePaymentsVars
  >(GetAcctOnlinePayments);

  const [AddMissedVoucher] = useMutation<
    AddAcctVoucherMasterOnlineStdReceiptData,
    AddAcctVoucherMasterOnlineStdReceiptVars
  >(AddAcctVoucherMasterOnlineStdReceipt, {
    onError: (e) => {
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      });
    },
  });

  useEffect(() => {
    if (token && InstId && state.ActiveFinYr) {
      GetAcctPayments({
        variables: {
          token,
          after: null,
          first: ROWS_PER_PAGE,
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          inst_id: InstId!,
          orderBy: {
            direction: Direction.ASC,
            field: SortBy.CREATED_AT,
          },
          query_type: queryType.value as AcctOnlinePaymentQueryType,
          student_id: state.studentId,

          transactionDateContainsFold: transactionDateContainsFold
            ? toStandardDate(transactionDateContainsFold)
            : EMPTY_STRING,
          searchString,
        },
      }).then(({ data }) => {
        if (data && data.GetAcctOnlinePayments) {
          setCount(data.GetAcctOnlinePayments.totalCount);
        }
      });
    }
  }, [
    token,
    GetAcctPayments,
    InstId,
    state.studentId,
    state.ActiveFinYr,
    queryType,
    transactionDateContainsFold,
    searchString,
    statusCode,
    data,
  ]);

  const handleViewVoucher = (v_id: number) => {
    GetAcctVoucher({
      variables: {
        ids: [v_id],
        token,
      },
    }).then(({ data }) => {
      if (data && data.nodes) {
        if (data.nodes.length) {
          setStudentDetails(data.nodes[0].mst_student);
          setTableData(
            data.nodes[0].acct_voucher_details
              .filter(
                (node) =>
                  node.vo_cr_db === DebitOrCredit.CREDIT && node.vo_cr > 0
              )
              .map((node) => ({
                particular: node.acct_ldgr.ldgr_desc,
                amount:
                  node.vo_cr_db === DebitOrCredit.CREDIT
                    ? node.vo_cr
                    : node.vo_db,
              }))
          );
          dispatch({
            type: payloadTypes.SET_STUDENT_ID,
            payload: {
              studentId: data.nodes[0].mst_student.id,
            },
          });
          if (data.nodes.length) {
            set_vo_no(data.nodes[0].v_no);
            if (data.nodes[0].acct_voucher_details.length)
              set_v_Date(data.nodes[0].acct_voucher_details[0].vo_date);
            setReferenceNumber(data.nodes[0].v_transcation_no);
          }
        }

        setPrintModal(!printModal);
      }
    });
  };
  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetAcctOnlinePayments.edges.map((edge) => ({
        ...edge,
        node: {
          ...edge.node,
          isChecked: true, // set default value of isChecked to true
        },
      }));

      if (endCursor) {
        // If we have already fetched some data, we need to check if there
        // are any duplicates in the new data, and update their isChecked
        // property based on the existing data.
        // const filteredStudents = students.filter(
        //   (student) => !student.node.isChecked
        // );

        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = transactions.find(
            (transaction) => transaction.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
                // isChecked: filteredStudent.node.isChecked,
              },
            };
          }
          return newStudent;
        });
        setTransactions(updatedNewData);
      } else {
        setTransactions(newData);
      }
      setEndCursor(data.GetAcctOnlinePayments.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);

  const generateMissedVoucher = (id: number) => {
    AddMissedVoucher({
      variables: {
        fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
        inst_id: Number(InstId)!,
        online_payment_id: id,
        token,
        user_details,
      },
      refetchQueries: [
        {
          query: GetAcctOnlinePayments,
          variables: {
            token,
            after: null,
            first: ONLINE_TRANSACTION_ROWS_PER_PAGE,
            fin_yr_id: state.ActiveFinYr?.id,
            inst_id: InstId!,
            orderBy: {
              direction: Direction.ASC,
              field: SortBy.CREATED_AT,
            },
            query_type: queryType.value as AcctOnlinePaymentQueryType,
            student_id: state.studentId,

            transactionDateContainsFold: transactionDateContainsFold
              ? toStandardDate(transactionDateContainsFold)
              : EMPTY_STRING,
            searchString,
          },
        },
      ],
    }).then(({ data }) => {
      if (
        data &&
        data.AddAcctVoucherMasterOnlineStdReceipt &&
        data.AddAcctVoucherMasterOnlineStdReceipt.length &&
        data.AddAcctVoucherMasterOnlineStdReceipt[0].id
      ) {
        setMessage({
          flag: true,
          message: "Raised voucher",
          operation: Operation.CREATE,
        });
      }
    });
  };

  const dynamicHeaders: TableHeaderProps[] =
    Accounts_Table.OnlineTransactionDetails.Table_Headers.map((header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
      hideable: header.hideable,
    }));

  const columns: GridColDef[] = [
    ...dynamicHeaders,

    {
      field: "status",
      headerName: "Transaction Status",
      headerAlign: "center" as GridAlignment,
      sortable: false,
      cellClassName: "td-status",

      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            <div
              className={
                params.row.response_code === E000 &&
                params.row.voucher_master_id === 0
                  ? "online-transaction__table--no-voucher"
                  : params.row.response_code === E000
                  ? "online-transaction__table--success"
                  : params.row.response_code === EMPTY_STRING
                  ? "online-transaction__table--incomplete"
                  : "online-transaction__table--failure"
              }
            >
              {params.row.response_code === E000 &&
              params.row.voucher_master_id === 0
                ? "Success / Voucher not generated"
                : params.row.response_code === E000
                ? "Success"
                : params.row.response_code === EMPTY_STRING
                ? "Incomplete"
                : "Failed"}
            </div>
          </>
        );
      },
    },
    ...(showLogDataBtn
      ? [
          {
            field: "actions",
            headerName: "Actions",
            headerAlign: "center" as GridAlignment,
            align: "center" as GridAlignment,
            sortable: false,

            renderCell: (params: GridRenderCellParams) => {
              return (
                <>
                  <button
                    onClick={() => {
                      setTransactionData(params.row.transaction_id);
                      setLogData(true);
                    }}
                  >
                    Get Bank Log
                  </button>
                </>
              );
            },
          },
        ]
      : []),

    {
      field: "generate_voucher",
      headerName: "Generate Voucher",
      headerAlign: "center" as GridAlignment,
      align: "center" as GridAlignment,
      sortable: false,

      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            {params.row.response_code === E000 &&
            params.row.voucher_master_id === 0 ? (
              <button
                onClick={() => {
                  generateMissedVoucher(params.row.transaction_id);
                }}
                disabled={
                  USE_ACCOUNTS.details
                    ? USE_ACCOUNTS.details.add === false
                    : false
                }
              >
                Generate Voucher
              </button>
            ) : null}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetAcctOnlinePayments.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newRow) => {
          const filteredStudent = rows.find(
            (row) => row.transaction_id && row.transaction_id === newRow.node.id
          );
          if (filteredStudent) {
            return {
              ...newRow,
              node: {
                ...newRow.node,
              },
            };
          }
          return newRow;
        });
        setTransactions(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            t_date: node.transaction_date,
            bank_processed_date: node.transaction_date
              ? `${node.transaction_date.split(" ")[0]} ${
                  node.transaction_date.split(" ")[1]
                }`
              : EMPTY_STRING,
            unique_ref_no: node.unique_ref_number,
            uuid: node.uuid,
            ref_no: node.reference_no,
            merchant_id: node.merchant_eazypay_id,
            payment_mode: node.payment_mode,
            transaction_amount: node.transaction_amount
              ? format(Number(node.transaction_amount))
              : EMPTY_STRING,
            total_amount: node.total_amount
              ? format(Number(node.total_amount))
              : EMPTY_STRING,
            status_code: node.response_code,
            message: node.response_code_str,
            voucher_no: node.voucher_no,
            response_code: node.response_code,
            voucher_master_id: node.voucher_master_id,
            transaction_id: node.id,
          }))
        );
      } else {
        setTransactions(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            t_date: node.transaction_date,
            bank_processed_date: node.transaction_date
              ? `${node.transaction_date.split(" ")[0]} ${
                  node.transaction_date.split(" ")[1]
                }`
              : EMPTY_STRING,
            unique_ref_no: node.unique_ref_number,
            uuid: node.uuid,
            ref_no: node.reference_no,
            merchant_id: node.merchant_eazypay_id,
            payment_mode: node.payment_mode,
            transaction_amount: node.transaction_amount
              ? format(Number(node.transaction_amount))
              : EMPTY_STRING,
            total_amount: node.total_amount
              ? format(Number(node.total_amount))
              : EMPTY_STRING,
            status_code: node.response_code,
            message: node.response_code_str,
            voucher_no: node.voucher_no,
            response_code: node.response_code,
            voucher_master_id: node.voucher_master_id,
            transaction_id: node.id,
          }))
        );
      }
      setEndCursor(data.GetAcctOnlinePayments.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);

  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;

      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: FETCH_MORE_DATA,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetAcctOnlinePayments.edges;
                const pageInfo = fetchMoreResult.GetAcctOnlinePayments.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck =
                  prevResult.GetAcctOnlinePayments.edges.filter(
                    ({ node: { id } }) =>
                      newEdges.findIndex(
                        ({ node: { id: newId } }) => newId === id
                      ) !== -1
                  );

                if (duplicateCheck.length > 0) return prevResult;

                return {
                  GetAcctOnlinePayments: {
                    edges: [...transactions, ...newEdges],
                    pageInfo,
                    totalCount: data
                      ? data.GetAcctOnlinePayments.totalCount!
                      : 0,
                  },
                };
              },
            });
          }
        }
      };

      if (scrollTable && rows.length)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    [rows]
  );

  return (
    <div className={`online-transaction__tableblock `}>
      {error ? (
        <b className="nodata">{error.message}</b>
      ) : (
        // <TableContainer
        //   className="online-transaction__table"
        //   onScroll={handleScroll}
        // >
        //   <Table stickyHeader>
        //     <TableHead>
        //       <TableRow>
        //         {OnlineTransactionDetails.Table_Headers.filter(
        //           (th) =>
        //             (th.labelName !== "Action" && showLogDataBtn === false) ||
        //             showLogDataBtn
        //         ).map((th: TableHeaderProps, index: React.Key) => {
        //           return <TableCell key={index}>{th.labelName}</TableCell>;
        //         })}
        //         <TableCell>Generate Voucher</TableCell>
        //       </TableRow>
        //     </TableHead>
        //     <TableBody>
        //       {data && transactions.length
        //         ? transactions.map((edge, index) => {
        //             const { istDate, istTime } = convertIsoToIst(
        //               edge.node.created_at
        //             );
        //             return (
        //               <TableRow key={index}>
        //                 <TableCell
        //                   className="online-transaction__table--slno"
        //                   id="td-center"
        //                 >
        //                   {index + 1}
        //                 </TableCell>
        //                 <TableCell className="online-transaction__table--date">
        //                   {`${toStandardDate(istDate)} ${istTime}`}
        //                 </TableCell>
        //                 <TableCell className="online-transaction__table--date">
        //                   {edge.node.transaction_date
        //                     ? `${edge.node.transaction_date.split(" ")[0]} ${
        //                         edge.node.transaction_date.split(" ")[1]
        //                       }`
        //                     : EMPTY_STRING}
        //                 </TableCell>
        //                 <TableCell id="td-center">
        //                   {edge.node.unique_ref_number}
        //                 </TableCell>
        //                 <TableCell>{edge.node.uuid}</TableCell>
        //                 <TableCell>{edge.node.reference_no}</TableCell>
        //                 <TableCell>{edge.node.merchant_eazypay_id}</TableCell>
        //                 <TableCell>{edge.node.payment_mode}</TableCell>
        //                 <TableCell id="td-right">
        //                   {edge.node.transaction_amount
        //                     ? format(Number(edge.node.transaction_amount))
        //                     : EMPTY_STRING}
        //                 </TableCell>
        //                 <TableCell id="td-right">
        //                   {edge.node.total_amount
        //                     ? format(Number(edge.node.total_amount))
        //                     : EMPTY_STRING}
        //                 </TableCell>
        //                 <TableCell
        //                   className={
        //                     edge.node.response_code === E000
        //                       ? "font-green"
        //                       : "font-red"
        //                   }
        //                   id="td-center"
        //                 >
        //                   {edge.node.response_code}
        //                 </TableCell>
        //                 <TableCell className="online-transaction__table--message">
        //                   {edge.node.response_code_str}
        //                 </TableCell>
        //                 <TableCell
        //                   className="online-transaction__table--vno"
        //                   id="td-center"
        //                   onClick={() => {
        //                     if (edge.node.voucher_no) {
        //                       handleViewVoucher(edge.node.voucher_master_id);
        //                     }
        //                   }}
        //                 >
        //                   {edge.node.voucher_no}
        //                 </TableCell>
        //                 <TableCell
        //                   className={
        //                     edge.node.response_code === E000 &&
        //                     edge.node.voucher_master_id === 0
        //                       ? "online-transaction__table--no-voucher"
        //                       : edge.node.response_code === E000
        //                       ? "online-transaction__table--success"
        //                       : edge.node.response_code === EMPTY_STRING
        //                       ? "online-transaction__table--incomplete"
        //                       : "online-transaction__table--failure"
        //                   }
        //                   id="td-center"
        //                 >
        //                   {edge.node.response_code === E000 &&
        //                   edge.node.voucher_master_id === 0
        //                     ? "Success / Voucher not generated"
        //                     : edge.node.response_code === E000
        //                     ? "Success"
        //                     : edge.node.response_code === EMPTY_STRING
        //                     ? "Incomplete"
        //                     : "Failed"}
        //                 </TableCell>
        //                 {showLogDataBtn ? (
        //                   <TableCell
        //                     className="online-transaction__table--actions"
        //                     id="td-center"
        //                   >
        //                     <button
        //                       onClick={() => {
        //                         setTransactionData(edge.node);
        //                         setLogData(true);
        //                       }}
        //                     >
        //                       Get Bank Log
        //                     </button>
        //                   </TableCell>
        //                 ) : null}
        //                 <TableCell
        //                   className="online-transaction__table--actions"
        //                   id="td-center"
        //                 >
        //                   {edge.node.response_code === E000 &&
        //                   edge.node.voucher_master_id === 0 ? (
        //                     <button
        //                       onClick={() => {
        //                         generateMissedVoucher(edge.node.id);
        //                       }}
        //                       disabled={
        //                         USE_ACCOUNTS.details
        //                           ? USE_ACCOUNTS.details.add === false
        //                           : false
        //                       }
        //                     >
        //                       Generate Voucher
        //                     </button>
        //                   ) : null}
        //                 </TableCell>
        //               </TableRow>
        //             );
        //           })
        //         : null}
        //     </TableBody>
        //   </Table>
        // </TableContainer>
        <StyledDatagrid
          columns={columns}
          rows={rows}
          onCellClick={(params) => {
            if (params.field === "voucher_no")
              if (params.row.voucher_no) {
                handleViewVoucher(params.row.voucher_master_id);
              }
          }}
          disableRowSelectionOnClick
          disableChildrenSorting
          rowHeight={TABLE_ROW_HEIGHT}
          hideFooter
        />
      )}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={printModal}
        style={PrintModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Print
              tableData={tableData}
              v_date={v_date}
              v_no={v_no}
              studentDetails={studentDetails}
              transaction_no={referenceNumber}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => {
                dispatch({
                  type: payloadTypes.SET_STUDENT_ID,
                  payload: {
                    studentId: 0,
                  },
                });
                setPrintModal(!printModal);
              }}
            />
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={logData}
        ariaHideApp={false}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <LogData
              setModal={setLogData}
              transactionData={transactionData}
              handleViewVoucher={handleViewVoucher}
            />
          </div>
          <div className="modal-flex__image">
            <img src={Close} alt="" onClick={() => setLogData(!logData)} />
          </div>
        </div>
      </Modal>
      <MessageModal
        handleClose={() => {
          setMessage(emptyMessageType);
        }}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </div>
  );
};

export default TransactionTable;
