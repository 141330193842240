import { useContext, useState } from "react";
import { Button } from "../../../stories/Button/Button";
import { Title } from "../../../stories/Title/Title";

import Home from "../../Master/configurations/Home/Index";

import { useNavigate, useParams } from "react-router-dom";
import ReceiptAndPayment from "../../Accounts/Reports/General/R&P/Index";
import IncomeAndExpenditure from "../../Accounts/Reports/General/I&E/Index";
import BalanceSheet from "../../Accounts/Reports/General/BalanceSheet/Index";
import Modal from "react-modal";
import { StudentModalStyles } from "../../../styles/ModalStyles";

import ReceiptAndPaymentAccount from "../../../images/ReceiptandPaymentAccount.svg";
import BalanceSheetImg from "../../../images/BalanceSheet.svg";
import IncomeAndExpenditureAccount from "../../../images/IncomeandExpenditureAccount.svg";
import CloseImg from "../../../images/Close.svg";
import { Operation, PageFor } from "../../../utils/Enum.types";
import { useMutation } from "@apollo/client";
import {
  ReCalculateAccounts,
  ResetAccountsData,
} from "../../Accounts/queries/receipts/mutation/Index";
import { msgType } from "../../../utils/Form.types";
import useToken from "../../../customhooks/useToken";
import LoadingModal from "../../../pages/LoadingModal";
import MessageModal from "../../../pages/MessageModal";
import { ReCalculateAcdSummaryCounts } from "../../../queries/institution/mutations/new";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import { AppContext } from "../../../context/context";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const AdminTools = () => {
  const navigate = useNavigate();
  const { InstId } = useParams();
  const { token } = useToken();
  const { user_details } = useLoggedInUserDetails();
  const { state } = useContext(AppContext);
  const [receiptAndPaymentModal, setReceiptAndPaymentModal] = useState(false);
  const [incomeAndExpenditureModal, setIncomeAndExpenditureModal] =
    useState(false);
  const [balanceSheetModal, setBalanceSheet] = useState(false);
  const [resetcalClicked, setResetCalClicked] = useState(true);
  const [message, setMessage] = useState<msgType>({
    flag: false,
    message: "",
    operation: Operation.NONE,
  });
  const [ResetData, { loading }] = useMutation(ResetAccountsData, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const [Recaluculate, { loading: reloading }] = useMutation(
    ReCalculateAccounts,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [RecalculateSummary, { loading: reCalSummaryLoading }] = useMutation(
    ReCalculateAcdSummaryCounts,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const handleResetData = () => {
    ResetData({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
      },
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Successfully Reseted Data",
          operation: Operation.CREATE,
        });
        setResetCalClicked(false);
      }
    });
  };
  const handleRecalculateSummaryCount = () => {
    RecalculateSummary({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      },
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Successfully Recalculated Summary Count",
          operation: Operation.CREATE,
        });
        setResetCalClicked(false);
      }
    });
  };

  const handleRecaluculateData = () => {
    Recaluculate({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
      },
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Successfully Recaluculated Data",
          operation: Operation.CREATE,
        });
        setResetCalClicked(true);
      }
    });
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Recalculation / Reset Calculation</Title>
      <div className="admintools-config">
        <div className="row g-0"></div>
        <div className="row g-0">
          <div className="col">
            <Title variant="subtitle1">
              Calculation has been Reset / Calculation has been Recalculated
            </Title>
          </div>
        </div>

        <div className="row g-0 admintools-config__data">
          <div className="col admintools-config__data--data-block">
            <div className="row g-0 admintools-config__data--data-block">
              <div className="col admintools-config__data--block">
                <Title variant="subtitle1">Reset Calculation</Title>
                <img
                  src={ReceiptAndPaymentAccount}
                  alt="/"
                  className="reset-config__data--block--image"
                />
                <Button
                  mode="reset"
                  onClick={handleResetData}
                  // onClick={() => setReceiptAndPaymentModal(!receiptAndPaymentModal)}
                >
                  Reset
                  {/* <img src={DataFetch} alt="/" />
              &nbsp;Open */}
                </Button>
              </div>
              <div className="col admintools-config__data--block">
                <Title variant="subtitle1">Re-Calculation</Title>

                <img
                  src={IncomeAndExpenditureAccount}
                  alt="/"
                  className="reset-config__data--block--image"
                />

                <Button
                  mode="re-calculate"
                  onClick={handleRecaluculateData}
                  // onClick={() => setIncomeAndExpenditureModal(!incomeAndExpenditureModal)}
                >
                  {" "}
                  Recalculation
                  {/* <img src={DataFetch} alt="/" />
              &nbsp;Open */}
                </Button>
              </div>
            </div>
            <div className="row g-0 admintools-config__data--data-block">
              <div className="col admintools-config__data--block">
                <Title variant="subtitle1">Recalculate Summary Counts</Title>

                <img
                  src={BalanceSheetImg}
                  alt="/"
                  className="reset-config__data--block--image"
                />

                <Button
                  onClick={handleRecalculateSummaryCount}
                  // onClick={() => setBalanceSheet(!balanceSheetModal)}
                >
                  Recalculate Summary Counts
                  {/* <img src={DataFetch} alt="/" />
              &nbsp;Open */}
                </Button>
              </div>
              <div className="col admintools-config__data--block">
                <Title variant="subtitle1">Delete all accounts data</Title>

                <img
                  src={BalanceSheetImg}
                  alt="/"
                  className="reset-config__data--block--image"
                />

                <Button
                  mode="delete"
                  onClick={() =>
                    navigate(
                      `/${InstId}/institutionconfiguration/deletereceiptsvoucher`
                    )
                  }
                  // onClick={() => setBalanceSheet(!balanceSheetModal)}
                >
                  {/* <img src={DataFetch} alt="/" />
              &nbsp;Open */}
                </Button>
              </div>
              <div className="col admintools-config__data--block">
                <Title variant="subtitle1">Change Active Financial Year</Title>

                <img
                  src={BalanceSheetImg}
                  alt="/"
                  className="reset-config__data--block--image"
                />

                <Button
                  onClick={() =>
                    navigate(
                      `/${InstId}/institutionconfiguration/makeFinYearActive`
                    )
                  }
                  // onClick={() => setBalanceSheet(!balanceSheetModal)}
                >
                  {/* <img src={DataFetch} alt="/" />
              &nbsp;Open */}
                  Change Active Fin Year
                </Button>
              </div>
            </div>
          </div>

          <div className="col-3 admintools-config__table">
            <TableContainer className="admintools-config__table--cell">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>View</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      onClick={() =>
                        setReceiptAndPaymentModal(!receiptAndPaymentModal)
                      }>
                      Receipt & Payment Account
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      onClick={() =>
                        setIncomeAndExpenditureModal(!incomeAndExpenditureModal)
                      }>
                      Income and Expenditure Account
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      onClick={() => setBalanceSheet(!balanceSheetModal)}>
                      Balance Sheet
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
        {/* <Button mode="reset" onClick={handleResetData}>
          Reset Calculation
        </Button>
        <Button
          mode="re-calculate"
          disabled={resetcalClicked}
          onClick={handleRecaluculateData}
        >
          Recalculation
        </Button>
        <Button onClick={handleRecalculateSummaryCount}>
          Recalculate Summary Counts
        </Button> */}
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>

      {/* receipt and payment modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={receiptAndPaymentModal}
        style={StudentModalStyles}
        ariaHideApp={false}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ReceiptAndPayment
              pageType={PageFor.MODAL}
              setModalFlag={setReceiptAndPaymentModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              onClick={() => setReceiptAndPaymentModal(!receiptAndPaymentModal)}
              src={CloseImg}
              alt="/"
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
      {/* income and expenditure modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={incomeAndExpenditureModal}
        style={StudentModalStyles}
        ariaHideApp={false}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <IncomeAndExpenditure
              pageType={PageFor.MODAL}
              setModalFlag={setIncomeAndExpenditureModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              onClick={() =>
                setIncomeAndExpenditureModal(!incomeAndExpenditureModal)
              }
              src={CloseImg}
              alt="/"
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
      {/* balance-modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={balanceSheetModal}
        style={StudentModalStyles}
        ariaHideApp={false}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <BalanceSheet
              pageType={PageFor.MODAL}
              setModalFlag={setBalanceSheet}
            />
          </div>
          <div className="modal-flex__image">
            <img
              onClick={() => setBalanceSheet(!balanceSheetModal)}
              src={CloseImg}
              alt="/"
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
      <LoadingModal flag={loading || reloading || reCalSummaryLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default AdminTools;
