import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Title } from "../../../stories/Title/Title";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Edit from "../../../images/Edit.svg";
import Close from "../../../images/Close.svg";
import Avatar from "../../../images/Avatar.svg";
import Settings from "../../../images/Settings.svg";

import Modal from "react-modal";
import {
  DeleteLedgerModalStyles,
  DepositModalCustomStyles,
  PrintConfigModalStyles,
  PrintModalStyles,
  StudentModalStyles,
} from "../../../styles/ModalStyles";
import StudentList from "../common/StudentList";
import {
  BankOrCash,
  DebitOrCredit,
  Direction,
  ModuleName,
  NonDemand,
  Operation,
  PageFor,
  ReceiptTypes,
  StudentAcctReportType,
  StudentListFor,
  VoucherBookKey,
  YesNo,
} from "../../../utils/Enum.types";
import { Label } from "../../../stories/Label/Label";
import Input from "../../../stories/Input/Input";
import { Button } from "../../../stories/Button/Button";
import { AccountsTableHeaderProps } from "../../../Types/Tables";
import useAcctStdAdmissionNumber from "../hooks/useAcctStdAdmissionNumber";
import {
  DEFAULT_TIME,
  EMPTY_STRING,
  NumberOfAdmissionNumberToBeDisplayed,
  ROWS_PER_PAGE,
} from "../../../utils/constants";
import useStudentDatabyId from "../../../customhooks/useStudentDatabyId";
import { Keys } from "../../../utils/Enum.keys";
import { payloadTypes } from "../../../context/reducer";
import { msgType, responseType } from "../../../utils/Form.types";
import { AppContext } from "../../../context/context";
import { getDownloadUrl } from "../../../utils/DownloadFile";
import useStudentDemandDetails, {
  studentDemandDetailsData,
} from "../hooks/useStudentDemandDetails";
import {
  AcctLedgerQueryType,
  DemandDetailsQueryType,
  DepositLedgerQueryType,
  LedgerType,
} from "../common/QueryTypes";
import { nodevars, studentRecepit } from "../../../Types/Accounting";
import { useLazyQuery, useMutation } from "@apollo/client";
import { UnkownStudentByMasterVoucherId } from "../../../queries/common";
import useToken from "../../../customhooks/useToken";
import useVoucherNumber, { VDetails } from "../hooks/useVoucherNumber";
import { Data } from "../../Print/Accounts/FeeReceipts/templates/Index";
import {
  formatter,
  GetNoOfDaysDelayed,
  NameOfTheDay,
  toInputStandardDate,
  toIsoDate,
  toStandardDate,
} from "../../../utils/UtilFunctions";
import { useParams } from "react-router-dom";
import FeeReceiptPrint from "../../Print/Accounts/FeeReceipts/Standard/FeeReceiptPrint";
import { AddReconciledStdReceipt } from "../queries/receipts/mutation/Index";
import { SwConfigQueryType } from "../../HR/enums/Enum.types";
import PerModuleConfiguration from "../../Configurations/PerModuleConfiguration";
import useServerDateandTime from "../../Library/customHooks/useServerDateandTime";
import useAcctLedgerData from "../hooks/useAcctLedgerData";
import MessageModal from "../../../pages/MessageModal";
import {
  GetAcctVoucherAnonymous,
  GetVoucherNumber,
} from "../queries/Vouchers/query";
import {
  AcctStudentType,
  InstitutionAcctConfigurationTypes,
} from "../common/Enum.types";
import { GetStdCompleteFeeDetailsByStudentID } from "../../../queries/students/list/byId";
import { GetAcctStdDeposits } from "../queries/FeeLedgers/query/Byid";
import LoadingModal from "../../../pages/LoadingModal";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import { GlobalPageConfigData } from "../../../Types/configtypes";
import MultipleReceipts, {
  IMultipleReceipts,
} from "../StudentDetails/FeeReceipt/modals/MultipleReceipts";
import {
  GetAcctVoucherAnonymousNodeDetails,
  UnkownStudentByMasterVoucherIdNodeData,
} from "../hooks/useAcctVoucherDetailsByDates";
import {
  GroupsWithSum,
  TotalInterface,
} from "../StudentDetails/FeeReceipt/Index";
import TextArea from "../../../stories/TextArea/TextArea";
import useAcctTableJson from "../json/useAcctTableJson";
import useInstLabels from "../../../customhooks/general/useInstLabels";
import useLoggedInUserDetails from "../hooks/useLoggedInUserDetails";
import useFineSlab from "../hooks/useFineSlab";
import WaiveOfModal from "../CustomComponents/Modal/WaiveOffModal";
import useInstDetails from "../../../customhooks/general/useInstDetails";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../styles/AutocompleteListStyles";
import { FineType } from "../Fines/Index";
interface Props {
  setAdjustmentsModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const UnkownStudentAdjustment = ({ setAdjustmentsModal }: Props) => {
  const { Accounts_Table } = useAcctTableJson();
  const { data: serverDateData, loading: serverDateLoading } =
    useServerDateandTime();
  const { InstId, studentId } = useParams();
  const { token } = useToken();
  const { format } = formatter;
  const [narration, setNarration] = useState("");
  const [studentModal, setStudentModal] = useState(false);
  const [admNo, setAdmNo] = useState("");
  const [imageString, setImageString] = useState("");
  const [depositSelectModal, setDepositSelectModal] = useState(false);
  const [multipleReceiptsModal, setMultipleRecepitsModal] = useState(false);
  const [rcptDate, setRcptDate] = useState("");
  const [totalFineAmt, setTotalFineAmt] = useState(0);
  const [waiveOffAmt, setWaiveOffAmt] = useState(0);
  const { finesData } = useFineSlab();
  const [receivedAmount, setReceivedAmount] = useState(0);
  const [waivedOff, setWaivedOff] = useState(false);
  const [UpdatedReceviedAmount, setUpdatedReceviedAmount] = useState(0);
  // const [editFine, setEditFine] = useState(false);
  const [inEditMode, setInEditMode] = useState({
    status: false,
    rowKey: 0,
  });
  const [tableData, setTableData] = useState<Data[]>([]);
  const [v_no, set_vo_no] = useState("");
  const [transaction_no, set_transaction_no] = useState("");

  const [v_date, set_v_Date] = useState("");
  const [printModal, SetPrintModal] = useState(false);
  const [printConfig, setPrintConfigModal] = useState(false);

  const [items, setItems] = useState<studentRecepit[]>([]);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [multiplereceipts, setMultipleReceipts] = useState<IMultipleReceipts[]>(
    []
  );

  const [enableWaiveOff, setEnableWaiveOff] = useState(false);

  const [oldReceiptData, setOldReceiptData] =
    useState<GetAcctVoucherAnonymousNodeDetails>();
  const [voucherKeys, setVoucherKeys] = useState<string[]>([]);
  const [generatedVoucherNumbers, setVoucherNumbers] = useState<VDetails[]>([]);
  const saveButtonRef = useRef<HTMLButtonElement>(null);
  const filteredCreditData =
    oldReceiptData?.voucher_master_details?.acct_voucher_details?.find(
      (data) => data.vo_cr_db === DebitOrCredit.CREDIT
    );

  const receivedButtonRef = useRef<HTMLButtonElement>(null);
  const remarksRef = useRef<HTMLInputElement>(null);
  const { user_details } = useLoggedInUserDetails();

  const { acctLedgers } = useAcctLedgerData(
    EMPTY_STRING,
    AcctLedgerQueryType.DEPOSIT_FEE_LDGRS,
    ROWS_PER_PAGE
  );
  const { InstDetails } = useInstDetails(1);

  const { studentData, studentFormData } = useStudentDatabyId();
  const { state, dispatch } = useContext(AppContext);
  const { studentAddmissionNumber } = useAcctStdAdmissionNumber(
    admNo,
    NumberOfAdmissionNumberToBeDisplayed,
    StudentAcctReportType.FEE_RECEIPT
  );
  const { StudentDemandDetails } = useStudentDemandDetails(
    DemandDetailsQueryType.DEMAND_DETAILS_GT_0,
    false,
    EMPTY_STRING
  );
  const { configData: configKeys } = useSwConfigData([
    InstitutionAcctConfigurationTypes.MULTIPLE_BILL_BOOKS,
    InstitutionAcctConfigurationTypes.STD_RECEIPT_EDIT_DATE,
    InstitutionAcctConfigurationTypes.ENABLE_FEE_FINE,
  ]);

  const [GetVoucherDetailsByVoucherId] = useLazyQuery<
    UnkownStudentByMasterVoucherIdNodeData,
    nodevars
  >(UnkownStudentByMasterVoucherId);

  const [GenerateStudentReceipts, { loading: GenerateStudentReceiptsLoading }] =
    useMutation(AddReconciledStdReceipt, {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    });

  const groups = items.reduce((acc: Record<string, studentRecepit[]>, item) => {
    const groupId: string = item.v_no || "";
    const group = acc[groupId] || [];

    // Loop through generatedVoucherNumbers to find a match
    generatedVoucherNumbers.forEach((voucher) => {
      if (
        item?.acct_ldgr_details?.voucher_book_details?.vo_book_key ===
        voucher?.vo_book_key
      ) {
        // Update the voucher number
        item.v_no = voucher.vo_number;
      }
    });

    return {
      ...acc,
      [groupId]: [...group, item],
    };
  }, {});
  const groupsWithSum: GroupsWithSum = Object.entries(groups).reduce(
    (acc, [groupId, group]) => ({
      ...acc,
      [groupId]: group.reduce(
        (total: TotalInterface, item) => ({
          sum: total.sum + (item.rcpt_amt || 0),
          vo_key: item.acct_ldgr_details.voucher_book_details?.vo_book_key!,
          vo_number: item.v_no!,
        }),
        { sum: 0, vo_key: "", vo_number: "" }
      ),
    }),
    {}
  );

  const waiveOffAmtMemoized =
    items.length > 0
      ? items.reduce((acc, item) => {
          acc += item.fine_amt;
          return acc;
        }, 0)
      : 0;

  useEffect(() => {
    if (items.length > 0 && state.studentId) {
      if (printModal === false) {
        setTotalFineAmt(waiveOffAmtMemoized);
        setWaiveOffAmt(waiveOffAmtMemoized);
      }
    }
  }, [waiveOffAmtMemoized, items, state.studentId]);

  const it = Object.entries(groups)
    .map(([groupId, items]) => ({
      groupId,
      abd: items.filter((item) => item.rcpt_amt > 0),
    }))
    .filter(({ abd }) => abd.length > 0);

  const filterDataByConfigKey = (data: GlobalPageConfigData[]) => {
    let isMultipleBillook = false;
    let editDate = false;
    let enableFeeFine = false;

    if (data) {
      data.forEach((item) => {
        switch (item.config_key) {
          case InstitutionAcctConfigurationTypes.MULTIPLE_BILL_BOOKS:
            isMultipleBillook = item.config_boolean_value;
            break;
          case InstitutionAcctConfigurationTypes.STD_RECEIPT_EDIT_DATE:
            editDate = item.config_boolean_value;
            break;
          case InstitutionAcctConfigurationTypes.ENABLE_FEE_FINE:
            enableFeeFine = item.config_boolean_value;
            break;

          default:
            break;
        }
      });
    }
    return {
      editDate,
      enableFeeFine,
      isMultipleBillook,
    };
  };

  const { isMultipleBillook, enableFeeFine, editDate } = filterDataByConfigKey(
    configKeys.data?.GetSwConfigVariables!
  );

  const { voucherNumber } = useVoucherNumber(
    isMultipleBillook
      ? (voucherKeys as VoucherBookKey[])!
      : VoucherBookKey.STUDENT_COMMAN_RECEIPT_BOOK
  );
  const updateItem = (
    dataArray: studentRecepit[],
    id: number,
    updatedAmount: number
  ) => {
    if (
      updatedAmount > dataArray.find((item) => item.id === id)?.fee_receivable!
    ) {
      alert("Please check you're receipt amount");
      return dataArray;
    }

    return dataArray.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          rcpt_amt: updatedAmount,
          fee_bal: item.fee_receivable - updatedAmount - item.fee_received,
        };
      }
      return item;
    });
  };

  const handleSplittingAmount = () => {
    if (items.length) {
      let initalAmount = receivedAmount;
      const rcvd_amt: studentRecepit[] = items.reduce(
        (result: studentRecepit[], element: studentRecepit) => {
          const rcpt_amt = Math.min(
            initalAmount,
            element.fee_receivable - element.fee_received
          );
          initalAmount -= rcpt_amt;
          result.push({
            ...element,
            rcpt_amt,
            fee_bal: element.fee_receivable - element.fee_received - rcpt_amt,
          });
          return result;
        },
        []
      );

      setItems(rcvd_amt);
      if (items[0].rcpt_amt && items[0].rcpt_amt > 0) {
        onEdit(items[0].id!, items[0].rcpt_amt);
      }
    }
  };

  const handleClose = () => {
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  const handleItems = (acctId: number) => {
    const voucherDetails = acctLedgers.data?.GetAcctLdgrs.edges.find(
      (d) => d.node.id === acctId
    )?.node.voucher_book_details;
    if (items.find((d) => d.newItem === YesNo.YES)) {
      setItems((prevItems) => {
        const updatedItems = prevItems.map((item) => {
          if (item.newItem === YesNo.YES) {
            return {
              ...item,
              acct_ldgr_details: {
                id: acctId,
                ldgr_cb: 0,
                ldgr_desc:
                  acctLedgers.responseType.find((d) => d.value === acctId)
                    ?.label ?? "",
                ldgr_ob: 0,
                ldgr_total_cr: 0,
                ldgr_total_db: 0,
                voucher_book_details: {
                  id: voucherDetails?.id!,
                  vo_book_desc: voucherDetails?.vo_book_desc!,
                  vo_book_key: voucherDetails?.vo_book_key!,
                  voucher_sub_type: "",
                },
              },
              acct_ldgr_id: acctId,
              id: acctId,
              fee_bal: 0,
              fee_concession: 0,
              fee_demand: 0,
              fee_ob: 0,
              fee_receivable: 0,
              fee_received: 0,
              fee_refunds: 0,
              fine_amt: 0,
              rcpt_amt: 0,
            };
          }
          return item;
        });
        return updatedItems;
      });
    } else {
      setItems((prevItems) => {
        const rcptAmt = Math.abs(receivedAmount - totals.totalacademicAmount);

        return [
          ...prevItems,
          {
            acct_ldgr_details: {
              id: acctId,
              ldgr_cb: 0,
              ldgr_desc:
                acctLedgers.responseType.find((d) => d.value === acctId)
                  ?.label ?? "",
              ldgr_ob: 0,
              ldgr_total_cr: 0,
              ldgr_total_db: 0,
              voucher_book_details: {
                id: voucherDetails?.id!,
                vo_book_desc: voucherDetails?.vo_book_desc!,
                vo_book_key: voucherDetails?.vo_book_key!,
                voucher_sub_type: "",
              },
            },
            acct_ldgr_id: acctId,
            fee_bal: 0,
            fee_concession: 0,
            fee_demand: 0,
            fee_ob: 0,
            fee_receivable: 0,
            fee_received: 0,
            fee_refunds: 0,
            fine_amt: 0,
            newItem: YesNo.YES,
            rcpt_amt: rcptAmt,
            id: acctId,
            initialBalance: 0,
            student_id: 0,
          },
        ];
      });
    }
  };

  const HandleEditItem = (sl_number: number) => {
    const updatedData = updateItem(items, sl_number, UpdatedReceviedAmount);
    if (updatedData) {
      setItems(updatedData);
    } else alert("Invalid ledger type");
  };

  const onEdit = (id: number, updatedRcptAmount: number) => {
    setInEditMode({
      status: true,
      rowKey: id,
    });
    setUpdatedReceviedAmount(updatedRcptAmount);
  };
  const handleClear = () => {
    setItems([]);
    dispatch({
      type: payloadTypes.SET_STUDENT_ID,
      payload: { studentId: 0 },
    });
    setNarration("");
    setTotalFineAmt(0);
    setReceivedAmount(0);
  };

  const handleStudentReceipts = () => {
    if (isMultipleBillook) {
      GenerateStudentReceipts({
        variables: {
          token,
          inst_id: InstId,
          user_details,
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          voucher_master_id: oldReceiptData?.voucher_master_details.id,
          input: it.map(({ groupId, abd }, index) => {
            const keys = Object.entries(groupsWithSum)?.find(
              ([id, num]: [string, TotalInterface]) => id === groupId
            )?.[1]?.vo_key!;
            const totalAmount = Object.entries(groupsWithSum).find(
              ([id, num]: [string, TotalInterface]) => id === groupId
            )?.[1].sum!;
            return {
              acct_voucher_master: {
                fin_yr: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
                v_type: ReceiptTypes.STUDENT_RECEIPT,
                v_no: groupId,
                v_date: toIsoDate(rcptDate),
                v_total_cr_amt: totalAmount,
                v_total_db_amt: totalAmount,
                v_reconciled: false,
                v_reconciled_date: toIsoDate(DEFAULT_TIME),
                v_std_receipt: true,
                v_std_non_demand_receipt: false,
                v_std_refund: false,
                v_std_enquiry: false,
                v_std_receipt_ob: false,
                enquiry_student_id: 0,
                v_std_deposit_adjusted: true,
                v_std_receipt_anonymous: false,
                v_std_refund_deposit: false,
                v_std_anonymous_deposit_adjusted: true,
                v_std_scholarship_deposit: false,
                v_book_type: keys,
                v_std_deposit: false,
                v_std_passout_receipt: false,
                v_std_demand_receipt: true,
                student_id: state.studentId,
                class_id: studentData.data?.nodes[0].class.id,
                semester_id: studentData.data?.nodes[0].semester.id,
                v_std_amt_receipt: totalAmount,
                v_std_amt_deposit: 0,
                v_std_amt_fine: 0,
                v_std_amt_total: totalAmount,
                v_std_amt_refunded: 0,
                v_std_amt_adjusted: 0,
                v_transcation_type: `Adjusted from ${filteredCreditData?.acct_ldgr.ldgr_desc}`,
                v_transcation_cash_or_bank: BankOrCash.ADJ,
                v_transcation_no: oldReceiptData?.v_transcation_no ?? "",
                v_transcation_date: toIsoDate(DEFAULT_TIME),
                v_transcation_narration: narration,
                // paid_party_id: "",
                // party_bill_no: "",
                party_bill_date: toIsoDate(DEFAULT_TIME),
                // party_name: "",
                annx_yesno: false,
                // annx_id: Math.random() * 1000,
                is_vouch_multi_entry: false,
                acct_ldgr_id_cr: items.filter((d) => d.rcpt_amt > 0)[0]
                  .acct_ldgr_details.id,
                acct_ldgr_id_db: filteredCreditData?.acct_ldgr.id,
              },
              acct_voucher_db: {
                vo_cr_db: DebitOrCredit.DEBIT,
                vo_sl_no: index + 1,
                vo_cr: 0,
                vo_db: Object.entries(groupsWithSum).find(
                  (sum) => sum[0] === groupId
                )?.[1].sum!,
                vo_cr_total: 0,
                vo_db_total: Object.entries(groupsWithSum).find(
                  (sum) => sum[0] === groupId
                )?.[1].sum!,

                acct_ldgr_id: filteredCreditData?.acct_ldgr.id,
              },
              acct_voucher_cr: abd.map((item, index) => ({
                vo_sl_no: index + 1,
                vo_cr_db: DebitOrCredit.CREDIT,
                vo_cr: item.rcpt_amt,
                vo_db: 0,
                vo_cr_total: Object.entries(groupsWithSum).find(
                  (sum) => sum[0] === groupId
                )?.[1].sum!,
                vo_db_total: 0,
                acct_ldgr_id: item.acct_ldgr_details.id,
              })),
            };
          }),
        },
        refetchQueries: [
          {
            query: GetAcctVoucherAnonymous,
            variables: {
              after: null,
              direction: Direction.ASC,
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              first: ROWS_PER_PAGE,
              sortBy: "CREATED_AT",
              token,
              vTranscationNo: "",
              inst_id: InstId!,
              anonymous_std_type: AcctStudentType.AnonymousRecolied,
            },
          },
          {
            query: GetAcctStdDeposits,
            variables: {
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              query_type: DepositLedgerQueryType.DEPOSIT_GT_0_BY_STD_ID,
              student_id: state.studentId,
              token,
            },
          },
          {
            query: GetStdCompleteFeeDetailsByStudentID,
            variables: {
              token,
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              inst_id: InstId!,
              student_id: state.studentId,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            message: `Successfully generated fee receipt for student ${studentData.data?.nodes[0].first_name}`,
            flag: true,
            operation: Operation.CREATE,
          });
          const { id } = data.AddReconciledStdReceipt[0];
          dispatch({
            type: payloadTypes.SET_RECEPIT_ID,
            payload: { receiptId: id },
          });

          setMultipleReceipts(data.AddReconciledStdReceipt);
          setMultipleRecepitsModal(!multipleReceiptsModal);
        }
      });
    } else {
      GenerateStudentReceipts({
        variables: {
          token,
          inst_id: InstId,
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          voucher_master_id: oldReceiptData?.voucher_master_details.id,
          user_details,
          input: {
            acct_voucher_master: {
              fin_yr: state.ActiveFinYr ? state.ActiveFinYr.fin_yr : "",
              v_type: ReceiptTypes.STUDENT_RECEIPT,
              v_no: voucherNumber.data?.GetVoucherNumber.vo_number!,
              v_date: toIsoDate(rcptDate),
              v_total_cr_amt: totals.totalacademicAmount,
              v_total_db_amt: totals.totalacademicAmount,
              v_reconciled: false,
              v_reconciled_date: toIsoDate(DEFAULT_TIME),
              v_std_receipt: true,
              v_std_non_demand_receipt: false,
              v_std_refund: false,
              v_std_enquiry: false,
              v_std_receipt_ob: false,
              enquiry_student_id: 0,
              v_std_deposit_adjusted: true,
              v_book_type: VoucherBookKey.STUDENT_COMMAN_RECEIPT_BOOK,
              v_std_deposit: false,
              v_std_passout_receipt: false,
              v_std_demand_receipt: true,
              v_std_receipt_anonymous: false,
              v_std_anonymous_deposit_adjusted: true,
              v_std_scholarship_deposit: false,

              student_id: state.studentId,
              v_std_refund_deposit: false,
              class_id: studentData.data?.nodes[0].class.id,
              semester_id: studentData.data?.nodes[0].semester.id,
              v_std_amt_receipt: totals.totalacademicAmount,
              v_std_amt_deposit: 0,
              v_std_amt_fine: 0,
              v_std_amt_total: totals.totalacademicAmount,
              v_std_amt_refunded: 0,
              v_std_amt_adjusted: 0,
              v_transcation_type: `Adjusted from ${filteredCreditData?.acct_ldgr.ldgr_desc}`,
              v_transcation_cash_or_bank: BankOrCash.ADJ,
              v_transcation_no: oldReceiptData?.v_transcation_no ?? "",
              v_transcation_date: toIsoDate(DEFAULT_TIME),
              v_transcation_narration: narration,
              // paid_party_id: "",
              // party_bill_no: "",
              party_bill_date: toIsoDate(DEFAULT_TIME),
              // party_name: "",
              annx_yesno: false,
              // annx_id: Math.random() * 1000,
              is_vouch_multi_entry: false,
              acct_ldgr_id_cr: items.filter((d) => d.rcpt_amt > 0)[0]
                .acct_ldgr_details.id,
              acct_ldgr_id_db: filteredCreditData?.acct_ldgr.id,
            },
            acct_voucher_db: {
              vo_cr_db: DebitOrCredit.DEBIT,
              vo_sl_no: 1,
              vo_cr: 0,
              vo_db: totals.totalacademicAmount,
              vo_cr_total: 0,
              vo_db_total: totals.totalacademicAmount,
              acct_ldgr_id: filteredCreditData?.acct_ldgr.id,
            },
            acct_voucher_cr: items
              .filter((item) => item.rcpt_amt > 0)
              .map((item, index) => ({
                vo_sl_no: index + 1,
                vo_cr_db: DebitOrCredit.CREDIT,
                vo_cr: item.rcpt_amt,
                vo_db: 0,
                vo_cr_total: totals.totalacademicAmount,
                vo_db_total: 0,
                acct_ldgr_id: item.acct_ldgr_id,
              })),
          },
        },
        refetchQueries: [
          {
            query: GetAcctVoucherAnonymous,
            variables: {
              after: null,
              direction: Direction.ASC,
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              first: ROWS_PER_PAGE,
              sortBy: "CREATED_AT",
              token,
              vTranscationNo: "",
              inst_id: InstId!,
              anonymous_std_type: AcctStudentType.AnonymousRecolied,
            },
          },
          {
            query: GetAcctStdDeposits,
            variables: {
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              query_type: DepositLedgerQueryType.DEPOSIT_GT_0_BY_STD_ID,
              student_id: state.studentId,
              token,
            },
          },
          {
            query: GetStdCompleteFeeDetailsByStudentID,
            variables: {
              token,
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              inst_id: InstId!,
              student_id: state.studentId,
            },
          },
          {
            query: GetVoucherNumber,
            variables: {
              vo_book_key: VoucherBookKey.STUDENT_COMMAN_RECEIPT_BOOK,
              token,
              inst_id: InstId!,
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          const { v_no, v_date, id } = data.AddReconciledStdReceipt[0];
          dispatch({
            type: payloadTypes.SET_RECEPIT_ID,
            payload: { receiptId: id },
          });
          setTableData(
            items
              .filter((item) => item.rcpt_amt > 0)
              .map((item) => ({
                particular: item.acct_ldgr_details.ldgr_desc,
                amount: item.rcpt_amt,
              }))
          );
          set_v_Date(v_date);
          set_vo_no(v_no);
          set_transaction_no(transaction_no);
          !printModal ? SetPrintModal(!printModal) : handleClear();
          setMessage({
            message: `Successfully generated fee receipt for student ${studentData.data?.nodes[0].first_name}`,
            flag: true,
            operation: Operation.CREATE,
          });
        }
      });
    }
  };

  const getFine = (noOfDaysDelayed: number, totalAmount?: number) => {
    if (
      finesData &&
      finesData.data &&
      finesData.data.GetAcctFineSlabByInstId.length === 0
    ) {
      return 0;
    }
    const { GetAcctFineSlabByInstId } = finesData.data || {};
    if (!GetAcctFineSlabByInstId) return 0;

    const { acct_fine_type, roi, days, fine_amt } = GetAcctFineSlabByInstId[0];

    const rateOfInterest = parseFloat(roi) || 0;
    const fineAmount = parseFloat(fine_amt.toString()) || 0;

    if (noOfDaysDelayed >= days) {
      if (acct_fine_type === FineType.PERCENTAGE) {
        const periodDays = days;
        const periods = Math.floor(noOfDaysDelayed / periodDays);
        const remainingDays = noOfDaysDelayed % periodDays;

        const fineForPeriods = (rateOfInterest / 100) * totalAmount! * periods;

        const fineForRemainingDays =
          ((rateOfInterest / 100) * totalAmount! * remainingDays) / periodDays;

        return fineForPeriods + fineForRemainingDays;
      } else {
        return fineAmount;
      }
    }

    return 0;
  };
  const mapStudentDemandDetails = (
    data: studentDemandDetailsData
  ): { studentFeeData: studentRecepit[] } => {
    const studentFeeData: studentRecepit[] =
      data.GetAcctStdDemandDetails.filter((item) => item !== null).map(
        (item) => ({
          fee_ob: item.fee_ob,
          fee_demand: item.fee_demand,
          fee_concession: item.fee_concession,
          fee_receivable: item.fee_receivable,
          fee_received: item.fee_received,
          fee_refunds: item.fee_refunds,
          fee_bal: item.fee_bal,
          v_no: "",
          fee_due_date: item.fee_due_date,
          acct_ldgr_id: item.acct_ldgr_id,
          id: item.id,
          cr_db: DebitOrCredit.CREDIT,
          recevied_amount: 0,
          newItem: YesNo.NO,
          legederType: LedgerType.GENERAL,
          rcpt_amt: 0,
          acct_ldgr_details: item?.acct_ldgr_details,
          student_id: item.student_id,
          fine_amt:
            new Date(item?.fee_due_date!).getTime() <
            new Date(rcptDate).getTime()!
              ? getFine(GetNoOfDaysDelayed(new Date(item.fee_due_date!)))! *
                GetNoOfDaysDelayed(new Date(item.fee_due_date!))
              : 0,
          initialBalance: item.fee_bal,
        })
      );

    return { studentFeeData };
  };
  const studentDemandDetailsmemoizedData = useMemo(() => {
    if (StudentDemandDetails.data && !StudentDemandDetails.loading) {
      const { studentFeeData } = mapStudentDemandDetails(
        StudentDemandDetails.data
      );
      return studentFeeData;
    }
    return items;
    // eslint-disable-next-line
  }, [
    StudentDemandDetails.data,
    StudentDemandDetails.loading,
    studentData.data,
  ]);

  const totals = items.reduce(
    (acc, item) => {
      acc.totalOutStandingBalance += item.fee_ob;
      acc.totalDemandAmount += item.fee_demand;
      acc.totalconcession += item.fee_concession;
      acc.totalbalance += item.fee_bal;
      acc.totalPayable += item.fee_receivable;
      acc.totalpaidAmount += item.fee_received;
      acc.totalacademicAmount += item.rcpt_amt!;

      return acc;
    },
    {
      totalOutStandingBalance: 0,
      totalDemandAmount: 0,
      totalconcession: 0,
      totalbalance: 0,
      totalPayable: 0,
      totalpaidAmount: 0,
      totalacademicAmount: 0,
    }
  );

  useEffect(() => {
    setItems(studentDemandDetailsmemoizedData);

    if (studentData.data) {
      if (
        studentData.data?.nodes[0].std_profile_filename !== EMPTY_STRING &&
        InstDetails.data
      ) {
        const studentProfiePicUrl = `${
          InstDetails.data?.nodes[0]?.inst_name
        }/students/${
          state.studentId ? state.studentId : studentId
        }/std_profile_pic/std_profile_pic`;
        getDownloadUrl(studentProfiePicUrl, false, setImageString);
      }
    } // eslint-disable-next-line
  }, [
    studentDemandDetailsmemoizedData,
    studentData.data,
    state.studentId,
    InstDetails.data,
  ]);
  useEffect(() => {
    if (state.unknownReceiptId) {
      GetVoucherDetailsByVoucherId({
        variables: { token, ids: [state.unknownReceiptId] },
      }).then(({ data }) => {
        if (data) {
          setOldReceiptData(data.nodes[0]);
          setReceivedAmount(data.nodes[0].bal_amt);
        }
      });
    }
  }, [state.unknownReceiptId, GetVoucherDetailsByVoucherId, token]);

  const voucherNumbers = useMemo(() => {
    if (
      isMultipleBillook &&
      voucherNumber.data &&
      !voucherNumber.loading &&
      state.studentId
    ) {
      return isMultipleBillook
        ? voucherNumber.multipleVoucherNumber
        : [voucherNumber.data.GetVoucherNumber.vo_number];
    }
    return [];
  }, [
    isMultipleBillook,
    voucherNumber.data,
    voucherNumber.loading,
    voucherNumber.multipleVoucherNumber,
    state.studentId,
  ]);
  const voucherKeysMemo = useMemo(() => {
    if (
      StudentDemandDetails.data &&
      !StudentDemandDetails.loading &&
      state.studentId
    ) {
      const d = items.find((d) => d.newItem === YesNo.YES)?.acct_ldgr_details
        .voucher_book_details?.vo_book_key;
      if (d) {
        return [
          ...new Set([
            d,
            ...StudentDemandDetails.data.GetAcctStdDemandDetails.filter(
              (d) => d !== null
            ).map((f) => f.acct_ldgr_details.voucher_book_details?.vo_book_key),
          ]),
        ] as string[];
      }
      return [
        ...new Set(
          StudentDemandDetails.data.GetAcctStdDemandDetails.filter(
            (item) => item !== null
          ).map(
            (demand) =>
              demand.acct_ldgr_details.voucher_book_details?.vo_book_key
          )
        ),
      ] as string[];
    }
    return [];
  }, [
    StudentDemandDetails.data,
    StudentDemandDetails.loading,
    state.studentId,
    items,
  ]);

  useEffect(() => {
    setVoucherKeys(voucherKeysMemo);
    setVoucherNumbers(voucherNumbers as VDetails[]);
  }, [voucherKeysMemo, voucherNumbers]);

  useEffect(() => {
    if (serverDateData && !serverDateLoading) {
      setRcptDate(serverDateData.GetServerDateAndTime);
    }
  }, [serverDateData, serverDateLoading]);

  const { branchLabel, classLabel, categoryLabel } = useInstLabels();
  const handleCloseWaiveOffModal = () => {
    setWaiveOffAmt(0);
    setEnableWaiveOff(!enableWaiveOff);
  };
  return (
    <>
      <Title>Adjust Unreconciled Receipt</Title>
      <div className="unknown-bank-trans__advance-fee">
        <div className="unknown-bank-trans__advance-fee--select row g-0">
          <div className="col">
            <div className="unknown-bank-trans__advance-fee--select--flex">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={studentAddmissionNumber}
                value={
                  state.studentId
                    ? studentAddmissionNumber.find(
                        ({ value }) => value === state.studentId
                      )!
                    : null
                }
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === Keys.ENTER) {
                    if (state.studentId) {
                      receivedButtonRef.current?.focus();
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    dispatch({
                      type: payloadTypes.SET_STUDENT_ID,
                      payload: {
                        studentId: 0,
                      },
                    });
                    setAdmNo("");
                  }
                }}
                openOnFocus
                onChange={(e, newValue) => {
                  if (newValue) {
                    dispatch({
                      type: payloadTypes.SET_STUDENT_ID,
                      payload: {
                        studentId: (newValue as responseType)?.value,
                      },
                    });
                  } else {
                    handleClear();
                  }
                }}
                autoHighlight
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className={labelClasses.formControlRoot}
                    onChange={(e) => setAdmNo(e.target.value)}
                    label="Admission Number"
                    autoFocus
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    fullWidth
                  />
                )}
              />
              <img
                src={Edit}
                alt=""
                className="data-fetch-icon"
                onClick={() => setStudentModal(!studentModal)}
              />
            </div>
            <TextField
              className="unknown-bank-trans__advance-fee--select--textfield"
              label="Reg No."
              value={studentFormData.reg_number}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              disabled
            />
            <TextField
              className="unknown-bank-trans__advance-fee--select--textfield"
              label={branchLabel}
              value={studentFormData.branch}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              disabled
            />
          </div>
          <div className="col">
            <TextField
              className="unknown-bank-trans__advance-fee--select--textfield"
              label="Name"
              value={studentFormData.std_name}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              disabled
            />
            <TextField
              className="unknown-bank-trans__advance-fee--select--textfield"
              label={classLabel}
              value={studentFormData.class}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              disabled
            />
          </div>
          <div className="col-5">
            <div className="unknown-bank-trans__advance-fee--select--flex">
              <TextField
                className="unknown-bank-trans__advance-fee--select--textfield--date"
                label="Date"
                type="date"
                disabled={editDate ? false : true}
                value={toInputStandardDate(rcptDate)}
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                onChange={(e) => setRcptDate(e.target.value)}
              />
              <Label variant="present-day">{NameOfTheDay(rcptDate)}</Label>
            </div>
            <TextField
              className="unknown-bank-trans__advance-fee--select--textfield"
              label={categoryLabel}
              value={studentFormData.category}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              disabled
            />
            <div className="row g-0 unknown-bank-trans__advance-fee--select--button">
              <div className="col">
                <Input disabled value={receivedAmount} />
              </div>
              <div className="col">
                <Button
                  buttonref={receivedButtonRef}
                  mode="adjustment-receipts"
                  onClick={handleSplittingAmount}
                />
              </div>
            </div>
          </div>
          <div className="col-1 unknown-bank-trans__advance-fee--select--image">
            <img src={imageString ? imageString : Avatar} alt="" />
          </div>
        </div>
        <div className="unknown-bank-trans__advance-fee--tableblock">
          <TableContainer className="unknown-bank-trans__advance-fee--table g-0">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Accounts_Table.Receipts.StudentFeeReceipt.Table_Headers.map(
                    (th: AccountsTableHeaderProps, index: React.Key) => {
                      return (
                        <TableCell key={index} className={th.className}>
                          {th.labelName}
                        </TableCell>
                      );
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {isMultipleBillook
                  ? Object.entries(groups).map(([groupId, groupedItems]) => {
                      return groupedItems.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            {index === 0 && (
                              <TableRow>
                                <TableCell colSpan={8}>
                                  <b>
                                    {
                                      item.acct_ldgr_details
                                        .voucher_book_details?.vo_book_desc
                                    }
                                  </b>
                                </TableCell>
                              </TableRow>
                            )}
                            <TableRow
                              className={
                                item.legederType === LedgerType.DEPOSIT
                                  ? "deposit"
                                  : ""
                              }>
                              <TableCell
                                className="student-fee-receipt__table--slNo"
                                align="center">
                                {index + 1}
                              </TableCell>
                              <TableCell className="student-fee-receipt__table--desc">
                                {item.acct_ldgr_details?.ldgr_desc}
                              </TableCell>
                              <TableCell>
                                {item.fee_due_date
                                  ? toStandardDate(item.fee_due_date)
                                  : EMPTY_STRING}
                              </TableCell>

                              <TableCell
                                id="td-right"
                                className="student-fee-receipt__table--amount">
                                {format(item.fee_ob)}
                              </TableCell>
                              <TableCell
                                id="td-right"
                                className="student-fee-receipt__table--amount">
                                {format(item.fee_demand)}
                              </TableCell>
                              <TableCell
                                id="td-right"
                                className="student-fee-receipt__table--amount">
                                {format(item.fee_concession)}
                              </TableCell>
                              <TableCell
                                id="td-right"
                                className="student-fee-receipt__table--amount">
                                {format(item.fee_receivable)}
                              </TableCell>
                              <TableCell
                                id="td-right"
                                className="student-fee-receipt__table--amount">
                                {format(item.fee_received)}
                              </TableCell>
                              <TableCell
                                className="student-fee-receipt__table--amount editCell"
                                onClick={() => {
                                  if (receivedAmount > 0) {
                                    if (item.rcpt_amt > 0) {
                                      onEdit(item.id!, item.rcpt_amt);
                                    }
                                    if (item.rcpt_amt === 0) {
                                      onEdit(item.id!, 0);
                                    }
                                  }
                                }}
                                id="td-right">
                                {inEditMode.status &&
                                inEditMode.rowKey === item.id ? (
                                  <input
                                    autoFocus
                                    disabled={item.newItem === YesNo.YES}
                                    type="number"
                                    value={UpdatedReceviedAmount}
                                    onFocus={(e) => e.target.select()}
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) =>
                                      setUpdatedReceviedAmount(
                                        Number(e.target.value)!
                                      )
                                    }
                                    onKeyDown={(event: React.KeyboardEvent) => {
                                      if (event.key === Keys.ENTER) {
                                        const currentGroupIndex = Object.keys(
                                          groups
                                        ).findIndex(
                                          (groupId) => groupId === item?.v_no
                                        );
                                        const currentGroup =
                                          groups[item?.v_no!];
                                        const currentItemIndex =
                                          currentGroup.findIndex(
                                            (groupItem) =>
                                              groupItem.id === item.id
                                          );

                                        if (
                                          currentItemIndex <
                                          currentGroup.length - 1
                                        ) {
                                          // If there is a next item in the current group, edit it
                                          const nextItem =
                                            currentGroup[currentItemIndex + 1];
                                          setUpdatedReceviedAmount(
                                            nextItem.rcpt_amt
                                          );
                                          onEdit(
                                            nextItem.id!,
                                            nextItem.rcpt_amt
                                          );
                                          HandleEditItem(item.id!);
                                        } else if (
                                          currentGroupIndex <
                                          Object.keys(groups).length - 1
                                        ) {
                                          // If there is a next group, edit the first item in the next group
                                          const nextGroupId =
                                            Object.keys(groups)[
                                              currentGroupIndex + 1
                                            ];
                                          const nextGroup = groups[nextGroupId];
                                          const nextItem = nextGroup[0];
                                          setUpdatedReceviedAmount(
                                            nextItem.rcpt_amt
                                          );
                                          onEdit(
                                            nextItem.id!,
                                            nextItem.rcpt_amt
                                          );
                                          HandleEditItem(item.id!);
                                        } else {
                                          // If there is no next item or group, exit edit mode
                                          onEdit(item.id!, item.rcpt_amt);
                                          HandleEditItem(item.id!);
                                          setInEditMode({
                                            rowKey: -805,
                                            status: false,
                                          });
                                        }
                                      }
                                    }}
                                  />
                                ) : (
                                  format(item.rcpt_amt)
                                )}
                              </TableCell>
                              <TableCell
                                id="td-right"
                                className="balance-amount student-fee-receipt__table--amount">
                                {format(item.fee_bal)}
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        );
                      });
                    })
                  : items.map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          <TableRow
                            className={
                              item.legederType === LedgerType.DEPOSIT
                                ? "deposit"
                                : ""
                            }>
                            <TableCell
                              className="student-fee-receipt__table--slNo"
                              align="center">
                              {index + 1}
                            </TableCell>
                            <TableCell className="student-fee-receipt__table--desc">
                              {item.acct_ldgr_details?.ldgr_desc}
                            </TableCell>
                            <TableCell>
                              {item.fee_due_date
                                ? toStandardDate(item.fee_due_date)
                                : EMPTY_STRING}
                            </TableCell>
                            <TableCell
                              id="td-right"
                              className="student-fee-receipt__table--amount">
                              {format(item.fee_ob)}
                            </TableCell>
                            <TableCell
                              id="td-right"
                              className="student-fee-receipt__table--amount">
                              {format(item.fee_demand)}
                            </TableCell>
                            <TableCell
                              id="td-right"
                              className="student-fee-receipt__table--amount">
                              {format(item.fee_concession)}
                            </TableCell>
                            <TableCell
                              id="td-right"
                              className="student-fee-receipt__table--amount">
                              {format(item.fee_receivable)}
                            </TableCell>
                            <TableCell
                              id="td-right"
                              className="student-fee-receipt__table--amount">
                              {format(item.fee_received)}
                            </TableCell>
                            <TableCell
                              className="student-fee-receipt__table--amount editCell"
                              onClick={() => {
                                if (receivedAmount > 0) {
                                  if (item.rcpt_amt > 0) {
                                    onEdit(item.id!, item.rcpt_amt);
                                  }
                                  if (item.rcpt_amt === 0) {
                                    onEdit(item.id!, 0);
                                  }
                                }
                              }}
                              id="td-right">
                              {inEditMode.status &&
                              inEditMode.rowKey === item.id ? (
                                <input
                                  disabled={item.newItem === YesNo.YES}
                                  autoFocus
                                  type="number"
                                  value={UpdatedReceviedAmount}
                                  onFocus={(e) => e.target.select()}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) =>
                                    setUpdatedReceviedAmount(
                                      Number(e.target.value)!
                                    )
                                  }
                                  onKeyDown={(event: React.KeyboardEvent) => {
                                    if (event.key === Keys.ENTER) {
                                      if (
                                        index === items.length - 1 &&
                                        remarksRef.current
                                      ) {
                                        remarksRef.current.focus();
                                      }
                                      if (
                                        items[index + 1] &&
                                        items[index + 1].id
                                      ) {
                                        setUpdatedReceviedAmount(
                                          items[index + 1].rcpt_amt
                                        );
                                        onEdit(
                                          items[index + 1].id!,
                                          items[index + 1].rcpt_amt
                                        );
                                        HandleEditItem(item.id!);
                                      } else {
                                        onEdit(item.id!, item.rcpt_amt);
                                        HandleEditItem(item.id!);

                                        setInEditMode({
                                          rowKey: -805,
                                          status: false,
                                        });
                                      }
                                    }
                                  }}
                                />
                              ) : (
                                format(item.rcpt_amt)
                              )}
                            </TableCell>
                            <TableCell
                              id="td-right"
                              className="balance-amount student-fee-receipt__table--amount">
                              {format(item.fee_bal)}
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
              </TableBody>

              <TableFooter>
                <TableRow>
                  <TableCell colSpan={8} className="total">
                    Academic Fee Total :
                  </TableCell>
                  <TableCell
                    id="td-right"
                    className="totalcount student-fee-receipt__table__borderbottom">
                    {format(totals.totalacademicAmount)}
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
                {enableFeeFine && (
                  <TableRow>
                    <TableCell colSpan={8} className="total">
                      Fine :
                    </TableCell>
                    <TableCell
                      id="td-right"
                      className="balance-count student-fee-receipt__table__borderbottom">
                      {format(enableFeeFine ? totalFineAmt : 0)}
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                )}

                <TableRow>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={3} className="total">
                    Total :
                  </TableCell>
                  <TableCell id="td-right" className="totalcount">
                    {format(totals.totalOutStandingBalance)}
                  </TableCell>
                  <TableCell id="td-right" className="totalcount">
                    {format(totals.totalDemandAmount)}
                  </TableCell>
                  <TableCell id="td-right" className="totalcount">
                    {format(totals.totalconcession)}
                  </TableCell>
                  <TableCell id="td-right" className="totalcount">
                    {format(totals.totalPayable)}
                  </TableCell>
                  <TableCell id="td-right" className="totalcount">
                    {format(totals.totalpaidAmount)}
                  </TableCell>

                  <TableCell id="td-right" className="totalcount">
                    {format(totals.totalacademicAmount + totalFineAmt)}
                  </TableCell>
                  <TableCell id="td-right" className="balance-count">
                    {format(totals.totalbalance)}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
        <div className="unknown-bank-trans__advance-fee--receiptblock row g-0">
          <div className="col unknown-bank-trans__advance-fee--receiptblock--frame">
            <div className="label-grid">
              <Label>Receipt No.</Label>
              <Input
                disabled
                value={
                  (generatedVoucherNumbers
                    .map((number) => number?.vo_number)
                    .join(", ") ||
                    voucherNumber.data?.GetVoucherNumber.vo_number) ??
                  EMPTY_STRING
                }
              />
              <Label>Adjusted From</Label>
              <Input
                disabled
                value={`${filteredCreditData?.acct_ldgr.ldgr_desc} ( ${oldReceiptData?.voucher_master_details?.v_no} ${oldReceiptData?.v_transcation_no} ) `}
              />
            </div>
          </div>
          <div className="col unknown-bank-trans__advance-fee--receiptblock--frame">
            <div className="label-grid">
              <Label>Remarks</Label>

              <TextArea
                rows={3}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === Keys.ENTER && !e.shiftKey) {
                    e.preventDefault();
                    saveButtonRef.current?.focus();
                  }
                }}
                textAreaRef={remarksRef!}
                value={narration}
                onChange={(e) => setNarration(e.target.value)}
              />
            </div>
          </div>
        </div>
        <Button
          mode="save"
          buttonref={saveButtonRef!}
          onClick={handleStudentReceipts}
          disabled={!totals.totalacademicAmount}
        />
        {enableFeeFine && (
          <Button
            mode="waive-off"
            onClick={() => {
              setEnableWaiveOff(!enableWaiveOff);
            }}
            disabled={
              !state.studentId ||
              totalFineAmt === 0 ||
              totalFineAmt < waiveOffAmtMemoized
            }
          />
        )}
        <Button mode="clear" onClick={() => handleClear()} />
        <Button
          mode="transfer"
          disabled={totals.totalacademicAmount === receivedAmount}
          onClick={() => setDepositSelectModal(!depositSelectModal)}
        />
        <Button mode="cancel" onClick={() => setAdjustmentsModal(false)} />
      </div>
      {/* studentmodal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={studentModal}
        style={StudentModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentList
              pageType={PageFor.MODAL}
              studentListFor={StudentListFor.ACCOUNTS}
              setStudentModal={setStudentModal}
              queryType={StudentAcctReportType.DEMAND_RAISED}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStudentModal(!studentModal)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={printModal}
        style={PrintModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <FeeReceiptPrint
              tableData={
                (waivedOff ? totalFineAmt - waiveOffAmt : totalFineAmt) > 0
                  ? [
                      ...tableData,
                      {
                        amount: waivedOff
                          ? totalFineAmt - waiveOffAmt
                          : totalFineAmt,
                        particular: "Fee Fine",
                      },
                    ]
                  : [...tableData]
              }
              v_date={v_date}
              v_no={v_no}
              transaction_no={transaction_no}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => {
                SetPrintModal(!printModal);
                handleClear();
              }}
            />
            <img
              src={Settings}
              alt="/"
              id="settings-icon"
              onClick={() => setPrintConfigModal(!printConfig)}
            />
          </div>
        </div>
      </Modal>
      {/* print configuration modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={printConfig}
        style={PrintConfigModalStyles}
        ariaHideApp={false}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <PerModuleConfiguration
              config_query_type={SwConfigQueryType.INST_BY_MODULE}
              str_value={ModuleName.RECEIPT_PRINT}
              int_value={0}
              setModalFlag={setPrintConfigModal}
              pageType={PageFor.MODAL}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setPrintConfigModal(!printConfig)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={depositSelectModal}
        style={DepositModalCustomStyles}
        ariaHideApp={false}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Title variant="subtitle1"> Student Fee Advance List</Title>
            <hr className="dashed" />
            <br />
            <div>
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={acctLedgers.responseType}
                openOnFocus
                onChange={(e, newValue) => {
                  if (newValue) {
                    handleItems((newValue as responseType).value);
                    setDepositSelectModal(false);
                  }
                }}
                freeSolo
                autoHighlight
                renderInput={(params) => (
                  <TextField
                    {...params}
                    classes={{ root: labelClasses.formControlRoot }}
                    label="Deposit Ledger"
                    autoFocus
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    fullWidth
                  />
                )}
              />
            </div>
            <Label variant="LabelPrimary">
              Select the fee ledger through which adjustment of fees should be
              done.
            </Label>
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setDepositSelectModal(!depositSelectModal)}
            />
          </div>
        </div>
      </Modal>
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <WaiveOfModal
        due_date={
          toStandardDate(
            studentData.data?.nodes[0].acct_std_demand.new_due_date!
          ) === toStandardDate(DEFAULT_TIME)
            ? "-"
            : toStandardDate(
                studentData.data?.nodes[0].acct_std_demand.new_due_date!
              )
        }
        enableWaiveOff={enableWaiveOff}
        handleCloseModal={handleCloseWaiveOffModal}
        items={items}
        setTotalFineAmt={setTotalFineAmt}
        setWaiveOffAmt={setWaiveOffAmt}
        totalFineAmt={totalFineAmt}
        waiveOffAmt={waiveOffAmt}
        waiveOffAmtMemoized={waiveOffAmtMemoized}
        setWaiveOff={setWaivedOff}
      />
      <LoadingModal flag={GenerateStudentReceiptsLoading} />

      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={multipleReceiptsModal}
        style={DeleteLedgerModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <MultipleReceipts items={multiplereceipts} type={NonDemand.ROLE} />
            <Button
              mode="cancel"
              onClick={() => {
                setMultipleRecepitsModal(!multipleReceiptsModal);
                handleClear();
              }}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => {
                setMultipleRecepitsModal(!multipleReceiptsModal);
                handleClear();
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UnkownStudentAdjustment;
