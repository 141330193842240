import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import useDisplayConfigIcon from "../../../../customhooks/useDisplayConfigIcon";
import { Button } from "../../../../stories/Button/Button";
import { Label } from "../../../../stories/Label/Label";
import { Title } from "../../../../stories/Title/Title";
import { TableHeaderProps } from "../../../../Types/Tables";
import { StudentFeeReceiptTitleProps } from "../../../../Types/Titles";
import {
  PageNumbers,
  PageFor,
  DebitOrCredit,
  ReceiptTypes,
  StudentListFor,
  StudentAcctReportType,
  Operation,
  ModuleName,
  Direction,
  SortBy,
} from "../../../../utils/Enum.types";
import Home from "../../Home/Index";
import {
  ConfigurationsModalStyles,
  PrintConfigModalStyles,
  PrintModalStyles,
  StudentModalStyles,
} from "../../../../styles/ModalStyles";
import ConfigurationSettings from "../../../Master/configurations/general/Index";
import StudentDemandReceipt from "./StudentDemandReceipt";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  nodevars,
  studentAcademicYearFormDetails,
  studentRecepit,
  VouchernodeData,
} from "../../../../Types/Accounting";
import {
  DateRange,
  formatter,
  NameOfTheDay,
  toggleFullSceen,
  toInputStandardDate,
  toIsoDate,
  toStandardCase,
} from "../../../../utils/UtilFunctions";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../../context/context";
import {
  GetAcctTransferVoucherDetailsByInstId,
  GetAcctVouchers,
} from "../../queries/Vouchers/query";
import { TransferAcctVoucherMasterAndDetails } from "../../queries/receipts/mutation/Index";
import dayjs from "dayjs";
import {
  DEFAULT_TIME,
  EMPTY_STRING,
  NumberOfAdmissionNumberToBeDisplayed,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";
import {
  GetStudentDetails,
  StudentDetailsByMasterVoucherId,
} from "../../../../queries/common";
import ViewInterchangeDetails from "./ViewInterchangeDetails";
import Close from "../../../../images/Close.svg";
import Settings from "../../../../images/Settings.svg";
import Enlarge from "../../../../images/Enlarge.svg";
import Edit from "../../../../images/Edit.svg";
import Avatar from "../../../../images/Avatar.svg";

import LoadingModal from "../../../../pages/LoadingModal";
import useStudentDatabyId, {
  StudentData,
  StudentDetailsvars,
} from "../../../../customhooks/useStudentDatabyId";
import useToken from "../../../../customhooks/useToken";
import { Keys } from "../../../../utils/Enum.keys";
import Input from "../../../../stories/Input/Input";
import useStudentDemandDetails from "../../hooks/useStudentDemandDetails";
import MessageModal from "../../../../pages/MessageModal";
import { msgType, responseType } from "../../../../utils/Form.types";
import { getDownloadUrl } from "../../../../utils/DownloadFile";
import { payloadTypes } from "../../../../context/reducer";
import StudentList from "../../common/StudentList";
import useAcctStdAdmissionNumber from "../../hooks/useAcctStdAdmissionNumber";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import { DemandDetailsQueryType, LedgerType } from "../../common/QueryTypes";
import { Data } from "../../../Print/Accounts/FeeReceipts/templates/Index";
import FeeReceiptPrint from "../../../Print/Accounts/FeeReceipts/Standard/FeeReceiptPrint";
import Configurations from "../../../Configurations/PerModuleConfiguration";
import { SwConfigQueryType } from "../../../HR/enums/Enum.types";
import { VoucherQueryTypes } from "../../common/Enum.types";
import { voucherMasterDetails } from "../../hooks/useAcctVoucherDetailsByDates";
import TextArea from "../../../../stories/TextArea/TextArea";
import useAcctTableJson from "../../json/useAcctTableJson";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import useLoggedInUserDetails from "../../hooks/useLoggedInUserDetails";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";

const { AccountsTitles } = require("../../json/title.json");

interface Props {
  pageType: PageFor;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  handleModalClose?: () => void;
}

const InterchangeReceipt = ({
  pageType,
  setModalFlag,
  handleModalClose,
}: Props) => {
  const { Accounts_Table } = useAcctTableJson();
  let DefaultDate = new Date();

  const dates = DateRange(DefaultDate.toString());
  const { format } = formatter;
  const { InstId } = useParams<{ InstId: string }>();
  const navigate = useNavigate();
  const { token } = useToken();
  //configuration settings
  const { studentData, studentFormData } = useStudentDatabyId();
  const { dispatch, state } = useContext(AppContext);
  const { USE_CONFIG_KEY } = useDisplayConfigIcon(
    PageNumbers.INTERCHANGE_FEE_RECEIPT_PAGE
  );
  const { InstDetails } = useInstDetails(1);
  const [imageString, setImageString] = useState("");
  const [imageStringStd, setImageStringStd] = useState("");

  const [printConfig, setPrintConfigModal] = useState(false);

  const [oldReceiptData, setOldReceiptData] =
    useState<voucherMasterDetails | null>(null);

  const filteredReceiptData = oldReceiptData?.acct_voucher_details?.find(
    (data) => data.vo_cr_db === DebitOrCredit.DEBIT
  );
  const [printModal, SetPrintModal] = useState(false);

  const [tableData, setTableData] = useState<Data[]>([]);
  const [v_no, set_vo_no] = useState("");
  const [v_date, set_v_Date] = useState("");
  const [transaction_no, set_transaction_no] = useState("");

  const [newReceiptItems, setNewReceiptItems] = useState<studentRecepit[]>([]);
  const [remarks, setRemarks] = useState("");
  const [admNo, setAdmNo] = useState("");

  const [disbaled, setDisabled] = useState(false);
  const [inEditMode, setInEditMode] = useState({
    status: false,
    rowKey: 0,
  });
  const [message, setMessage] = useState<msgType>({
    flag: false,
    message: "",
    operation: Operation.NONE,
  });

  // eslint-disable-next-line
  let totalCurrentReceiptFineAmount = 0;
  // eslint-disable-next-line
  let totalOutStandingBalance = 0;
  // eslint-disable-next-line
  let totalconcession = 0;
  let totalpaidAmount = 0;
  let totalPayable = 0;
  let totalbalance = 0;
  let totalDemandAmount = 0;
  let totalReceiptAmount = 0;
  let totalChangedFineamount = 0;
  // eslint-disable-next-line
  // oldReceiptData.map((oldData) => {
  //   if (oldData.vo_cr_db === DebitOrCredit.CREDIT) {
  //     // eslint-disable-next-line
  //     totalCurrentReceiptFineAmount += 0;
  //   }
  // });

  // eslint-disable-next-line
  newReceiptItems.map((item) => {
    totalOutStandingBalance += item.fee_ob;
    totalDemandAmount += item.fee_demand;
    totalconcession += item.fee_concession;
    totalbalance += item.fee_bal;
    totalReceiptAmount += item.rcpt_amt;
    totalPayable += item.fee_receivable;
    totalpaidAmount += item.fee_received;
  });

  const { serverDate } = useServerDateandTime();
  // TransferAcctVoucherMasterAndDetails;
  const [UpdatedReceviedAmount, setUpdatedReceviedAmount] = useState(0);
  //use state for student details

  const [currentReceiptStudentDetails, setCurrentReceiptStudentDetails] =
    useState<studentAcademicYearFormDetails>({
      std_adm_no: "",
      std_reg_no: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      branch_desc: "",
      class_desc: "",
      std_father_name: "",
      dept_desc: "",
      cat_desc: "",
    });
  //usestate for modal screen

  const [configurationModal, setConfigurationModal] = useState(false);
  const [enableSave, setEnableSave] = useState(false);

  const [studentDemandReceiptModal, setStudentDemandReceiptModal] =
    useState(false);
  const [studentModal, setStudentModal] = useState(false);
  const [viewInterchangeDetailsModal, setViewInterchangeDetailsModal] =
    useState(false);
  const { user_details } = useLoggedInUserDetails();

  //To Be changed shoudl ask for new query type
  const { studentAddmissionNumber } = useAcctStdAdmissionNumber(
    admNo,
    NumberOfAdmissionNumberToBeDisplayed,
    StudentAcctReportType.DEMAND_RAISED_NOT_PAID
  );

  //lazy queries
  const { StudentDemandDetails } = useStudentDemandDetails(
    DemandDetailsQueryType.DEMAND_DETAILS_TO_EXCHANGE,
    false,
    oldReceiptData?.v_book_type!
  );
  const [GetStudentDetailsByIds, { data: stdReceiptData }] = useLazyQuery<
    StudentData,
    StudentDetailsvars
  >(GetStudentDetails, {
    variables: { token, ids: [state.receiptStudentId] },
  });

  const [GetVoucherDetailsByVoucherId] = useLazyQuery<
    VouchernodeData,
    nodevars
  >(StudentDetailsByMasterVoucherId);
  const [GenerateStudentReceipts, { loading: GenerateStudentReceiptsLoading }] =
    useMutation(TransferAcctVoucherMasterAndDetails, {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    });
  const onEdit = (id: number, updatedRcptAmount: number) => {
    setInEditMode({
      status: true,
      rowKey: id,
    });
    setUpdatedReceviedAmount(updatedRcptAmount);
  };
  const HandleEditItem = (sl_number: number) => {
    const updatedData = newReceiptItems.map((item) => {
      if (item.id === sl_number) {
        if (UpdatedReceviedAmount > item.fee_receivable) {
          alert("Please check you're recepit amount");
          return item;
        } else {
          const newData = {
            ...item,
            fee_ob: item.fee_ob,
            fee_demand: item.fee_demand,
            fee_concession: item.fee_concession,
            fee_receivable: item.fee_receivable,
            fee_received: item.fee_received,
            fee_refunds: item.fee_refunds,
            fee_bal:
              item.fee_receivable - UpdatedReceviedAmount - item.fee_received,
            acct_ldgr_id: item.acct_ldgr_id,
            id: item.id,
            cr_db: DebitOrCredit.CREDIT,
            rcpt_amt: UpdatedReceviedAmount,
          };

          return newData;
        }
      }
      return item;
    });
    setNewReceiptItems(updatedData);
  };

  const handleAutoClear = () => {
    if (!printModal)
      dispatch({
        type: payloadTypes.SET_STUDENT_ID,
        payload: { studentId: 0 },
      });

    setNewReceiptItems([]);
    setImageString(EMPTY_STRING);
  };
  const handleClear = () => {
    setCurrentReceiptStudentDetails({
      std_adm_no: "",
      std_reg_no: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      branch_desc: "",
      class_desc: "",
      std_father_name: "",
      dept_desc: "",
      cat_desc: "",
    });
    handleAutoClear();
    setImageStringStd(EMPTY_STRING);
    dispatch({
      type: payloadTypes.SET_RECEIPT_STUDENT_ID,
      payload: { receiptStudentId: 0 },
    });
    dispatch({
      type: payloadTypes.SET_RECEPIT_ID,
      payload: { receiptId: 0 },
    });
    setOldReceiptData(null);
  };

  const handleSplittingAmount = (receivedAmount: number) => {
    setDisabled(!disbaled);
    setEnableSave(!enableSave);
    if (receivedAmount > totalDemandAmount) {
      alert(
        "Transfer Amount is Greater than Demand Amount of Transfer student"
      );
      setDisabled(false);
      setStudentModal(!studentModal);
      return;
    }
    if (state.studentId === state.receiptStudentId) {
      alert(
        "both students cannot be same, please select two different students in order to continue"
      );
      setStudentModal(!studentModal);
      setDisabled(false);
      return;
    } else {
      let initalAmount = receivedAmount;

      const rcvd_amt: studentRecepit[] = newReceiptItems.reduce(
        (result: studentRecepit[], element: studentRecepit) => {
          const rcpt_amt = Math.min(
            initalAmount,
            element.fee_receivable - element.fee_received
          );
          initalAmount -= rcpt_amt;
          result.push({
            ...element,
            rcpt_amt,
            fee_bal: element.fee_receivable - element.fee_received - rcpt_amt,
          });
          return result;
        },
        []
      );
      setNewReceiptItems(rcvd_amt);
    }
  };

  const handleStudentReceipts = () => {
    if (!oldReceiptData) return;

    GenerateStudentReceipts({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        voucher_master_id: state.receiptId,
        fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
        acct_voucher_master: {
          fin_yr: state.ActiveFinYr ? state.ActiveFinYr.fin_yr : 0,
          v_type: ReceiptTypes.STUDENT_RECEIPT,
          v_no: oldReceiptData.v_no,
          v_date: oldReceiptData.v_date,
          v_total_cr_amt: oldReceiptData.v_total_cr_amt,
          v_total_db_amt: oldReceiptData.v_total_db_amt,
          v_reconciled: false,
          v_reconciled_date: dayjs(DEFAULT_TIME).format(),
          v_std_receipt: true,
          v_std_refund: false,
          v_std_deposit_adjusted: false,
          v_std_anonymous_deposit_adjusted: false,

          v_std_receipt_ob: false,

          v_std_receipt_anonymous: false,
          v_std_refund_deposit: false,
          v_book_type: oldReceiptData.v_book_type,
          v_std_deposit: false,
          v_std_demand_receipt: true,
          v_std_scholarship_deposit: false,

          v_std_non_demand_receipt: false,
          student_id: state.studentId,
          class_id: studentData.data?.nodes[0].class.id,
          semester_id: studentData.data?.nodes[0].semester.id,
          v_std_amt_receipt: totalReceiptAmount,
          v_std_amt_deposit: 0,
          v_std_amt_fine: totalChangedFineamount,
          v_std_amt_total: totalReceiptAmount,
          v_std_amt_refunded: 0,
          v_std_passout_receipt: false,
          v_std_amt_adjusted: 0,
          v_std_enquiry: false,
          enquiry_student_id: 0,
          v_transcation_type: filteredReceiptData?.acct_ldgr.ldgr_desc,
          v_transcation_cash_or_bank:
            filteredReceiptData?.acct_ldgr.ldgr_cash_bank_other,
          v_transcation_no: oldReceiptData.v_transcation_no,
          v_transcation_date: oldReceiptData.v_date,
          v_transcation_narration: remarks,
          // paid_party_id: "",
          // party_bill_no: "",
          party_bill_date: dayjs(DEFAULT_TIME).format(),
          // party_name: "",
          annx_yesno: false,
          // annx_id: Math.random() * 1000,
          is_vouch_multi_entry: false,
          acct_ldgr_id_cr: newReceiptItems.filter((d) => d.rcpt_amt > 0)[0]
            .acct_ldgr_details.id,
          acct_ldgr_id_db: filteredReceiptData?.acct_ldgr.id,
        },
        acct_voucher_db:
          filteredReceiptData &&
          [filteredReceiptData].map((receipt, index) => ({
            vo_cr_db: DebitOrCredit.DEBIT,
            vo_sl_no: index + 1,
            vo_cr: 0,
            vo_db: receipt.vo_db,
            vo_cr_total: 0,
            vo_db_total: receipt.vo_db_total,
            acct_ldgr_id: receipt.acct_ldgr_id,
          })),
        acct_voucher_cr: newReceiptItems
          .filter((d) => d.rcpt_amt > 0)
          .map((item, index) => ({
            vo_sl_no: index + 1,
            vo_cr_db: DebitOrCredit.CREDIT,
            vo_cr: item.rcpt_amt,
            vo_db: 0,
            vo_cr_total: totalReceiptAmount,
            vo_db_total: 0,
            acct_ldgr_id: item.acct_ldgr_id,
          })),
      },
      refetchQueries: [
        {
          query: GetAcctTransferVoucherDetailsByInstId,
          variables: {
            token,
            inst_id: InstId!,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          },
        },
        {
          query: GetAcctVouchers,
          variables: {
            after: null,
            amount: null,
            deposit: null,
            direction: Direction.ASC,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            first: ROWS_PER_PAGE,
            input: {
              acct_ldgr_id: state.accountLedgerId,
              inst_id: InstId!,
              student_id: 0,
              user_details,
              vo_end_date: toIsoDate(dates?.lastDay!),
              vo_start_date: toIsoDate(dates?.firstDay!),
              vo_type: EMPTY_STRING,
              voucher_query_type: VoucherQueryTypes.ANONYMOUS_STD_RECEIPTS,
            },
            ldgrDesc: EMPTY_STRING,
            partyName: EMPTY_STRING,

            sortBy: SortBy.V_DATE,
            token,
            vTranscationCashOrBank: null,
            vTranscationNo: EMPTY_STRING,
            vTranscationNarration: EMPTY_STRING,
            v_no: EMPTY_STRING,
          },
        },
        {
          query: GetAcctVouchers,
          variables: {
            after: null,
            amount: null,
            deposit: null,
            direction: Direction.ASC,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            first: ROWS_PER_PAGE,
            input: {
              acct_ldgr_id: state.accountLedgerId,
              inst_id: InstId!,
              student_id: 0,
              user_details,
              vo_end_date: toIsoDate(dates?.lastDay!),
              vo_start_date: toIsoDate(dates?.firstDay!),
              vo_type: EMPTY_STRING,
              voucher_query_type: VoucherQueryTypes.STUDENT_DEMAND_RECEIPTS,
            },
            ldgrDesc: EMPTY_STRING,
            partyName: EMPTY_STRING,

            sortBy: SortBy.V_DATE,
            token,
            vTranscationCashOrBank: null,
            vTranscationNo: EMPTY_STRING,
            vTranscationNarration: EMPTY_STRING,
            v_no: EMPTY_STRING,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setTableData(
          newReceiptItems
            .filter((item) => item.rcpt_amt > 0)
            .map((item) => ({
              particular: item.acct_ldgr_details.ldgr_desc,
              amount: item.rcpt_amt,
            }))
        );
        set_v_Date(filteredReceiptData?.vo_date!);
        set_vo_no(oldReceiptData?.v_no!);
        set_transaction_no(oldReceiptData?.v_transcation_no!);

        !printModal ? SetPrintModal(!printModal) : handleClear();
        setMessage({
          message: `Sucessfully interchanged Receipts from ${
            currentReceiptStudentDetails.first_name +
            " " +
            currentReceiptStudentDetails.middle_name +
            " " +
            currentReceiptStudentDetails.last_name
          } to
        ${studentFormData?.std_name}`,
          flag: true,
          operation: Operation.CREATE,
        });
      }
    });
  };

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    if (
      StudentDemandDetails.data &&
      !StudentDemandDetails.loading &&
      state.studentId
    ) {
      const result = StudentDemandDetails.data?.GetAcctStdDemandDetails.filter(
        (item) => item !== null
      ).map((item) => ({
        legederType: LedgerType.GENERAL,
        fee_ob: item.fee_ob,
        fee_demand: item.fee_demand,
        fee_concession: item.fee_concession,
        fee_receivable: item.fee_receivable,
        fee_received: item.fee_received,
        fee_refunds: item.fee_refunds,
        fee_bal: item.fee_bal,
        acct_ldgr_id: item.acct_ldgr_id,
        id: item.id,
        fine_amt: 0,
        cr_db: DebitOrCredit.CREDIT,
        rcpt_amt: 0,
        student_id: item.student_id,
        vo_key: EMPTY_STRING,
        acct_ldgr_details: item.acct_ldgr_details,
        fee_receiveable: item.fee_receivable,
        initialBalance: item.fee_bal,
      }));

      setNewReceiptItems(result!);
    }
  }, [
    state.studentId,
    token,
    StudentDemandDetails.data,
    StudentDemandDetails.loading,
  ]);
  useEffect(() => {
    if (state.receiptStudentId) {
      GetStudentDetailsByIds().then(({ data }) => {
        if (data) {
          // eslint-disable-next-line
          data?.nodes.map((node) => {
            setCurrentReceiptStudentDetails({
              std_adm_no: node.std_adm_no,
              first_name: node.first_name,
              middle_name: node.middle_name,
              last_name: node.last_name,
              std_reg_no: node.std_reg_no,
              std_father_name: node.std_father_name,
              dept_desc: node.dept.dept_desc,
              branch_desc: node.branch.branch_desc,
              class_desc: node.class.class_desc,
              cat_desc: node.category.cat_desc,
            });
          });
        }
      });
    } // eslint-disable-next-line
  }, [state.receiptStudentId]);
  useEffect(() => {
    if (state.receiptId) {
      GetVoucherDetailsByVoucherId({
        variables: { token, ids: [state.receiptId] },
      }).then(({ data }) => {
        if (data) {
          setOldReceiptData(data.nodes[0]);
        }
      });
    }
  }, [state.receiptId, GetVoucherDetailsByVoucherId, token]);
  useEffect(() => {
    if (
      studentData.data &&
      studentData.data?.nodes[0].std_profile_filename !== EMPTY_STRING &&
      InstDetails.data
    ) {
      const studentProfiePicUrl = `${InstDetails.data?.nodes[0]?.inst_name}/students/${state.studentId}/std_profile_pic/std_profile_pic`;
      // eslint-disable-next-line
      const ers = getDownloadUrl(studentProfiePicUrl, false, setImageString);
    } else {
      setImageString("");
    }
  }, [studentData.data, InstDetails.data, state.studentId]);
  useEffect(() => {
    if (
      stdReceiptData &&
      state.receiptStudentId &&
      stdReceiptData?.nodes[0].std_profile_filename !== ""
    ) {
      // eslint-disable-next-line
      const studentProfiePicUrl = `${InstDetails.data?.nodes[0]?.inst_name}/students/${state.receiptStudentId}/std_profile_pic/std_profile_pic`;
      // eslint-disable-next-line
      const ers = getDownloadUrl(studentProfiePicUrl, false, setImageStringStd);
    }
  }, [stdReceiptData, state.receiptStudentId, InstDetails.data]);
  const { branchLabel, classLabel } = useInstLabels();

  return (
    <>
      {pageType === PageFor.GENERAL ? <Home DashBoardRequired={false} /> : null}
      <div className="row g-0">
        <div className="col">
          <Title>
            {AccountsTitles.StudentFeeReceipt.Titles.map(
              (title: StudentFeeReceiptTitleProps, index: React.Key) => {
                return (
                  <React.Fragment key={index}>
                    {title.InterChange_Fee_Receipt}
                  </React.Fragment>
                );
              }
            )}
          </Title>
        </div>

        <div className="configuration-settings">
          {pageType === PageFor.GENERAL ? (
            <>
              {USE_CONFIG_KEY && (
                <img
                  src={Settings}
                  alt="/"
                  id="settings-icon"
                  onClick={() => setConfigurationModal(!configurationModal)}
                />
              )}
            </>
          ) : (
            <>
              <img src={Enlarge} alt="/" onClick={() => toggleFullSceen()} />
            </>
          )}
        </div>
      </div>
      <div
        className={
          pageType === PageFor.GENERAL
            ? "interchange-fee-receipt"
            : "interchange-fee-receipt--modal"
        }>
        <div className="row g-0 interchange-fee-receipt__details">
          <div className="col">
            <div className="interchange-fee-receipt__block--image">
              <TextField
                label="Receipt No."
                className="interchange-fee-receipt__block--textfield"
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                value={
                  filteredReceiptData && state.receiptId
                    ? oldReceiptData?.v_no
                    : EMPTY_STRING
                }
                disabled
              />

              <img
                className="data-fetch-icon"
                src={Edit}
                alt="/"
                onClick={() =>
                  setStudentDemandReceiptModal(!studentDemandReceiptModal)
                }
              />
            </div>

            <TextField
              label="Remitted to."
              className="interchange-fee-receipt__block--textfield"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              value={
                filteredReceiptData && state.receiptId
                  ? toStandardCase(filteredReceiptData.acct_ldgr.ldgr_desc)
                  : EMPTY_STRING
              }
              disabled
            />
          </div>
          <div className="col interchange-fee-receipt__block--remarks">
            <Label>Remarks</Label>
            <TextArea
              disabled
              onChange={(e) => setRemarks(e.target.value)}
              rows={2}
            />
          </div>
          <div className="col">
            <div className="interchange-fee-receipt__block--image">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={studentAddmissionNumber}
                value={
                  state.studentId
                    ? studentAddmissionNumber?.find(
                        ({ value }) => value === state.studentId
                      )!
                    : null
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    dispatch({
                      type: payloadTypes.SET_STUDENT_ID,
                      payload: {
                        studentId: (newValue as responseType)?.value,
                      },
                    });
                  } else {
                    handleAutoClear();
                  }
                }}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === Keys.BACKSPACE) {
                    handleAutoClear();
                  }
                }}
                autoHighlight
                renderInput={(params) => (
                  <TextField
                    {...params}
                    autoFocus
                    onChange={(e) => setAdmNo(e.target.value)}
                    label="Admission No. to Transfer"
                    fullWidth
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
              <img
                className="data-fetch-icon"
                src={Edit}
                alt="/"
                onClick={() => setStudentModal(!studentModal)}
              />
            </div>

            <div className="interchange-fee-receipt__block--date">
              <TextField
                type="date"
                label="Date"
                className="interchange-fee-receipt__block--textfield--date"
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                value={
                  toInputStandardDate(oldReceiptData?.v_date!) ?? EMPTY_STRING
                }
                disabled
              />
              <Label variant="present-day">{NameOfTheDay(serverDate)}</Label>
            </div>
          </div>
        </div>
        <div className="interchange-fee-receipt__block2">
          <div className="account-frames h-100">
            <div className="row g-0 h-30 interchange-fee-receipt__block2--details">
              <div className="details interchange-fee-receipt__block2--current">
                <h4>Current Receipt Details</h4>
              </div>
              <div className="col-2 interchange-fee-receipt__block2--subblock interchange-fee-receipt__block2--image h-100">
                {imageStringStd === EMPTY_STRING ? (
                  <img src={Avatar} alt="/" />
                ) : (
                  <img src={imageStringStd} alt="/" />
                )}
              </div>
              <div className="col interchange-fee-receipt__block2--subblock">
                <TextField
                  className="interchange-fee-receipt__block2--textfield"
                  label="Admission No."
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  value={
                    state.receiptStudentId
                      ? currentReceiptStudentDetails.std_adm_no
                      : EMPTY_STRING
                  }
                  disabled
                />
                <TextField
                  className="interchange-fee-receipt__block2--textfield"
                  label="Name"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  disabled
                  value={
                    state.receiptStudentId
                      ? currentReceiptStudentDetails.first_name +
                        " " +
                        currentReceiptStudentDetails.middle_name +
                        " " +
                        currentReceiptStudentDetails.last_name
                      : EMPTY_STRING
                  }
                />
                <TextField
                  className="interchange-fee-receipt__block2--textfield"
                  label={classLabel}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  disabled
                  value={
                    state.receiptStudentId
                      ? currentReceiptStudentDetails.class_desc
                      : EMPTY_STRING
                  }
                />
              </div>
              <div className="col interchange-fee-receipt__block2--subblock">
                <TextField
                  label="Reg No."
                  className="interchange-fee-receipt__block2--textfield"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  disabled
                  value={
                    state.receiptStudentId
                      ? currentReceiptStudentDetails.std_reg_no
                      : EMPTY_STRING
                  }
                />
                <TextField
                  label={branchLabel}
                  className="interchange-fee-receipt__block2--textfield"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  disabled
                  value={
                    state.receiptStudentId
                      ? currentReceiptStudentDetails.branch_desc
                      : EMPTY_STRING
                  }
                />
              </div>
            </div>
          </div>

          <div className="account-frames h-100">
            <div className="row g-0 h-30 interchange-fee-receipt__block2--details">
              <div className="details interchange-fee-receipt__block2--changed">
                <h4>Changed Receipt Details</h4>
              </div>

              <div className="col interchange-fee-receipt__block2--subblock">
                <TextField
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  className="interchange-fee-receipt__block2--textfield"
                  label="Admission No."
                  value={
                    state.studentId ? studentFormData?.adm_no : EMPTY_STRING
                  }
                  disabled
                />
                <TextField
                  className="interchange-fee-receipt__block2--textfield"
                  label="Name"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  value={
                    state.studentId ? studentFormData?.std_name : EMPTY_STRING
                  }
                  disabled
                />
                <TextField
                  className="interchange-fee-receipt__block2--textfield"
                  label={classLabel}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  value={
                    state.studentId ? studentFormData?.class : EMPTY_STRING
                  }
                  disabled
                />
              </div>
              <div className="col interchange-fee-receipt__block2--subblock">
                <TextField
                  label="Reg No."
                  className="interchange-fee-receipt__block2--textfield"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  disabled
                  value={
                    state.studentId ? studentFormData?.reg_number : EMPTY_STRING
                  }
                />
                <TextField
                  label={branchLabel}
                  className="interchange-fee-receipt__block2--textfield"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  value={
                    state.studentId ? studentFormData?.branch : EMPTY_STRING
                  }
                  disabled
                />
              </div>
              <div className="col-2 interchange-fee-receipt__block2--subblock interchange-fee-receipt__block2--image h-100">
                {imageString === EMPTY_STRING ? (
                  <img src={Avatar} alt="/" />
                ) : (
                  <img src={imageString} alt="/" />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="interchange-fee-receipt__tableblock">
          <TableContainer className="interchange-fee-receipt__table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Accounts_Table.Receipts.InterChangeReceipt.CurrentReceiptDetails.Table_Headers.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return (
                        <TableCell key={index} className={th.className}>
                          {th.labelName}
                        </TableCell>
                      );
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredReceiptData && state.receiptId && oldReceiptData
                  ? oldReceiptData.acct_voucher_details
                      ?.filter((d) => d.vo_cr_db === DebitOrCredit.CREDIT)
                      ?.map((receipt, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell
                              id="td-center"
                              className="interchange-fee-receipt__table--slno">
                              {index + 1}
                            </TableCell>
                            <TableCell>{receipt.acct_ldgr.ldgr_desc}</TableCell>
                            <TableCell
                              id="td-right"
                              className="interchange-fee-receipt__table--amount">
                              {format(receipt.vo_cr)}
                            </TableCell>
                          </TableRow>
                        );
                      })
                  : null}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={2} className="total">
                    Fine Amount :
                  </TableCell>
                  <TableCell id="td-right" className="balance-count">
                    0
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} className="total">
                    Receipt Amount :
                  </TableCell>
                  <TableCell id="td-right" className="balance-count">
                    {format(
                      filteredReceiptData &&
                        state.receiptId &&
                        oldReceiptData?.v_total_cr_amt
                        ? oldReceiptData.v_total_cr_amt
                        : 0
                    )}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>

          <div className="interchange-fee-receipt__block2--interchange-image">
            <Button
              mode="transfer"
              disabled={disbaled || !state.studentId}
              onClick={() =>
                handleSplittingAmount(
                  oldReceiptData?.v_total_cr_amt
                    ? oldReceiptData.v_total_cr_amt
                    : 0
                )
              }
            />
          </div>
          <TableContainer className="interchange-fee-receipt__table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Accounts_Table.Receipts.InterChangeReceipt.ChangedReceiptDetails.Table_Headers.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return <TableCell key={index}>{th.labelName}</TableCell>;
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {newReceiptItems.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <TableRow>
                        <TableCell
                          className="interchange-fee-receipt__table--slno"
                          align="center">
                          {index + 1}
                        </TableCell>
                        <TableCell>
                          {item.acct_ldgr_details.ldgr_desc}
                        </TableCell>

                        <TableCell
                          id="td-right"
                          className="interchange-fee-receipt__table--amount">
                          {format(item.fee_demand)}
                        </TableCell>

                        <TableCell
                          id="td-right"
                          className="interchange-fee-receipt__table--amount">
                          {format(item.fee_receivable)}
                        </TableCell>
                        <TableCell
                          id="td-right"
                          className="interchange-fee-receipt__table--amount">
                          {format(item.fee_received)}
                        </TableCell>

                        <TableCell
                          id="td-right"
                          onClick={() => {
                            if (item.rcpt_amt > 0) {
                              onEdit(
                                newReceiptItems[index].id!,
                                newReceiptItems[index].rcpt_amt
                              );
                            }
                            if (item.rcpt_amt === 0) {
                              onEdit(item.id!, 0);
                            }
                          }}
                          className="interchange-fee-receipt__table--amount totalcountcell interchange-fee-receipt__table--input ">
                          {inEditMode.status &&
                          inEditMode.rowKey === item.id ? (
                            <Input
                              id="td-right"
                              size="small"
                              type="number"
                              autoFocus
                              value={UpdatedReceviedAmount}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                if (Number(e.target.value) >= 0) {
                                  setUpdatedReceviedAmount(
                                    Number(e.target.value)
                                  );
                                }
                              }}
                              onKeyDown={(event: React.KeyboardEvent) => {
                                if (event.key === Keys.ENTER) {
                                  if (
                                    newReceiptItems[index + 1] &&
                                    newReceiptItems[index + 1].id
                                  ) {
                                    onEdit(
                                      newReceiptItems[index + 1].id!,
                                      newReceiptItems[index + 1].rcpt_amt
                                    );
                                    HandleEditItem(item.id!);
                                  } else {
                                    onEdit(
                                      newReceiptItems[index].id!,
                                      newReceiptItems[index].rcpt_amt
                                    );
                                    HandleEditItem(item.id!);

                                    setInEditMode({
                                      rowKey: -805,
                                      status: false,
                                    });
                                  }
                                }
                              }}
                            />
                          ) : (
                            format(item.rcpt_amt)
                          )}
                        </TableCell>
                        <TableCell
                          id="td-right"
                          className="balance-amount interchange-fee-receipt__table--amount">
                          {format(item.fee_bal)}
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={5} className="total">
                    Fine Amount :
                  </TableCell>
                  <TableCell id="td-right" className="totalcount">
                    0
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell colSpan={2} className="total">
                    Total :
                  </TableCell>

                  <TableCell id="td-right" className="totalcount">
                    {format(totalDemandAmount)}
                  </TableCell>

                  <TableCell id="td-right" className="totalcount">
                    {format(totalPayable)}
                  </TableCell>
                  <TableCell id="td-right" className="totalcount">
                    {format(totalpaidAmount)}
                  </TableCell>
                  <TableCell id="td-right" className="totalcount">
                    {format(totalReceiptAmount)}
                  </TableCell>
                  <TableCell id="td-right" className="balance-count">
                    {format(totalbalance)}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>

        <Button
          mode="save"
          onClick={handleStudentReceipts}
          disabled={totalReceiptAmount !== oldReceiptData?.v_total_cr_amt}
        />

        <Button
          mode="view"
          onClick={() =>
            setViewInterchangeDetailsModal(!viewInterchangeDetailsModal)
          }
        />

        <Button mode="clear" onClick={handleClear} />
        {pageType === PageFor.GENERAL ? (
          <Button mode="back" onClick={() => navigate(-1)} type="button" />
        ) : (
          <Button
            mode="cancel"
            type="button"
            onClick={() => {
              if (handleModalClose) {
                handleModalClose?.();
              }
              setModalFlag(false);
            }}
          />
        )}
      </div>

      {/* configurationModal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={configurationModal}
        style={ConfigurationsModalStyles}
        ariaHideApp={false}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ConfigurationSettings
              pageNumber={PageNumbers.INTERCHANGE_FEE_RECEIPT_PAGE}
              setModalFlag={setConfigurationModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        </div>
      </Modal>
      {/* studentmodal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={studentModal}
        style={StudentModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentList
              pageType={PageFor.MODAL}
              studentListFor={StudentListFor.ACCOUNTS}
              setStudentModal={setStudentModal}
              queryType={StudentAcctReportType.DEMAND_RAISED}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStudentModal(!studentModal)}
            />
          </div>
        </div>
      </Modal>
      {/* student Demand Receipt modal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={studentDemandReceiptModal}
        style={StudentModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentDemandReceipt
              setStudentModal={setStudentDemandReceiptModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() =>
                setStudentDemandReceiptModal(!studentDemandReceiptModal)
              }
            />
          </div>
        </div>
      </Modal>
      {/* interchange view details modal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={viewInterchangeDetailsModal}
        style={StudentModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ViewInterchangeDetails
              setStudentModal={setViewInterchangeDetailsModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() =>
                setViewInterchangeDetailsModal(!viewInterchangeDetailsModal)
              }
            />
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={printModal}
        style={PrintModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <FeeReceiptPrint
              tableData={tableData}
              v_date={v_date}
              v_no={v_no}
              transaction_no={transaction_no}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => {
                SetPrintModal(!printModal);
                dispatch({
                  type: payloadTypes.SET_STUDENT_ID,
                  payload: { studentId: 0 },
                });
                handleClear();
              }}
            />
            <img
              src={Settings}
              alt="/"
              id="settings-icon"
              onClick={() => setPrintConfigModal(!printConfig)}
            />
          </div>
        </div>
      </Modal>
      {/* print configuration modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={printConfig}
        style={PrintConfigModalStyles}
        ariaHideApp={false}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Configurations
              config_query_type={SwConfigQueryType.INST_BY_MODULE}
              str_value={ModuleName.RECEIPT_PRINT}
              int_value={0}
              setModalFlag={setPrintConfigModal}
              pageType={PageFor.MODAL}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setPrintConfigModal(!printConfig)}
            />
          </div>
        </div>
      </Modal>
      <LoadingModal flag={GenerateStudentReceiptsLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default InterchangeReceipt;
