import axios from "axios";
import fileDownload from "js-file-download";

export const getDownloadUrl = (
  image: string,
  downloadFile: boolean,
  setDownloadUrl?: React.Dispatch<React.SetStateAction<string>>
) => {
  let downloadImageUrl = "";
  const downloadBaseUrl = `https://ckhuef9sg0.execute-api.ap-south-1.amazonaws.com/dev/downloadObject?file_name=${image}&access_type=${process.env.React_App_access_type}`;
  axios
    .post(downloadBaseUrl, null, {
      headers: {
        "Content-Type":
          "application/x-www-form-urlencoded; charset=UTF-8;application/json",
      },
    })
    .then((response) => {
      axios
        .get(response.data.split("?")[0], { responseType: "blob" })
        .then((fileData) => {
          const resultUrl = response.data.split("?")[0];
          const file_name =
            resultUrl.split("/")[resultUrl.split("/").length - 1];

          if (downloadFile) {
            fileDownload(fileData.data, file_name.replace("%20", " "));
          }

          if (!downloadFile) {
            downloadImageUrl = response.data;
            setDownloadUrl?.(response.data);
          }
        });
      // axios.head(response.data).then((headResponse) => {
      //   const fileSizeBytes = parseInt(headResponse.headers["content-length"]);
      //   const fileSizeKB = fileSizeBytes / 1024;
      //   const fileSizeMB = fileSizeKB / 1024;

      //   console.log("File size (bytes):", fileSizeBytes);
      //   console.log("File size (KB):", fileSizeKB);
      //   console.log("File size (MB):", fileSizeMB);

      //   const roundedFileSizeMB = Math.max(fileSizeMB, 1); // Round off to 1MB if less than 1MB

      // });
    })
    .catch((err) => {
      console.log(err);
    });
  return downloadImageUrl;
};
