import { useLazyQuery } from "@apollo/client";
import {
  GetAcdTestReportCardConfigData,
  GetAcdTestReportCardConfigVars,
} from "../../../Academics/types/Progresscard";
import { useParams } from "react-router-dom";
import useToken from "../../../../customhooks/useToken";
import { GetAcdTestReportCardConfig } from "../../../Academics/queries/reportcard";
import { useEffect } from "react";
import { reportCardConfigTypes } from "../../Enum.types";

const usePerTestReportConfig = () => {
  const { token } = useToken();
  const { InstId, testId } = useParams();
  const [GetReportCartConfigDetails, { data }] = useLazyQuery<
    GetAcdTestReportCardConfigData,
    GetAcdTestReportCardConfigVars
  >(GetAcdTestReportCardConfig, {
    variables: {
      token,
      inst_id: Number(InstId),
      acd_test_class_id: Number(testId),
    },
  });
  

  useEffect(() => {
    if (token && InstId && testId) {
      GetReportCartConfigDetails();
    }
  }, [token, InstId, testId, GetReportCartConfigDetails]);

  const custNamePerTest =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_CUSTOMER_NAME
    );
  const instLogoPerTest=
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_INST_LOGO
    );
  const stdImagePerTest =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_STUDENT_PHOTO
    );
  const headerPerTest =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_HEADER
    );
  const affiliatedToPerTest =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_AFFILIATED_CAPTION
    );
  const photo1ConfigPerTest =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_PHOTO_1
    );
  const photo2ConfigPerTest =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_PHOTO_2
    );
  const instNamePerTest =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_INST_NAME
    );

  const instAddressPerTest =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_INST_ADDRESS
    );
  const instEmailPerTest =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_INST_EMAIL
    );

  const instWebsitePerTest =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_INST_WEBSITE
    );
  const gradeScalePerTest =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_GRADE_SCALE
    );
  const stdNamePerTest =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_STD_NAME
    );
  const sectionPerTest =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_STD_CLASS_SECTION
    );
  const rollNoPerTest =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_STD_ROLL_NUMBER
    );
  const dobPerTest =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_STD_DOB
    );
  const motherNamePerTest =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_STD_MOTHER_NAME
    );
  const fatherNamePerTest =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_STD_FATHER_NAME
    );

  const stdAddressPerTest =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_STD_ADDRESS
    );

  const stdMobilePerTest =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_STD_MOBILE
    );
  const stdEmailPerTest =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_STD_EMAIL
    );
  const parentSignPerTest =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_PARENT_SIGN
    );
  const classTeacherSignPerTest =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_CLASS_TEACHER_SIGN
    );

  const attendanceStatsPerTest =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_STD_ATTENDANCE_STATS
    );
  const remarksPerTest =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_REMARKS_PER_TEST
    );
  const gradeForEachSubPerTest=
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) =>
        res.config_key === reportCardConfigTypes.RP_GRADE_FOR_EACH_SUBJECT
    );

  const heightWeightPerTest =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_STD_HEIGHT_WEIGHT
    );

  const overAllPercentagePerTest =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_OVERALL_PERCENTAGE
    );
  const annualResultsPerTest =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_ANNUAL_RESULTS
    );

  const overAllGradePerTest=
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_STD_OVERALL_GRADE
    );
  const overAllRankPerTest =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_STD_OVERALL_RANK
    );
  const titlePerTest=
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_TITLE
    );
    const marginLeftPerTest =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_LEFT_MARGIN
    );
    const marginRightPerTest =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_RIGHT_MARGIN
    );
    const marginTopPerTest =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_TOP_MARGIN
    );
    const marginBottomPerTest =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_BOTTOM_MARGIN
    );
    const borderTypePerTest =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_FRONT_PAGE_BORDER_TYPE
    );
    const templatePerTest =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_TEMPLATE
    );  
    const signatureTypePerTest =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_SIGNATURE_TYPE
    );
    const signatureFromInstPerTest =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_SIGNATURE_FROM_INST
    );

  const otherDetailsPerTest =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_OTHER_DETAILS_HEADER
    );
  const attendanceDetailsPerTest =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_ATTENDANCE_HEADER
    );
  const ConductPerTest =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_CONDUCT_PER_TEST
    );

  return {
    instAddressPerTest,
    instLogoPerTest,
    instNamePerTest,
    instWebsitePerTest,
    gradeScalePerTest,
    instEmailPerTest,
    stdEmailPerTest,
    stdMobilePerTest,
    stdAddressPerTest,
    stdNamePerTest,
    sectionPerTest,
    dobPerTest,
    motherNamePerTest,
    fatherNamePerTest,
    rollNoPerTest,
    custNamePerTest,
    parentSignPerTest,
    attendanceStatsPerTest,
    heightWeightPerTest,
    gradeForEachSubPerTest,
    remarksPerTest,
    classTeacherSignPerTest,
    overAllPercentagePerTest,
    annualResultsPerTest,
    overAllGradePerTest,
    overAllRankPerTest,
    stdImagePerTest,
    headerPerTest,
    affiliatedToPerTest,
    photo1ConfigPerTest,
    photo2ConfigPerTest,
    titlePerTest,
    marginLeftPerTest,
    marginRightPerTest,
    marginTopPerTest,
    marginBottomPerTest,
    borderTypePerTest,
    templatePerTest,
    ConductPerTest,
    otherDetailsPerTest,
    attendanceDetailsPerTest,
    signatureFromInstPerTest,
    signatureTypePerTest
  };
};
export default usePerTestReportConfig;
