/**
 * React functional component that renders a table of concession vouchers.
 * Uses the `useLazyQuery` hook from the `@apollo/client` library to fetch the voucher data from a GraphQL API.
 * Includes pagination functionality and a modal for displaying additional details of a selected voucher.
 *
 * @component
 * @example
 * <ConcessionView pageType={PageFor.GENERAL} setModal={setModal} />
 *
 * @param {PageFor} pageType - The type of page where the component is rendered.
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setModal - A state setter function to control the visibility of the modal.
 *
 * @returns {JSX.Element} - A table of concession vouchers with pagination and search functionality.
 * Clicking on a row in the table opens a modal with additional details of the selected voucher.
 */
import { useLazyQuery } from "@apollo/client";

import React, { useContext, useEffect, useMemo, useState } from "react";
import Modal from "react-modal";
import Close from "../../../images/Close.svg";
import useToken from "../../../customhooks/useToken";
import { Title } from "../../../stories/Title/Title";
import {
  GetAcctStdConcessionsData,
  GetAcctStdConcessionsEdge,
  GetAcctStdConcessionsVars,
} from "../../../Types/Accounting";
import {
  formatter,
  getModifiedScrollHeight,
  toInputStandardDate,
  toIsoDate,
  toStandardDate,
} from "../../../utils/UtilFunctions";
import {
  EMPTY_STRING,
  emptyMessageType,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../utils/constants";
import {
  AcctStdConcessionQueryType,
  Direction,
  Operation,
  PageFor,
  SortBy,
} from "../../../utils/Enum.types";

import { SortBy as SortByForReport } from "../../Master/Reports/InstitutionLevel/GlobalStates/types";
import { GetAcctStdConcessions } from "../queries/Vouchers/query";
import { useNavigate, useParams } from "react-router-dom";
import { ViewPickedSubjectModalStyles } from "../../../styles/ModalStyles";
import { Button } from "../../../stories/Button/Button";
import Home from "../Home/Index";
import Input from "../../../stories/Input/Input";
import StudentTotalCount from "../../Master/Student/Components/StudentTotalCount";
import useAcctTableJson from "../json/useAcctTableJson";
import useLoggedInUserDetails from "../hooks/useLoggedInUserDetails";
import { AppContext } from "../../../context/context";
import { TableHeaderProps } from "../../../utils/types";
import {
  GridAlignment,
  GridCellParams,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../styles/DataGridTableStyles";
import { ExportAcctStdtoExcel } from "../../../queries/xls";
import {
  ExportAcctStdtoExcelData,
  ExportAcctStdtoExcelVars,
} from "../../Master/Reports/InstitutionLevel/useExportMutation";
import { getDownloadUrl } from "../../../utils/DownloadFile";
import MessageModal from "../../../pages/MessageModal";
import LoadingModal from "../../../pages/LoadingModal";
import { TextField } from "@mui/material";
import { VoucherQueryTypes } from "../common/Enum.types";

interface Props {
  pageType: PageFor;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConcessionView = ({ pageType, setModal }: Props) => {
  const { format } = formatter;
  const navigate = useNavigate();
  const { InstId } = useParams();
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const [message, setMessage] = useState(emptyMessageType);
  const { Accounts_Table } = useAcctTableJson();
  const [feeReceiptModal, setFeeReceiptModal] = useState(false);
  const [v_id, setV_id] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchData, setSearchData] = useState("");
  const [students, setStudents] = useState<GetAcctStdConcessionsEdge[]>([]);
  const [rows, setRows] = useState<GridValidRowModel[]>([]);

  const [ExportAcct, { loading: exportLoading }] = useLazyQuery<
    ExportAcctStdtoExcelData,
    ExportAcctStdtoExcelVars
  >(ExportAcctStdtoExcel);

  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const { user_details } = useLoggedInUserDetails();
  const [GetAcctConcession, { data, loading, error, fetchMore }] = useLazyQuery<
    GetAcctStdConcessionsData,
    GetAcctStdConcessionsVars
  >(GetAcctStdConcessions, {
    variables: {
      after: null,
      direction: Direction.ASC,
      fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
      first: ROWS_PER_PAGE,
      input: {
        query_type: AcctStdConcessionQueryType.STD_CONCESSIONS,
        vo_start_date: toIsoDate(startDate),
        vo_end_date: toIsoDate(endDate),
        user_details,
      },
      inst_id: InstId!,
      name: searchData,
      sortBy: SortBy.CONCESSION_VOUCH_DATE,
      token,
    },
  });

  const total = useMemo(() => {
    return data
      ? data.GetAcctStdConcessions.edges.reduce((acc, i) => {
          acc += i.node.concession_total;
          return acc;
        }, 0)
      : 0;
  }, [data]);

  const filteredConcesstionDetails = students?.find((d) => d.node.id === v_id);

  const dynamicHeaders: TableHeaderProps[] =
    Accounts_Table.Concession.Table_Headers.map((header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
      hideable: header.hideable,
    }));
  const ConcessionGivenForLedgers: TableHeaderProps[] =
    Accounts_Table.ConcessionGivenForLedgers.Table_Headers.map((header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
      hideable: header.hideable,
    }));
  const columns: GridColDef[] = [...dynamicHeaders];
  const columnsConcessionGivenForLedgers: GridColDef[] = [
    ...ConcessionGivenForLedgers,
  ];
  const concessionGivenForLedgersRows =
    filteredConcesstionDetails?.node.concession_details.map((f, index) => ({
      id: index + 1,
      particulars: f.acct_ldgr_details.ldgr_desc,
      amount: format(f.concession_amt),
    })) ?? [];
  useEffect(() => {
    if (token && state.ActiveFinYr) {
      if (startDate && endDate) GetAcctConcession();
    }
  }, [
    token,
    state.ActiveFinYr,
    startDate,
    endDate,
    GetAcctConcession,
    searchData,
  ]);

  useEffect(() => {
    if (state.ActiveFinYr) {
      setStartDate(state.ActiveFinYr.fin_st_date);
      setEndDate(state.ActiveFinYr.fin_end_date);
    }
  }, [state.ActiveFinYr]);

  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetAcctStdConcessions.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newRow) => {
          const filteredStudent = students.find(
            (row) => row.node.id === newRow.node.id
          );
          if (filteredStudent) {
            return {
              ...newRow,
              node: {
                ...newRow.node,
              },
            };
          }
          return newRow;
        });
        setStudents(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            concession_id: node.id,
            id: index + 1,
            concession_no: node.concession_vouch_no,
            date: toStandardDate(node.concession_vouch_date),
            adm_no: node.mst_student.std_adm_no,
            reg_no: node.mst_student.std_reg_no,
            name:
              node.mst_student.first_name +
              node.mst_student.middle_name +
              node.mst_student.last_name +
              `(${node.branch_details.branch_desc} / ${node.class_details.class_desc} / ${node.concession_details[0].acct_ldgr_details.ldgr_desc})`,

            concession: format(node.concession_total),
            authorized_by: node.concession_auth_by,
            remarks: node.concession_naration,
            std_id: node.mst_student.id,
          }))
        );
      } else {
        setStudents(newData);
        setRows(
          newData.map(({ node }, index) => ({
            concession_id: node.id,

            id: index + 1,
            concession_no: node.concession_vouch_no,
            date: toStandardDate(node.concession_vouch_date),
            adm_no: node.mst_student.std_adm_no,
            reg_no: node.mst_student.std_reg_no,
            name:
              node.mst_student.first_name +
              node.mst_student.middle_name +
              node.mst_student.last_name +
              `(${node.branch_details.branch_desc} / ${node.class_details.class_desc} / ${node.concession_details[0].acct_ldgr_details.ldgr_desc})`,
            concession: format(node.concession_total),
            authorized_by: node.concession_auth_by,
            remarks: node.concession_naration,
            std_id: node.mst_student.id,
          }))
        );
      }
      setEndCursor(data.GetAcctStdConcessions.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);

  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;

      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: FETCH_MORE_DATA,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetAcctStdConcessions.edges;
                const pageInfo = fetchMoreResult.GetAcctStdConcessions.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck =
                  prevResult.GetAcctStdConcessions.edges.filter(
                    ({ node: { id } }) =>
                      newEdges.findIndex(
                        ({ node: { id: newId } }) => newId === id
                      ) !== -1
                  );

                if (duplicateCheck.length > 0) return prevResult;

                return {
                  GetAcctStdConcessions: {
                    edges: [...students, ...newEdges],
                    pageInfo,
                    totalCount: data
                      ? data.GetAcctStdConcessions.totalCount!
                      : 0,
                  },
                };
              },
            });
          }
        }
      };

      if (scrollTable && rows.length)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    [rows]
  );

  if (error) return <p>{error.message}</p>;

  const getRow = () => {
    if (students.length) {
      return [
        {
          id: [],
          concession_no: [],
          date: [],
          adm_no: [],
          reg_no: [],
          name: "Summation of Concession:",
          concession: format(total) || 0,
          authorized_by: [],
          remarks: [],
        },
      ];
    }
    return [];
  };

  const pinnedRows = {
    bottom: getRow(),
  };
  const hanldeExport = () => {
    try {
      ExportAcct({
        variables: {
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          input: {
            float_data: [],
            ids: [Number(InstId), 0],
            int_data: [],
            std_status: [],
            str_data: [],
          },
          voucher_input: {
            acct_ldgr_id: 0,
            vo_start_date: toIsoDate(startDate),
            vo_end_date: toIsoDate(endDate),
            vo_type: EMPTY_STRING,
            v_book_type: EMPTY_STRING,
          },
          inst_id: InstId!,
          parameters: [],
          query_type: VoucherQueryTypes.ACCT_VOUCHERS_STD_CONCESSION,
          sort_by: SortByForReport.STD_NAME,
          token,
        },
      }).then(async ({ data, error }) => {
        if (data && data.ExportAcctStdtoExcel) {
          const res = await getDownloadUrl(data.ExportAcctStdtoExcel, true);
          if (res) {
            setMessage({
              flag: true,
              message: "Downloading...",
              operation: Operation.CREATE,
            });
          }
        } else {
          setMessage({
            flag: true,
            message: "Failed downloading file",
            operation: Operation.NONE,
          });
        }

        if (error && error.message) {
          setMessage({
            flag: true,
            message: error.message,
            operation: Operation.NONE,
          });
        }
      });
    } catch (e) {
      setMessage({
        flag: true,
        message: "Failed to download",
        operation: Operation.NONE,
      });
    }
  };

  const getCellClassName = (params: GridCellParams) => {
    const pinnedRowIds = pinnedRows.bottom
      ? pinnedRows.bottom.map((row) => row.id)
      : [];

    const isPinnedRow = pinnedRowIds.includes(params.row.id);

    if (isPinnedRow) {
      switch (params.field) {
        case "concession":
          return "totalcount";
        default:
          return "total";
      }
    }

    return "";
  };
  return (
    <>
      {pageType === PageFor.GENERAL && <Home DashBoardRequired={false} />}

      <Title>
        {pageType === PageFor.MODAL ? (
          <>View Concession Fee Receipts</>
        ) : (
          <>Concession Reports</>
        )}
      </Title>
      <div
        className={
          pageType === PageFor.GENERAL
            ? "view-concession-report"
            : "view-concession-report__modal"
        }
      >
        <div className="row g-0 view-concession-report__select">
          <div className="col-2">
            <Input
              id="search"
              placeholder="Search..."
              onChange={(e) => setSearchData(e.target.value)}
            />
          </div>
          <div className="col"></div>

          <div className="col-4 flex-end">
            <TextField
              className="fb-form-report__datablock--grid--textfield"
              label="Start Date"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              type="date"
              value={toInputStandardDate(startDate)}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <TextField
              className="fb-form-report__datablock--grid--textfield"
              label="End Date"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              value={toInputStandardDate(endDate)}
              onChange={(e) => setEndDate(e.target.value)}
              type="date"
            />
          </div>
        </div>
        <div className={`view-concession-report__tableblock`}>
          {!students?.length ? (
            <b className="nodata">Sorry, No Vouchers Found</b>
          ) : (
            <StyledDatagrid
              columns={columns}
              rows={rows}
              onRowClick={(params) => {
                if (params.row.concession_id) {
                  setFeeReceiptModal(true);
                  setV_id(params.row.concession_id);
                }
              }}
              disableRowSelectionOnClick
              disableChildrenSorting
              rowHeight={TABLE_ROW_HEIGHT}
              pinnedRows={pinnedRows}
              getCellClassName={getCellClassName}
              hideFooter
            />
          )}
        </div>
        <div className="row g-0">
          <div className="col">
            {pageType === PageFor.GENERAL ? (
              <>
                <Button onClick={hanldeExport} mode="export" />
                <Button mode="back" onClick={() => navigate(-1)} />
              </>
            ) : (
              <Button mode="cancel" onClick={() => setModal(false)} />
            )}
          </div>
          <div className="col-2 view-concession-report__total">
            <StudentTotalCount
              totalCount={data ? data.GetAcctStdConcessions.totalCount : 0}
            />
          </div>
        </div>
      </div>

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={feeReceiptModal}
        style={ViewPickedSubjectModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Title>Concession Given for Ledgers</Title>
            <div className={`view-fee-concession-ledgers__tableblock `}>
              <StyledDatagrid
                columns={columnsConcessionGivenForLedgers}
                rows={concessionGivenForLedgersRows}
                disableRowSelectionOnClick
                disableChildrenSorting
                rowHeight={TABLE_ROW_HEIGHT}
                hideFooter
              />
            </div>
            <Button
              mode="cancel"
              onClick={() => setFeeReceiptModal(!feeReceiptModal)}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setFeeReceiptModal(!feeReceiptModal)}
            />
          </div>
        </div>
      </Modal>

      <MessageModal
        handleClose={() => setMessage(emptyMessageType)}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
      <LoadingModal flag={loading ?? exportLoading} />
    </>
  );
};

export default ConcessionView;
