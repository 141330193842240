import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Home from "../Home/Index";
import { Button } from "../../../stories/Button/Button";
import Input from "../../../components/common/Input/Index";
import { Label } from "../../../stories/Label/Label";
import { Title } from "../../../stories/Title/Title";
import { TableHeaderProps } from "../../../Types/Tables";
import { FineSlabTitleProps } from "../../../Types/Titles";
import EditProfile from "../../../images/EditProfile.svg";
import Delete from "../../../images/Delete.svg";
import { Operation } from "../../../utils/Enum.types";
import { Form, Formik } from "formik";
import { LibraryFineSlab } from "../../../utils/validationRules";
import { useLazyQuery, useMutation } from "@apollo/client";
import LoadingModal from "../../../pages/LoadingModal";
import useToken from "../../../customhooks/useToken";
import { useNavigate, useParams } from "react-router-dom";
import { msgType, optionsType } from "../../../utils/Form.types";
import MessageModal from "../../../pages/MessageModal";
import DeleteModal from "../../../pages/DeleteModal";
import {
  AddAcctFineSlab,
  DeleteAcctFineSlabById,
  UpdateAcctFineSlab,
} from "../queries/Fines/mutation";
import {
  GetAcctFineSlabById,
  GetAcctFineSlabByInstId,
} from "../queries/Fines/query";
import {
  GetAcctFineSlabByInstIdData,
  GetAcctFineSlabByInstIdVars,
  GetFineSlabByIdData,
  GetFineSlabByIdVars,
} from "../hooks/useFineSlab";
import useAcctTableJson from "../json/useAcctTableJson";
import useLoggedInUserDetails from "../hooks/useLoggedInUserDetails";
import Fine from "../../../images/CashReceipts.svg";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../styles/AutocompleteListStyles";
import { EMPTY_STRING } from "../../../utils/constants";

const { AccountsTitles } = require("../json/title.json");

export enum FineType {
  PERCENTAGE = "PERCENTAGE",
  PER_DAY = "PER_DAY",
  FLAT = "FLAT",
  RECURRING = "RECURRING",
}

const fineTypeOptions = [
  {
    label: "Percentage wise",
    value: FineType.PERCENTAGE,
  },
  {
    label: "Perday wise",
    value: FineType.PER_DAY,
  },
  {
    label: "Flat",
    value: FineType.FLAT,
  },
  {
    label: "Recurring",
    value: FineType.RECURRING,
  },
];
const percentWiseOptions = [
  { label: "Monthly", value: "M" },
  { label: "Yearly", value: "Y" },
];

const Index = () => {
  const { InstId } = useParams();
  const { token } = useToken();
  const navigate = useNavigate();
  const { Accounts_Table } = useAcctTableJson();
  const [fineTitle, setFineTitle] = useState("");
  const [operation, setOperation] = useState(Operation.CREATE);
  const [fineId, setFineId] = useState(0);
  const [fineType, setFineType] = useState<optionsType>({
    label: "Perday wise",
    value: FineType.PER_DAY,
  });
  const [perSelected, setPercentSelected] = useState<optionsType | null>(null);
  const [isRepeat, setIsRepeat] = useState(false);
  const [fineSlabArray, setFineSlabArray] = useState<{
    amount: string;
    days: number;
  }>({
    amount: "",
    days: 0,
  });

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  //Modals flag
  const [deleteModal, setDeleteModal] = useState(false);

  //Queries
  const [GetFineSlabData, { data, error }] = useLazyQuery<
    GetAcctFineSlabByInstIdData,
    GetAcctFineSlabByInstIdVars
  >(GetAcctFineSlabByInstId, {
    variables: {
      token,
      inst_id: InstId!,
    },
  });

  const { user_details } = useLoggedInUserDetails();

  const [GetFineSlabById, { loading: FineSlabLoading }] = useLazyQuery<
    GetFineSlabByIdData,
    GetFineSlabByIdVars
  >(GetAcctFineSlabById);
  useEffect(() => {
    if (operation === Operation.UPDATE && fineId && token) {
      GetFineSlabById({
        variables: {
          token,
          id: fineId,
        },
      }).then(({ data }) => {
        if (data) {
          setFineType({
            label: data.node.acct_fine_desc,
            value: data.node.acct_fine_type,
          });
          setFineSlabArray({
            amount: data.node.fine_amt,
            days: data.node.days,
          });
        }
      });
    }
  }, [fineId, operation, token, GetFineSlabById]);
  //Mutations
  const [AddAcctFine, { loading: creationLoading }] = useMutation(
    AddAcctFineSlab,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const [UpdateAcctFine, { loading: updationLoading }] = useMutation(
    UpdateAcctFineSlab,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [DeleteAcctFine, { loading: deletionLoading }] = useMutation(
    DeleteAcctFineSlabById,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const handleClear = () => {
    setFineTitle("");
    setFineSlabArray({
      amount: "",
      days: 0,
    });
    setOperation(Operation.CREATE);
    setPercentSelected(null);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    if (message.operation !== Operation.NONE && message.flag) {
      setOperation(Operation.CREATE);
      handleClear();
    }

    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  const HandleSubmit = () => {
    const input = {
      acct_fine_desc: fineType.label,
      acct_fine_type: fineType.value,
      roi:
        fineType.value === FineType.PERCENTAGE
          ? Number(fineSlabArray.amount)
          : 0,
      days: Number(fineSlabArray.days),
      fine_amt:
        fineType.value === FineType.PERCENTAGE
          ? 0
          : Number(fineSlabArray.amount),
      recurring: isRepeat,
    };

    if (operation === Operation.CREATE) {
      AddAcctFine({
        variables: {
          token,
          inst_id: InstId!,
          input,
          user_details,
        },
        refetchQueries: [
          {
            query: GetAcctFineSlabByInstId,
            variables: {
              token,
              inst_id: InstId!,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            message: "Fineslab Added successfully",
            flag: true,
            operation: Operation.CREATE,
          });
        }
      });
    }
    if (operation === Operation.UPDATE) {
      UpdateAcctFine({
        variables: {
          token,
          id: fineId,
          input,
          inst_id: InstId,
          user_details,
        },
        refetchQueries: [
          {
            query: GetAcctFineSlabByInstId,
            variables: {
              token,
              inst_id: InstId!,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            message: "Fineslab Updated successfully",
            flag: true,
            operation: Operation.UPDATE,
          });
        }
      });
    }
  };

  const HandleDelete = (id: number) => {
    setDeleteModal(!deleteModal);
    DeleteAcctFine({
      variables: {
        token,
        id,
        user_details,
        inst_id: InstId,
      },
      refetchQueries: [
        {
          query: GetAcctFineSlabByInstId,
          variables: {
            inst_id: InstId!,
            token,
          },
        },
        // {
        //   query: GetAcctFineSlabById,
        //   variables: {
        //     token,
        //     id: fineId,
        //   },
        // },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Fineslab Deleted Successfully",
          flag: true,
          operation: Operation.DELETE,
        });
      }
    });
  };

  useEffect(() => {
    if (token && !deletionLoading) {
      GetFineSlabData();
    }
  }, [token, GetFineSlabData, InstId, deletionLoading, message.flag]);
  useEffect(() => {
    if (
      fineType &&
      fineType.value === FineType.PERCENTAGE &&
      perSelected &&
      perSelected.value === "M"
    ) {
      setFineSlabArray((prevValue) => ({
        ...prevValue,
        days: 30,
      }));
    }
    if (
      fineType &&
      fineType.value === FineType.PERCENTAGE &&
      perSelected &&
      perSelected.value === "Y"
    ) {
      setFineSlabArray((prevValue) => ({
        ...prevValue,
        days: 365,
      }));
    }
  }, [fineType, perSelected]);
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>
        {AccountsTitles.Fines.Titles.map(
          (title: FineSlabTitleProps, index: React.Key) => {
            return (
              <React.Fragment key={index}>
                {title.AccountsFineSlab}
              </React.Fragment>
            );
          }
        )}
      </Title>

      <Formik
        initialValues={{ fineTitle }}
        onSubmit={HandleSubmit}
        validationSchema={LibraryFineSlab}
        validateOnChange
        enableReinitialize>
        {(meta) => {
          return (
            <Form className="fine-slab">
              <div className="row g-0 fine-slab__details">
                <div className="col h-100 booktype-left">
                  <div className="fine-slab__details--title">
                    <Title variant="subtitle1">
                      {AccountsTitles.Fines.Titles.map(
                        (title: FineSlabTitleProps, index: React.Key) => {
                          return (
                            <React.Fragment key={index}>
                              {operation === Operation.UPDATE
                                ? title.Update
                                : title.Add}
                            </React.Fragment>
                          );
                        }
                      )}
                    </Title>
                  </div>
                  <div className="fine-slab__frame">
                    <div className="row g-0 fine-slab__frame--autocomplete">
                      {data &&
                      data.GetAcctFineSlabByInstId.length > 0 &&
                      operation !== Operation.UPDATE ? (
                        <div className="fine-slab__frame--fine col-4">
                          <img src={Fine} alt="" />
                          <b>
                            {data.GetAcctFineSlabByInstId.length
                              ? data.GetAcctFineSlabByInstId[0].acct_fine_desc
                              : EMPTY_STRING}
                          </b>
                        </div>
                      ) : (
                        <div className="col-4">
                          <LabeledAutocomplete
                            className={labelClasses.inputRoot}
                            options={fineTypeOptions}
                            openOnFocus
                            onChange={(e, newValue) => {
                              setFineType(newValue as optionsType);
                            }}
                            value={fineType}
                            forcePopupIcon
                            disableClearable
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                className={labelClasses.formControlRoot}
                                label="Fine type"
                                slotProps={{
                                  inputLabel: {
                                    shrink: true,
                                  },
                                }}
                              />
                            )}
                          />
                        </div>
                      )}
                    </div>
                    <div className="fine-slab__frame--block">
                      {fineType.value === FineType.RECURRING && (
                        <div className="fine-slab__frame--repeat">
                          <Checkbox
                            id="repeat"
                            checked={isRepeat}
                            onChange={() => setIsRepeat(!isRepeat)}
                          />
                          <label htmlFor="repeat">Repeat</label>
                        </div>
                      )}
                      {fineType.value === FineType.PERCENTAGE && (
                        <div className="col-4">
                          <Label>Choose Type</Label>
                          <LabeledAutocomplete
                            className={labelClasses.inputRoot}
                            options={percentWiseOptions}
                            openOnFocus
                            onChange={(e, newValue) => {
                              setPercentSelected(newValue as optionsType);
                            }}
                            value={perSelected}
                            forcePopupIcon
                            disableClearable
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                className={labelClasses.formControlRoot}
                                slotProps={{
                                  inputLabel: {
                                    shrink: true,
                                  },
                                }}
                              />
                            )}
                          />
                        </div>
                      )}
                      <div className="fine-slab__frame--block">
                        <div className="fine-slab__formlabels">
                          <div className="fine-slab__formlabels--columns">
                            <Label>Days Beyond the Due Date</Label>
                            <Input
                              name="No of Days"
                              type="number"
                              disabled={!operation}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                const updatedDays = Number(e.target.value);
                                setFineSlabArray((prevValue) => ({
                                  ...prevValue,
                                  days: updatedDays,
                                }));
                              }}
                              values={fineSlabArray.days}
                            />
                          </div>
                          <div className="fine-slab__formlabels--columns--amountperday">
                            <Label>
                              {fineType.value === FineType.PERCENTAGE
                                ? "Percentage"
                                : fineType.value === FineType.PER_DAY
                                ? "Amount / Day"
                                : "Amount"}
                            </Label>
                            <Input
                              name="Amount / Day"
                              type="number"
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                const updatedAmount = e.target.value;
                                setFineSlabArray((prevValue) => ({
                                  ...prevValue,
                                  amount: updatedAmount,
                                }));
                              }}
                              values={fineSlabArray.amount}
                              disabled={!operation}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col h-100 booktype-right">
                  <div className="fine-slab__details--title">
                    <Title variant="subtitle1">
                      {AccountsTitles.Fines.Titles.map(
                        (title: FineSlabTitleProps, index: React.Key) => {
                          return (
                            <React.Fragment key={index}>
                              {title.List}
                            </React.Fragment>
                          );
                        }
                      )}
                    </Title>
                  </div>
                  <div className="fine-slab__frame--tableblock">
                    {error ? (
                      <b className="nodata">{error.message}</b>
                    ) : data && data.GetAcctFineSlabByInstId.length > 0 ? (
                      <TableContainer className="fine-slab__frame--table">
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow>
                              {Accounts_Table.Fines.Table_Headers.map(
                                (th: TableHeaderProps, index: React.Key) => {
                                  const modifiedLabel =
                                    th.labelName === "Amount" &&
                                    data.GetAcctFineSlabByInstId[0]
                                      .acct_fine_type === FineType.PERCENTAGE
                                      ? "Percentage"
                                      : th.labelName;

                                  return (
                                    <TableCell
                                      key={index}
                                      className={th.className}>
                                      {modifiedLabel}
                                    </TableCell>
                                  );
                                }
                              )}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell
                                id="td-center"
                                className="fine-slab__frame--table--slno">
                                1
                              </TableCell>
                              <TableCell>
                                {data.GetAcctFineSlabByInstId.length
                                  ? data.GetAcctFineSlabByInstId[0]
                                      .acct_fine_desc
                                  : EMPTY_STRING}
                              </TableCell>
                              <TableCell>
                                {data.GetAcctFineSlabByInstId.length
                                  ? data.GetAcctFineSlabByInstId[0].days
                                  : 0}
                              </TableCell>
                              <TableCell>
                                {data.GetAcctFineSlabByInstId.length
                                  ? data.GetAcctFineSlabByInstId[0]
                                      .acct_fine_type === FineType.PERCENTAGE
                                    ? `${data.GetAcctFineSlabByInstId[0].roi} %`
                                    : data.GetAcctFineSlabByInstId[0].fine_amt
                                  : EMPTY_STRING}
                              </TableCell>
                              <TableCell
                                id="td-center"
                                className="fine-slab__frame--table--actions">
                                <img
                                  src={EditProfile}
                                  alt="/"
                                  onClick={() => {
                                    setOperation(Operation.UPDATE);
                                    setFineId(
                                      data.GetAcctFineSlabByInstId[0].id
                                    );
                                  }}
                                />
                                &nbsp;
                                <img
                                  src={Delete}
                                  alt="/"
                                  onClick={() => {
                                    setFineId(
                                      data.GetAcctFineSlabByInstId[0].id
                                    );
                                    setDeleteModal(!deleteModal);
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <b className="nodata"> Sorry, no results</b>
                    )}
                  </div>
                </div>
              </div>
              <div className="button-left">
                <Button mode="save" type="submit" />
                <Button
                  mode="clear"
                  type="button"
                  onClick={() => {
                    handleClear();
                  }}
                />

                <Button mode="back" onClick={() => navigate(-1)} />
              </div>
            </Form>
          );
        }}
      </Formik>
      <LoadingModal
        flag={
          creationLoading ||
          updationLoading ||
          deletionLoading ||
          FineSlabLoading
        }
      />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
        id={fineId}
      />
    </>
  );
};

export default Index;
