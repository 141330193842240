import { useContext, useState } from "react";
// eslint-disable-next-line
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import {
  AccountBookTypes,
  BankOrCash,
  EduateModule,
  LicenseTypes,
  ModuleName,
  NonDemand,
  Operation,
  PageFor,
  SortBy,
  StudentAcctReportType,
  StudentListFor,
  StudentsWhoLeftInstitutionTypes,
  VoucherPageType,
} from "../utils/Enum.types";
import CreateAcctStudent from "../Modules/Accounts/common/CreateAcctStudent";
import AcctUserLogin from "../Modules/Accounts/Users/Index";
import DepositReceipt from "../Modules/Accounts/Deposits/Receipt";
import DepositAdjustments from "../Modules/Accounts/Deposits/Adjustments";
import DepositRefund from "../Modules/Accounts/Deposits/Refunds";
import TrailBalance from "../Modules/Accounts/Reports/TrialBalance/Index";
import PaymentVoucherRegister from "../Modules/Accounts/Reports/PaymentAndGeneralRegister/Index";
import GeneralReceiptVoucherRegister from "../Modules/Accounts/Reports/PaymentAndGeneralRegister/Index";
import ForIndividualStudent from "../Modules/Accounts/FeeDemand/AdditionalFee/ForIndividualStudent";
import ForGroupOfStudents from "../Modules/Accounts/FeeDemand/AdditionalFee/ForGroupOfStudents";
import TableViewDemandSummary from "../Modules/Accounts/FeeDemand/DemandSummary/TableView";

import BalanceSheet from "../Modules/Accounts/Reports/General/BalanceSheet/Index";
import RAndP from "../Modules/Accounts/Reports/General/R&P/Index";
import IAndE from "../Modules/Accounts/Reports/General/I&E/Index";
import BankReconcilation from "../Modules/Accounts/Reports/BankReconcilation&FeeCollected/Index";
import DemandReceipt from "../Modules/Accounts/Reports/FeeReceiptBook/Index";
import AllReceiptsBook from "../Modules/Accounts/Reports/FeeReceiptBook/Index";
import ReceiptGroupLedegerReorder from "../Modules/Accounts/GroupLedger/Reorder";
import PaymentGroupLedgerReorder from "../Modules/Accounts/GroupLedger/Reorder";
import DeleteDemand from "../Modules/Accounts/StudentDetails/DeleteStudent/DeleteStudent";

import BankAndCashDailyreport from "../Modules/Accounts/Reports/DailyReports/BankAndCashReport";
import LedgerWiseDailyReport from "../Modules/Accounts/Reports/DailyReports/LedgerWiseReport";
import PermissionLetter from "../Modules/Accounts/StudentDetails/LateFeePayment/PermissionLetter";
import DepositDetails from "../Modules/Accounts/Deposits/DepositDetails";
import FeeReceiptForEnquiry from "../Modules/Accounts/StudentDetails/FeeReceipt/Enquirystudentreceipt";
import AccountsHome from "../Modules/Accounts/Home/Index";
import RefundAcademicFee from "../Modules/Accounts/StudentDetails/RefundAcademicFee/Index";
import ConcessionFee from "../Modules/Accounts/Concession/Index";
import AcctLedgerSummary from "../Modules/Accounts/Reports/DemandSummary/Index";
import StudentCompleteFeeReceipt from "../Modules/Accounts/StudentDetails/CompleteFeeReceipt/Index";
import DeleteFeeDemandForParticularStudent from "../Modules/Accounts/FeeDemand/DeleteFeeDemandForParticularStudent";
import DeleteParticularFeeInFeeDemand from "../Modules/Accounts/FeeDemand/DeleteParticularFeeInFeeDemand";
// import Voucher from "../Modules/Accounts/Voucher/Index";
import InterchangeReceipt from "../Modules/Accounts/StudentDetails/FeeReceipt/InterchangeReceipt";
import BankBook from "../Modules/Accounts/Reports/AccountLedgerBook/Index";
import CashBalanceReport from "../Modules/Accounts/Reports/DailyReports/CashBalanceReport";
import CashFlowDetails from "../Modules/Accounts/Reports/DailyReports/CashFlowDetails";
import GroupLedger from "../Modules/Accounts/GroupLedger/Index";
import AccountingLedger from "../Modules/Accounts/AccountingLedger/Index";
import AccountsFineSlab from "../Modules/Accounts/Fines/Index";
import FeeLedger from "../Modules/Accounts/FeeLedger/Index";
import FeeDemand from "../Modules/Accounts/FeeDemand/Index";
import DemandFeePayable from "../Modules/Accounts/StudentDetails/DemandFeePayable/Index";
import StudentFeeReceipt, {
  PayerType,
} from "../Modules/Accounts/StudentDetails/FeeReceipt/Index";
import ManagementFeeReceipt from "../Modules/Accounts/StudentDetails/FeeReceipt/captationfee/CollectCaptationFee";
import ManagementNonDemandFeeReceipt from "../Modules/Accounts/StudentDetails/FeeReceipt/captationfee/NonDemandCaptationFee";
import ChangeOfDepartment from "../Modules/Master/Student/ChangeOfDepartment/Index";
import NonDemandFeePayable from "../Modules/Accounts/StudentDetails/NonDemandFeePayable/Index";
import TransportNonDemand from "../Modules/Accounts/StudentDetails/TransportNondemand/Index";
import StudentListAccounts from "../Modules/Accounts/common/StudentList";
import Reports from "../Modules/Accounts/Reports/BalanceReports/Reports";
import BillBook from "../Modules/Accounts/BillBook/Index";
import { SwConfigQueryType } from "../Modules/HR/enums/Enum.types";
import Configurations from "../Modules/Configurations/PerModuleConfiguration";
import useEduateModuleConfig from "../customhooks/useModulesConfiguartion";
import Page404 from "../pages/Page404";
import useUserRightsByEmpId from "../Modules/UserRights/hooks/useUserRightsByEmpId";
import DemandSummary from "../Modules/Accounts/FeeDemand/DemandSummary/Tree";
import ScholarshipReceiptList from "../Modules/Accounts/Scholarships/List";
import ScholarshipReceipt from "../Modules/Accounts/Scholarships/AssignNewReceipt";
import {
  AcctStudentType,
  VoucherQueryTypes,
} from "../Modules/Accounts/common/Enum.types";
import NewVoucher from "../Modules/Accounts/Voucher/NewVoucher";
import { AppContext } from "../context/context";
import StudentLedgerWiseReport from "../Modules/Accounts/Reports/StudentLedgerWiseReport";
import ConcessionView from "../Modules/Accounts/Concession/ConcessionView";
import ViewRefundedList from "../Modules/Accounts/StudentDetails/RefundAcademicFee/ViewRefundedList";
import Group from "../Modules/Accounts/PurchaseEntry/Masters/Group";
import Category from "../Modules/Accounts/PurchaseEntry/Masters/Category";
import UnitOfMeasurement from "../Modules/Accounts/PurchaseEntry/Masters/UnitOfMeasurement";
import Type from "../Modules/Accounts/PurchaseEntry/Masters/Type";
import Details from "../Modules/Accounts/PurchaseEntry/Details/Index";
import Invoice from "../Modules/Accounts/PurchaseEntry/Invoice/Index";
import MkckVoucherList from "../Modules/Accounts/Voucher/MakerAndChecker/MkckVoucherList";
import OpeningBalance from "../Modules/Accounts/Deposits/OpeningBalance";
import { OtherAccountLedgerBooks } from "../utils/types";
import OnlineTransaction from "../Modules/Accounts/OnlineTransaction/Index";
import ChairmanDashBoard from "../Modules/Accounts/Dashboard/CasheirDashBoard";
import CreateChallan from "../Modules/Accounts/StudentDetails/Challan/CreateChallan";
import NonDemandChallan from "../Modules/Accounts/StudentDetails/Challan/NonDemandChallan";
import ApplicationFeeChallan from "../Modules/Accounts/StudentDetails/Challan/ApllicationFeeChallan";
import Challan from "../Modules/Accounts/StudentDetails/Challan/Index";
import PassedOutStudentList from "../Modules/Accounts/common/PassedOutStudentList";
import Miscellaneous from "../Modules/Accounts/Miscellaneous/Index";
import Add from "../Modules/Accounts/Miscellaneous/Add";
import MiscActivities from "../Modules/Accounts/MiscActivities/Index";
import AllYearFeeDetails from "../Modules/Accounts/Reports/AllYearFeeDetails/Index";
import List from "../Modules/Accounts/Agents/List";
import UpdateAgent from "../Modules/Accounts/Agents/UpdateAgent";
import StudentAssociation from "../Modules/Accounts/Agents/StudentAssociation/StudentAssociation";
import InstitutionLevel from "../Modules/Master/Reports/InstitutionLevel/Index";
import ImportFeeReceipt from "../Modules/Accounts/StudentDetails/FeeReceipt/ImportFeeReceipt";
import AllYearsLedgerSummary from "../Modules/Accounts/Reports/AllYearsLedgerSummary/Index";
import LedgerWiseBalancereport from "../Modules/Accounts/Reports/StudentLedgerWiseBalanceReport";
import ImportStudentFeeDEmand from "../Modules/Master/Student/ImportData/ImportStudentFeeDemand";
import AdjustDepositFee from "../Modules/Accounts/StudentDetails/Challan/AdjustDepositFee";
import MakeFinYearActive from "../Modules/Accounts/MakeFinYearActive";

const AccountingRoutes = () => {
  // eslint-disable-next-line
  const [modalFlag, setModalFlag] = useState(false);
  const { state } = useContext(AppContext);
  const { error } = useEduateModuleConfig(EduateModule.ACCOUNTS);
  const { USE_ACCOUNTS } = useUserRightsByEmpId();
  return (
    <Routes>
      {error || (!USE_ACCOUNTS.flag && !state.claims?.OWNER) ? (
        <Route path="*" element={<Page404 />} />
      ) : (
        <>
          {state.claims?.OWNER ? (
            <Route path="/" element={<AccountsHome DashBoardRequired />} />
          ) : (
            <>
              <Route
                path="/onlinetransaction"
                element={<OnlineTransaction />}
              />
              <Route
                path="/challanList"
                element={<Challan pageType={PageFor.GENERAL} />}
              />
              <Route path="/" element={<AccountsHome DashBoardRequired />} />
              <Route
                path="/studentlist"
                element={
                  <StudentListAccounts
                    pageType={PageFor.GENERAL}
                    studentListFor={StudentListFor.ACCOUNTS}
                    queryType={StudentAcctReportType.GENERAL}
                    setStudentModal={setModalFlag}
                  />
                }
              />
              <Route
                path="/passedOutList"
                element={
                  <PassedOutStudentList
                    pageType={PageFor.GENERAL}
                    studentListFor={StudentListFor.ACCOUNTS}
                    queryType={StudentAcctReportType.GENERAL}
                    setStudentModal={setModalFlag}
                  />
                }
              />
              <Route
                path="/enquirystudentreceipt"
                element={
                  <FeeReceiptForEnquiry
                    pageType={PageFor.GENERAL}
                    setFeeReceiptModal={setModalFlag}
                    studentSelected={false}
                  />
                }
              />
              <Route path="/groupledger" element={<GroupLedger />} />
              <Route
                path="/groupledger/receiptreoder"
                element={
                  <ReceiptGroupLedegerReorder
                    sortBy={SortBy.GR_LDGR_RCPT_INDEX_POSITION}
                  />
                }
              />
              <Route
                path="/groupledger/paymentreorder"
                element={
                  <PaymentGroupLedgerReorder
                    sortBy={SortBy.GR_LDGR_PYMT_INDEX_POSITION}
                  />
                }
              />
              <Route
                path="/accountingledger"
                element={<AccountingLedger type={PageFor.GENERAL} />}
              />
              <Route path="/fineslab" element={<AccountsFineSlab />} />
              <Route
                path="/feeledger"
                element={
                  <FeeLedger
                    pageFor={PageFor.GENERAL}
                    setFeeledgerModal={setModalFlag}
                  />
                }
              />

              <Route
                path="/miscActivities"
                element={<MiscActivities type={PageFor.GENERAL} />}
              />
              <Route path="/feedemand" element={<FeeDemand />} />
              <Route path="/billbook" element={<BillBook />} />
              <Route
                path="/demandfeepayable"
                element={<DemandFeePayable pageType={PageFor.GENERAL} />}
              />
              <Route
                path="/demandfeeImport"
                element={<ImportStudentFeeDEmand />}
              />
              <Route
                path="/refundacademicfee"
                element={<RefundAcademicFee pageType={PageFor.GENERAL} />}
              />
              <Route
                path="/feeconcession"
                element={<ConcessionFee pageType={PageFor.GENERAL} />}
              />
              <Route
                path="/nondemandfeepayableacademicalreceipt"
                element={
                  <NonDemandFeePayable
                    type={NonDemand.ROLE}
                    pageType={PageFor.GENERAL}
                    setModalFlag={setModalFlag}
                    selectStudent={true}
                  />
                }
              />
              <Route
                path="/nonDemandChallan"
                element={
                  <NonDemandChallan
                    type={NonDemand.ROLE}
                    pageType={PageFor.GENERAL}
                    setModalFlag={setModalFlag}
                    selectStudent={true}
                  />
                }
              />
              <Route
                path="/adjustdepositfee"
                element={<AdjustDepositFee pageType={PageFor.GENERAL} />}
              />
              <Route
                path="/nonDemandChallanFornonrole"
                element={
                  <NonDemandChallan
                    type={NonDemand.NON_ROLE}
                    pageType={PageFor.GENERAL}
                    setModalFlag={setModalFlag}
                    selectStudent={true}
                  />
                }
              />
              <Route
                path="/challanReceipt"
                element={
                  <CreateChallan
                    pageType={PageFor.GENERAL}
                    setModalFlag={setModalFlag}
                    payer={PayerType.ACCOUNTING}
                    studentSelected={false}
                  />
                }
              />
              <Route
                path="/challanReceiptForEnquiry"
                element={
                  <ApplicationFeeChallan
                    pageType={PageFor.GENERAL}
                    setFeeReceiptModal={setModalFlag}
                    studentSelected={false}
                  />
                }
              />
              <Route
                path="/transportnondemand"
                element={
                  <TransportNonDemand
                    pageType={PageFor.GENERAL}
                    setModalFlag={setModalFlag}
                  />
                }
              />
              <Route
                path="/nondemandfeepayablenonrole"
                element={
                  <NonDemandFeePayable
                    type={NonDemand.NON_ROLE}
                    setModalFlag={setModalFlag}
                    pageType={PageFor.GENERAL}
                    selectStudent={true}
                  />
                }
              />
              <Route
                path="/studentfeereceipt"
                element={
                  <StudentFeeReceipt
                    pageType={PageFor.GENERAL}
                    setModalFlag={setModalFlag}
                    payer={PayerType.ACCOUNTING}
                    studentSelected={false}
                  />
                }
              />
              <Route
                path="/miscellaneous"
                element={
                  <Miscellaneous
                    pageType={PageFor.GENERAL}
                    setModalFlag={setModalFlag}
                  />
                }
              />
              <Route
                path="/miscellaneous/add"
                element={
                  <Add pageType={PageFor.GENERAL} setModalFlag={setModalFlag} />
                }
              />
              <Route
                path="/managementfeereceipt"
                element={
                  <ManagementFeeReceipt
                    pageType={PageFor.GENERAL}
                    setModalFlag={setModalFlag}
                  />
                }
              />
              <Route
                path="/managementnondemandfeereceipt"
                element={
                  <ManagementNonDemandFeeReceipt
                    pageType={PageFor.GENERAL}
                    setModalFlag={setModalFlag}
                  />
                }
              />
              <Route
                path="/changeofdepartment"
                element={<ChangeOfDepartment pageFor={LicenseTypes.ACCOUNTS} />}
              />
              <Route
                path="/unknownbanktransaction"
                element={
                  <ScholarshipReceiptList
                    pageType={PageFor.GENERAL}
                    anonymous_std_type={AcctStudentType.AnonymousRecolied}
                    setModalFlag={setModalFlag}
                  />
                }
              />
              <Route
                path="/unknownbanktransaction/add"
                element={
                  <ScholarshipReceipt
                    studentType={StudentAcctReportType.ANONYMOUS_STUDENT}
                    pageType={PageFor.GENERAL}
                    setModalFlag={setModalFlag}
                  />
                }
              />
              <Route path="/makercheckerlist" element={<MkckVoucherList />} />
              <Route
                path="/reports/ledgerwisestudentreport"
                element={<StudentLedgerWiseReport />}
              />
              <Route
                path="/reports/ledgerwisestdBalancereport"
                element={<LedgerWiseBalancereport />}
              />
              <Route
                path="/reports/allyearfeedetails"
                element={<AllYearFeeDetails />}
              />
              <Route
                path="/latefeepayment/permissionletter"
                element={<PermissionLetter />}
              />
              <Route
                path="/socialwelfare"
                element={
                  <ScholarshipReceiptList
                    anonymous_std_type={AcctStudentType.SocialWelfare}
                    pageType={PageFor.GENERAL}
                    setModalFlag={setModalFlag}
                  />
                }
              />
              <Route
                path="/socialwelfare/add"
                element={
                  <ScholarshipReceipt
                    studentType={StudentAcctReportType.SOCIAL_WELFARE_STUDENT}
                    pageType={PageFor.GENERAL}
                    setModalFlag={setModalFlag}
                  />
                }
              />
              <Route
                path="/interchangefeereceipt"
                element={
                  <InterchangeReceipt
                    pageType={PageFor.GENERAL}
                    setModalFlag={setModalFlag}
                  />
                }
              />
              <Route path="/importFeeReceipt" element={<ImportFeeReceipt />} />
              <Route
                path="/feedemand/deletefeedemand"
                element={<DeleteFeeDemandForParticularStudent />}
              />
              <Route
                path="/feedemand/deleteParticularfee"
                element={<DeleteParticularFeeInFeeDemand />}
              />
              <Route
                path="/studentcompletefeereceipt"
                element={
                  <StudentCompleteFeeReceipt PageType={PageFor.GENERAL} />
                }
              />
              <Route
                path="/vouchers/*"
                element={
                  <>
                    <Routes>
                      <Route
                        path="/paymentvoucher"
                        element={
                          <NewVoucher
                            type={VoucherPageType.PAYMENT_VOUCHER}
                            operation={Operation.CREATE}
                            pageType={PageFor.GENERAL}
                            setModalFlag={setModalFlag}
                          />
                        }
                      />
                      <Route
                        path="/newvoucher"
                        element={
                          <NewVoucher
                            type={VoucherPageType.PAYMENT_VOUCHER}
                            operation={Operation.CREATE}
                            pageType={PageFor.GENERAL}
                            setModalFlag={setModalFlag}
                          />
                        }
                      />
                      <Route
                        path="/journalvoucher"
                        element={
                          <NewVoucher
                            type={VoucherPageType.JOURNAL}
                            operation={Operation.CREATE}
                            pageType={PageFor.GENERAL}
                            setModalFlag={setModalFlag}
                          />
                        }
                      />
                      <Route
                        path="/receiptvoucher"
                        element={
                          <NewVoucher
                            type={VoucherPageType.RECEIPT_VOUCHER}
                            operation={Operation.CREATE}
                            pageType={PageFor.GENERAL}
                            setModalFlag={setModalFlag}
                          />
                        }
                      />
                      <Route
                        path="/contravoucher"
                        element={
                          <NewVoucher
                            type={VoucherPageType.CONTRA_VOUCHER}
                            operation={Operation.CREATE}
                            pageType={PageFor.GENERAL}
                            setModalFlag={setModalFlag}
                          />
                        }
                      />
                    </Routes>
                  </>
                }
              />
              {/* to be removed */}
              {/* <Route
                path="/accountledgerbooks/accountingledger"
                element={
                  <BankBook type={BankOrCash.CASH} pageType={PageFor.GENERAL} />
                }
              /> */}
              <Route
                path="/reports/*"
                element={
                  <Routes>
                    <Route
                      path="/deposit"
                      element={
                        <InstitutionLevel
                          reportType={VoucherQueryTypes.ACCT_STD_DEPOSIT_REPORT}
                          setModalFlag={setModalFlag}
                        />
                      }
                    />
                    <Route
                      path="/deposit/balance"
                      element={
                        <InstitutionLevel
                          reportType={
                            VoucherQueryTypes.ACCT_STD_DEPOSIT_BALANCE_REPORT
                          }
                          setModalFlag={setModalFlag}
                        />
                      }
                    />
                    <Route
                      path="/refund"
                      element={
                        <InstitutionLevel
                          reportType={VoucherQueryTypes.ACCT_STD_REFUND_REPORT}
                          setModalFlag={setModalFlag}
                        />
                      }
                    />
                    <Route
                      path="/concession"
                      element={
                        <InstitutionLevel
                          reportType={
                            VoucherQueryTypes.ACCT_STD_CONCESSION_REPORT
                          }
                          setModalFlag={setModalFlag}
                        />
                      }
                    />
                    <Route
                      path="/yearOfJoining"
                      element={
                        <InstitutionLevel
                          reportType={VoucherQueryTypes.ACCT_STD_BY_JOINING_YR}
                          setModalFlag={setModalFlag}
                        />
                      }
                    />
                    <Route
                      path="/yearOfJoiningLedgerWise"
                      element={
                        <InstitutionLevel
                          reportType={
                            VoucherQueryTypes.ACCT_STD_BY_JOINING_YR_LEDGER_WISE_REPORT
                          }
                          setModalFlag={setModalFlag}
                        />
                      }
                    />
                    <Route
                      path="/feeBalanceBydate"
                      element={
                        <InstitutionLevel
                          reportType={
                            VoucherQueryTypes.ACCT_STD_FEE_BALANCE_REPORT_ON_GIVEN_DATE
                          }
                          setModalFlag={setModalFlag}
                        />
                      }
                    />
                    <Route
                      path="/stdLedgerWise"
                      element={
                        <InstitutionLevel
                          reportType={
                            VoucherQueryTypes.ACCT_STD_FEE_LEDGER_WISE_REPORT
                          }
                          setModalFlag={setModalFlag}
                        />
                      }
                    />
                    <Route
                      path="/stdLedgerWiseBalance"
                      element={
                        <InstitutionLevel
                          reportType={
                            VoucherQueryTypes.ACCT_STD_FEE_ONLY_BALANCE_DEMANDS
                          }
                          setModalFlag={setModalFlag}
                        />
                      }
                    />
                    <Route
                      path="/todaysLedgerWiseDue"
                      element={
                        <InstitutionLevel
                          reportType={
                            VoucherQueryTypes.ACCT_STD_FEE_DUE_BALANCE_ON_GIVEN_DATE
                          }
                          setModalFlag={setModalFlag}
                        />
                      }
                    />
                    <Route
                      path="/fee/*"
                      element={
                        <Routes>
                          <Route
                            path="/balance"
                            element={
                              <InstitutionLevel
                                reportType={
                                  VoucherQueryTypes.ACCT_STD_FEE_BALANCE_REPORT
                                }
                                setModalFlag={setModalFlag}
                              />
                            }
                          />
                          <Route
                            path="/voucher/:ledgerId"
                            element={
                              <InstitutionLevel
                                reportType={
                                  VoucherQueryTypes.ACCT_STD_FEE_BALANCE_REPORT
                                }
                                setModalFlag={setModalFlag}
                              />
                            }
                          />
                          <Route
                            path="/completely_paid"
                            element={
                              <InstitutionLevel
                                reportType={
                                  VoucherQueryTypes.ACCT_STD_FEE_COMPLETELY_PAID_REPORT
                                }
                                setModalFlag={setModalFlag}
                              />
                            }
                          />
                          <Route
                            path="/partially_paid"
                            element={
                              <InstitutionLevel
                                reportType={
                                  VoucherQueryTypes.ACCT_STD_FEE_PARTIALLY_PAID_REPORT
                                }
                                setModalFlag={setModalFlag}
                              />
                            }
                          />
                          <Route
                            path="/not_paid"
                            element={
                              <InstitutionLevel
                                reportType={
                                  VoucherQueryTypes.ACCT_STD_FEE_NOT_PAID_REPORT
                                }
                                setModalFlag={setModalFlag}
                              />
                            }
                          />
                          <Route
                            path="/by_agent"
                            element={
                              <InstitutionLevel
                                reportType={
                                  VoucherQueryTypes.ACCT_STD_FEE_BY_AGENT_REPORT
                                }
                                setModalFlag={setModalFlag}
                              />
                            }
                          />
                        </Routes>
                      }
                    />
                  </Routes>
                }
              />
              <Route
                path="/accountledgerbooks/accountingledger/bank"
                element={
                  <BankBook type={BankOrCash.BANK} pageType={PageFor.GENERAL} />
                }
              />
              <Route
                path="/accountledgerbooks/accountingledger/cash"
                element={
                  <BankBook type={BankOrCash.CASH} pageType={PageFor.GENERAL} />
                }
              />
              <Route
                path="/accountledgerbooks/accountingledger/registers"
                element={
                  <BankBook
                    type={OtherAccountLedgerBooks.REGISTER}
                    pageType={PageFor.GENERAL}
                  />
                }
              />
              <Route
                path="/accountledgerbooks/accountingledger/generalledgers"
                element={
                  <BankBook
                    type={BankOrCash.OTHERS}
                    pageType={PageFor.GENERAL}
                  />
                }
              />
              <Route
                path="/accountledgerbooks/accountingledger/feeledgers"
                element={
                  <BankBook type={BankOrCash.STD} pageType={PageFor.GENERAL} />
                }
              />
              <Route
                path="/accountledgerbooks/groupledgers"
                element={
                  <BankBook
                    type={OtherAccountLedgerBooks.GROUP_LEDGER}
                    pageType={PageFor.GENERAL}
                  />
                }
              />
              <Route
                path="/accountledgerbooks/registers"
                element={
                  <BankBook type={BankOrCash.CASH} pageType={PageFor.GENERAL} />
                }
              />
              <Route
                path="/bankregisters"
                element={
                  <BankBook type={BankOrCash.CASH} pageType={PageFor.GENERAL} />
                }
              />
              <Route
                path="/groupledgerbooks"
                element={
                  <BankBook type={BankOrCash.CASH} pageType={PageFor.GENERAL} />
                }
              />
              <Route
                path="/reports/bankreconcilation"
                element={
                  <BankReconcilation
                    type={AccountBookTypes.BANK_RECONCILATION}
                  />
                }
              />
              <Route
                path="/reports/feecollected"
                element={
                  <BankReconcilation type={AccountBookTypes.FEE_COLLECTED} />
                }
              />
              <Route
                path="/receiptandpaymentaccount"
                element={<RAndP pageType={PageFor.GENERAL} />}
              />
              <Route
                path="/incomeandexpenditureaccount"
                element={<IAndE pageType={PageFor.GENERAL} />}
              />
              <Route
                path="/balancesheet"
                element={<BalanceSheet pageType={PageFor.GENERAL} />}
              />
              <Route
                path="/dailyfeecollected/feecollected"
                element={
                  <BankAndCashDailyreport
                    pageType={PageFor.GENERAL}
                    setModalFlag={setModalFlag}
                  />
                }
              />
              <Route
                path="/dailyfeecollected/ledgerwise"
                element={<LedgerWiseDailyReport />}
              />
              <Route
                path="/cashbalancereports"
                element={<CashBalanceReport />}
              />
              <Route path="/cashflowdetails" element={<CashFlowDetails />} />
              <Route
                path="/feebalancereport/balancereport"
                element={
                  <Reports reportType={StudentAcctReportType.DEMAND_RAISED} />
                }
              />
              <Route
                path="/feebalancereport/completelypaid"
                element={
                  <Reports reportType={StudentAcctReportType.COMPLETELY_PAID} />
                }
              />
              <Route
                path="/feebalancereport/partiallypaid"
                element={
                  <Reports reportType={StudentAcctReportType.PARTIALLY_PAID} />
                }
              />
              <Route
                path="/concessionreports"
                element={
                  <ConcessionView
                    pageType={PageFor.GENERAL}
                    setModal={setModalFlag}
                  />
                }
              />
              <Route
                path="/refundreports"
                element={
                  <ViewRefundedList
                    pageType={PageFor.GENERAL}
                    setModal={setModalFlag}
                  />
                }
              />
              <Route
                path="/feebalancereport/notpaid"
                element={
                  <Reports
                    reportType={StudentAcctReportType.DEMAND_RAISED_NOT_PAID}
                  />
                }
              />
              <Route
                path="/feebalancereport/agent"
                element={
                  <Reports reportType={StudentAcctReportType.BY_AGENT} />
                }
              />
              <Route
                path="/deposit/*"
                element={
                  <Routes>
                    <Route
                      path="/receipt"
                      element={
                        <DepositReceipt
                          pageType={PageFor.GENERAL}
                          setModalFlag={setModalFlag}
                        />
                      }
                    />
                    <Route
                      path="/adjustment"
                      element={
                        <DepositAdjustments
                          pageType={PageFor.GENERAL}
                          setModalFlag={setModalFlag}
                          selectStudent={true}
                        />
                      }
                    />
                    <Route
                      path="/refunds"
                      element={
                        <DepositRefund
                          pageType={PageFor.GENERAL}
                          selectStudent={true}
                          setModalFlag={setModalFlag}
                        />
                      }
                    />
                    <Route
                      path="/details"
                      element={
                        <DepositDetails
                          pageType={PageFor.GENERAL}
                          setModalFlag={setModalFlag}
                        />
                      }
                    />
                    <Route
                      path="/ob"
                      element={
                        <OpeningBalance
                          pageType={PageFor.GENERAL}
                          setModalFlag={setModalFlag}
                        />
                      }
                    />
                  </Routes>
                }
              />
              <Route
                path="/agents/*"
                element={
                  <Routes>
                    <Route
                      path="/list"
                      element={
                        <List
                          pageType={PageFor.GENERAL}
                          setModal={setModalFlag}
                        />
                      }
                    />
                    <Route
                      path="/update"
                      element={<UpdateAgent pageType={PageFor.GENERAL} />}
                    />
                    <Route path="/associate" element={<StudentAssociation />} />
                  </Routes>
                }
              />
              <Route
                path="/feedemand/additionalfee/forindividualstudent"
                element={<ForIndividualStudent />}
              />
              <Route
                path="/feedemand/additionalfee/forgroupofstudents"
                element={<ForGroupOfStudents />}
              />
              <Route
                path="/demandsummary"
                element={
                  <DemandSummary
                    type={PageFor.GENERAL}
                    setModalFlag={setModalFlag}
                  />
                }
              />
              <Route
                path="/demandsummary/table"
                element={
                  <TableViewDemandSummary
                    pageType={PageFor.GENERAL}
                    setModalFlag={setModalFlag}
                  />
                }
              />
              <Route path="/reports/trialbalance" element={<TrailBalance />} />
              <Route
                path="/reports/ledgersummary"
                element={<AcctLedgerSummary />}
              />
              <Route
                path="/reports/allYearsLedgersummary"
                element={<AllYearsLedgerSummary />}
              />
              <Route
                path="/reports/paymentvoucherregister"
                element={
                  <PaymentVoucherRegister
                    type={VoucherQueryTypes.PAYMENT_VOUCHERS}
                    pageType={PageFor.GENERAL}
                  />
                }
              />
              <Route path="/createstudent" element={<CreateAcctStudent />} />

              <Route
                path="/reports/generalreceiptvoucherregister"
                element={
                  <GeneralReceiptVoucherRegister
                    type={VoucherQueryTypes.RECEIPTS_VOUCHERS}
                    pageType={PageFor.GENERAL}
                  />
                }
              />
              <Route
                path="/reports/feereceiptbook/demandreceipt"
                element={
                  <DemandReceipt
                    queryType={VoucherQueryTypes.STUDENT_DEMAND_RECEIPTS}
                  />
                }
              />
              <Route
                path="/reports/feereceiptbook/showallreceipts"
                element={
                  <AllReceiptsBook
                    queryType={
                      VoucherQueryTypes.STUDENT_ALL_RECEIPTS_WITHOUT_REFUNDS
                    }
                  />
                }
              />
              <Route
                path="/reports/feereceiptbook/feeCollectedDaily"
                element={
                  <AllReceiptsBook
                    queryType={
                      VoucherQueryTypes.STUDENT_ALL_RECEIPTS_WITHOUT_REFUNDS
                    }
                  />
                }
              />
              <Route
                path="/reports/feereceiptbook/nondemandreceipts"
                element={
                  <AllReceiptsBook
                    queryType={VoucherQueryTypes.STD_NON_DEMAND_RECEIPTS}
                  />
                }
              />
              <Route
                path="/reports/feereceiptbook/aluminireceipts"
                element={
                  <AllReceiptsBook
                    queryType={VoucherQueryTypes.PASSOUT_STD_VOUCHERS}
                  />
                }
              />
              <Route
                path="/reports/feereceiptbook/enquiryreceipts"
                element={
                  <AllReceiptsBook
                    queryType={VoucherQueryTypes.ENQ_STD_VOUCHERS}
                  />
                }
              />
              <Route
                path="/deletedemand"
                element={
                  <DeleteDemand
                    pageType={StudentsWhoLeftInstitutionTypes.DELETE_DEMAND}
                  />
                }
              />
              <Route
                path="/configurations"
                element={
                  <Configurations
                    config_query_type={SwConfigQueryType.INST_BY_MODULE}
                    str_value={ModuleName.ACCOUNTS}
                    int_value={0}
                    setModalFlag={setModalFlag}
                    pageType={PageFor.GENERAL}
                  />
                }
              />
              <Route
                path="/purchaseentry/masters/group"
                element={
                  <Group
                    pageType={PageFor.GENERAL}
                    setModalFlag={setModalFlag}
                  />
                }
              />
              <Route
                path="/purchaseentry/masters/category"
                element={
                  <Category
                    pageType={PageFor.GENERAL}
                    setModalFlag={setModalFlag}
                  />
                }
              />
              <Route
                path="/purchaseentry/masters/unitofmeasurement"
                element={
                  <UnitOfMeasurement
                    pageType={PageFor.GENERAL}
                    setModalFlag={setModalFlag}
                  />
                }
              />
              <Route
                path="/purchaseentry/masters/type"
                element={
                  <Type
                    pageType={PageFor.GENERAL}
                    setModalFlag={setModalFlag}
                  />
                }
              />
              <Route
                path="/purchaseentry/details"
                element={
                  <Details
                    pageType={PageFor.GENERAL}
                    setModalFlag={setModalFlag}
                  />
                }
              />
              <Route path="/purchaseentry/invoice" element={<Invoice />} />
              <Route path="/userLogin" element={<AcctUserLogin />} />
              <Route path="/cashierdashboard" element={<ChairmanDashBoard />} />
            </>
          )}
        </>
      )}
    </Routes>
  );
};

export default AccountingRoutes;
