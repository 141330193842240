import { useEffect, useState } from "react";
import useSwConfigData from "../../../../../../customhooks/useSwConfigData";
import { NonDemandPrintReceipts } from "../../../../Enum.types";
const useNonDemandReceiptConfig = () => {
  const [copies, setCopies] = useState(0);


  const {
    configData: { data: AdmissionNumber },
  } = useSwConfigData(
    NonDemandPrintReceipts.NON_DEMAND_RECEIPT_ADMISSION_NUMBER
  );
  const {
    configData: { data: CashDetails },
  } = useSwConfigData(
    NonDemandPrintReceipts.NON_DEMAND_RECEIPT_CASH_BANK_DETAILS
  );
  const {
    configData: { data: ClassDetails },
  } = useSwConfigData(NonDemandPrintReceipts.NON_DEMAND_RECEIPT_CLASS_NAME);
  const {
    configData: { data: CustomerName },
  } = useSwConfigData(NonDemandPrintReceipts.NON_DEMAND_RECEIPT_CUSTOMER_NAME);
  const {
    configData: { data: FatherName },
  } = useSwConfigData(NonDemandPrintReceipts.NON_DEMAND_RECEIPT_FATHER_NAME);
  const {
    configData: { data: motherName },
  } = useSwConfigData(NonDemandPrintReceipts.NON_DEMAND_RECEIPT_MOTHER_NAME);

  const {
    configData: { data: Logo },
  } = useSwConfigData(NonDemandPrintReceipts.NON_DEMAND_RECEIPT_INST_LOGO);
  const {
    configData: { data: InstName },
  } = useSwConfigData(NonDemandPrintReceipts.NON_DEMAND_RECEIPT_INST_NAME);
  const {
    configData: { data: InstAdress },
  } = useSwConfigData(NonDemandPrintReceipts.NON_DEMAND_RECEIPT_INST_ADDRESS);
  const {
    configData: { data: PrintNarrations },
  } = useSwConfigData(NonDemandPrintReceipts.NON_DEMAND_RECEIPT_NARRATIONS);
  const {
    configData: { data: PrintRegNo },
  } = useSwConfigData(
    NonDemandPrintReceipts.NON_DEMAND_RECEIPT_REGISTER_NUMBER
  );
  const {
    configData: { data: SectionName },
  } = useSwConfigData(NonDemandPrintReceipts.NON_DEMAND_RECEIPT_SECTION_NAME);

  const {
    configData: { data: ReceiptHeader },
  } = useSwConfigData(NonDemandPrintReceipts.NON_DEMAND_RECEIPT_HEADER);

  const {
    configData: { data: ReceiptFooter },
  } = useSwConfigData(NonDemandPrintReceipts.NON_DEMAND_RECEIPT_FOOTER);
  const {
    configData: { data: transactionNumber },
  } = useSwConfigData(
    NonDemandPrintReceipts.NON_DEMAND_RECEIPT_TRANSACTION_NUMBER
  );
  const {
    configData: { data: ReceiptFooter2 },
  } = useSwConfigData(NonDemandPrintReceipts.NON_DEMAND_RECEIPT_FOOTER_2);
  const {
    configData: { data: NoOfCopies, loading },
  } = useSwConfigData(NonDemandPrintReceipts.NON_DEMAND_RECEIPT_NUMBER_COPIES);

  const {
    configData: { data: PrintInstOrBranchLabel },
  } = useSwConfigData(
    NonDemandPrintReceipts.NON_DEMAND_RECEIPT_INST_OR_BRANCH_NAME
  );

  const {
    configData: { data: topMargin },
  } = useSwConfigData(NonDemandPrintReceipts.NON_DEMAND_RECEIPT_TOP_MARGIN);

  const {
    configData: { data: bottomMargin },
  } = useSwConfigData(NonDemandPrintReceipts.NON_DEMAND_RECEIPT_BOTTOM_MARGIN);

  const {
    configData: { data: leftMargin },
  } = useSwConfigData(NonDemandPrintReceipts.NON_DEMAND_RECEIPT_LEFT_MARGIN);
  const {
    configData: { data: rightMargin },
  } = useSwConfigData(NonDemandPrintReceipts.NON_DEMAND_RECEIPT_RIGHT_MARGIN);

  const {
    configData: { data: generatedBy },
  } = useSwConfigData(NonDemandPrintReceipts.NON_DEMAND_RECEIPT_GENERATED_BY);
  const {
    configData: { data: EnableInstStdLabel },
  } = useSwConfigData(NonDemandPrintReceipts.NON_DEMAND_RECEIPT_INST_STD_LABEL);
  const {
    configData: { data: simplifiedBy },
  } = useSwConfigData(NonDemandPrintReceipts.NON_DEMAND_SIMPLIPIED_BY_MYEDUATE);
  const {
    configData: { data: receiptTitle },
  } = useSwConfigData(NonDemandPrintReceipts.NON_DEMAND_RECEIPT_TITLE);

  useEffect(() => {
    if (NoOfCopies && !loading) {
      const { config_string_value } = NoOfCopies.GetSwConfigVariables[0];
      setCopies(Number(config_string_value));
    }
  }, [NoOfCopies, loading]);

  return {
    USE_NON_DEMAND_ADM_NO:
      AdmissionNumber &&
      AdmissionNumber.GetSwConfigVariables.length &&
      AdmissionNumber.GetSwConfigVariables[0].config_boolean_value,
    USE_SIMPLIFIED_BY:
      simplifiedBy &&
      simplifiedBy.GetSwConfigVariables.length &&
      simplifiedBy.GetSwConfigVariables[0].config_boolean_value,
    USE_RECEIPT_TITLE:
      receiptTitle &&
      receiptTitle.GetSwConfigVariables.length &&
      receiptTitle.GetSwConfigVariables[0].config_string_value,
    USE_NON_DEMAND_CASH_DETAILS:
      CashDetails &&
      CashDetails.GetSwConfigVariables.length &&
      CashDetails.GetSwConfigVariables[0].config_boolean_value,
    USE_NON_DEMAND_CLASS_NAME:
      ClassDetails &&
      ClassDetails.GetSwConfigVariables.length &&
      ClassDetails.GetSwConfigVariables[0].config_boolean_value,
    USE_NON_DEMAND_CUSTOMER_NAME:
      CustomerName &&
      CustomerName.GetSwConfigVariables.length &&
      CustomerName.GetSwConfigVariables[0].config_boolean_value,
    USE_NON_DEMAND_FATHER_NAME:
      FatherName &&
      FatherName.GetSwConfigVariables.length &&
      FatherName.GetSwConfigVariables[0].config_boolean_value,
      USE_NON_DEMAND_MOTHER_NAME:
      motherName &&
      motherName.GetSwConfigVariables.length &&
      motherName.GetSwConfigVariables[0].config_boolean_value,
    USE_NON_DEMAND_LOGO:
      Logo &&
      Logo.GetSwConfigVariables.length &&
      Logo.GetSwConfigVariables[0].config_boolean_value,
    USE_NON_DEMAND_INST_NAME:
      InstName &&
      InstName.GetSwConfigVariables.length &&
      InstName.GetSwConfigVariables[0].config_boolean_value,
    USE_NON_DEMAND_INST_ADDRESS:
      InstAdress &&
      InstAdress.GetSwConfigVariables.length &&
      InstAdress.GetSwConfigVariables[0].config_boolean_value,
    USE_NON_DEMAND_NARRATIONS:
      PrintNarrations &&
      PrintNarrations.GetSwConfigVariables.length &&
      PrintNarrations.GetSwConfigVariables[0].config_boolean_value,
    USE_NON_DEMAND_REG_NO:
      PrintRegNo &&
      PrintRegNo.GetSwConfigVariables.length &&
      PrintRegNo.GetSwConfigVariables[0].config_boolean_value,
    USE_NON_DEMAND_SECTION_NAME:
      SectionName &&
      SectionName.GetSwConfigVariables.length &&
      SectionName.GetSwConfigVariables[0].config_boolean_value,
    USE_NON_DEMAND_GENERATED_BY:
      generatedBy &&
      generatedBy.GetSwConfigVariables.length &&
      generatedBy.GetSwConfigVariables[0].config_boolean_value,
    USE_NON_DEMAND_INST_STD_LABEL:
      EnableInstStdLabel &&
      EnableInstStdLabel.GetSwConfigVariables.length &&
      EnableInstStdLabel.GetSwConfigVariables[0].config_boolean_value,
    USE_NON_DEMAND_RECEIPT_HEADER:
      ReceiptHeader &&
      ReceiptHeader.GetSwConfigVariables.length &&
      ReceiptHeader.GetSwConfigVariables[0].config_string_value,
    USE_NON_DEMAND_RECEIPT_FOOTER:
      ReceiptFooter &&
      ReceiptFooter.GetSwConfigVariables.length &&
      ReceiptFooter.GetSwConfigVariables[0].config_string_value,
    USE_NON_DEMAND_TRANSACTION_NUMBER:
      transactionNumber &&
      transactionNumber.GetSwConfigVariables.length &&
      transactionNumber.GetSwConfigVariables[0].config_boolean_value,
    USE_NON_DEMAND_RECEIPT_FOOTER_2:
      ReceiptFooter2 &&
      ReceiptFooter2.GetSwConfigVariables.length &&
      ReceiptFooter2.GetSwConfigVariables[0].config_string_value,
    USE_NON_DEMAND_NO_COPIES: copies,
    NON_DEMAND_PRINT_INST_OR_BRANCH_NAME:
      PrintInstOrBranchLabel &&
      PrintInstOrBranchLabel.GetSwConfigVariables.length &&
      PrintInstOrBranchLabel.GetSwConfigVariables[0].config_string_value,
    USE_NON_DEMAND_TOP_MARGIN:
      topMargin &&
      topMargin.GetSwConfigVariables.length &&
      topMargin.GetSwConfigVariables[0].config_integer_value,

    USE_NON_DEMAND_BOTTOM_MARGIN:
      bottomMargin &&
      bottomMargin.GetSwConfigVariables.length &&
      bottomMargin.GetSwConfigVariables[0].config_integer_value,
    USE_NON_DEMAND_LEFT_MARGIN:
      leftMargin &&
      leftMargin.GetSwConfigVariables.length &&
      leftMargin.GetSwConfigVariables[0].config_integer_value,
    USE_NON_DEMAND_RIGHT_MARGIN:
      rightMargin &&
      rightMargin.GetSwConfigVariables.length &&
      rightMargin.GetSwConfigVariables[0].config_integer_value,
  };
};
export default useNonDemandReceiptConfig;
