import React, { useState } from "react";
import { PageFor } from "../../../../utils/Enum.types";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import IndividualAdjustDepositFee from "./IndividualAdjustDepositFee";
import { useTheme } from "@mui/material/styles";
import { TabPanel, a11yProps } from "../../../../styles/Tabs";
import GroupAdjustDepositFee from "./GroupAdjustDepositFee";

interface Props {
  pageType: PageFor;
}
const AdjustDepositFee = ({ pageType }: Props) => {
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [
    depositAdjustmentIndividualModal,
    setDepositAdjustmentIndividualModal,
  ] = useState(false);
  return (
    <>
      {pageType === PageFor.GENERAL ? <Home DashBoardRequired={false} /> : null}
      <Title>Adjust Deposit Fee to Non Demand Ledger</Title>
      <div className="adjust-deposit-fee">
        <div className="adjust-deposit-fee__tabs">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            variant="fullWidth"
            textColor="secondary">
            <Tab label="Individual" value={0} {...a11yProps(0)} />
            <Tab label="Group" value={1} {...a11yProps(1)} />
          </Tabs>
        </div>
        <div className="adjust-deposit-fee__swipable">
          <TabPanel value={value} index={0} dir={theme.direction}>
            <IndividualAdjustDepositFee
              pageType={PageFor.GENERAL}
              setDepositAdjustmentIndividualModal={
                setDepositAdjustmentIndividualModal
              }
            />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <GroupAdjustDepositFee
              pageType={PageFor.GENERAL}
              setDepositAdjustmentGroupModal={
                setDepositAdjustmentIndividualModal
              }
            />
          </TabPanel>
        </div>
      </div>
    </>
  );
};

export default AdjustDepositFee;
