import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Title } from "../../../../stories/Title/Title";
import {
  emptyMessageType,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE_30,
} from "../../../../utils/constants";
import {
  formatter,
  getModifiedScrollHeight,
  handleFormEvent,
  handleMUISelectEvent,
  isOptionEqualToValue,
  toStandardCase,
} from "../../../../utils/UtilFunctions";
import InstitutionLevel from "../../../../Modules/Master/Reports/InstitutionLevel/Index";
import {
  ColumnVisibilityFor,
  StudentAcctReportType,
} from "../../../../utils/Enum.types";
import List from "./List";
import Home from "../../Home/Index";
import { Button } from "../../../../stories/Button/Button";
import Input from "../../../../stories/Input/Input";
import useDropdownData from "../../../../customhooks/useDropdownData";
import { optionsType, responseType } from "../../../../utils/Form.types";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import { Keys } from "../../../../utils/Enum.keys";
import useInstMasterDataByInstId from "../../../../customhooks/useInstMasterDataByInstId";
import UseStudentsbyDemandAmount, {
  StudentDemandEdges,
} from "../../hooks/useStudentsbyDemandAmount";

import StudentTotalCount from "../../../Master/Student/Components/StudentTotalCount";
import useSummationDatabyNewApi from "../../hooks/useSummationDatabyNewApi";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import useAcctTableJson from "../../json/useAcctTableJson";
import { TableHeaderProps } from "../../../../utils/types";
import {
  GridAlignment,
  GridCellParams,
  GridColDef,
  GridColumnVisibilityModel,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../../styles/DataGridTableStyles";
import AgentOptionsAutocomplete from "../../Agents/Components/AgentOptionsAutocomplete";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";
import { VoucherQueryTypes } from "../../common/Enum.types";

import MessageModal from "../../../../pages/MessageModal";

import { StudentModalStyles } from "../../../../styles/ModalStyles";
import Modal from "react-modal";

export const studentStatusDropDown = [
  { label: "Current", value: "CUR" },
  { label: "Transfer", value: "TC" },
  { label: "Detained", value: "DET" },
  { label: "Not Eligible", value: "NE" },
  { label: "Reserved", value: "NXT" },
  { label: "Shortage Of Attendance", value: "SOA" },
  { label: "Cancelled", value: "CXLD" },
  { label: "Passed Out", value: "PASS" },
];

interface Props {
  reportType: StudentAcctReportType;
}

const Reports = ({ reportType }: Props) => {
  const { format } = formatter;
  const { Accounts_Table } = useAcctTableJson();
  const [rows, setRows] = useState<GridValidRowModel[]>([]);
  const [customizeDetails, setCustomizeDetails] = useState(false);

  const navigate = useNavigate();
  const [message, setMessage] = useState(emptyMessageType);
  const [searchData, setSearchData] = useState("");
  const [students, setStudents] = useState<StudentDemandEdges[]>([]);
  const [departmentId, setDepartmentId] = useState<responseType | null>(null);
  const [branchId, setBranchId] = useState<responseType | null>(null);
  const [classId, setClassId] = useState<responseType | null>(null);
  const [semesterId, setSemesterId] = useState<responseType | null>(null);
  const [sectionId, setSectionId] = useState<responseType | null>(null);
  const [categoryId, setCategoryId] = useState<responseType | null>(null);
  const [status, setStatus] = useState<optionsType | null>(null);
  // this should be always -1 for filter to work, if i set 0 as inital value, by default it's null, filter doesnt work
  const [balanceAmount, setBalanceAmount] = useState(-1);
  //useRefs
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);

  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useDropdownData(
    departmentId ? departmentId.value : 0,
    branchId ? branchId.value : 0,
    classId ? classId.value : 0,
    semesterId ? semesterId.value : 0
  );

  const { categoryDropDown } = useInstMasterDataByInstId();

  const { students: studentsDemandDetails } = UseStudentsbyDemandAmount(
    departmentId ? departmentId.value : 0,
    branchId ? branchId.value : 0,
    classId ? classId.value : 0,
    semesterId ? semesterId.value : 0,
    sectionId ? sectionId.value : 0,
    categoryId ? categoryId.value : 0,
    searchData,
    ROWS_PER_PAGE_30,
    reportType as StudentAcctReportType,
    0,
    status ? status.value : "",
    balanceAmount
  );
  const { StudentsSummationData } = useSummationDatabyNewApi(
    departmentId ? departmentId.value : 0,
    branchId ? branchId.value : 0,
    classId ? classId.value : 0,
    semesterId ? semesterId.value : 0,
    sectionId ? sectionId.value : 0,
    categoryId ? categoryId.value : 0,
    reportType as StudentAcctReportType,
    status ? status.value : ""
  );
  const { data, fetchMore, loading } = studentsDemandDetails;

  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CATEGORY_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();

  const StudentsSummationDataFinal =
    StudentsSummationData.data &&
    StudentsSummationData.data.GetAcctStdDemandSummation.length
      ? StudentsSummationData.data?.GetAcctStdDemandSummation[0]
      : {
          std_demand_ob: 0,
          std_demand_amt: 0,
          std_demand_concession: 0,
          std_demand_receivable: 0,
          std_demand_received: 0,
          std_demand_bal: 0,
          std_demand_refunds: 0,
        };

  const ReturnTitle = () => {
    switch (reportType) {
      case StudentAcctReportType.DEMAND_RAISED:
        return "Fee Balance Students Report";
      case StudentAcctReportType.COMPLETELY_PAID:
        return "Completely Paid  Students Report";
      case StudentAcctReportType.PARTIALLY_PAID:
        return "Partially Paid Students Report";
      case StudentAcctReportType.DEMAND_RAISED_NOT_PAID:
        return "Not Paid Students Report";

      case StudentAcctReportType.REFUNDS:
        return "Refund Fee Report";

      case StudentAcctReportType.BY_AGENT:
        return "Agent Student Report";
      case StudentAcctReportType.LEDGER_WISE_BALANCE:
        return "Ledger Wise Fee Balance";
      default:
        return "Concession Fee Report";
    }
  };

  const dynamicHeaders: TableHeaderProps[] =
    Accounts_Table.Reports.FeeBalanceReports.Table_Headers.map((header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
      hideable: header.hideable,
    }));
  const columns: GridColDef[] = [...dynamicHeaders];

  const {
    branchLabel,
    classLabel,
    departmentLabel,
    sectionLabel,
    semesterLabel,
    categoryLabel,
  } = useInstLabels();

  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetAcctStdDemand.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newRow) => {
          const filteredStudent = students.find(
            (row) => row.node.id && row.node.id === newRow.node.id
          );
          if (filteredStudent) {
            return {
              ...newRow,
              node: {
                ...newRow.node,
              },
            };
          }
          return newRow;
        });
        setStudents(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            adm_no: node.mst_student.std_adm_no,
            reg_no: node.mst_student.std_reg_no,

            name: toStandardCase(
              node.mst_student.first_name +
                node.mst_student.middle_name +
                node.mst_student.last_name
            ),
            mobile: node.mst_student.std_mobile,
            status: node.std_status,
            cat: node.mst_student.category.cat_desc,
            ob: format(node.std_demand_ob),
            demand: format(node.std_demand_amt),
            concession: format(node.std_demand_concession),
            receivable: format(node.std_demand_receivable),
            received: format(node.std_demand_received),
            refunds: format(node.std_demand_refunds),
            balance: format(node.std_demand_bal),
          }))
        );
      } else {
        setStudents(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            adm_no: node.mst_student.std_adm_no,
            reg_no: node.mst_student.std_reg_no,

            name: toStandardCase(
              node.mst_student.first_name +
                node.mst_student.middle_name +
                node.mst_student.last_name
            ),
            mobile: node.mst_student.std_mobile,
            status: node.std_status,
            cat: node.mst_student.category.cat_desc,
            ob: format(node.std_demand_ob),
            demand: format(node.std_demand_amt),
            concession: format(node.std_demand_concession),
            receivable: format(node.std_demand_receivable),
            received: format(node.std_demand_received),
            refunds: format(node.std_demand_refunds),
            balance: format(node.std_demand_bal),
          }))
        );
      }
      setEndCursor(data.GetAcctStdDemand.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);
  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;

      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: FETCH_MORE_DATA,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetAcctStdDemand.edges;
                const pageInfo = fetchMoreResult.GetAcctStdDemand.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck = prevResult.GetAcctStdDemand.edges.filter(
                  ({ node: { id } }) =>
                    newEdges.findIndex(
                      ({ node: { id: newId } }) => newId === id
                    ) !== -1
                );

                if (duplicateCheck.length > 0) return prevResult;

                return {
                  GetAcctStdDemand: {
                    edges: [...students, ...newEdges],
                    pageInfo,
                    totalCount: data ? data.GetAcctStdDemand.totalCount! : 0,
                  },
                };
              },
            });
          }
        }
      };

      if (scrollTable && rows.length)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    [rows]
  );

  const getRow = () => {
    if (students.length) {
      return [
        {
          id: [],
          adm_no: [],
          reg_no: [],
          name: [],
          mobile: [],
          status: "Total",
          ob: format(StudentsSummationDataFinal.std_demand_ob),
          demand: format(StudentsSummationDataFinal.std_demand_amt),
          concession: format(StudentsSummationDataFinal.std_demand_concession),
          receivable: format(StudentsSummationDataFinal.std_demand_receivable),
          received: format(StudentsSummationDataFinal.std_demand_received),
          refunds: format(StudentsSummationDataFinal.std_demand_refunds),
          balance: format(StudentsSummationDataFinal.std_demand_bal),
        },
      ];
    }
    return [];
  };

  const pinnedRows = {
    bottom: getRow(),
  };

  const getCellClassName = (params: GridCellParams) => {
    const pinnedRowIds = pinnedRows.bottom
      ? pinnedRows.bottom.map((row) => row.id)
      : [];

    const isPinnedRow = pinnedRowIds.includes(params.row.id);

    if (isPinnedRow) {
      switch (params.field) {
        case "status":
          return "total";
        case "balance":
          return "balance-count";
        case "id":
        case "adm_no":
        case "reg_no":
        case "name":
        case "mobile":
          return "hide-td";
        default:
          return "totalcount";
      }
    }

    return "";
  };

  const [columnVisibilityModel, setColumnVisibilityModel] =
    React.useState<GridColumnVisibilityModel>({
      reg_no: false,
      mobile: false,
      cat: false,
    });

  useEffect(() => {
    const savedVisibilityModel = localStorage.getItem(
      ColumnVisibilityFor.FEE_BALANCE_REPORT
    );
    if (savedVisibilityModel) {
      setColumnVisibilityModel(JSON.parse(savedVisibilityModel));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      ColumnVisibilityFor.FEE_BALANCE_REPORT,
      JSON.stringify(columnVisibilityModel)
    );
  }, [columnVisibilityModel]);

  useEffect(() => {
    setHasNextPage(true);
  }, [reportType]);

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>{ReturnTitle()}</Title>
      <div className="fee-balance-reports">
        <div className="fee-balance-reports__tableblock">
          <List />
        </div>

        <div className="fee-balance-reports__reportblock">
          <form className="row g-0 fee-balance-reports__select-options">
            {reportType === StudentAcctReportType.BY_AGENT ? (
              <div className="col-2">
                <AgentOptionsAutocomplete labelRequired />
              </div>
            ) : null}
            {USE_DEPARTMENT_KEY ? (
              <div className="col-2">
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={departmentDropDown}
                  openOnFocus
                  value={departmentId}
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option as responseType, departmentId)
                  }
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) {
                      e.preventDefault();
                      if (departmentId) {
                        handleMUISelectEvent(e);
                      }
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setDepartmentId(null);
                    }
                  }}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setDepartmentId(newValue as responseType);
                      setHasNextPage(true);
                    } else {
                      setDepartmentId(null);
                    }
                    setBranchId(null);
                    setClassId(null);
                    setSemesterId(null);
                    setSectionId(null);
                    setCategoryId(null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={departmentLabel}
                      fullWidth
                      autoFocus
                      className={labelClasses.formControlRoot}
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                    />
                  )}
                />
              </div>
            ) : null}

            {USE_BRANCH_KEY ? (
              <div className="col-2">
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={branchDropDown}
                  openOnFocus
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option as responseType, branchId)
                  }
                  value={branchId}
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) {
                      e.preventDefault();
                      if (branchId) {
                        handleMUISelectEvent(e);
                      }
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setBranchId(null);
                    }
                  }}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setBranchId(newValue as responseType);
                      setHasNextPage(true);
                    } else {
                      setBranchId(null);
                    }
                    setClassId(null);
                    setSemesterId(null);
                    setSectionId(null);
                    setCategoryId(null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={branchLabel}
                      className={labelClasses.formControlRoot}
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                    />
                  )}
                />
              </div>
            ) : null}

            {USE_CLASS_KEY ? (
              <div className="col-2">
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={classDropDown}
                  openOnFocus
                  value={classId}
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option as responseType, classId)
                  }
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) {
                      e.preventDefault();
                      if (classId) {
                        handleMUISelectEvent(e);
                      }
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setClassId(null);
                    }
                  }}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setClassId(newValue as responseType);
                      setHasNextPage(true);
                    } else {
                      setClassId(null);
                    }
                    setSemesterId(null);
                    setSectionId(null);
                    setCategoryId(null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={`${classLabel}`}
                      className={labelClasses.formControlRoot}
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                    />
                  )}
                />
              </div>
            ) : null}

            {USE_SEMESTER_KEY ? (
              <div className="col-2">
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={semesterDropDown}
                  openOnFocus
                  value={semesterId}
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option as responseType, semesterId)
                  }
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) {
                      e.preventDefault();
                      if (semesterId) {
                        handleMUISelectEvent(e);
                      }
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setSemesterId(null);
                    }
                  }}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setSemesterId(newValue as responseType);
                      setHasNextPage(true);
                    } else {
                      setSemesterId(null);
                    }
                    setSectionId(null);
                    setCategoryId(null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={semesterLabel}
                      className={labelClasses.formControlRoot}
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                    />
                  )}
                />
              </div>
            ) : null}

            {USE_SECTION_KEY ? (
              <div className="col-2">
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={sectionDropDown}
                  openOnFocus
                  value={sectionId}
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option as responseType, sectionId)
                  }
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) {
                      e.preventDefault();
                      if (sectionId) {
                        handleMUISelectEvent(e);
                      }
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setSectionId(null);
                    }
                  }}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setSectionId(newValue as responseType);
                      setHasNextPage(true);
                    } else {
                      setSectionId(null);
                    }
                    setCategoryId(null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={sectionLabel}
                      className={labelClasses.formControlRoot}
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                    />
                  )}
                />
              </div>
            ) : null}

            {USE_CATEGORY_KEY ? (
              <div className="col-2">
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={categoryDropDown}
                  openOnFocus
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option as responseType, categoryId)
                  }
                  value={categoryId}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setCategoryId(newValue as responseType);
                      setHasNextPage(true);
                    } else {
                      setCategoryId(null);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === Keys.BACKSPACE) {
                      setCategoryId(null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={categoryLabel}
                      className={labelClasses.formControlRoot}
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                    />
                  )}
                />
              </div>
            ) : null}
          </form>
          <div className="row g-0 fee-balance-reports__select-options2">
            <div className="col-2">
              <Input
                id="search"
                type="text"
                placeholder="Search "
                className="fee-balance-reports__reportblock--filter-options--search"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSearchData(e.target.value);
                }}
                value={searchData}
                onKeyDown={handleFormEvent}
              />
            </div>

            {reportType === StudentAcctReportType.GENERAL && (
              <div className="col-2">
                <TextField
                  label="Balance Amount"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  onChange={(e) => setBalanceAmount(Number(e.target.value))}
                  onKeyDown={(e) => {
                    if (e.key === Keys.BACKSPACE) {
                      setBalanceAmount(-1);
                    }
                  }}
                  className="fee-balance-reports__select-options2--textfield"
                />
              </div>
            )}

            <div className="col-2">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={studentStatusDropDown}
                openOnFocus
                value={status}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setStatus(newValue as optionsType);
                    setHasNextPage(true);
                  } else {
                    setStatus(null);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setStatus(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Status"
                    className={labelClasses.formControlRoot}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                  />
                )}
              />
            </div>
          </div>

          <div className={`fee-balance-reports__reportblock--details `}>
            <StyledDatagrid
              columns={columns}
              rows={rows}
              disableRowSelectionOnClick
              disableChildrenSorting
              rowHeight={TABLE_ROW_HEIGHT}
              pinnedRows={pinnedRows}
              getCellClassName={getCellClassName}
              hideFooter
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) =>
                setColumnVisibilityModel(newModel)
              }
            />
          </div>
        </div>
      </div>
      <div className="row g-0">
        <div className="col">
          <div className="fee-balance-reports__buttons">
            {students.length ? (
              <>
                <Button
                  onClick={() => {
                    setCustomizeDetails(!customizeDetails);
                  }}
                  mode="export"
                />
              </>
            ) : null}
            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
        </div>
        <div className="col-2 fee-balance-reports__total">
          <StudentTotalCount
            totalCount={data ? data.GetAcctStdDemand.totalCount! : 0}
          />
        </div>
      </div>
      <MessageModal
        handleClose={() => setMessage(emptyMessageType)}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
      <Modal
        isOpen={customizeDetails}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <InstitutionLevel
          reportType={
            reportType === StudentAcctReportType.COMPLETELY_PAID
              ? VoucherQueryTypes.ACCT_STD_FEE_COMPLETELY_PAID_REPORT
              : reportType === StudentAcctReportType.PARTIALLY_PAID
              ? VoucherQueryTypes.ACCT_STD_FEE_PARTIALLY_PAID_REPORT
              : reportType === StudentAcctReportType.DEMAND_RAISED_NOT_PAID
              ? VoucherQueryTypes.ACCT_STD_FEE_NOT_PAID_REPORT
              : reportType === StudentAcctReportType.BY_AGENT
              ? VoucherQueryTypes.ACCT_STD_FEE_BY_AGENT_REPORT
              : VoucherQueryTypes.ACCT_STD_FEE_BALANCE_REPORT
          }
          forModal={true}
          setModalFlag={setCustomizeDetails}
        />
      </Modal>
    </>
  );
};

export default Reports;
