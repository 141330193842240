import React, { useState } from "react";
import Home from "../../Master/configurations/Home/Index";
import { Title } from "../../../stories/Title/Title";
import { Checkbox } from "@mui/material";
import { Label } from "../../../stories/Label/Label";
import { Button } from "../../../stories/Button/Button";
import DeleteModal from "../../../pages/DeleteModal";
import { useNavigate } from "react-router-dom";

const DeleteReceiptsVouchers = () => {
  const navigate = useNavigate();
  const DeleteReceipts = [
    {
      label: "Delete all Refund Receipts",
    },
    {
      label: "Delete all Concession Receipts",
    },
    {
      label: "Delete all Adjustment Receipts",
    },
    {
      label: " Delete all Deposit Receipts",
    },
    {
      label: "Delete all Non Demand Receipts",
    },
    {
      label: "Delete all Demand Receipts",
    },
    {
      label: " Delete all Students Demand",
    },
    {
      label: " Delete all Challan Reconciled Receipts",
    },
    {
      label: "  Delete all Challan Entries",
    },
  ];

  const DeleteVouchers = [
    {
      label: "Delete all Payment Vouchers",
    },
    {
      label: " Delete all Receipt Vouchers",
    },
    {
      label: " Delete all Cash Deposited Vouchers",
    },
    {
      label: " Delete all Cash withdrawal Vouchers",
    },
    {
      label: "Delete all Bank to Bank adjustment Entries",
    },
    {
      label: "Delete all Journal Vouchers",
    },
  ];

  const [selectAllReceipts, setSelectAllReceipts] = useState(false);
  const [selectAllVouchers, setSelectAllVouchers] = useState(false);

  const [checkboxReceipts, setCheckboxReceipts] = useState(
    new Array(DeleteReceipts.length).fill(false)
  );
  const [checkboxVouchers, setCheckboxVouchers] = useState(
    new Array(DeleteVouchers.length).fill(false)
  );
  const [deleteModal, setDeleteModal] = useState(false);

  // Handle the "Delete All" checkbox change
  const handleSelectAllReceiptsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setSelectAllReceipts(isChecked);
    setCheckboxReceipts(new Array(DeleteReceipts.length).fill(isChecked));
  };

  const handleSelectAllVouchersChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setSelectAllVouchers(isChecked);
    setCheckboxVouchers(new Array(DeleteVouchers.length).fill(isChecked));
  };

  // Handle individual checkbox change
  const handleCheckboxReceiptsChange = (index: number) => {
    const newCheckboxReceipts = [...checkboxReceipts];
    newCheckboxReceipts[index] = !newCheckboxReceipts[index];
    setCheckboxReceipts(newCheckboxReceipts);
    // If any individual checkbox is unchecked, set "Delete All" to false
    if (newCheckboxReceipts.some((state) => !state)) {
      setSelectAllReceipts(false);
    } else {
      setSelectAllReceipts(true);
    }
  };

  const handleCheckboxVouchersChange = (index: number) => {
    const newCheckboxVouchers = [...checkboxVouchers];
    newCheckboxVouchers[index] = !newCheckboxVouchers[index];
    setCheckboxVouchers(newCheckboxVouchers);
    // If any individual checkbox is unchecked, set "Delete All" to false
    if (newCheckboxVouchers.some((state) => !state)) {
      setSelectAllVouchers(false);
    } else {
      setSelectAllVouchers(true);
    }
  };

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Delete all Receipts / Vouchers</Title>
      <div className="delete-config">
        <div className="row g-0 delete-config__block">
          <div className="col">
            <ul>
              <li>
                <Checkbox
                  checked={selectAllReceipts}
                  onChange={handleSelectAllReceiptsChange}
                />
                <b className="delete-config__block--list">
                  Delete All Receipts
                </b>
              </li>
            </ul>
            <ul className="delete-config__block--list-items">
              {DeleteReceipts.map((item, index) => (
                <li key={index}>
                  <Checkbox
                    checked={checkboxReceipts[index]}
                    onChange={() => handleCheckboxReceiptsChange(index)}
                  />
                  <Label>{item.label}</Label>
                </li>
              ))}
            </ul>
          </div>
          <div className="col">
            <ul>
              <li>
                <Checkbox
                  checked={selectAllVouchers}
                  onChange={handleSelectAllVouchersChange}
                />
                <b className="delete-config__block--list">
                  Delete All Vouchers
                </b>
              </li>
            </ul>
            <ul className="delete-config__block--list-items">
              {DeleteVouchers.map((item, index) => (
                <li key={index}>
                  <Checkbox
                    checked={checkboxVouchers[index]}
                    onChange={() => handleCheckboxVouchersChange(index)}
                  />
                  <Label>{item.label}</Label>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <Button
          mode="delete"
          onClick={() => {
            setDeleteModal(!deleteModal);
          }}
        />
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>

      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        id={0}
      />
    </>
  );
};

export default DeleteReceiptsVouchers;
