import { useLazyQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { VouchernodeData, nodevars } from "../../../../../Types/Accounting";
import { AccountVoucherDetailsByMasterVoucherId } from "../../../../../queries/common";
import {
  BankOrCash,
  DebitOrCredit,
  FileUploadParams,
  ReceiptTypes,
  VoucherBookKey,
} from "../../../../../utils/Enum.types";
import { AppContext } from "../../../../../context/context";
import useToken from "../../../../../customhooks/useToken";
import { formatter, toStandardDate } from "../../../../../utils/UtilFunctions";
import EduateLogo from "../../../../../images/EduateLogo.svg";
import useInstLogoDetails from "../../../../../customhooks/useInstLogoDetails";
import USeVoucherReceiptConfig from "../hooks/UseReceiptConfig";
import useInstDetails from "../../../../../customhooks/general/useInstDetails";
import { toIsoDate } from "../../../../../utils/UtilFunctions";
import { voucherMasterDetails } from "../../../../Accounts/hooks/useAcctVoucherDetailsByDates";
import { VoucherTypes } from "../../../Enum.types";
import { ToWords } from "to-words";

const Index = () => {
  const { state } = useContext(AppContext);
  const { token } = useToken();
  const { format } = formatter;
  const { LogoOrSign } = useInstLogoDetails({
    filetype: FileUploadParams.INST_LOGO,
  });
  const { InstFormData } = useInstDetails(1);

  const [paymentVoucherDetails, setPaymentVoucherDetails] =
    useState<voucherMasterDetails>();
  const [GetPaymentVoucherDetails] = useLazyQuery<VouchernodeData, nodevars>(
    AccountVoucherDetailsByMasterVoucherId
  );
  const toWords = new ToWords();

  let totalDebitAmount = 0;
  let totalCreditAmount = 0;
  // eslint-disable-next-line
  totalCreditAmount = paymentVoucherDetails?.acct_voucher_details.filter(
    (acct) => acct.vo_cr_db === DebitOrCredit.CREDIT
  )[0]?.vo_cr_total!;
  // eslint-disable-next-line
  totalDebitAmount = paymentVoucherDetails?.acct_voucher_details.filter(
    (acct) => acct.vo_cr_db === DebitOrCredit.DEBIT
  )[0]?.vo_db_total!;
  useEffect(() => {
    if (state.receiptId) {
      GetPaymentVoucherDetails({
        variables: {
          token,
          ids: [state.receiptId],
        },
      }).then(({ data }) => {
        if (data) {
          setPaymentVoucherDetails(data?.nodes[0]);
        }
      });
    } // eslint-disable-next-line
  }, [GetPaymentVoucherDetails, state.receiptId, token]);

  const {
    USE_LOGO,
    USE_INST_NAME,
    USE_INST_ADDRESS,
    USE_VOUCHER_TYPE,
    USE_VOUCHERS_APPROVED_BY,
    USE_VOUCHERS_SIGNED_BY,
    USE_CREATED_TIME,
  } = USeVoucherReceiptConfig();

  return (
    <>
      <div className="voucher-receipts">
        <div className="voucher-receipts__block">
          <div className="voucher-receipts__block--inst-data">
            <div className={"voucher-receipts__block--inst-data--details"}>
              {USE_LOGO && (
                <div className="voucher-receipts__block--inst-data--logo">
                  {LogoOrSign.defaultLogo ? (
                    <img src={LogoOrSign.defaultLogo} alt="/" />
                  ) : (
                    <img src={EduateLogo} alt="/" />
                  )}
                </div>
              )}

              <div
                className={
                  USE_LOGO
                    ? "voucher-receipts__block--inst-data--details-block"
                    : "voucher-receipts__block--inst-data--details-block--without-image"
                }>
                {USE_INST_NAME && (
                  <b className="voucher-receipts__block--inst-data--inst-name">
                    {InstFormData.inst_name}
                  </b>
                )}

                {USE_INST_ADDRESS && (
                  <span className="voucher-receipts__block--inst-data--inst-address">
                    {[
                      InstFormData.inst_address,
                      InstFormData.inst_place,
                      InstFormData.inst_pin,
                    ]
                      .filter(Boolean)
                      .join(", ")
                      .trim()}
                  </span>
                )}
              </div>
            </div>

            <div className="voucher-receipts__block--inst-data--flex">
              <div className="voucher-receipts__block--inst-data--flex--rcptno">
                <span>No.: </span>
                <b>{paymentVoucherDetails?.v_no}</b>
              </div>
              {USE_VOUCHER_TYPE && (
                <b className="voucher-receipts__block--inst-data--voucher-type">
                  {paymentVoucherDetails?.v_type === ReceiptTypes.RCPT
                    ? VoucherTypes.R
                    : paymentVoucherDetails?.v_type === ReceiptTypes.CONTRA
                    ? VoucherTypes.C
                    : paymentVoucherDetails?.v_type === ReceiptTypes.JOURNAL
                    ? VoucherTypes.J
                    : VoucherTypes.P}
                </b>
              )}
              <div className="voucher-receipts__block--inst-data--flex--date">
                <span>Date: </span>
                <b>{toStandardDate(paymentVoucherDetails?.v_date!)}</b>
              </div>
            </div>
          </div>
          {paymentVoucherDetails?.v_type === ReceiptTypes.PYMT ||
          (paymentVoucherDetails?.v_type === ReceiptTypes.STUDENT_RECEIPT &&
            paymentVoucherDetails.v_book_type ===
              VoucherBookKey.STUDENT_REFUND_RECEIPT_BOOK) ? (
            <div className="voucher-receipts__block--payment-info">
              <p>
                Pay to
                <span className="voucher-receipts__block--payment-info--capitalize">
                  &nbsp;
                  {paymentVoucherDetails?.party_name}
                </span>
                &nbsp;a sum of Rs.&nbsp;
                {format(paymentVoucherDetails?.v_total_cr_amt!)}&nbsp;
                <span className="voucher-receipts__block--payment-info--capitalize">
                  (
                  {toWords.convert(paymentVoucherDetails?.v_total_cr_amt!, {
                    currency: true,
                  })}
                  ) towards
                </span>
                <span className="voucher-receipts__block--payment-info--uppercase">
                  &nbsp;
                  {paymentVoucherDetails?.v_transcation_narration}
                </span>
                &nbsp;vide Bill No. :{paymentVoucherDetails?.party_bill_no}
              </p>
              <div className="voucher-receipts__block--payment-info--trans">
                <div className="voucher-receipts__block--payment-info--trans-type">
                  <span>DEBIT: </span>
                  <b className="voucher-receipts__block--payment-info--uppercase">
                    {
                      paymentVoucherDetails?.acct_voucher_details.filter(
                        ({ vo_cr_db }) => vo_cr_db === DebitOrCredit.DEBIT
                      )[0].acct_ldgr.ldgr_desc
                    }
                  </b>
                </div>
                <div className="voucher-receipts__block--payment-info--trans-type">
                  <span>CREDIT: </span>
                  <b className="voucher-receipts__block--payment-info--uppercase">
                    {
                      paymentVoucherDetails?.acct_voucher_details.filter(
                        ({ vo_cr_db }) => vo_cr_db === DebitOrCredit.CREDIT
                      )[0].acct_ldgr.ldgr_desc
                    }
                  </b>
                </div>
                <div className="voucher-receipts__block--payment-info--trans-summary">
                  <div className="voucher-receipts__block--payment-info--trans-summary--amount">
                    <span>
                      Rs. {format(paymentVoucherDetails?.v_total_cr_amt!)}
                    </span>
                  </div>
                  <div className="voucher-receipts__block--payment-info--trans-summary--approved-by">
                    <span>Approved By {USE_VOUCHERS_APPROVED_BY}</span>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <div className="voucher-receipts__block--sender-info">
            <p>
              Received with thanks from
              <span className="voucher-receipts__block--payment-info--uppercase">
                &nbsp; {InstFormData.inst_name}
              </span>
              &nbsp;{InstFormData.inst_address} {InstFormData.inst_place} -
              {InstFormData.inst_pin} a sum of Rs.&nbsp;
              {format(paymentVoucherDetails?.v_total_db_amt!)}&nbsp;
              {paymentVoucherDetails?.v_transcation_cash_or_bank ===
              BankOrCash.CASH
                ? `through Cash`
                : `through Cheque vide bearing Cheque No.:${paymentVoucherDetails?.v_transcation_no} dated : 28-03-2023`}
            </p>
            <div className="voucher-receipts__block--sender-info--signatures">
              {paymentVoucherDetails &&
              (paymentVoucherDetails.v_type === ReceiptTypes.PYMT ||
                (paymentVoucherDetails.v_type ===
                  ReceiptTypes.STUDENT_RECEIPT &&
                  paymentVoucherDetails.v_book_type ===
                    VoucherBookKey.STUDENT_REFUND_RECEIPT_BOOK)) ? (
                <div className="voucher-receipts__block--sender-info--signatures--reciever-type">
                  <span>{USE_VOUCHERS_SIGNED_BY}</span>
                </div>
              ) : (
                <div className="voucher-receipts__block--payment-info--trans-summary--amount">
                  <span>
                    Rs. {format(paymentVoucherDetails?.v_total_cr_amt!)}
                  </span>
                </div>
              )}

              <div className="voucher-receipts__block--sender-info--signatures--reciever-sign">
                <span>(Name) & Signature of Receiver</span>
              </div>
            </div>
          </div>
        </div>
        {USE_CREATED_TIME ? (
          <span className="voucher-receipts__block--time">
            {toIsoDate(paymentVoucherDetails?.created_at!)}
          </span>
        ) : null}
      </div>
    </>
  );
};

export default Index;
