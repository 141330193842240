import React, { useContext, useEffect, useState } from "react";
import { SelectedStudentsProps } from "../hooks/useStudentMarksStatistics";
import useToken from "../../../../customhooks/useToken";
import {
  AcdStudentDetails,
  StudentAcademicData,
  StudentAcademicVars
} from "../../../Academics/hooks/useAcdStudentDetails";
import { GetAcdStudentDetails } from "../../../Academics/queries/student";
import { useLazyQuery } from "@apollo/client";
import InstInformation from "../common/InstDetails";
import useReportCardConfig from "../../../Academics/Reports/CompleteReports/ReportCards/hooks/useReportCardConfig";
import MarksDetails, { MarksDetailsTableFor } from "../common/MarksDetails";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import { EMPTY_STRING } from "../../../../utils/constants";
import { AppContext } from "../../../../context/context";
import Avatar from "../../../../images/Avatar.svg";
import { toStandardDate } from "../../../../utils/UtilFunctions";
import GradingScale from "../common/GradingScale";
import { getDownloadUrl } from "../../../../utils/DownloadFileWithPromise";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import useInstLogoDetails from "../../../../customhooks/useInstLogoDetails";
import { FileUploadParams } from "../../../../utils/Enum.types";

export interface StudentDataType extends AcdStudentDetails {
  profile_pic: string;
}
const RPTemplate3 = ({
  selectedStudents,
  setStudentIdSet
}: SelectedStudentsProps) => {
  const { token } = useToken();
  const { InstDetails, InstFormData } = useInstDetails(1);
  const { LogoOrSign: PrincipalSign } = useInstLogoDetails({
    filetype: FileUploadParams.PRINCIPAL_SIGNATURE
  });

  const [students, setStudents] = useState<StudentDataType[]>([]);

  const [GetStudentDetailsByIds, { data: studentData }] = useLazyQuery<
    StudentAcademicData,
    StudentAcademicVars
  >(GetAcdStudentDetails, {
    variables: { token, ids: selectedStudents },
    fetchPolicy: "network-only"
  });
  useEffect(() => {
    if (token && selectedStudents) {
      GetStudentDetailsByIds();
    }
  }, [token, GetStudentDetailsByIds, selectedStudents]);

  const {
    attendanceStats,
    heightWeight,
    annualResults,
    stdAddress,
    dob,
    stdImage,
    title,
    stdName,
    section,
    fatherName,
    motherName,
    rollNo,
    gradeScale,
    borderType,
    signatureType,
    classTeacherSign,
    parentSign,
    otherDetails,
    attendanceDetails,
    signatureFromInst
  } = useReportCardConfig();
  const { classLabel } = useInstLabels();
  // useEffect(() => {
  //   if (studentData) {
  //     if (
  //       studentData?.nodes[0].std_profile_filename !== EMPTY_STRING &&
  //       InstDetails.data
  //     ) {
  //       const studentProfiePicUrl = `${InstDetails.data?.nodes[0]?.inst_name}/students/${state.studentId}/std_profile_pic/std_profile_pic`;

  //       getDownloadUrl(studentProfiePicUrl, false, setImageString);
  //     }
  //   }
  // }, [studentData, InstDetails.data, state.studentId]);

  useEffect(() => {
    const fetchData = () => {
      if (studentData)
        studentData.nodes.map(async (std) => {
          if (InstDetails.data && std.std_profile_filename) {
            const fileName = `${InstFormData.inst_name}/students/${std.id}/std_profile_pic/std_profile_pic`;
            const url = await getDownloadUrl(fileName, false);
            setStudents((prev) => {
              return [
                ...prev,
                {
                  ...std,
                  profile_pic: url
                }
              ];
            });
          } else {
            setStudents((prev) => {
              return [
                ...prev,
                {
                  ...std,
                  profile_pic: EMPTY_STRING
                }
              ];
            });
          }
        });
    };
    fetchData();
  }, [studentData]);

  return (
    <div className="rp-template-3">
      {studentData
        ? students.map((std, index) => {
            return (
              <React.Fragment key={index}>
                <div
                  className="rp-template-3__page1"
                  style={{
                    border: borderType?.config_boolean_value
                      ? `2px ${borderType.config_string_value} var(--text-color)`
                      : ""
                  }}
                >
                  <InstInformation />
                  <br />
                  {title?.config_boolean_value && (
                    <b className="progress-report__title">
                      {title.config_string_value}
                    </b>
                  )}

                  <br />
                  <div className="rp-template-3__std">
                    <div className="rp-template-3__std--details">
                      {stdName?.config_boolean_value && (
                        <>
                          <div className="bgs-pn-pr__std-details--grid">
                            <label>{stdName.config_string_value}</label>
                            <span>:</span>
                            <input
                              type="text"
                              value={
                                std.first_name +
                                " " +
                                std.middle_name +
                                " " +
                                std.last_name
                              }
                            />
                          </div>
                        </>
                      )}

                      {section?.config_boolean_value && (
                        <>
                          <div className="bgs-pn-pr__std-details--grid">
                            <label>{section.config_string_value}</label>
                            <span>:</span>
                            <input
                              type="text"
                              value={std.acd_class.class_desc}
                            />
                          </div>
                        </>
                      )}

                      {rollNo?.config_boolean_value && (
                        <div className="bgs-pn-pr__std-details--grid">
                          <label>{rollNo.config_string_value}</label>
                          <span>:</span>
                          <input type="text" value={std.std_adm_no} />
                        </div>
                      )}
                      {dob?.config_boolean_value && (
                        <div className="bgs-pn-pr__std-details--grid">
                          <label>{dob.config_string_value}</label>
                          <span>:</span>
                          <input
                            type="text"
                            value={toStandardDate(
                              studentData ? std.std_dob : EMPTY_STRING
                            )}
                          />
                        </div>
                      )}
                      {fatherName?.config_boolean_value && (
                        <div className="bgs-pn-pr__std-details--grid">
                          <label>{fatherName.config_string_value}</label>
                          <span>:</span>
                          <input type="text" value={std.std_father_name} />
                        </div>
                      )}
                      {motherName?.config_boolean_value && (
                        <div className="bgs-pn-pr__std-details--grid">
                          <label>{motherName.config_string_value}</label>
                          <span>:</span>
                          <input type="text" value={std.std_mother_name} />
                        </div>
                      )}

                      {stdAddress?.config_boolean_value && (
                        <div className="bgs-pn-pr__std-details--grid">
                          <label>{stdAddress.config_string_value}</label>
                          <span>:</span>
                          <input
                            type="text"
                            value={std.std_details.std_corr_address}
                          />
                        </div>
                      )}
                    </div>
                    {stdImage && stdImage.config_boolean_value ? (
                      <div className="rp-template-3__std--image">
                        {std.profile_pic ? (
                          <img
                            src={std.profile_pic}
                            alt=""
                            className="rp-template-3__std--image"
                          />
                        ) : (
                          <img src={Avatar} alt="" />
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="page-break" />
                <div className="rp-template-3__page2">
                  <InstInformation />
                  {title?.config_boolean_value && (
                    <b className="progress-report__title">
                      {title.config_string_value}
                    </b>
                  )}
                  <div className="bgs-pn-pr__acd-details">
                    <MarksDetails
                      studentData={std}
                      selectedStudents={[std.id]}
                      setStudentIdSet={setStudentIdSet}
                      type={MarksDetailsTableFor.ACADEMIC}
                    />
                  </div>
                  <br />

                  <div className="rp-template-3__page2--flex">
                    <div className="rp-template-3__page2--other-details">
                      <div className="bgs-pn-pr__non-acd">
                        <MarksDetails
                          studentData={std}
                          selectedStudents={[std.id]}
                          setStudentIdSet={setStudentIdSet}
                          type={MarksDetailsTableFor.NON_ACADEMIC}
                        />
                      </div>

                      {otherDetails?.config_boolean_value ? (
                        <>
                          <br />
                          <div className="bgs-pn-pr__non-acd">
                            <MarksDetails
                              studentData={std}
                              selectedStudents={[std.id]}
                              setStudentIdSet={setStudentIdSet}
                              type={MarksDetailsTableFor.OTHER_DETAILS}
                            />
                          </div>
                        </>
                      ) : null}

                      {attendanceDetails?.config_boolean_value ? (
                        <>
                          <br />
                          <div className="bgs-pn-pr__non-acd">
                            <MarksDetails
                              studentData={std}
                              selectedStudents={[std.id]}
                              setStudentIdSet={setStudentIdSet}
                              type={MarksDetailsTableFor.ATTENDANCE}
                            />
                          </div>
                          <br />
                        </>
                      ) : null}
                    </div>
                    {gradeScale?.config_boolean_value && (
                      <div className="rp-template-3__page2--grade">
                        <GradingScale />
                      </div>
                    )}
                  </div>
              
                    <>
                      <br />
                      <div className="bgs-pn-pr__non-acd">
                        <MarksDetails
                          studentData={std}
                          selectedStudents={[std.id]}
                          setStudentIdSet={setStudentIdSet}
                          type={MarksDetailsTableFor.SIGNATURES}
                        />
                      </div>
                    </>
                  

                  {annualResults?.config_boolean_value && (
                    <>
                      <div className="bgs-pn-pr__annual-results">
                        <b>Annual Result's</b>
                        <div className="bgs-pn-pr__annual-results--status">
                          Passed and Promoted
                        </div>
                        <div className="bgs-pn-pr__annual-results--to">
                          <label>To {classLabel}</label>
                          <input type="text" value="" />
                        </div>
                      </div>
                    </>
                  )}
                  <br />
                  <div className="page-break"></div>
                  <br />
                </div>
              </React.Fragment>
            );
          })
        : null}
    </div>
  );
};

export default RPTemplate3;
