import React, { useContext, useEffect, useRef, useState } from "react";
import {
  GridAlignment,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import Avatar from "../../../../images/Avatar.svg";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../../styles/DataGridTableStyles";
import useMasterTableJson, {
  TableColumn,
} from "../../../../json/useMasterTableJson";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import useToken from "../../../../customhooks/useToken";

import useInstDetails from "../../../../customhooks/general/useInstDetails";
import { updateStudentById } from "../../../../queries/students/mutations/update";
import {
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";
import {
  Direction,
  Operation,
  OrderBy,
  SortBy,
} from "../../../../utils/Enum.types";
import {
  getModifiedScrollHeight,
  removeMoreSpace,
} from "../../../../utils/UtilFunctions";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import { TableHeaderProps } from "../../../../utils/types";
import { handleUploadAndDownloadFile } from "../../../../utils/Upload";
import { GetStudentDetailsForRefetch } from "../../../../queries/common";
import { Title } from "../../../../stories/Title/Title";
import { Button } from "../../../../stories/Button/Button";
import PicPreview from "../PicPreview";
import LoadingModal from "../../../../pages/LoadingModal";
import Home from "../../Home/Index";
import { AppContext } from "../../../../context/context";
import Upload from "../../../../images/Upload.svg";
import { GetAcdStudents } from "../../../Academics/queries/students/Index";
import {
  AcdStudentsvars,
  GetStudentAcdData,
  StudentEdges,
} from "../../../Academics/hooks/useAcdStudentsData";
import { StudentAcdType } from "../../../../utils/studentAcdEnum.types";
import { TextField } from "@mui/material";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";
import { Keys } from "../../../../utils/Enum.keys";
import MessageModal from "../../../../pages/MessageModal";
import { msgType } from "../../../../utils/Form.types";

export interface previewStudentType {
  adm_no: string;
  image: File | null;
  std_name: string;
  flag: boolean;
}
const BulkProfilePicUpload = () => {
  const { StudentsBulkPic } = useMasterTableJson();
  const { InstId, entryId } = useParams();
  const { user_details } = useLoggedInUserDetails();
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const navigate = useNavigate();
  const [rows, setRows] = useState<GridValidRowModel[]>([]);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [stateChange, setStateChange] = useState(false);
  const [fetchingProgress, setFetchingProgress] = useState(false);
  const [students, setStudents] = useState<StudentEdges[]>([]);
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const { InstDetails } = useInstDetails(1);
  const [progress, setProgress] = useState(0);
  const [updateStudent, { loading: updateLoading }] =
    useMutation(updateStudentById);
  const [previewStudent, setPreviewStudent] = useState<previewStudentType>({
    adm_no: "",
    image: null,
    std_name: "",
    flag: false,
  });
  const [endCursor, setEndCursor] = useState<string | null>(null);

  const [adm_numbers, setAdmNumbers] = useState<Map<string, File>>(new Map());

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const arrayOfAdmNumber = Array.from(adm_numbers.keys());

  const [GetStudentsData, { data, loading, error, fetchMore }] = useLazyQuery<
    GetStudentAcdData,
    AcdStudentsvars
  >(GetAcdStudents, {
    variables: {
      after: null,
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      first: ROWS_PER_PAGE,
      name: EMPTY_STRING,
      token,
      orderBy: [
        {
          direction: Direction.ASC,
          field: SortBy.FIRST_NAME,
        },
      ],
      input: {
        ids: [Number(InstId), Number(entryId), 0],
        acd_std_query_type:
          status === OrderBy.ADM
            ? StudentAcdType.STDS_BY_ADMISSION_NUMS
            : status === OrderBy.NAME
            ? StudentAcdType.STDS_BY_NAMES
            : status === OrderBy.ROLL_NO
            ? StudentAcdType.STDS_BY_ROLL_NUMS
            : status === OrderBy.REG_NO
            ? StudentAcdType.STDS_BY_REG_NUMS
            : StudentAcdType.STDS_BY_ADMISSION_NUMS,
        str_data: arrayOfAdmNumber,
      },
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (arrayOfAdmNumber.length) {
      GetStudentsData();
    }
  }, [
    state.ActiveFinYr,
    entryId,
    InstId,
    arrayOfAdmNumber.length,
    GetStudentsData,
  ]);
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setStudents([]);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;
      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: FETCH_MORE_DATA,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetAcdStudents.edges;
                const pageInfo = fetchMoreResult.GetAcdStudents.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck = prevResult.GetAcdStudents.edges.filter(
                  ({ node: { id } }) =>
                    newEdges.findIndex(
                      ({ node: { id: newId } }) => newId === id
                    ) !== -1
                );

                if (duplicateCheck.length > 0) return prevResult;

                return {
                  GetAcdStudents: {
                    edges: [...students, ...newEdges],
                    pageInfo,
                    totalCount: data ? data.GetAcdStudents.totalCount! : 0,
                  },
                };
              },
            });
          }
        }
      };
      if (scrollTable && rows.length)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },

    // eslint-disable-next-line
    [rows]
  );
  const handleFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFetchingProgress(true);

    if (event.target.files) {
      const { files } = event.target;
      const tempAdmNumbers = Object.values(files).reduce((acc, element) => {
        const adm_number: string = (element as unknown as File).name.split(
          "."
        )[0];

        acc.set(adm_number, element);
        return acc;
      }, new Map() as Map<string, File>); // Handle selected files

      setAdmNumbers(tempAdmNumbers);
    }

    if (rows.length !== 0 && rows.length > 0) {
      setFetchingProgress(false);
    }
  };

  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();

  const enabledMasters = (ele: TableColumn) => {
    if (ele.field === "dept_desc") return USE_DEPARTMENT_KEY;
    else if (ele.field === "branch_desc") return USE_BRANCH_KEY;
    else if (ele.field === "class_desc") {
      return USE_CLASS_KEY;
    } else if (ele.field === "sem_desc") return USE_SEMESTER_KEY;
    else if (ele.field === "section_desc") return USE_SECTION_KEY;
    else return true;
  };

  const dynamicHeaders: TableHeaderProps[] =
    StudentsBulkPic.Table_Headers.filter(enabledMasters).map((header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.headerAlign as GridAlignment,
      flex: header.flex,
      hideable: header.hideable,
    }));
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Sl",
      headerAlign: HEADER_TEXT_ALIGN,
      align: SLNO_TEXT_ALIGN,
      cellClassName: "td-sl-no",
    },
    ...dynamicHeaders,
    {
      field: "std_profile_pic",
      headerName: "Profile Pic",
      cellClassName: "td-center",
      headerAlign: HEADER_TEXT_ALIGN,
      align: "center" as GridAlignment,
      renderCell: (params) => {
        const foundStudent = adm_numbers.get(params.row.stdId);
        if (foundStudent) {
          const imageUrl = URL.createObjectURL(foundStudent);
          return (
            <img
              src={imageUrl}
              alt={params.row.stdId}
              onClick={() => {
                setPreviewStudent({
                  adm_no: params.row.std_adm_no,
                  flag: true,
                  image: foundStudent,
                  std_name: params.row.std_name,
                });
              }}
              className="profile-pic__image"
            />
          );
        } else {
          return <img src={Avatar} alt={params.row.std_name} />;
        }
      },
    },
  ];

  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetAcdStudents.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newRow) => {
          const filteredStudent = rows.find(
            (row) => row.custId && row.custId === newRow.node.id
          );
          if (filteredStudent) {
            return {
              ...newRow,
              node: {
                ...newRow.node,
              },
            };
          }
          return newRow;
        });
        setStudents(updatedNewData);

        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            stdId: node.id,
            std_adm_no: node.std_adm_no,
            std_roll_no: node.std_roll_no,
            std_reg_no: node.std_reg_no,
            std_name: `${node.first_name} ${node.middle_name} ${node.last_name}`,
            parent_name: EMPTY_STRING,
            dept_desc: node.acd_dept.dept_desc,
            branch_desc: node.acd_branch.branch_desc,
            class_desc: node.acd_class.class_desc,
            sem_desc: node.acd_semester.sem_desc,
            section_desc: node.acd_section.section_desc,
            std_status: node.std_status,
          }))
        );
      } else {
        setStudents(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            stdId: node.id,
            std_adm_no: node.std_adm_no,
            std_roll_no: node.std_roll_no,
            std_reg_no: node.std_reg_no,
            std_name: `${node.first_name} ${node.middle_name} ${node.last_name}`,
            parent_name: EMPTY_STRING,
            dept_desc: node.acd_dept.dept_desc,
            branch_desc: node.acd_branch.branch_desc,
            class_desc: node.acd_class.class_desc,
            sem_desc: node.acd_semester.sem_desc,
            section_desc: node.acd_section.section_desc,
            std_status: node.std_status,
          }))
        );
      }
      setEndCursor(data.GetAcdStudents.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);

  const changePic = (adm_no: string, image: File) => {
    const adm_nums = adm_numbers;
    adm_nums.set(adm_no, image);
    setAdmNumbers(adm_nums);
    setStateChange(!stateChange);
  };

  // const uploadFiles = () => {
  //   rows.forEach(async (item) => {
  //     console.log(item, adm_numbers, "item");

  //     const file = adm_numbers.get(
  //       status === OrderBy.NAME
  //         ? removeMoreSpace(item.std_name)
  //         : status === OrderBy.ADM
  //         ? removeMoreSpace(item.std_adm_no)
  //         : status === OrderBy.ROLL_NO
  //         ? item.std_roll_no
  //         : status === OrderBy.REG_NO
  //         ? removeMoreSpace(item.std_reg_no)
  //         : EMPTY_STRING
  //     );
  //     console.log(file, "file");

  //     const file_type = file
  //       ? file.name.split(".")[file.name.split(".").length - 1]
  //       : EMPTY_STRING;
  //     const file_name = `${InstDetails.data?.nodes[0]?.inst_name}/students/${item.stdId}/std_profile_pic/std_profile_pic.${file_type}`;
  //     if (file_type && file) {
  //       await handleUploadAndDownloadFile(
  //         file,
  //         file_name,
  //         setProgress,
  //         false
  //       ).then((res) => {
  //         if (res) {
  //           updateStudent({
  //             variables: {
  //               id: item.stdId,
  //               token,
  //               inst_id: InstId!,
  //               user_details,
  //               input: {
  //                 std_profile_filename: file_name,
  //               },
  //             },
  //             refetchQueries: [
  //               {
  //                 query: GetStudentDetailsForRefetch,
  //                 variables: {
  //                   token,
  //                   ids: [item.stdId],
  //                 },
  //               },
  //             ],
  //           }).then(({ data }) => {
  //             if (data && data.UpdateStudentById) {
  //               if (data.UpdateStudentById.id === rows[rows.length - 1].stdId) {
  //                 setMessage({
  //                   flag: true,
  //                   message: "Students Profile Updated Successfully",
  //                   operation: Operation.CREATE,
  //                 });
  //               }
  //             }
  //           });
  //         }
  //       });
  //     }
  //   });
  // };
  const uploadFiles = () => {
    rows.forEach(async (item) => {
      const key =
        status === OrderBy.NAME
          ? removeMoreSpace(item.std_name)
          : status === OrderBy.ADM
          ? removeMoreSpace(item.std_adm_no)
          : status === OrderBy.ROLL_NO
          ? String(item.std_roll_no).trim()
          : status === OrderBy.REG_NO
          ? removeMoreSpace(item.std_reg_no)
          : EMPTY_STRING;

      const file = adm_numbers.get(key);

      if (!file) {
        console.warn(`File not found for key: ${key}`);
        return;
      }

      const file_type = file.name.split(".").pop() || EMPTY_STRING;
      const file_name = `${InstDetails.data?.nodes[0]?.inst_name}/students/${item.stdId}/std_profile_pic/std_profile_pic.${file_type}`;

      if (file_type) {
        await handleUploadAndDownloadFile(
          file,
          file_name,
          setProgress,
          false
        ).then((res) => {
          if (res) {
            updateStudent({
              variables: {
                id: item.stdId,
                token,
                inst_id: InstId!,
                user_details,
                input: {
                  std_profile_filename: file_name,
                },
              },
              refetchQueries: [
                {
                  query: GetStudentDetailsForRefetch,
                  variables: {
                    token,
                    ids: [item.stdId],
                  },
                },
              ],
            }).then(({ data }) => {
              if (data && data.UpdateStudentById) {
                if (data.UpdateStudentById.id === rows[rows.length - 1].stdId) {
                  setMessage({
                    flag: true,
                    message: "Students Profile Updated Successfully",
                    operation: Operation.CREATE,
                  });
                }
              }
            });
          }
        });
      }
    });
  };

  useEffect(() => {
    if (
      (rows.length > 0 &&
        adm_numbers.size > 0 &&
        rows.length <= adm_numbers.size) ||
      (data && rows.length === 0) ||
      error
    ) {
      setFetchingProgress(false);
    }
  }, [rows, adm_numbers.size, data, error]);

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Bulk Profile Pic Upload</Title>
      <div className="bulk_pic_upload">
        <div className="bulk_pic_upload__select row g-0">
          <div className="col-2">
            <div className="bulk_pic_upload__file">
              <input
                type="file"
                ref={fileInputRef}
                accept="image/*"
                onChange={handleFileChange}
                multiple
              />

              <button type="button" onClick={handleFileClick}>
                <img src={Upload} alt="" /> Select Folder
              </button>

              {/* <b>
                {" "}
                {adm_numbers.size
                  ? `${adm_numbers.size} Image${
                      adm_numbers.size > 1 ? "s" : ""
                    } Selected`
                  : null}
              </b> */}
            </div>
          </div>

          <div className="col-2">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={Object.values(OrderBy)}
              openOnFocus
              value={status}
              isOptionEqualToValue={(option) =>
                status === "" || option === status
              }
              onChange={(e, newValue) => {
                if (newValue) {
                  setStatus(newValue as OrderBy);
                  setHasNextPage(true);
                } else {
                  setStatus(EMPTY_STRING);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === Keys.BACKSPACE) {
                  setStatus(EMPTY_STRING);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Order By"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  className={labelClasses.formControlRoot}
                  fullWidth
                />
              )}
            />
          </div>
        </div>

        <div className={`bulk_pic_upload__tableblock`}>
          <StyledDatagrid
            columns={columns}
            rows={rows}
            onCellClick={() => {}}
            disableRowSelectionOnClick
            rowHeight={TABLE_ROW_HEIGHT}
            hideFooter
          />
        </div>

        <Button
          mode="save"
          onClick={uploadFiles}
          disabled={!rows.length ? true : false}
        />
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      <PicPreview
        details={previewStudent}
        setPreviewStudent={setPreviewStudent}
        changePic={changePic}
      />

      <LoadingModal flag={fetchingProgress || updateLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default BulkProfilePicUpload;
