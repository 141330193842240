import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DropResult,
} from "react-beautiful-dnd";
import { useParams } from "react-router-dom";
import usePageDetailsByPageNumber from "../../../../customhooks/usePageDetailsByPageNumber";
import useToken from "../../../../customhooks/useToken";
import LoadingModal from "../../../../pages/LoadingModal";
import MessageModal from "../../../../pages/MessageModal";
import {
  GetSwConfigPageInfo,
  GetSwConfigVariablesPageAssociByPageNumber,
} from "../../../../queries/institution/configuration/query/SoftwreConfig";
import { Button } from "../../../../stories/Button/Button";
import { Label } from "../../../../stories/Label/Label";
import { Select } from "../../../../stories/Select/Select";
import { Title } from "../../../../stories/Title/Title";
import {
  SoftwareDetails,
  PageSoftwareDetailsVars,
  PageSwData,
} from "../../../../Types/configtypes";
import { TableHeaderProps } from "../../../../Types/Tables";

import { msgType } from "../../../../utils/Form.types";
import {
  reOrderProcess,
  toStandardCase,
} from "../../../../utils/UtilFunctions";

import { AddSwConfigVariablesPageAssoci } from "../../../../queries/institution/configuration/mutation";
import { Operation, PageFor } from "../../../../utils/Enum.types";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import useAcctTableJson from "../../../Accounts/json/useAcctTableJson";

interface Props {
  pageType: PageFor;
  modalpageNumber?: number;
  setModal?: React.Dispatch<React.SetStateAction<boolean>>;
}
const Reorder = ({ pageType, modalpageNumber, setModal }: Props) => {
  const { token } = useToken();
  const { user_details } = useLoggedInUserDetails();
  const { Accounts_Table } = useAcctTableJson();
  const [pageNumber, setPageNumber] = useState("");
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [localItems, setLocalItems] = useState<SoftwareDetails[]>([]);
  const [item, setitems] = useState<SoftwareDetails[]>([]);
  const [GetConfigData, { data }] = useLazyQuery<
    PageSwData,
    PageSoftwareDetailsVars
  >(GetSwConfigPageInfo, {
    variables: { token },
  });

  const pageNumberInfo = data?.GetSwConfigPageInfo?.find(
    ({ id }) => id === Number(pageNumber)
  );

  const {
    data: PageDetails,
    GetSwConfigVariablesPageAssociByPageNumberLoading,
  } = usePageDetailsByPageNumber(
    // pageType === PageFor.GENERAL
    // ?
    pageNumberInfo?.page_number!
    // : modalpageNumber!
  );

  //mutations
  const [AddPageDisplayConfigDetails, { loading: creationLoading }] =
    useMutation(AddSwConfigVariablesPageAssoci, {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    });
  const { InstId } = useParams();
  useEffect(() => {
    if (
      pageNumber &&
      PageDetails &&
      !GetSwConfigVariablesPageAssociByPageNumberLoading
    ) {
      setLocalItems(PageDetails);
    }
  }, [
    PageDetails,
    pageNumber,
    GetSwConfigVariablesPageAssociByPageNumberLoading,
  ]);

  const handleDragAndDrop = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    setLocalItems((prev: SoftwareDetails[]) => {
      const temp = [...prev];
      reOrderProcess(result, temp);
      setitems(temp);
      return temp;
    });
  };

  const HandleSaveDragAndDrop = () => {
    // eslint-disable-next-line
    item?.map((element, index: number) => {
      if (localItems[index].config_details.id === element.config_details.id) {
        AddPageDisplayConfigDetails({
          variables: {
            token,
            input: {
              sw_config_variable_id: item[index].config_details.id,
              idx: index + 1,
              sw_config_page_info_id: pageNumber,
              inst_id: InstId,
            },
            user_details,
          },
          refetchQueries: [
            {
              query: GetSwConfigVariablesPageAssociByPageNumber,
              variables: {
                token,
                page_number: pageNumberInfo?.page_number,
                inst_id: InstId,
              },
            },
          ],
        }).then(({ data }) => {
          if (data) {
            setMessage({
              message: "Page Configuration Re-Ordered Successfully",
              operation: Operation.CREATE,
              flag: true,
            });
          }
        });
      }
    });
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    if (token) {
      GetConfigData();
    }
  }, [token, GetConfigData]);
  return (
    <>
      <Title>Re-Order Page Configurations</Title>
      <div className="row g-0 reorder-accountledger__search">
        <div className="col"></div>
        {/* {pageType === PageFor.GENERAL && ( */}
        <div className="col-3 label-grid">
          <Label>Page Name</Label>
          <Select
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPageNumber(e.target.value)
            }
            value={pageNumber}>
            {data?.GetSwConfigPageInfo?.map((option, index) => (
              <option key={index} value={option.id}>
                {toStandardCase(option.page_name)}
              </option>
            ))}
          </Select>
        </div>
        {/* )} */}
      </div>
      <div className="reorder-accountledger__tableblock--modal">
        <TableContainer className="reorder-accountledger__tableblock--table">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {Accounts_Table.AccountLedger.Reorder.map(
                  (th: TableHeaderProps, index: React.Key) => {
                    return <TableCell key={index}> {th.labelName}</TableCell>;
                  }
                )}
              </TableRow>
            </TableHead>
            <DragDropContext onDragEnd={handleDragAndDrop}>
              <Droppable droppableId="droppable" direction="vertical">
                {(droppableProvided: DroppableProvided) => (
                  <TableBody
                    ref={droppableProvided.innerRef}
                    {...droppableProvided.droppableProps}>
                    {localItems?.map((response, index: number) => (
                      <Draggable
                        key={index}
                        draggableId={index.toString()}
                        index={index}>
                        {(
                          draggableProvided: DraggableProvided,
                          snapshot: DraggableStateSnapshot
                        ) => {
                          return (
                            <TableRow
                              key={index}
                              ref={draggableProvided.innerRef}
                              {...draggableProvided.draggableProps}>
                              <TableCell
                                {...draggableProvided.dragHandleProps}
                                id="td-center"
                                className="reorder-accountledger__tableblock--table--slno">
                                =
                              </TableCell>

                              <TableCell
                                {...draggableProvided.dragHandleProps}
                                id="td-center"
                                className="reorder-accountledger__tableblock--table--slno">
                                {index + 1}
                              </TableCell>

                              <TableCell
                                {...draggableProvided.dragHandleProps}
                                key={index}>
                                {
                                  response.config_details
                                    .config_form_label_caption
                                }
                              </TableCell>
                            </TableRow>
                          );
                        }}
                      </Draggable>
                    ))}
                    {droppableProvided.placeholder}
                  </TableBody>
                )}
              </Droppable>
            </DragDropContext>
          </Table>
        </TableContainer>
      </div>

      {localItems?.length > 1 ? (
        <>
          <Button mode="save-order" onClick={() => HandleSaveDragAndDrop()} />
        </>
      ) : null}
      <Button mode="cancel" onClick={() => setModal?.(false)} />

      <LoadingModal flag={creationLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};
export default Reorder;
