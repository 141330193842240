import { useLazyQuery } from "@apollo/client";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../../context/context";
import { payloadTypes } from "../../../../../context/reducer";
import useToken from "../../../../../customhooks/useToken";
import {
  PassedOutStudentDetailsByMasterVoucherId,
  StudentDetailsByMasterVoucherId,
} from "../../../../../queries/common";
import {
  PrintConfigModalStyles,
  PrintModalStyles,
} from "../../../../../styles/ModalStyles";
import { nodevars, VouchernodeData } from "../../../../../Types/Accounting";
import Print from "../../../../Print/Accounts/FeeReceipts/Standard/FeeReceiptPrint";
import { toStandardDate } from "../../../../../utils/UtilFunctions";
import Close from "../../../../../images/Close.svg";
import Modal from "react-modal";
import PrintImg from "../../../../../images/Print.svg";
import { Title } from "../../../../../stories/Title/Title";
import {
  DebitOrCredit,
  ModuleName,
  NonDemand,
  PageFor,
  UserType,
} from "../../../../../utils/Enum.types";
import { Data } from "../../../../Print/Accounts/FeeReceipts/templates/Index";
import Settings from "../../../../../images/Settings.svg";
import Configurations from "../../../../Configurations/PerModuleConfiguration";
import { SwConfigQueryType } from "../../../../HR/enums/Enum.types";
import useAssignedInstbyEmpId, {
  InstUserAccessQueryType,
} from "../../../../UserRights/hooks/useAssignedInstbyEmpId";

export interface IMultipleReceipts {
  id: number;
  v_date: string;
  v_no: string;
}
interface IProps {
  items: IMultipleReceipts[];
  type: NonDemand;
}
const MultipleReceipts = ({ items, type }: IProps) => {
  const { dispatch, state } = useContext(AppContext);
  const { token } = useToken();

  const [tableData, setTableData] = useState<Data[]>([]);
  const [v_no, set_vo_no] = useState("");
  const [transaction_no, set_transaction_no] = useState("");

  const [v_date, set_v_Date] = useState("");
  const [printModal, setPrintModal] = useState(false);
  const [prinConfigModal, setPrintConfigModal] = useState(false);

  const [GetVoucherDetailsByVoucherId] = useLazyQuery<
    VouchernodeData,
    nodevars
  >(StudentDetailsByMasterVoucherId);

  const [GetPassedOutVoucherDetailsByVoucherId] = useLazyQuery<
    VouchernodeData,
    nodevars
  >(PassedOutStudentDetailsByMasterVoucherId);
  useEffect(() => {
    if (state.receiptId) {
      if (type === NonDemand.ROLE) {
        GetVoucherDetailsByVoucherId({
          variables: { token, ids: [state.receiptId] },
        }).then(({ data }) => {
          if (data) {
            const { v_no, v_date, acct_voucher_details } = data.nodes[0];
            set_v_Date(v_date);
            set_vo_no(v_no);
            set_transaction_no(transaction_no);
            setTableData(
              acct_voucher_details
                .filter((detail) => detail.vo_cr_db === DebitOrCredit.CREDIT)
                .map((detail) => ({
                  amount: detail.vo_cr,
                  particular: detail.acct_ldgr.ldgr_desc,
                }))
            );
          }
        });
      } else {
        GetPassedOutVoucherDetailsByVoucherId({
          variables: { token, ids: [state.receiptId] },
        }).then(({ data }) => {
          if (data) {
            const { v_no, v_date, acct_voucher_details } = data.nodes[0];
            set_v_Date(v_date);
            set_vo_no(v_no);
            set_transaction_no(transaction_no);
            setTableData(
              acct_voucher_details
                .filter((detail) => detail.vo_cr_db === DebitOrCredit.CREDIT)
                .map((detail) => ({
                  amount: detail.vo_cr,
                  particular: detail.acct_ldgr.ldgr_desc,
                }))
            );
          }
        });
      }
    }
  }, [
    state.receiptId,
    GetVoucherDetailsByVoucherId,
    GetPassedOutVoucherDetailsByVoucherId,
    type,
    token,
  ]);
  const { MultipleInstitutions } = useAssignedInstbyEmpId(
    InstUserAccessQueryType.USER_ACCESS_BY_EMP_ID,
    state.empLoginId
  );
  const isAdmin =
    MultipleInstitutions?.data?.GetInstUserAccess.edges[0]?.node?.pr_emp_details
      .emp_type === UserType.SYS_ADMIN;

  return (
    <>
      <Title>Vouchers Generated</Title>
      <TableContainer className="multiplereceipts__container">
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className="multiplereceipts--sl">Sl</TableCell>
              <TableCell>Vo Date</TableCell>
              <TableCell>Vo No</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, index) => {
              return (
                <TableRow key={index}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell>{toStandardDate(item.v_date)}</TableCell>
                  <TableCell>{item.v_no}</TableCell>
                  <TableCell
                    align="center"
                    className="multiplereceipts--actions"
                  >
                    <img
                      src={PrintImg}
                      alt="/"
                      onClick={() => {
                        dispatch({
                          type: payloadTypes.SET_RECEPIT_ID,
                          payload: { receiptId: item.id },
                        });
                        setPrintModal(!printModal);
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={printModal}
        style={PrintModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Print
              tableData={tableData}
              v_date={v_date}
              v_no={v_no}
              transaction_no={transaction_no}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setPrintModal(!printModal)}
            />
            {isAdmin ? (
              <>
                <img
                  src={Settings}
                  alt="/"
                  id="settings-icon"
                  onClick={() => setPrintConfigModal(!prinConfigModal)}
                />
              </>
            ) : null}
          </div>
        </div>
      </Modal>
      {/* print configuration modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={prinConfigModal}
        style={PrintConfigModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Configurations
              config_query_type={SwConfigQueryType.INST_BY_MODULE}
              str_value={ModuleName.RECEIPT_PRINT}
              int_value={0}
              setModalFlag={setPrintConfigModal}
              pageType={PageFor.MODAL}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setPrintConfigModal(!prinConfigModal)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default MultipleReceipts;
