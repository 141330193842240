import { GridAlignment } from "@mui/x-data-grid-pro";
import { BankOrCash } from "./Enum.types";
import { responseType } from "./Form.types";

export enum OtherAccountLedgerBooks {
  REGISTER = "REGISTER",
  GROUP_LEDGER = "GROUP_LEDGER",
  FEE_COLLECTED = "FEE_COLLECTED",
}
export type LedgersOrRegisters = BankOrCash | OtherAccountLedgerBooks;

export interface TableHeaderProps {
  headerName: string;
  className?: string;
  field: string;
  headerAlign?: GridAlignment;
  align?: GridAlignment;
  disableColumnMenu?: boolean;
  disableColumnFilter?: boolean;
  cellClassName?: string;
  hideable?: boolean;
}

export interface subjectOptions extends responseType {
  subj_code: string;
}

export interface studentsType {
  dept: string;
  branch: string;
  class: string;
  sem: string;
  section: string;
  category: string;
  std_adm_no: string;
  std_reg_no: string;
  std_name: string;
  std_sts_no: string;
  std_father_name: string;
  std_father_mobile: string;
  std_mother_name: string;
  std_mother_mobile: string;
  std_mobile: string;
  std_email: string;
  std_dob: string;
  std_sex: string;
  std_fresher: string;
  std_doa: string;
  std_aadhar: string;
  std_blood_group: string;
  std_address: string;
  std_place: string;
  std_district: string;
  std_state: string;
  std_pincode: string;
  std_phone: string;
  std_corr_address: string;
  std_corr_place: string;
  std_corr_district: string;
  std_corr_state: string;
  std_corr_pincode: string;
  std_corr_phone: string;
  std_religion: string;
  std_caste: string;
  std_sub_caste: string;
  std_caste_group: string;
  std_sc_st: string;
  std_caste_minority: string;

  std_rural_urban: string;
  std_mother_tongue: string;
  std_annual_income: string;
  std_identity_marks: string;
  std_place_of_birth: string;
  std_nationality: string;
  std_is_phy_challenged: string;
  std_prev_tc_details: string;
  std_prev_inst: string;
  std_prev_quali_exam: string;
  std_prev_studied_board: string;
  std_prev_studied_state: string;
  std_prev_subject_studied: string;
  std_prev_total_marks: string;
  std_prev_total_per: string;
}
