import { gql } from "@apollo/client";

export const AddUserRights = gql`
  mutation AddUserRights(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $pr_emp_id: ID!
    $is_inst_sysadmin: Boolean!
    $input: [CreateUserRightsDetailsInput!]!
  ) {
    AddUserRights(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      pr_emp_id: $pr_emp_id
      input: $input
      is_inst_sysadmin: $is_inst_sysadmin
    )
  }
`;

export const AddInstUserAccess = gql`
  mutation AddInstUserAccess(
    $token: String!
    $inst_ids: [ID!]!
    $pr_emp_id: ID!
    $user_details: SubscribedUser!
  ) {
    AddInstUserAccess(
      token: $token
      inst_ids: $inst_ids
      pr_emp_id: $pr_emp_id
      user_details: $user_details
    )
  }
`;
export const UpdateInstUserAccess = gql`
  mutation UpdateInstUserAccess(
    $token: String!
    $inst_ids: [ID!]!
    $pr_emp_id: ID!
    $user_details: SubscribedUser!
  ) {
    UpdateInstUserAccess(
      token: $token
      inst_ids: $inst_ids
      pr_emp_id: $pr_emp_id
      user_details: $user_details
    )
  }
`;
export const ReCreateUserInFirebase = gql`
  mutation ReCreateUserInFirebase(
    $token: String!
    $pr_emp_id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    ReCreateUserInFirebase(
      token: $token
      inst_id: $inst_id
      pr_emp_id: $pr_emp_id
      user_details: $user_details
    )
  }
`;
export const UpdateUserRightsTheme = gql`
  mutation UpdateUserRightsTheme(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $pr_emp_id: ID!
    $theme: String!
  ) {
    UpdateUserRightsTheme(
      token: $token
      inst_id: $inst_id
      user_details: $user_details

      pr_emp_id: $pr_emp_id
      theme: $theme
    )
  }
`;
