import React, { useContext, useEffect, useState } from "react";
import { Title } from "../../../stories/Title/Title";
import More from "../../../images/More.svg";
import NotEligibleStudents from "../../../images/NotEligibleStudents.svg";
import NotAllocated from "../../../images/NotDecided.svg";

import TotalStudents from "../../../images/TotalStudentsDashboard.svg";
import Boys from "../../../images/Boys.svg";
import Girls from "../../../images/Girls.svg";
import Reservation from "../../../images/TotalReservationStudents.svg";
import ReservedBoys from "../../../images/ReservedBoys.svg";
import ReservedGirls from "../../../images/ReservedGirls.svg";
import TotalNewAdmissions from "../../../images/TotalNewAdmissions.svg";
import Readmission from "../../../images/Readmission.svg";
import Cancelled from "../../../images/PendingMarksEntry.svg";
import Detained from "../../../images/Detained.svg";
import AttendanceShortage from "../../../images/AttendanceShortage.svg";
import DepartmentImage from "../../../images/department.svg";
import BioData from "../../../images/BioData.svg";
import MailingLabel from "../../../images/MailingLabel.svg";
import ReligionWiseList from "../../../images/ReligionWiseList.svg";
import StudentListImage from "../../../images/StudentList.svg";
import StudentSummary from "../../../images/StudentSummary.svg";
import VacantSeats from "../../../images/VacancySeats.svg";
import TotalSeats from "../../../images/TotalSeats.svg";
import CloseImg from "../../../images/Close.svg";
import StudentList from "../../Master/Student/List";
import { MasterDashboardtitleProps } from "../../../Types/Titles";
import { useLazyQuery } from "@apollo/client";
import {
  GetAdmissionDashBoardDetails,
  GetOwnersAdmissionDashBoardDetails,
} from "../../../queries/institution/masterDashboard/new";
import useToken from "../../../customhooks/useToken";
import { useNavigate, useParams } from "react-router-dom";
import {
  GetAdmissionDashBoard,
  GetAdmissionDashBoardDetailsData,
  GetAdmissionDashBoardDetailsVars,
  GetOwnersAdmissionDashBoardDetailsData,
  GetOwnersAdmissionDashBoardDetailsVars,
  instAdmTotalStats,
} from "../Types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import Modal from "react-modal";
import { StudentModalStyles } from "../../../styles/ModalStyles";
import {
  DashBoardFor,
  InstitutionConfigurationTypes,
  PageFor,
  PageLabel,
  StudentDetailedReportType,
  StudentListFor,
  UserType,
} from "../../../utils/Enum.types";
import { StudentReportType } from "../../../utils/Enum.types";
import ReservationPage from "../Student/StudentReservation/StudentReservedList";
import NewAdmissionList from "../../Master/Student/NewAdmission/List";
import StudentBioData from "../../Master/Student/DashboardReports/BioData";
import StudentReligion from "../../Master/Student/DashboardReports/ReligionWise";
import TreeView from "./TreeView";
import StudentSummaryList from "./StudentSummary";
import { Button } from "../../../stories/Button/Button";
import { GetMstInstTreeData } from "../../../customhooks/useMstInstTree";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import ForProprietor from "../../Proprietor/Dashboard/Admissions";
import { AppContext } from "../../../context/context";
import {
  InstTreeQueryType,
  InstTreeVars,
} from "../../HR/Types/masterDataTypes";
import { GetMstInstTree } from "../../../queries/common";
import usePollIntervalTime from "../../../customhooks/usePollIntervalTime";
import useAssignedInstbyEmpId, {
  InstUserAccessQueryType,
} from "../../UserRights/hooks/useAssignedInstbyEmpId";
import useSwConfigData from "../../../customhooks/useSwConfigData";
const { MasterTitles } = require("../../../json/title.json");

interface Props {
  pageType: DashBoardFor;
}
interface Report {
  label: string;
  image: string;
  showModal: () => void;
}
export interface tableType {
  level: string;
  level_name: string;
  description: string;
  boys_count: number;
  girls_count: number;
  total_count: number;
  id: number;
  total_seats: number;
  vacant_seats: number;
  show_seats: boolean;
}
interface keyPairType {
  [key: string]: number;
}
export interface level_ids extends keyPairType {
  level1: number;
  level2: number;
  level3: number;
  level4: number;
}
const MastersDashboard = ({ pageType }: Props) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { state } = useContext(AppContext);
  const navigate = useNavigate();

  const [studentModal, setStudentModal] = useState(false);
  const [studentNEModal, setStudentNEModal] = useState(false);
  const [reservationmodal, setReservationModal] = useState(false);
  const [admissionModal, setAdmissionModal] = useState(false);
  const [bioDataModal, setBioDataModal] = useState(false);
  const [mailingModal, setMailingModal] = useState(false);
  const [religionModal, setRelgionModal] = useState(false);
  const [treeViewModal, setTreeViewModal] = useState(false);
  const [levelId, setLevelId] = useState(0);

  const [admissionDashBoardDetails, setAdmissionDashBoardDetails] =
    useState<GetAdmissionDashBoard>({
      total_cur_std_count: 0,
      boys_cur_std_count: 0,
      girls_cur_std_count: 0,
      total_nxt_std_count: 0,
      boys_nxt_std_count: 0,
      girls_nxt_std_count: 0,
      total_new_admission_count: 0,
      total_cancelled_count: 0,
      total_re_admission_count: 0,
      total_ne_count: 0,
      total_detained_count: 0,
      total_soa_count: 0,
      total_seats_count: 0,
      total_vacant_count: 0,
      std_sec_not_alloc_count: 0,
    });
  const [inst_adm_total_stats, setinst_adm_total_stats] = useState<
    instAdmTotalStats[]
  >([]);
  const [studentSummary, setStudentSummary] = useState(false);
  const pollIntervalTime = usePollIntervalTime();

  const [GetInstTree, { data, loading }] = useLazyQuery<
    GetMstInstTreeData,
    InstTreeVars
  >(GetMstInstTree, {
    variables: {
      inst_id: InstId ? InstId : state.InstId,
      token,
      id: InstId ? InstId : state.InstId,
      query_type: InstTreeQueryType.TREE_BY_INST_ID,
    },
    fetchPolicy: "network-only",
    pollInterval: pollIntervalTime,
  });

  const { configData: vacantData } = useSwConfigData(
    InstitutionConfigurationTypes.ENABLE_TO_MANAGE_VACANT_SEATS
  );
  const vacantFlag =
    vacantData.data &&
    vacantData.data.GetSwConfigVariables.length &&
    vacantData.data.GetSwConfigVariables[0].config_boolean_value;
  const { entry_level } = useInstitutionConfiguration();
  const [table, setTable] = useState<tableType[]>([]);
  const [parent, setParent] = useState(0);
  const [level_ids, setLevelIds] = useState<level_ids>({
    level1: 0,
    level2: 0,
    level3: 0,
    level4: 0,
  });
  const { MultipleInstitutions } = useAssignedInstbyEmpId(
    InstUserAccessQueryType.USER_ACCESS_BY_EMP_ID,
    state.empLoginId
  );
  const isAdminExecutive =
    MultipleInstitutions &&
    MultipleInstitutions.data &&
    MultipleInstitutions.data.GetInstUserAccess.edges.length &&
    MultipleInstitutions.data.GetInstUserAccess.edges[0].node.pr_emp_details
      .emp_type === UserType.ADM_EXECUTIVE;

  const [GetDashBoardDetails, { data: EmpDashBoardData }] = useLazyQuery<
    GetAdmissionDashBoardDetailsData,
    GetAdmissionDashBoardDetailsVars
  >(GetAdmissionDashBoardDetails, {
    variables: {
      token,
      inst_id: InstId!,
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
    },
    pollInterval: pollIntervalTime,
  });
  const [GetAdmOwnerDashBoardDetails, { data: ownerDashBoard }] = useLazyQuery<
    GetOwnersAdmissionDashBoardDetailsData,
    GetOwnersAdmissionDashBoardDetailsVars
  >(GetOwnersAdmissionDashBoardDetails, {
    variables: {
      token,
      cust_id: state.custId,
    },
    pollInterval: pollIntervalTime,
  });
  //Students Total Count

  const totalCounts = table.reduce(
    (acc, item) => {
      acc.boys_count += item.boys_count;
      acc.girls_count += item.girls_count;
      acc.total_count += item.total_count;
      acc.total_seats += item.total_seats;
      if (item.vacant_seats > 0) {
        acc.total_vacant_seats += item.vacant_seats;
      }
      return acc;
    },
    {
      boys_count: 0,
      girls_count: 0,
      total_count: 0,
      total_seats: 0,
      total_vacant_seats: 0,
    }
  );

  const Reports: Report[] = [
    {
      label: "Student List",
      image: `${StudentListImage}`,
      showModal: () => setStudentModal(!studentModal),
    },
    {
      label: "Bio Data",
      image: `${BioData}`,
      showModal: () => setBioDataModal(!bioDataModal),
    },
    {
      label: "Mailing Label",
      image: `${MailingLabel}`,
      showModal: () => setMailingModal(!mailingModal),
    },
    {
      label: "Religion Wise List",
      image: `${ReligionWiseList}`,
      showModal: () => setRelgionModal(!religionModal),
    },
    {
      label: "Student Summary (Fresher / Existing)",
      image: `${StudentSummary}`,
      showModal: () => setStudentSummary(!studentSummary),
    },
  ];

  const setNextLevel = (id: number, step: number) => {
    if (data) {
      switch (step) {
        case 0:
          setTable(
            data.GetMstInstTree.map((tree_data) => ({
              level: tree_data.level_1,
              level_name: tree_data.level_1_name,
              description: tree_data.level_1_desc,
              boys_count: tree_data.boys_count,
              girls_count: tree_data.girls_count,
              id: tree_data.level_1_id,
              total_count: tree_data.total_count,
              total_seats: tree_data.total_seats,
              vacant_seats: tree_data.total_vacant_seats,
              show_seats: tree_data.show_seats_info,
            }))
          );
          setLevelIds({
            level1: 0,
            level2: 0,
            level3: 0,
            level4: 0,
          });
          setParent(step);
          break;
        case 1:
          setTable(
            data.GetMstInstTree.filter(
              ({ level_1_id }) => level_1_id === id
            ).flatMap((level1_data) => {
              const { level_2_details } = level1_data;
              return level_2_details.flatMap((level_2) => {
                return {
                  level: level_2.level_2,
                  level_name: level_2.level_2_name,
                  description: level_2.level_2_desc,
                  boys_count: level_2.boys_count,
                  girls_count: level_2.girls_count,
                  total_count: level_2.total_count,
                  id: level_2.level_2_id,
                  total_seats: level_2.total_seats,
                  vacant_seats: level_2.total_vacant_seats,
                  show_seats: level_2.show_seats_info,
                };
              });
            })
          );
          setLevelIds({
            level1: id,
            level2: 0,
            level3: 0,
            level4: 0,
          });
          setParent(step);
          break;
        case 2:
          const level_2_tree = data.GetMstInstTree.filter(
            ({ level_1_id }) => level_1_id === level_ids.level1
          )[0].level_2_details.filter(({ level_2_id }) => level_2_id === id);
          if (level_2_tree[0].level_3_details) {
            setTable(
              level_2_tree.flatMap((level2_data) => {
                const { level_3_details } = level2_data;
                return level_3_details.flatMap((level_3) => {
                  return {
                    level: level_3.level_3,
                    level_name: level_3.level_3_name,
                    description: level_3.level_3_desc,
                    boys_count: level_3.boys_count,
                    girls_count: level_3.girls_count,
                    total_count: level_3.total_count,
                    id: level_3.level_3_id,
                    total_seats: level_3.total_seats,
                    vacant_seats: level_3.total_vacant_seats,
                    show_seats: level_3.show_seats_info,
                  };
                });
              })
            );
            setLevelIds({
              level1: level_ids.level1,
              level2: id,
              level3: 0,
              level4: 0,
            });
            setParent(step);
          }
          break;
        case 3:
          const level_3_tree = data.GetMstInstTree.filter(
            ({ level_1_id }) => level_1_id === level_ids.level1
          )[0]
            .level_2_details.filter(
              ({ level_2_id }) => level_2_id === level_ids.level2
            )[0]
            .level_3_details.filter(({ level_3_id }) => level_3_id === id);

          if (level_3_tree[0].level_4_details) {
            setTable(
              level_3_tree.flatMap((level3_data) => {
                const { level_4_details } = level3_data;
                return level_4_details.flatMap((level_4) => {
                  return {
                    level: level_4.level_4,
                    level_name: level_4.level_4_name,
                    description: level_4.level_4_desc,
                    boys_count: level_4.boys_count,
                    girls_count: level_4.girls_count,
                    total_count: level_4.total_count,
                    id: level_4.level_4_id,
                    total_seats: level_4.total_seats,
                    vacant_seats: level_4.total_vacant_seats,
                    show_seats: level_4.show_seats_info,
                  };
                });
              })
            );
            setLevelIds({
              level1: level_ids.level1,
              level2: level_ids.level2,
              level3: id,
              level4: 0,
            });
            setParent(step);
          }
          break;
        case 4:
          const level_4_tree = data.GetMstInstTree.filter(
            ({ level_1_id }) => level_1_id === level_ids.level1
          )[0]
            .level_2_details.filter(
              ({ level_2_id }) => level_2_id === level_ids.level2
            )[0]
            .level_3_details.filter(
              ({ level_3_id }) => level_3_id === level_ids.level3
            )[0]
            .level_4_details.filter(({ level_4_id }) => level_4_id === id);

          if (level_4_tree[0]?.level_5_details) {
            setTable(
              level_4_tree.flatMap((level_4_data) => {
                const { level_5_details } = level_4_data;
                return level_5_details.flatMap((level_5) => {
                  return {
                    level: level_5.level_5,
                    level_name: level_5.level_5_name,
                    description: level_5.level_5_desc,
                    boys_count: level_5.boys_count,
                    girls_count: level_5.girls_count,
                    total_count: level_5.total_count,
                    id: level_5.level_5_id,
                    total_seats: level_5.total_seats,
                    vacant_seats: level_5.total_vacant_seats,
                    show_seats: level_5.show_seats_info,
                  };
                });
              })
            );
            setLevelIds({
              level1: level_ids.level1,
              level2: level_ids.level2,
              level3: level_ids.level3,
              level4: id,
            });
            setParent(step);
          }

          break;
        default:
          break;
      }
    }
  };
  useEffect(() => {
    if (token && InstId) {
      GetInstTree();
    }
  }, [token, state.InstId, GetInstTree, InstId]);
  useEffect(() => {
    if (data && !loading) {
      setTable(
        data.GetMstInstTree.map((tree_data) => ({
          level: tree_data.level_1,
          level_name: tree_data.level_1_name,
          description: tree_data.level_1_desc,
          boys_count: tree_data.boys_count,
          girls_count: tree_data.girls_count,
          id: tree_data.level_1_id,
          total_count: tree_data.total_count,
          total_seats: tree_data.total_seats,
          vacant_seats: tree_data.total_vacant_seats,
          show_seats: tree_data.show_seats_info,
        }))
      );
    }
  }, [data, loading]);

  useEffect(() => {
    if (token && InstId && state.ActiveFinYr) {
      GetDashBoardDetails();
      if (EmpDashBoardData) {
        const { GetAdmissionDashBoardDetails } = EmpDashBoardData;
        setAdmissionDashBoardDetails(GetAdmissionDashBoardDetails);
      }
    }
  }, [InstId, token, GetDashBoardDetails, state.ActiveFinYr, EmpDashBoardData]);

  useEffect(() => {
    if (token && state.custId && state.claims?.OWNER && !InstId) {
      GetAdmOwnerDashBoardDetails();
      if (ownerDashBoard) {
        const { cust_adm_total_stats, inst_adm_total_stats } =
          ownerDashBoard.GetOwnersAdmissionDashBoardDetails;
        setAdmissionDashBoardDetails(cust_adm_total_stats);
        setinst_adm_total_stats(inst_adm_total_stats);
      }
    }
  }, [token, state, ownerDashBoard, GetAdmOwnerDashBoardDetails, InstId]);
  if (isAdminExecutive) {
    return (
      <>
        <Title>
          {MasterTitles.Dashboard.map(
            (title: MasterDashboardtitleProps, index: React.Key) => {
              return <React.Fragment key={index}>{title.Title}</React.Fragment>;
            }
          )}
        </Title>
        <div className="masters-dashboard">
          <div className="row g-0 masters-dashboard__details">
            <div
              className="col-3 masters-dashboard__details--block"
              onClick={() => {
                if (pageType !== DashBoardFor.PROPRIETOR)
                  setReservationModal(!reservationmodal);
              }}
            >
              <div className="masters-dashboard__details--block--title">
                <Title variant="subtitle1">
                  {MasterTitles.Dashboard.map(
                    (title: MasterDashboardtitleProps, index: React.Key) => {
                      return (
                        <React.Fragment key={index}>
                          {title.Reserved}
                        </React.Fragment>
                      );
                    }
                  )}
                </Title>

                <img src={More} alt="/" />
              </div>
              <div className="masters-dashboard__details--block--details">
                <img src={Reservation} alt="/" />
                <span className="masters-dashboard__details--block--details--total">
                  {admissionDashBoardDetails.total_nxt_std_count}
                </span>
              </div>
              <div className="masters-dashboard__details--block--g-count">
                <div className="masters-dashboard__details--block--g-count--flex separator">
                  <img src={ReservedBoys} alt="/" />
                  <div className="masters-dashboard__details--block--g-count--block">
                    <span className="masters-dashboard__details--block--g-count--block--text">
                      Boys
                    </span>
                    <span className="masters-dashboard__details--block--g-count--block--total">
                      {admissionDashBoardDetails.boys_nxt_std_count}
                    </span>
                  </div>
                </div>
                <div className="masters-dashboard__details--block--g-count--flex">
                  <img src={ReservedGirls} alt="/" />
                  <div className="masters-dashboard__details--block--g-count--block">
                    <span className="masters-dashboard__details--block--g-count--block--text">
                      Girls
                    </span>
                    <span className="masters-dashboard__details--block--g-count--block--total">
                      {admissionDashBoardDetails.girls_nxt_std_count}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <Title>
        {MasterTitles.Dashboard.map(
          (title: MasterDashboardtitleProps, index: React.Key) => {
            return <React.Fragment key={index}>{title.Title}</React.Fragment>;
          }
        )}
      </Title>
      <div className="masters-dashboard">
        <div className="row g-0 masters-dashboard__details">
          <div
            className="col masters-dashboard__details--block"
            onClick={() => {
              if (pageType !== DashBoardFor.PROPRIETOR)
                setStudentModal(!studentModal);
            }}
          >
            <div className="masters-dashboard__details--block--title">
              <Title variant="subtitle1">
                {MasterTitles.Dashboard.map(
                  (title: MasterDashboardtitleProps, index: React.Key) => {
                    return (
                      <React.Fragment key={index}>
                        {title.Strength}
                      </React.Fragment>
                    );
                  }
                )}
              </Title>
              <img src={More} alt="/" />
            </div>
            <div className="masters-dashboard__details--block--details">
              <img src={TotalStudents} alt="/" />

              <span className="masters-dashboard__details--block--details--total">
                {admissionDashBoardDetails.total_cur_std_count}
              </span>
            </div>
            <div className="masters-dashboard__details--block--g-count">
              <div className="masters-dashboard__details--block--g-count--flex separator">
                <img src={Boys} alt="/" />
                <div className="masters-dashboard__details--block--g-count--block">
                  <span className="masters-dashboard__details--block--g-count--block--text">
                    Boys
                  </span>
                  <span className="masters-dashboard__details--block--g-count--block--total">
                    {admissionDashBoardDetails.boys_cur_std_count}
                  </span>
                </div>
              </div>
              <div className="masters-dashboard__details--block--g-count--flex">
                <img src={Girls} alt="/" />
                <div className="masters-dashboard__details--block--g-count--block">
                  <span className="masters-dashboard__details--block--g-count--block--text">
                    Girls
                  </span>
                  <span className="masters-dashboard__details--block--g-count--block--total">
                    {admissionDashBoardDetails.girls_cur_std_count}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {vacantFlag && (
            <div className="col masters-dashboard__details--block">
              <div className="masters-dashboard__details--block--title">
                <Title variant="subtitle1">Total Vacant Seats</Title>
                <img src={More} alt="/" />
              </div>
              <div className="masters-dashboard__details--block--vacant-seats">
                <img src={VacantSeats} alt="" />

                <span
                  className="masters-dashboard__details--block--vacant-seats--total"
                  style={{
                    color:
                      admissionDashBoardDetails.total_vacant_count < 0
                        ? "red"
                        : "",
                  }}
                >
                  {Math.abs(admissionDashBoardDetails.total_vacant_count)}
                </span>
              </div>
              <div className="masters-dashboard__details--block--total-seats">
                <img src={TotalSeats} alt="" />

                <div className="masters-dashboard__details--block--total-seats--margin">
                  <span className="masters-dashboard__details--block--total-seats--text">
                    Total Seats
                  </span>
                  <span className="masters-dashboard__details--block--vacant-seats--total">
                    {admissionDashBoardDetails.total_seats_count}
                  </span>
                </div>
              </div>
            </div>
          )}
          <div
            className="col masters-dashboard__details--block"
            onClick={() => {
              if (pageType !== DashBoardFor.PROPRIETOR)
                setReservationModal(!reservationmodal);
            }}
          >
            <div className="masters-dashboard__details--block--title">
              <Title variant="subtitle1">
                {MasterTitles.Dashboard.map(
                  (title: MasterDashboardtitleProps, index: React.Key) => {
                    return (
                      <React.Fragment key={index}>
                        {title.Reserved}
                      </React.Fragment>
                    );
                  }
                )}
              </Title>
              <img src={More} alt="/" />
            </div>
            <div className="masters-dashboard__details--block--details">
              <img src={Reservation} alt="/" />
              <span className="masters-dashboard__details--block--details--total">
                {admissionDashBoardDetails.total_nxt_std_count}
              </span>
            </div>
            <div className="masters-dashboard__details--block--g-count">
              <div className="masters-dashboard__details--block--g-count--flex separator">
                <img src={ReservedBoys} alt="/" />
                <div className="masters-dashboard__details--block--g-count--block">
                  <span className="masters-dashboard__details--block--g-count--block--text">
                    Boys
                  </span>
                  <span className="masters-dashboard__details--block--g-count--block--total">
                    {admissionDashBoardDetails.boys_nxt_std_count}
                  </span>
                </div>
              </div>
              <div className="masters-dashboard__details--block--g-count--flex">
                <img src={ReservedGirls} alt="/" />
                <div className="masters-dashboard__details--block--g-count--block">
                  <span className="masters-dashboard__details--block--g-count--block--text">
                    Girls
                  </span>
                  <span className="masters-dashboard__details--block--g-count--block--total">
                    {admissionDashBoardDetails.girls_nxt_std_count}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col masters-dashboard__details--block"
            onClick={() => {
              if (pageType !== DashBoardFor.PROPRIETOR)
                setAdmissionModal(!admissionModal);
            }}
          >
            <div className="masters-dashboard__details--block--title">
              <Title variant="subtitle1">
                {MasterTitles.Dashboard.map(
                  (title: MasterDashboardtitleProps, index: React.Key) => {
                    return (
                      <React.Fragment key={index}>
                        {title.NewAdmissions}
                      </React.Fragment>
                    );
                  }
                )}
              </Title>
              <img src={More} alt="/" />
            </div>
            <div className="masters-dashboard__details--block--details">
              <img src={TotalNewAdmissions} alt="/" />

              <span className="masters-dashboard__details--block--details--total">
                {admissionDashBoardDetails.total_new_admission_count}
              </span>
            </div>
            <div className="masters-dashboard__details--block--g-count">
              <div className="masters-dashboard__details--block--g-count--flex separator">
                <img src={Cancelled} alt="/" />
                <div className="masters-dashboard__details--block--g-count--block">
                  <span className="masters-dashboard__details--block--g-count--block--text">
                    Cancelled
                  </span>
                  <span className="masters-dashboard__details--block--g-count--block--total">
                    {admissionDashBoardDetails.total_cancelled_count}
                  </span>
                </div>
              </div>
              <div className="masters-dashboard__details--block--g-count--flex">
                <img src={Readmission} alt="/" />
                <div className="masters-dashboard__details--block--g-count--block">
                  <span className="masters-dashboard__details--block--g-count--block--text">
                    Re-admission
                  </span>
                  <span className="masters-dashboard__details--block--g-count--block--total">
                    {admissionDashBoardDetails.total_re_admission_count}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {pageType === DashBoardFor.PROPRIETOR ? (
          <>
            <ForProprietor inst_adm_total_stats={inst_adm_total_stats} />
          </>
        ) : (
          <div className="row g-0 masters-dashboard__datablock">
            <div className="col masters-dashboard__details--block h-100">
              <div className="masters-dashboard__details--block--title">
                <Title variant="subtitle1">
                  {MasterTitles.Dashboard.map(
                    (title: MasterDashboardtitleProps, index: React.Key) => {
                      return (
                        <React.Fragment key={index}>
                          {title.Strength}
                        </React.Fragment>
                      );
                    }
                  )}
                </Title>
                <img
                  src={More}
                  alt="/"
                  onClick={() => {
                    setStudentSummary(!studentSummary);
                  }}
                />
              </div>
              <div className="masters-dashboard__datablock--tableblock">
                <TableContainer className="masters-dashboard__datablock--admissions">
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <img
                            src={DepartmentImage}
                            alt="/"
                            className="academics-table-logo"
                          />
                        </TableCell>

                        <TableCell>
                          {table.length && table[0].level_name} Name
                        </TableCell>
                        <TableCell>Boys</TableCell>
                        <TableCell>Girls</TableCell>
                        <TableCell>Total</TableCell>
                        {table.length && table[0].show_seats ? (
                          <>
                            {/* <TableCell>Total Seats</TableCell> */}
                            {vacantFlag && <TableCell>Total Seats</TableCell>}

                            {vacantFlag && <TableCell>Vacant Seats</TableCell>}
                          </>
                        ) : null}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {table &&
                        table.map((data, index: number) => {
                          return (
                            <TableRow
                              onClick={() => {
                                if (data.level === entry_level) {
                                  setTreeViewModal(!treeViewModal);
                                  setLevelId(data.id);
                                } else {
                                  setNextLevel(data.id, parent + 1);
                                }
                              }}
                              key={index}
                            >
                              <TableCell
                                id="td-center"
                                className="masters-dashboard__datablock--table--slno"
                              >
                                {index + 1}
                              </TableCell>
                              <TableCell className="masters-dashboard__datablock--table--name">
                                {data?.description}
                              </TableCell>
                              <TableCell
                                id="td-right"
                                className="masters-dashboard__datablock--table--count"
                              >
                                {data.boys_count}
                              </TableCell>
                              <TableCell
                                id="td-right"
                                className="masters-dashboard__datablock--table--count"
                              >
                                {data.girls_count}
                              </TableCell>
                              <TableCell
                                id="td-right"
                                className="masters-dashboard__datablock--table--count"
                              >
                                {data.total_count}
                              </TableCell>
                              {data.show_seats ? (
                                <>
                                  {vacantFlag && (
                                    <TableCell
                                      id="td-right"
                                      className="masters-dashboard__datablock--table--count"
                                    >
                                      {data.total_seats}
                                    </TableCell>
                                  )}

                                  {vacantFlag && (
                                    <TableCell
                                      id="td-right"
                                      className={`masters-dashboard__datablock--table--count ${
                                        data.vacant_seats < 0
                                          ? "balance-count"
                                          : ""
                                      }`}
                                    >
                                      {Math.abs(data.vacant_seats)}
                                    </TableCell>
                                  )}
                                </>
                              ) : null}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell className="total" colSpan={2} id="td-right">
                          Total:
                        </TableCell>
                        <TableCell className="totalcount">
                          {totalCounts.boys_count}
                        </TableCell>
                        <TableCell className="totalcount">
                          {totalCounts.girls_count}
                        </TableCell>
                        <TableCell className="totalcount">
                          {totalCounts.total_count}
                        </TableCell>
                        {table.length && table[0].show_seats ? (
                          <>
                            {vacantFlag && (
                              <TableCell className="totalcount">
                                {totalCounts.total_seats}
                              </TableCell>
                            )}

                            {vacantFlag && (
                              <TableCell
                                className={`totalcount ${
                                  totalCounts.total_vacant_seats < 0
                                    ? "balance-count"
                                    : ""
                                }`}
                              >
                                {totalCounts.total_vacant_seats}
                              </TableCell>
                            )}
                          </>
                        ) : null}
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </div>
              <Button
                mode="previous"
                disabled={parent === 0}
                onClick={() => {
                  const position = parent - 1;
                  const key = `level${position}`;
                  setNextLevel(level_ids[key], parent - 1);
                }}
              />
            </div>
            <div className="col-3 h-100">
              <div className="masters-dashboard__details--block vacant-block">
                <div className="masters-dashboard__details--block--title">
                  <Title variant="subtitle1">
                    {MasterTitles.Dashboard.map(
                      (title: MasterDashboardtitleProps, index: React.Key) => {
                        return (
                          <React.Fragment key={index}>
                            {title.NotEligible}
                          </React.Fragment>
                        );
                      }
                    )}
                  </Title>
                  <img src={More} alt="/" />
                </div>
                <div
                  className="masters-dashboard__details--block--details"
                  onClick={() => setStudentNEModal(!studentNEModal)}
                >
                  <img src={NotEligibleStudents} alt="/" />

                  <span className="masters-dashboard__details--block--details--total">
                    {admissionDashBoardDetails.total_ne_count}
                  </span>
                </div>
                <div className="masters-dashboard__details--block--g-count">
                  <div className="masters-dashboard__details--block--g-count--flex separator">
                    <img src={Detained} alt="/" />
                    <div className="masters-dashboard__details--block--g-count--block">
                      <span className="masters-dashboard__details--block--g-count--block--text">
                        Detained
                      </span>
                      <span className="masters-dashboard__details--block--g-count--block--total">
                        {admissionDashBoardDetails.total_detained_count}
                      </span>
                    </div>
                  </div>
                  <div className="masters-dashboard__details--block--g-count--flex">
                    <img src={AttendanceShortage} alt="/" />
                    <div className="masters-dashboard__details--block--g-count--block">
                      <span className="masters-dashboard__details--block--g-count--block--text">
                        Att. Shortage
                      </span>
                      <span className="masters-dashboard__details--block--g-count--block--total">
                        {admissionDashBoardDetails.total_soa_count}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="masters-dashboard__details--block vacant-block frame-space">
                <div className="masters-dashboard__details--block--title">
                  <Title variant="subtitle1">
                    {MasterTitles.Dashboard.map(
                      (title: MasterDashboardtitleProps, index: React.Key) => {
                        return (
                          <React.Fragment key={index}>
                            {title.NotAllocated}
                          </React.Fragment>
                        );
                      }
                    )}
                  </Title>
                  <img src={More} alt="/" />
                </div>
                <div
                  className="masters-dashboard__details--block--details"
                  onClick={() => navigate(`students/allocatesection`)}
                >
                  <img src={NotAllocated} alt="/" />

                  <span className="masters-dashboard__details--block--details--total">
                    {admissionDashBoardDetails.std_sec_not_alloc_count}
                  </span>
                </div>
              </div>
              {/* <div className="masters-dashboard__details--block total-seats-block">
                <div className="masters-dashboard__details--block--title">
                  <Title variant="subtitle1">
                    {MasterTitles.Dashboard.map(
                      (title: MasterDashboardtitleProps, index: React.Key) => {
                        return (
                          <React.Fragment key={index}>
                            {title.Reports}
                          </React.Fragment>
                        );
                      }
                    )}
                  </Title>
                  <img src={More} alt="/" />
                </div>
                <ul className="masters-dashboard__datablock--services">
                  {Reports.map((data, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() => {
                          data.showModal();
                        }}
                      >
                        <div className="masters-dashboard__datablock--services--image">
                          <img src={data.image} alt="/" />
                          <Label>{data.label}</Label>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div> */}
            </div>
          </div>
        )}
      </div>

      {/* student-modal */}
      <Modal
        isOpen={studentModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentList
              pageType={PageFor.MODAL}
              studentListFor={StudentListFor.DASHBOARD}
              queryType={StudentReportType.ELIGIBLE}
              setStudentModal={setStudentModal}
              studentModal={studentModal}
              label={PageLabel.TRUE}
            />
          </div>
          <div className="modal-flex__image">
            <img
              onClick={() => setStudentModal(!studentModal)}
              src={CloseImg}
              alt="/"
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
      {/* NE-modal */}
      <Modal
        isOpen={studentNEModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentList
              pageType={PageFor.MODAL}
              studentListFor={StudentListFor.DASHBOARD}
              queryType={StudentReportType.NON_ELIGIBLE}
              setStudentModal={setStudentNEModal}
              studentModal={studentNEModal}
              label={PageLabel.TRUE}
            />
          </div>
          <div className="modal-flex__image">
            <img
              onClick={() => setStudentNEModal(!studentNEModal)}
              src={CloseImg}
              alt="/"
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
      {/* reservation-modal */}
      <Modal
        isOpen={reservationmodal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ReservationPage
              pageType={PageFor.MODAL}
              setReservationModal={setReservationModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              onClick={() => setReservationModal(!reservationmodal)}
              src={CloseImg}
              alt="/"
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
      {/* New-Admission-modal */}
      <Modal
        isOpen={admissionModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <NewAdmissionList
              pageType={PageFor.MODAL}
              setAdmissionModal={setAdmissionModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              onClick={() => setAdmissionModal(!admissionModal)}
              src={CloseImg}
              alt="/"
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
      {/* BioData-modal */}
      <Modal
        isOpen={bioDataModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentBioData
              setBioDataModal={setBioDataModal}
              pageType={PageFor.MODAL}
              type={StudentDetailedReportType.STUDENT_BIO_DATA}
            />
          </div>
          <div className="modal-flex__image">
            <img
              onClick={() => setBioDataModal(!bioDataModal)}
              src={CloseImg}
              alt="/"
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
      {/* Mailing-modal */}
      <Modal
        isOpen={mailingModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentBioData
              setMailingModal={setMailingModal}
              pageType={PageFor.MODAL}
              type={StudentDetailedReportType.MAILING_LABEL}
            />
          </div>
          <div className="modal-flex__image">
            <img
              onClick={() => setMailingModal(!mailingModal)}
              src={CloseImg}
              alt="/"
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
      {/* Summary-modal */}
      <Modal
        isOpen={studentSummary}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentSummaryList
              setModalFlag={setStudentSummary}
              pageType={PageFor.MODAL}
            />
          </div>
          <div className="modal-flex__image">
            <img
              onClick={() => setStudentSummary(!studentSummary)}
              src={CloseImg}
              alt="/"
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
      {/* Religion-modal */}
      <Modal
        isOpen={religionModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentReligion setRelgionModal={setRelgionModal} />
          </div>
          <div className="modal-flex__image">
            <img
              onClick={() => setRelgionModal(!religionModal)}
              src={CloseImg}
              alt="/"
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
      {/* TreeView-modal */}
      <Modal
        isOpen={treeViewModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <TreeView
              levelId={levelId}
              setTreeViewModal={setTreeViewModal}
              pageType={PageFor.MODAL}
            />
          </div>
          <div className="modal-flex__image">
            <img
              onClick={() => setTreeViewModal(!treeViewModal)}
              src={CloseImg}
              alt="/"
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default MastersDashboard;
