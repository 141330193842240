import React, { useContext, useEffect, useState } from "react";
import Home from "../../../Home/Index";
import { Title } from "../../../../../stories/Title/Title";
import InstDetails from "../../../DailyActivities/InstDetails";
import { TextField } from "@mui/material";

import { useParams } from "react-router-dom";

import useStudentTestMarks, {
  AcdStdMarksQueryType,
} from "../../../hooks/useStudentTestMarks";
import { EMPTY_STRING } from "../../../../../utils/constants";
import useTestDetails from "../../../hooks/useTestDetails";
import {
  isOptionEqualToValue,
  toStandardDate,
} from "../../../../../utils/UtilFunctions";
import { responseType } from "../../../../../utils/Form.types";
import useTestStatus, {
  AcdTestMarksStatusQueryType,
} from "../../../hooks/useTestStatus";
import { payloadTypes } from "../../../../../context/reducer";
import { AppContext } from "../../../../../context/context";
import ViewParticularTestReportTable from "./ViewParticularTestReportTable";
import ViewSubjects from "./ViewSubjects";
import { useLazyQuery } from "@apollo/client";
import { GetAcdStudentsCountForTest } from "../../../queries/test/query";
import useToken from "../../../../../customhooks/useToken";
import useInstitutionConfiguration from "../../../../../customhooks/useInstitutionConfiguration";
import { MarksFrom, PageFor } from "../../../../../utils/Enum.types";
import Close from "../../../../../images/Close.svg";
import { AcdSubjComboQueryType } from "../../../../Accounts/common/QueryTypes";
import useCombinationDetails from "../../../CustomAllocation/customHooks/useCombinationDetails";

import ViewParticularTestBySubjectReportTable from "./ViewParticularTestBySubjectReportTable";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../../styles/AutocompleteListStyles";
import { AllocateTeachersDrawer } from "../../../../../styles/DrawerStyles";
interface GetAcdStudentsCountForTestList {
  total_students: number;
  total_present: number;
  total_absent: number;
  att_percentage: number;
  completed_session: number;
}
export interface GetAcdStudentsCountForTestData {
  GetAcdStudentsCountForTest: GetAcdStudentsCountForTestList;
}
export interface GetAcdStudentsCountForTestVars {
  token: string;
  inst_id: string | number;
  acd_yr_id: number;
  input: {
    inst_id: number | string;
    acd_test_class_id: number;
    entry_level: string;
    entry_id: number;
    test_type_id: number;
  };
  acd_subj_combo_id: number;
}

interface props {
  pageType: PageFor;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  marksFor?: MarksFrom;
}

const ViewParticularTestReport = ({ pageType, setModal, marksFor }: props) => {
  const { token } = useToken();
  const { InstId } = useParams();

  const { dispatch, state } = useContext(AppContext);
  const { testId, entryId, testTypeId, comboId } = useParams();
  const [testConductId, setTestConductId] = useState<responseType | null>(null);
  const [selectedCombination, setSelectedCombination] =
    useState<responseType | null>(null);

  const { entry_level } = useInstitutionConfiguration();

  const { combinations } = useCombinationDetails(
    AcdSubjComboQueryType.COMBO_BY_TEST_CLASS_ID
  );

  const [viewSubjects, setViewSubjects] = useState(false);
  const { testMarks } = useStudentTestMarks(
    AcdStdMarksQueryType.TEST_MARKS_AT_ENTRY_LEVEL,
    null,
    EMPTY_STRING
  );
  const [GetStdCounts, { data }] = useLazyQuery<
    GetAcdStudentsCountForTestData,
    GetAcdStudentsCountForTestVars
  >(GetAcdStudentsCountForTest);

  const { testConductDetails } = useTestDetails();
  const { statusOfSubject } = useTestStatus(
    AcdTestMarksStatusQueryType.FINALIZED_TESTS,
    Number(entryId)
  );

  useEffect(() => {
    if (token && testConductId && testTypeId && entryId) {
      GetStdCounts({
        variables: {
          token,
          inst_id: InstId!,
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          input: {
            inst_id: InstId!,
            acd_test_class_id: testConductId.value,
            entry_level,
            entry_id: Number(entryId)!,
            test_type_id: Number(testTypeId)!,
          },
          acd_subj_combo_id: 0,
        },
      });
    }
  }, [token, testConductId, GetStdCounts]);

  useEffect(() => {
    if (comboId && selectedCombination === null) {
      setSelectedCombination(
        combinations.find(({ value }) => value === Number(comboId)) ?? null
      );
      dispatch({
        type: payloadTypes.SET_ACD_COMBO_ID,
        payload: {
          acdCombinationId: Number(comboId),
        },
      });
    }
  }, [comboId, selectedCombination, combinations]);
  useEffect(() => {
    if (testId && testConductId === null) {
      setTestConductId(
        statusOfSubject.classTests.find(
          ({ value }) => value === Number(testId)
        ) ?? null
      );
    }
  }, [testId, statusOfSubject.classTests, testConductId]);

  return (
    <>
      {pageType === PageFor.GENERAL && <Home DashBoardRequired={false} />}
      <div className="acd-reports__per-test--title">
        <Title>Academics Test/Exam Reports</Title>

        {pageType === PageFor.MODAL && (
          <img src={Close} alt="" onClick={() => setModal(false)} />
        )}
      </div>
      <div
        className={
          pageType === PageFor.GENERAL
            ? "acd-reports__per-test"
            : "acd-reports__per-test--modal"
        }
      >
        <InstDetails />
        <div className="row g-0 acd-reports__per-test--select">
          <div className="col-3">
            {marksFor ? (
              marksFor === MarksFrom.COMBINATION ? (
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={combinations}
                  openOnFocus
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(
                      option as responseType,
                      selectedCombination
                    )
                  }
                  value={selectedCombination}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setSelectedCombination(newValue as responseType);
                      dispatch({
                        type: payloadTypes.SET_ACD_COMBO_ID,
                        payload: {
                          acdCombinationId: (newValue as responseType).value,
                        },
                      });
                    } else {
                      setSelectedCombination(null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      className={labelClasses.formControlRoot}
                      label="Select Combination"
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                    />
                  )}
                />
              ) : null
            ) : (
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={statusOfSubject.classTests}
                openOnFocus
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, testConductId)
                }
                value={testConductId}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setTestConductId(newValue as responseType);
                    dispatch({
                      type: payloadTypes.SET_TEST_CONDUCT_ID,
                      payload: {
                        testConductId: (newValue as responseType)?.value,
                      },
                    });
                  } else {
                    setTestConductId(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    className={labelClasses.formControlRoot}
                    label="Select Test / Exam"
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                  />
                )}
              />
            )}
          </div>
          <div className="col">
            <Title variant="subtitle1">
              Test:{" "}
              {
                statusOfSubject.classTests.find(
                  ({ value }) => value === testConductId?.value
                )?.label
              }
            </Title>
          </div>
          <div className="col-3 acd-reports__per-test--textfields">
            <TextField
              className="acd-reports__per-test--textfield"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              label="Start Date"
              disabled
              value={
                testConductDetails
                  ? toStandardDate(
                      testConductDetails.data?.node.test_start_date!
                    )
                  : EMPTY_STRING
              }
            />
            <TextField
              className="acd-reports__per-test--textfield"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              label="End Date"
              disabled
              value={
                toStandardDate(testConductDetails.data?.node.test_end_date!) ||
                EMPTY_STRING
              }
            />
          </div>
        </div>
        <div className="row g-0">
          <div className="col">
            <div className="acd-reports__per-test--cards">
              <div className="acd-reports__per-test--card">
                <span>Total Students</span>
                <b className="font-green">
                  {testMarks.data?.GetStdTestMarks.totalCount!}
                </b>
              </div>
              <div className="acd-reports__per-test--card">
                <span>Present</span>
                <b className="font-green">
                  {data && data.GetAcdStudentsCountForTest.total_present}
                </b>
              </div>
              <div className="acd-reports__per-test--card">
                <span>Absent</span>
                <b className="font-red">
                  {data && data.GetAcdStudentsCountForTest.total_absent}
                </b>
              </div>
            </div>
          </div>
          <div className="col-2 acd-reports__per-test--button">
            <button
              onClick={() => {
                setViewSubjects(!viewSubjects);
                // dispatch({
                //   type: payloadTypes.SET_TEST_CONDUCT_ID,
                //   payload: {
                //     testConductId: Number(testConductId),
                //   },
                // });
              }}
            >
              View Test/Exam Dates
            </button>
          </div>
        </div>

        {marksFor === MarksFrom.SUBJECT ? (
          <ViewParticularTestBySubjectReportTable
            testConductId={testConductId}
            pageType={
              pageType === PageFor.GENERAL ? PageFor.GENERAL : PageFor.MODAL
            }
            selectedCombination={selectedCombination}
          />
        ) : null}
        {marksFor === undefined || marksFor === MarksFrom.COMBINATION ? (
          <ViewParticularTestReportTable
            testConductId={testConductId}
            pageType={
              pageType === PageFor.GENERAL ? PageFor.GENERAL : PageFor.MODAL
            }
            selectedCombination={selectedCombination}
            forCombination={
              marksFor ? marksFor === MarksFrom.COMBINATION : false
            }
          />
        ) : null}
      </div>
      <AllocateTeachersDrawer
        anchor="right"
        open={viewSubjects}
        onClose={() => setViewSubjects(!viewSubjects)}
      >
        <ViewSubjects
          testConductId={testConductId}
          setModal={setViewSubjects}
        />
      </AllocateTeachersDrawer>
    </>
  );
};

export default ViewParticularTestReport;
