import React, { useContext, useEffect, useState } from "react";
import Home from "../Home/Index";
import { Title } from "../../../stories/Title/Title";
import Input from "../../../stories/Input/Input";
import { TextField } from "@mui/material";
import { Button } from "../../../stories/Button/Button";
import Link from "../../../images/Link.svg";
import Delete from "../../../images/Delete.svg";
import Close from "../../../images/Close.svg";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-modal";
import { StudentModalStyles } from "../../../styles/ModalStyles";

import {
  GetAcctVoucherAnonymousData,
  GetAcctVoucherAnonymousEdges,
  GetAcctVoucherAnonymousNodeDetails,
  GetAcctVoucherAnonymousVars,
} from "../hooks/useAcctVoucherDetailsByDates";
import {
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
  TODAY_DATE,
} from "../../../utils/constants";
import {
  DateRange,
  formatter,
  toInputStandardDate,
  toStandardDate,
  getModifiedScrollHeight,
} from "../../../utils/UtilFunctions";

import { AcctStudentType } from "../common/Enum.types";
import useToken from "../../../customhooks/useToken";
import {
  DebitOrCredit,
  Direction,
  Operation,
  PageFor,
  SortBy,
  StudentAcctReportType,
  TableHeaders,
} from "../../../utils/Enum.types";

import { AppContext } from "../../../context/context";
import { payloadTypes } from "../../../context/reducer";
import UnknownStdDepositFix from "./Adjustment";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GetAcctVoucherAnonymous } from "../queries/Vouchers/query";
import UnkownReceiptSplitDetails from "./SplitReceiptDetails";
import { DeleteAcctVoucherMasterAndDetails } from "../queries/receipts/mutation/Index";
import { msgType, optionsType } from "../../../utils/Form.types";
import DeleteModal from "../../../pages/DeleteModal";
import MessageModal from "../../../pages/MessageModal";
import LoadingModal from "../../../pages/LoadingModal";
import UnkownStudentAdjustment from "./UnkownStudentAdjustment";
import dayjs from "dayjs";
import useAcctTableJson from "../json/useAcctTableJson";
import useLoggedInUserDetails from "../hooks/useLoggedInUserDetails";
import ScholarshipReceipt from "../Scholarships/AssignNewReceipt";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../styles/AutocompleteListStyles";
import {
  GridAlignment,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { TableHeaderProps } from "../../../utils/types";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../styles/DataGridTableStyles";
interface Props {
  anonymous_std_type: AcctStudentType;
  pageType: PageFor;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

const vouchersType: optionsType[] = [
  { label: "Social Welfare", value: AcctStudentType.SocialWelfare },

  { label: "Agent", value: AcctStudentType.Agent },
];

const List = ({ anonymous_std_type, pageType, setModalFlag }: Props) => {
  const navigate = useNavigate();
  const { InstId } = useParams();
  const { token } = useToken();
  const { format } = formatter;
  const { Accounts_Table } = useAcctTableJson();

  const { dispatch, state } = useContext(AppContext);
  const [adjustmentsModal, setAdjustmentsModal] = useState(false);
  const [unkownStudentadjustmentsModal, setUnkownStudentadjustmentsModal] =
    useState(false);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [rows, setRows] = useState<GridValidRowModel[]>([]);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [vouchers, setVouchers] = useState<GetAcctVoucherAnonymousEdges[]>([]);
  const [vTranscationNo, setvTranscationNo] = useState("");
  const [openSplitStudentDetails, setSplitOpenStudentDetails] = useState(false);
  const [addNewReceiptModal, setAddNewReceiptModal] = useState(false);

  const [selectedRow, setSelectedRow] =
    useState<GetAcctVoucherAnonymousNodeDetails | null>(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [voucherId, setVoucherId] = useState(0);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const dates = DateRange(TODAY_DATE.toString());
  const [startDate, setStartDate] = useState(dates?.firstDay!);
  const [endDate, setEndDate] = useState(dates?.lastDay!);
  const [queryType, setQueryType] = useState(vouchersType[0]);
  const totals = vouchers.reduce(
    (acc, item) => {
      acc.totalAdjustedAmt += item.node.adjusted_amt;
      acc.totalBalanceAmt += item.node.bal_amt;
      acc.totalReceivedAmt += item.node.received_amt;

      return acc;
    },
    {
      totalAdjustedAmt: 0,
      totalBalanceAmt: 0,
      totalReceivedAmt: 0,
    }
  );
  const { user_details } = useLoggedInUserDetails();

  const [GetVoucher, { data, loading, fetchMore }] = useLazyQuery<
    GetAcctVoucherAnonymousData,
    GetAcctVoucherAnonymousVars
  >(GetAcctVoucherAnonymous, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [DeleteVoucher, { loading: deleteloading }] = useMutation(
    DeleteAcctVoucherMasterAndDetails,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const handleClose = () => {
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  const HandleDelete = (id: number) => {
    DeleteVoucher({
      variables: {
        token,
        id,
        inst_id: InstId!,
        user_details,
      },
      refetchQueries: [
        {
          query: GetAcctVoucherAnonymous,
          variables: {
            after: null,
            direction: Direction.ASC,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            first: ROWS_PER_PAGE,
            sortBy: SortBy.CREATED_AT,
            token,
            vTranscationNo: "",
            inst_id: InstId!,
            anonymous_std_type: anonymous_std_type,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Voucher Deleted Sucessfully",
          operation: Operation.CREATE,
        });
        // setDeleteModal(!deleteModal);
        // setModal(!modal);
      }
    });
  };

  useEffect(() => {
    if (state.ActiveFinYr && anonymous_std_type) {
      GetVoucher({
        variables: {
          after: null,
          direction: Direction.DESC,
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          first: ROWS_PER_PAGE,
          sortBy: SortBy.CREATED_AT,
          token,
          vTranscationNo,
          inst_id: InstId!,
          start_date: dayjs(startDate).format(),
          end_date: dayjs(endDate).format(),
          anonymous_std_type:
            anonymous_std_type === AcctStudentType.AnonymousRecolied
              ? anonymous_std_type
              : queryType.value,
        },
      });
    }
  }, [
    state.ActiveFinYr,
    GetVoucher,
    InstId,
    anonymous_std_type,
    vTranscationNo,
    token,
    queryType,
    startDate,
    endDate,
  ]);

  const dynamicHeaders: TableHeaderProps[] =
    Accounts_Table.UnknownBankTransaction.Table_Headers.map((header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
      hideable: header.hideable,
    }));
  const columns: GridColDef[] = [
    ...dynamicHeaders,
    {
      field: "actions",
      headerName: TableHeaders.ACTION,
      headerAlign: HEADER_TEXT_ALIGN,
      align: SLNO_TEXT_ALIGN,
      sortable: false,

      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="unknown-bank-trans__table--actions">
            <img
              src={Delete}
              alt=""
              onClick={() => {
                setVoucherId(params.row.voucher_master_id);
                setDeleteModal(!deleteModal);
              }}
            />

            {parseFloat(params.row.balance_amount) > 0 ? (
              <img
                src={Link}
                alt=""
                onClick={() => {
                  // use this for suspense
                  // setInterChangeReceipt(!interChangeReceipt);
                  // use for Suspense
                  dispatch({
                    type: payloadTypes.SET_UNKOWN_RECEIPT_ID,
                    payload: { unknownReceiptId: params.row.node_id },
                  });

                  if (queryType.value === AcctStudentType.Agent)
                    dispatch({
                      type: payloadTypes.SET_AGENT_ID,
                      payload: {
                        agentId: params.row.std_id,
                      },
                    });

                  dispatch({
                    type: payloadTypes.SET_STUDENT_ID,
                    payload: { studentId: 0 },
                  });

                  anonymous_std_type === AcctStudentType.AnonymousRecolied
                    ? setUnkownStudentadjustmentsModal(
                        !unkownStudentadjustmentsModal
                      )
                    : setAdjustmentsModal(!adjustmentsModal);
                }}
              />
            ) : null}
          </div>
        );
      },
    },
  ];

  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;

      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: FETCH_MORE_DATA,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetAcctVoucherAnonymous.edges;
                const pageInfo =
                  fetchMoreResult.GetAcctVoucherAnonymous.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck =
                  prevResult.GetAcctVoucherAnonymous.edges.filter(
                    ({ node: { id } }) =>
                      newEdges.findIndex(
                        ({ node: { id: newId } }) => newId === id
                      ) !== -1
                  );

                if (duplicateCheck.length > 0) return prevResult;

                return {
                  GetAcctVoucherAnonymous: {
                    edges: [...vouchers, ...newEdges],
                    pageInfo,
                    totalCount: data
                      ? data.GetAcctVoucherAnonymous.totalCount!
                      : 0,
                  },
                };
              },
            });
          }
        }
      };

      if (scrollTable && rows.length)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    [rows]
  );

  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetAcctVoucherAnonymous.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newRow) => {
          const filteredStudent = rows.find(
            (row) => row.custId && row.custId === newRow.node.id
          );
          if (filteredStudent) {
            return {
              ...newRow,
              node: {
                ...newRow.node,
              },
            };
          }
          return newRow;
        });
        setVouchers(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            date: toStandardDate(node.voucher_master_details.v_date),
            name:
              node.voucher_master_details.mst_student.first_name +
              node.voucher_master_details.mst_student.middle_name +
              node.voucher_master_details.mst_student.last_name,

            rcpt_no: node.voucher_master_details.v_no,
            bank_ac: node.voucher_master_details.acct_voucher_details.find(
              (d) => d.vo_cr_db === DebitOrCredit.DEBIT
            )?.acct_ldgr.ldgr_desc,
            transaction_type: node.voucher_master_details.v_transcation_type,
            received_amount: format(node.received_amt),
            adjusted_amount: format(node.adjusted_amt),
            balance_amount: format(node.bal_amt),
            voucher_master_id: node.voucher_master_details.id,
            node_id: node.id,
            std_id: node.voucher_master_details.student_id,
            row_node: node,
          }))
        );
      } else {
        setVouchers(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            date: toStandardDate(node.voucher_master_details.v_date),
            name:
              node.voucher_master_details.mst_student.first_name +
              node.voucher_master_details.mst_student.middle_name +
              node.voucher_master_details.mst_student.last_name,

            rcpt_no: node.voucher_master_details.v_no,
            bank_ac: node.voucher_master_details.acct_voucher_details.find(
              (d) => d.vo_cr_db === DebitOrCredit.DEBIT
            )?.acct_ldgr.ldgr_desc,
            transaction_type: node.voucher_master_details.v_transcation_type,
            received_amount: format(node.received_amt),
            adjusted_amount: format(node.adjusted_amt),
            balance_amount: format(node.bal_amt),
            voucher_master_id: node.voucher_master_details.id,
            node_id: node.id,
            std_id: node.voucher_master_details.student_id,
            row_node: node,
          }))
        );
      }
      setEndCursor(data.GetAcctVoucherAnonymous.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);

  const footerRow = () => {
    if (vouchers.length) {
      return [
        {
          id: [],
          name: [],
          date: [],
          rcpt_no: [],
          bank_ac: [],
          transaction_type: "Total :",
          received_amount: format(totals.totalReceivedAmt),
          adjusted_amount: format(totals.totalAdjustedAmt),
          balance_amount: format(totals.totalBalanceAmt),
          actions: [],
        },
      ];
    }
    return [];
  };

  const pinnedRows = {
    bottom: footerRow(),
  };

  const getCellClassName = (params: GridCellParams) => {
    const pinnedRowIds = pinnedRows.bottom
      ? pinnedRows.bottom.map((row) => row.id)
      : [];

    const isPinnedRow = pinnedRowIds.includes(params.row.id);

    if (isPinnedRow) {
      switch (params.field) {
        case "adjusted_amount":
        case "received_amount":
          return "totalcount";
        case "balance_amount":
          return "balance-count";
        case "actions":
          return "none";
        default:
          return "total";
      }
    }

    return "";
  };
  return (
    <>
      {pageType === PageFor.GENERAL ? <Home DashBoardRequired={false} /> : null}
      <Title>
        {anonymous_std_type === AcctStudentType.AnonymousRecolied
          ? "UnReconciled Student Fee Receipt"
          : "Social Welfare/Agent"}
      </Title>
      <div className={`unknown-bank-trans${pageType === PageFor.GENERAL ? "" :"--modal"}`}>
        <div className="row unknown-bank-trans__select row g-0">
          <div className="col-2">
            <Input
              placeholder="Search Reference No."
              id="search"
              onChange={(e) => setvTranscationNo(e.target.value)}
            />
          </div>
          {/* <div className="col-2">
            <TextField
              label="Filter Date"
              type="date"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              className="unknown-bank-trans__select--textfield"
            />
          </div> */}
          {anonymous_std_type !== AcctStudentType.AnonymousRecolied && (
            <>
              <div className="col-2">
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={vouchersType}
                  value={queryType}
                  openOnFocus
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setQueryType(newValue as optionsType);
                      setHasNextPage(true);
                    } else {
                      setQueryType(vouchersType[0]);
                    }
                  }}
                  autoHighlight
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="outlined-uncontrolled"
                      label="Social Welfare / Agent"
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                      fullWidth
                      className={labelClasses.formControlRoot}
                    />
                  )}
                />
              </div>
            </>
          )}
          <div className="col"></div>

          <div className="col-5 unknown-bank-trans__select--button">
            <TextField
              type="date"
              fullWidth
              label="From"
              value={toInputStandardDate(startDate)}
              onChange={(e) => setStartDate(e.target.value)}
              className="payment-general-register__reportblock--textfield--date"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
                input: {
                  inputProps: {
                    min: state.ActiveFinYr
                      ? toInputStandardDate(state.ActiveFinYr.fin_st_date!)
                      : EMPTY_STRING,
                    max: toInputStandardDate(TODAY_DATE),
                  },
                },
              }}
            />
            <TextField
              type="date"
              fullWidth
              value={toInputStandardDate(endDate)}
              onChange={(e) => setEndDate(e.target.value)}
              label="To"
              className="payment-general-register__reportblock--textfield--date"
              id="outlined Cheque No."
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
                input: {
                  inputProps: {
                    min: state.ActiveFinYr
                      ? toInputStandardDate(state.ActiveFinYr.fin_st_date!)
                      : EMPTY_STRING,
                    max: toInputStandardDate(TODAY_DATE),
                  },
                },
              }}
            />
            <Button
              onClick={() => {
                if (window.location.pathname.includes("/cashierdashboard")) {
                  setAddNewReceiptModal(!addNewReceiptModal);
                } else {
                  anonymous_std_type === AcctStudentType.AnonymousRecolied
                    ? navigate(`/${InstId}/accounts/unknownbanktransaction/add`)
                    : navigate(`/${InstId}/accounts/socialwelfare/add`);
                }
              }}
              mode="addnew"
            >
              &nbsp;Receipt
            </Button>
          </div>
        </div>
        <div
          className="unknown-bank-trans__tableblock"
        >
          <StyledDatagrid
            columns={columns}
            rows={rows}
            onCellClick={(params) => {
              if (params.field === "rcpt_no") {
                dispatch({
                  type: payloadTypes.SET_UNKOWN_ADJUSTMENT_DETAILS_ID,
                  payload: {
                    unkownVoucherAdjustmentDetailsId: params.row.node_id,
                  },
                });
                setSelectedRow(params.row.row_node);
                setSplitOpenStudentDetails(!openSplitStudentDetails);
              }
            }}
            disableRowSelectionOnClick
            disableChildrenSorting
            rowHeight={TABLE_ROW_HEIGHT}
            pinnedRows={pinnedRows}
            getCellClassName={getCellClassName}
            hideFooter
          />
        </div>

        {pageType === PageFor.GENERAL ? (
          <Button mode="back" onClick={() => navigate(-1)} />
        ) : (
          <Button
            mode="cancel"
            onClick={() => {
              setModalFlag(false);
            }}
          />
        )}
      </div>

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={adjustmentsModal}
        ariaHideApp={false}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <UnknownStdDepositFix setAdjustmentsModal={setAdjustmentsModal} />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt=""
              onClick={() => setAdjustmentsModal(!adjustmentsModal)}
            />
          </div>
        </div>
      </Modal>

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={unkownStudentadjustmentsModal}
        ariaHideApp={false}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <UnkownStudentAdjustment
              setAdjustmentsModal={setUnkownStudentadjustmentsModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt=""
              onClick={() =>
                setUnkownStudentadjustmentsModal(!unkownStudentadjustmentsModal)
              }
            />
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={openSplitStudentDetails}
        ariaHideApp={false}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <UnkownReceiptSplitDetails
              setModal={setSplitOpenStudentDetails}
              selectedRow={selectedRow}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt=""
              onClick={() =>
                setSplitOpenStudentDetails(!openSplitStudentDetails)
              }
            />
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={addNewReceiptModal}
        ariaHideApp={false}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ScholarshipReceipt
              studentType={
                anonymous_std_type === AcctStudentType.AnonymousRecolied
                  ? StudentAcctReportType.ANONYMOUS_STUDENT
                  : StudentAcctReportType.SOCIAL_WELFARE_STUDENT
              }
              pageType={PageFor.MODAL}
              setModalFlag={setAddNewReceiptModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt=""
              onClick={() => setAddNewReceiptModal(!addNewReceiptModal)}
            />
          </div>
        </div>
      </Modal>
      <DeleteModal
        modalFlag={deleteModal!}
        setModalFlag={setDeleteModal!}
        handleDelete={HandleDelete}
        id={voucherId}
      />
      <LoadingModal flag={deleteloading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default List;
