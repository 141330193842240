import React, { useContext, useEffect, useState } from "react";
import useToken from "../../customhooks/useToken";
import { useNavigate, useParams } from "react-router-dom";
import useActiveFinancialYear from "./hooks/useActiveFinancialYear";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GetFinYrsByInstIdData,
  GetFinYrsByInstIdVars,
} from "./Dashboard/types";
import Home from "../Master/configurations/Home/Index";

import { GetFinYrsByInstId } from "./queries/DashBoard";
import { Title } from "../../stories/Title/Title";
import { Button } from "../../stories/Button/Button";
import { TextField } from "@mui/material";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../styles/AutocompleteListStyles";
import { isOptionEqualToValue } from "../../utils/UtilFunctions";
import { msgType, responseType } from "../../utils/Form.types";
import MakeFinYearActiveModal from "../../pages/MakeFinYearActiveModal";
import { UpdateFinYrToActive } from "../../queries/chatapplication/mutations";
import { Operation } from "../../utils/Enum.types";
import useLoggedInUserDetails from "./hooks/useLoggedInUserDetails";
import LoadingModal from "../../pages/LoadingModal";
import MessageModal from "../../pages/MessageModal";
import { GetFinYrActiveByInstId } from "../../queries/institution/financialyear/list/byid";
import { payloadTypes } from "../../context/reducer";
import { AppContext } from "../../context/context";
import { ListAcademicYears } from "../../Types/Accounting";

const MakeFinYearActive = () => {
  const navigate = useNavigate();
  const { token } = useToken();
  const { InstId } = useParams();
  const { ActiveFinancicalYear } = useActiveFinancialYear();
  const [finYearSelected, setFinYearSelected] = useState<responseType | null>(
    null
  );
  const { user_details } = useLoggedInUserDetails();
  const { dispatch } = useContext(AppContext);

  const [activeModal, setActiveModal] = useState(false);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [GetFinYrsByInstIdDetails, { data }] = useLazyQuery<
    GetFinYrsByInstIdData,
    GetFinYrsByInstIdVars
  >(GetFinYrsByInstId, {
    variables: {
      inst_id: Number(InstId),
      token,
    },
  });
  const [UpdateFinYear, { loading }] = useMutation(UpdateFinYrToActive, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const finYearDropwDown =
    data && data.GetFinYrsByInstId.length
      ? data.GetFinYrsByInstId.filter(
          (data) =>
            data.id !== ActiveFinancicalYear?.data?.GetFinYrActiveByInstId.id
        ).map((res) => ({
          label: res.fin_yr,
          value: res.id,
        }))
      : [];
  const selectedFinYearData =
    data && data.GetFinYrsByInstId.length
      ? data.GetFinYrsByInstId.filter(
          (data) => data.id === finYearSelected?.value
        ).map((res) => ({
          id: res.id,
          fin_yr: res.fin_yr,
          fin_is_curr_yr: res.fin_is_curr_yr,
          fin_st_date: res.fin_st_date,
          fin_end_date: res.fin_end_date,
        }))
      : [];

  const HandleDelete = () => {
    UpdateFinYear({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        fin_yr_id: finYearSelected ? finYearSelected.value : 0,
      },
      refetchQueries: [
        {
          query: GetFinYrsByInstId,
          variables: {
            inst_id: Number(InstId),
            token,
          },
        },
        {
          query: GetFinYrActiveByInstId,
          variables: {
            token,
            inst_id: InstId!,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Financial Year Updated Successfully",
          operation: Operation.CREATE,
        });
        dispatch({
          type: payloadTypes.SET_ACTIVE_ACD_YR,
          payload: {
            ActiveAcdYr: selectedFinYearData as unknown as ListAcademicYears,
          },
        });
      }
    });
    setActiveModal(!activeModal);
  };
  useEffect(() => {
    if (token && InstId) {
      GetFinYrsByInstIdDetails();
    }
  }, [token, InstId, GetFinYrsByInstIdDetails]);
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setFinYearSelected(null);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  return (
    <>
      <Home DashBoardRequired={false} />
      <div className="accounts-dashboard__historic">
        <div className="accounts-dashboard__historic--title">
          <Title>Make Financial Year Active</Title>
        </div>
        <div className="accounts-dashboard__historic--data">
          <b className="accounts-dashboard__historic--b"></b>
          <div className="accounts-dashboard__historic--block">
            <b className="accounts-dashboard__historic--block--yr">
              {ActiveFinancicalYear.data &&
                ActiveFinancicalYear.data.GetFinYrActiveByInstId.fin_yr}
            </b>

            <span className="accounts-dashboard__historic--block--span">
              Active Year
            </span>
          </div>
          <div className="accounts-dashboard__historic--ul">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={finYearDropwDown}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option as responseType, finYearSelected)
              }
              openOnFocus
              value={finYearSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setFinYearSelected(newValue as responseType);
                } else {
                  setFinYearSelected(null);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"Choose Financial Year"}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  className={labelClasses.formControlRoot}
                  fullWidth
                />
              )}
            />
            <Button mode="save" onClick={() => setActiveModal(true)} />

            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
        </div>
      </div>
      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
      <MakeFinYearActiveModal
        id={finYearSelected ? finYearSelected.value : 0}
        modalFlag={activeModal}
        setModalFlag={setActiveModal}
        handleDelete={HandleDelete}
      />
      <LoadingModal flag={loading} />
    </>
  );
};

export default MakeFinYearActive;
