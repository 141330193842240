import React, { useContext, useEffect, useState } from "react";
import { Title } from "../../../stories/Title/Title";
import Home from "../Home/Index";
import StudentFeeReceipt from "../../../images/StudentFeeReceipt.svg";
import DepositReceipt from "../../../images/DepositReceipt.svg";
import DepositAdjustment from "../../../images/DepositAdjustment.svg";
import DepositRefund from "../../../images/DepositRefund.svg";
import FeeDepositDetails from "../../../images/FeeDepositDetails.svg";
import DepositOB from "../../../images/DepositOB.svg";
import StudentInterchangeReceipt from "../../../images/StudentInterchangeReceipt.svg";
import NonDemandReceipt from "../../../images/NonDemandReceiptMenu.svg";
import ApplicationReceipt from "../../../images/ApplicationReceipt.svg";
// import BankCollection from "../../../images/BankCollection.svg";
// import CashCollection from "../../../images/CashCollection.svg";

import Reports from "../../../images/Reports.svg";

import { Label } from "../../../stories/Label/Label";
// import Card, { CardDetails } from "../CustomComponents/Card";
import Modal from "react-modal";
import { StudentModalStyles } from "../../../styles/ModalStyles";
import FeeReceipt, {
  PayerType,
} from "../../Accounts/StudentDetails/FeeReceipt/Index";
import {
  InstitutionConfigurationTypes,
  NonDemand,
  PageFor,
  StudentAcctReportType,
  StudentListFor,
} from "../../../utils/Enum.types";
import Close from "../../../images/Close.svg";
import ArrowRight from "../../../images/ArrowRight.svg";
import DownArrow from "../../../images/DownArrow.svg";

import NonDemandNonRole from "../../../images/NonDemandNonRole.svg";
import NonDemandFeePayableImg from "../../../images/NonDemandFeePayable.svg";
import StudentListImg from "../../../images/StudentListIcon.svg";
import NotDecided from "../../../images/NotDecided.svg";
import ReservedBoys from "../../../images/ReservedBoys.svg";

import Enquirystudentreceipt from "../StudentDetails/FeeReceipt/Enquirystudentreceipt";
import Receipt from "../Deposits/Receipt";
import Adjustments from "../Deposits/Adjustments";
import Refunds from "../Deposits/Refunds";
import DepositDetails from "../Deposits/DepositDetails";
import OpeningBalance from "../Deposits/OpeningBalance";
import InterchangeReceipt from "../StudentDetails/FeeReceipt/InterchangeReceipt";
import NonDemandFeePayable from "../StudentDetails/NonDemandFeePayable/Index";
import BankAndCashReport from "../Reports/DailyReports/BankAndCashReport";
import { AppContext } from "../../../context/context";
import { payloadTypes } from "../../../context/reducer";
import StudentList from "../common/StudentList";
import {
  AcctStudentType,
  InstitutionAcctConfigurationTypes,
} from "../common/Enum.types";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import { GlobalPageConfigData } from "../../../Types/configtypes";
import ScholarshipReceiptList from "../Scholarships/List";
import CreateChallan from "../StudentDetails/Challan/CreateChallan";
import ApplicationFeeChallan from "../StudentDetails/Challan/ApllicationFeeChallan";
import NonDemandChallan from "../StudentDetails/Challan/NonDemandChallan";
import Challan from "../StudentDetails/Challan/Index";
import IndividualAdjustDepositFee from "../StudentDetails/Challan/IndividualAdjustDepositFee";
import GroupAdjustDepositFee from "../StudentDetails/Challan/GroupAdjustDepositFee";

const ChairmanDashBoard = () => {
  const [studentListModal, setStudentListModal] = useState(false);
  const [studentFeeReceiptModal, setStudentFeeReceiptModal] = useState(false);
  const [applicationReceiptModal, setApplicationReceiptModal] = useState(false);
  const [depositReceiptModal, setDepositReceiptModal] = useState(false);
  const [depositAdjustmentModal, setDepositAdjustmentModal] = useState(false);
  const [
    depositAdjustmentIndividualModal,
    setDepositAdjustmentIndividualModal,
  ] = useState(false);
  const [depositAdjustmentGroupModal, setDepositAdjustmentGroupModal] =
    useState(false);

  const [refundsModal, setRefundsModal] = useState(false);
  const [feeDepositModal, setFeeDepositModal] = useState(false);
  const [depositOBModal, setDepositOBModal] = useState(false);
  const [reconciledChallan, setReconciledChallan] = useState(false);
  const { dispatch } = useContext(AppContext);
  const [studentInterchangeReceiptModal, setStudentInterchangeReceiptModal] =
    useState(false);
  const [nonDemandModal, setNonDemandModal] = useState(false);
  const [nonDemandModalNonRole, setNonDemandModalNonRole] = useState(false);
  const [dailyReports, setDailyReports] = useState(false);
  const [openSubList, setOpenSubList] = useState(false);
  const [unReconciledModal, setUnReconciledModal] = useState(false);
  const [socialWelfareModal, setSocialWelfareModal] = useState(false);
  const [createChallan, setCreateChallan] = useState(false);
  const [applicationChallan, setApplicationChallan] = useState(false);
  const [nonDemandRoleChallan, setNonDemandRoleChallan] = useState(false);
  const [nonDemandNonRoleChallan, setNonDemandNonRoleChallan] = useState(false);

  const { configData } = useSwConfigData([
    InstitutionAcctConfigurationTypes.ENABLE_APPLICATION_FEE,
    InstitutionConfigurationTypes.CREATE_ANONYMOUS_STUDENT,
    InstitutionConfigurationTypes.ENABLE_SCHOLARSHIP,
    InstitutionConfigurationTypes.ENABLE_CHALLAN_RECEIPT,
    InstitutionConfigurationTypes.ENABLE_STD_FEE_RECEIPT,
  ]);

  const filterDataByConfigKey = (data: GlobalPageConfigData[]) => {
    let enableAppFee;
    let anonymousStudent;
    let enableScholarship;
    let enableChallanReceipt;
    let enableStdReceipt;

    if (data) {
      data.forEach((item) => {
        switch (item.config_key) {
          case InstitutionAcctConfigurationTypes.ENABLE_APPLICATION_FEE:
            enableAppFee = item.config_boolean_value;
            break;
          case InstitutionConfigurationTypes.CREATE_ANONYMOUS_STUDENT:
            anonymousStudent = item.config_boolean_value;
            break;
          case InstitutionConfigurationTypes.ENABLE_SCHOLARSHIP:
            enableScholarship = item.config_boolean_value;
            break;
          case InstitutionConfigurationTypes.ENABLE_CHALLAN_RECEIPT:
            enableChallanReceipt = item.config_boolean_value;
            break;
          case InstitutionConfigurationTypes.ENABLE_STD_FEE_RECEIPT:
            enableStdReceipt = item.config_boolean_value;
            break;
          default:
            break;
        }
      });
    }
    return {
      enableAppFee,
      anonymousStudent,
      enableScholarship,
      enableChallanReceipt,
      enableStdReceipt,
    };
  };

  const {
    anonymousStudent,
    enableAppFee,
    enableScholarship,
    enableChallanReceipt,
    enableStdReceipt,
  } = filterDataByConfigKey(
    configData.data ? configData.data.GetSwConfigVariables : []
  );

  const navJson = [
    {
      img: `${StudentListImg}`,
      label: "Student List",
      onclick: setStudentListModal,
      isOpen: false,
      visible: true,
      sub: [],
    },
    {
      img: `${StudentFeeReceipt}`,
      label: "Student Fee Receipt",
      onclick: setStudentFeeReceiptModal,
      isOpen: false,
      visible: enableStdReceipt,
      sub: [],
    },
    {
      img: `${StudentFeeReceipt}`,
      label: "Student Fee Challan",
      onclick: setCreateChallan,
      isOpen: false,
      visible: enableChallanReceipt,
      sub: [],
    },
    {
      img: `${ApplicationReceipt}`,
      label: "Application Receipt",
      onclick: setApplicationReceiptModal,
      isOpen: false,
      visible: enableAppFee && enableStdReceipt,
      sub: [],
    },
    {
      img: `${ApplicationReceipt}`,
      label: "Application Challan",
      onclick: setApplicationChallan,
      isOpen: false,
      visible: enableChallanReceipt,
      sub: [],
    },
    {
      img: `${DepositReceipt}`,
      label: "Deposit",
      onclick: setOpenSubList,
      isOpen: false,
      visible: true,
      sub: [
        {
          img: `${DepositReceipt}`,
          label: "Deposit Receipt",
          onclick: setDepositReceiptModal,
          visible: enableStdReceipt,
        },
        {
          img: `${DepositAdjustment}`,
          label: "Deposit Adjustment",
          onclick: setDepositAdjustmentModal,
          visible: true,
        },
        {
          img: `${DepositAdjustment}`,
          label: "Adjustment (Non Demand) Individual",
          onclick: setDepositAdjustmentIndividualModal,
          visible: true,
        },
        {
          img: `${DepositAdjustment}`,
          label: "Adjustment (Non Demand) Group",
          onclick: setDepositAdjustmentGroupModal,
          visible: true,
        },
        {
          img: `${DepositRefund}`,
          label: "Deposit Refund",
          onclick: setRefundsModal,
          visible: true,
        },
        {
          img: `${FeeDepositDetails}`,
          label: "Fee Deposit Details",
          onclick: setFeeDepositModal,
          visible: true,
        },
        {
          img: `${DepositOB}`,
          label: "Deposit OB",
          onclick: setDepositOBModal,
          visible: true,
        },
      ],
    },
    {
      img: `${StudentInterchangeReceipt}`,
      label: "StudentInterchangeReceipt",
      onclick: setStudentInterchangeReceiptModal,
      isOpen: false,
      visible: true,
      sub: [],
    },
    {
      img: `${NonDemandReceipt}`,
      label: "Non Demand Receipt",
      onclick: setOpenSubList,
      visible: enableStdReceipt,
      isOpen: false,
      sub: [
        {
          img: `${NonDemandFeePayableImg}`,
          label: "Non Demand Fee Payable",
          onclick: setNonDemandRoleChallan,
          visible: true,
        },
        {
          img: `${NonDemandNonRole}`,
          label: "Non Demand (Non Role)",
          onclick: setNonDemandNonRoleChallan,
          visible: true,
        },
      ],
    },
    {
      img: `${NonDemandReceipt}`,
      label: "Non Demand Challan",
      onclick: setOpenSubList,
      visible: enableChallanReceipt,
      isOpen: false,
      sub: [
        {
          img: `${NonDemandFeePayableImg}`,
          label: "Non Demand Fee Payable",
          onclick: setNonDemandModal,
          visible: true,
        },
        {
          img: `${NonDemandNonRole}`,
          label: "Non Demand (Non Role)",
          onclick: setNonDemandModalNonRole,
          visible: true,
        },
      ],
    },
    {
      img: `${NotDecided}`,
      label: "Reconciled Challan",
      onclick: setReconciledChallan,
      sub: [],
      visible: enableChallanReceipt,
      isOpen: false,
    },
    {
      img: `${NotDecided}`,
      label: "UnReconciled Receipts",
      onclick: setUnReconciledModal,
      sub: [],
      visible: anonymousStudent,
      isOpen: false,
    },

    {
      img: `${ReservedBoys}`,
      label: "Social Welfare / Scholarship",
      onclick: setSocialWelfareModal,
      sub: [],
      visible: enableScholarship,
      isOpen: false,
    },
    {
      img: `${Reports}`,
      label: "Daily Reports",
      onclick: setDailyReports,
      sub: [],
      visible: true,
      isOpen: false,
    },
  ];
  const [navState, setNavState] = useState(navJson);
  const [activeMenu, setActiveMenu] = useState("");

  // const ReceiptCards: CardDetails[] = [
  //   {
  //     head: "Todays's Fee Collection",
  //     image: `${StudentFeeReceipt}`,
  //     amount: 90000,
  //     noOfReceipts: 90,
  //   },
  //   {
  //     head: "Todays's Bank Collection",
  //     image: `${BankCollection}`,
  //     amount: 90000,
  //     noOfReceipts: 90,
  //   },
  //   {
  //     head: "Todays's Cash Collection",
  //     image: `${CashCollection}`,
  //     amount: 90000,
  //     noOfReceipts: 90,
  //   },
  //   {
  //     head: "Todays's Application Fee Collection",
  //     image: `${ApplicationReceipt}`,
  //     amount: 90000,
  //     noOfReceipts: 90,
  //   },
  // ];
  useEffect(() => {
    setNavState(navJson);
  }, [
    anonymousStudent,
    enableAppFee,
    enableScholarship,
    enableChallanReceipt,
    enableStdReceipt,
  ]);

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>DashBoard</Title>
      <div className="cashier-dashboard">
        <div className="cashier-dashboard--navigation">
          <Title variant="subtitle1">
            <b>Navigations</b>
          </Title>

          <ul className="cashier-dashboard--navigation--ul">
            {navState
              .filter((node) => node.visible)
              .map((node, index) => {
                return (
                  <React.Fragment key={index}>
                    <li
                      className={`cashier-dashboard--navigation--ul--li${
                        activeMenu === node.label ? "--active" : ""
                      }`}
                      onClick={() => {
                        if (node.sub.length) {
                          node.isOpen = !node.isOpen;
                          setOpenSubList(!openSubList);
                        } else {
                          dispatch({
                            type: payloadTypes.SET_STUDENT_ID,
                            payload: { studentId: 0 },
                          });
                          dispatch({
                            type: payloadTypes.SET_RECEPIT_ID,
                            payload: { receiptId: 0 },
                          });
                          setActiveMenu(node.label);
                          node.onclick?.(true);
                        }
                      }}>
                      <div>
                        <img src={node.img} alt="/" />
                        <Label>{node.label}</Label>
                      </div>
                      {node.sub.length ? (
                        node.isOpen ? (
                          <img src={DownArrow} alt="/" />
                        ) : (
                          <img src={ArrowRight} alt="/" />
                        )
                      ) : null}
                    </li>
                    {node.isOpen ? (
                      <li>
                        <ul className="cashier-dashboard--navigation--sub">
                          {node.sub
                            .filter((res) => res.visible)
                            .map((sub_node, sub_index) => {
                              return (
                                <li
                                  className="cashier-dashboard--navigation--sub"
                                  key={sub_index}
                                  onClick={() => {
                                    dispatch({
                                      type: payloadTypes.SET_STUDENT_ID,
                                      payload: { studentId: 0 },
                                    });
                                    dispatch({
                                      type: payloadTypes.SET_RECEPIT_ID,
                                      payload: { receiptId: 0 },
                                    });
                                    sub_node.onclick?.(true);
                                  }}>
                                  <div>
                                    <img src={sub_node.img} alt="/" />
                                    <Label>{sub_node.label}</Label>
                                  </div>
                                </li>
                              );
                            })}
                        </ul>
                      </li>
                    ) : null}
                  </React.Fragment>
                );
              })}
          </ul>
        </div>
        {/* <div className="cashier-dashboard__body--receipt-cards">
            {ReceiptCards.map((receipt) => {
              return (
                <Card
                  head={receipt.head}
                  amount={receipt.amount}
                  image={receipt.image}
                  noOfReceipts={receipt.noOfReceipts}
                />
              );
            })}
          </div> */}
      </div>
      <Modal
        isOpen={studentFeeReceiptModal}
        ariaHideApp={false}
        style={StudentModalStyles}
        onRequestClose={() =>
          setStudentFeeReceiptModal(!studentFeeReceiptModal)
        }>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <FeeReceipt
              setModalFlag={setStudentFeeReceiptModal}
              pageType={PageFor.MODAL}
              payer={PayerType.ACCOUNTING}
              studentSelected={false}
            />
          </div>
          <div className="modal-flex__image ">
            <img
              className="modal-close-icon"
              src={Close}
              alt="/"
              onClick={() => setStudentFeeReceiptModal(!studentFeeReceiptModal)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={applicationReceiptModal}
        ariaHideApp={false}
        style={StudentModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Enquirystudentreceipt
              pageType={PageFor.MODAL}
              setFeeReceiptModal={setApplicationReceiptModal}
              studentSelected={false}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() =>
                setApplicationReceiptModal(!applicationReceiptModal)
              }
            />
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={depositReceiptModal}
        ariaHideApp={false}
        style={StudentModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Receipt
              pageType={PageFor.MODAL}
              setModalFlag={setDepositReceiptModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setDepositReceiptModal(!depositReceiptModal)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={depositAdjustmentModal}
        ariaHideApp={false}
        style={StudentModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Adjustments
              pageType={PageFor.MODAL}
              setModalFlag={setDepositAdjustmentModal}
              selectStudent={true}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setDepositAdjustmentModal(!depositAdjustmentModal)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={refundsModal}
        ariaHideApp={false}
        style={StudentModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Refunds
              pageType={PageFor.MODAL}
              setModalFlag={setRefundsModal}
              selectStudent={true}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setRefundsModal(!refundsModal)}
            />
          </div>
        </div>
      </Modal>

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={feeDepositModal}
        ariaHideApp={false}
        style={StudentModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <DepositDetails
              pageType={PageFor.MODAL}
              setModalFlag={setFeeDepositModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setFeeDepositModal(!feeDepositModal)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={feeDepositModal}
        ariaHideApp={false}
        style={StudentModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <DepositDetails
              pageType={PageFor.MODAL}
              setModalFlag={setFeeDepositModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setFeeDepositModal(!feeDepositModal)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={depositOBModal}
        ariaHideApp={false}
        style={StudentModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <OpeningBalance
              pageType={PageFor.MODAL}
              setModalFlag={setDepositOBModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setDepositOBModal(!depositOBModal)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={studentInterchangeReceiptModal}
        ariaHideApp={false}
        style={StudentModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <InterchangeReceipt
              pageType={PageFor.MODAL}
              setModalFlag={setStudentInterchangeReceiptModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() =>
                setStudentInterchangeReceiptModal(
                  !studentInterchangeReceiptModal
                )
              }
            />
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={nonDemandModal}
        ariaHideApp={false}
        style={StudentModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <NonDemandFeePayable
              type={NonDemand.ROLE}
              pageType={PageFor.MODAL}
              setModalFlag={setNonDemandModal}
              selectStudent={true}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setNonDemandModal(!nonDemandModal)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={nonDemandModalNonRole}
        ariaHideApp={false}
        style={StudentModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <NonDemandFeePayable
              type={NonDemand.NON_ROLE}
              pageType={PageFor.MODAL}
              setModalFlag={setNonDemandModalNonRole}
              selectStudent={true}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setNonDemandModalNonRole(!nonDemandModalNonRole)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={dailyReports}
        ariaHideApp={false}
        style={StudentModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <BankAndCashReport
              pageType={PageFor.MODAL}
              setModalFlag={setDailyReports}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setDailyReports(!dailyReports)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={studentListModal}
        style={StudentModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentList
              pageType={PageFor.MODAL}
              studentListFor={StudentListFor.ACCOUNTS}
              queryType={StudentAcctReportType.GENERAL}
              setStudentModal={setStudentListModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStudentListModal(!studentListModal)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={unReconciledModal}
        style={StudentModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ScholarshipReceiptList
              pageType={PageFor.MODAL}
              anonymous_std_type={AcctStudentType.AnonymousRecolied}
              setModalFlag={setUnReconciledModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setUnReconciledModal(!unReconciledModal)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={socialWelfareModal}
        style={StudentModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ScholarshipReceiptList
              pageType={PageFor.MODAL}
              anonymous_std_type={AcctStudentType.SocialWelfare}
              setModalFlag={setSocialWelfareModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setSocialWelfareModal(!socialWelfareModal)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={createChallan}
        style={StudentModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <CreateChallan
              pageType={PageFor.MODAL}
              setModalFlag={setCreateChallan}
              payer={PayerType.ACCOUNTING}
              studentSelected={false}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setCreateChallan(!createChallan)}
            />
          </div>
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={applicationChallan}
        style={StudentModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ApplicationFeeChallan
              pageType={PageFor.MODAL}
              setFeeReceiptModal={setApplicationChallan}
              studentSelected={false}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setApplicationChallan(!applicationChallan)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={depositAdjustmentIndividualModal}
        style={StudentModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <IndividualAdjustDepositFee
              pageType={PageFor.MODAL}
              setDepositAdjustmentIndividualModal={
                setDepositAdjustmentIndividualModal
              }
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() =>
                setDepositAdjustmentIndividualModal(
                  !depositAdjustmentIndividualModal
                )
              }
            />
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={depositAdjustmentGroupModal}
        style={StudentModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <GroupAdjustDepositFee
              pageType={PageFor.MODAL}
              setDepositAdjustmentGroupModal={setDepositAdjustmentGroupModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() =>
                setDepositAdjustmentGroupModal(!depositAdjustmentGroupModal)
              }
            />
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={nonDemandRoleChallan}
        style={StudentModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            {enableChallanReceipt ? (
              <NonDemandChallan
                type={NonDemand.ROLE}
                pageType={PageFor.MODAL}
                setModalFlag={setNonDemandRoleChallan}
                selectStudent={true}
              />
            ) : (
              <NonDemandFeePayable
                type={NonDemand.ROLE}
                pageType={PageFor.MODAL}
                setModalFlag={setNonDemandNonRoleChallan}
                selectStudent={true}
              />
            )}
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setNonDemandRoleChallan(!nonDemandRoleChallan)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={nonDemandNonRoleChallan}
        style={StudentModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            {enableChallanReceipt ? (
              <NonDemandChallan
                type={NonDemand.NON_ROLE}
                pageType={PageFor.MODAL}
                setModalFlag={setNonDemandNonRoleChallan}
                selectStudent={true}
              />
            ) : (
              <NonDemandFeePayable
                type={NonDemand.NON_ROLE}
                pageType={PageFor.MODAL}
                setModalFlag={setNonDemandNonRoleChallan}
                selectStudent={true}
              />
            )}
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() =>
                setNonDemandNonRoleChallan(!nonDemandNonRoleChallan)
              }
            />
          </div>
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={reconciledChallan}
        style={StudentModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Challan pageType={PageFor.MODAL} />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setReconciledChallan(!reconciledChallan)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ChairmanDashBoard;
