/**
 * React functional component that displays the details of raised fee demands.
 *
 * @component
 * @example
 * <ViewRaisedFeeDemandDetails
 *   setViewDemandStructureModal={setViewDemandStructureModal}
 *   viewDemandStructureModal={viewDemandStructureModal}
 * />
 *
 * @param {function} setViewDemandStructureModal - Function to set the visibility of the fee demand structure modal.
 * @param {boolean} viewDemandStructureModal - Boolean value indicating whether the fee demand structure modal is visible or not.
 *
 * @returns {JSX.Element} - User interface for selecting branch, class, and category options and displaying the corresponding fee demand details in separate tables.
 *
 * @throws {Error} - If demand structure for chosen option is not found.
 */
import React, { useState, useEffect, useRef, useContext } from "react";
import { Button } from "../../../stories/Button/Button";
import { Title } from "../../../stories/Title/Title";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Table,
  TableHead,
  TableFooter,
  TextField,
} from "@mui/material";
import { useParams } from "react-router-dom";
import FeedemandTreeView from "./TreeView";

import {
  FeeDetails,
  ListDemandDetailsData,
  ListDemandDetailsVars,
  GetAcctDemandDetailsByDemandMainIdData,
  GetAcctDemandDetailsByDemandMainIdVars,
} from "../../../Types/Accounting";
import {
  GetAcctDemandDetails,
  GetAcctDemandDetailsByDemandMainId,
} from "../queries/FeeLedgers/query/Byid";
import { useLazyQuery } from "@apollo/client";
import { AppContext } from "../../../context/context";

import dayjs from "dayjs";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import { FeeDemandTitleProps } from "../../../Types/Titles";
import { TableHeaderProps } from "../../../Types/Tables";
import {
  formatter,
  RefsByTagName,
  toStandardDate,
} from "../../../utils/UtilFunctions";

import useToken from "../../../customhooks/useToken";
import useDropdownData from "../../../customhooks/useDropdownData";
import useInstMasterDataByInstId from "../../../customhooks/useInstMasterDataByInstId";
import { Keys } from "../../../utils/Enum.keys";
import { responseType } from "../../../utils/Form.types";
import { payloadTypes } from "../../../context/reducer";
import useAcctTableJson from "../json/useAcctTableJson";
import useInstLabels from "../../../customhooks/general/useInstLabels";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../styles/AutocompleteListStyles";
const { AccountsTitles } = require("../json/title.json");
interface Props {
  setViewDemandStructureModal: React.Dispatch<React.SetStateAction<boolean>>;
  viewDemandStructureModal: boolean;
}
const ViewRaisedFeeDemandDetails = ({
  setViewDemandStructureModal,
  viewDemandStructureModal,
}: Props) => {
  const { format } = formatter;
  const { InstId } = useParams<{ InstId: string }>();
  const { dispatch, state } = useContext(AppContext);
  const { token } = useToken();
  const { Accounts_Table } = useAcctTableJson();
  //usestates for select tags
  const [branchId, setBranchId] = useState<responseType | null>(null);
  const [classId, setClassId] = useState<responseType | null>(null);
  const [categoryId, setCategoryId] = useState<responseType | null>(null);
  const [items, setItems] = useState<FeeDetails[]>([]);

  const branchRef = useRef<HTMLSelectElement>(null);
  const branchInputRef = RefsByTagName(branchRef);
  const classRef = useRef<HTMLSelectElement>(null);
  const classInputRef = RefsByTagName(classRef);
  const categoryRef = useRef<HTMLSelectElement>(null);
  const categoryInputRef = RefsByTagName(categoryRef);

  const fetchButtonRef = useRef<HTMLButtonElement>(null);
  let existingStudentData: FeeDetails[] = [];
  let NewStudentData: FeeDetails[] = [];
  const { USE_CATEGORY_KEY } = useInstitutionConfiguration();

  const { branchDropDown } = useInstMasterDataByInstId();

  const { classDropDown } = useDropdownData(
    0,
    branchId ? branchId.value : 0,
    classId ? classId.value : 0,
    0
  );

  const { categoryDropDown } = useInstMasterDataByInstId();

  //lazy queries
  const [AccountDemandDetails] = useLazyQuery<
    ListDemandDetailsData,
    ListDemandDetailsVars
  >(GetAcctDemandDetails);
  const [AcctDemandDetailsByMainId, { data }] = useLazyQuery<
    GetAcctDemandDetailsByDemandMainIdData,
    GetAcctDemandDetailsByDemandMainIdVars
  >(GetAcctDemandDetailsByDemandMainId, {
    variables: {
      acct_demand_main_id: state.account_main_id,
      token,
    },
  });

  useEffect(() => {
    AccountDemandDetails({
      variables: {
        token,
        branch_id: branchId ? branchId.value : 0,
        category_id: categoryId ? categoryId.value : 0,
        class_id: classId ? classId.value : 0,
        fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
        inst_id: InstId!,
      },
    })
      .then((response) => {
        setItems(response.data?.GetAcctDemandDetails!);
      })
      .catch(() => alert("Demand Structure for Choosen Option not Found "));
    // eslint-disable-next-line
  }, [token, AccountDemandDetails, branchId, categoryId, classId, InstId]);

  useEffect(() => {
    if (state.account_main_id) {
      AcctDemandDetailsByMainId().then(({ data }) => {
        if (data) {
          setItems(data.GetAcctDemandDetailsByDemandMainId);
        }
      });
    }
    if (state.account_main_id === 0) {
      setItems([]);
      setBranchId(null);
      setClassId(null);
      setCategoryId(null);
    }
  }, [state.account_main_id, AcctDemandDetailsByMainId, token, data]);

  //to differentiate bwt old student and new student data
  items?.map(
    ({
      fee_for_all,
      fee_for_exs_std,
      fee_for_new_std,
      fee_due_date,
      sl_no,
      acct_ldgr_id,
      acct_ldgr_details,
      // eslint-disable-next-line
    }) => {
      if (fee_for_all !== 0) {
        existingStudentData.push({
          fee_for_all,
          fee_for_exs_std,
          fee_for_new_std,
          fee_due_date,
          sl_no,
          acct_ldgr_id,
          acct_ldgr_details,
        });
        NewStudentData.push({
          fee_for_all,
          fee_for_exs_std,
          fee_for_new_std,
          fee_due_date,
          sl_no,
          acct_ldgr_id,
          acct_ldgr_details,
        });
      }
      if (fee_for_exs_std !== 0) {
        existingStudentData.push({
          fee_for_all,
          fee_for_exs_std,
          fee_for_new_std,
          fee_due_date,
          sl_no,
          acct_ldgr_id,
          acct_ldgr_details,
        });
      }
      if (fee_for_new_std !== 0) {
        NewStudentData.push({
          fee_for_all,
          fee_for_exs_std,
          fee_for_new_std,
          fee_due_date,
          sl_no,
          acct_ldgr_id,
          acct_ldgr_details,
        });
      }
    }
  );
  let newStudentSum = 0;
  let existingStudentSum = 0;
  existingStudentData.forEach(({ fee_for_all, fee_for_exs_std }) => {
    existingStudentSum += fee_for_all + fee_for_exs_std;
  });
  NewStudentData.forEach(({ fee_for_all, fee_for_new_std }) => {
    newStudentSum += fee_for_all + fee_for_new_std;
  });
  useEffect(() => {
    if (viewDemandStructureModal) {
      branchInputRef?.focus();
    }
  }, [branchInputRef, viewDemandStructureModal]);
  const { branchLabel, classLabel, categoryLabel } = useInstLabels();

  return (
    <>
      <Title>
        {AccountsTitles.FeeDemand.Titles.map(
          (title: FeeDemandTitleProps, index: React.Key) => {
            return (
              <React.Fragment key={index}>
                {title.View_Fee_Demand}
              </React.Fragment>
            );
          }
        )}
      </Title>
      <div className="row g-0 view-feedemand">
        <div className="col-4 account-frames h-100">
          <FeedemandTreeView />
        </div>
        <div className="col account-frames h-100">
          <div className="row g-0 view-feedemand__options">
            <div className="col">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={branchDropDown}
                openOnFocus
                forcePopupIcon
                ref={branchRef}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === Keys.ENTER) {
                    if (branchId) {
                      classInputRef?.focus();
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setBranchId(null);
                  }
                }}
                value={branchId}
                onChange={(e, newValue) => {
                  if (newValue)
                    setBranchId({
                      label: (newValue as responseType).label,
                      value: (newValue as responseType).value,
                      isChecked: true,
                    });
                  dispatch({
                    type: payloadTypes.SET_ACCOUNT_MAIN_ID,
                    payload: {
                      account_main_id: 0,
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    label={branchLabel}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
            <div className="col">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                ref={classRef}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === Keys.ENTER && classId) {
                    categoryInputRef?.focus();
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setClassId(null);
                  }
                }}
                options={classDropDown}
                openOnFocus
                forcePopupIcon
                value={classId}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setClassId(newValue as responseType);
                  } else {
                    setClassId(null);
                  }
                  dispatch({
                    type: payloadTypes.SET_ACCOUNT_MAIN_ID,
                    payload: {
                      account_main_id: 0,
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    label={classLabel}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
            {USE_CATEGORY_KEY ? (
              <div className="col">
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={categoryDropDown}
                  ref={categoryRef}
                  openOnFocus
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (e.key === Keys.ENTER && categoryId) {
                      fetchButtonRef.current?.focus();
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setCategoryId(null);
                    }
                  }}
                  forcePopupIcon
                  value={categoryId}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setCategoryId({
                        label: (newValue as responseType).label,
                        value: (newValue as responseType).value,
                        isChecked: true,
                      });
                    } else {
                      setCategoryId(null);
                    }
                    dispatch({
                      type: payloadTypes.SET_ACCOUNT_MAIN_ID,
                      payload: {
                        account_main_id: 0,
                      },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={labelClasses.formControlRoot}
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                      label={categoryLabel}
                    />
                  )}
                />
              </div>
            ) : null}
          </div>

          {!existingStudentData.length &&
          !NewStudentData.length &&
          categoryId ? (
            <b className="nodata">
              Demand Structure for Choosen Option not Found for this particular
              category
            </b>
          ) : !existingStudentData.length &&
            !NewStudentData.length &&
            !categoryId &&
            state.account_main_id === 0 ? (
            <b className="nodata">Please Select All Fields </b>
          ) : (
            <div className="view-feedemand__table-view">
              {existingStudentData.length ? (
                <div className="row g-0 ">
                  <div className="col">
                    <div className="view-feedemand__block1">
                      <Title variant="subtitle1">
                        {AccountsTitles.FeeDemand.Titles.map(
                          (title: FeeDemandTitleProps, index: React.Key) => {
                            return (
                              <React.Fragment key={index}>
                                {title.For_Existing_Students}
                              </React.Fragment>
                            );
                          }
                        )}
                      </Title>
                      <TableContainer className="view-feedemand__table--pink">
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow>
                              {Accounts_Table.FeeDemand.ViewFeeDemand.Table_Headers.map(
                                (th: TableHeaderProps, index: React.Key) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <TableCell className={th.className}>
                                        {th.labelName}
                                      </TableCell>
                                    </React.Fragment>
                                  );
                                }
                              )}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {existingStudentData.map((data, index) => {
                              return (
                                <TableRow key={index + 1}>
                                  <TableCell
                                    id="td-center"
                                    className="view-feedemand__table--pink--slno">
                                    {index + 1}
                                  </TableCell>
                                  <TableCell>
                                    {data.acct_ldgr_details?.ldgr_desc}
                                  </TableCell>
                                  <TableCell
                                    id="td-right"
                                    className="view-feedemand__table--pink--fee">
                                    {data.fee_for_all !== 0
                                      ? format(data.fee_for_all)
                                      : format(data.fee_for_exs_std)}
                                  </TableCell>
                                  <TableCell
                                    id="td-center"
                                    className="view-feedemand__table--pink--fee">
                                    {dayjs(data.fee_due_date).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </TableCell>
                                  <TableCell
                                    id="td-right"
                                    className="view-feedemand__table--pink--fee">
                                    {data.fee_for_all !== 0
                                      ? format(data.fee_for_all)
                                      : format(data.fee_for_exs_std)}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                          <TableFooter>
                            <TableRow>
                              <TableCell colSpan={4} className="total">
                                Total :
                              </TableCell>
                              <TableCell className="totalcount" id="td-right">
                                {format(existingStudentSum)}
                              </TableCell>
                            </TableRow>
                          </TableFooter>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                </div>
              ) : (
                <b className="nodata">
                  Demand Structure for Choosen Option not Found for existing
                  students
                </b>
              )}
              {NewStudentData.length ? (
                <div className="row g-0">
                  <div className="col">
                    <div className="view-feedemand__block2">
                      <Title variant="subtitle1">
                        {AccountsTitles.FeeDemand.Titles.map(
                          (title: FeeDemandTitleProps, index: React.Key) => {
                            return (
                              <React.Fragment key={index}>
                                {title.For_New_Admission_Students}
                              </React.Fragment>
                            );
                          }
                        )}
                      </Title>
                      <TableContainer className="view-feedemand__table--green">
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow>
                              {Accounts_Table.FeeDemand.ViewFeeDemand.Table_Headers.map(
                                (th: TableHeaderProps, index: React.Key) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <TableCell className={th.className}>
                                        {th.labelName}
                                      </TableCell>
                                    </React.Fragment>
                                  );
                                }
                              )}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {NewStudentData.map((data, index) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell
                                    id="td-center"
                                    className="view-feedemand__table--pink--slno">
                                    {index + 1}
                                  </TableCell>
                                  <TableCell>
                                    {data.acct_ldgr_details?.ldgr_desc}
                                  </TableCell>
                                  <TableCell
                                    id="td-right"
                                    className="view-feedemand__table--pink--fee">
                                    {data.fee_for_all !== 0
                                      ? format(data.fee_for_all)
                                      : format(data.fee_for_new_std)}
                                  </TableCell>
                                  <TableCell
                                    id="td-center"
                                    className="view-feedemand__table--pink--fee">
                                    {toStandardDate(data.fee_due_date)}
                                  </TableCell>
                                  <TableCell
                                    id="td-right"
                                    className="view-feedemand__table--pink--fee">
                                    {data.fee_for_all !== 0
                                      ? format(data.fee_for_all)
                                      : format(data.fee_for_new_std)}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                          <TableFooter>
                            <TableRow>
                              <TableCell colSpan={4} className="total">
                                Total :
                              </TableCell>
                              <TableCell className="totalcount" id="td-right">
                                {format(newStudentSum)}
                              </TableCell>
                            </TableRow>
                          </TableFooter>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                </div>
              ) : (
                <b className="nodata">
                  Demand Structure for Choosen Option not Found for freshers
                </b>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="row g-0 ">
        <div className="col view-feedemand__buttons">
          <Button
            mode="cancel"
            onClick={() =>
              setViewDemandStructureModal(!viewDemandStructureModal)
            }
          />
        </div>
      </div>
    </>
  );
};

export default ViewRaisedFeeDemandDetails;
