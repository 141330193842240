import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useContext } from "react";
import { AppContext } from "../../../../../context/context";
import { TODAY_DATE } from "../../../../../utils/constants";
import { PrintReceiptProps } from "../Standard/FeeReceiptPrint";
import useStudentReceiptDetails from "../../../../Accounts/hooks/useStudentReceiptDetails";
import { BankOrCash, DebitOrCredit } from "../../../../../utils/Enum.types";

import { formatter, toStandardDate } from "../../../../../utils/UtilFunctions";
import usePassedOutStudentReceiptDetails from "../../../../Accounts/hooks/usePassedOutStudentReceiptDetails";
import { ToWords } from "to-words";
import InstInfo, { InstDetailsPageType } from "../common/InstDetails";
import StudentInfo from "../common/StudentDetails";
import useNonDemandReceiptConfig from "../Standard/hooks/useNonDemandReceiptConfig";
import useStudentDatabyId from "../../../../../customhooks/useStudentDatabyId";
export interface Data {
  particular: string;
  amount: number;
}
const NonDemandReceiptPrint = ({
  tableData,
  v_date,
  v_no,
  transaction_no,
  studentDetails,
}: PrintReceiptProps) => {
  const { state } = useContext(AppContext);
  const { format } = formatter;

  const toWords = new ToWords();
  const { studentReceiptDetails } = useStudentReceiptDetails();

  const { passedOutStudentReceiptDetails } =
    usePassedOutStudentReceiptDetails();

  const filteredDebitTransactions =
    studentReceiptDetails?.acct_voucher_details.filter(
      (c) => c.vo_cr_db === DebitOrCredit.DEBIT
    );
  const { totalacademicAmount } = tableData.reduce(
    (acc, item) => {
      acc.totalacademicAmount += item.amount;
      return acc;
    },
    {
      totalacademicAmount: 0,
    }
  );

  const {
    USE_NON_DEMAND_CASH_DETAILS,

    USE_NON_DEMAND_RECEIPT_FOOTER,
    USE_NON_DEMAND_NARRATIONS,
    USE_NON_DEMAND_RECEIPT_FOOTER_2,
    USE_NON_DEMAND_TRANSACTION_NUMBER,
    USE_RECEIPT_TITLE,
  } = useNonDemandReceiptConfig();
  return (
    <div className={tableData.length > 5 ? "prs-a5-l" : "prs"}>
      <div className="prs__top">
        <InstInfo
          studentDetails={studentDetails}
          tableData={tableData}
          v_date={v_date}
          v_no={v_no}
          transaction_no={transaction_no}
          pageType={InstDetailsPageType.NON_DEMAND}
        />
        {USE_RECEIPT_TITLE ? (
          <b className="prs__top--receipt--name">{USE_RECEIPT_TITLE}</b>
        ) : null}
        <div className="prs__top--receipt">
          <div className="prs__top--receipt--number">
            <span>
              Receipt No : <b>{v_no}</b>
            </span>
          </div>

          <div className="prs__top--receipt--date">
            <span>Date : {toStandardDate(v_date ?? TODAY_DATE)}</span>
          </div>
        </div>
        <StudentInfo
          studentDetails={studentDetails}
          tableData={tableData}
          v_date={v_date}
          v_no={v_no}
          transaction_no={transaction_no}
          pageType={InstDetailsPageType.NON_DEMAND}
        />
      </div>
      <div className="prs__tableblock">
        <TableContainer className="prs__table">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className="prs__table--th-slno">Sl</TableCell>
                <TableCell className="prs__table--th-fee">
                  Particulars
                </TableCell>
                <TableCell>Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData &&
                tableData.map((data, index) => {
                  return (
                    <TableRow key={index + 1}>
                      <TableCell id="td-center" className="prs__table--slno">
                        {index + 1}
                      </TableCell>
                      <TableCell className="prs__table--fee">
                        {data.particular}
                      </TableCell>
                      <TableCell id="td-right" className="prs__table--amount">
                        {format(data.amount)}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <div className="prs__table--total">
          <span className="prs__table--total--text">Total Paid</span>
          <div className="prs__table--amount">
            <span>{format(totalacademicAmount)}</span>
          </div>
        </div>
      </div>

      <div className="prs__bottom">
        <span className="prs__bottom--amount-in-words">
          ({toWords.convert(totalacademicAmount, { currency: true })})
        </span>

        <div className="row g-0">
          <div className="col prs__bottom--payment-method">
            {USE_NON_DEMAND_CASH_DETAILS ? (
              <b>
                {filteredDebitTransactions &&
                filteredDebitTransactions[0]?.acct_ldgr.ldgr_cash_bank_other ===
                  BankOrCash.CASH
                  ? filteredDebitTransactions[0].acct_ldgr.ldgr_desc
                  : ": " + state.studentId
                  ? studentReceiptDetails?.v_transcation_type
                  : passedOutStudentReceiptDetails?.v_transcation_type +
                    " (" +
                    state.studentId
                  ? studentReceiptDetails?.v_transcation_no
                  : passedOutStudentReceiptDetails?.v_transcation_no + ")"}
              </b>
            ) : null}

            {filteredDebitTransactions &&
            filteredDebitTransactions[0]?.acct_ldgr.ldgr_cash_bank_other ===
              BankOrCash.CASH ? null : (
              <>
                {USE_NON_DEMAND_TRANSACTION_NUMBER && (
                  <div className="col">
                    <span>
                      Transaction No : <b>{transaction_no}</b>
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        {USE_NON_DEMAND_NARRATIONS ? (
          <span className="prs__bottom--amount-in-words">
            Narration : {studentReceiptDetails?.v_transcation_narration}
          </span>
        ) : null}
        <div className="prs__bottom--signature">
          <b>Receiver's Signature</b>
        </div>
        {USE_NON_DEMAND_RECEIPT_FOOTER && (
          <div className="prs__bottom--narration">
            <span>{USE_NON_DEMAND_RECEIPT_FOOTER}</span>
          </div>
        )}

        {USE_NON_DEMAND_RECEIPT_FOOTER_2 && (
          <div className="prs__bottom--narration">
            <span>{USE_NON_DEMAND_RECEIPT_FOOTER_2}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default NonDemandReceiptPrint;
