import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import dayjs from "dayjs";
import Modal from "react-modal";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  AddAcctVoucherMasterAndDetails,
  AddAcctVoucherMasterMkCkAndDetails,
  EditAcctPaymentVouchers,
} from "../queries/receipts/mutation/Index";
import { Button } from "../../../stories/Button/Button";
import InputHoc from "../.././../components/common/Input/Index";
import Input from "../../../stories/Input/Input";

import { Label } from "../../../stories/Label/Label";
import { Title } from "../../../stories/Title/Title";
import { TableHeaderProps } from "../../../Types/Tables";
import { VoucherTitleProps } from "../../../Types/Titles";
import {
  DEFAULT_TIME,
  EMPTY_STRING,
  ROWS_PER_PAGE,
  TODAY_DATE,
} from "../../../utils/constants";
import {
  BankOrCash,
  DebitOrCredit,
  Direction,
  InstitutionConfigurationTypes,
  ModuleName,
  Operation,
  PageFor,
  PageNumbers,
  ReceiptTypes,
  SortBy,
  transactionTypeEnum,
  VoucherBookKey,
  VoucherPageType,
} from "../../../utils/Enum.types";
import {
  DateRange,
  formatter,
  handleDbOrCr,
  isOptionEqualToValue,
  NameOfTheDay,
  RefsByTagName,
  toInputStandardDate,
  toIsoDate,
  toStandardCase,
} from "../../../utils/UtilFunctions";
import Home from "../Home/Index";

import AccountingLedger from "../AccountingLedger/Index";

import ConfigurationSettings from "../../Master/configurations/general/Index";
import useDisplayConfigIcon from "../../../customhooks/useDisplayConfigIcon";
import {
  AccountingLedgerModalStyles,
  ConfigurationsModalStyles,
  EditModalCustomStyles,
  PrintConfigModalStyles,
  PrintModalStyles,
  StudentModalStyles,
} from "../../../styles/ModalStyles";
import LoadingModal from "../../../pages/LoadingModal";
import useAcctLedgerData from "../hooks/useAcctLedgerData";
import useToken from "../../../customhooks/useToken";
import { Keys } from "../../../utils/Enum.keys";
import Settings from "../../../images/Settings.svg";
import Close from "../../../images/Close.svg";
import PaymentVoucher from "../../../images/PaymentVoucher.svg";
import ReceiptVoucher from "../../../images/ReceiptVoucher.svg";
import BanktoBank from "../../../images/BanktoBank.svg";
import EditProfile from "../../../images/EditProfile.svg";
import DeleteImage from "../../../images/Delete.svg";
import MessageModal from "../../../pages/MessageModal";
import {
  msgType,
  optionsType,
  responseType,
  VoucherPaymentFormType,
} from "../../../utils/Form.types";
import useVoucherNumber, {
  VoucherBookData,
  VoucherBookVars,
} from "../hooks/useVoucherNumber";
import {
  GetAcctVouchers,
  GetAcctVouchersMkCk,
  GetVoucherNumber,
} from "../queries/Vouchers/query";
import { Form, Formik } from "formik";
import { PaymentVoucherValidation } from "../../../utils/validationRules";
import useInstReferenceDataByType, {
  InstReferenceDataByType,
} from "../../../customhooks/useInstReferenceDataByType";
import { AcctLedgerQueryType, GroupLedgerRPTypes } from "../common/QueryTypes";
import useServerDateandTime from "../../Library/customHooks/useServerDateandTime";
import VoucherReceiptPrint from "../../Print/Accounts/Vouchers/VoucherPrint";
import { AppContext } from "../../../context/context";
import { payloadTypes } from "../../../context/reducer";
import {
  InstitutionAcctConfigurationTypes,
  MkCkQueryType,
  VoucherApprovalStatus,
  VoucherQueryTypes,
} from "../common/Enum.types";
import { AccountVoucherDetailsByMasterVoucherId } from "../../../queries/common";
import { nodevars, VouchernodeData } from "../../../Types/Accounting";
import PerModuleConfiguration from "../../Configurations/PerModuleConfiguration";
import { SwConfigQueryType } from "../../HR/enums/Enum.types";
import Enter from "../../../images/Enter.svg";
import Save from "../../../images/Save.svg";
import PendingRequestImage from "../../../images/PendingRequest.svg";
import RejectedRequestImage from "../../../images/RejectedRequest.svg";
import ApprovedRequestImage from "../../../images/ApprovedRequest.svg";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import { GlobalPageConfigData } from "../../../Types/configtypes";
import { CustomTooltip, TOOLTIP_COLORS } from "../../../styles/TooltipStyles";
import ApprovedRequest from "./MakerAndChecker/Requests";
import Send from "../../../images/Send.svg";
import { PendingStatusFor } from "./MakerAndChecker/View";
import useEmployee, { empQueryTypes } from "../../HR/hooks/useEmployee";
import useEmpDetailsById from "../../HR/hooks/useEmpDetailsById";
import useUploadComponent from "../../../customhooks/useUploadComponent";
import useInstDetails from "../../../customhooks/general/useInstDetails";
import TextArea from "../../../stories/TextArea/TextArea";
import useAcctTableJson from "../json/useAcctTableJson";
import useLoggedInUserDetails from "../hooks/useLoggedInUserDetails";
import {
  AddAcctVoucherMasterAndDetailsData,
  AddAcctVoucherMasterAndDetailsVars,
} from "../../../Types/Accounting/mutations";
import {
  FormAutocomplete,
  formClasses,
} from "../../../styles/AutocompleteStyles";
const { AccountsTitles } = require("../json/title.json");
interface VoucherData {
  acctLdgrId: number;
  debitAmt: number;
  creditAmt: number;
  byTo: string;
  cashBank: string;
  index: number;
  name: string;
  ldgr_cb: number;
  grp_ldgr_desc: string;
  gr_ldgr_rp: string;
}

const transactionType = [
  { label: "By", value: transactionTypeEnum.BY },
  { label: "To", value: transactionTypeEnum.TO },
];

interface props {
  type: VoucherPageType;
  operation: Operation;
  pageType: PageFor;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}
interface LedgerOptionsType extends responseType {
  cash_or_bank: string;
  ldgr_cb: number;
  grp_ldgr_desc: string;
  gr_ldgr_rp: string;
}
const NewVoucher = ({ type, operation, pageType, setModalFlag }: props) => {
  const { Accounts_Table } = useAcctTableJson();
  const { dispatch, state } = useContext(AppContext);

  const { firstDay, lastDay } = DateRange(new Date().toString()) || {};
  const { token } = useToken();
  const { InstId } = useParams();
  const { format } = formatter;
  const navigate = useNavigate();
  const { serverDate } = useServerDateandTime();
  const floatingPointRegx = /^[+-]?\d+(\.\d*)?$/;
  const [date, setDate] = useState("");
  const [voucherNumber, SetVoucherNumber] = useState("");
  const [voucherType, setVoucherType] = useState("");
  const [printModal, setPrintModal] = useState(false);
  const [printConfig, setPrintConfigModal] = useState(false);
  //modal
  const [accountingLedgerMOdal, setAccountingLedgerModal] = useState(false);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [newbyto, setNewByTo] = useState<optionsType | undefined>(
    transactionType.find(({ value }) => value === transactionTypeEnum.BY)
  );
  const [acctLdgrId, setAcctLdgrId] = useState<LedgerOptionsType | null>(null);

  const [reportingmanagerId, setReportingManagerId] =
    useState<responseType | null>(null);
  const [approvalRemarks, setApprovalRemarks] = useState("");
  const [searchData, setsearchData] = useState("");
  const [edit, setEdit] = useState(false);
  const [debitAmount, setDebitAmount] = useState("");
  const [creditAmount, setCreditAmount] = useState("");
  const [editIndex, setEditIndex] = useState(0);
  const [editAcctLedger, setEditAcctLdgr] = useState(false);
  const [items, setItems] = useState<VoucherData[]>([]);
  const [selectedAcctLdgr, setAcctLdgr] = useState(false);
  const itemsSet = new Set(items.map(({ cashBank }) => cashBank));

  const [formData, setFormData] = useState<VoucherPaymentFormType>({
    payTo: "",
    DDNo: "",
    DDDate: TODAY_DATE,
    BillNo: "",
    BillDate: TODAY_DATE,
    Remarks: "",
    partyName: "",
  });

  //Consts
  const receiptType = "receiptType";
  const { InstreferenceData } = useInstReferenceDataByType(
    InstReferenceDataByType.NAME_TITLE
  );
  const [GetPaymentVoucherDetails] = useLazyQuery<VouchernodeData, nodevars>(
    AccountVoucherDetailsByMasterVoucherId
  );

  const { empolyeeData } = useEmpDetailsById(false, true);
  const { empDetails } = useEmployee(
    ROWS_PER_PAGE,
    empQueryTypes.REPORTING_MANAGER,
    "",
    0,
    empolyeeData.data ? empolyeeData.data.node.pr_dept_details.id : 0,
    empolyeeData.data ? empolyeeData.data.node.pr_designation_details.id : 0
  );

  //useRefs
  const bytoRef = useRef<HTMLSelectElement>(null);
  const feeDescRef = useRef<HTMLSelectElement>(null);
  const feeDescInputRef = RefsByTagName(feeDescRef);
  const bytoRefInputRef = RefsByTagName(bytoRef);
  const debitRef = useRef<HTMLInputElement>(null);
  const creditRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const payToRef = useRef<HTMLSelectElement>(null);
  const tableBodyRef = useRef<HTMLTableSectionElement>(null);

  const paytoInputRef = RefsByTagName(payToRef);

  const [requestToApprove, setRequestToApprove] = useState(false);
  const [approvedRequest, setApprovedRequest] = useState(false);
  const [rejectedRequest, setRejectedRequest] = useState(false);
  const [pendingRequest, setPendingRequest] = useState(false);
  const narrationRef = useRef<HTMLTextAreaElement>(null);

  const payToNameRef = useRef<HTMLInputElement>(null);
  const saveRef = useRef<HTMLButtonElement>(null);
  const { InstDetails } = useInstDetails(1);
  //configuration modal
  const [configurationModal, setConfigurationModal] = useState(false);

  const { USE_CONFIG_KEY } = useDisplayConfigIcon(PageNumbers.VOUCHER_PAGE);
  const { user_details } = useLoggedInUserDetails();
  const accLdgrIdSet = new Set(items.map(({ acctLdgrId }) => acctLdgrId));
  const { component, file, filename, uploadFile, setFile } = useUploadComponent(
    `${InstDetails.data?.nodes[0]?.inst_name}/${type}/${voucherNumber}`,
    Operation.CREATE,
    EMPTY_STRING,
    InstitutionConfigurationTypes.ATTACHMENT_FILE_SIZE
  );
  const {
    acctLedgers: { data: cashAndBankData },
  } = useAcctLedgerData(
    searchData,
    AcctLedgerQueryType.CASH_AND_BANK,
    ROWS_PER_PAGE
  );

  const { configData: configKeys } = useSwConfigData([
    InstitutionAcctConfigurationTypes.VOUCHER_EDIT_DATE,
    InstitutionAcctConfigurationTypes.ENABLE_MAKER_CHECKER,
    InstitutionAcctConfigurationTypes.MKCK_MAX_PAYMENT_AMOUNT,
  ]);
  const {
    acctLedgers: { data: notCashAndBankData },
  } = useAcctLedgerData(
    searchData,
    AcctLedgerQueryType.EXCEPT_CASH_AND_BANK,
    ROWS_PER_PAGE
  );

  const { acctLedgers: paymentLedgers } = useAcctLedgerData(
    searchData,
    AcctLedgerQueryType.ACCT_LDGRS_BY_INST_ID,
    ROWS_PER_PAGE
  );
  //lazyquery
  const [GetVoucherDetails, { data: voucherData }] = useLazyQuery<
    VoucherBookData,
    VoucherBookVars
  >(GetVoucherNumber);

  const { voucherNumber: mkckVno } = useVoucherNumber(
    VoucherBookKey.PAYMENT_MKCK
  );

  //mutations
  const [GenerateVoucherReceipts, { loading: GenerateStudentReceiptsLoading }] =
    useMutation<
      AddAcctVoucherMasterAndDetailsData,
      AddAcctVoucherMasterAndDetailsVars
    >(AddAcctVoucherMasterAndDetails, {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    });

  const [GenerateApprovalReqest, { loading: generateApprovalLoading }] =
    useMutation(AddAcctVoucherMasterMkCkAndDetails, {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    });

  const [EditVoucherReceipts, { loading: EditStudentReceiptsLoading }] =
    useMutation(EditAcctPaymentVouchers, {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    });

  const filterDataByConfigKey = (data: GlobalPageConfigData[]) => {
    let editDate = false;
    let enableApproval = false;
    let maxAmountForApproval = 0;
    if (data) {
      data.forEach((item) => {
        switch (item.config_key) {
          case InstitutionAcctConfigurationTypes.VOUCHER_EDIT_DATE:
            editDate = item.config_boolean_value;
            break;
          case InstitutionAcctConfigurationTypes.ENABLE_MAKER_CHECKER:
            enableApproval = item.config_boolean_value;
            break;
          case InstitutionAcctConfigurationTypes.MKCK_MAX_PAYMENT_AMOUNT:
            maxAmountForApproval = item.config_integer_value;
            break;

          default:
            break;
        }
      });
    }
    return {
      editDate,
      enableApproval,
      maxAmountForApproval,
    };
  };
  const { editDate, enableApproval, maxAmountForApproval } =
    filterDataByConfigKey(configKeys.data?.GetSwConfigVariables!);

  const handleValueChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) =>
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));

  const handleItems = () => {
    const { value: acctLdgrIdValue } = acctLdgrId || { value: 0 };
    const { value: newbytoValue } = newbyto || { value: "" };

    const itemAlreadyExists = items.some(
      (d) => d.acctLdgrId === acctLdgrIdValue
    );
    const ledgerWithZeroAmount = items.find(
      ({ debitAmt, creditAmt }) => debitAmt === 0 && creditAmt === 0
    );

    if (itemAlreadyExists) {
      setMessage({
        flag: true,
        message: "Duplicate fee Ledger entry",
        operation: Operation.NONE,
      });
      setAcctLdgrId(null);
      feeDescInputRef?.select();
      feeDescInputRef?.focus();
      return;
    }

    if (ledgerWithZeroAmount) {
      setMessage({
        flag: true,
        message: `${ledgerWithZeroAmount.name} has no amount`,
        operation: Operation.NONE,
      });
      return;
    }

    if (newbytoValue && acctLdgrIdValue && (debitAmount || creditAmount)) {
      setItems((localItems) => {
        const newItem: VoucherData = {
          acctLdgrId: acctLdgrIdValue,
          creditAmt: creditAmount ? parseFloat(creditAmount) : 0,
          debitAmt: debitAmount ? parseFloat(debitAmount) : 0,
          byTo: newbytoValue,
          cashBank:
            AutoCompleteOptions(newbytoValue).find(
              (d) => d.value === acctLdgrIdValue
            )?.cash_or_bank || "",
          name:
            AutoCompleteOptions(newbytoValue).find(
              (d) => d.value === acctLdgrIdValue
            )?.label || "",
          index: localItems.length + 1,
          grp_ldgr_desc: acctLdgrId ? acctLdgrId.grp_ldgr_desc : "",
          // ldgr_cb: acctLdgrId
          //   ? amountCalc(
          //       acctLdgrId.ldgr_cb,
          //       handleDbOrCr(
          //         acctLdgrId.gr_ldgr_rp as GroupLedgerRPTypes,
          //         acctLdgrId.ldgr_cb
          //       )
          //     )
          //   : 0,
          ldgr_cb: acctLdgrId ? acctLdgrId.ldgr_cb : 0,
          gr_ldgr_rp: acctLdgrId ? acctLdgrId.gr_ldgr_rp : "",
        };
        return [
          ...localItems.map((item, index) => ({ ...item, index: index + 1 })),
          newItem,
        ];
      });
    }

    const parseAmount = (amount: string) => parseFloat(amount ? amount : "0");

    const isDebitGreaterThanCredit =
      totalDebitAmount + parseAmount(debitAmount) >
      totalCreditAmount + parseAmount(creditAmount);

    const findTransactionType = (type: transactionTypeEnum) =>
      transactionType.find(({ value }) => value === type);

    const calculateNewAmount = (debit: number, credit: number) => {
      return isDebitGreaterThanCredit
        ? totalDebitAmount + parseAmount(debitAmount) + credit
        : totalCreditAmount + parseAmount(creditAmount) + debit;
    };

    if (isDebitGreaterThanCredit) {
      setNewByTo(findTransactionType(transactionTypeEnum.TO));
      const credit = totalCreditAmount
        ? -totalCreditAmount - parseAmount(creditAmount)
        : -parseAmount(creditAmount);

      setCreditAmount(calculateNewAmount(0, credit).toString());
      setDebitAmount("");
    } else {
      setNewByTo(findTransactionType(transactionTypeEnum.BY));
      const debit = totalDebitAmount
        ? -totalDebitAmount - parseAmount(debitAmount)
        : -parseAmount(debitAmount);
      setDebitAmount(calculateNewAmount(debit, 0).toString());
      setCreditAmount("");
    }

    bytoRefInputRef?.select();
    bytoRefInputRef?.focus();

    setAcctLdgrId(null);
    setsearchData("");
    setAcctLdgr(false);
  };

  let totalDebitAmount = 0;
  let totalCreditAmount = 0;

  // const handleRemainingAmount = (
  //   accountLedgerId: number,
  //   transactionType: string,
  //   debitAmount: number,
  //   creditAmount: number
  // ) => {
  //   const account_ledger_details =
  //     paymentLedgers?.data?.GetAcctLdgrs.edges.find(
  //       (v) => v.node.id === accountLedgerId
  //     );
  //   const ldgr_cb = account_ledger_details && account_ledger_details!;
  //   if (transactionType === transactionTypeEnum.BY && ldgr_cb) {
  //     if (ldgr_cb.node.ldgr_cb > 0 && debitAmount <= ldgr_cb.node.ldgr_cb) {
  //       return ldgr_cb?.node.ldgr_cb - debitAmount;
  //     }
  //     if (transactionType === transactionTypeEnum.TO.toString() && ldgr_cb) {
  //       return ldgr_cb?.node.ldgr_cb! + creditAmount;
  //     }
  //     return ldgr_cb;
  //   }
  // };

  const handleDelete = (ids: number) => {
    const updatedItems = items.filter((item) => item.acctLdgrId !== ids);

    setItems(updatedItems);
  };

  const filterEditItem = (id: number) => {
    const filteredData = items.find((f) => f.acctLdgrId === id);
    if (filteredData) {
      const res = AutoCompleteOptions(filteredData.byTo).find(
        (f) => f.value === filteredData.acctLdgrId
      );
      res && setAcctLdgrId(res);
      setCreditAmount(filteredData.creditAmt.toString());
      setDebitAmount(filteredData.debitAmt.toString());
      setNewByTo(transactionType.find((f) => f.value === filteredData.byTo)!);
    }
  };
  const HandleEditItem = (index: number) => {
    const filteredSelectedAcctLdgr = items.filter(
      (item) => acctLdgrId && item.acctLdgrId === acctLdgrId.value
    );
    const foundAcctLdgr = filteredSelectedAcctLdgr.find(
      ({ acctLdgrId: id }) => acctLdgrId && id === acctLdgrId.value
    );

    if (
      newbyto &&
      foundAcctLdgr &&
      acctLdgrId &&
      foundAcctLdgr.byTo !== newbyto.value
    ) {
      alert(`${acctLdgrId.label} is already present as ${foundAcctLdgr.byTo}`);
      return;
    }

    const updatedData = items.map((item) => {
      if (item.index === index && acctLdgrId && newbyto) {
        return {
          ...item,
          acctLdgrId: acctLdgrId.value,
          creditAmt: creditAmount ? parseFloat(creditAmount) : 0,
          debitAmt: debitAmount ? parseFloat(debitAmount) : 0,
          byTo: newbyto.value,
          cashBank:
            AutoCompleteOptions(newbyto.value).find(
              (d) => d.value === acctLdgrId.value
            )?.cash_or_bank || "",
          index: item.index,
          name:
            AutoCompleteOptions(newbyto.value).find(
              (d) => d.value === acctLdgrId.value
            )?.label || "",
        };
      }
      return item;
    });
    feeDescInputRef?.focus();

    setItems(updatedData);
    setsearchData("");
    setDebitAmount("");
    setCreditAmount("");
    setAcctLdgrId(null);
    setEdit(false);
    setEditAcctLdgr(false);
    setAcctLdgr(false);
  };
  const handleApprovalRequest = async () => {
    try {
      const uploadResult = await uploadFile();
      if (uploadResult) {
        GenerateApprovalReqest({
          variables: {
            token,
            inst_id: InstId,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            user_details,
            input: {
              acct_voucher_master: {
                fin_yr: state.ActiveFinYr
                  ? state.ActiveFinYr.fin_yr
                  : EMPTY_STRING,
                mkck_type: ReceiptTypes.PYMT,
                mkck_book_type: VoucherBookKey.PAYMENT_MKCK,
                remarks: formData.Remarks,
                mkck_no: mkckVno.data?.GetVoucherNumber.vo_number,
                mkck_date: toIsoDate(date),
                mkck_total_cr_amt: totalCreditAmount,
                mkck_total_db_amt: totalDebitAmount,
                party_bill_no: formData.BillNo,
                party_bill_date: toIsoDate(formData.BillDate),
                party_name: formData.partyName,
                transcation_no: formData.DDNo,
                transcation_type: itemsSet.has(BankOrCash.CASH)
                  ? "Cash A/c"
                  : "Bank Transaction",
                payment_invoice_filename: file
                  ? `${InstDetails.data?.nodes[0]?.inst_name}/${type}/${voucherNumber}`
                  : EMPTY_STRING,
              },
              acct_voucher_db: debitItemArray.map((item, index) => ({
                vo_cr_db: DebitOrCredit.DEBIT,
                vo_sl_no: item.index,
                vo_cr: 0,
                vo_db: item.debitAmt,
                vo_cr_total: 0,
                vo_db_total: totalDebitAmount,
                acct_ldgr_id: item.acctLdgrId,
              })),
              acct_voucher_cr: creditItemArray.map((item) => ({
                vo_sl_no: item.index,
                vo_cr_db: DebitOrCredit.CREDIT,
                vo_cr: item.creditAmt,
                vo_db: 0,
                vo_cr_total: totalCreditAmount,
                vo_db_total: 0,
                acct_ldgr_id: item.acctLdgrId,
              })),
              mkck_assign_details: {
                remarks: approvalRemarks,
                status: VoucherApprovalStatus.Pending,
                pr_emp_id_created: state.empLoginId,
                pr_emp_id_assigned: reportingmanagerId
                  ? reportingmanagerId.value
                  : 0,
              },
            },
          },
          refetchQueries: [
            {
              query: GetAcctVouchersMkCk,
              variables: {
                finYrId: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
                first: ROWS_PER_PAGE,
                input: {
                  pr_emp_id: state.empLoginId,
                  query_type: MkCkQueryType.MK_CK_PENDING,
                },
                instId: InstId!,
                orderBy: { direction: Direction.ASC },
                token,
                after: null,
              },
            },
          ],
        }).then(({ data }) => {
          if (data) {
            setMessage({
              flag: true,
              message: "Voucher Sent for Approval Successfully",
              operation: Operation.CREATE,
            });
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleStudentReceipts = async () => {
    try {
      const uploadResult = await uploadFile();
      if (uploadResult) {
        if (operation === Operation.CREATE) {
          GenerateVoucherReceipts({
            variables: {
              token,
              inst_id: InstId!,
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              user_details,
              input: [
                {
                  acct_voucher_master: {
                    fin_yr: state.ActiveFinYr
                      ? state.ActiveFinYr.fin_yr
                      : EMPTY_STRING,
                    v_type:
                      type === VoucherPageType.PAYMENT_VOUCHER
                        ? ReceiptTypes.PYMT
                        : type === VoucherPageType.RECEIPT_VOUCHER
                        ? ReceiptTypes.RCPT
                        : type === VoucherPageType.CONTRA_VOUCHER
                        ? ReceiptTypes.CONTRA
                        : ReceiptTypes.JOURNAL,
                    v_no: voucherNumber,
                    v_date: toIsoDate(date),
                    v_total_cr_amt: totalCreditAmount,
                    v_total_db_amt: totalDebitAmount,
                    v_reconciled_date: toIsoDate(DEFAULT_TIME),
                    v_reconciled: false,
                    v_std_receipt: false,
                    v_std_refund: false,
                    v_std_deposit_adjusted: false,
                    v_std_receipt_ob: false,
                    v_std_deposit: false,
                    v_std_demand_receipt: false,
                    v_std_scholarship_deposit: false,
                    v_std_passout_receipt: false,
                    v_transcation_cash_or_bank: itemsSet.has(BankOrCash.CASH)
                      ? BankOrCash.CASH
                      : BankOrCash.BANK,
                    v_std_non_demand_receipt: false,
                    v_book_type: voucherType,
                    student_id: 0,
                    v_std_anonymous_deposit_adjusted: false,

                    class_id: 0,
                    v_std_receipt_anonymous: false,
                    semester_id: 0,
                    v_std_amt_receipt: 0,
                    v_std_amt_deposit: 0,
                    v_std_amt_fine: 0,
                    v_std_amt_total: 0,
                    v_std_amt_refunded: 0,
                    v_std_amt_adjusted: 0,
                    v_std_enquiry: false,
                    enquiry_student_id: 0,
                    v_transcation_type: receiptType,
                    v_transcation_no: formData.DDNo,
                    v_transcation_date: toIsoDate(TODAY_DATE),
                    v_transcation_narration: formData.Remarks,
                    v_std_refund_deposit: false,
                    // paid_party_id: "",
                    party_bill_no: formData.BillNo,
                    party_bill_date: toIsoDate(formData.BillDate),
                    // don't remove extra it is being used in printing vouchers
                    party_name: formData.payTo + " " + formData.partyName,
                    annx_yesno: false,
                    // annx_id: Math.random() * 1000,
                    is_vouch_multi_entry: false,
                    acct_ldgr_id_cr: creditItemArray[0].acctLdgrId,
                    acct_ldgr_id_db: debitItemArray[0].acctLdgrId,
                    payment_invoice_filename: file ? filename : EMPTY_STRING,
                  },
                  acct_voucher_db: debitItemArray.map((item, index) => ({
                    vo_cr_db: DebitOrCredit.DEBIT,
                    vo_sl_no: item.index,
                    vo_cr: 0,
                    vo_db: item.debitAmt,
                    vo_cr_total: 0,
                    vo_db_total: totalDebitAmount,
                    acct_ldgr_id: item.acctLdgrId,
                  })),
                  acct_voucher_cr: creditItemArray.map((item) => ({
                    vo_sl_no: item.index,
                    vo_cr_db: DebitOrCredit.CREDIT,
                    vo_cr: item.creditAmt,
                    vo_db: 0,
                    vo_cr_total: totalCreditAmount,
                    vo_db_total: 0,
                    acct_ldgr_id: item.acctLdgrId,
                  })),
                },
              ],
            },
            refetchQueries: [
              {
                query: GetAcctVouchers,
                variables: {
                  after: null,
                  direction: Direction.ASC,
                  fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
                  first: null,
                  sortBy: SortBy.V_DATE,
                  token,
                  v_no: "",
                  deposit: false,
                  vTranscationCashOrBank: null,
                  partyName: EMPTY_STRING,
                  vTranscationNo: EMPTY_STRING,
                  vTranscationNarration: EMPTY_STRING,
                  ldgrDesc: EMPTY_STRING,
                  amount: null,
                  input: {
                    inst_id: InstId!,
                    voucher_query_type: VoucherQueryTypes.BY_DATES,
                    vo_end_date: dayjs(lastDay).format(),
                    acct_ldgr_id: 0,
                    vo_start_date: dayjs(firstDay).format(),
                    vo_type: EMPTY_STRING,
                    student_id: 0,
                  },
                },
              },
              {
                query: GetVoucherNumber,
                variables: {
                  token,
                  vo_book_key: voucherType,
                  inst_id: InstId!,
                  fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
                },
              },
            ],
          }).then(({ data }) => {
            if (data) {
              dispatch({
                type: payloadTypes.SET_RECEPIT_ID,
                payload: {
                  receiptId: data.AddAcctVoucherMasterAndDetails[0].id,
                },
              });
              if (type !== VoucherPageType.CONTRA_VOUCHER) {
                setPrintModal(!printModal);
              }

              handleClear();
              setMessage({
                message: "Successfully Generated",
                flag: true,
                operation: Operation.CREATE,
              });
            }
          });
        } else {
          EditVoucherReceipts({
            variables: {
              token,
              inst_id: InstId,
              user_details,
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              voucher_master_id: state.receiptId,
              input: {
                acct_ldgr_id_cr: creditItemArray[0].acctLdgrId,
                acct_ldgr_id_db: debitItemArray[0].acctLdgrId,
                payment_invoice_filename: file ? filename : EMPTY_STRING,
                acct_voucher_cr: creditItemArray.map((item) => ({
                  vo_sl_no: item.index,
                  vo_cr_db: DebitOrCredit.CREDIT,
                  vo_cr: item.creditAmt,
                  vo_db: 0,
                  vo_cr_total: totalCreditAmount,
                  vo_db_total: 0,
                  acct_ldgr_id: item.acctLdgrId,
                })),
                acct_voucher_db: debitItemArray.map((item) => ({
                  vo_cr_db: DebitOrCredit.DEBIT,
                  vo_sl_no: item.index,
                  vo_cr: 0,
                  vo_db: item.debitAmt,
                  vo_cr_total: 0,
                  vo_db_total: totalDebitAmount,
                  acct_ldgr_id: item.acctLdgrId,
                })),
                // paid_party_id:
                party_bill_date: toIsoDate(formData.BillDate),
                party_bill_no: formData.BillNo,
                party_name: formData.payTo + " " + formData.partyName,
                v_date: toIsoDate(date),
                v_total_cr_amt: totalCreditAmount,
                v_total_db_amt: totalDebitAmount,
                v_transcation_cash_or_bank: itemsSet.has(BankOrCash.CASH)
                  ? BankOrCash.CASH
                  : BankOrCash.BANK,
                v_transcation_date: toIsoDate(date),
                v_transcation_narration: formData.Remarks,
              },
            },
            refetchQueries: [
              {
                query: AccountVoucherDetailsByMasterVoucherId,
                variables: {
                  token,
                  ids: [state.receiptId],
                },
              },
              {
                query: GetAcctVouchers,
                variables: {
                  after: null,
                  direction: Direction.ASC,
                  fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
                  first: null,
                  sortBy: SortBy.V_DATE,
                  token,
                  v_no: "",
                  deposit: null,
                  vTranscationCashOrBank: itemsSet.has(BankOrCash.CASH)
                    ? BankOrCash.CASH
                    : BankOrCash.BANK,
                  partyName: EMPTY_STRING,
                  vTranscationNo: EMPTY_STRING,
                  vTranscationNarration: EMPTY_STRING,
                  ldgrDesc: EMPTY_STRING,
                  amount: null,
                  input: {
                    inst_id: InstId!,
                    voucher_query_type: VoucherQueryTypes.BY_DATES,
                    vo_end_date: dayjs(lastDay).format(),
                    acct_ldgr_id: 0,
                    vo_start_date: dayjs(firstDay).format(),
                    vo_type: EMPTY_STRING,
                    student_id: 0,
                  },
                },
                fetchPolicy: "network-only",
              },
            ],
          }).then(({ data }) => {
            if (data) {
              setMessage({
                message: "Successfully Edited",
                flag: true,
                operation: Operation.UPDATE,
              });
            }
          });
        }
      }
    } catch (err) {
      console.log("error in catch");
    }
  };

  const debitItemArray: VoucherData[] = [];
  const creditItemArray: VoucherData[] = [];
  // eslint-disable-next-line
  items.map((item) => {
    if (
      item.byTo === transactionTypeEnum.BY &&
      item.acctLdgrId &&
      item.debitAmt > 0
    ) {
      debitItemArray.push(item);
    }
    if (
      item.byTo === transactionTypeEnum.TO &&
      item.acctLdgrId &&
      item.creditAmt > 0
    ) {
      creditItemArray.push(item);
    }
    totalCreditAmount += item.creditAmt;
    totalDebitAmount += item.debitAmt;
  });

  const handleClear = () => {
    setCreditAmount("");
    setDebitAmount("");
    setsearchData("");
    setFormData({
      BillDate: toInputStandardDate(serverDate),
      BillNo: "",
      DDDate: toInputStandardDate(serverDate),
      DDNo: "",
      partyName: "",
      payTo: "",
      Remarks: "",
    });
    setAcctLdgrId(null);
    setReportingManagerId(null);
    setApprovalRemarks("");
    selectTransactionType();
    setAcctLdgr(false);
    setItems([]);
    setFile(null);
    if (feeDescInputRef) feeDescInputRef.focus();
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
      setModalFlag(false);
      setRequestToApprove(false);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  const AcctledgerDropDown: LedgerOptionsType[] = paymentLedgers.data
    ? paymentLedgers.data?.GetAcctLdgrs.edges?.map((ledger) => ({
        label: ledger.node.ldgr_desc,
        // handleRemainingAmount(
        //   Number(item.particular),
        //   item.byTo,
        //   item.debit_amount,
        //   item.credit_amount
        // ) +
        value: ledger.node.id,
        isChecked: false,
        cash_or_bank: ledger.node.ldgr_cash_bank_other,
        grp_ldgr_desc: ledger.node.acct_group_ldgr_details.gr_ldgr_desc,
        ldgr_cb: ledger.node.ldgr_cb,
        gr_ldgr_rp: ledger.node.acct_group_ldgr_details.gr_ldgr_rp,
      }))
    : [];

  const cashAndBank: LedgerOptionsType[] = cashAndBankData
    ? cashAndBankData?.GetAcctLdgrs.edges?.map((ledger) => ({
        label: ledger.node.ldgr_desc,
        value: ledger.node.id,
        isChecked: false,
        cash_or_bank: ledger.node.ldgr_cash_bank_other,
        grp_ldgr_desc: ledger.node.acct_group_ldgr_details.gr_ldgr_desc,
        ldgr_cb: ledger.node.ldgr_cb,
        gr_ldgr_rp: ledger.node.acct_group_ldgr_details.gr_ldgr_rp,
      }))
    : [];
  const notCashAndBank: LedgerOptionsType[] = notCashAndBankData
    ? notCashAndBankData?.GetAcctLdgrs.edges?.map((ledger) => ({
        label: ledger.node.ldgr_desc,
        value: ledger.node.id,
        isChecked: false,
        cash_or_bank: ledger.node.ldgr_cash_bank_other,
        grp_ldgr_desc: ledger.node.acct_group_ldgr_details.gr_ldgr_desc,
        ldgr_cb: ledger.node.ldgr_cb,
        gr_ldgr_rp: ledger.node.acct_group_ldgr_details.gr_ldgr_rp,
      }))
    : [];

  const chooseVoucherType = () => {
    switch (type) {
      case VoucherPageType.PAYMENT_VOUCHER:
        return VoucherBookKey.PAYMENT_VOUCHER_NUMBER;
      case VoucherPageType.RECEIPT_VOUCHER:
        return VoucherBookKey.RECEIPT_VOUCHER_NUMBER;
      case VoucherPageType.CONTRA_VOUCHER:
        return VoucherBookKey.CONTRA_VOUCHER_NUMBER;
      default:
        return VoucherBookKey.JOURNAL;
    }
  };

  const AutoCompleteOptions = (by_to: string) => {
    if (!by_to) return [];

    const onlyAcctLedgerIds = items.map((item) => item.acctLdgrId);
    switch (type) {
      case VoucherPageType.PAYMENT_VOUCHER:
        if (by_to === transactionTypeEnum.TO && paymentLedgers.loading) {
          return []; // return empty array while data is loading
        } else if (by_to === transactionTypeEnum.TO && paymentLedgers.data) {
          return AcctledgerDropDown; //Checking if the options was already present and avoiding them to reoccur
        } else {
          return notCashAndBank;
        }
      case VoucherPageType.RECEIPT_VOUCHER:
        if (by_to === transactionTypeEnum.TO) {
          return notCashAndBank;
        } else {
          return AcctledgerDropDown;
        }
      case VoucherPageType.CONTRA_VOUCHER:
        return cashAndBank;
      case VoucherPageType.JOURNAL:
        return notCashAndBank;
      default:
        return [];
    }
  };
  useEffect(() => {
    if (serverDate && operation === Operation.CREATE) {
      setFormData((prevValues) => ({
        ...prevValues,
        BillDate: toInputStandardDate(serverDate),
        DDDate: toInputStandardDate(serverDate),
      }));
      setDate(toInputStandardDate(serverDate));
    }
  }, [serverDate, operation, type]);

  const scrollEnd = () => {
    if (
      tableBodyRef.current &&
      tableBodyRef.current.scrollHeight > tableBodyRef.current.clientHeight
    ) {
      tableBodyRef.current.scrollTop += 100;
    }
  };
  useEffect(() => {
    scrollEnd();
  }, [items]);
  useEffect(() => {
    if (voucherType && state.ActiveFinYr && operation === Operation.CREATE) {
      GetVoucherDetails({
        variables: {
          token,
          vo_book_key: voucherType,
          inst_id: InstId!,
          fin_yr_id: state.ActiveFinYr.id,
        },
      }).then(({ data }) => {
        if (data) {
          SetVoucherNumber(data?.GetVoucherNumber.vo_number);
        }
      });
    }
  }, [
    voucherType,
    GetVoucherDetails,
    state.ActiveFinYr,
    token,
    voucherData,
    InstId,
    operation,
  ]);

  useEffect(() => {
    if (state.receiptId && operation === Operation.UPDATE) {
      GetPaymentVoucherDetails({
        variables: {
          token,
          ids: [state.receiptId],
        },
      }).then(({ data }) => {
        if (data) {
          const {
            acct_voucher_details,
            v_transcation_cash_or_bank,
            party_name,
            party_bill_date,
            party_bill_no,
            v_no,
            v_date,
            v_type,
            v_transcation_no,
            v_transcation_narration,
            v_transcation_date,
          } = data.nodes[0];
          const splitArray = party_name.split(" ");
          const sortedDetails = [...acct_voucher_details].sort(
            (a, b) => a.vo_sl_no - b.vo_sl_no
          );

          setItems(
            sortedDetails.map((f, index) => ({
              byTo:
                f.vo_cr_db === DebitOrCredit.DEBIT
                  ? transactionTypeEnum.BY
                  : transactionTypeEnum.TO,
              cashBank: v_transcation_cash_or_bank,
              creditAmt: f.vo_cr,
              debitAmt: f.vo_db,
              index,
              acctLdgrId: f.acct_ldgr.id,
              name: f.acct_ldgr.ldgr_desc,
              grp_ldgr_desc: "",
              ldgr_cb: 0,
              gr_ldgr_rp: "",
            }))
          );
          SetVoucherNumber(v_no);
          setVoucherType(v_type);
          setDate(toInputStandardDate(v_date));
          setFormData({
            BillDate: toInputStandardDate(party_bill_date!),
            BillNo: party_bill_no!,
            DDDate: toInputStandardDate(v_transcation_date!),
            DDNo: v_transcation_no,
            partyName: splitArray[1],
            payTo: splitArray[0],
            Remarks: v_transcation_narration,
          });
        }
      });
    }
  }, [GetPaymentVoucherDetails, operation, state.receiptId, token]);

  const selectTransactionType = () => {
    switch (type) {
      case VoucherPageType.PAYMENT_VOUCHER:
      case VoucherPageType.JOURNAL:
        setNewByTo({
          label: toStandardCase(transactionTypeEnum.BY),
          value: transactionTypeEnum.BY,
        });
        break;

      default:
        setNewByTo({
          label: toStandardCase(transactionTypeEnum.TO),
          value: transactionTypeEnum.TO,
        });
    }
  };

  useEffect(
    () => {
      selectTransactionType();
      setItems([]);
      setDebitAmount("");
      setCreditAmount("");
      setAcctLdgrId(null);
    },
    // eslint-disable-next-line
    [type]
  );

  useEffect(
    () => {
      if (type === VoucherPageType.PAYMENT_VOUCHER) {
        setVoucherType(VoucherBookKey.PAYMENT_VOUCHER_NUMBER);
      }
      if (type === VoucherPageType.RECEIPT_VOUCHER) {
        setVoucherType(VoucherBookKey.RECEIPT_VOUCHER_NUMBER);
      }
      if (type === VoucherPageType.CONTRA_VOUCHER) {
        setVoucherType(VoucherBookKey.CONTRA_VOUCHER_NUMBER);
      }
      if (type === VoucherPageType.JOURNAL) {
        setVoucherType(VoucherBookKey.JOURNAL);
      }

      handleClear();
    },
    // eslint-disable-next-line
    [type]
  );
  useEffect(() => {
    if (type === VoucherPageType.CONTRA_VOUCHER) {
      const foundToCash = items.find(
        ({ byTo, cashBank }) =>
          byTo === transactionTypeEnum.TO && cashBank === BankOrCash.CASH
      );
      const foundByBank = items.find(
        ({ byTo, cashBank }) =>
          byTo === transactionTypeEnum.BY && cashBank === BankOrCash.BANK
      );
      const foundToBank = items.find(
        ({ byTo, cashBank }) =>
          byTo === transactionTypeEnum.TO && cashBank === BankOrCash.BANK
      );
      const foundByCash = items.find(
        ({ byTo, cashBank }) =>
          byTo === transactionTypeEnum.BY && cashBank === BankOrCash.CASH
      );
      if (foundToCash && foundByBank) {
        setFormData((prev) => ({
          ...prev,
          Remarks: "Being Cash Remitted to Bank",
        }));
      }
      if (foundToBank && foundByCash) {
        setFormData((prev) => ({
          ...prev,
          Remarks: "Being Cash Withdrawn from Bank vide Chq No.:",
        }));
      }
      if (foundToBank && foundByBank) {
        setFormData((prev) => ({
          ...prev,
          Remarks: "Inter Bank Transfers vide Chq No.:",
        }));
      }

      setVoucherType(VoucherBookKey.CONTRA_VOUCHER_NUMBER);
    }
    if (type === VoucherPageType.JOURNAL) {
      setVoucherType(VoucherBookKey.JOURNAL);
    }
    if (type) handleClear();
    // eslint-disable-next-line
  }, [type]);
  useEffect(() => {
    if (
      totalCreditAmount === totalDebitAmount &&
      totalCreditAmount !== 0 &&
      totalCreditAmount !== 0
    ) {
      type === VoucherPageType.PAYMENT_VOUCHER ||
      type === VoucherPageType.RECEIPT_VOUCHER
        ? paytoInputRef?.focus()
        : narrationRef.current?.focus();
    }
  }, [totalCreditAmount, paytoInputRef, type, narrationRef, totalDebitAmount]);

  const handleToggleApproved = () => setApprovedRequest(!approvedRequest);
  const handleToggleRejected = () => setRejectedRequest(!rejectedRequest);
  const handleTogglePending = () => setPendingRequest(!pendingRequest);

  const renderModal = (
    pageType: MkCkQueryType,
    isOpen: boolean,
    handleClose: () => void
  ) => (
    <Modal
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      isOpen={isOpen}
      style={StudentModalStyles}>
      <div className="modal-flex h-100">
        <div className="modal-flex__data h-100">
          <ApprovedRequest
            setModalFlag={handleClose}
            pageType={pageType}
            type={PendingStatusFor.MAKER}
          />
        </div>
        <div className="modal-flex__image">
          <img src={Close} alt="/" onClick={handleClose} />
        </div>
      </div>
    </Modal>
  );
  // const amountCalc = (ldgr_cb: number, cr_dr: DebitOrCredit | "") => {
  //   if (ldgr_cb === 0) {
  //     if (Number(creditAmount) > 0 && cr_dr === DebitOrCredit.CREDIT) {
  //       return Number(creditAmount);
  //     }
  //     if (Number(creditAmount) > 0 && cr_dr !== DebitOrCredit.CREDIT) {
  //       return -Number(creditAmount);
  //     }
  //     if (Number(debitAmount) > 0 && cr_dr !== DebitOrCredit.DEBIT) {
  //       return -Number(debitAmount);
  //     }
  //     if (Number(debitAmount) > 0 && cr_dr === DebitOrCredit.DEBIT) {
  //       return Number(debitAmount);
  //     }
  //   }
  //   return ldgr_cb + Number(creditAmount) + Number(debitAmount);
  // };

  return (
    <>
      {operation === Operation.CREATE && <Home DashBoardRequired={false} />}
      <div className="row g-0">
        <div className="col">
          <Title>
            {type === VoucherPageType.PAYMENT_VOUCHER ? (
              <img
                src={PaymentVoucher}
                alt="/"
                className="payment-voucher__image"
              />
            ) : type === VoucherPageType.RECEIPT_VOUCHER ? (
              <img
                src={ReceiptVoucher}
                alt="/"
                className="payment-voucher__image"
              />
            ) : (
              <img
                src={BanktoBank}
                alt="/"
                className="payment-voucher__image"
              />
            )}

            {AccountsTitles.Voucher.Titles.map(
              (title: VoucherTitleProps, index: React.Key) => {
                return (
                  <React.Fragment key={index}>
                    {type === VoucherPageType.PAYMENT_VOUCHER
                      ? title.Payment_Voucher
                      : type === VoucherPageType.RECEIPT_VOUCHER
                      ? title.Receipt_Voucher
                      : type === VoucherPageType.CONTRA_VOUCHER
                      ? title.Contra_Voucher
                      : title.Journal_Voucher}
                  </React.Fragment>
                );
              }
            )}
          </Title>
        </div>
        <div className="configuration-settings">
          {USE_CONFIG_KEY && (
            <img
              src={Settings}
              alt="/"
              id="settings-icon"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          )}
        </div>
      </div>
      <div
        className={
          pageType === PageFor.GENERAL
            ? "payment-voucher"
            : "payment-voucher__modal"
        }>
        <div className="row g-0 payment-voucher__block">
          <div className="col-4 payment-voucher__block--payment-number">
            <Label>
              <span> {toStandardCase(chooseVoucherType())}</span>
              &nbsp; No.
            </Label>
            <Input
              disabled
              type="text"
              className="voucher-number"
              value={voucherNumber}
            />
          </div>
          <div className="col"></div>
          <div className="col-4 payment-voucher__block--date">
            <Label>Date : </Label>
            <Input
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              min={
                state.ActiveFinYr
                  ? toInputStandardDate(state.ActiveFinYr.fin_st_date!)
                  : EMPTY_STRING
              }
              max={TODAY_DATE}
              disabled={editDate ? false : true}
            />

            <Label>{NameOfTheDay(date)}</Label>
          </div>
        </div>

        <div
          className={
            type !== VoucherPageType.CONTRA_VOUCHER
              ? "payment-voucher__tableblock"
              : "contra-voucher__tableblock"
          }>
          <TableContainer className="payment-voucher__table" ref={tableBodyRef}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Accounts_Table.PaymentVoucher.Table_Headers.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return (
                        <TableCell key={index} className={th.className}>
                          {th.labelName}
                        </TableCell>
                      );
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((item) => {
                  return (
                    <TableRow key={item.acctLdgrId}>
                      <TableCell>{toStandardCase(item.byTo)}</TableCell>
                      <TableCell>
                        {
                          <div className="payment-voucher__voucher-flex">
                            <span className="payment-voucher__voucher-v-name">
                              {item.name}
                            </span>
                            {operation === Operation.CREATE ? (
                              <div>
                                <span className="payment-voucher__voucher-v-cb">
                                  {`₹ ${format(
                                    Math.abs(item.ldgr_cb)
                                  )} ${handleDbOrCr(
                                    item.gr_ldgr_rp as GroupLedgerRPTypes,
                                    item.ldgr_cb
                                  )} `}
                                </span>
                                <span className="payment-voucher__voucher-v-groupledger">{`(${item.grp_ldgr_desc})`}</span>
                              </div>
                            ) : null}
                          </div>
                        }
                      </TableCell>
                      <TableCell id="td-right">
                        {item.debitAmt
                          ? format(item.debitAmt ? item.debitAmt : 0)
                          : "-"}
                      </TableCell>
                      <TableCell id="td-right">
                        {item.creditAmt
                          ? format(item.creditAmt ? item.creditAmt : 0)
                          : "-"}
                      </TableCell>
                      <TableCell
                        id="td-center"
                        className="payment-voucher__table--actions">
                        <img
                          src={EditProfile}
                          alt="/"
                          onClick={() => {
                            setsearchData(item.name);
                            setEditIndex(item.index);
                            filterEditItem(item.acctLdgrId);
                            setEdit(true);
                            setEditAcctLdgr(false);
                            scrollEnd();
                          }}
                        />
                        <img
                          src={DeleteImage}
                          alt="/"
                          onClick={() => handleDelete(item.acctLdgrId)}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
                {totalCreditAmount !== totalDebitAmount ||
                totalCreditAmount === 0 ||
                totalDebitAmount === 0 ||
                edit ? (
                  <TableRow className="payment-voucher__table--row">
                    <TableCell className="payment-voucher__table--select">
                      <FormAutocomplete
                        className={formClasses.inputRoot}
                        ref={bytoRef}
                        options={transactionType}
                        value={newbyto}
                        openOnFocus
                        isOptionEqualToValue={(option, value) =>
                          (option as optionsType).value ===
                          (value as optionsType).value
                        }
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setNewByTo(newValue as optionsType);
                            setAcctLdgrId(null);
                            if (
                              (newValue as optionsType).value ===
                              transactionTypeEnum.BY
                            ) {
                              setCreditAmount(EMPTY_STRING);
                            }
                            if (
                              (newValue as optionsType).value ===
                              transactionTypeEnum.TO
                            ) {
                              setDebitAmount(EMPTY_STRING);
                            }

                            if (!edit) {
                              if (items.length > 0) {
                                const isDebitGreaterThanCredit =
                                  totalDebitAmount > totalCreditAmount;

                                const debitMinusCredit =
                                  totalDebitAmount - totalCreditAmount;
                                const creditMinusDebit =
                                  totalCreditAmount - totalDebitAmount;

                                if (isDebitGreaterThanCredit) {
                                  if (
                                    (newValue as optionsType).value ===
                                    transactionTypeEnum.BY
                                  ) {
                                    setCreditAmount("");
                                    setDebitAmount("");
                                  } else {
                                    setCreditAmount(
                                      (debitMinusCredit > 0
                                        ? debitMinusCredit
                                        : 0
                                      ).toString()
                                    );
                                    setDebitAmount("");
                                  }
                                } else {
                                  if (
                                    (newValue as optionsType).value ===
                                    transactionTypeEnum.BY
                                  ) {
                                    setDebitAmount(
                                      (creditMinusDebit > 0
                                        ? creditMinusDebit
                                        : 0
                                      ).toString()
                                    );
                                    setCreditAmount("");
                                  } else {
                                    setCreditAmount("");
                                    setDebitAmount("");
                                  }
                                }
                              }
                            }
                          }
                        }}
                        disableClearable
                        onKeyDown={(e: React.KeyboardEvent) => {
                          if (e.key === Keys.ENTER) {
                            if (newbyto) {
                              RefsByTagName(feeDescRef)?.select();
                              RefsByTagName(feeDescRef)?.focus();
                            }
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className={formClasses.formControlRoot}
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell
                      className="payment-voucher__table--desc"
                      id="center"
                      onClick={() => {
                        if (edit) {
                          setEditAcctLdgr(true);
                        } else {
                          if (acctLdgrId) setAcctLdgr(false);
                        }
                      }}>
                      {edit && !editAcctLedger ? (
                        <div className="payment-voucher__voucher-flex">
                          {edit ? (
                            searchData
                          ) : acctLdgrId ? (
                            <>
                              <span className="payment-voucher__voucher-v-name">
                                {acctLdgrId.label}
                              </span>
                              {operation === Operation.CREATE ? (
                                <div>
                                  <span className="payment-voucher__voucher-v-cb">
                                    {`₹ ${format(
                                      Math.abs(acctLdgrId.ldgr_cb)
                                    )} ${handleDbOrCr(
                                      acctLdgrId.gr_ldgr_rp as GroupLedgerRPTypes,
                                      acctLdgrId.ldgr_cb
                                    )} `}
                                  </span>
                                  <span className="payment-voucher__voucher-v-groupledger">{`(${acctLdgrId.grp_ldgr_desc})`}</span>
                                </div>
                              ) : null}
                            </>
                          ) : (
                            EMPTY_STRING
                          )}
                        </div>
                      ) : (
                        <FormAutocomplete
                          className={formClasses.inputRoot}
                          ref={feeDescRef}
                          openOnFocus
                          forcePopupIcon
                          onKeyDown={(e: React.KeyboardEvent) => {
                            if (e.key === Keys.ENTER && acctLdgrId) {
                              setAcctLdgr(true);
                              if (
                                newbyto &&
                                newbyto.value === transactionTypeEnum.BY
                              ) {
                                debitRef.current?.focus();
                                debitRef.current?.select();
                              } else {
                                creditRef.current?.focus();
                                creditRef.current?.select();
                              }
                            }
                          }}
                          options={
                            newbyto
                              ? AutoCompleteOptions(newbyto.value).filter(
                                  ({ value }) =>
                                    !edit ? !accLdgrIdSet.has(value) : value
                                )
                              : []
                          }
                          value={acctLdgrId}
                          isOptionEqualToValue={(option, value) =>
                            (option as LedgerOptionsType).value ===
                            (value as LedgerOptionsType).value
                          }
                          onChange={(e, newValue) => {
                            if (newValue) {
                              setAcctLdgrId(newValue as LedgerOptionsType);
                              setAcctLdgr(true);
                              if (
                                newbyto &&
                                newbyto.value === transactionTypeEnum.BY
                              ) {
                                debitRef.current?.focus();
                                debitRef.current?.select();
                              } else {
                                creditRef.current?.focus();
                                creditRef.current?.select();
                              }
                            } else setAcctLdgrId(null);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              value={searchData}
                              onChange={(e) => setsearchData(e.target.value)}
                              className={formClasses.formControlRoot}
                            />
                          )}
                        />
                      )}
                    </TableCell>
                    <TableCell className="payment-voucher__table--amount">
                      <input
                        value={debitAmount}
                        ref={debitRef}
                        disabled={
                          newbyto
                            ? newbyto.value === transactionTypeEnum.TO
                            : false
                        }
                        onKeyDown={(e) => {
                          if (e.key === Keys.ENTER) {
                            if (
                              Number(debitAmount) !== 0 &&
                              debitAmount !== EMPTY_STRING
                            ) {
                              buttonRef.current?.focus();
                            } else {
                              debitRef.current?.focus();
                            }
                          }
                        }}
                        id="td-right"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (
                            (floatingPointRegx.test(e.target.value) &&
                              Number(e.target.value) !== 0) ||
                            e.target.value === EMPTY_STRING
                          )
                            setDebitAmount(e.target.value);
                        }}
                      />
                    </TableCell>

                    <TableCell className="payment-voucher__table--amount">
                      <input
                        id="td-right"
                        ref={creditRef}
                        value={creditAmount}
                        onKeyDown={(e) => {
                          if (e.key === Keys.ENTER) {
                            if (
                              Number(creditAmount) !== 0 &&
                              creditAmount !== EMPTY_STRING
                            ) {
                              buttonRef.current?.focus();
                            } else {
                              creditRef.current?.focus();
                            }
                          }
                        }}
                        disabled={
                          newbyto
                            ? type === VoucherPageType.PAYMENT_VOUCHER &&
                              newbyto.value === transactionTypeEnum.BY
                            : false
                        }
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (
                            (floatingPointRegx.test(e.target.value) &&
                              Number(e.target.value) !== 0) ||
                            e.target.value === EMPTY_STRING
                          )
                            setCreditAmount(e.target.value);
                        }}
                      />
                    </TableCell>
                    <TableCell
                      id="td-center"
                      className="payment-voucher__table--actions">
                      <button
                        onClick={() =>
                          edit ? HandleEditItem(editIndex) : handleItems()
                        }
                        ref={buttonRef}>
                        {edit ? (
                          <>
                            <img
                              src={Save}
                              alt=""
                              className="payment-voucher__table--actions--save"
                            />
                          </>
                        ) : (
                          <>
                            <img src={Enter} alt="/" />
                          </>
                        )}
                      </button>
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={2} className="total"></TableCell>
                  <TableCell className="totalcount" align="right">
                    {format(totalDebitAmount)}
                  </TableCell>
                  <TableCell className="totalcount" align="right">
                    {format(totalCreditAmount)}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
        <Formik
          initialValues={formData}
          validationSchema={PaymentVoucherValidation}
          onSubmit={handleStudentReceipts}
          enableReinitialize>
          {(meta) => {
            return (
              <Form
                className={
                  type === VoucherPageType.PAYMENT_VOUCHER ||
                  type === VoucherPageType.RECEIPT_VOUCHER
                    ? "payment-voucher__lableblock"
                    : "contra-voucher__lableblock"
                }>
                <div className="row g-0 payment-voucher__lableblock--details h-100">
                  <div
                    className={
                      type === VoucherPageType.PAYMENT_VOUCHER ||
                      type === VoucherPageType.RECEIPT_VOUCHER
                        ? "col payment-voucher__lableblock--frames h-100"
                        : "col"
                    }>
                    {(type === VoucherPageType.PAYMENT_VOUCHER ||
                      type === VoucherPageType.RECEIPT_VOUCHER) && (
                      <>
                        <div className="payment-voucher__lableblock--label-grid">
                          <Label>
                            {type === VoucherPageType.PAYMENT_VOUCHER
                              ? "Pay to"
                              : "Received from"}
                          </Label>
                          <FormAutocomplete
                            className={formClasses.inputRoot}
                            options={InstreferenceData?.optionsType || []}
                            value={
                              InstreferenceData?.optionsType.find(
                                (f) => f.value === formData.payTo
                              ) || null
                            }
                            openOnFocus
                            ref={payToRef}
                            onChange={(e, newValue) => {
                              if (newValue) {
                                setFormData((formData) => {
                                  return {
                                    ...formData,
                                    payTo: (newValue as optionsType).label,
                                  };
                                });
                              } else {
                                setFormData((formData) => {
                                  return { ...formData, payTo: EMPTY_STRING };
                                });
                              }
                            }}
                            onKeyDown={(e) => {
                              if (e.key === Keys.ENTER) {
                                e.preventDefault();
                                if (formData.payTo)
                                  payToNameRef.current?.focus();
                              }
                              if (e.key === Keys.BACKSPACE) {
                                setFormData((formData) => {
                                  return { ...formData, payTo: EMPTY_STRING };
                                });
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                required
                                className={formClasses.formControlRoot}
                              />
                            )}
                          />
                          <InputHoc
                            name="partyName"
                            inputRef={payToNameRef!}
                            values={formData.partyName}
                            required
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              handleValueChange(e);
                              meta.handleChange(e);
                            }}
                          />
                        </div>
                        <div className="row g-0 payment-voucher__lableblock--label-grid--cheque">
                          <div className="col">
                            <InputHoc
                              name="BillNo"
                              LabelName="Bill No."
                              values={formData.BillNo}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                handleValueChange(e);
                                meta.handleChange(e);
                              }}
                            />
                          </div>
                          <div className="col">
                            <InputHoc
                              name="BillDate"
                              required
                              LabelName="Bill Date"
                              type="date"
                              values={formData.BillDate}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                handleValueChange(e);
                                meta.handleChange(e);
                              }}
                            />
                          </div>
                        </div>
                        {itemsSet.has(BankOrCash.CASH) &&
                        !itemsSet.has(BankOrCash.BANK) ? null : (
                          <div className="row g-0 payment-voucher__lableblock--label-grid--cheque">
                            <div className="col">
                              <InputHoc
                                name="DDNo"
                                LabelName="Chq / DD No."
                                values={formData.DDNo}
                                disabled={
                                  itemsSet.has("C") && !itemsSet.has("B")
                                }
                                required
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  handleValueChange(e);
                                  meta.handleChange(e);
                                }}
                              />
                            </div>
                            <div className="col">
                              <InputHoc
                                name="DDDate"
                                type="date"
                                values={formData.DDDate}
                                required
                                LabelName="Chq / DD Date"
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  handleValueChange(e);
                                  meta.handleChange(e);
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>

                  <div className="col payment-voucher__lableblock--frames h-100">
                    <div className="payment-voucher__label-grid2">
                      <Label>Narration :</Label>

                      <TextArea
                        rows={3}
                        textAreaRef={narrationRef!}
                        value={formData.Remarks}
                        name="Remarks"
                        draggable={false}
                        required
                        resize="none"
                        onChange={(
                          e: React.ChangeEvent<HTMLTextAreaElement>
                        ) => {
                          meta.handleChange(e);
                          handleValueChange(e);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === Keys.ENTER) {
                            e.preventDefault();
                            saveRef.current?.focus();
                          }
                          if (e.key === Keys.ENTER && e.shiftKey) {
                            setFormData({
                              ...formData,
                              Remarks: formData.Remarks + "\n",
                            });
                          }
                        }}
                      />
                    </div>
                    {component}
                  </div>
                </div>

                <div className="payment-voucher__buttons-left row g-0">
                  <div className="col">
                    <Button
                      mode="save"
                      type="submit"
                      buttonref={saveRef!}
                      disabled={
                        type === VoucherPageType.PAYMENT_VOUCHER &&
                        enableApproval
                          ? totalCreditAmount !== totalDebitAmount ||
                            totalCreditAmount === 0 ||
                            totalCreditAmount > maxAmountForApproval ||
                            totalDebitAmount > maxAmountForApproval
                          : totalCreditAmount !== totalDebitAmount ||
                            totalCreditAmount === 0
                      }
                    />
                    {enableApproval &&
                      type === VoucherPageType.PAYMENT_VOUCHER && (
                        <Button
                          onClick={() => setRequestToApprove(!requestToApprove)}
                          type="button"
                          buttonref={saveRef}
                          disabled={
                            totalCreditAmount <= maxAmountForApproval ||
                            totalDebitAmount <= maxAmountForApproval
                          }>
                          <img src={Send} alt="/" /> Request to Approve
                        </Button>
                      )}
                    <Button mode="clear" type="button" onClick={handleClear} />

                    <Button
                      mode="account-ledger"
                      type="button"
                      onClick={() =>
                        setAccountingLedgerModal(!accountingLedgerMOdal)
                      }
                    />
                    {pageType === PageFor.GENERAL ? (
                      <Button
                        mode="back"
                        onClick={() => navigate(-1)}
                        type="button"
                      />
                    ) : (
                      <Button
                        mode="cancel"
                        onClick={() => setModalFlag(false)}
                        type="button"
                      />
                    )}
                  </div>
                  {pageType === PageFor.GENERAL && (
                    <div className="payment-voucher__icons col-4">
                      <CustomTooltip
                        title="Approved Request"
                        placement="top"
                        slotProps={{
                          tooltip: {
                            sx: {
                              bgcolor: TOOLTIP_COLORS.GREY,
                              "& .MuiTooltip-arrow": {
                                color: TOOLTIP_COLORS.GREY,
                              },
                            },
                          },
                        }}
                        arrow>
                        <img
                          src={ApprovedRequestImage}
                          alt="/"
                          onClick={() => setApprovedRequest(!approvedRequest)}
                        />
                      </CustomTooltip>
                      <CustomTooltip
                        title="Rejected Request"
                        placement="top"
                        slotProps={{
                          tooltip: {
                            sx: {
                              bgcolor: TOOLTIP_COLORS.GREY,
                              "& .MuiTooltip-arrow": {
                                color: TOOLTIP_COLORS.GREY,
                              },
                            },
                          },
                        }}
                        arrow>
                        <img
                          src={RejectedRequestImage}
                          alt="/"
                          onClick={() => setRejectedRequest(!rejectedRequest)}
                        />
                      </CustomTooltip>
                      <CustomTooltip
                        title="Pending Request"
                        placement="top"
                        slotProps={{
                          tooltip: {
                            sx: {
                              bgcolor: TOOLTIP_COLORS.GREY,
                              "& .MuiTooltip-arrow": {
                                color: TOOLTIP_COLORS.GREY,
                              },
                            },
                          },
                        }}
                        arrow>
                        <img
                          src={PendingRequestImage}
                          alt="/"
                          onClick={() => setPendingRequest(!pendingRequest)}
                        />
                      </CustomTooltip>
                    </div>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>

      {/* configurationModal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={configurationModal}
        style={ConfigurationsModalStyles}
        ariaHideApp={false}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ConfigurationSettings
              pageNumber={PageNumbers.VOUCHER_PAGE}
              setModalFlag={setConfigurationModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={accountingLedgerMOdal}
        style={AccountingLedgerModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <AccountingLedger
              type={PageFor.MODAL}
              setModalFlag={setAccountingLedgerModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setAccountingLedgerModal(!accountingLedgerMOdal)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={printModal}
        style={PrintModalStyles}
        ariaHideApp={false}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <VoucherReceiptPrint setModalFlag={setPrintModal} />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setPrintModal(!printModal)}
              className="modal-close-icon"
            />
            <img
              src={Settings}
              alt="/"
              id="settings-icon"
              onClick={() => setPrintConfigModal(!printConfig)}
            />
          </div>
        </div>
      </Modal>

      {/* requestToApprove modal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={requestToApprove}
        style={EditModalCustomStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Title>Request for Approval</Title>
            <div className="label-grid">
              <Label>Approver</Label>
              <FormAutocomplete
                className={formClasses.inputRoot}
                options={empDetails.responseType}
                ref={payToRef}
                value={reportingmanagerId}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(
                    option as responseType,
                    reportingmanagerId
                  )
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    setReportingManagerId(newValue as responseType);
                  } else {
                    setReportingManagerId(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    required
                    className={formClasses.formControlRoot}
                  />
                )}
              />
              <Label>Remarks</Label>
              <TextArea
                rows={3}
                value={approvalRemarks}
                onChange={(e) => setApprovalRemarks(e.target.value)}
              />
            </div>
            <Button
              mode="save"
              onClick={handleApprovalRequest}
              disabled={!reportingmanagerId?.value || !approvalRemarks}
            />
            <Button mode="clear" />
            <Button
              mode="cancel"
              onClick={() => setRequestToApprove(!requestToApprove)}
            />
          </div>
          <div className="modal-flex__image h-100">
            <img
              src={Close}
              alt=""
              onClick={() => setRequestToApprove(!requestToApprove)}
            />
          </div>
        </div>
      </Modal>

      {renderModal(
        MkCkQueryType.MK_CK_APPROVED,
        approvedRequest,
        handleToggleApproved
      )}
      {renderModal(
        MkCkQueryType.MK_CK_REJECTED,
        rejectedRequest,
        handleToggleRejected
      )}
      {renderModal(
        MkCkQueryType.MK_CK_PENDING,
        pendingRequest,
        handleTogglePending
      )}

      {/* print configuration modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={printConfig}
        style={PrintConfigModalStyles}
        ariaHideApp={false}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <PerModuleConfiguration
              config_query_type={SwConfigQueryType.INST_BY_MODULE}
              str_value={ModuleName.VOUCHER_PRINT}
              int_value={0}
              setModalFlag={setPrintConfigModal}
              pageType={PageFor.MODAL}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setPrintConfigModal(!printConfig)}
            />
          </div>
        </div>
      </Modal>
      <LoadingModal
        flag={
          GenerateStudentReceiptsLoading ||
          EditStudentReceiptsLoading ||
          generateApprovalLoading
        }
      />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default NewVoucher;
