import React, { useContext, useEffect, useState } from "react";
import {
  getModifiedScrollHeight,
  handleFormEvent,
} from "../../../utils/UtilFunctions";
import { useNavigate } from "react-router";

import { Title } from "../../../stories/Title/Title";
import Input from "../../../stories/Input/Input";
import { Operation, PageFor, TableHeaders } from "../../../utils/Enum.types";
import { FETCH_MORE_DATA, ROWS_PER_PAGE } from "../../../utils/constants";
import { Button } from "../../../stories/Button/Button";
import Home from "../Home/Index";
import useEmployee, {
  empQueryTypes,
  PayRollEmpEdges,
} from "../../HR/hooks/useEmployee";
import AddLoginUser from "./AddLoginUser";
import Edit from "../../../images/EditProfile.svg";
import useHRtable from "../../HR/json/useHRtable";
import { TableHeaderProps } from "../../../utils/types";
import {
  GridAlignment,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  HEADER_TEXT_ALIGN,
  MARKS_TEXT_ALIGN,
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../styles/DataGridTableStyles";
import StudentTotalCount from "../../Master/Student/Components/StudentTotalCount";
import { AppContext } from "../../../context/context";
import { payloadTypes } from "../../../context/reducer";

export interface ModalType {
  operation: Operation;
  id: number;
  flag: boolean;
}
interface Props {
  pageType: PageFor;
  setEmpModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const LoginUser = ({ pageType, setEmpModal }: Props) => {
  const navigate = useNavigate();
  const { HR_Table } = useHRtable();
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [employees, setEmployees] = useState<PayRollEmpEdges[]>([]);
  const [rows, setRows] = useState<GridValidRowModel[]>([]);
  const { dispatch } = useContext(AppContext);

  const [openModal, setOpenModal] = useState<ModalType>({
    operation: Operation.NONE,
    flag: false,
    id: 0,
  });
  const [searchData, setSearchData] = useState("");
  const {
    empDetails: { data, loading, fetchMore },
  } = useEmployee(ROWS_PER_PAGE, empQueryTypes.JUST_LOGIN_ACCTS, searchData);

  const dynamicHeaders: TableHeaderProps[] = HR_Table.LoginUser?.map(
    (header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
      hideable: header.hideable,
    })
  );
  const columns: GridColDef[] = [
    ...dynamicHeaders,

    {
      field: "actions",
      cellClassName: "td-actions-print ",
      headerClassName: "td-actions-print-header",
      headerAlign: HEADER_TEXT_ALIGN,
      align: MARKS_TEXT_ALIGN,
      headerName: TableHeaders.ACTION,
      renderCell: (params) => {
        return (
          <>
            {pageType === PageFor.GENERAL && (
              <img
                src={Edit}
                alt="/"
                onClick={() =>
                  setOpenModal({
                    operation: Operation.UPDATE,
                    flag: true,
                    id: params.row.user_id,
                  })
                }
              />
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetPayRollEmp.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = employees.find(
            (employee) => employee.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
              },
            };
          }
          return newStudent;
        });
        setEmployees(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            name:
              node.emp_first_name + node.emp_middle_name + node.emp_last_name,
            mobile: node.emp_mobile,
            email: node.emp_email,
            user_type: node.emp_type,
            user_id: node.id,
          }))
        );
      } else {
        setEmployees(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            name:
              node.emp_first_name + node.emp_middle_name + node.emp_last_name,
            mobile: node.emp_mobile,
            email: node.emp_email,
            user_type: node.emp_type,
            user_id: node.id,
          }))
        );
      }
      setEndCursor(data.GetPayRollEmp.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);

  useEffect(() => {
    const scrollTable = document.getElementsByClassName(
      "MuiDataGrid-virtualScroller"
    )[0] as Element;
    const handleScroll = (e: Event) => {
      const target = e.target as HTMLDivElement;
      const scrollTop = target.scrollTop;
      const scrollHeight = target.scrollHeight;
      const clientHeight = target.clientHeight;
      if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
        if (hasNextPage && !loading) {
          fetchMore({
            variables: {
              first: FETCH_MORE_DATA,
              after: endCursor,
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prevResult;

              const newEdges = fetchMoreResult.GetPayRollEmp.edges;
              const pageInfo = fetchMoreResult.GetPayRollEmp.pageInfo;
              setEndCursor(pageInfo.endCursor);
              setHasNextPage(pageInfo.hasNextPage);

              const duplicateCheck = prevResult.GetPayRollEmp.edges.filter(
                ({ node: { id } }) =>
                  newEdges.findIndex(
                    ({ node: { id: newId } }) => newId === id
                  ) !== -1
              );

              if (duplicateCheck.length > 0) return prevResult;

              return {
                GetPayRollEmp: {
                  edges: [...employees, ...newEdges],
                  pageInfo,
                  totalCount: data ? data.GetPayRollEmp.totalCount! : 0,
                },
              };
            },
          });
        }
      }
    };
    if (scrollTable && rows.length)
      scrollTable.addEventListener("scroll", handleScroll);

    return () => {
      if (scrollTable) scrollTable.removeEventListener("scroll", handleScroll);
    };
  }, [rows]);

  return (
    <>
      {pageType === PageFor.GENERAL && <Home DashBoardRequired={false} />}
      <Title>Login Users</Title>
      <div
        className={`emp-login-user${
          pageType === PageFor.GENERAL ? "" : "__modal"
        }`}
      >
        <div className="row g-0 ">
          <div className="col-2 ">
            <Input
              id="search"
              type="text"
              placeholder="Search ..."
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearchData(e.target.value)
              }
              onKeyDown={handleFormEvent}
            />
          </div>
          <div className="col"></div>

          <div className="col-1">
            {pageType === PageFor.GENERAL && (
              <Button
                mode="addnew"
                className="emp-login-user__button"
                autoFocus
                onClick={() =>
                  setOpenModal({
                    operation: Operation.CREATE,
                    flag: true,
                    id: 0,
                  })
                }
              />
            )}
          </div>
        </div>

        <div className={`emp-login-user__tableblock `}>
          {!loading && data && data.GetPayRollEmp.edges.length === 0 ? (
            <b className="nodata">Sorry, no results.</b>
          ) : (
            <StyledDatagrid
              columns={columns}
              rows={rows}
              rowHeight={TABLE_ROW_HEIGHT}
              disableRowSelectionOnClick
              onCellClick={(params) => {
                if (params.field === "name") {
                  if (pageType === PageFor.MODAL) {
                    dispatch({
                      type: payloadTypes.SET_EMPLOYEE_ID,
                      payload: { employeeId: params.row.user_id },
                    });

                    setEmpModal(false);
                  }
                }
              }}
              hideFooter
            />
          )}
        </div>
        <div className="row g-0">
          <div className="col">
            {pageType === PageFor.GENERAL && (
              <Button mode="back" onClick={() => navigate(-1)} />
            )}
            {pageType === PageFor.MODAL && (
              <Button mode="cancel" onClick={() => setEmpModal(false)} />
            )}
          </div>
          <div className="col-3 student-total-count flex-end">
            Total Users :&nbsp; <b>{data?.GetPayRollEmp.totalCount!}</b>
            {/* <StudentTotalCount totalCount={data?.GetPayRollEmp.totalCount!} /> */}
          </div>
        </div>
      </div>

      <AddLoginUser openModal={openModal} setOpenModal={setOpenModal} />
    </>
  );
};

export default LoginUser;
