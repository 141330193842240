export enum AcctStudentQueryType {
  // general
  ACCT_STD_DEMAND = "ACCT_STD_DEMAND",
  DEPOSIT_BAL_GT_REQUESTED_AMT = "DEPOSIT_BAL_GT_REQUESTED_AMT",
  // Freshers
  FRESHER_DEMAND_RAISED = "FRESHER_DEMAND_RAISED",

  //Existing
  EXISTING_DEMAND_RAISED = "EXISTING_DEMAND_RAISED",

  //students by completely paid fee
  COMPLETELY_PAID = "COMPLETELY_PAID",

  //students by partially paid fee
  PARTIALLY_PAID = "PARTIALLY_PAID",

  //bwt amount
  IN_BWT_AMT_PAID = "IN_BWT_AMT_PAID",

  //advance query type
  DEPOSIT_BAL = "DEPOSIT_BAL",

  //student receipt

  DEMAND_RAISED_NOT_PAID = "DEMAND_RAISED_NOT_PAID",

  UNMAPPED_ACCT_LDGR = "UNMAPPED_ACCT_LDGR",
  // NOT_SELECTED = "NOT_SELECTED",
  MAPPED_ACCT_LDGR = "MAPPED_ACCT_LDGR",

  //by acct ledgers
  ACCT_LDGR_WISE_BY_INST_ID = "ACCT_LDGR_WISE_BY_INST_ID",
  ACCT_LDGR_WISE_BY_DEPT_ID = "ACCT_LDGR_WISE_BY_DEPT_ID",
  ACCT_LDGR_WISE_BY_BRANCH_ID = "ACCT_LDGR_WISE_BY_BRANCH_ID",
  ACCT_LDGR_WISE_BY_CLASS_ID = "ACCT_LDGR_WISE_BY_CLASS_ID",
  ACCT_LDGR_WISE_BY_SEMESTER_ID = "ACCT_LDGR_WISE_BY_SEMESTER_ID",
  ACCT_LDGR_WISE_BY_SECTION_ID = "ACCT_LDGR_WISE_BY_SECTION_ID",
  ACCT_LDGR_WISE_BY_CATEGORY_ID = "ACCT_LDGR_WISE_BY_CATEGORY_ID",
  ACCT_LDGR_WISE_BY_DEPT_AND_CATEGORY_ID = "ACCT_LDGR_WISE_BY_DEPT_AND_CATEGORY_ID",
  ACCT_LDGR_WISE_BY_BRANCH_AND_CATEGORY_ID = "ACCT_LDGR_WISE_BY_BRANCH_AND_CATEGORY_ID",
  ACCT_LDGR_WISE_BY_CLASS_AND_CATEGORY_ID = "ACCT_LDGR_WISE_BY_CLASS_AND_CATEGORY_ID",
  ACCT_LDGR_WISE_BY_SEMESTER_AND_CATEGORY_ID = "ACCT_LDGR_WISE_BY_SEMESTER_AND_CATEGORY_ID",
  ACCT_LDGR_WISE_BY_SECTION_AND_CATEGORY_ID = "ACCT_LDGR_WISE_BY_SECTION_AND_CATEGORY_ID",

  // refunds
  REFUND = "REFUND",
  // COncessions
  CONCESSION = "CONCESSION",

  // demand raised
  DEMAND_RAISED = "DEMAND_RAISED",

  // PARTIALLY_OR_COMPLETELY_paid
  PARTIALLY_OR_COMPLETELY_PAID = "PARTIALLY_OR_COMPLETELY_PAID",

  //  new due date
  NEW_DUE_DATE = "NEW_DUE_DATE",
  DEMAND_RAISED_ONLY_BAL = "DEMAND_RAISED_ONLY_BAL",
  ACCT_CURR_STD_DEMAND_BY_INST_ID = "ACCT_CURR_STD_DEMAND_BY_INST_ID",
  ACCT_CURR_STD_DEMAND_BY_DEPT_ID = "ACCT_CURR_STD_DEMAND_BY_DEPT_ID",
  ACCT_CURR_STD_DEMAND_BY_BRANCH_ID = "ACCT_CURR_STD_DEMAND_BY_BRANCH_ID",
  ACCT_CURR_STD_DEMAND_BY_CLASS_ID = "ACCT_CURR_STD_DEMAND_BY_CLASS_ID",
  ACCT_CURR_STD_DEMAND_BY_SEMESTER_ID = "ACCT_CURR_STD_DEMAND_BY_SEMESTER_ID",
  ACCT_CURR_STD_DEMAND_BY_SECTION_ID = "ACCT_CURR_STD_DEMAND_BY_SECTION_ID",
  ACCT_CURR_STD_DEMAND_BY_CATEGORY_ID = "ACCT_CURR_STD_DEMAND_BY_CATEGORY_ID",
  ACCT_CURR_STD_DEMAND_BY_DEPT_AND_CATEGORY_ID = "ACCT_CURR_STD_DEMAND_BY_DEPT_AND_CATEGORY_ID",
  ACCT_CURR_STD_DEMAND_BY_BRANCH_AND_CATEGORY_ID = "ACCT_CURR_STD_DEMAND_BY_BRANCH_AND_CATEGORY_ID",
  ACCT_CURR_STD_DEMAND_BY_CLASS_AND_CATEGORY_ID = "ACCT_CURR_STD_DEMAND_BY_CLASS_AND_CATEGORY_ID",
  ACCT_CURR_STD_DEMAND_BY_SEMESTER_AND_CATEGORY_ID = "ACCT_CURR_STD_DEMAND_BY_SEMESTER_AND_CATEGORY_ID",
  ACCT_CURR_STD_DEMAND_BY_SECTION_AND_CATEGORY_ID = "ACCT_CURR_STD_DEMAND_BY_SECTION_AND_CATEGORY_ID",

  //Demand not raised
  DEMAND_NOT_RAISED = "DEMAND_NOT_RAISED",

  ANONYMOUS_STUDENT = "ANONYMOUS_STUDENT",
  SOCIAL_WELFARE_STUDENT = "SOCIAL_WELFARE_STUDENT",
  AGENT_STUDENT = "AGENT_STUDENT",
  ALL_ANONYMOUS_STUDENTS = "ALL_ANONYMOUS_STUDENTS",

  PASSED_STDS_DEMAND_BY_INST_ID = "PASSED_STDS_DEMAND_BY_INST_ID",
  PASSED_STDS_DEMAND_BY_DEPT_ID = "PASSED_STDS_DEMAND_BY_DEPT_ID",
  PASSED_STDS_DEMAND_BY_BRANCH_ID = "PASSED_STDS_DEMAND_BY_BRANCH_ID",

  BY_AGENT_ID = "BY_AGENT_ID",
  ACCT_STD_DEMAND_BY_ENTRY_ID = "ACCT_STD_DEMAND_BY_ENTRY_ID",
  STDS_NOT_ASSOICATED_TO_AGENT = "STDS_NOT_ASSOICATED_TO_AGENT",
  BY_AGENT_STUDENT_ASSOCI_ID = "BY_AGENT_STUDENT_ASSOCI_ID",
}

export enum DemandDetailsQueryType {
  ALL_DEMAND_DETAILS = "ALL_DEMAND_DETAILS",
  DEMAND_DETAILS_GT_0 = "DEMAND_DETAILS_GT_0",
  DEMAND_DETAILS_TO_REFUND = "DEMAND_DETAILS_TO_REFUND",
  DEMAND_DETAILS_TO_EXCHANGE = "DEMAND_DETAILS_TO_EXCHANGE",
}

export enum LedgerType {
  GENERAL = "GENERAL",
  DEPOSIT = "DEPOSIT",
}

export enum DepositLedgerQueryType {
  ALL_DEPOSIT_LDGRS_BY_STD_ID = "ALL_DEPOSIT_LDGRS_BY_STD_ID",
  DEPOSIT_GT_0_BY_STD_ID = "DEPOSIT_GT_0_BY_STD_ID",
  DEPOSIT_OB_GT_0_BY_STD_ID = "DEPOSIT_OB_GT_0_BY_STD_ID",
  ALL_DEPOSIT_LDGRS_FOR_ALL_STDS = "ALL_DEPOSIT_LDGRS_FOR_ALL_STDS",
  // ALL_DEPOSIT_LDGRS = "ALL_DEPOSIT_LDGRS",
  // DEPOSIT_GT_0 = "DEPOSIT_GT_0",
  DEPOSIT_BAL_GT_REQUESTED_AMT = "DEPOSIT_BAL_GT_REQUESTED_AMT",
}

export enum AcctGroupLdgrQueryType {
  GROUP_LDGR_BY_INST_ID = "GROUP_LDGR_BY_INST_ID",
  GROUP_STD_LDGR_BY_INST_ID = "GROUP_STD_LDGR_BY_INST_ID",
  GROUP_LDGR_FOR_LIABILITIES = "GROUP_LDGR_FOR_LIABILITIES",
  GROUP_LDGR_FOR_ASSETS = "GROUP_LDGR_FOR_ASSETS",
}
export enum AcctLedgerQueryType {
  ACCT_LDGRS_BY_INST_ID = "ACCT_LDGRS_BY_INST_ID",
  DEPOSIT_FEE_LDGRS = "DEPOSIT_FEE_LDGRS",
  CASH_AND_BANK = "CASH_AND_BANK",
  TRANSPORT_FEE_LDGRS = "TRANSPORT_FEE_LDGRS",
  APPLICATION_FEE_LDGRS = "APPLICATION_FEE_LDGRS",
  EXAM_FEE_LDGRS = "EXAM_FEE_LDGRS",
  EXCEPT_CASH_AND_BANK = "EXCEPT_CASH_AND_BANK",
  APPLICATION_AND_EXAM_FEE_LDGRS = "APPLICATION_AND_EXAM_FEE_LDGRS",
  STD_FEE_ACCT_LDGRS = "STD_FEE_ACCT_LDGRS",
  ACCT_LDGR_BY_GROUP_LDGR_ID = "ACCT_LDGR_BY_GROUP_LDGR_ID",
  HIDDEN_CASH_ACCOUNT = "HIDDEN_CASH_ACCOUNT",
  ACCT_LDGRS_CASH = "ACCT_LDGRS_CASH",
  ACCT_LDGRS_BANK = "ACCT_LDGRS_BANK",
  ACCT_LDGR_USER_DEFINED = "ACCT_LDGR_USER_DEFINED",
  STD_LDGR_USER_DEFINED = "STD_LDGR_USER_DEFINED",
  ONLY_APPLICATION_FEE_LDGR = "ONLY_APPLICATION_FEE_LDGR",
  CAPITATION_FEE_STD_LDGRS = "CAPITATION_FEE_STD_LDGRS",
  NON_CAPITATION_FEE_STD_LDGRS = "NON_CAPITATION_FEE_STD_LDGRS",
  ALL_STD_FEE_ACCT_LDGRS_FOR_NON_DEMAND = "ALL_STD_FEE_ACCT_LDGRS_FOR_NON_DEMAND",
  BILL_BOOK_ASSIGNED_STD_FEE_ACCT_LDGRS = "BILL_BOOK_ASSIGNED_STD_FEE_ACCT_LDGRS",
  SUSPENSE_DEPOSIT = "SUSPENSE_DEPOSIT",
  STD_FEE_ACCT_LDGRS_FOR_REPORT = "STD_FEE_ACCT_LDGRS_FOR_REPORT",
  SUNDRY_DEBITORS = "SUNDRY_DEBITORS",
  SUNDRY_SUPPLIERS = "SUNDRY_SUPPLIERS",
  STD_FEE_ACCT_LDGRS_WITHOUT_FINE = "STD_FEE_ACCT_LDGRS_WITHOUT_FINE",
  STD_FEE_ACCT_LDGRS_WITHOUT_DEPOSITS = "STD_FEE_ACCT_LDGRS_WITHOUT_DEPOSITS",
}

export enum AcctGroupLedgerFor {
  GENERAL = "GENERAL",
  INCOME = "INCOME",
  EXPENSE = "EXPENSE",
  RECEIPT = "RECEIPT",
  PAYMENT = "PAYMENT",
  PL = "PL",
}
export enum GroupLedgerRPTypes {
  BankAndCash = "BC",
  CurrentAsset = "CA",
  FixedAsset = "FA",
  Expense = "EX",
  Income = "IN",
  Liability = "LI",
  ProfitAndLoss = "PL",
}

export enum AcdSubjComboQueryType {
  ALL_COMBOS = "ALL_COMBOS",
  COMBO_STDS_ALLOCATED = "COMBO_STDS_ALLOCATED",
  COMBO_STDS_NOT_ALLOCATED = "COMBO_STDS_NOT_ALLOCATED",
  COMBO_BY_TEST_CLASS_ID = "COMBO_BY_TEST_CLASS_ID",
}
