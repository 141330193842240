import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import Modal from "react-modal";
import { useParams } from "react-router-dom";

import { Title } from "../../../../../stories/Title/Title";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import useStudentDatabyId from "../../../../../customhooks/useStudentDatabyId";
import {
  DEFAULT_TIME,
  EMPTY_STRING,
  ROWS_PER_PAGE,
  ROWS_PER_PAGE_30,
  TODAY_DATE,
  emptyMessageType,
} from "../../../../../utils/constants";
import useInstLabels from "../../../../../customhooks/general/useInstLabels";
import {
  DateRange,
  GetNoOfDaysDelayed,
  NameOfTheDay,
  formatter,
  handleMUISelectEvent,
  isOptionEqualToValue,
  toInputStandardDate,
  toIsoDate,
} from "../../../../../utils/UtilFunctions";
import { AppContext } from "../../../../../context/context";
import useServerDateandTime from "../../../../Library/customHooks/useServerDateandTime";
import { Label } from "../../../../../stories/Label/Label";
import useInstitutionConfiguration from "../../../../../customhooks/useInstitutionConfiguration";
import Input from "../../../../../stories/Input/Input";
import { Keys } from "../../../../../utils/Enum.keys";
import { studentRecepit } from "../../../../../Types/Accounting";
import useStudentDemandDetails, {
  studentDemandDetailsData,
} from "../../../hooks/useStudentDemandDetails";
import {
  AcctLedgerQueryType,
  DemandDetailsQueryType,
  LedgerType,
} from "../../../common/QueryTypes";
import {
  BankOrCash,
  DebitOrCredit,
  Direction,
  ModuleName,
  Operation,
  PageFor,
  PredefinedDataTypes,
  ReceiptTypes,
  ReturnType,
  SortBy,
  UserType,
  VoucherBookKey,
  YesNo,
} from "../../../../../utils/Enum.types";
import useFineSlab from "../../../hooks/useFineSlab";
import useAcctLedgerData, {
  FeeLedgerEdge,
} from "../../../hooks/useAcctLedgerData";
import {
  msgType,
  optionsType,
  responseType,
} from "../../../../../utils/Form.types";
import { Button } from "../../../../../stories/Button/Button";
import Avatar from "../../../../../images/Avatar.svg";
import { AccountsTableHeaderProps } from "../../../../../Types/Tables";
import {
  GroupsWithSum,
  PayerType,
  TotalInterface,
  ledgerOptions,
  recepitsw,
} from "../Index";
import useAcctTableJson from "../../../json/useAcctTableJson";
import useVoucherNumber, { VDetails } from "../../../hooks/useVoucherNumber";
import useToken from "../../../../../customhooks/useToken";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  CheckAnyBankLdgrAddedData,
  CheckAnyBankLdgrAddedVars,
} from "../../../common/types";
import {
  CheckAnyBankLdgrAdded,
  GetAcctLdgrs,
} from "../../../queries/FeeLedgers/query/Byid";

import DownArrow from "../../../../../images/DownArrow.svg";
import usePredefinedDataByType from "../../../../../customhooks/usePredefinedDataByType";
import TextArea from "../../../../../stories/TextArea/TextArea";
import { getDownloadUrl } from "../../../../../utils/DownloadFile";
import { AddAcctVoucherMasterAndDetails } from "../../../queries/receipts/mutation/Index";
import useLoggedInUserDetails from "../../../hooks/useLoggedInUserDetails";
import {
  GetAcctVouchers,
  GetVoucherNumber,
} from "../../../queries/Vouchers/query";
import { VoucherQueryTypes } from "../../../common/Enum.types";
import { GetStdCompleteFeeDetailsByStudentID } from "../../../../../queries/students/list/byId";
import { payloadTypes } from "../../../../../context/reducer";
import { IMultipleReceipts } from "./MultipleReceipts";
import { Data } from "../../../../Print/Accounts/FeeReceipts/templates/Index";
import { useOnlinePayment } from "../useOnlinePayment";
import {
  PrintConfigModalStyles,
  PrintModalStyles,
} from "../../../../../styles/ModalStyles";
import Print from "../../../../Print/Accounts/FeeReceipts/Standard/FeeReceiptPrint";
import Close from "../../../../../images/Close.svg";
import Settings from "../../../../../images/Settings.svg";
import Configurations from "../../../../Configurations/PerModuleConfiguration";
import { SwConfigQueryType } from "../../../../HR/enums/Enum.types";
import MessageModal from "../../../../../pages/MessageModal";
import {
  AcctVoucherMasterAndDetails,
  AddAcctVoucherMasterAndDetailsData,
  AddAcctVoucherMasterAndDetailsVars,
} from "../../../../../Types/Accounting/mutations";
import useAssignedInstbyEmpId, {
  InstUserAccessQueryType,
} from "../../../../UserRights/hooks/useAssignedInstbyEmpId";
import useInstDetails from "../../../../../customhooks/general/useInstDetails";
import {
  FormAutocomplete,
  formClasses,
  RequiredAutoComplete,
} from "../../../../../styles/AutocompleteStyles";
import { FineType } from "../../../Fines/Index";

interface configFlags {
  allowMultipleBillsUnderOne: boolean;
  isMultipleBillook: boolean;
  editDate: boolean;
  hideCaptationFee: boolean;
  enableFeeFine: boolean;
  enablePaymentGateway: boolean;
  enablePartialStdReceiptPayment: boolean;
  strictlyCollectStdFeeInOrder: boolean;
  enableObCollectFirst: boolean;
  enableDefaultPrintWhilesaved: boolean;
}
interface Props {
  rcptDate: string;
  setRcptDate: React.Dispatch<React.SetStateAction<string>>;
  configFlags: configFlags;
  payer: PayerType;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  handleClose: () => void;
}
const ObReceipt = ({
  rcptDate,
  setRcptDate,
  payer,
  configFlags,
  setModalFlag,
  handleClose,
}: Props) => {
  const { InstId } = useParams();
  const { token } = useToken();

  const { dispatch, state } = useContext(AppContext);
  const { InstDetails } = useInstDetails(1);

  const dates = DateRange(new Date().toString());
  // eslint-disable-next-line
  const [amountDisabled, setAmountDisabled] = useState(false);
  const [admissionNumberDisabled, setAdmissionNumberDiabled] = useState(false);
  const [bankLedgerExists, setBankLedgerExists] = useState(false);
  const [cashAccount, setCashAccount] = useState(false);
  const [bankAccount, setBankAccount] = useState(false);
  const [printModal, SetPrintModal] = useState(false);
  const [enableWaiveOff, setEnableWaiveOff] = useState(false);
  // eslint-disable-next-line
  const [waivedOff, setWaivedOff] = useState(false);
  const [prinConfigModal, setPrintConfigModal] = useState(false);

  const [searchData, setSearchData] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("");
  const [narration, setNarration] = useState("");

  const [receivedAmount, setReceivedAmount] = useState(0);
  const [totalFineAmt, setTotalFineAmt] = useState(0);
  const [depositLedgerAmount, setDepositLedgerAmount] = useState(0);

  const [selectedDepositLedger, setSelectedDepositLedger] =
    useState<FeeLedgerEdge | null>(null);
  const [transactionBookLedgerId, setTransactionLedgerId] =
    useState<responseType | null>(null);
  const [bankTransactionType, setBankTransactionType] =
    useState<optionsType | null>(null);
  const [ledgerType, setLedgerType] = useState<optionsType | null>(null);

  const [items, setItems] = useState<studentRecepit[]>([]);
  const [generatedVoucherNumbers, setVoucherNumbers] = useState<VDetails[]>([]);
  const [receipts, setReceipts] = useState<recepitsw[]>([]);
  const [voucherKeys, setVoucherKeys] = useState<string[]>([]);
  const [v_no, set_vo_no] = useState("");
  const [v_date, set_v_Date] = useState("");

  const [inEditMode, setInEditMode] = useState({
    status: false,
    rowKey: 0,
  });

  const [UpdatedReceviedAmount, setUpdatedReceviedAmount] = useState(0);
  const [waiveOffAmt, setWaiveOffAmt] = useState(0);
  const [imageString, setImageString] = useState("");

  const [tableData, setTableData] = useState<Data[]>([]);

  const [message, setMessage] = useState<msgType>({
    flag: false,
    message: "",
    operation: Operation.NONE,
  });

  const waiveOffAmtMemoized =
    items.length > 0
      ? items.reduce((acc, item) => {
          acc += item.fine_amt;
          return acc;
        }, 0)
      : 0;

  const [multiplereceipts, setMultipleReceipts] = useState<IMultipleReceipts[]>(
    []
  );
  const [multipleReceiptsModal, setMultipleRecepitsModal] = useState(false);

  const { component } = useOnlinePayment(setModalFlag, items, totalFineAmt);
  const modeOfTransactionRef = useRef<HTMLSelectElement>(null);
  const modeOfTransactionInputRef = modeOfTransactionRef.current?.childNodes[0]
    .childNodes[0].childNodes[0] as HTMLInputElement;
  const remarksRef = useRef<HTMLTextAreaElement>(null);
  const saveButtonRef = useRef<HTMLButtonElement>(null);

  const [GenerateStudentReceipts, { loading: GenerateStudentReceiptsLoading }] =
    useMutation<
      AddAcctVoucherMasterAndDetailsData,
      AddAcctVoucherMasterAndDetailsVars
    >(AddAcctVoucherMasterAndDetails, {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    });

  const [GetBankLedgersExitsStatus, { data }] = useLazyQuery<
    CheckAnyBankLdgrAddedData,
    CheckAnyBankLdgrAddedVars
  >(CheckAnyBankLdgrAdded, {
    variables: {
      inst_id: InstId!,
      token,
    },
  });

  const { format } = formatter;
  const { serverDate } = useServerDateandTime();

  const { user_details } = useLoggedInUserDetails();
  const { studentData, studentFormData } = useStudentDatabyId();
  const { branchLabel, classLabel, categoryLabel } = useInstLabels();
  const { Accounts_Table } = useAcctTableJson();
  const { acctLedgers: CashLedgers } = useAcctLedgerData(
    searchData,
    AcctLedgerQueryType.ACCT_LDGRS_CASH,
    ROWS_PER_PAGE
  );
  const { acctLedgers: BankLedgers } = useAcctLedgerData(
    searchData,
    AcctLedgerQueryType.ACCT_LDGRS_BANK,
    ROWS_PER_PAGE
  );

  const {
    PredefinedData: { dropDown: bankTransactionTypes },
  } = usePredefinedDataByType(
    PredefinedDataTypes.BANK_COLLECTION,
    EMPTY_STRING,
    ReturnType.WITH_ID
  );

  const {
    allowMultipleBillsUnderOne,
    editDate,
    enableFeeFine,
    enablePaymentGateway,
    hideCaptationFee,
    enablePartialStdReceiptPayment,
    isMultipleBillook,
    strictlyCollectStdFeeInOrder,
    enableDefaultPrintWhilesaved,
  } = configFlags;
  const { USE_CATEGORY_KEY } = useInstitutionConfiguration();

  const { finesData } = useFineSlab();
  const refetchQueries = [
    {
      query: GetAcctVouchers,
      variables: {
        after: null,
        direction: Direction.ASC,
        fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
        first: ROWS_PER_PAGE,
        sortBy: SortBy.V_DATE,
        token,
        name: EMPTY_STRING,
        deposit: null,
        vTranscationCashOrBank: null,
        partyName: EMPTY_STRING,
        vTranscationNo: EMPTY_STRING,
        vTranscationNarration: EMPTY_STRING,
        ldgrDesc: EMPTY_STRING,
        amount: null,
        input: {
          inst_id: InstId!,
          voucher_query_type: VoucherQueryTypes.STUDENT_ALL_RECEIPTS,
          vo_end_date: toIsoDate(dates?.lastDay!),
          acct_ldgr_id: state.accountLedgerId,
          vo_start_date: toIsoDate(dates?.firstDay!),
          vo_type: EMPTY_STRING,
        },
      },
    },
    {
      query: GetAcctVouchers,
      variables: {
        after: null,
        direction: Direction.ASC,
        fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
        first: ROWS_PER_PAGE,
        sortBy: SortBy.V_DATE,
        token,
        name: EMPTY_STRING,
        deposit: null,
        vTranscationCashOrBank: null,
        v_no: EMPTY_STRING,
        partyName: EMPTY_STRING,
        vTranscationNo: EMPTY_STRING,
        vTranscationNarration: EMPTY_STRING,
        ldgrDesc: EMPTY_STRING,
        amount: null,
        input: {
          acct_ldgr_id: 0,
          inst_id: InstId!,
          student_id: 0,
          user_details,
          vo_end_date: toIsoDate(dates?.lastDay!),
          vo_start_date: toIsoDate(dates?.firstDay!),
          voucher_query_type: VoucherQueryTypes.STUDENT_DEMAND_RECEIPTS,
          vo_type: EMPTY_STRING,
        },
      },
    },
    {
      query: GetAcctVouchers,
      variables: {
        after: null,
        amount: null,
        deposit: null,
        direction: Direction.ASC,
        fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
        first: ROWS_PER_PAGE_30,
        input: {
          acct_ldgr_id: state.accountLedgerId,
          inst_id: InstId!,
          student_id: 0,
          user_details,
          vo_end_date: toIsoDate(TODAY_DATE),
          vo_start_date: toIsoDate(TODAY_DATE),
          vo_type: EMPTY_STRING,
          voucher_query_type: VoucherQueryTypes.STD_DAILY_RECEIPTS,
        },
        ldgrDesc: EMPTY_STRING,
        partyName: EMPTY_STRING,
        sortBy: SortBy.V_DATE,
        token,
        vTranscationCashOrBank: null,
        vTranscationNo: EMPTY_STRING,
        vTranscationNarration: EMPTY_STRING,
        v_no: EMPTY_STRING,
      },
    },
    {
      query: GetAcctLdgrs,
      variables: {
        token,
        after: null,
        first: ROWS_PER_PAGE,
        input: {
          acct_ldgr_query_type: AcctLedgerQueryType.ACCT_LDGRS_BANK,
          ids: [Number(InstId)],
        },
        name: EMPTY_STRING,
        orderBy: [
          {
            direction: Direction.ASC,
            field: SortBy.INDEX,
          },
          {
            direction: Direction.DESC,
            field: SortBy.LDGR_DESC,
          },
        ],
      },
    },
    {
      query: GetStdCompleteFeeDetailsByStudentID,
      variables: {
        token,
        fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
        inst_id: InstId!,
        student_id: state.studentId,
      },
    },
    {
      query: GetVoucherNumber,
      variables: {
        vo_book_key: VoucherBookKey.STUDENT_COMMAN_RECEIPT_BOOK,
        token,
        inst_id: InstId!,
        fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
      },
    },
  ];
  const itemsVoBookDescSet = useMemo(() => {
    return new Set(
      items.map(
        (item) =>
          item.acct_ldgr_details.voucher_book_details &&
          item.acct_ldgr_details.voucher_book_details.vo_book_desc
      )
    );
  }, [items, state.studentId]);
  const { voucherNumber } = useVoucherNumber(
    isMultipleBillook
      ? (voucherKeys as VoucherBookKey[])!
      : VoucherBookKey.STUDENT_COMMAN_RECEIPT_BOOK
  );
  const { StudentDemandDetails } = useStudentDemandDetails(
    DemandDetailsQueryType.DEMAND_DETAILS_GT_0,
    false,
    EMPTY_STRING
  );

  const getFine = (noOfDaysDelayed: number, totalAmount?: number) => {
    if (
      finesData &&
      finesData.data &&
      finesData.data.GetAcctFineSlabByInstId.length === 0
    ) {
      return 0;
    }
    const { GetAcctFineSlabByInstId } = finesData.data || {};
    if (!GetAcctFineSlabByInstId) return 0;

    const { acct_fine_type, roi, days, fine_amt } = GetAcctFineSlabByInstId[0];

    const rateOfInterest = parseFloat(roi) || 0;
    const fineAmount = parseFloat(fine_amt.toString()) || 0;

    if (noOfDaysDelayed >= days) {
      if (acct_fine_type === FineType.PERCENTAGE) {
        const periodDays = days;
        const periods = Math.floor(noOfDaysDelayed / periodDays);
        const remainingDays = noOfDaysDelayed % periodDays;

        const fineForPeriods = (rateOfInterest / 100) * totalAmount! * periods;

        const fineForRemainingDays =
          ((rateOfInterest / 100) * totalAmount! * remainingDays) / periodDays;

        return fineForPeriods + fineForRemainingDays;
      } else {
        return fineAmount;
      }
    }

    return 0;
  };

  const mapStudentDemandDetails = (
    data: studentDemandDetailsData
  ): { studentFeeData: studentRecepit[] } => {
    const studentFeeData: studentRecepit[] =
      data.GetAcctStdDemandDetails.filter(
        (item) =>
          item !== null &&
          item.fee_ob > 0 &&
          item.fee_ob - item.fee_received > 0
      ).map((item) => {
        return {
          fee_ob: item.fee_ob,
          fee_demand: item.fee_demand,
          fee_concession: item.fee_concession,
          fee_receivable: item.fee_ob - item.fee_received,
          fee_received: item.fee_received,
          fee_refunds: item.fee_refunds,
          fee_bal: item.fee_ob - item.fee_received,
          v_no: "",
          fee_due_date: item.fee_due_date,
          acct_ldgr_id: item.acct_ldgr_id,
          id: item.id,
          cr_db: DebitOrCredit.CREDIT,
          recevied_amount: 0,
          newItem: YesNo.NO,
          legederType: LedgerType.GENERAL,
          rcpt_amt: 0,
          acct_ldgr_details: item?.acct_ldgr_details,
          initialBalance: item.fee_ob - item.fee_received,
          student_id: item.student_id,
          fine_amt:
            new Date(item?.fee_due_date!).getTime() <
            new Date(rcptDate).getTime()!
              ? getFine(GetNoOfDaysDelayed(new Date(item.fee_due_date!)))! *
                GetNoOfDaysDelayed(new Date(item.fee_due_date!))
              : 0,
        };
      });

    return { studentFeeData };
  };

  const totals = items.reduce(
    (acc, item) => {
      acc.totalOutStandingBalance += item.fee_ob;
      acc.totalDemandAmount += item.fee_demand;
      acc.totalconcession += item.fee_concession;
      acc.totalbalance += item.fee_bal;
      acc.totalPayable += item.fee_receivable;
      acc.totalpaidAmount += item.fee_received;
      acc.totalacademicAmount += item.rcpt_amt!;

      return acc;
    },
    {
      totalOutStandingBalance: 0,
      totalDemandAmount: 0,
      totalconcession: 0,
      totalbalance: 0,
      totalPayable: 0,
      totalpaidAmount: 0,
      totalacademicAmount: 0,
    }
  );

  const studentDemandDetailsmemoizedData = useMemo(() => {
    if (StudentDemandDetails.data && !StudentDemandDetails.loading) {
      const { studentFeeData } = mapStudentDemandDetails(
        StudentDemandDetails.data
      );

      return studentFeeData;
    }
    return items;
    // eslint-disable-next-line
  }, [
    StudentDemandDetails.data,
    StudentDemandDetails.loading,
    studentData.data,
    state.studentId,
  ]);
  const onEdit = (id: number, updatedRcptAmount: number) => {
    setInEditMode({
      status: true,
      rowKey: id,
    });

    setUpdatedReceviedAmount(updatedRcptAmount);
    setAdmissionNumberDiabled(true);
  };

  const getElementFromMultipleBooksArray = (checkId: number) => {
    const resArray: studentRecepit[] = [...itemsVoBookDescSet].reduce(
      (acc: studentRecepit[], v_book_desc) => {
        let fileteredBasedOnVDesc = items.filter(
          (item) =>
            item.acct_ldgr_details.voucher_book_details?.vo_book_desc ===
            v_book_desc
        );
        acc = [...acc, ...fileteredBasedOnVDesc];
        return acc;
      },
      []
    );
    const foundElement = resArray.find(({ id }) => id === checkId);
    const indexOfElement = foundElement ? resArray.indexOf(foundElement) : -1;
    const returnEle = foundElement ? resArray.indexOf(foundElement) : 0;

    return {
      nextElement:
        resArray.length > returnEle + 1 && resArray[returnEle + 1]
          ? resArray[returnEle + 1]
          : null,
      previousElement:
        resArray.length > returnEle - 1 && resArray[returnEle - 1]
          ? resArray[returnEle - 1]
          : null,
      elementIndex: indexOfElement,
      multipleBooksArray: resArray,
      length: resArray.length,
    };
  };

  const groups = useMemo(() => {
    return items.reduce(
      (acc: Record<string, studentRecepit[]>, item, index) => {
        const groupId: string = item.v_no || "";
        const group = acc[groupId] || [];

        // Loop through generatedVoucherNumbers to find a match
        // generatedVoucherNumbers.forEach((voucher) => {
        //   if (
        //     item?.acct_ldgr_details?.voucher_book_details?.vo_book_key ===
        //     voucher?.vo_book_key
        //   ) {
        //     // Update the voucher number
        //     item.v_no = voucher.vo_number;
        //   }
        // });

        const generatedItem = generatedVoucherNumbers
          .filter(Boolean)
          .find(
            (voucher) =>
              item.acct_ldgr_details?.voucher_book_details?.vo_book_key ===
              voucher.vo_book_key
          );
        item.v_no = generatedItem ? generatedItem.vo_number : "";

        return {
          ...acc,
          [groupId]: [...group, item],
        };
      },
      {}
    );
  }, [items, generatedVoucherNumbers]);

  const it = useMemo(() => {
    return Object.entries(groups)
      .map(([groupId, items]) => ({
        groupId,
        items_rcpts: items.filter((item) => item.rcpt_amt > 0),
      }))
      .filter(({ items_rcpts }) => items_rcpts.length > 0);
  }, [groups]);

  const updateItem = (
    dataArray: studentRecepit[],
    id: number,
    updatedAmount: number
  ) => {
    if (
      dataArray.find((item) => item.id === id)
        ? updatedAmount >
          dataArray.find((item) => item.id === id)?.initialBalance!
        : false
    ) {
      alert("Please check you're receipt amount");
      return dataArray;
    }
    return dataArray.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          rcpt_amt: updatedAmount,
          fee_bal: item.fee_ob - updatedAmount - item.fee_received,
        };
      }
      return item;
    });
  };

  const HandleEditItem = (sl_number: number) => {
    const updatedData = updateItem(items, sl_number, UpdatedReceviedAmount);
    if (updatedData) {
      setItems(updatedData);
    } else alert("Invalid ledger type");
  };

  const handleSplittingAmount = () => {
    let initalAmount = receivedAmount;

    if (enablePartialStdReceiptPayment) {
      const resArray: studentRecepit[] = [...itemsVoBookDescSet].reduce(
        (acc: studentRecepit[], v_book_desc) => {
          let m = studentDemandDetailsmemoizedData.filter(
            (item) =>
              item.acct_ldgr_details.voucher_book_details &&
              item.acct_ldgr_details.voucher_book_details.vo_book_desc ===
                v_book_desc
          );
          acc = [...acc, ...m];
          return acc;
        },
        []
      );
      const rcvd_amt = resArray.map((item) => {
        const assignableAmt =
          initalAmount > item.fee_bal ? item.fee_bal : initalAmount;
        initalAmount -= item.fee_bal;

        if (assignableAmt > 0) {
          return {
            ...item,
            rcpt_amt: assignableAmt,
            fee_bal:
              item.fee_bal - assignableAmt > 0
                ? item.fee_bal - assignableAmt
                : 0,
          };
        } else {
          return {
            ...item,
            rcpt_amt: 0,
          };
        }
      });
      setItems(rcvd_amt);
      if (selectedDepositLedger != null) {
        setDepositLedgerAmount(initalAmount);
      }
      if (items[0].rcpt_amt && items[0].rcpt_amt > 0) {
        onEdit(items[0].id!, items[0].rcpt_amt);
      }
    } else {
      const res = studentDemandDetailsmemoizedData.map((item) => {
        if (strictlyCollectStdFeeInOrder) {
          if (initalAmount !== 0) {
            if (initalAmount < item.initialBalance) {
              initalAmount -= item.fee_bal;
              return {
                ...item,
                rcpt_amt: item.initialBalance,
                fee_bal: 0,
              };
            } else if (initalAmount >= item.initialBalance) {
              initalAmount -= item.fee_bal;
              return {
                ...item,
                rcpt_amt: item.initialBalance,
                fee_bal: 0,
              };
            } else if (initalAmount > 0) {
              return {
                ...item,
                rcpt_amt: 0,
              };
            }
          }
        } else {
          if (initalAmount < item.initialBalance) {
            initalAmount -= item.fee_bal;
            return {
              ...item,
              rcpt_amt: item.initialBalance,
              fee_bal: 0,
            };
          } else if (initalAmount >= item.initialBalance) {
            initalAmount -= item.fee_bal;
            return {
              ...item,
              rcpt_amt: item.initialBalance,
              fee_bal: 0,
            };
          } else if (initalAmount > 0) {
            return {
              ...item,
              rcpt_amt: 0,
            };
          }
        }

        return { ...item, rcpt_amt: 0 };
      });

      if (strictlyCollectStdFeeInOrder) {
        if (selectedDepositLedger != null) {
          setItems(res);
          setDepositLedgerAmount(initalAmount);
        } else if (initalAmount === 0) {
          setItems(res);
        } else {
          setMessage({
            flag: true,
            message: "Cannot Enter Partial Amount",
            operation: Operation.NONE,
          });
        }
      }
      setInEditMode({
        rowKey: 0,
        status: false,
      });
      if (items[0].rcpt_amt && items[0].rcpt_amt > 0) {
        onEdit(items[0].id!, items[0].rcpt_amt);
      }
      if (initalAmount === 0) {
        setItems(res);
      }
      if (initalAmount > 0) {
        if (selectedDepositLedger != null) {
          setItems(res);
          setDepositLedgerAmount(initalAmount);
        } else
          setMessage({
            flag: true,
            message: `Amount cannot be partially paid`,
            operation: Operation.NONE,
          });
      }
    }
  };

  const groupsWithSum: GroupsWithSum = Object.entries(groups).reduce(
    (acc, [groupId, group]) => ({
      ...acc,
      [groupId]: group.reduce(
        (total: TotalInterface, item) => ({
          sum: total.sum + (item.rcpt_amt || 0),
          vo_key: item.acct_ldgr_details.voucher_book_details?.vo_book_key!,
          vo_number: item.v_no!,
        }),
        { sum: 0, vo_key: "", vo_number: "" }
      ),
    }),
    {}
  );
  const handleReceipts = () => {
    if (transactionBookLedgerId)
      setReceipts((items) => [
        ...items,
        {
          acct_ldgr_id: transactionBookLedgerId.value,
        },
      ]);
  };

  const handleClear = () => {
    setItems([]);
    setAdmissionNumberDiabled(false);
    setReceivedAmount(0);
    setUpdatedReceviedAmount(0);
    dispatch({
      type: payloadTypes.SET_STUDENT_ID,
      payload: {
        studentId: 0,
      },
    });
    setReceipts([]);
    setTransactionLedgerId(null);
    setLedgerType(null);
    setBankTransactionType(null);
    setNarration("");
    setRcptDate(TODAY_DATE);
    setTotalFineAmt(0);
    setReferenceNumber("");
    setInEditMode({
      rowKey: -1,
      status: false,
    });
    setDepositLedgerAmount(0);
    setSelectedDepositLedger(null);
  };

  const isSumGreaterThanZero = Object.values(groupsWithSum)
    .slice(1) // exclude the first array
    .every(({ sum }) => sum > 0);

  const handleStudentReceipts = () => {
    if (ledgerType === null) {
      alert("Mode of transaction is required");
      return;
    }
    const res = bankTransactionTypes.find(
      ({ value }) => bankTransactionType && value === bankTransactionType.value
    );

    if (transactionBookLedgerId) {
      if (isMultipleBillook) {
        if (
          hideCaptationFee &&
          generatedVoucherNumbers.find((no) => no.voucher_sub_type === "MGT") &&
          res
        ) {
          alert("Management fee can only be collected through cash");
          return;
        }
        if (
          generatedVoucherNumbers.length > 1 &&
          !allowMultipleBillsUnderOne &&
          res &&
          isSumGreaterThanZero
        ) {
          alert("multiple bill books cannot be under one bank transaction");
          return;
        }
        const input: AcctVoucherMasterAndDetails[] = it.map(
          ({ groupId, items_rcpts }, index) => {
            const keys = Object.entries(groupsWithSum)?.find(
              ([id, num]: [string, TotalInterface]) => id === groupId
            )?.[1]?.vo_key!;
            const totalAmount =
              Object.entries(groupsWithSum).find(
                ([id, num]: [string, TotalInterface]) => id === groupId
              )?.[1].sum! + totalFineAmt;
            if (index === it.length - 1 && selectedDepositLedger !== null) {
              return {
                acct_voucher_master: {
                  fin_yr: state.ActiveFinYr
                    ? state.ActiveFinYr.fin_yr
                    : EMPTY_STRING,

                  v_type: ReceiptTypes.STUDENT_RECEIPT,
                  v_no: groupId,
                  v_std_anonymous_deposit_adjusted: false,

                  v_date: toIsoDate(rcptDate),
                  v_total_cr_amt: totalAmount + depositLedgerAmount,
                  v_total_db_amt: totalAmount + depositLedgerAmount,
                  v_reconciled: false,
                  v_reconciled_date: toIsoDate(DEFAULT_TIME),
                  v_std_receipt: true,
                  v_std_receipt_ob: true,
                  v_std_non_demand_receipt: false,
                  v_std_refund: false,
                  v_std_enquiry: false,

                  enquiry_student_id: 0,
                  v_std_deposit_adjusted: false,

                  v_std_receipt_anonymous: false,
                  v_std_refund_deposit: false,
                  v_book_type: keys,
                  v_std_deposit: false,
                  v_std_passout_receipt: false,
                  v_std_demand_receipt: true,
                  v_std_scholarship_deposit: false,

                  student_id: state.studentId,
                  class_id: studentData.data?.nodes[0].class.id,
                  semester_id: studentData.data?.nodes[0].semester.id,
                  v_std_amt_receipt: totalAmount + depositLedgerAmount,
                  v_std_amt_deposit: 0,
                  v_std_amt_fine: totalFineAmt,
                  v_std_amt_total: totalAmount + depositLedgerAmount,
                  v_std_amt_refunded: 0,
                  v_std_amt_adjusted: 0,
                  v_transcation_type: res?.label ?? "Cash Book",
                  v_transcation_cash_or_bank: res
                    ? BankOrCash.BANK
                    : BankOrCash.CASH,
                  v_transcation_no: referenceNumber,
                  v_transcation_date: toIsoDate(rcptDate),
                  v_transcation_narration: narration,
                  // paid_party_id: "",
                  // party_bill_no: "",
                  party_bill_date: toIsoDate(DEFAULT_TIME),
                  // party_name: "",
                  annx_yesno: false,
                  // annx_id: Math.random() * 1000,
                  is_vouch_multi_entry: false,
                  acct_ldgr_id_cr: items.filter((d) => d.rcpt_amt > 0)[0]
                    .acct_ldgr_details.id,
                  acct_ldgr_id_db: receipts[0].acct_ldgr_id,
                },
                acct_voucher_db: receipts.map((item, index) => ({
                  vo_cr_db: DebitOrCredit.DEBIT,
                  vo_sl_no: index + 1,
                  vo_cr: 0,
                  vo_db:
                    Object.entries(groupsWithSum).find(
                      (sum) => sum[0] === groupId
                    )?.[1].sum! +
                    totalFineAmt +
                    depositLedgerAmount,
                  vo_cr_total: 0,
                  vo_db_total:
                    Object.entries(groupsWithSum).find(
                      (sum) => sum[0] === groupId
                    )?.[1].sum! +
                    totalFineAmt +
                    depositLedgerAmount,

                  acct_ldgr_id: transactionBookLedgerId.value,
                })),
                acct_voucher_cr: items_rcpts.map((item, index) => ({
                  vo_sl_no: index + 1,
                  vo_cr_db: DebitOrCredit.CREDIT,
                  vo_cr: item.rcpt_amt,
                  vo_db: 0,
                  vo_cr_total:
                    Object.entries(groupsWithSum).find(
                      (sum) => sum[0] === groupId
                    )?.[1].sum! +
                    totalFineAmt +
                    depositLedgerAmount,
                  vo_db_total: 0,
                  acct_ldgr_id: item.acct_ldgr_details.id,
                })),
                acct_deposit_cr: [
                  {
                    vo_sl_no: 1,
                    vo_cr_db: DebitOrCredit.CREDIT,
                    vo_cr: depositLedgerAmount,
                    vo_db: 0,
                    vo_cr_total:
                      Object.entries(groupsWithSum).find(
                        (sum) => sum[0] === groupId
                      )?.[1].sum! +
                      totalFineAmt +
                      depositLedgerAmount,
                    vo_db_total: 0,
                    acct_ldgr_id: selectedDepositLedger?.node.id,
                  },
                ],
              };
            } else
              return {
                acct_voucher_master: {
                  fin_yr: state.ActiveFinYr
                    ? state.ActiveFinYr.fin_yr
                    : EMPTY_STRING,

                  v_type: ReceiptTypes.STUDENT_RECEIPT,
                  v_no: groupId,
                  v_std_anonymous_deposit_adjusted: false,

                  v_date: toIsoDate(rcptDate),
                  v_total_cr_amt: totalAmount,
                  v_total_db_amt: totalAmount,
                  v_reconciled: false,
                  v_reconciled_date: toIsoDate(DEFAULT_TIME),
                  v_std_receipt: true,
                  v_std_non_demand_receipt: false,
                  v_std_refund: false,
                  v_std_enquiry: false,
                  enquiry_student_id: 0,
                  v_std_deposit_adjusted: false,
                  v_std_receipt_ob: true,

                  v_std_receipt_anonymous: false,
                  v_std_refund_deposit: false,
                  v_book_type: keys,
                  v_std_deposit: false,
                  v_std_passout_receipt: false,
                  v_std_demand_receipt: true,
                  v_std_scholarship_deposit: false,

                  student_id: state.studentId,
                  class_id: studentData.data?.nodes[0].class.id,
                  semester_id: studentData.data?.nodes[0].semester.id,
                  v_std_amt_receipt: totalAmount,
                  v_std_amt_deposit: 0,
                  v_std_amt_fine: totalFineAmt,
                  v_std_amt_total: totalAmount,
                  v_std_amt_refunded: 0,
                  v_std_amt_adjusted: 0,
                  v_transcation_type: res?.label ?? "Cash Book",
                  v_transcation_cash_or_bank: res
                    ? BankOrCash.BANK
                    : BankOrCash.CASH,
                  v_transcation_no: referenceNumber,
                  v_transcation_date: toIsoDate(rcptDate),
                  v_transcation_narration: narration,
                  // paid_party_id: "",
                  // party_bill_no: "",
                  party_bill_date: toIsoDate(DEFAULT_TIME),
                  // party_name: "",
                  annx_yesno: false,
                  // annx_id: Math.random() * 1000,
                  is_vouch_multi_entry: false,
                  acct_ldgr_id_cr: items.filter((d) => d.rcpt_amt > 0)[0]
                    .acct_ldgr_details.id,
                  acct_ldgr_id_db: receipts[0].acct_ldgr_id,
                },
                acct_voucher_db: receipts.map((item, index) => ({
                  vo_cr_db: DebitOrCredit.DEBIT,
                  vo_sl_no: index + 1,
                  vo_cr: 0,
                  vo_db:
                    Object.entries(groupsWithSum).find(
                      (sum) => sum[0] === groupId
                    )?.[1].sum! + totalFineAmt,
                  vo_cr_total: 0,
                  vo_db_total:
                    Object.entries(groupsWithSum).find(
                      (sum) => sum[0] === groupId
                    )?.[1].sum! + totalFineAmt,

                  acct_ldgr_id: transactionBookLedgerId.value,
                })),
                acct_voucher_cr: items_rcpts.map((item, index) => ({
                  vo_sl_no: index + 1,
                  vo_cr_db: DebitOrCredit.CREDIT,
                  vo_cr: item.rcpt_amt,
                  vo_db: 0,
                  vo_cr_total:
                    Object.entries(groupsWithSum).find(
                      (sum) => sum[0] === groupId
                    )?.[1].sum! + totalFineAmt,
                  vo_db_total: 0,
                  acct_ldgr_id: item.acct_ldgr_details.id,
                })),
              };
          }
        );

        GenerateStudentReceipts({
          variables: {
            token,
            inst_id: InstId!,
            user_details,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            input: input,
          },
          refetchQueries,
        }).then(({ data }) => {
          if (data) {
            setMessage({
              message: `Successfully generated fee receipt for student ${studentData.data?.nodes[0].first_name}`,
              flag: true,
              operation: Operation.CREATE,
            });
            const { id } = data.AddAcctVoucherMasterAndDetails[0];
            dispatch({
              type: payloadTypes.SET_RECEPIT_ID,
              payload: { receiptId: id },
            });
            if (enablePartialStdReceiptPayment) {
              setMultipleReceipts(data.AddAcctVoucherMasterAndDetails);
              setMultipleRecepitsModal(!multipleReceiptsModal);
            }
          }
        });
      } else {
        const input = {
          acct_voucher_master: {
            fin_yr: state.ActiveFinYr ? state.ActiveFinYr.fin_yr : EMPTY_STRING,
            v_type: ReceiptTypes.STUDENT_RECEIPT,
            v_no: voucherNumber.data?.GetVoucherNumber.vo_number!,
            v_date: toIsoDate(rcptDate),
            v_total_cr_amt:
              totals.totalacademicAmount + totalFineAmt + depositLedgerAmount,
            v_total_db_amt:
              totals.totalacademicAmount + totalFineAmt + depositLedgerAmount,
            v_reconciled: false,
            v_reconciled_date: toIsoDate(DEFAULT_TIME),
            v_std_receipt: true,
            v_std_non_demand_receipt: false,
            v_std_anonymous_deposit_adjusted: false,
            v_std_receipt_ob: true,
            v_std_refund: false,
            v_std_enquiry: false,
            enquiry_student_id: 0,
            v_std_deposit_adjusted: false,
            v_book_type: VoucherBookKey.STUDENT_COMMAN_RECEIPT_BOOK,
            v_std_deposit: false,
            v_std_passout_receipt: false,
            v_std_demand_receipt: true,
            v_std_scholarship_deposit: false,

            v_std_receipt_anonymous: false,
            student_id: state.studentId,
            v_std_refund_deposit: false,
            class_id: studentData.data?.nodes[0].class.id,
            semester_id: studentData.data?.nodes[0].semester.id,
            v_std_amt_receipt: totals.totalacademicAmount + depositLedgerAmount,
            v_std_amt_deposit: 0,
            v_std_amt_fine: totalFineAmt,
            v_std_amt_total:
              totals.totalacademicAmount + totalFineAmt + depositLedgerAmount,
            v_std_amt_refunded: 0,
            v_std_amt_adjusted: 0,
            v_transcation_type: res?.label ?? "Cash Book",
            v_transcation_cash_or_bank: res ? BankOrCash.BANK : BankOrCash.CASH,
            v_transcation_no: referenceNumber,
            v_transcation_date: toIsoDate(DEFAULT_TIME),
            v_transcation_narration: narration,
            // paid_party_id: "",
            // party_bill_no: "",
            party_bill_date: toIsoDate(DEFAULT_TIME),
            // party_name: "",
            annx_yesno: false,
            // annx_id: Math.random() * 1000,
            is_vouch_multi_entry: false,
            acct_ldgr_id_cr: items.filter((d) => d.rcpt_amt > 0)[0]
              .acct_ldgr_details.id,
            acct_ldgr_id_db: receipts[0].acct_ldgr_id,
          },
          acct_voucher_db: receipts.map((receipt, index) => ({
            vo_cr_db: DebitOrCredit.DEBIT,
            vo_sl_no: index + 1,
            vo_cr: 0,
            vo_db:
              totals.totalacademicAmount + totalFineAmt + depositLedgerAmount,
            vo_cr_total: 0,
            vo_db_total:
              totals.totalacademicAmount + totalFineAmt + depositLedgerAmount,
            acct_ldgr_id: receipt.acct_ldgr_id,
          })),
          acct_voucher_cr: items
            .filter((item) => item.rcpt_amt > 0)
            .map((item, index) => ({
              vo_sl_no: index + 1,
              vo_cr_db: DebitOrCredit.CREDIT,
              vo_cr: item.rcpt_amt,
              vo_db: 0,
              vo_cr_total:
                totals.totalacademicAmount + totalFineAmt + depositLedgerAmount,
              vo_db_total: 0,
              acct_ldgr_id: item.acct_ldgr_id,
            })),
        };

        const finalInput: AcctVoucherMasterAndDetails[] =
          selectedDepositLedger !== null
            ? [
                {
                  ...input,
                  acct_deposit_cr: [
                    {
                      vo_sl_no:
                        items.filter((item) => item.rcpt_amt > 0).length + 1,
                      vo_cr_db: DebitOrCredit.CREDIT,
                      vo_cr: depositLedgerAmount,
                      vo_db: 0,
                      vo_cr_total:
                        totals.totalacademicAmount +
                        totalFineAmt +
                        depositLedgerAmount,
                      vo_db_total: 0,
                      acct_ldgr_id: selectedDepositLedger.node.id,
                    },
                  ],
                },
              ]
            : [input];
        GenerateStudentReceipts({
          variables: {
            token,
            inst_id: InstId!,
            user_details,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            input: finalInput,
          },
          refetchQueries,
        }).then(({ data }) => {
          if (data && data.AddAcctVoucherMasterAndDetails) {
            const { v_no, v_date, id } = data.AddAcctVoucherMasterAndDetails[0];
            dispatch({
              type: payloadTypes.SET_RECEPIT_ID,
              payload: { receiptId: id },
            });

            setTableData(
              items
                .filter((item) => item.rcpt_amt > 0)
                .map((item) => ({
                  particular: item.acct_ldgr_details.ldgr_desc,
                  amount: item.rcpt_amt,
                }))
            );
            set_v_Date(v_date);
            set_vo_no(v_no);

            enableDefaultPrintWhilesaved && !printModal
              ? SetPrintModal(!printModal)
              : handleClear();
            setMessage({
              message: `Successfully generated fee receipt for student ${studentData.data?.nodes[0].first_name}`,
              flag: true,
              operation: Operation.CREATE,
            });
          }
        });
      }
    }
  };

  useEffect(() => {
    setItems(state.studentId ? studentDemandDetailsmemoizedData : []);
    if (studentData.data && state.studentId) {
      setReceivedAmount(
        studentDemandDetailsmemoizedData.reduce((acc, item) => {
          acc = acc + (item.fee_ob - item.fee_received);
          return acc;
        }, 0)
      );

      if (
        studentData.data.nodes[0].std_profile_filename !== EMPTY_STRING &&
        InstDetails.data
      ) {
        const studentProfiePicUrl = `${InstDetails.data?.nodes[0]?.inst_name}/students/${state.studentId}/std_profile_pic/std_profile_pic`;
        // eslint-disable-next-line
        const ers = getDownloadUrl(studentProfiePicUrl, false, setImageString);
      }
    } else {
      setImageString("");
    }

    // eslint-disable-next-line
  }, [
    studentDemandDetailsmemoizedData,
    // eslint-disable-next-line
    hideCaptationFee,
    studentData.data,
    voucherNumber.data,
    state.studentId,
    InstDetails.data,
  ]);
  useEffect(() => {
    if (transactionBookLedgerId && transactionBookLedgerId.value > 0) {
      handleReceipts();
    } // eslint-disable-next-line
  }, [transactionBookLedgerId]);
  useEffect(() => {
    if (token) {
      GetBankLedgersExitsStatus().then(({ data }) => {
        if (data) {
          setBankLedgerExists(data.CheckAnyBankLdgrAdded);
          if (data.CheckAnyBankLdgrAdded === false) {
            if (CashLedgers.responseType.length === 1) {
              setTransactionLedgerId(CashLedgers.responseType[0]);
            }
            setLedgerType(
              ledgerOptions.find(({ value }) => value !== BankOrCash.CASH) ??
                null
            );
          }
        }
      });
    }
  }, [
    token,
    GetBankLedgersExitsStatus,
    data,
    ledgerOptions,
    CashLedgers.responseType,
  ]);

  const { MultipleInstitutions } = useAssignedInstbyEmpId(
    InstUserAccessQueryType.USER_ACCESS_BY_EMP_ID,
    state.empLoginId
  );
  const isAdmin =
    MultipleInstitutions?.data?.GetInstUserAccess.edges[0]?.node?.pr_emp_details
      .emp_type === UserType.SYS_ADMIN;

  return (
    <>
      <div className="row g-0">
        <div className="col">
          <Title>OB Fee Receipt</Title>
        </div>
      </div>
      <div className={"student-fee-receipt--modal"}>
        <div className="student-fee-receipt__details">
          <div
            className={"row g-0 student-fee-receipt__details--select-options"}>
            <div className="col student-fee-receipt__frame">
              <div className="student-fee-receipt__frame--imagefield">
                <TextField
                  label=" Admission Number"
                  className="student-fee-receipt__frame--textfield"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  value={
                    studentData.data
                      ? studentData.data.nodes[0].std_adm_no
                      : EMPTY_STRING
                  }
                  disabled
                />
              </div>
              <>
                <TextField
                  label="Reg No."
                  className="student-fee-receipt__frame--textfield"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  value={studentFormData.reg_number}
                  disabled
                />
                <TextField
                  label={branchLabel}
                  className="student-fee-receipt__frame--textfield"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  value={studentFormData.branch}
                  disabled
                />
              </>
            </div>
            <div className="col student-fee-receipt__frame g-0">
              <>
                <TextField
                  label="Name"
                  className="student-fee-receipt__frame--textfield"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  value={studentFormData.std_name}
                  disabled
                />
                <TextField
                  label="Father Name"
                  className="student-fee-receipt__frame--textfield"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  value={studentFormData.father_name}
                  disabled
                />

                <TextField
                  label={classLabel}
                  className="student-fee-receipt__frame--textfield"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  value={studentFormData.class}
                  disabled
                />
              </>
            </div>
            <div className={"col student-fee-receipt__frame g-0"}>
              <>
                <TextField
                  type="date"
                  label="Date"
                  className="student-fee-receipt__frame--textfield--date"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                    input: {
                      inputProps: {
                        min: state.ActiveFinYr
                          ? toInputStandardDate(state.ActiveFinYr.fin_st_date!)
                          : EMPTY_STRING,
                        max: toInputStandardDate(serverDate),
                      },
                    },
                  }}
                  value={toInputStandardDate(rcptDate)}
                  onChange={(e) => setRcptDate(e.target.value)}
                  disabled={editDate ? false : true}
                />
                <Label variant="present-day">{NameOfTheDay(rcptDate)}</Label>

                {USE_CATEGORY_KEY && (
                  <TextField
                    label={categoryLabel}
                    className="student-fee-receipt__frame--textfield"
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    value={studentFormData.category}
                    disabled
                  />
                )}
              </>

              <div className="student-fee-receipt__label-gridtrio">
                <Input
                  id="receivedamount"
                  placeholder="Amount"
                  type="number"
                  disabled={amountDisabled}
                  value={receivedAmount}
                  //   inputRef={receivedAmountRef}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (Number(e.target.value) >= 0) {
                      setReceivedAmount(Number(e.target.value));
                      setUpdatedReceviedAmount(Number(e.target.value));
                    }
                  }}
                  onKeyDown={(event: React.KeyboardEvent) => {
                    if (event.key === Keys.ENTER) handleSplittingAmount();
                  }}
                />
                <Button
                  mode="received"
                  disabled={admissionNumberDisabled}
                  onClick={handleSplittingAmount}
                />
              </div>
            </div>
            <div className="col-1 student-fee-receipt__frame--image h-100">
              {imageString === EMPTY_STRING ? (
                <img src={Avatar} alt="/" />
              ) : (
                <img src={imageString} alt="/" />
              )}
            </div>
          </div>
          <span className="nodata">
            Cannot pay New Balance without clearing Old Balance
          </span>
          <div className={"row g-0 for-ob-receipt"}>
            <TableContainer className="student-fee-receipt__table g-0">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {Accounts_Table.Receipts.StudentObFeeReceipt.Table_Headers.map(
                      (th: AccountsTableHeaderProps, index: React.Key) => {
                        return (
                          <TableCell key={index} className={th.className}>
                            {th.labelName === "Receivable" &&
                            payer === PayerType.STUDENT
                              ? "Payable"
                              : th.labelName === "Received" &&
                                payer === PayerType.STUDENT
                              ? "Paid"
                              : th.labelName}
                          </TableCell>
                        );
                      }
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isMultipleBillook && state.claims && state.claims.EMPLOYEE
                    ? [...itemsVoBookDescSet].map((v_book_desc, index) => {
                        return (
                          <React.Fragment key={index}>
                            <TableRow>
                              <TableCell colSpan={8}>
                                <b>{v_book_desc}</b>
                              </TableCell>
                            </TableRow>
                            {items
                              .filter(
                                (item) =>
                                  item.acct_ldgr_details.voucher_book_details
                                    ?.vo_book_desc === v_book_desc
                              )
                              .map((v_item, i_index) => {
                                return (
                                  <TableRow
                                    className={
                                      v_item.legederType === LedgerType.DEPOSIT
                                        ? "deposit"
                                        : ""
                                    }>
                                    <TableCell
                                      className="student-fee-receipt__table--slNo"
                                      align="center">
                                      {i_index + 1}
                                    </TableCell>
                                    <TableCell className="student-fee-receipt__table--desc">
                                      {v_item.acct_ldgr_details?.ldgr_desc}
                                    </TableCell>

                                    <TableCell
                                      id="td-right"
                                      className="student-fee-receipt__table--amount">
                                      {format(v_item.fee_ob)}
                                    </TableCell>

                                    <TableCell
                                      id="td-right"
                                      className="student-fee-receipt__table--amount">
                                      {format(v_item.fee_concession)}
                                    </TableCell>
                                    <TableCell
                                      id="td-right"
                                      className="student-fee-receipt__table--amount">
                                      {format(v_item.fee_receivable)}
                                    </TableCell>
                                    <TableCell
                                      id="td-right"
                                      className="student-fee-receipt__table--amount">
                                      {format(v_item.fee_received)}
                                    </TableCell>
                                    <TableCell
                                      className="student-fee-receipt__table--amount editCell"
                                      onClick={() => {
                                        const {
                                          previousElement,
                                          elementIndex,
                                        } = getElementFromMultipleBooksArray(
                                          v_item.id!
                                        );

                                        if (
                                          (strictlyCollectStdFeeInOrder &&
                                            previousElement &&
                                            previousElement.rcpt_amt! > 0) ||
                                          strictlyCollectStdFeeInOrder ===
                                            false ||
                                          elementIndex === 0
                                        ) {
                                          if (enablePartialStdReceiptPayment) {
                                            if (receivedAmount > 0) {
                                              if (v_item.rcpt_amt > 0) {
                                                onEdit(
                                                  v_item.id!,
                                                  v_item.rcpt_amt
                                                );
                                              }
                                              if (v_item.rcpt_amt === 0) {
                                                onEdit(v_item.id!, 0);
                                              }
                                            }
                                          } else {
                                            if (v_item.rcpt_amt > 0) {
                                              onEdit(
                                                v_item.id!,
                                                v_item.rcpt_amt
                                              );
                                            }
                                            if (v_item.rcpt_amt === 0) {
                                              onEdit(v_item.id!, 0);
                                            }
                                          }
                                        } else {
                                          if (
                                            strictlyCollectStdFeeInOrder &&
                                            previousElement &&
                                            previousElement.rcpt_amt === 0
                                          ) {
                                            setMessage({
                                              flag: true,
                                              message: `Amounts should be paid in order`,
                                              operation: Operation.NONE,
                                            });
                                          }
                                        }
                                      }}
                                      id="td-right">
                                      {inEditMode.status &&
                                      inEditMode.rowKey === v_item.id ? (
                                        <input
                                          autoFocus
                                          type="number"
                                          onBlur={() => {
                                            setInEditMode({
                                              rowKey: -1,
                                              status: false,
                                            });
                                          }}
                                          value={UpdatedReceviedAmount}
                                          onFocus={(e) => e.target.select()}
                                          onChange={(
                                            e: React.ChangeEvent<HTMLInputElement>
                                          ) => {
                                            if (Number(e.target.value) >= 0) {
                                              setUpdatedReceviedAmount(
                                                Number(e.target.value)!
                                              );
                                            }
                                          }}
                                          onKeyDown={(
                                            event: React.KeyboardEvent
                                          ) => {
                                            if (event.key === Keys.ENTER) {
                                              const {
                                                previousElement,
                                                nextElement,
                                                elementIndex,
                                                length,
                                              } =
                                                getElementFromMultipleBooksArray(
                                                  v_item.id!
                                                );
                                              if (
                                                (strictlyCollectStdFeeInOrder &&
                                                  previousElement &&
                                                  previousElement.rcpt_amt >
                                                    0) ||
                                                strictlyCollectStdFeeInOrder ===
                                                  false ||
                                                elementIndex === 0
                                              ) {
                                                if (
                                                  strictlyCollectStdFeeInOrder &&
                                                  elementIndex < items.length &&
                                                  nextElement &&
                                                  nextElement.rcpt_amt > 0 &&
                                                  UpdatedReceviedAmount === 0
                                                ) {
                                                  setMessage({
                                                    flag: true,
                                                    message: `Receipt Amount cannot be zero since ${nextElement.acct_ldgr_details.ldgr_desc} has receipt amount`,
                                                    operation: Operation.NONE,
                                                  });
                                                  return;
                                                }

                                                if (
                                                  (enablePartialStdReceiptPayment ===
                                                    false &&
                                                    v_item.initialBalance ===
                                                      UpdatedReceviedAmount) ||
                                                  enablePartialStdReceiptPayment ||
                                                  UpdatedReceviedAmount === 0
                                                ) {
                                                  if (nextElement) {
                                                    setUpdatedReceviedAmount(
                                                      nextElement.rcpt_amt
                                                    );
                                                    onEdit(
                                                      nextElement.id!,
                                                      nextElement.rcpt_amt
                                                    );
                                                    HandleEditItem(v_item.id!);
                                                  } else {
                                                    HandleEditItem(v_item.id!);
                                                    modeOfTransactionInputRef?.focus();
                                                  }
                                                } else {
                                                  if (
                                                    v_item.initialBalance >
                                                    UpdatedReceviedAmount
                                                  ) {
                                                    setMessage({
                                                      flag: true,
                                                      message:
                                                        "Partial Amount cannot be received.",
                                                      operation: Operation.NONE,
                                                    });
                                                  } else {
                                                    setMessage({
                                                      flag: true,
                                                      message:
                                                        "Entered amount exceeds fee balance amount.",
                                                      operation: Operation.NONE,
                                                    });
                                                  }
                                                }
                                              } else {
                                                if (
                                                  strictlyCollectStdFeeInOrder &&
                                                  previousElement?.rcpt_amt! ===
                                                    0 &&
                                                  UpdatedReceviedAmount > 0
                                                ) {
                                                  setMessage({
                                                    flag: true,
                                                    message: `Amounts should be paid in order`,
                                                    operation: Operation.NONE,
                                                  });
                                                } else {
                                                  if (
                                                    nextElement &&
                                                    nextElement.id
                                                  ) {
                                                    onEdit(
                                                      nextElement.id,
                                                      nextElement.rcpt_amt
                                                    );
                                                  } else if (
                                                    length - 1 ===
                                                    elementIndex
                                                  ) {
                                                    if (
                                                      selectedDepositLedger !==
                                                      null
                                                    ) {
                                                      setInEditMode({
                                                        rowKey: -1,
                                                        status: false,
                                                      });
                                                    } else if (
                                                      modeOfTransactionInputRef
                                                    ) {
                                                      modeOfTransactionInputRef.focus();
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                        />
                                      ) : (
                                        format(v_item.rcpt_amt)
                                      )}
                                    </TableCell>
                                    <TableCell
                                      id="td-right"
                                      className="balance-amount student-fee-receipt__table--amount">
                                      {format(
                                        v_item.fee_ob -
                                          v_item.rcpt_amt -
                                          v_item.fee_received
                                      )}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </React.Fragment>
                        );
                      })
                    : items.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            <TableRow
                              className={
                                item.legederType === LedgerType.DEPOSIT
                                  ? "deposit"
                                  : ""
                              }>
                              <TableCell
                                className="student-fee-receipt__table--slNo"
                                align="center">
                                {index + 1}
                              </TableCell>
                              <TableCell className="student-fee-receipt__table--desc">
                                {item.acct_ldgr_details.ldgr_desc}
                              </TableCell>
                              <TableCell
                                id="td-right"
                                className="student-fee-receipt__table--amount">
                                {format(item.fee_ob)}
                              </TableCell>
                              <TableCell
                                id="td-right"
                                className="student-fee-receipt__table--amount">
                                {format(item.fee_concession)}
                              </TableCell>
                              <TableCell
                                id="td-right"
                                className="student-fee-receipt__table--amount">
                                {format(item.fee_receivable)}
                              </TableCell>
                              <TableCell
                                id="td-right"
                                className="student-fee-receipt__table--amount">
                                {format(item.fee_received)}
                              </TableCell>
                              <TableCell
                                className="student-fee-receipt__table--amount editCell"
                                onClick={() => {
                                  if (
                                    (strictlyCollectStdFeeInOrder &&
                                      index - 1 >= 0 &&
                                      items[index - 1]?.rcpt_amt! > 0) ||
                                    strictlyCollectStdFeeInOrder === false ||
                                    index === 0
                                  ) {
                                    if (receivedAmount > 0) {
                                      if (item.rcpt_amt > 0) {
                                        onEdit(item.id!, item.rcpt_amt);
                                      }
                                      if (item.rcpt_amt === 0) {
                                        onEdit(item.id!, 0);
                                      }
                                    }
                                  } else {
                                    if (
                                      strictlyCollectStdFeeInOrder &&
                                      index - 1 >= 0 &&
                                      items[index - 1]?.rcpt_amt === 0
                                    ) {
                                      setMessage({
                                        flag: true,
                                        message: `Amounts should be paid in order`,
                                        operation: Operation.NONE,
                                      });
                                    }
                                  }
                                }}
                                id="td-right">
                                {inEditMode.status &&
                                inEditMode.rowKey === item.id ? (
                                  <input
                                    autoFocus
                                    type="number"
                                    value={UpdatedReceviedAmount}
                                    onFocus={(e) => e.target.select()}
                                    onBlur={() => {
                                      setInEditMode({
                                        rowKey: -1,
                                        status: false,
                                      });
                                    }}
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      if (Number(e.target.value) >= 0) {
                                        setUpdatedReceviedAmount(
                                          Number(e.target.value)!
                                        );
                                      }
                                    }}
                                    onKeyDown={(event: React.KeyboardEvent) => {
                                      if (event.key === Keys.ENTER) {
                                        if (
                                          (strictlyCollectStdFeeInOrder &&
                                            index - 1 >= 0 &&
                                            items[index - 1]?.rcpt_amt! > 0) ||
                                          strictlyCollectStdFeeInOrder ===
                                            false ||
                                          index === 0
                                        ) {
                                          if (
                                            strictlyCollectStdFeeInOrder &&
                                            index + 1 < items.length &&
                                            items[index + 1].rcpt_amt > 0 &&
                                            UpdatedReceviedAmount === 0
                                          ) {
                                            setMessage({
                                              flag: true,
                                              message: `Receipt Amount cannot be zero since ${
                                                items[index + 1]
                                                  .acct_ldgr_details.ldgr_desc
                                              } has receipt amount`,
                                              operation: Operation.NONE,
                                            });
                                            return;
                                          }

                                          if (
                                            enablePartialStdReceiptPayment ===
                                              false &&
                                            UpdatedReceviedAmount !==
                                              item.initialBalance &&
                                            UpdatedReceviedAmount !== 0
                                          ) {
                                            setMessage({
                                              flag: true,
                                              message:
                                                UpdatedReceviedAmount >
                                                item.initialBalance
                                                  ? "Entered Receipt Amount is Greater than the balance"
                                                  : "Partial Amount cannot be taken",
                                              operation: Operation.NONE,
                                            });
                                            return;
                                          } else if (
                                            items[index + 1] &&
                                            items[index + 1].id
                                          ) {
                                            setUpdatedReceviedAmount(
                                              items[index + 1].rcpt_amt
                                            );
                                            onEdit(
                                              items[index + 1].id!,
                                              items[index + 1].rcpt_amt
                                            );
                                            HandleEditItem(item.id!);
                                          } else {
                                            onEdit(item.id!, item.rcpt_amt);
                                            HandleEditItem(item.id!);
                                            if (
                                              selectedDepositLedger !== null
                                            ) {
                                              setInEditMode({
                                                rowKey: -1,
                                                status: false,
                                              });
                                            } else if (
                                              modeOfTransactionInputRef
                                            )
                                              modeOfTransactionInputRef?.focus();
                                          }
                                        } else {
                                          if (index === 0) {
                                            if (
                                              (enablePartialStdReceiptPayment ===
                                                false &&
                                                UpdatedReceviedAmount !==
                                                  item.initialBalance &&
                                                UpdatedReceviedAmount !== 0) ===
                                              false
                                            ) {
                                              HandleEditItem(item.id!);
                                              setInEditMode({
                                                rowKey: -1,
                                                status: false,
                                              });
                                              if (UpdatedReceviedAmount > 0) {
                                                setUpdatedReceviedAmount(0);

                                                setInEditMode({
                                                  rowKey: items[index + 1].id!,
                                                  status: true,
                                                });
                                              }
                                            } else {
                                              setMessage({
                                                flag: true,
                                                message:
                                                  UpdatedReceviedAmount >
                                                  item.initialBalance
                                                    ? "Entered Receipt Amount is Greater than the balance"
                                                    : "Partial Amount cannot be taken",
                                                operation: Operation.NONE,
                                              });
                                            }
                                          }
                                          if (
                                            strictlyCollectStdFeeInOrder &&
                                            index - 1 >= 0 &&
                                            items[index - 1]?.rcpt_amt === 0 &&
                                            UpdatedReceviedAmount > 0
                                          ) {
                                            setMessage({
                                              flag: true,
                                              message: `Amounts should be paid in order`,
                                              operation: Operation.NONE,
                                            });
                                          } else {
                                            if (
                                              items.length > index + 1 &&
                                              items[index + 1]
                                            ) {
                                              onEdit(
                                                items[index + 1].id!,
                                                items[index + 1].rcpt_amt
                                              );
                                            }
                                            if (items.length === index + 1) {
                                              if (
                                                selectedDepositLedger !== null
                                              ) {
                                                setInEditMode({
                                                  rowKey: -1,
                                                  status: false,
                                                });
                                              } else if (
                                                modeOfTransactionInputRef
                                              ) {
                                                modeOfTransactionInputRef.focus();
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }}
                                  />
                                ) : (
                                  format(item.rcpt_amt)
                                )}
                              </TableCell>
                              <TableCell
                                id="td-right"
                                className="balance-amount student-fee-receipt__table--amount">
                                {format(
                                  item.fee_ob -
                                    item.rcpt_amt -
                                    item.fee_received
                                )}
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        );
                      })}
                </TableBody>

                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={6} className="total">
                      Academic Fee Total :
                    </TableCell>
                    <TableCell
                      id="td-right"
                      className="totalcount student-fee-receipt__table__borderbottom">
                      {format(totals.totalacademicAmount + depositLedgerAmount)}
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  {enableFeeFine && (
                    <TableRow>
                      <TableCell colSpan={7} className="total">
                        Fine :
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className="balance-count student-fee-receipt__table__borderbottom">
                        {format(enableFeeFine ? totalFineAmt : 0)}
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell colSpan={2} className="total">
                      Total :
                    </TableCell>
                    <TableCell id="td-right" className="totalcount">
                      {format(totals.totalOutStandingBalance)}
                    </TableCell>
                    <TableCell id="td-right" className="totalcount">
                      {format(totals.totalconcession)}
                    </TableCell>
                    <TableCell id="td-right" className="totalcount">
                      {format(totals.totalPayable)}
                    </TableCell>
                    <TableCell id="td-right" className="totalcount">
                      {format(totals.totalpaidAmount)}
                    </TableCell>
                    {enableFeeFine ? (
                      <TableCell id="td-right" className="totalcount">
                        {format(
                          totals.totalacademicAmount +
                            (enableFeeFine ? totalFineAmt : 0) +
                            depositLedgerAmount
                        )}
                      </TableCell>
                    ) : (
                      <TableCell></TableCell>
                    )}
                    <TableCell id="td-right" className="balance-count">
                      {format(totals.totalbalance)}
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
          {payer === PayerType.ACCOUNTING && (
            <form
              className={"row g-0 student-fee-receipt__receiptblock--modal"}>
              <div className="col student-fee-receipt__receiptblock--frame">
                <div className="label-grid">
                  <Label>Receipt No.</Label>
                  <Input
                    disabled
                    value={
                      state.studentId
                        ? generatedVoucherNumbers
                            .map((number) => number?.vo_number)
                            .join(", ") ||
                          voucherNumber.data?.GetVoucherNumber.vo_number ||
                          EMPTY_STRING
                        : EMPTY_STRING
                    }
                  />
                  <Label> Mode of Transaction</Label>
                  <RequiredAutoComplete
                    className={formClasses.inputRoot}
                    options={ledgerOptions.filter(
                      ({ value }) =>
                        (bankLedgerExists === false &&
                          value !== BankOrCash.BANK) ||
                        bankLedgerExists
                    )}
                    openOnFocus
                    isOptionEqualToValue={(option) =>
                      isOptionEqualToValue(option as optionsType, ledgerType)
                    }
                    ref={modeOfTransactionRef}
                    autoHighlight
                    value={ledgerType}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setLedgerType(newValue as optionsType);
                        setTransactionLedgerId(null);
                        if (
                          (newValue as optionsType).value === BankOrCash.BANK
                        ) {
                          setBankAccount(true);
                          setCashAccount(false);
                        } else {
                          setCashAccount(true);
                          setBankAccount(false);
                        }
                      } else {
                        setLedgerType(null);
                      }
                    }}
                    onKeyDown={(e: React.KeyboardEvent) => {
                      if (e.key === Keys.ENTER && ledgerType) {
                        handleMUISelectEvent(e);
                      }
                      if (e.key === Keys.BACKSPACE) {
                        setLedgerType(null);
                      }
                    }}
                    popupIcon={<img src={DownArrow} alt="/" />}
                    forcePopupIcon
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        className={formClasses.formControlRoot}
                      />
                    )}
                  />
                </div>
              </div>
              {cashAccount ? (
                <div className="col student-fee-receipt__receiptblock--frame label-grid">
                  <Label> Cash Ledgers</Label>
                  <FormAutocomplete
                    className={formClasses.inputRoot}
                    options={CashLedgers.responseType!}
                    openOnFocus
                    autoHighlight
                    isOptionEqualToValue={(option) =>
                      isOptionEqualToValue(
                        option as responseType,
                        transactionBookLedgerId
                      )
                    }
                    value={transactionBookLedgerId}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setTransactionLedgerId(newValue as responseType);
                        setBankTransactionType(null);
                        setReceipts([]);
                      } else {
                        setTransactionLedgerId(null);
                        setReceipts([]);
                      }
                    }}
                    onKeyDown={(e: React.KeyboardEvent) => {
                      if (transactionBookLedgerId && e.key === Keys.ENTER) {
                        handleMUISelectEvent(e);
                      }
                      if (e.key === Keys.BACKSPACE) {
                        setTransactionLedgerId(null);
                      }
                    }}
                    popupIcon={<img src={DownArrow} alt="/" />}
                    forcePopupIcon
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        onChange={(e) => setSearchData(e.target.value)}
                        className={formClasses.formControlRoot}
                      />
                    )}
                  />
                </div>
              ) : null}
              {bankAccount ? (
                <div className="col student-fee-receipt__receiptblock--frame">
                  <div className="student-fee-receipt__receiptblock--frame--bankdetails">
                    <Label>Bank Transaction Type</Label>
                    <FormAutocomplete
                      className={formClasses.inputRoot}
                      options={bankTransactionTypes!}
                      openOnFocus
                      autoHighlight
                      value={bankTransactionType}
                      isOptionEqualToValue={(option) =>
                        isOptionEqualToValue(
                          option as optionsType,
                          bankTransactionType
                        )
                      }
                      onChange={(e, newValue) => {
                        if (newValue) {
                          setBankTransactionType(newValue as optionsType);
                        } else {
                          setBankTransactionType(null);
                        }
                      }}
                      onKeyDown={(e: React.KeyboardEvent) => {
                        if (ledgerType && e.key === Keys.ENTER) {
                          handleMUISelectEvent(e);
                        }
                        if (e.key === Keys.BACKSPACE) {
                          setBankTransactionType(null);
                        }
                      }}
                      popupIcon={<img src={DownArrow} alt="/" />}
                      forcePopupIcon
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          className={formClasses.formControlRoot}
                        />
                      )}
                    />
                  </div>
                  <div className="student-fee-receipt__receiptblock--frame--bankdetails">
                    <Label> Bank Ledgers</Label>
                    <FormAutocomplete
                      className={formClasses.inputRoot}
                      options={BankLedgers.responseType!}
                      openOnFocus
                      autoHighlight
                      value={transactionBookLedgerId}
                      isOptionEqualToValue={(option) =>
                        isOptionEqualToValue(
                          option as responseType,
                          transactionBookLedgerId
                        )
                      }
                      onChange={(e, newValue) => {
                        if (newValue) {
                          setTransactionLedgerId(newValue as responseType);
                          setReceipts([]);
                        } else {
                          setTransactionLedgerId(null);
                          setReceipts([]);
                        }
                      }}
                      onKeyDown={(e: React.KeyboardEvent) => {
                        if (transactionBookLedgerId && e.key === Keys.ENTER) {
                          handleMUISelectEvent(e);
                        }
                        if (e.key === Keys.BACKSPACE) {
                          setTransactionLedgerId(null);
                        }
                      }}
                      popupIcon={<img src={DownArrow} alt="/" />}
                      forcePopupIcon
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          onChange={(e) => setSearchData(e.target.value)}
                          className={formClasses.formControlRoot}
                        />
                      )}
                    />
                  </div>
                  <div className="student-fee-receipt__receiptblock--frame--bankdetails">
                    <Label>Transaction Reference Number</Label>
                    <Input
                      required
                      onKeyDown={(e: React.KeyboardEvent) => {
                        if (e.key === Keys.ENTER) {
                          handleMUISelectEvent(e);
                        }
                      }}
                      value={referenceNumber}
                      type="text"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setReferenceNumber(e.target.value)
                      }
                    />
                  </div>
                </div>
              ) : null}
              <div className="col student-fee-receipt__receiptblock--frame label-grid">
                <Label>Remarks :</Label>
                <TextArea
                  rows={3}
                  textAreaRef={remarksRef}
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (e.key === Keys.ENTER) {
                      saveButtonRef.current?.focus();
                    }
                  }}
                  value={narration}
                  onChange={(e) => setNarration(e.target.value)}
                />
              </div>
            </form>
          )}
        </div>
      </div>

      <div className={"row g-0 student-fee-receipt__buttons--modal"}>
        <div className="col">
          {payer === PayerType.ACCOUNTING && (
            <>
              <Button
                mode="save"
                buttonref={saveButtonRef}
                disabled={totals.totalacademicAmount > 0 ? false : true}
                onClick={handleStudentReceipts}
              />

              {enableFeeFine && (
                <Button
                  mode="waive-off"
                  onClick={() => {
                    setEnableWaiveOff(!enableWaiveOff);
                  }}
                  disabled={
                    !state.studentId ||
                    totalFineAmt === 0 ||
                    totalFineAmt < waiveOffAmtMemoized
                  }
                />
              )}
              <Button
                mode="clear"
                onClick={handleClear}
                disabled={!state.studentId}
              />
              <Button
                mode="cancel"
                type="button"
                onClick={() => {
                  dispatch({
                    type: payloadTypes.SET_STUDENT_ID,
                    payload: { studentId: 0 },
                  });
                  handleClose();
                }}
              />
            </>
          )}
          {enablePaymentGateway && state.claims?.STUDENT ? (
            <div>{component}</div>
          ) : null}
        </div>
      </div>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={prinConfigModal}
        style={PrintConfigModalStyles}
        ariaHideApp={false}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Configurations
              config_query_type={SwConfigQueryType.INST_BY_MODULE}
              str_value={ModuleName.RECEIPT_PRINT}
              int_value={0}
              setModalFlag={setPrintConfigModal}
              pageType={PageFor.MODAL}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setPrintConfigModal(!prinConfigModal)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={printModal}
        style={PrintModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Print
              tableData={
                (waivedOff ? totalFineAmt - waiveOffAmt : totalFineAmt) > 0 &&
                enableFeeFine
                  ? [
                      ...tableData,
                      {
                        amount: waivedOff
                          ? totalFineAmt - waiveOffAmt
                          : totalFineAmt,
                        particular: "Fee Fine",
                      },
                    ]
                  : [...tableData]
              }
              v_date={v_date}
              v_no={v_no}
              transaction_no={referenceNumber}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => {
                setWaiveOffAmt(0);
                if (enableDefaultPrintWhilesaved) SetPrintModal(!printModal);
                setModalFlag(false);
                handleClear();
              }}
            />
            {isAdmin ? (
              <>
                <img
                  src={Settings}
                  alt="/"
                  id="settings-icon"
                  onClick={() => setPrintConfigModal(!prinConfigModal)}
                />
              </>
            ) : null}
          </div>
        </div>
      </Modal>
      <MessageModal
        handleClose={() => setMessage(emptyMessageType)}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default ObReceipt;
