import React, { useContext, useRef, useState } from "react";
import {
  BankOrCash,
  DebitOrCredit,
  Operation,
  PageFor,
  ReceiptTypes,
  StudentAcctReportType,
  VoucherBookKey,
} from "../../../../utils/Enum.types";
import { Label } from "../../../../stories/Label/Label";
import Input from "../../../../stories/Input/Input";
import {
  DEFAULT_TIME,
  EMPTY_STRING,
  INPUT,
  TODAY_DATE,
} from "../../../../utils/constants";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";
import {
  GridAlignment,
  GridColDef,
  GridRenderCellParams,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { Checkbox, TextField } from "@mui/material";
import DownArrow from "../../../../images/DownArrow.svg";
import useAcctTableJson from "../../json/useAcctTableJson";
import {
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../../styles/DataGridTableStyles";
import { Button } from "../../../../stories/Button/Button";
import {
  FormAutocomplete,
  formClasses,
} from "../../../../styles/AutocompleteStyles";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import { msgType, responseType } from "../../../../utils/Form.types";
import useInstMasterDataByInstId from "../../../../customhooks/useInstMasterDataByInstId";
import { Keys } from "../../../../utils/Enum.keys";
import useDropdownData from "../../../../customhooks/useDropdownData";
import {
  AcctLedgerQueryType,
  DepositLedgerQueryType,
} from "../../common/QueryTypes";
import useAcctLedgerData from "../../hooks/useAcctLedgerData";
import {
  isOptionEqualToValue,
  toInputStandardDate,
} from "../../../../utils/UtilFunctions";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import dayjs from "dayjs";
import { AddAcctVoucherNonDemandAdjFromDeposit } from "../../queries/receipts/mutation/Index";
import useToken from "../../../../customhooks/useToken";
import { useNavigate, useParams } from "react-router-dom";
import useLoggedInUserDetails from "../../hooks/useLoggedInUserDetails";
import { useMutation } from "@apollo/client";
import { AppContext } from "../../../../context/context";
import useVoucherNumber from "../../hooks/useVoucherNumber";
import MessageModal from "../../../../pages/MessageModal";
import { Title } from "../../../../stories/Title/Title";
import useStudentDepositDetails from "../../hooks/useStudentDepositDetails";
interface Props {
  pageType: PageFor;
  setDepositAdjustmentGroupModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const GroupAdjustDepositFee = ({
  pageType,
  setDepositAdjustmentGroupModal,
}: Props) => {
  const { USE_CATEGORY_KEY } = useInstitutionConfiguration();
  const [searchLdgrData, setsearchLdgrData] = useState("");
  const { acctLedgers: acctLedgersData } = useAcctLedgerData(
    searchLdgrData,
    AcctLedgerQueryType.DEPOSIT_FEE_LDGRS,
    null
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { state } = useContext(AppContext);
  const naviagte = useNavigate();
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [searchData, setsearchData] = useState("");
  const { categoryDropDown } = useInstMasterDataByInstId();
  const { acctLedgers } = useAcctLedgerData(
    searchLdgrData,
    AcctLedgerQueryType.STD_FEE_ACCT_LDGRS_WITHOUT_DEPOSITS,
    null
  );
  const [voucherDate, setVoucherDate] = useState(
    toInputStandardDate(TODAY_DATE)
  );
  const [feeLedgerSelected, setFeeLedgerSelected] =
    useState<responseType | null>(null);
  const [amount, setAmount] = useState("");
  const { token } = useToken();
  const { InstId } = useParams();
  const { user_details } = useLoggedInUserDetails();
  const [checkedStdIds, setCheckedStdIds] = useState<string[]>([]);

  const handleCheckboxChange = (stdId: string, isChecked: boolean) => {
    setCheckedStdIds((prev) =>
      isChecked ? [...prev, stdId] : prev.filter((id) => id !== stdId)
    );
  };

  const handleSelectAll = (isChecked: boolean, rows: GridValidRowModel[]) => {
    if (isChecked) {
      setCheckedStdIds(rows.map((row) => row.stdId));
    } else {
      setCheckedStdIds([]);
    }
  };
  const amountRef = useRef<HTMLInputElement>(null);
  const [feeDepositSelected, setFeeDepositSelected] =
    useState<responseType | null>(null);
  const classRef = useRef<HTMLSelectElement>(null);
  const classInputRef = classRef.current?.getElementsByTagName(
    INPUT
  )[0] as HTMLInputElement;
  const categoryRef = useRef<HTMLSelectElement>(null);
  const categoryInputRef = categoryRef.current?.getElementsByTagName(
    INPUT
  )[0] as HTMLInputElement;
  const feeDepositRef = useRef<HTMLSelectElement>(null);
  const feeDepositInputRef = feeDepositRef.current?.getElementsByTagName(
    INPUT
  )[0] as HTMLInputElement;
  const accountLedgerRef = useRef<HTMLSelectElement>(null);
  const accountLedgerRefInputRef =
    accountLedgerRef.current?.getElementsByTagName(
      INPUT
    )[0] as HTMLInputElement;
  const { branchDropDown } = useInstMasterDataByInstId();

  const [branchId, setBranchId] = useState<responseType | null>(null);
  const [classId, setClassId] = useState<responseType | null>(null);

  const [categoryId, setCategoryId] = useState<responseType | null>(null);
  const { classDropDown } = useDropdownData(
    0,
    branchId ? branchId.value : 0,
    0,
    0
  );
  const depositFeeLedgersDropdown =
    acctLedgersData && acctLedgersData.responseType;
  const acctFeeLedgersDropdown = acctLedgers && acctLedgers.responseType;
  // const { students: StudentsData } = useStudentsbyDemandAmount(
  //   0,
  //   branchId ? branchId.value : 0,
  //   classId ? classId.value : 0,
  //   0,
  //   0,
  //   categoryId ? categoryId.value : 0,
  //   searchData,
  //   null,
  //   StudentAcctReportType.DEPOSIT_BAL_GT_REQUESTED_AMT,
  //   feeDepositSelected ? feeDepositSelected.value : 0,
  //   EMPTY_STRING,
  //   null,
  //   null,
  //   amount
  // );
  const { StudentDepositData } = useStudentDepositDetails(
    DepositLedgerQueryType.DEPOSIT_BAL_GT_REQUESTED_AMT,
    searchData,
    EMPTY_STRING,
    0,
    branchId ? branchId.value : 0,
    classId ? classId.value : 0,
    0,
    0,
    categoryId ? categoryId.value : 0,
    EMPTY_STRING,
    amount,
    feeDepositSelected ? feeDepositSelected.value : 0
  );

  const [AddAcctVoucher] = useMutation(AddAcctVoucherNonDemandAdjFromDeposit, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const { voucherNumber } = useVoucherNumber(
    VoucherBookKey.STUDENT_COMMAN_RECEIPT_BOOK
  );
  const HandleSubmit = () => {
    setIsSubmitting(true);
    AddAcctVoucher({
      variables: {
        token,
        inst_id: InstId!,
        user_details,
        fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
        input: {
          acct_voucher_master: {
            fin_yr: state.ActiveFinYr ? state.ActiveFinYr.fin_yr : EMPTY_STRING,
            v_type: ReceiptTypes.STUDENT_RECEIPT,
            v_no: voucherNumber.data?.GetVoucherNumber.vo_number,
            v_date: dayjs(voucherDate).format(),
            v_total_cr_amt: amount,
            v_total_db_amt: amount,
            v_reconciled: false,
            v_reconciled_date: dayjs(DEFAULT_TIME).format(),
            v_book_type: VoucherBookKey.STUDENT_COMMAN_RECEIPT_BOOK,
            student_id: state.studentId,
            class_id: classId ? classId.value : 0,
            semester_id: 0,
            v_std_amt_receipt: amount,
            v_std_amt_deposit: amount,
            v_std_amt_fine: 0,
            v_std_amt_total: amount,
            v_std_amt_refunded: 0,
            v_std_amt_adjusted: 0,
            v_transcation_type: `Adjusted from ${feeDepositSelected?.label}`,
            v_transcation_cash_or_bank: BankOrCash.ADJ,
            v_transcation_no: "",
            v_transcation_date: dayjs(TODAY_DATE).format(),
            v_transcation_narration: EMPTY_STRING,
            party_bill_date: dayjs(TODAY_DATE).format(),
            acct_ldgr_id_cr: feeLedgerSelected ? feeLedgerSelected.value : 0,
            acct_ldgr_id_db: feeDepositSelected ? feeDepositSelected.value : 0,
          },

          acct_voucher_db: [
            {
              vo_cr_db: DebitOrCredit.DEBIT,
              vo_sl_no: 1,
              vo_cr: 0,
              vo_db: amount,
              vo_cr_total: 0,
              vo_db_total: amount,
              acct_ldgr_id: feeDepositSelected ? feeDepositSelected.value : 0,
            },
          ],
          acct_voucher_cr: [
            {
              vo_sl_no: 1,
              vo_cr_db: DebitOrCredit.CREDIT,
              vo_cr: amount,
              vo_db: 0,
              vo_cr_total: amount,
              vo_db_total: 0,
              acct_ldgr_id: feeLedgerSelected ? feeLedgerSelected.value : 0,
            },
          ],
        },
        stdIds: checkedStdIds.map((Res) => Res),
      },
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Successfully Adjusted Deposit Fee to Non Demand Ledger",
          operation: Operation.CREATE,
        });
      }
    });
  };

  const { Accounts_Table } = useAcctTableJson();
  const dynamicHeaders: GridColDef[] =
    Accounts_Table.Receipts.GroupAdjustDepositFee.Table_Headers?.map(
      (header) => ({
        headerName: header.headerName,
        className: header.cellClassName,
        field: header.field,
        headerAlign: header.headerAlign as GridAlignment,
        align: header.align as GridAlignment,
        flex: header.flex,
        hideable: header.hideable,
        renderCell: (params: GridRenderCellParams) => {
          if (header.field === "id") {
            return (
              <div className="group-adjust-fee__details--table--slno">
                <Checkbox
                  checked={checkedStdIds.includes(params.row.stdId)}
                  onChange={(e) =>
                    handleCheckboxChange(params.row.stdId, e.target.checked)
                  }
                />
                {params.row.id}
              </div>
            );
          }
        },
        renderHeader: (params) => {
          if (header.field === "id") {
            return (
              <div className="group-adjust-fee__details--table--slno">
                <Checkbox
                  onChange={(e) => handleSelectAll(e.target.checked, rows)}
                />
              </div>
            );
          }
        },
      })
    );

  const columns: GridColDef[] = [...dynamicHeaders];

  const rows: GridValidRowModel[] = (
    StudentDepositData.data?.GetAcctStdDeposits.edges || []
  ).map((data, index) => ({
    id: index + 1,
    admission_no: data.node.student_details.std_adm_no,
    register_no: data.node.student_details.std_reg_no,
    student_name:
      data.node.student_details.first_name +
      " " +
      data.node.student_details.middle_name +
      " " +
      data.node.student_details.last_name,
    total_deposit: data.node.deposit_bal,
    adjust_amount: amount,
    deposit_balance: data.node.deposit_bal - Number(amount),
    stdId: data.node.student_details.id,
  }));
  const { branchLabel, classLabel, categoryLabel } = useInstLabels();
  const handleClear = () => {
    setFeeLedgerSelected(null);
    setAmount("");
    setFeeDepositSelected(null);
    setBranchId(null);
    setClassId(null);
    setCategoryId(null);
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
      setIsSubmitting(false);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  return (
    <>
      {pageType === PageFor.MODAL && (
        <Title>Adjustment (Non Demand) Group</Title>
      )}
      <div
        className={`adjust-deposit-fee__group${
          pageType === PageFor.GENERAL ? "" : "--modal"
        }`}>
        <div className="row g-0 adjust-deposit-fee__group--details">
          <div className="col adjust-deposit-fee__group--details--frames">
            <div className="adjust-deposit-fee__group--details--frames--label-grid">
              <Label>{branchLabel}</Label>
              <FormAutocomplete
                options={branchDropDown}
                className={formClasses.inputRoot}
                openOnFocus
                freeSolo
                autoHighlight
                popupIcon={<img src={DownArrow} alt="/" />}
                forcePopupIcon
                value={branchId}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (branchId && branchId.value && e.key === Keys.ENTER) {
                    if (classRef.current) {
                      classInputRef.focus();
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setBranchId(null);
                  }
                }}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setBranchId(newValue as responseType);
                  } else {
                    setBranchId(null);
                  }
                  setClassId(null);
                  setCategoryId(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    autoFocus
                    fullWidth
                    className={formClasses.formControlRoot}
                  />
                )}
              />
            </div>
            <div className="adjust-deposit-fee__group--details--frames--label-grid">
              <Label>{classLabel}</Label>
              <FormAutocomplete
                options={classDropDown}
                className={formClasses.inputRoot}
                freeSolo
                openOnFocus
                ref={classRef}
                value={classId || null}
                autoHighlight
                popupIcon={<img src={DownArrow} alt="/" />}
                forcePopupIcon
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === Keys.ENTER && classId ? classId.value : 0) {
                    if (categoryRef.current) {
                      categoryInputRef.focus();
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setClassId(null);
                  }
                }}
                clearOnEscape
                onChange={(e, newValue) => {
                  if (newValue) {
                    setClassId((newValue as responseType) || null);
                  } else {
                    setClassId(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    className={formClasses.formControlRoot}
                  />
                )}
              />
            </div>
            {USE_CATEGORY_KEY ? (
              <div className="adjust-deposit-fee__group--details--frames--label-grid">
                <Label>{categoryLabel}</Label>
                <FormAutocomplete
                  options={categoryDropDown}
                  className={formClasses.inputRoot}
                  freeSolo
                  openOnFocus
                  autoHighlight
                  ref={categoryRef}
                  popupIcon={<img src={DownArrow} alt="/" />}
                  forcePopupIcon
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (e.key === Keys.ENTER && categoryId) {
                      if (feeDepositRef.current) {
                        feeDepositInputRef.focus();
                      }
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setCategoryId(null);
                    }
                  }}
                  value={categoryId}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setCategoryId(newValue as responseType);
                    } else {
                      setCategoryId(null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      className={formClasses.formControlRoot}
                    />
                  )}
                />
              </div>
            ) : null}
          </div>
          <div className="col adjust-deposit-fee__group--details--frames">
            <div className="adjust-deposit-fee__group--details--frames--label-grid">
              <Label>Fee Deposit</Label>
              <FormAutocomplete
                options={depositFeeLedgersDropdown}
                className={formClasses.inputRoot}
                freeSolo
                openOnFocus
                autoHighlight
                ref={feeDepositRef}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === Keys.ENTER && categoryId) {
                    if (amountRef.current) {
                      amountRef.current.focus();
                    }
                  }
                }}
                value={feeDepositSelected}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(
                    option as responseType,
                    feeDepositSelected
                  )
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    setFeeDepositSelected(newValue as responseType);
                  } else {
                    setFeeDepositSelected(null);
                  }
                }}
                popupIcon={<img src={DownArrow} alt="/" />}
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onChange={(e) => setsearchLdgrData(e.target.value)}
                    fullWidth
                    className={formClasses.formControlRoot}
                  />
                )}
              />
            </div>
            <div className="adjust-deposit-fee__group--details--frames--label-grid">
              <Label>Amount</Label>
              <Input
                value={amount}
                inputRef={amountRef}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === Keys.ENTER && categoryId) {
                    if (accountLedgerRef.current) {
                      accountLedgerRefInputRef.focus();
                    }
                  }
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setAmount(e.target.value)
                }
              />
            </div>

            <div className="adjust-deposit-fee__group--details--frames--label-grid">
              <TextField
                type="date"
                value={voucherDate}
                onChange={(e) => setVoucherDate(e.target.value)}
                className="adjust-deposit-fee__individual--details--textfield--date"
                label="Date"
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                  input: {
                    inputProps: {
                      min: state.ActiveFinYr
                        ? toInputStandardDate(state.ActiveFinYr.fin_st_date)
                        : 0,

                      max: toInputStandardDate(TODAY_DATE),
                    },
                  },
                }}
              />

              <LabeledAutocomplete
                options={acctFeeLedgersDropdown}
                className={labelClasses.inputRoot}
                freeSolo
                ref={accountLedgerRef}
                openOnFocus
                autoHighlight
                value={feeLedgerSelected}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(
                    option as responseType,
                    feeLedgerSelected
                  )
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    setFeeLedgerSelected(newValue as responseType);
                  } else {
                    setFeeLedgerSelected(null);
                  }
                }}
                popupIcon={<img src={DownArrow} alt="/" />}
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    label="To Ledger"
                    fullWidth
                    onChange={(e) => setsearchData(e.target.value)}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div
          className={`adjust-deposit-fee__group--details--table${
            pageType === PageFor.GENERAL ? "" : "--modal"
          }`}>
          {amount.length ? (
            <StyledDatagrid
              columns={columns}
              rows={rows}
              rowHeight={TABLE_ROW_HEIGHT}
              hideFooter
            />
          ) : null}
        </div>
        <div className="row g-0">
          <div className="col">
            <Button
              mode="save"
              onClick={HandleSubmit}
              disabled={isSubmitting}
            />

            {pageType === PageFor.GENERAL ? (
              <Button mode="back" onClick={() => naviagte(-1)} />
            ) : (
              <Button
                mode="cancel"
                onClick={() => setDepositAdjustmentGroupModal(false)}
              />
            )}
            <Button
              mode="clear"
              onClick={() => handleClear()}
            />
          </div>
          <div className="col flex-end">
            <div className="student-total-count">
              Selected Students : <b>{checkedStdIds.length}</b> &nbsp; Total
              Students : <b>{rows.length}</b>
            </div>
          </div>
        </div>
      </div>
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default GroupAdjustDepositFee;
