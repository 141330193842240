import React, { useEffect } from "react";

import { Title } from "../../../../stories/Title/Title";
import StudentAttStatus from "./StudentAttStatus";
import ClassWiseAttStatus from "./ClassWiseAttStatus";
import StaffAttStatus from "./StaffAttendance";
import UpcomingTest from "./UpcomingTest";
import TotalStudents from "./TotalStudents";
import DailyDairy from "./DailyDairy";
import BirthdayList from "./BirthdayList";
import {
  InstitutionConfigurationTypes,
  UserType,
} from "../../../../utils/Enum.types";
import useSwConfigData from "../../../../customhooks/useSwConfigData";

const Index = () => {
  const { configData: EnableDailyDiary } = useSwConfigData(
    InstitutionConfigurationTypes.ENABLE_TEACHER_DAILY_DIARY
  );
  const dailyDiaryFlag =
    EnableDailyDiary &&
    EnableDailyDiary.data &&
    EnableDailyDiary.data.GetSwConfigVariables[0].config_boolean_value;
  return (
    <>
      <Title>Academics</Title>
      <div className="acd-admin-dashboard">
        <div className="acd-admin-dashboard__cards">
          <div className="acd-admin-dashboard__card">
            <StudentAttStatus />
          </div>
          <div className="acd-admin-dashboard__card">
            <ClassWiseAttStatus />
          </div>
          <div className="acd-admin-dashboard__top-right">
            <UpcomingTest />
          </div>
          {dailyDiaryFlag && (
            <div className="acd-admin-dashboard__top-right">
              <DailyDairy />
            </div>
          )}
        </div>
        <div className="acd-admin-dashboard__datablock">
          <div className="col-5 acd-admin-dashboard__card">
            {/* <Calendar /> */}
            <BirthdayList userType={UserType.USER} />
          </div>
          {/* <div className="col-3 acd-admin-dashboard__card">
            <StaffAttStatus />
          </div> */}
          <div className="col acd-admin-dashboard__bottom-right">
            {/* <div className="acd-admin-dashboard__bottom-right--top">
              <AttendanceBehaviour />
            </div>
            <div className="acd-admin-dashboard__bottom-right--bottom"> */}
            <TotalStudents />
            {/* </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
