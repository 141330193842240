import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import Modal from "react-modal";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Home from "./Home/Index";
import useAcademicYear from "../Academics/hooks/useActiveAcademicYear";
import { useMutation } from "@apollo/client";
import Close from "../../images/Close.svg";
import {
  Direction,
  Operation,
  SortBy,
  TableHeaders,
} from "../../utils/Enum.types";
import useToken from "../../customhooks/useToken";
import { msgType } from "../../utils/Form.types";
import useLoggedInUserDetails from "../Accounts/hooks/useLoggedInUserDetails";
import {
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../utils/constants";
import {
  EnableEmpLoginStatusWithNewEmail,
  UpdateEmpLoginStatus,
} from "../../queries/students/mutations/new";
import { GetAcdStudents } from "../Academics/queries/students/Index";
import { StudentAcdType } from "../../utils/studentAcdEnum.types";
import {
  getModifiedScrollHeight,
  handleFormEvent,
} from "../../utils/UtilFunctions";
import { Title } from "../../stories/Title/Title";
import Input from "../../stories/Input/Input";
import { Keys } from "../../utils/Enum.keys";
import { Button } from "../../stories/Button/Button";
import StudentTotalCount from "../Master/Student/Components/StudentTotalCount";
import { InstitutionCustomStyles } from "../../styles/ModalStyles";
import { StudentsTitlesType } from "../../Types/Student/json";
import LoadingModal from "../../pages/LoadingModal";
import MessageModal from "../../pages/MessageModal";
import { AntSwitch } from "../../pages/Switch";
import useEmployee, {
  PayRollEmpEdges,
  PrEmpQueryType,
  empQueryTypes,
} from "../HR/hooks/useEmployee";
import { GetPayRollEmp } from "../HR/queries/employee/query";
import { AccountentType } from "../Accounts/common/Enum.types";
import useCheckAllocationType from "../Academics/hooks/useCheckAllocationType";
import {
  GridAlignment,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  HEADER_TEXT_ALIGN,
  MARKS_TEXT_ALIGN,
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../styles/DataGridTableStyles";
import { TableHeaderProps } from "../../utils/types";
import useHRtable from "../HR/json/useHRtable";
const { Students } = require("../../json/title.json");

const EnableUserLogin = () => {
  const navigate = useNavigate();
  const { token } = useToken();
  const { InstId } = useParams();
  const saveRef = useRef<HTMLButtonElement>(null);
  const { HR_Table } = useHRtable();
  const [mobileNo, setMobileNo] = useState("");

  const [employees, setEmployees] = useState<PayRollEmpEdges[]>([]);

  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [searchData, setSearchData] = useState("");
  const [empId, setEmpId] = useState(0);
  const [eMail, setEmail] = useState("");
  const [selectedValue, setSelectedValue] = useState("All Users");
  const [rows, setRows] = useState<GridValidRowModel[]>([]);

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(e.target.value);
  };

  const [emailModal, setEmailModal] = useState(false);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const { flag } = useCheckAllocationType();
  const { user_details } = useLoggedInUserDetails();
  const {
    empDetails: { data, loading: EmpLoading, fetchMore },
  } = useEmployee(
    ROWS_PER_PAGE,
    selectedValue === "All Users"
      ? empQueryTypes.JUST_LOGIN_ACCTS
      : empQueryTypes.EMP_BY_TYPE,
    searchData,
    0,
    0,
    0,
    0,
    0,
    0,
    selectedValue === "Accounts User"
      ? [
          AccountentType.ACCOUNTANT,
          AccountentType.CASHIER,
          AccountentType.CLERK,
        ]
      : [AccountentType.TEACHER]
  );

  const [EnableLogin, { loading: EnableLoading }] = useMutation(
    UpdateEmpLoginStatus,
    {
      onError: (e) => {
        setEmailModal(!emailModal);
      },
    }
  );
  const [UpdateEmail, { loading }] = useMutation(
    EnableEmpLoginStatusWithNewEmail,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const handleNumberChange = (mobileNo: string) => {
    setMobileNo(mobileNo);
  };

  const { activeAcademicYearData } = useAcademicYear();

  const clear = () => {
    setSearchData("");
  };

  const empData = data?.GetPayRollEmp.edges.find(
    (data) => data.node.id === empId
  )?.node;
  const fullName = `${empData?.emp_first_name} ${empData?.emp_middle_name} ${empData?.emp_last_name}`;
  const handleSubmit = (pr_emp_id: number) => {
    const flagData = data?.GetPayRollEmp.edges.find(
      (data) => data.node.id === pr_emp_id
    )?.node;
    EnableLogin({
      variables: {
        token,
        pr_emp_id,
        inst_id: InstId!,
        user_details,
        login_status: !flagData?.is_login_created,
      },
      refetchQueries: [
        {
          query: GetPayRollEmp,
          variables: {
            after: null,
            direction: Direction.ASC,
            first: ROWS_PER_PAGE,
            input: {
              ids: [Number(InstId)],
              pr_emp_query_type: PrEmpQueryType.EMP_BY_INST_ID,
            },
            per_std_subj_allocation: flag ? flag : false,
            name: EMPTY_STRING,
            sortBy: SortBy.EMP_FIRST_NAME,
            departmentId: null,
            designationId: null,
            categoryId: null,
            gradeId: null,
            jobTypeId: null,
            employeeId: null,
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: !flagData?.is_login_created
            ? "User Login Enabled Successfully"
            : "User Login Disabled Successfully",
          operation: Operation.UPDATE,
        });
      }
    });
  };
  const handleUpdate = () => {
    UpdateEmail({
      variables: {
        token,
        pr_emp_id: empId,
        login_data: {
          emp_email: eMail,
          emp_mobile: `+${mobileNo}`,
        },
        inst_id: InstId!,
        user_details,
      },
      refetchQueries: [
        {
          query: GetAcdStudents,
          variables: {
            after: null,
            acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId?.id!,
            first: ROWS_PER_PAGE,
            name: "",
            token,
            direction: Direction.ASC,
            sortBy: SortBy.FIRST_NAME,
            input: {
              ids: [Number(InstId!)],
              acd_std_query_type: StudentAcdType.BY_ACD_INST_ID,
              str_data: [""],
            },
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Student Details Updated Successfully",
          operation: Operation.UPDATE,
        });
        setEmailModal(!emailModal);
        setEmail(EMPTY_STRING);
      }
    });
  };

  const handleBack = () => {
    if (message.operation !== Operation.NONE && message.flag) {
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  useEffect(() => {
    if (data && !EmpLoading) {
      const newData = data.GetPayRollEmp.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = employees.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
              },
            };
          }
          return newStudent;
        });
        setEmployees(updatedNewData);
      } else {
        setEmployees(newData);
      }
      setHasNextPage(data.GetPayRollEmp.pageInfo.hasNextPage);
      setEndCursor(data.GetPayRollEmp.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, EmpLoading]);

  const dynamicHeaders: TableHeaderProps[] = HR_Table.EnableLoginUserList.map(
    (header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
      hideable: header.hideable,
    })
  );
  const columns: GridColDef[] = [
    ...dynamicHeaders,

    {
      field: "actions",
      cellClassName: "td-actions-print ",
      headerClassName: "td-actions-print-header",
      headerAlign: HEADER_TEXT_ALIGN,
      align: MARKS_TEXT_ALIGN,
      headerName: TableHeaders.ACTION,
      renderCell: (params) => {
        return (
          <>
            <FormControlLabel
              label=""
              control={
                <AntSwitch
                  checked={params.row.is_login_created}
                  onClick={() => {
                    handleSubmit(params.row.user_id);
                    setEmpId(params.row.user_id);
                    setMobileNo(params.row.mobile);
                  }}
                />
              }
              labelPlacement="start"
            />
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetPayRollEmp.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = employees.find(
            (employee) => employee.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
              },
            };
          }
          return newStudent;
        });
        setEmployees(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            name:
              node.emp_first_name + node.emp_middle_name + node.emp_last_name,
            mobile: node.emp_mobile,
            email: node.emp_email,
            user_type: node.emp_type,
            user_id: node.id,
            emp_type: node.emp_type,
            is_login_created: node.is_login_created,
          }))
        );
      } else {
        setEmployees(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            name:
              node.emp_first_name + node.emp_middle_name + node.emp_last_name,
            mobile: node.emp_mobile,
            email: node.emp_email,
            user_type: node.emp_type,
            user_id: node.id,
            emp_type: node.emp_type,
            is_login_created: node.is_login_created,
          }))
        );
      }
      setEndCursor(data.GetPayRollEmp.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);

  useEffect(() => {
    const scrollTable = document.getElementsByClassName(
      "MuiDataGrid-virtualScroller"
    )[0] as Element;
    const handleScroll = (e: Event) => {
      const target = e.target as HTMLDivElement;
      const scrollTop = target.scrollTop;
      const scrollHeight = target.scrollHeight;
      const clientHeight = target.clientHeight;
      if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
        if (hasNextPage && !loading) {
          fetchMore({
            variables: {
              first: FETCH_MORE_DATA,
              after: endCursor,
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prevResult;

              const newEdges = fetchMoreResult.GetPayRollEmp.edges;
              const pageInfo = fetchMoreResult.GetPayRollEmp.pageInfo;
              setEndCursor(pageInfo.endCursor);
              setHasNextPage(pageInfo.hasNextPage);

              const duplicateCheck = prevResult.GetPayRollEmp.edges.filter(
                ({ node: { id } }) =>
                  newEdges.findIndex(
                    ({ node: { id: newId } }) => newId === id
                  ) !== -1
              );

              if (duplicateCheck.length > 0) return prevResult;

              return {
                GetPayRollEmp: {
                  edges: [...employees, ...newEdges],
                  pageInfo,
                  totalCount: data ? data.GetPayRollEmp.totalCount! : 0,
                },
              };
            },
          });
        }
      }
    };
    if (scrollTable && rows.length)
      scrollTable.addEventListener("scroll", handleScroll);

    return () => {
      if (scrollTable) scrollTable.removeEventListener("scroll", handleScroll);
    };
  }, [rows]);

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>User Login Status</Title>
      <div className="std-enable-login">
        <form className="row g-0 std-enable-login__options">
          <div className="col-1 studentlist__select-flex ">
            <Input
              id="search"
              type="text"
              placeholder="Search "
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearchData(e.target.value)
              }
              value={searchData}
              onKeyDown={handleFormEvent}
            />
          </div>

          <div className="col">
            <FormControl>
              <RadioGroup row onChange={handleRadioChange}>
                <FormControlLabel
                  control={<Radio />}
                  label="All Users"
                  value="All Users"
                />
                <FormControlLabel
                  control={<Radio />}
                  label="Accounts User"
                  value="Accounts User"
                />
                <FormControlLabel
                  control={<Radio />}
                  label="Academics Users"
                  value="Academics Users"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="col-1">
            <Button
              mode="clear"
              onClick={(e: React.FormEvent) => {
                e.preventDefault();
                clear();
              }}
              className={"student-clear-button--acd"}
            />
          </div>
        </form>
        <div className={`std-enable-login__tableblock--for-emp`}>
          <StyledDatagrid
            columns={columns}
            rows={rows}
            rowHeight={TABLE_ROW_HEIGHT}
            disableRowSelectionOnClick
            hideFooter
          />
        </div>
        <div className="row g-0">
          <div className="col">
            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
          <div className="col-2 std-enable-login__total">
            <StudentTotalCount totalCount={data?.GetPayRollEmp.totalCount!} />
          </div>
        </div>
      </div>

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={emailModal}
        style={InstitutionCustomStyles}
        ariaHideApp={false}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <div className="row g-0">
              <Title variant="subtitle1">
                {Students.BasicDetails.Titles.map(
                  (title: StudentsTitlesType, index: React.Key) => {
                    return (
                      <React.Fragment key={index}>{title.Email}</React.Fragment>
                    );
                  }
                )}
              </Title>
            </div>

            <TextField
              className="std-enable-login__textfield"
              label="Name"
              value={fullName}
              disabled
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
            />
            {/* <TextField
              className="std-enable-login__textfield"
              label={branchLabel}
              value={branchName}
              disabled
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
            />
            <TextField
              className="std-enable-login__textfield"
              label={classLabel}
              value={className}
              disabled
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
            /> */}
            <TextField
              className="std-enable-login__textfield--email"
              label="Update E-mail"
              autoFocus
              value={eMail}
              onChange={(e) => setEmail(e.target.value)}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
            />

            <PhoneInput
              country={"in"}
              value={mobileNo}
              onChange={handleNumberChange}
              inputProps={{
                required: true,
                name: "Phone No.",
              }}
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  saveRef.current?.focus();
                }
              }}
            />
            <div className="frame-space">
              <Button
                type="submit"
                mode="save"
                buttonref={saveRef!}
                onClick={handleUpdate}
              />

              <Button
                mode="cancel"
                type="button"
                onClick={() => {
                  setEmailModal(!emailModal);
                  setEmail(EMPTY_STRING);
                }}
              />
            </div>
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setEmailModal(!emailModal)}
            />
          </div>
        </div>
      </Modal>
      <LoadingModal flag={loading || EnableLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleBack}
        operation={message.operation!}
      />
    </>
  );
};

export default EnableUserLogin;
