import { gql } from "@apollo/client";
export const AddInstSection = gql`
  mutation AddInstSection(
    $token: String!
    $input: CreateMstInstSectionInput!
    $user_details: SubscribedUser!
  ) {
    AddInstSection(token: $token, input: $input, user_details: $user_details)
  }
`;
export const AddInstSemester = gql`
  mutation AddInstSemester(
    $input: CreateMstInstSemesterInput!
    $token: String!
    $user_details: SubscribedUser!
  ) {
    AddInstSemester(token: $token, input: $input, user_details: $user_details)
  }
`;
export const ReCalculateSummaryCounts = gql`
  mutation ReCalculateSummaryCounts(
    $token: String!
    $inst_id: ID!
    $fin_yr_id: ID!
    $user_details: SubscribedUser!
  ) {
    ReCalculateSummaryCounts(
      token: $token
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
      user_details: $user_details
    )
  }
`;
export const ReCalculateAcdSummaryCounts = gql`
  mutation ReCalculateAcdSummaryCounts(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $user_details: SubscribedUser!
  ) {
    ReCalculateAcdSummaryCounts(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      user_details: $user_details
    )
  }
`;
export const AddInstClass = gql`
  mutation AddInstClass(
    $input: CreateMstInstClassInput!
    $token: String!
    $user_details: SubscribedUser!
  ) {
    AddInstClass(token: $token, input: $input, user_details: $user_details)
  }
`;
export const AddInstStdCategory = gql`
  mutation AddInstStdCategory(
    $input: CreateMstStdCategoryInput!
    $token: String!
    $user_details: SubscribedUser!
  ) {
    AddInstStdCategory(
      token: $token
      input: $input
      user_details: $user_details
    )
  }
`;
export const AddInstDept = gql`
  mutation AddInstDept(
    $input: CreateMstInstDeptInput!
    $token: String!
    $user_details: SubscribedUser!
  ) {
    AddInstDept(token: $token, input: $input, user_details: $user_details)
  }
`;
export const AddInstBranch = gql`
  mutation AddInstBranch(
    $input: CreateMstInstBranchInput!
    $token: String!
    $user_details: SubscribedUser!
  ) {
    AddInstBranch(token: $token, input: $input, user_details: $user_details)
  }
`;
export const AddAcdBranchAcdYr = gql`
  mutation AddAcdBranchAcdYr(
    $input: CreateAcdBranchAcdYr!
    $token: String!
    $inst_id: ID!
    $acd_branch_id: ID!
    $user_details: SubscribedUser!
  ) {
    AddAcdBranchAcdYr(
      token: $token
      inst_id: $inst_id
      input: $input
      user_details: $user_details
      acd_branch_id: $acd_branch_id
    ) {
      id
      acd_yr
      acd_st_date
      acd_end_date
      re_opening_date
      acd_is_curr_yr
      inst_id
      acd_branch_id
    }
  }
`;
export const AddInstAcdYr = gql`
  mutation AddInstAcdYr(
    $input: CreateMstInstAcdYrInput!
    $token: String!
    $user_details: SubscribedUser!
  ) {
    AddInstAcdYr(token: $token, input: $input, user_details: $user_details) {
      inst_id
      acd_yr
      acd_st_date
      acd_end_date
    }
  }
`;

export const AddInst = gql`
  mutation AddInst(
    $token: String!
    $input: CreateMstInstInput!
    $user_details: SubscribedUser!
  ) {
    AddInst(token: $token, input: $input, user_details: $user_details) {
      id
    }
  }
`;
export const AddMstInstLogoAndSigns = gql`
  mutation AddMstInstLogoAndSigns(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $file_type: InstFileType!
    $file_name: String!
  ) {
    AddMstInstLogoAndSigns(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      file_type: $file_type
      file_name: $file_name
    )
  }
`;
export const AddInstBranchLogo = gql`
  mutation AddInstBranchLogo(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $branch_id: ID!
    $file_name: String!
  ) {
    AddInstBranchLogo(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      branch_id: $branch_id
      file_name: $file_name
    )
  }
`;
