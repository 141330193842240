import React, { useContext, useEffect, useState } from "react";
import useInstDetails from "../../../../../customhooks/general/useInstDetails";
import useToken from "../../../../../customhooks/useToken";
import { Props } from "./PerTestTemplate1";
import { AppContext } from "../../../../../context/context";
import { useLazyQuery } from "@apollo/client";
import {
  StudentAcademicData,
  StudentAcademicVars
} from "../../../../Academics/hooks/useAcdStudentDetails";
import { GetAcdStudentDetails } from "../../../../Academics/queries/student";
import usePerTestReportConfig from "../../hooks/usePerTestReportConfig";
import { EMPTY_STRING } from "../../../../../utils/constants";
import { getDownloadUrl } from "../../../../../utils/DownloadFile";
import InstInformation from "./common/InstDetails";
import { toStandardDate } from "../../../../../utils/UtilFunctions";
import Avatar from "../../../../../images/Avatar.svg";
import TableDetails, { PerTestTableFor } from "./common/TableDetails";
import GradingScale from "../../common/GradingScale";
import { StudentDataType } from "../RPTemplate3";
const PerTestTemplate2 = ({
  testConductId,
  setModalFlag,
  selectedStudents,
  setStudentIdSet,
  forCombination
}: Props) => {
  const { token } = useToken();
  const { InstDetails, InstFormData } = useInstDetails(1);
  const [imageString, setImageString] = useState("");

  const [students, setStudents] = useState<StudentDataType[]>([]);

  const [GetStudentDetailsByIds, { data: studentData }] = useLazyQuery<
    StudentAcademicData,
    StudentAcademicVars
  >(GetAcdStudentDetails, {
    variables: { token, ids: selectedStudents },
    fetchPolicy: "network-only"
  });
  useEffect(() => {
    if (token && selectedStudents) {
      GetStudentDetailsByIds();
    }
  }, [token, GetStudentDetailsByIds, selectedStudents]);

  const {
    attendanceStatsPerTest,
    heightWeightPerTest,
    stdAddressPerTest,
    dobPerTest,
    stdImagePerTest,
    titlePerTest,
    stdNamePerTest,
    sectionPerTest,
    fatherNamePerTest,
    motherNamePerTest,
    rollNoPerTest,
    gradeScalePerTest,
    borderTypePerTest,
    otherDetailsPerTest,
    attendanceDetailsPerTest,
 
  } = usePerTestReportConfig();

  useEffect(() => {
    const fetchData = () => {
      if (studentData)
        studentData.nodes.map(async (std) => {
          if (InstDetails.data && std.std_profile_filename) {
            const fileName = `${InstFormData.inst_name}/students/${std.id}/std_profile_pic/std_profile_pic`;
            const url = await getDownloadUrl(fileName, false);
            setStudents((prev) => {
              return [
                ...prev,
                {
                  ...std,
                  profile_pic: url
                }
              ];
            });
          } else {
            setStudents((prev) => {
              return [
                ...prev,
                {
                  ...std,
                  profile_pic: EMPTY_STRING
                }
              ];
            });
          }
        });
    };
    fetchData();
  }, [studentData]);
  return (
    <div className="rp-template-3">
      {studentData
        ? students.map((std, index) => {
            return (
              <React.Fragment key={index}>
                <div
                  className="rp-template-3__page1"
                  style={{
                    border: borderTypePerTest?.config_boolean_value
                      ? `2px ${borderTypePerTest.config_string_value} var(--text-color)`
                      : ""
                  }}
                >
                  <InstInformation />
                  <br />
                  {titlePerTest?.config_boolean_value && (
                    <b className="progress-report__title">
                      {titlePerTest.config_string_value}
                    </b>
                  )}

                  <br />
                  <div className="rp-template-3__std">
                    <div className="rp-template-3__std--details">
                      {stdNamePerTest?.config_boolean_value && (
                        <>
                          <div className="bgs-pn-pr__std-details--grid">
                            <label>{stdNamePerTest.config_string_value}</label>
                            <span>:</span>
                            <input
                              type="text"
                              value={
                                std.first_name +
                                " " +
                                std.middle_name +
                                " " +
                                std.last_name
                              }
                            />
                          </div>
                        </>
                      )}

                      {sectionPerTest?.config_boolean_value && (
                        <>
                          <div className="bgs-pn-pr__std-details--grid">
                            <label>{sectionPerTest.config_string_value}</label>
                            <span>:</span>
                            <input
                              type="text"
                              value={studentData && std.acd_class.class_desc}
                            />
                          </div>
                        </>
                      )}

                      {rollNoPerTest?.config_boolean_value && (
                        <div className="bgs-pn-pr__std-details--grid">
                          <label>{rollNoPerTest.config_string_value}</label>
                          <span>:</span>
                          <input
                            type="text"
                            value={studentData && std.std_adm_no}
                          />
                        </div>
                      )}
                      {dobPerTest?.config_boolean_value && (
                        <div className="bgs-pn-pr__std-details--grid">
                          <label>{dobPerTest.config_string_value}</label>
                          <span>:</span>
                          <input
                            type="text"
                            value={toStandardDate(
                              studentData ? std.std_dob : EMPTY_STRING
                            )}
                          />
                        </div>
                      )}
                      {fatherNamePerTest?.config_boolean_value && (
                        <div className="bgs-pn-pr__std-details--grid">
                          <label>{fatherNamePerTest.config_string_value}</label>
                          <span>:</span>
                          <input
                            type="text"
                            value={studentData && std.std_father_name}
                          />
                        </div>
                      )}
                      {motherNamePerTest?.config_boolean_value && (
                        <div className="bgs-pn-pr__std-details--grid">
                          <label>{motherNamePerTest.config_string_value}</label>
                          <span>:</span>
                          <input
                            type="text"
                            value={studentData && std.std_mother_name}
                          />
                        </div>
                      )}

                      {stdAddressPerTest?.config_boolean_value && (
                        <div className="bgs-pn-pr__std-details--grid">
                          <label>{stdAddressPerTest.config_string_value}</label>
                          <span>:</span>
                          <input
                            type="text"
                            value={
                              studentData && std.std_details.std_corr_address
                            }
                          />
                        </div>
                      )}
                    </div>
                    {stdImagePerTest && stdImagePerTest.config_boolean_value ? (
                      <div className="rp-template-3__std--image">
                        {imageString ? (
                          <img
                            src={imageString}
                            alt=""
                            className="rp-template-3__std--image"
                          />
                        ) : (
                          <img src={Avatar} alt="" />
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="page-break" />
                <div className="rp-template-3__page2">
                  <InstInformation />
                  {titlePerTest?.config_boolean_value && (
                    <b className="progress-report__title">
                      {titlePerTest.config_string_value}
                    </b>
                  )}
                  <div className="bgs-pn-pr__acd-details">
                    <TableDetails
                      selectedStudents={[std.id]}
                      testConductId={testConductId}
                      forCombination={forCombination}
                      pageType={PerTestTableFor.MARKS_DETAILS}
                    />
                  </div>
                  <br />

                  <div className="row g-0 gap-4">
            
                    <div className="col">
                    {
                    otherDetailsPerTest?.config_boolean_value ? (
                      <>

                        <div className="bgs-pn-pr__non-acd">
                          <TableDetails
                            selectedStudents={[std.id]}
                            testConductId={testConductId}
                            forCombination={forCombination}
                            pageType={PerTestTableFor.OTHER_DETAILS}
                          />
                        </div>
                      </>
                    ) : null}
                    {
                    attendanceDetailsPerTest?.config_boolean_value ? (
                      <>

                    <br />
                        <div className="bgs-pn-pr__non-acd">
                          <TableDetails
                            selectedStudents={[std.id]}
                            testConductId={testConductId}
                            forCombination={forCombination}
                            pageType={PerTestTableFor.ATTENDANCE_STATS}
                          />
                        </div>
                      </>
                    ) : null}
                    </div>
                    {gradeScalePerTest?.config_boolean_value && (
                      <div className="rp-template-3__page2--grade col">
                        <GradingScale />
                      </div>
                    )}
                    <br />
              

                  </div>
             
                  <br />
                  <div className="bgs-pn-pr__non-acd">
                    <TableDetails
                      selectedStudents={[std.id]}
                      testConductId={testConductId}
                      forCombination={forCombination}
                      pageType={PerTestTableFor.SIGNATURE}
                    />
                  </div>

                  <br />
                  <div className="page-break"></div>
                  <br />
                </div>
              </React.Fragment>
            );
          })
        : null}
    </div>
  );
};

export default PerTestTemplate2;
