import React, { useContext, useEffect, useMemo, useState } from "react";
import Home from "../Home/Index";
import Avatar from "../../../../../images/Avatar.svg";
import TotalFee from "../../../../../images/DemandCollected.svg";
import TotalPaid from "../../../../../images/TotalPaid.svg";
import FeeBalance from "../../../../../images/FeeBalance.svg";
import Close from "../../../../../images/Close.svg";
import { Title } from "../../../../../stories/Title/Title";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { Button } from "../../../../../stories/Button/Button";
import { TableHeaderProps } from "../../../../../Types/Tables";
import Modal from "react-modal";
import {
  ConsolidatedPayModalStyles,
  PrintModalStyles,
  StudentModalStyles,
  TransactionStatusStyles,
} from "../../../../../styles/ModalStyles";
import StudentCompleteFeeReceipt from "../../../../Accounts/StudentDetails/CompleteFeeReceipt/Index";
import {
  DebitOrCredit,
  InstitutionType,
  Operation,
  PageFor,
  TransactionStatusType,
  YesNo,
} from "../../../../../utils/Enum.types";
import useStudentDatabyId from "../../../../../customhooks/useStudentDatabyId";
import FeeReceipt, {
  PayerType,
} from "../../../../Accounts/StudentDetails/FeeReceipt/Index";
import { formatter, toStandardDate } from "../../../../../utils/UtilFunctions";
import { useNavigate, useParams } from "react-router-dom";
import {
  GetStdCompleteFeeDetailsByStudentIDData,
  GetStdCompleteFeeDetailsByStudentIDDetails,
  VouchernodeData,
  nodevars,
  studentDemandVars,
  studentRecepit,
} from "../../../../../Types/Accounting";
import { useLazyQuery } from "@apollo/client";
import { GetStdCompleteFeeDetailsByStudentID } from "../../../../../queries/students/list/byId";
import { AppContext } from "../../../../../context/context";
import useToken from "../../../../../customhooks/useToken";
import useInstitutionConfiguration from "../../../../../customhooks/useInstitutionConfiguration";
import useMasterTableJson from "../../../../../json/useMasterTableJson";
import useInstLabels from "../../../../../customhooks/general/useInstLabels";

import Print from "../../../../Print/Accounts/FeeReceipts/Standard/FeeReceiptPrint";
import { Data } from "../../../../Print/Accounts/FeeReceipts/templates/Index";
import useSwConfigData from "../../../../../customhooks/useSwConfigData";
import { InstitutionAcctConfigurationTypes } from "../../../../Accounts/common/Enum.types";
import useStudentDemandDetails from "../../../../Accounts/hooks/useStudentDemandDetails";
import {
  DemandDetailsQueryType,
  LedgerType,
} from "../../../../Accounts/common/QueryTypes";
import { EMPTY_STRING } from "../../../../../utils/constants";
import { StudentDetailsByMasterVoucherId } from "../../../../../queries/common";
import ConsolidatedAmountPay from "../../Modals/ConsolidatedAmountPay";
import { msgType, responseType } from "../../../../../utils/Form.types";
import { GlobalPageConfigData } from "../../../../../Types/configtypes";
import PayByDemand from "./PayByDemand";
import { CustomModalProps } from "../../../../Logs/ModuleWiseDetails/Accounts";
import useActiveFinancialYear from "../../../../Accounts/hooks/useActiveFinancialYear";
import Failure from "../../../../../images/Unsuccessfull_Transaction_Gif.gif";
import useTransactionDetailsById from "../../../../../customhooks/useTransactionDetailsById";
import FinetoPay from "../../../../../images/FineToPay.svg";
import NoData from "../../../../../images/No_data_to_display.svg";

import { getDownloadUrl } from "../../../../../utils/DownloadFile";
import useInstDetails from "../../../../../customhooks/general/useInstDetails";

import PaymentFailure from "../../../../Accounts/Status/Failure";
import useSwConfigInstType from "../../../../Academics/hooks/useSwConfigInstType";
import { payloadTypes } from "../../../../../context/reducer";
import { FineType } from "../../../../Accounts/Fines/Index";
import useFineSlab from "../../../../Accounts/hooks/useFineSlab";
import useServerDateandTime from "../../../../Library/customHooks/useServerDateandTime";
interface Props {
  pageType: PageFor;
  transactionStatus: TransactionStatusType;
  setCompleteFeeModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

const Index = ({
  pageType,
  transactionStatus,
  setModal,
  setCompleteFeeModal,
}: Props) => {
  const navigate = useNavigate();
  const { institutionType } = useSwConfigInstType();

  const { Masters_Table } = useMasterTableJson();
  const { data: transactionData } = useTransactionDetailsById();
  const [tableData, setTableData] = useState<Data[]>([]);
  const [v_no, set_vo_no] = useState("");
  const [reference_no, set_reference_no] = useState("");
  const [imageString, setImageString] = useState("");
  const { transactionId } = useParams();
  const [v_date, set_v_Date] = useState("");
  const { state, dispatch } = useContext(AppContext);
  const [finYear, setFinYear] = useState<responseType | null>(null);

  // eslint-disable-next-line
  const [message, setMessage] = useState<msgType>({
    flag: false,
    message: "",
    operation: Operation.NONE,
  });
  const [viewReceipts, setViewReceipts] = useState(false);
  const [consolidatePayOnline, setConsolidatePayOnline] = useState(false);
  const [payFeeByDemand, setPayFeeByDemand] = useState(false);
  const [payOnline, setPayOnline] = useState(false);
  const [printModal, setPrintModal] = useState(false);
  const [feeModal, setFeeModal] = useState(false);

  const isSuccess = window.location.pathname.includes("success");
  const isFailure = window.location.pathname.includes("failed");

  const [failureModal, setFailureModal] = useState(false);

  const { format } = formatter;
  const { token } = useToken();
  const { ActiveFinancicalYear } = useActiveFinancialYear();
  const { InstDetails } = useInstDetails(1);
  const [totalFineAmt, setTotalFineAmt] = useState(0);

  const {
    studentData,
    studentFormData,
    studentData: { data },
  } = useStudentDatabyId();

  const { StudentDemandDetails } = useStudentDemandDetails(
    DemandDetailsQueryType.ALL_DEMAND_DETAILS,
    false,
    EMPTY_STRING
  );
  const [items, setItems] = useState<studentRecepit[]>([]);
  const [rcptDate, setRcptDate] = useState("");
  const { data: serverDateData, loading: serverDateLoading } =
    useServerDateandTime();
  const { USE_CATEGORY_KEY } = useInstitutionConfiguration();
  const [completeFeeReceiptData, setStudentCompleteFeeReceiptData] =
    useState<GetStdCompleteFeeDetailsByStudentIDDetails>();
  const { configData } = useSwConfigData([
    InstitutionAcctConfigurationTypes.SHOW_ONLY_CONSOL_FEE_IN_STD_LOGIN,
    InstitutionAcctConfigurationTypes.ENABLE_PARTIAL_STD_RECEIPT_PAYMENT,
    InstitutionAcctConfigurationTypes.PAYMENT_GATEWAY,
  ]);
  const { finesData } = useFineSlab();

  const getFine = (dueDate: string, totalAmount?: number) => {
    if (!finesData?.data?.GetAcctFineSlabByInstId?.length) return 0;

    const { GetAcctFineSlabByInstId } = finesData.data;
    const { acct_fine_type, roi, days, fine_amt, recurring } =
      GetAcctFineSlabByInstId[0];

    const fineAmount = parseFloat(fine_amt) || 0;
    const rateOfInterest = parseFloat(roi) || 0;

    const currentDate = new Date();
    const dueDateObj = new Date(dueDate);
    const noOfDaysDelayed = Math.max(
      0,
      Math.floor(
        (currentDate.getTime() - dueDateObj.getTime()) / (1000 * 60 * 60 * 24)
      )
    );

    if (noOfDaysDelayed > 0) {
      switch (acct_fine_type) {
        case FineType.PERCENTAGE: {
          if (!totalAmount) return 0;

          const interest = totalAmount * (rateOfInterest / 100);
          const dailyInterest = interest / days;
          const totalFine = dailyInterest * noOfDaysDelayed;

          return parseFloat(totalFine.toFixed(2));
        }

        case FineType.RECURRING: {
          if (recurring) {
            const periods = Math.ceil(noOfDaysDelayed / days);
            return fineAmount * periods;
          } else {
            return fineAmount;
          }
        }

        case FineType.PER_DAY: {
          return fineAmount * noOfDaysDelayed;
        }

        default:
          return fineAmount;
      }
    }

    return 0;
  };
  const waiveOffAmtMemoized = useMemo(() => {
    if (!finesData?.data?.GetAcctFineSlabByInstId?.length) return 0;

    const { GetAcctFineSlabByInstId } = finesData.data;
    const { acct_fine_type } = GetAcctFineSlabByInstId[0];

    switch (acct_fine_type) {
      case FineType.RECURRING:
      case FineType.PER_DAY:
        return items.reduce(
          (sum, item) => sum + getFine(item.fee_due_date!, item.initialBalance),
          0
        );

      case FineType.FLAT: {
        const overdueLedger = items.find((item) => {
          if (!item.fee_due_date) return false;
          const dueDate = new Date(item.fee_due_date);
          return dueDate < new Date();
        });

        if (overdueLedger) {
          return getFine(
            overdueLedger.fee_due_date!,
            overdueLedger.initialBalance
          );
        }

        return 0;
      }

      case FineType.PERCENTAGE:
        return items.reduce(
          (sum, item) =>
            sum +
            (item.fee_due_date?.length && item.initialBalance
              ? getFine(item.fee_due_date!, item.initialBalance)
              : 0),
          0
        );

      default:
        return 0;
    }
  }, [finesData.data, items]);
  useEffect(() => {
    if (serverDateData && !serverDateLoading) {
      setRcptDate(serverDateData.GetServerDateAndTime);
    }
  }, [serverDateData, serverDateLoading]);

  const fineType =
    finesData &&
    finesData.data &&
    finesData.data.GetAcctFineSlabByInstId.length === 0
      ? EMPTY_STRING
      : finesData.data
      ? finesData.data.GetAcctFineSlabByInstId[0].acct_fine_type
      : EMPTY_STRING;
  useEffect(() => {
    if (StudentDemandDetails.data) {
      const data = StudentDemandDetails.data;
      const studentFeeData: studentRecepit[] =
        data.GetAcctStdDemandDetails.filter((item) => item !== null).map(
          (item) => {
            const feeDueDate = item.fee_due_date!;
            const isDelayed =
              new Date(feeDueDate).getTime() < new Date(rcptDate).getTime();
            let calculatedFine = 0;
            if (isDelayed) {
              switch (fineType) {
                case FineType.PERCENTAGE:
                  calculatedFine = getFine(feeDueDate, item.fee_bal);
                  break;

                case FineType.RECURRING:
                case FineType.PER_DAY:
                  calculatedFine = getFine(feeDueDate, item.fee_bal);
                  break;

                case FineType.FLAT:
                default:
                  calculatedFine = 0;
                  break;
              }
            }

            return {
              fee_ob: item.fee_ob,
              fee_demand: item.fee_demand,
              fee_concession: item.fee_concession,
              fee_receivable: item.fee_receivable,
              fee_received: item.fee_received,
              fee_refunds: item.fee_refunds,
              fee_bal: item.fee_bal,
              v_no: "",
              fee_due_date: item.fee_due_date,
              acct_ldgr_id: item.acct_ldgr_id,
              id: item.id,
              cr_db: DebitOrCredit.CREDIT,
              recevied_amount: 0,
              newItem: YesNo.NO,
              legederType: LedgerType.GENERAL,
              rcpt_amt: 0,
              acct_ldgr_details: item?.acct_ldgr_details,
              initialBalance: item.fee_bal,
              student_id: item.student_id,
              fine_amt: calculatedFine,
            };
          }
        );

      setItems(state.studentId ? studentFeeData : []);
    }

    // eslint-disable-next-line
  }, [
    StudentDemandDetails.data,
    // eslint-disable-next-line
    studentData.data,
    state.studentId,
    finesData.data,
    InstDetails.data,
  ]);

  useEffect(() => {
    if (
      studentFormData.std_profile_filename !== EMPTY_STRING &&
      InstDetails.data
    ) {
      const studentProfiePicUrl = `${InstDetails.data?.nodes[0]?.inst_name}/students/${state.studentId}/std_profile_pic/std_profile_pic`;

      // eslint-disable-next-line
      const ers = getDownloadUrl(studentProfiePicUrl, false, setImageString);
    }
  }, [studentFormData.std_profile_filename, InstDetails.data, state.studentId]);
  useEffect(() => {
    if (items.length > 0 && state.studentId) {
      if (printModal === false) {
        setTotalFineAmt(waiveOffAmtMemoized);
      }
    }
  }, [items, state.studentId, printModal]);
  const filterDataByConfigKey = (data: GlobalPageConfigData[]) => {
    let consolidatedFee;
    let PartialFee;
    if (data) {
      data.forEach((item) => {
        switch (item.config_key) {
          case InstitutionAcctConfigurationTypes.SHOW_ONLY_CONSOL_FEE_IN_STD_LOGIN:
            consolidatedFee = item.config_boolean_value;
            break;
          case InstitutionAcctConfigurationTypes.ENABLE_PARTIAL_STD_RECEIPT_PAYMENT:
            PartialFee = item.config_boolean_value;
            break;

          default:
            break;
        }
      });
    }
    return { consolidatedFee, PartialFee };
  };

  const { consolidatedFee, PartialFee } = filterDataByConfigKey(
    configData.data?.GetSwConfigVariables!
  );

  const [GetStdCompleteFeeReceiptsByStudentID] = useLazyQuery<
    GetStdCompleteFeeDetailsByStudentIDData,
    studentDemandVars
  >(GetStdCompleteFeeDetailsByStudentID, { fetchPolicy: "network-only" });
  const [GetLedgersfromVoucherMaster] = useLazyQuery<VouchernodeData, nodevars>(
    StudentDetailsByMasterVoucherId
  );

  useEffect(() => {
    if (state.studentId && finYear && finYear.value) {
      GetStdCompleteFeeReceiptsByStudentID({
        variables: {
          token,
          fin_yr_id: finYear ? finYear.value : 0,
          inst_id: state.InstId!.toString(),
          student_id: state.studentId,
        },
      }).then(({ data }) => {
        if (data) {
          setStudentCompleteFeeReceiptData(
            data.GetStdCompleteFeeDetailsByStudentID
          );
        }
      });
    }
  }, [
    state.studentId,
    token,
    GetStdCompleteFeeReceiptsByStudentID,
    finYear,
    state.InstId,
  ]);
  useEffect(() => {
    if (token && ActiveFinancicalYear?.data && !ActiveFinancicalYear?.loading) {
      const startDate = new Date(
        ActiveFinancicalYear.data.GetFinYrActiveByInstId.fin_st_date
      );
      const endDate = new Date(
        ActiveFinancicalYear.data.GetFinYrActiveByInstId.fin_end_date
      );
      setFinYear({
        label: `${startDate.getFullYear()} - ${endDate.getFullYear()}`,
        value: ActiveFinancicalYear.data.GetFinYrActiveByInstId.id,
      });
    }
  }, [token, ActiveFinancicalYear.data, ActiveFinancicalYear.loading]);
  const demandTotal = StudentDemandDetails.data
    ? StudentDemandDetails.data.GetAcctStdDemandDetails.reduce(
        (acc, item) => {
          acc.totalOb += item.fee_ob;
          acc.totalDemand += item.fee_demand;
          acc.totalConcession += item.fee_concession;
          acc.totalPaid += item.fee_received;
          acc.totalBalance += item.fee_bal;
          return acc;
        },
        {
          totalOb: 0,
          totalDemand: 0,
          totalConcession: 0,
          totalPaid: 0,
          totalBalance: 0,
        }
      )
    : {
        totalOb: 0,
        totalDemand: 0,
        totalConcession: 0,
        totalPaid: 0,
        totalBalance: 0,
      };

  const showConcession: boolean = StudentDemandDetails.data
    ? StudentDemandDetails.data.GetAcctStdDemandDetails.filter(
        (demand) => demand.fee_concession > 0
      ).length > 0
    : false;
  const pendingBalance =
    StudentDemandDetails.data &&
    StudentDemandDetails.data.GetAcctStdDemandDetails.filter(
      (demand) => demand.fee_bal > 0
    ).length;
  useEffect(() => {
    if ((isSuccess || isFailure) && Number(transactionId))
      setFailureModal(true);
  }, [isSuccess, isFailure, transactionId]);
  const ViewReceiptsComponent = () => {
    return (
      <>
        <Title variant="subtitle1">
          {consolidatedFee
            ? "Paid Receipts"
            : pendingBalance
            ? "Pending Fees"
            : "Completely Paid"}
        </Title>

        {!consolidatedFee ? (
          pendingBalance ? (
            <TableContainer className="std-login-fee__table">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {Masters_Table.Students.Dashboard.PendingFee.filter(
                      (th) =>
                        (th.labelName === "Concession" && showConcession) ||
                        th.labelName !== "Concession"
                    ).map((th: TableHeaderProps, index: React.Key) => {
                      return <TableCell key={index}>{th.labelName}</TableCell>;
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {StudentDemandDetails.data
                    ? StudentDemandDetails.data.GetAcctStdDemandDetails.filter(
                        (demand) => demand.fee_bal > 0
                      ).map((std_fee, index) => {
                        return (
                          <TableRow>
                            <TableCell
                              id="td-center"
                              className="std-login-fee__table--slno">
                              {index + 1}
                            </TableCell>
                            <TableCell>
                              {std_fee.acct_ldgr_details.ldgr_desc}
                            </TableCell>
                            <TableCell
                              className="std-login-fee__table--amount"
                              id="td-right">
                              {std_fee.fee_ob}
                            </TableCell>
                            <TableCell
                              className="std-login-fee__table--amount"
                              id="td-right">
                              {std_fee.fee_demand}
                            </TableCell>
                            {showConcession ? (
                              <TableCell
                                className="std-login-fee__table--amount"
                                id="td-right">
                                {std_fee.fee_concession}
                              </TableCell>
                            ) : null}
                            <TableCell
                              id="td-right"
                              className="std-login-fee__table--amount">
                              {std_fee.fee_received}
                            </TableCell>
                            <TableCell
                              id="td-right"
                              className="std-login-fee__table--amount">
                              {std_fee.fee_bal}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : null}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={2} className="total">
                      Total :
                    </TableCell>
                    <TableCell className="totalcount std-login-fee__table--amount">
                      {demandTotal.totalOb}
                    </TableCell>
                    <TableCell className="totalcount std-login-fee__table--amount">
                      {demandTotal.totalDemand}
                    </TableCell>
                    {showConcession ? (
                      <TableCell className="totalcount std-login-fee__table--amount">
                        {demandTotal.totalConcession}
                      </TableCell>
                    ) : null}
                    <TableCell className="totalcount std-login-fee__table--amount">
                      {demandTotal.totalPaid}
                    </TableCell>
                    <TableCell className="totalcount std-login-fee__table--amount">
                      {demandTotal.totalBalance}
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          ) : (
            <div className="std-login-fee__nodata">
              <img src={NoData} alt="NoData" />
            </div>
          )
        ) : (
          <>
            {completeFeeReceiptData &&
            completeFeeReceiptData.std_demand_receipts.length > 0 ? (
              <TableContainer className="std-login-fee__table">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {Masters_Table.Students.Dashboard.FeeDetails.map(
                        (th: TableHeaderProps, index: React.Key) => {
                          return (
                            <TableCell key={index}>{th.labelName}</TableCell>
                          );
                        }
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {completeFeeReceiptData &&
                      completeFeeReceiptData?.std_demand_receipts.map(
                        (edge, index) => {
                          return (
                            <TableRow
                              key={index}
                              onClick={() => {
                                GetLedgersfromVoucherMaster({
                                  variables: {
                                    ids: [edge.id],
                                    token,
                                  },
                                }).then(({ data }) => {
                                  if (data && data.nodes) {
                                    const filteredCrs =
                                      data.nodes[0].acct_voucher_details.filter(
                                        ({ vo_cr_db, vo_cr }) =>
                                          vo_cr_db === DebitOrCredit.CREDIT &&
                                          vo_cr > 0
                                      );
                                    set_vo_no(edge.v_no);
                                    set_reference_no(edge.v_transcation_no);

                                    set_v_Date(edge.v_date);
                                    if (filteredCrs.length) {
                                      setTableData(
                                        filteredCrs.map((item) => ({
                                          amount: item.vo_cr,
                                          particular: item.acct_ldgr.ldgr_desc,
                                        }))
                                      );
                                    }

                                    dispatch({
                                      type: payloadTypes.SET_RECEPIT_ID,
                                      payload: {
                                        receiptId: edge.id,
                                      },
                                    });

                                    setPrintModal(!printModal);
                                  }
                                });
                              }}>
                              <TableCell
                                id="td-center"
                                className="std-login-fee__table--slno">
                                {index + 1}
                              </TableCell>
                              <TableCell
                                id="td-center"
                                className="std-login-fee__table--date">
                                {toStandardDate(edge.v_date)}
                              </TableCell>
                              <TableCell className="std-login-fee__table--receiptno">
                                {edge.v_no}
                              </TableCell>
                              <TableCell className="std-login-fee__table--desc">
                                {edge.v_transcation_type}
                              </TableCell>
                              <TableCell className="std-login-fee__table--desc">
                                {edge.v_transcation_no}
                              </TableCell>
                              <TableCell
                                id="td-right"
                                className="std-login-fee__table--amount">
                                {format(edge.v_std_amt_total)}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell colSpan={5} className="total">
                        Total :
                      </TableCell>
                      <TableCell className="totalcount std-login-fee__table--amount">
                        {format(receiptAccumulator?.receiptTotalAmount!)}
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            ) : (
              <div className="std-login-fee__nodata">
                <img src={NoData} alt="NoData" />
              </div>
            )}
          </>
        )}
      </>
    );
  };
  const receiptAccumulator = completeFeeReceiptData?.std_demand_receipts.reduce(
    (acc, receipt) => {
      acc.receiptTotalFine += receipt.v_std_amt_fine;
      if (receipt.v_std_demand_receipt) {
        acc.receiptTotalDemandFee += receipt.v_std_amt_receipt;
      }
      if (!receipt.v_std_demand_receipt) {
        acc.receiptTotalNonDemandFee += receipt.v_std_amt_receipt;
      }
      if (receipt.v_std_deposit) {
        acc.receiptTotalDeposit += receipt.v_std_amt_deposit;
      }
      acc.receiptTotalAmount += receipt.v_std_amt_total;
      return acc;
    },
    {
      receiptTotalFine: 0,
      receiptTotalDemandFee: 0,
      receiptTotalNonDemandFee: 0,
      receiptTotalDeposit: 0,
      receiptTotalAmount: 0,
    }
  );

  const { branchLabel, classLabel, categoryLabel } = useInstLabels();

  const CustomModal: React.FC<CustomModalProps> = ({
    isOpen,
    onRequestClose,
    contentComponent,
  }) => {
    return (
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={isOpen}
        style={StudentModalStyles}
        ariaHideApp={false}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">{contentComponent}</div>
          <div className="modal-flex__image">
            <img src={Close} alt="Close" onClick={onRequestClose} />
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <>
      {pageType === PageFor.GENERAL && <Home />}
      <Title>Fee Details</Title>
      <div
        className={
          pageType === PageFor.GENERAL
            ? "std-login-fee"
            : "std-login-fee__modal"
        }>
        <div className="std-login-fee__details row g-0">
          <div className="col">
            <TextField
              className="std-login-fee__details--textfield"
              label="Admission Number"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              disabled
              value={studentFormData.adm_no}
            />
            {institutionType !== InstitutionType.SCHOOL && (
              <TextField
                className="std-login-fee__details--textfield"
                label="Register Number"
                disabled
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                value={studentFormData.reg_number}
              />
            )}
            <TextField
              className="std-login-fee__details--textfield"
              label={branchLabel}
              disabled
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              value={studentFormData.branch}
            />
          </div>
          <div className="col">
            <TextField
              className="std-login-fee__details--textfield"
              label="Name"
              disabled
              value={studentFormData.std_name}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
            />
            <TextField
              className="std-login-fee__details--textfield"
              label="Father Name"
              disabled
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              value={studentFormData.father_name}
            />
            <TextField
              className="std-login-fee__details--textfield"
              label={classLabel}
              disabled
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              value={studentFormData.class}
            />
          </div>
          <div className="col">
            <TextField
              className="std-login-fee__details--textfield"
              label="Academic Year"
              disabled
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              value={studentFormData.acd_yr}
            />
            {USE_CATEGORY_KEY && (
              <TextField
                className="std-login-fee__details--textfield"
                label={categoryLabel}
                value={studentFormData.category}
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                disabled
              />
            )}
          </div>
          <div className="col-1 std-login-fee__details--profile h-100">
            {imageString ? (
              <img src={imageString} alt="/" />
            ) : (
              <img src={Avatar} alt="/" />
            )}
          </div>
        </div>
        <div className="std-login-fee__year row g-0"></div>
        <div className="std-login-fee__year row g-0"></div>

        <div className="std-login-fee__blocks row g-0">
          <div className="col std-login-fee__blocks--cards ">
            <div className="std-login-fee__blocks--cards--title">
              <img src={TotalFee} alt="/" />
              <b>Total Fee</b>
            </div>

            <span className="std-login-fee__blocks--cards--total-fee">
              {format(
                completeFeeReceiptData
                  ? completeFeeReceiptData.std_demand_by_fin_yr.std_demand_amt +
                      completeFeeReceiptData.std_demand_by_fin_yr.std_demand_ob
                  : 0
              )}
            </span>
          </div>
          <div className="col std-login-fee__blocks--cards">
            <div className="std-login-fee__blocks--cards--title">
              <img src={TotalPaid} alt="/" />

              <b>Total Paid</b>
            </div>

            <span className="std-login-fee__blocks--cards--paid">
              {format(
                completeFeeReceiptData
                  ? completeFeeReceiptData.std_demand_by_fin_yr
                      .std_demand_received
                  : 0
              )}
            </span>
          </div>
          <div className="col std-login-fee__blocks--cards">
            <div className="std-login-fee__blocks--cards--title">
              <img src={FeeBalance} alt="/" />
              <b>Fee Balance</b>
            </div>

            <span className="std-login-fee__blocks--cards--balance">
              {format(
                completeFeeReceiptData
                  ? completeFeeReceiptData.std_demand_by_fin_yr.std_demand_bal
                  : 0
              )}
            </span>
          </div>
          <div className="col std-login-fee__blocks--cards">
            <div className="std-login-fee__blocks--cards--title">
              <img src={FinetoPay} alt="/" />
              <b>Fine to Pay</b>
            </div>

            <span className="std-login-fee__blocks--cards--balance font-amber">
              {Math.round(totalFineAmt)}
            </span>
          </div>
          {configData.data?.GetSwConfigVariables[2].config_boolean_value && (
            <div className="col-2 std-login-fee__blocks--button">
              {/* {enablePayementGateway.data?.GetSwConfigVariables[0]
              .config_boolean_value && <OnlinePayment />} */}
              <Button
                mode="pay-online"
                disabled={!pendingBalance}
                onClick={() => {
                  if (consolidatedFee !== undefined && PartialFee !== undefined)
                    if (consolidatedFee) {
                      setConsolidatePayOnline(!consolidatePayOnline);
                    } else if (PartialFee) {
                      setPayOnline(!payOnline);
                    } else {
                      setPayFeeByDemand(!payFeeByDemand);
                    }
                }}
              />
            </div>
          )}
        </div>

        {/* if demand details enabled in configuration show Demand details table and View Receipts button if not show only Fee Paid Details page */}
        <div className="std-login-fee__tableblock">
          {transactionStatus === TransactionStatusType.FAILURE ? (
            <div className="std-login-fee__tableblock--proceed">
              <Title variant="subtitle1">Payment Details</Title>

              <div className="std-login-fee__tableblock--proceed--gif">
                <img src={Failure} alt="" />
                <span className="nodata">
                  Transaction Failed:{" "}
                  {transactionData
                    ? `${transactionData.node.response_code} : ${transactionData.node.response_code_str}`
                    : ""}
                </span>
              </div>
            </div>
          ) : (
            <ViewReceiptsComponent />
          )}
        </div>
        {/* {consolidatedFee &&
        transactionStatus === TransactionStatusType.SUCCESS ? (
          <>
            <Button onClick={() => setViewReceipts(!viewReceipts)} mode="view">
              &nbsp;Receipts
            </Button>
            <Button
              onClick={() => setViewCompleteFeeSummary(!viewCompleteFeeSummary)}
              mode="view">
              &nbsp; Complete Fee Summary
            </Button>
          </>
        ) : null} */}
        {!consolidatedFee && (
          <Button
            mode="fee-details"
            onClick={() => {
              if (pageType === PageFor.MODAL) {
                setCompleteFeeModal?.(true);
                setModal?.(false);
              } else setFeeModal(!feeModal);
            }}
          />
        )}
        {pageType === PageFor.GENERAL ? (
          <Button mode="back" onClick={() => navigate(-1)} />
        ) : (
          <Button mode="cancel" onClick={() => setModal?.(false)} />
        )}
      </div>

      <CustomModal
        isOpen={payOnline}
        onRequestClose={() => setPayOnline(!payOnline)}
        contentComponent={
          <FeeReceipt
            setModalFlag={setPayOnline}
            pageType={PageFor.MODAL}
            payer={PayerType.STUDENT}
            studentSelected={false}
          />
        }
      />
      <CustomModal
        isOpen={payFeeByDemand}
        onRequestClose={() => setPayFeeByDemand(!payFeeByDemand)}
        contentComponent={<PayByDemand setModalFlag={setPayFeeByDemand} />}
      />
      <CustomModal
        isOpen={viewReceipts}
        onRequestClose={() => setViewReceipts(!viewReceipts)}
        contentComponent={<ViewReceiptsComponent />}
      />

      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={printModal}
        style={PrintModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Print
              tableData={tableData}
              v_date={v_date}
              v_no={v_no}
              transaction_no={reference_no}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setPrintModal(!printModal)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={consolidatePayOnline}
        ariaHideApp={false}
        style={ConsolidatedPayModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ConsolidatedAmountPay
              balance={
                completeFeeReceiptData
                  ? completeFeeReceiptData.std_demand_by_fin_yr.std_demand_bal
                  : 0
              }
              setModalFlag={setConsolidatePayOnline}
              setMessage={setMessage}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt=""
              onClick={() => setConsolidatePayOnline(!consolidatePayOnline)}
            />
          </div>
        </div>
      </Modal>

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={feeModal}
        style={StudentModalStyles}
        ariaHideApp={false}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentCompleteFeeReceipt
              PageType={PageFor.MODAL}
              setModalFlag={setFeeModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="Close"
              onClick={() => setFeeModal(!feeModal)}
            />
          </div>
        </div>
      </Modal>

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={failureModal}
        style={TransactionStatusStyles}
        ariaHideApp={false}>
        <PaymentFailure
          pageType={TransactionStatusType.FAILURE}
          setModalFlag={setFailureModal}
        />
      </Modal>
    </>
  );
};

export default Index;
