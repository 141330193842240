import React, { useContext, useEffect, useRef, useState } from "react";
import Input from "../../../../stories/Input/Input";
import { Label } from "../../../../stories/Label/Label";
import { Title } from "../../../../stories/Title/Title";
import {
  PageFor,
  DebitOrCredit,
  VoucherBookKey,
  ReceiptTypes,
  StudentListFor,
  StudentAcctReportType,
  BankOrCash,
  PredefinedDataTypes,
  SortBy,
  Direction,
  Operation,
  ModuleName,
  VoucherPageType,
  InstitutionConfigurationTypes,
  InstitutionType,
} from "../../../../utils/Enum.types";
import {
  DeleteLedgerModalStyles,
  PrintConfigModalStyles,
  PrintModalStyles,
  StudentModalStyles,
} from "../../../../styles/ModalStyles";
import Modal from "react-modal";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { Button } from "../../../../stories/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../../context/context";

import { useMutation } from "@apollo/client";
import { RefundAmount } from "../../../../Types/Accounting";
import {
  DateRange,
  formatter,
  handleMUISelectEvent,
  NameOfTheDay,
  toggleFullSceen,
  toInputStandardDate,
} from "../../../../utils/UtilFunctions";
import {
  DEFAULT_TIME,
  EMPTY_STRING,
  NumberOfAdmissionNumberToBeDisplayed,
  ROWS_PER_PAGE,
  TODAY_DATE,
} from "../../../../utils/constants";
import {
  GetAcctVouchers,
  GetVoucherNumber,
} from "../../queries/Vouchers/query";
import { AddAcctVoucherMasterAndDetails } from "../../queries/receipts/mutation/Index";
import dayjs from "dayjs";
import { RefundAcademicFeeTitleProps } from "../../../../Types/Titles";
import { TableHeaderProps } from "../../../../Types/Tables";
import Home from "../../Home/Index";
import ViewRefundedList from "./ViewRefundedList";
import Avatar from "../../../../images/Avatar.svg";
import LoadingModal from "../../../../pages/LoadingModal";

import Enlarge from "../../../../images/Enlarge.svg";
import useStudentDatabyId from "../../../../customhooks/useStudentDatabyId";
import DownArrow from "../../../../images/DownArrow.svg";
import {
  msgType,
  optionsType,
  responseType,
} from "../../../../utils/Form.types";
import useAcctLedgerData from "../../hooks/useAcctLedgerData";
import useToken from "../../../../customhooks/useToken";
import { Keys } from "../../../../utils/Enum.keys";
import useStudentDemandDetails from "../../hooks/useStudentDemandDetails";
import Close from "../../../../images/Close.svg";
import Edit from "../../../../images/Edit.svg";
import usePredefinedDataByType from "../../../../customhooks/usePredefinedDataByType";
import MessageModal from "../../../../pages/MessageModal";
import { getDownloadUrl } from "../../../../utils/DownloadFile";
import { payloadTypes } from "../../../../context/reducer";
import useInstReferenceDataByType, {
  InstReferenceDataByType,
} from "../../../../customhooks/useInstReferenceDataByType";
import Delete from "../../StudentDetails/FeeReceipt/modals/Delete";
import useVoucherNumber from "../../hooks/useVoucherNumber";
import StudentList from "../../common/StudentList";
import useAcctStdAdmissionNumber from "../../hooks/useAcctStdAdmissionNumber";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import {
  AcctLedgerQueryType,
  DemandDetailsQueryType,
} from "../../common/QueryTypes";
import { Data } from "../../../Print/Accounts/FeeReceipts/templates/Index";
import {
  InstitutionAcctConfigurationTypes,
  VoucherQueryTypes,
} from "../../common/Enum.types";
import { SwConfigQueryType } from "../../../HR/enums/Enum.types";
import Settings from "../../../../images/Settings.svg";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import { GlobalPageConfigData } from "../../../../Types/configtypes";
import TextArea from "../../../../stories/TextArea/TextArea";
import VoucherPrint from "../../../Print/Accounts/Vouchers/VoucherPrint";
import PerModuleConfiguration from "../../../Configurations/PerModuleConfiguration";
import useAcctTableJson from "../../json/useAcctTableJson";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import useLoggedInUserDetails from "../../hooks/useLoggedInUserDetails";
import {
  AddAcctVoucherMasterAndDetailsData,
  AddAcctVoucherMasterAndDetailsVars,
} from "../../../../Types/Accounting/mutations";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";
import {
  FormAutocomplete,
  formClasses,
  RequiredAutoComplete,
} from "../../../../styles/AutocompleteStyles";
import useSwConfigInstType from "../../../Academics/hooks/useSwConfigInstType";
const { AccountsTitles } = require("../../json/title.json");
interface recepitsw {
  acct_ldgr_id: number;
}
interface Props {
  pageType: PageFor;
  modalFlag?: boolean;
  setModalFlag?: (modalFlag: boolean) => void;
  vtype?: VoucherPageType;
}
const ledgerOptions: optionsType[] = [
  { label: "Cash Book", value: BankOrCash.CASH },

  { label: "Bank Book", value: BankOrCash.BANK },
];
const Index = ({ pageType, setModalFlag, vtype }: Props) => {
  const { format } = formatter;
  const { institutionType } = useSwConfigInstType();

  const { firstDay, lastDay } = DateRange(new Date().toString()) || {};
  const { dispatch, state } = useContext(AppContext);
  const [rcptDate, setRcptDate] = useState("");
  const { Accounts_Table } = useAcctTableJson();
  const { token } = useToken();
  const navigate = useNavigate();
  const { InstDetails } = useInstDetails(1);

  const { data: serverDateData, loading: serverDateLoading } =
    useServerDateandTime();
  const [searchData, setsearchData] = useState("");
  const [imageString, setImageString] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [printConfig, setPrintConfigModal] = useState(false);

  const [remarks, setRemarks] = useState("");
  const { InstId } = useParams<{ InstId: string }>();
  const [items, setItems] = useState<RefundAmount[]>([]);
  const [refundAmount, setRefundAmount] = useState(0);
  const [inEditMode, setInEditMode] = useState({
    status: false,
    rowKey: 0,
  });
  const { configData: enableChallanReceipt } = useSwConfigData(
    InstitutionConfigurationTypes.ENABLE_CHALLAN_RECEIPT
  );
  const challanFlag =
    enableChallanReceipt.data &&
    enableChallanReceipt.data.GetSwConfigVariables[0].config_boolean_value;
  const [receipts, setReceipts] = useState<recepitsw[]>([]);
  const [printModal, SetPrintModal] = useState(false);
  // eslint-disable-next-line
  const [tableData, setTableData] = useState<Data[]>([]);
  // eslint-disable-next-line
  const [v_no, set_vo_no] = useState("");
  // eslint-disable-next-line
  const [v_date, set_v_Date] = useState("");
  //use states for receipts
  const [ledgerType, setLedgerType] = useState<optionsType | null>(null);
  const [authorizedBy, setAuthorizedBy] = useState<optionsType | null>(null);
  const [cashAccount, setCashAccount] = useState(false);
  const [bankAccount, setBankAccount] = useState(false);
  const [bankTransactionType, setBankTransactionType] =
    useState<optionsType | null>(null);

  const [referenceNumber, setReferenceNumber] = useState("");
  const [transactionBookLedgerId, setTransactionLedgerId] =
    useState<responseType | null>(null);
  const [studentModal, setStudentModal] = useState(false);
  const [message, setMessage] = useState<msgType>({
    flag: false,
    message: "",
    operation: Operation.NONE,
  });

  const [viewRefundDetailsModal, setViewRefundAmountDetailsModal] =
    useState(false);

  const [admNo, setAdmNo] = useState("");

  const transactionRefNumberRef = useRef<HTMLInputElement>(null);
  const saveButtonRef = useRef<HTMLButtonElement>(null);
  const modeOfTransactionRef = useRef<HTMLSelectElement>(null);
  const modeOfTransactionInputRef = modeOfTransactionRef.current?.childNodes[0]
    .childNodes[0].childNodes[0] as HTMLInputElement;
  // use queries

  const { user_details } = useLoggedInUserDetails();
  const { studentAddmissionNumber } = useAcctStdAdmissionNumber(
    admNo,
    NumberOfAdmissionNumberToBeDisplayed,
    StudentAcctReportType.PARTIALLY_OR_COMPLETELY_PAID
  );

  const { studentData, studentFormData } = useStudentDatabyId();
  const { USE_CATEGORY_KEY } = useInstitutionConfiguration();

  const { acctLedgers: CashLedgers } = useAcctLedgerData(
    searchData,
    AcctLedgerQueryType.ACCT_LDGRS_CASH,
    ROWS_PER_PAGE
  );
  const { acctLedgers: BankLedgers } = useAcctLedgerData(
    searchData,
    AcctLedgerQueryType.ACCT_LDGRS_BANK,
    ROWS_PER_PAGE
  );
  const {
    PredefinedData: { dropDown: bankTransactionTypes },
  } = usePredefinedDataByType(PredefinedDataTypes.BANK_COLLECTION, "");

  const { voucherNumber } = useVoucherNumber(
    VoucherBookKey.STUDENT_REFUND_RECEIPT_BOOK
  );
  const { configData: configKeys } = useSwConfigData([
    InstitutionAcctConfigurationTypes.STD_RECEIPT_EDIT_DATE,
  ]);
  const filterDataByConfigKey = (data: GlobalPageConfigData[]) => {
    let editDate = false;
    if (data) {
      data.forEach((item) => {
        switch (item.config_key) {
          case InstitutionAcctConfigurationTypes.STD_RECEIPT_EDIT_DATE:
            editDate = item.config_boolean_value;
            break;

          default:
            break;
        }
      });
    }
    return {
      editDate,
    };
  };
  const { editDate } = filterDataByConfigKey(
    configKeys.data?.GetSwConfigVariables!
  );
  const { InstreferenceData } = useInstReferenceDataByType(
    InstReferenceDataByType.AUTHORIZER
  );
  //lazy queries

  const { StudentDemandDetails } = useStudentDemandDetails(
    DemandDetailsQueryType.DEMAND_DETAILS_TO_REFUND,
    false,
    EMPTY_STRING
  );
  //mutations
  const [GenerateStudentReceipts, { loading: GenerateStudentReceiptsLoading }] =
    useMutation<
      AddAcctVoucherMasterAndDetailsData,
      AddAcctVoucherMasterAndDetailsVars
    >(AddAcctVoucherMasterAndDetails, {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    });
  let totaldemandAmount = 0;
  let totalFeeCollected = 0;
  let totalRefundAmount = 0;
  let totalRefundedAmount = 0;
  // eslint-disable-next-line
  items.map((item) => {
    totaldemandAmount += item.fee_demand;
    totalFeeCollected += item.fee_received;
    totalRefundAmount += item.fee_refunds;
    totalRefundedAmount += item.fee_refunded;
  });

  const onEdit = (id: number, updatedRcptAmount: number) => {
    setInEditMode({
      status: true,
      rowKey: id,
    });
    setRefundAmount(updatedRcptAmount);
  };

  const HandleEditItem = (sl_number: number) => {
    const itemIndex = items.findIndex((item) => item.id === sl_number);
    if (itemIndex === -1) return;
    if (
      refundAmount >
      items[itemIndex].fee_received - items[itemIndex].fee_refunded
    ) {
      alert("Please check you're refund amount");
      return;
    }

    const updatedData = [...items];
    updatedData[itemIndex].fee_refunds = refundAmount;
    setItems(updatedData);
  };
  const handleReceipts = () => {
    if (transactionBookLedgerId)
      setReceipts((items) => [
        ...items,
        {
          acct_ldgr_id: transactionBookLedgerId.value,
        },
      ]);
  };
  const handleClear = () => {
    setItems([]);
    dispatch({
      type: payloadTypes.SET_STUDENT_ID,
      payload: {
        studentId: 0,
      },
    });
    setAdmNo("");
    setReceipts([]);
    setTransactionLedgerId(null);
    setLedgerType(null);
    setRemarks("");
    setAuthorizedBy(null);
    setImageString(EMPTY_STRING);
    setReferenceNumber(EMPTY_STRING);

    setBankTransactionType(null);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  const handleStudentReceipts = (e: React.FormEvent) => {
    e.preventDefault();
    if (totalRefundAmount === 0) {
      alert("Please check ur refund amount");
      return;
    }
    const res = bankTransactionTypes.find(
      ({ value }) => bankTransactionType && value === bankTransactionType.value
    );
    GenerateStudentReceipts({
      variables: {
        token,
        inst_id: InstId!,
        user_details,
        fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
        input: [
          {
            acct_voucher_master: {
              fin_yr: state.ActiveFinYr
                ? state.ActiveFinYr.fin_yr
                : EMPTY_STRING,
              v_type: ReceiptTypes.PYMT,
              v_no: voucherNumber.data?.GetVoucherNumber.vo_number,
              v_date: dayjs(rcptDate).format(),
              v_total_cr_amt: totalRefundAmount,
              v_total_db_amt: totalRefundAmount,
              v_reconciled: false,
              v_reconciled_date: dayjs(DEFAULT_TIME).format(),
              v_std_receipt: true,
              v_std_refund: true,
              v_std_anonymous_deposit_adjusted: false,
              v_std_receipt_ob: false,
              v_std_refund_deposit: false,
              v_std_deposit_adjusted: false,
              v_book_type: VoucherBookKey.STUDENT_REFUND_RECEIPT_BOOK,
              v_std_deposit: false,
              v_std_demand_receipt: false,
              v_std_scholarship_deposit: false,

              student_id: state.studentId,
              v_std_non_demand_receipt: false,
              v_std_passout_receipt: false,
              class_id: studentData.data?.nodes[0].class.id,
              semester_id: studentData.data?.nodes[0].semester.id,
              v_std_amt_receipt: 0,
              v_std_amt_deposit: 0,
              v_std_amt_fine: 0,
              v_std_amt_total: totalRefundAmount,
              v_std_amt_refunded: totalRefundAmount,
              v_std_receipt_anonymous: false,
              v_std_amt_adjusted: 0,
              v_std_enquiry: false,
              enquiry_student_id: 0,
              v_transcation_type: res?.label ?? "Cash Book",
              v_transcation_cash_or_bank: res
                ? BankOrCash.BANK
                : BankOrCash.CASH,
              v_transcation_no: referenceNumber,
              v_transcation_date: dayjs(DEFAULT_TIME).format(),
              v_transcation_narration:
                remarks +
                ";" +
                (authorizedBy ? authorizedBy.label : EMPTY_STRING),
              // paid_party_id: "",
              party_bill_no:
                state.studentId && voucherNumber.data
                  ? voucherNumber.data.GetVoucherNumber.vo_number
                  : EMPTY_STRING,
              party_bill_date: dayjs(DEFAULT_TIME).format(),
              party_name: `${studentFormData.first_name} ${studentFormData.middle_name} ${studentFormData.last_name}`,
              annx_yesno: false,
              // annx_id: Math.random() * 1000,
              is_vouch_multi_entry: false,
              acct_ldgr_id_cr: receipts[0].acct_ldgr_id,
              acct_ldgr_id_db: items.filter((f) => f.fee_refunds !== 0)[0]
                .acct_ldgr_id,
            },
            acct_voucher_db: items
              .filter((f) => f.fee_refunds !== 0)
              .map((item, index) => ({
                vo_cr_db: DebitOrCredit.DEBIT,
                vo_sl_no: index + 1,
                vo_cr: 0,
                vo_db: item.fee_refunds,
                vo_cr_total: 0,
                vo_db_total: totalRefundAmount,
                acct_ldgr_id: item.acct_ldgr_id,
              })),
            acct_voucher_cr: receipts.map((receipt, index) => ({
              vo_sl_no: index + 1,
              vo_cr_db: DebitOrCredit.CREDIT,
              vo_cr: totalRefundAmount,
              vo_db: 0,
              vo_cr_total: totalRefundAmount,
              vo_db_total: 0,
              acct_ldgr_id: receipt.acct_ldgr_id,
            })),
          },
        ],
      },
      refetchQueries: [
        {
          query: GetAcctVouchers,
          variables: {
            after: null,
            direction: Direction.ASC,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,

            first: ROWS_PER_PAGE,
            sortBy: SortBy.V_DATE,
            token,
            name: EMPTY_STRING,
            input: {
              inst_id: InstId!,
              voucher_query_type: VoucherQueryTypes.BY_DATES,

              vo_end_date: dayjs(lastDay!).format(),
              acct_ldgr_id: 0,
              vo_start_date: dayjs(firstDay).format(),
              vo_type: EMPTY_STRING,
            },
          },
        },

        {
          query: GetAcctVouchers,
          variables: {
            after: null,
            amount: null,
            deposit: null,
            direction: Direction.ASC,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            first: ROWS_PER_PAGE,
            input: {
              acct_ldgr_id: 0,
              inst_id: InstId!,
              student_id: 0,
              user_details,
              vo_end_date: dayjs(lastDay!).format(),
              vo_start_date: dayjs(firstDay).format(),
              vo_type: EMPTY_STRING,
              voucher_query_type: VoucherQueryTypes.REFUND_VOUCHERS,
            },
            ldgrDesc: EMPTY_STRING,
            partyName: EMPTY_STRING,
            sortBy: SortBy.V_DATE,
            token,
            vTranscationCashOrBank: null,
            vTranscationNarration: EMPTY_STRING,
            vTranscationNo: EMPTY_STRING,
            v_no: EMPTY_STRING,
          },
        },
        {
          query: GetVoucherNumber,
          variables: {
            token,
            inst_id: InstId!,
            vo_book_key: VoucherBookKey.STUDENT_REFUND_RECEIPT_BOOK,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        const { v_no, v_date, id } = data.AddAcctVoucherMasterAndDetails[0];
        dispatch({
          type: payloadTypes.SET_RECEPIT_ID,
          payload: { receiptId: id },
        });
        setTableData(
          items
            .filter((item) => item.fee_refunds > 0)
            .map((item) => ({
              particular: item.acct_ldgr_details.ldgr_desc,
              amount: item.fee_refunds,
            }))
        );
        set_v_Date(v_date);
        set_vo_no(v_no);

        !printModal ? SetPrintModal(!printModal) : handleClear();
        setMessage({
          message: `Successfully generated fee receipt for student ${studentData.data?.nodes[0].first_name}`,
          flag: true,
          operation: Operation.CREATE,
        });
      }
    });
  };

  useEffect(() => {
    if (StudentDemandDetails.data) {
      const result = StudentDemandDetails.data?.GetAcctStdDemandDetails.filter(
        (item) => item !== null
      ).map((item) => ({
        id: item.id!,
        fee_ob: item.fee_ob,
        fee_demand: item.fee_demand,
        fee_concession: item.fee_concession,
        fee_received: item.fee_received,
        fee_refunds:
          item.fee_received > item.fee_refunds
            ? item.fee_received - item.fee_refunds
            : 0,
        acct_ldgr_id: item.acct_ldgr_id!,
        fee_bal: item.fee_bal,
        fee_refunded: item.fee_refunds,
        fee_receivable: item.fee_receivable,
        acct_ldgr_details: item.acct_ldgr_details,
      }));

      setItems(result!);
    }
  }, [state.studentId, token, StudentDemandDetails.data]);

  useEffect(() => {
    if (serverDateData && !serverDateLoading) {
      setRcptDate(serverDateData.GetServerDateAndTime);
    }
  }, [serverDateData, serverDateLoading]);
  useEffect(() => {
    if (transactionBookLedgerId) {
      handleReceipts();
    } // eslint-disable-next-line
  }, [transactionBookLedgerId]);
  useEffect(() => {
    if (
      studentData.data &&
      studentData.data?.nodes[0].std_profile_filename !== "" &&
      InstDetails.data
    ) {
      // eslint-disable-next-line
      const studentProfiePicUrl = `${InstDetails.data?.nodes[0]?.inst_name}/students/${state.studentId}/std_profile_pic/std_profile_pic`;
      // eslint-disable-next-line
      const ers = getDownloadUrl(studentProfiePicUrl, false, setImageString);
    }
  }, [studentData.data, InstDetails.data, state.studentId]);
  const { branchLabel, classLabel, categoryLabel } = useInstLabels();
  useEffect(() => {
    if (challanFlag) {
      setLedgerType({
        label: "Bank Book",
        value: BankOrCash.BANK,
      });
      setBankAccount(true);
      setCashAccount(false);
    }
  }, [challanFlag]);
  return (
    <>
      {pageType === PageFor.GENERAL ? <Home DashBoardRequired={false} /> : null}
      <div className="row g-0">
        <div className="col">
          <Title>
            {AccountsTitles.RefundsFee.Titles.map(
              (title: RefundAcademicFeeTitleProps) => {
                return (
                  <React.Fragment key={title.Refund_Academic_Fee}>
                    {title.Refund_Academic_Fee}
                  </React.Fragment>
                );
              }
            )}
          </Title>
        </div>

        <div className="configuration-settings">
          {pageType === PageFor.MODAL && (
            <>
              <img src={Enlarge} alt="/" onClick={() => toggleFullSceen()} />
            </>
          )}
        </div>
      </div>

      <div className="row g-0 refund-academic-fee">
        <div className="col refund-academic-fee__frame ">
          <div className="refund-academic-fee__frame--imagefield">
            {pageType === PageFor.GENERAL ? (
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={studentAddmissionNumber}
                openOnFocus
                value={
                  state.studentId
                    ? studentAddmissionNumber?.find(
                        ({ value }) => value === state.studentId
                      )!
                    : null
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    dispatch({
                      type: payloadTypes.SET_STUDENT_ID,
                      payload: {
                        studentId: (newValue as responseType)?.value,
                      },
                    });
                  } else {
                    handleClear();
                  }
                }}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (
                    e.key === Keys.ENTER &&
                    state.studentId &&
                    items[0].fee_received > 0
                  ) {
                    onEdit(items[0].id, items[0].fee_refunds);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    dispatch({
                      type: payloadTypes.SET_STUDENT_ID,
                      payload: {
                        studentId: 0,
                      },
                    });
                  }
                }}
                autoHighlight
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onChange={(e) => setAdmNo(e.target.value)}
                    autoFocus
                    label="Admission No."
                    fullWidth
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            ) : (
              <TextField
                id="Admission No."
                label="Register Number"
                className="refund-academic-fee__frame--textfield"
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                value={studentFormData?.adm_no}
                disabled
              />
            )}

            {pageType === PageFor.GENERAL ? (
              <img
                className="data-fetch-icon"
                src={Edit}
                alt="/"
                onClick={() => setStudentModal(!studentModal)}
              />
            ) : null}
          </div>

          {institutionType !== InstitutionType.SCHOOL && (
            <TextField
              label="Register Number"
              className="refund-academic-fee__frame--textfield"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              value={studentFormData?.reg_number}
              disabled
            />
          )}
          <TextField
            label={classLabel}
            className="refund-academic-fee__frame--textfield"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            value={studentFormData?.class}
            disabled
          />
        </div>

        <div className="col refund-academic-fee__frame g-0">
          <TextField
            label="Name"
            className="refund-academic-fee__frame--textfield"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            value={studentFormData?.std_name}
            disabled
          />
          <TextField
            label="Father Name"
            className="refund-academic-fee__frame--textfield"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            value={studentFormData?.father_name}
            disabled
          />
          <TextField
            label={branchLabel}
            className="refund-academic-fee__frame--textfield"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            value={studentFormData?.branch}
            disabled
          />
        </div>
        <div className="col refund-academic-fee__frame g-0">
          <TextField
            type="date"
            label="Date"
            className="refund-academic-fee__frame--textfield--date"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
              input: {
                inputProps: {
                  min: state.ActiveFinYr
                    ? toInputStandardDate(state.ActiveFinYr.fin_st_date!)
                    : EMPTY_STRING,
                  max: toInputStandardDate(TODAY_DATE),
                },
              },
            }}
            value={toInputStandardDate(rcptDate)}
            onChange={(e) => setRcptDate(e.target.value)}
            disabled={editDate ? false : true}
          />
          <Label variant="present-day">{NameOfTheDay(rcptDate)}</Label>
          {USE_CATEGORY_KEY && (
            <TextField
              label={categoryLabel}
              className="refund-academic-fee__frame--textfield"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              value={studentFormData?.category}
              disabled
            />
          )}
        </div>
        <div className="col-1 refund-academic-fee__frame--image h-100">
          {imageString === EMPTY_STRING ? (
            <img src={Avatar} alt="/" />
          ) : (
            <img src={imageString} alt="/" />
          )}
        </div>
      </div>
      <div
        className={
          pageType === PageFor.GENERAL
            ? "row g-0 refund-academic-fee__tableblock"
            : "row g-0 refund-academic-fee__tableblock--modal"
        }
      >
        {state.studentId ? (
          <TableContainer className="refund-academic-fee__table g-0">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Accounts_Table.RefundAcademicFee.Table_Headers.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return (
                        <TableCell key={index} className={th.className}>
                          {th.labelName}
                        </TableCell>
                      );
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((item, index) => {
                  return (
                    <TableRow key={item.id}>
                      <TableCell
                        className="refund-academic-fee__table--slno"
                        align="center"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell>{item.acct_ldgr_details.ldgr_desc}</TableCell>
                      <TableCell
                        id="td-right"
                        className="refund-academic-fee__table--fee"
                      >
                        {format(item.fee_demand)}
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className="refund-academic-fee__table--fee"
                      >
                        {format(item.fee_received)}
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className="refund-academic-fee__table--fee"
                      >
                        {format(item.fee_refunded)}
                      </TableCell>
                      <TableCell
                        id="td-right"
                        onClick={() => {
                          if (item.fee_received > 0) {
                            onEdit(item.id!, item.fee_refunds);
                          } else {
                            alert("student has not paid any amount to refund");
                          }
                        }}
                        className="refund-academic-fee__table--fee editCell"
                      >
                        {inEditMode.status && inEditMode.rowKey === item.id ? (
                          <input
                            autoFocus
                            type="number"
                            value={refundAmount}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              if (Number(e.target.value) >= 0)
                                setRefundAmount(Number(e.target.value));
                            }}
                            onFocus={(e: React.ChangeEvent<HTMLInputElement>) =>
                              e.target.select()
                            }
                            onKeyDown={(event: React.KeyboardEvent) => {
                              if (event.key === Keys.ENTER) {
                                event.preventDefault();
                                if (items[index + 1] && items[index + 1].id) {
                                  onEdit(
                                    items[index + 1].id!,
                                    items[index + 1].fee_refunds
                                  );
                                  HandleEditItem(item.id!);
                                } else {
                                  onEdit(
                                    items[index].id!,
                                    items[index].fee_refunds
                                  );
                                  HandleEditItem(item.id!);

                                  setInEditMode({
                                    rowKey: -805,
                                    status: false,
                                  });
                                  modeOfTransactionInputRef?.focus();
                                }
                              }
                            }}
                          />
                        ) : (
                          format(item.fee_refunds)
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={2} className="total">
                    Total :
                  </TableCell>
                  <TableCell id="td-right" className="totalcount">
                    {format(totaldemandAmount)}
                  </TableCell>
                  <TableCell id="td-right" className="totalcount">
                    {format(totalFeeCollected)}
                  </TableCell>
                  <TableCell id="td-right" className="totalcount">
                    {format(totalRefundedAmount)}
                  </TableCell>
                  <TableCell id="td-right" className="totalcount">
                    {format(totalRefundAmount)}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        ) : null}
      </div>
      <form onSubmit={handleStudentReceipts}>
        <div className="row g-0 refund-academic-fee__receiptblock">
          <div className="col account-frames">
            <div className="label-grid">
              <Label>Receipt No.</Label>
              <Input
                disabled
                value={
                  state.studentId && voucherNumber.data
                    ? voucherNumber.data.GetVoucherNumber.vo_number
                    : EMPTY_STRING
                }
              />
              <Label> Mode of Transaction</Label>
              {challanFlag ? (
                <Input value={ledgerType?.label} disabled />
              ) : (
                <RequiredAutoComplete
                  className={formClasses.inputRoot}
                  options={ledgerOptions}
                  openOnFocus
                  ref={modeOfTransactionRef}
                  autoHighlight
                  value={ledgerType}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setLedgerType(newValue as optionsType);
                      setTransactionLedgerId(null);

                      if ((newValue as optionsType).value === BankOrCash.BANK) {
                        setBankAccount(true);
                        setCashAccount(false);
                      } else {
                        setCashAccount(true);
                        setBankAccount(false);
                      }
                    } else {
                      setLedgerType(null);
                    }
                  }}
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (e.key === Keys.ENTER && ledgerType) {
                      handleMUISelectEvent(e);
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setLedgerType(null);
                    }
                  }}
                  popupIcon={<img src={DownArrow} alt="/" />}
                  forcePopupIcon
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      className={formClasses.formControlRoot}
                      required
                    />
                  )}
                />
              )}
              <Label>Authorized By</Label>
              <FormAutocomplete
                className={formClasses.inputRoot}
                options={InstreferenceData.optionsType}
                openOnFocus
                autoHighlight
                value={authorizedBy}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setAuthorizedBy(newValue as optionsType);
                  } else {
                    setAuthorizedBy(null);
                  }
                }}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === Keys.BACKSPACE) {
                    setAuthorizedBy(null);
                  } else if (e.key === Keys.ENTER) {
                    handleMUISelectEvent(e);
                  }
                }}
                popupIcon={<img src={DownArrow} alt="/" />}
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    className={formClasses.formControlRoot}
                    required
                  />
                )}
              />
            </div>
          </div>
          {cashAccount ? (
            <div className="col label-grid account-frames">
              <Label> Cash Ledgers</Label>
              <RequiredAutoComplete
                className={formClasses.inputRoot}
                options={CashLedgers.responseType!}
                openOnFocus
                autoHighlight
                // ref={cashAccount ? cashLedgerRef : null}
                value={transactionBookLedgerId}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setTransactionLedgerId(newValue as responseType);
                    setReceipts([]);
                  } else {
                    setTransactionLedgerId(null);
                    setReceipts([]);
                  }
                }}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (
                    transactionBookLedgerId &&
                    transactionBookLedgerId.value &&
                    e.key === Keys.ENTER
                  ) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setTransactionLedgerId(null);
                  }
                }}
                popupIcon={<img src={DownArrow} alt="/" />}
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    onChange={(e) => setsearchData(e.target.value)}
                    className={formClasses.formControlRoot}
                    required
                  />
                )}
              />
            </div>
          ) : null}
          {bankAccount ? (
            <div className="col account-frames">
              <div className="student-fee-receipt__receiptblock--frame--bankdetails">
                <Label> Bank Transaction Type</Label>
                <RequiredAutoComplete
                  className={formClasses.inputRoot}
                  options={bankTransactionTypes!}
                  openOnFocus
                  autoHighlight
                  value={bankTransactionType}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setBankTransactionType(newValue as optionsType);
                    } else {
                      setBankTransactionType(null);
                    }
                  }}
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (ledgerType && e.key === Keys.ENTER) {
                      handleMUISelectEvent(e);
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setBankTransactionType(null);
                    }
                  }}
                  popupIcon={<img src={DownArrow} alt="/" />}
                  forcePopupIcon
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      className={formClasses.formControlRoot}
                      required
                    />
                  )}
                />
              </div>
              <div className="student-fee-receipt__receiptblock--frame--bankdetails">
                <Label> Bank Ledgers</Label>
                <RequiredAutoComplete
                  className={formClasses.inputRoot}
                  options={BankLedgers.responseType!}
                  openOnFocus
                  autoHighlight
                  value={transactionBookLedgerId}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setTransactionLedgerId(newValue as responseType);
                      setReceipts([]);
                    } else {
                      setTransactionLedgerId(null);
                      setReceipts([]);
                    }
                  }}
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (
                      transactionBookLedgerId &&
                      transactionBookLedgerId.value &&
                      e.key === Keys.ENTER
                    ) {
                      handleMUISelectEvent(e);
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setTransactionLedgerId(null);
                    }
                  }}
                  popupIcon={<img src={DownArrow} alt="/" />}
                  forcePopupIcon
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      onChange={(e) => setsearchData(e.target.value)}
                      className={formClasses.formControlRoot}
                      required
                    />
                  )}
                />
              </div>
              <div className="student-fee-receipt__receiptblock--frame--bankdetails">
                <Label>Transaction Reference Number</Label>
                <Input
                  inputRef={transactionRefNumberRef}
                  value={referenceNumber}
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (e.key === Keys.ENTER) {
                      handleMUISelectEvent(e);
                    }
                  }}
                  required={ledgerType ? ledgerType.value === "B" : false}
                  type="text"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setReferenceNumber(e.target.value)
                  }
                />
              </div>
            </div>
          ) : null}
          <div className="col  account-frames label-grid">
            <Label>Remarks :</Label>
            <TextArea
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  saveButtonRef.current?.focus();
                }
              }}
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
              required
              rows={3}
            />
          </div>
        </div>
        <div className="refund-academic-fee__btns">
          <Button mode="save" type="submit" buttonref={saveButtonRef} />
          <Button
            mode="delete"
            type="button"
            onClick={() => setDeleteModal(!deleteModal)}
          />
          <Button
            mode="view"
            type="button"
            onClick={() =>
              setViewRefundAmountDetailsModal(!viewRefundDetailsModal)
            }
          />
          <Button
            mode="clear"
            onClick={() => handleClear()}
            disabled={!state.studentId}
          />
          {pageType === PageFor.GENERAL ? (
            <Button
              mode="back"
              onClick={() => {
                dispatch({
                  type: payloadTypes.SET_STUDENT_ID,
                  payload: {
                    studentId: 0,
                  },
                });
                navigate(-1);
              }}
              type="button"
            />
          ) : (
            <Button
              mode="cancel"
              type="button"
              onClick={() => setModalFlag?.(false)}
            />
          )}
        </div>
      </form>

      {/* student-modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={studentModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentList
              pageType={PageFor.MODAL}
              studentListFor={StudentListFor.ACCOUNTS}
              queryType={StudentAcctReportType.PARTIALLY_OR_COMPLETELY_PAID}
              setStudentModal={setStudentModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              onClick={() => setStudentModal(!studentModal)}
              src={Close}
              alt="/"
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>

      {/* view-refund-modal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={viewRefundDetailsModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ViewRefundedList
              pageType={PageFor.MODAL}
              setModal={setViewRefundAmountDetailsModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              alt="/"
              src={Close}
              className="modal-close-icon"
              onClick={() =>
                setViewRefundAmountDetailsModal(!viewRefundDetailsModal)
              }
            />
          </div>
        </div>
      </Modal>

      {/* deleteModal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={deleteModal}
        style={DeleteLedgerModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Delete
              v_type={VoucherQueryTypes.REFUND_VOUCHERS}
              setModalFlag={setDeleteModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setDeleteModal(!deleteModal)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={printModal}
        style={PrintModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <VoucherPrint setModalFlag={SetPrintModal} />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => {
                dispatch({
                  type: payloadTypes.SET_STUDENT_ID,
                  payload: {
                    studentId: 0,
                  },
                });
                SetPrintModal(!printModal);
                handleClear();
              }}
            />
            <img
              src={Settings}
              alt="/"
              id="settings-icon"
              onClick={() => setPrintConfigModal(!printConfig)}
            />
          </div>
        </div>
      </Modal>

      {/* print configuration modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={printConfig}
        style={PrintConfigModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <PerModuleConfiguration
              config_query_type={SwConfigQueryType.INST_BY_MODULE}
              str_value={ModuleName.VOUCHER_PRINT}
              int_value={0}
              setModalFlag={setPrintConfigModal}
              pageType={PageFor.MODAL}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setPrintConfigModal(!printConfig)}
            />
          </div>
        </div>
      </Modal>
      <LoadingModal flag={GenerateStudentReceiptsLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default Index;
