import { useLazyQuery } from "@apollo/client";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import useActiveAcademicYear from "../../../../Academics/hooks/useActiveAcademicYear";
import { GetAcdStudentsMarksStatisticsAtEntryLevel } from "../../../../Academics/queries/test/query";
import useToken from "../../../../../customhooks/useToken";
import { responseType } from "../../../../../utils/Form.types";
import useInstitutionConfiguration from "../../../../../customhooks/useInstitutionConfiguration";
import useInstLogoDetails from "../../../../../customhooks/useInstLogoDetails";
import useInstDetails from "../../../../../customhooks/general/useInstDetails";
import { EMPTY_STRING } from "../../../../../utils/constants";

import ReactToPrint from "react-to-print";
import { Button } from "../../../../../stories/Button/Button";
import { AppContext } from "../../../../../context/context";
import { FileUploadParams, RPTemplates } from "../../../../../utils/Enum.types";
import usePerTestReportConfig from "../../hooks/usePerTestReportConfig";
import { getDownloadUrl } from "../../../../../utils/DownloadFileForChat";
import PerTestTemplate1 from "./PerTestTemplate1";
import PerTestTemplate2 from "./PerTestTemplate2";
interface SubjectMarks {
  subj_master_id: number;
  subj_desc: string;
  subj_code: string;
  std_marks_scored: number;
  marks_percentage: number;
  subj_total_max_marks: number;
  subj_total_min_marks: number;
  subj_max_marks_scored: number;
  subj_avg_marks_scored: number;
  is_std_present: boolean;
  scored_grade: string;
}
interface StudentAcdDetails {
  std_adm_no: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  branch_desc: string;
  class_desc: string;
  section_desc: string;
  std_profile_filename: string;
  std_father_name: string;
  std_mother_name: string;
  std_dob: string;
}

interface GetAcdStudentsMarksStatisticsAtEntryLevelDetails {
  student_id: number;
  acd_test_class_id: number;
  test_start_date: string;
  test_end_date: string;
  test_type_name: string;
  acd_test_name: string;
  test_total_max_marks: number;
  test_total_min_marks: number;
  test_top_percentage: number;
  test_avg_percentage: number;
  att_percentage: number;
  test_remarks: string;
  test_std_total_marks_scored: number;
  test_std_total_percentage: number;
  student_details: StudentAcdDetails;
  marks_details: SubjectMarks[];
}
interface GetAcdStudentsMarksStatisticsAtEntryLevelData {
  GetAcdStudentsMarksStatisticsAtEntryLevel: GetAcdStudentsMarksStatisticsAtEntryLevelDetails[];
}
interface StdStatisticsInput {
  inst_id: number | string;
  acd_test_class_id: number;
  entry_level: string;
  entry_id: number;
  test_type_id: number;
}
interface GetAcdStudentsMarksStatisticsAtEntryLevelVars {
  token: string;
  inst_id: string | number;
  acd_yr_id: number;
  student_ids: number[];
  input: StdStatisticsInput;
  acd_subj_combo_id: number | string;
}
interface Props {
  testConductId: responseType | null;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  selectedStudents: number[];
  setStudentIdSet: React.Dispatch<React.SetStateAction<Set<number>>>;
  forCombination?: boolean;
}
interface stdDataType extends GetAcdStudentsMarksStatisticsAtEntryLevelDetails {
  profile_pic: string;
}
const ProgressReport = ({
  testConductId,
  setModalFlag,
  selectedStudents,
  setStudentIdSet,
  forCombination,
}: Props) => {
  const { token } = useToken();
  const { entry_level } = useInstitutionConfiguration();
  const componentRef = useRef<HTMLDivElement>(null);
  const instLogoDetails = {
    LogoOrSign: useInstLogoDetails({ filetype: FileUploadParams.INST_LOGO })
      .LogoOrSign,
    PrincipalSign: useInstLogoDetails({
      filetype: FileUploadParams.PRINCIPAL_SIGNATURE,
    }).LogoOrSign,
    Photo1: useInstLogoDetails({ filetype: FileUploadParams.PHOTO_1 })
      .LogoOrSign,
    Photo2: useInstLogoDetails({ filetype: FileUploadParams.PHOTO_2 })
      .LogoOrSign,
  };
  const [stdData, setStdData] = useState<stdDataType[]>([]);
  const { InstFormData, InstDetails } = useInstDetails(1);
  const { state } = useContext(AppContext);
  const { InstId, entryId } = useParams();
  const { activeAcademicYearData } = useActiveAcademicYear();
  const [GetStats, { data: statsData, error }] = useLazyQuery<
    GetAcdStudentsMarksStatisticsAtEntryLevelData,
    GetAcdStudentsMarksStatisticsAtEntryLevelVars
  >(GetAcdStudentsMarksStatisticsAtEntryLevel);

  useEffect(() => {
    if (
      token &&
      testConductId &&
      entryId &&
      selectedStudents &&
      entry_level &&
      activeAcademicYearData.data &&
      !activeAcademicYearData.loading &&
      state.ActiveAcdYr
    ) {
      GetStats({
        variables: {
          token,
          inst_id: InstId!,
          student_ids: selectedStudents,
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          input: {
            inst_id: InstId!,
            acd_test_class_id: testConductId ? testConductId.value : 0,
            entry_level: entry_level,
            entry_id: entryId ? Number(entryId) : 0,
            test_type_id: 0,
          },
          acd_subj_combo_id:
            forCombination && state.acdCombinationId
              ? state.acdCombinationId
              : 0,
        },
      });
    } // eslint-disable-next-line
  }, [
    token,
    testConductId,
    entryId,
    selectedStudents,
    entry_level,
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
    GetStats,
    state.ActiveAcdYr,
    forCombination,
    statsData,
  ]);

  const [imageString, setImageString] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      if (statsData) {
        const promise: Promise<stdDataType[]> = new Promise(
          async (res, rej) => {
            let tempStudentList: stdDataType[] = [];
            for (
              let index = 0;
              index <
              statsData.GetAcdStudentsMarksStatisticsAtEntryLevel.length;
              index++
            ) {
              const res =
                statsData.GetAcdStudentsMarksStatisticsAtEntryLevel[index];

              if (res.student_details.std_profile_filename) {
                const filename = `${InstFormData.inst_name}/students/${res.student_id}/std_profile_pic/std_profile_pic`;
                const url = await getDownloadUrl(filename, false);

                if (url) {
                  tempStudentList.push({
                    ...res,
                    profile_pic: url,
                  } as stdDataType);
                } else {
                  tempStudentList.push({ ...res, profile_pic: "" });
                }
              } else {
                tempStudentList.push({ ...res, profile_pic: "" });
              }
            }
            if (
              statsData.GetAcdStudentsMarksStatisticsAtEntryLevel.length ===
              tempStudentList.length
            ) {
              res(tempStudentList);
            }
          }
        );
        promise.then((res) => {
          if (res.length) {
            setStdData(res);
          }
        });
      }
    };
    fetchData();
  }, [statsData]);

  useEffect(() => {
    if (
      statsData &&
      statsData.GetAcdStudentsMarksStatisticsAtEntryLevel.length &&
      statsData.GetAcdStudentsMarksStatisticsAtEntryLevel[0].student_details
        .std_profile_filename !== EMPTY_STRING &&
      InstDetails.data &&
      InstDetails.data.nodes.length
    ) {
      const studentProfiePicUrl = `${InstDetails.data.nodes[0]?.inst_name}/students/${statsData?.GetAcdStudentsMarksStatisticsAtEntryLevel[0].student_id}/std_profile_pic/std_profile_pic`;

      // eslint-disable-next-line
      const ers = getDownloadUrl(studentProfiePicUrl, false, setImageString);
    }
  }, [statsData, InstDetails.data]);

  const {
    templatePerTest,
    marginBottomPerTest,
    marginLeftPerTest,
    marginRightPerTest,
    marginTopPerTest,
  } = usePerTestReportConfig();

  return (
    <>
      <div className="progress-report" ref={componentRef}>
        <div className="progress-report__print">
          <style type="text/css" media="print" scoped>
            {`
                html,
    body {
        height: 100% !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;

    }
            @page {
          
          width:100%;
          height:100%;
padding-left: ${marginLeftPerTest?.config_integer_value}px;
              padding-right: ${marginRightPerTest?.config_integer_value}px;
              padding-top: ${marginTopPerTest?.config_integer_value}px;
              padding-bottom: ${marginBottomPerTest?.config_integer_value}px;  
        }
           
  .progress-report__print--form{
    padding:40px !important;
  }
    .progress-report__print {
    height: 100%;
}
        .progress-report {
    height: 100%;
   overflow: initial !important;
   }
 
        .rp-template-3 {
         

          height:100% !important;

        }  
             .rp-template-3__page1 {
         

          height:100% !important;

        } 
            .page-break {
            page-break-after: always !important;
            margin: 0px;
            padding: 0px;
        }

        .rp-template-3__page1{

        height:calc(100% - 40px) !important;
        }

          `}
          </style>
          {templatePerTest?.config_string_value === RPTemplates.TEMPLATE_1 ? (
            <PerTestTemplate1
              testConductId={testConductId}
              setModalFlag={setModalFlag}
              selectedStudents={selectedStudents}
              setStudentIdSet={setStudentIdSet}
              forCombination={forCombination}
            />
          ) : (
            <>
              <PerTestTemplate2
                testConductId={testConductId}
                setModalFlag={setModalFlag}
                selectedStudents={selectedStudents}
                setStudentIdSet={setStudentIdSet}
                forCombination={forCombination}
              />
            </>
          )}
        </div>
      </div>

      <ReactToPrint
        trigger={() => <Button mode="print" />}
        content={() => componentRef.current}
      />
      <Button
        mode="cancel"
        onClick={() => {
          setModalFlag(false);
          setStudentIdSet(new Set());
        }}
      />
    </>
  );
};

export default ProgressReport;
