import dayjs from "dayjs";
import { BookStatusEnum, Operation, WeeksEnum } from "./Enum.types";
import { msgType } from "./Form.types";
import { AcctOnlinePaymentQueryType } from "../Modules/Accounts/OnlineTransaction/TransactionTable";
export const AWS_ACCESS_KEY = "AKIAW545OOACVPLFFQVI";

export const AWS_SECRET_ACCESS_KEY = "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";
export const ROWS_PER_PAGE = 25;
export const ROWS_PER_PAGE_30 = 30;
export const LIBRARY_ROWS_PER_PAGE = 15;
export const FIRST_FEW_DATA = 10;
export const FETCH_MORE_DATA = 5;
export const CURRENT_YEAR = new Date().getFullYear();
export const CURRENT_MONTH = new Date().getMonth();
export const FINANCIAL_YEAR_START_DATE = `${CURRENT_YEAR}-04-01`;
export const FINANCIAL_YEAR_END_DATE = `${CURRENT_YEAR + 1}-03-31`;
export const TODAY_DATE = dayjs().format("YYYY-MM-DD");
export const NOTALLOCATED = "---";
export const DEFAULT_TIME = "1970-01-01";
export const NumberOfAdmissionNumberToBeDisplayed = 5;
export const DEBOUNCE_TIME_INTERVAL = 500;
export const STANDARD_DATE_FORMAT = "DD/MM/YYYY";
export const EMPTY_RESPONSETYPE = [
  {
    label: "**End of List**",
    value: -580,
    isChecked: false,
  },
];
export const EMPTY_SHELF_RESPONSE = {
  lib_rack_desc: "",
  lib_rack_no: "",
  id: 0,
};

export const EMPTY_SHELF_RESPONSE_OPTIONS = [EMPTY_SHELF_RESPONSE];
export const EMPTY_RESPONSETYPE_OBJECT = {
  label: "",
  value: 0,
  isChecked: false,
};
export const EMPTY_OPTIONSTYPE_OBJECT = {
  label: "",
  value: "",
};
export const DEFAULT_OPTIONS_5 = 5;
export const CURRENT_TIME = new Date().toLocaleString();

export const PAGINATION_ARRAY = [5, 10, 15, 25];

export const INPUT = "INPUT";
export const BUTTON = "BUTTON";
export const SELECT = "SELECT";
export const TEXTAREA = "TEXTAREA";
export const TABLE_DATA_PER_PAGE = "View Data Per Page";
export const EMPTY_RETURNVALUE = [
  {
    label: "",
    value: "",
    id: 0,
  },
];
export const EMPTY_STRING = "";
export const ZERO = "0";

export const CURRENT_PAGE = 1;
export const DEFAULT_NUMBER_VALUE = -580;
export const MULTI_SELECT_LIMIT_TAGS = 2;
export const AUTOCOMPLETE_CLEAR_CLASSNAME = "MuiAutocomplete-clearIndicator";
export const AUTOCOMPLETE_INPUT_CLASSNAME = "MuiInputBase-input";
export const MUI_RADIO_CLASSNAME = "PrivateSwitchBase-input";
export const AUTOCOMPLETE_POPUP_CLASSNAME = "MuiAutocomplete-popupIndicator";
export const IMAGE_FILE = /\.(jpe?g|png)$/i;
export const PDF_FILE = /\.(pdf)$/i;
export const muiCheckboxClassName = "PrivateSwitchBase-input css-1m9pwf3";
export const image_upload_size = 2400000;
export const convertMB2Bytes = 1024 * 1024;
export const LateFeePayment = "Late Fee Payment";
export const CURR = "CURR";
export const WeekDays = [
  { label: "Sunday", value: WeeksEnum.SUN },
  { label: "Monday", value: WeeksEnum.MON },
  { label: "Tuesday", value: WeeksEnum.TUE },
  { label: "Wednesday", value: WeeksEnum.WED },
  { label: "Thursday", value: WeeksEnum.THU },
  { label: "Friday", value: WeeksEnum.FRI },
  { label: "Saturday", value: WeeksEnum.SAT },
];
export const weeksJson = [
  { name: 1, label: "1st", isChecked: false },
  { name: 2, label: "2nd", isChecked: false },
  { name: 3, label: "3rd", isChecked: false },
  { name: 4, label: "4th", isChecked: false },
  { name: 5, label: "5th", isChecked: false },
];
export const BookStatus = [
  { label: "Issued", value: BookStatusEnum.ISSUED },
  {
    label: "OverDue",
    value: BookStatusEnum.OVERDUE,
  },
  {
    label: "Returned",
    value: BookStatusEnum.RETURNED,
  },
];
export const WINDOW_MIN_WIDTH = 960;
export const WINDOW_MIN_HEIGHT = 600;
export const WINDOW_MAX_ZOOM = 2.5;
export const ACCOUNTANT = "ACCOUNTANT";

export const Sunday = "Sunday";
export const Monday = "Monday";
export const Tuesday = "Tuesday";
export const Wednesday = "Wednesday";
export const Thursday = "Thursday";
export const Friday = "Friday";
export const Saturday = "Saturday";
export const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
export const query = "/query";
export const width = [0, 1, 2, 3, 4, 5];
export const ROWS_PER_PAGE_ON_SCROll = 10;

export const transactionStatusOptions = [
  {
    label: "All",
    value: AcctOnlinePaymentQueryType.ON_PY_RECORDS,
  },
  {
    label: "Success",
    value: AcctOnlinePaymentQueryType.ON_PY_SUCCESS_RECORDS,
  },
  {
    label: "In Complete",
    value: AcctOnlinePaymentQueryType.ON_PY_IN_COMPLETE_RECORDS,
  },
  {
    label: "Failed",
    value: AcctOnlinePaymentQueryType.ON_PY_FAILED_RECORDS,
  },
];

// Error code for success
export const E000 = "E000";

export const ONLINE_TRANSACTION_ROWS_PER_PAGE = 11;

export const emptyMessageType: msgType = {
  flag: false,
  message: "",
  operation: Operation.NONE,
};

export const formattingToolbarOptions = {
  toolbar: [
    ["bold", "italic"], // Include the options you want in the toolbar
    ["link"],
    // Headers
    [{ list: "ordered" }, { list: "bullet" }], // Lists
    ["clean"], // Remove formatting
  ],
};

export const formatOptions = [
  "bold",
  "italic",
  "underline",
  "strike", // Formats available
  // "header",
  "list",
  "link",
];

export const getTimeFormatted = (): string => {
  const currentDate = new Date();
  const formattedTime = currentDate.toLocaleTimeString("en-US", {
    hour12: false, // To display time in 24-hour format
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  return formattedTime;
};

export const getPercentageColor = (percentage: number) => {
  if (percentage >= 80) {
    return "#86efac";
  } else if (percentage >= 70 && percentage <= 79.9) {
    return "#38bdf8";
  } else if (percentage >= 60 && percentage <= 69.9) {
    return "#f472b6";
  } else if (percentage >= 50 && percentage <= 59.9) {
    return "#fdba74";
  } else if (percentage >= 40 && percentage <= 49.9) {
    return "#9ca3af";
  } else if (percentage <= 40 && percentage >= 1) {
    return "#e879f9";
  } else {
    return "var(--level-1)";
  }
};

export const getTextPercentageColor = (percentage: number) => {
  if (percentage < 45) {
    return "var(--text-color)";
  } else {
    return "white";
  }
};

export const inEditModeDefaultValue = {
  status: false,
  rowKey: 0,
};

export const DELETE = "DELETE";
export const ACTIVE = "MAKE ACTIVE";

export const AutoCloseButton =
  "css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator";

export const imageFormats = ["jpg", "jpeg", "png"];
export const empType = [
  {
    label: "Active",
    value: "CUR",
  },
  {
    label: "Resigned",
    value: "RESIGNED",
  },
  {
    label: "Long Leave",
    value: "LONG-LEAVE",
  },
  {
    label: "Dismissed",
    value: "DISMISSED",
  },
];
