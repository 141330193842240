import { useContext, useEffect, useState } from "react";
import usePrintConfig from "../Standard/hooks/usePrintConfiguration";
import useInstLogoDetails from "../../../../../customhooks/useInstLogoDetails";
import { FileUploadParams } from "../../../../../utils/Enum.types";
import useInstDetails from "../../../../../customhooks/general/useInstDetails";
import { PrintReceiptProps } from "../Standard/FeeReceiptPrint";
import { AppContext } from "../../../../../context/context";
import useStudentDatabyId from "../../../../../customhooks/useStudentDatabyId";
import usePassedOutStudent from "../../../../../customhooks/usePassedOutStudent";
import useEnquiryStudentDetails from "../../../../../customhooks/useEnquiryStudentDetails";
import { EMPTY_STRING } from "../../../../../utils/constants";
import { getDownloadUrl } from "../../../../../utils/DownloadFile";

import useInstDetailsConfigs from "./useInstDetailsConfigs";

export enum InstDetailsPageType {
  FEE_RECEIPT = "FEE_RECEIPT",
  NON_DEMAND = "NON_DEMAND"
}
export interface InstInfoProps extends PrintReceiptProps {
  pageType: InstDetailsPageType;
}

const InstInfo = ({
  studentDetails,
  tableData,
  v_date,
  v_no,
  transaction_no,
  pageType
}: InstInfoProps) => {
  const { state } = useContext(AppContext);
  const { InstFormData, InstDetails } = useInstDetails(1);
  const { studentFormData } = useStudentDatabyId();
  const { passedOutStudentFormData } = usePassedOutStudent();
  const {
    enquiryStudentData: { EnquiryQueryDetails }
  } = useEnquiryStudentDetails();

  const {
    printInstOrBranchName,
    printInstLogo,
    printReceiptHeader,
    printCustomerName,
    printInstName,
    printInstAddress,
    nonDemandReceiptInstOrBranchName,
    nonDemandReceiptInstLogo,
    nonDemandReceiptHeader,
    nonDemandReceiptCustomerName,
    nonDemandReceiptInstName,
    nonDemandReceiptInstAddress
  } = useInstDetailsConfigs();
  const stdBranchId = studentDetails
    ? studentDetails.branch.id
    : state.studentId || state.stdIdForChallan
    ? studentFormData.branch_id
    : state.aluminiStudentId || state.passOutStdIdForChallan
    ? passedOutStudentFormData.branch_id
    : EnquiryQueryDetails.data
    ? EnquiryQueryDetails.data.node.branch_details.id
    : 0;

  const { LogoOrSign } = useInstLogoDetails({
    filetype: FileUploadParams.INST_LOGO
  });
  const [imageString, setImageString] = useState("");

  // const {
  //   USE_CUSTOMER_NAME,
  //   USE_INST_NAME,
  //   USE_INST_ADDRESS,
  //   USE_LOGO,
  //   USE_RECEIPT_HEADER,
  //   PRINT_INST_OR_BRANCH_NAME,
  // } = usePrintConfig();

  useEffect(() => {
    if (
      InstDetails.data &&
      !InstDetails.loading &&
      InstDetails.data.nodes.length &&
      stdBranchId
    ) {
      // eslint-disable-next-line
      const res = getDownloadUrl(
        `${InstDetails.data.nodes[0].inst_name}/logos/branch/${stdBranchId}/logo`,
        false,
        setImageString
      );
    }
  }, [InstDetails.data, InstDetails.loading, stdBranchId]);

  const imgSrc: string =
    pageType === InstDetailsPageType.NON_DEMAND
      ? nonDemandReceiptInstOrBranchName === "I"
        ? LogoOrSign.defaultLogo
        : printInstOrBranchName === "B"
        ? imageString
        : LogoOrSign.defaultLogo
      : printInstOrBranchName === "I"
      ? LogoOrSign.defaultLogo
      : printInstOrBranchName === "B"
      ? imageString
      : LogoOrSign.defaultLogo;

  return (
    <>
      <div className="prs__top--inst">
        {(pageType === InstDetailsPageType.NON_DEMAND &&
          nonDemandReceiptInstLogo) ||
        (pageType !== InstDetailsPageType.NON_DEMAND && printInstLogo) ? (
          <div className="prs__top--logo">
            {LogoOrSign.defaultLogo ? <img src={imgSrc} alt="/" /> : null}
          </div>
        ) : null}
        <div className="prs__top--inst-details">
          {(pageType === InstDetailsPageType.NON_DEMAND && nonDemandReceiptHeader) ? (
            <span className="prs__top--inst-details--address">
              {nonDemandReceiptHeader}
            </span>
          ) :(pageType !== InstDetailsPageType.NON_DEMAND && printReceiptHeader) ? (
            <span className="prs__top--inst-details--address">
              {printReceiptHeader}
            </span>
          ) : null}
          {(pageType === InstDetailsPageType.NON_DEMAND &&
            nonDemandReceiptCustomerName) ||
          (pageType !== InstDetailsPageType.NON_DEMAND && printCustomerName) ? (
            <span className="prs__top--inst-details--name">
              {InstFormData.cust_name}
            </span>
          ) : null}

          {(pageType === InstDetailsPageType.NON_DEMAND &&
            nonDemandReceiptInstName) ||
          (pageType !== InstDetailsPageType.NON_DEMAND && printInstName) ? (
            <b>
              {(pageType === InstDetailsPageType.NON_DEMAND ? nonDemandReceiptInstOrBranchName:  printInstOrBranchName) === "I"
                ? InstFormData.inst_name
                : studentDetails
                ? studentDetails.branch.branch_desc
                : state.studentId || state.stdIdForChallan
                ? studentFormData.branch
                : state.aluminiStudentId || state.passOutStdIdForChallan
                ? passedOutStudentFormData.branch_desc
                : EnquiryQueryDetails.data
                ? EnquiryQueryDetails.data.node.branch_details.branch_desc
                : EMPTY_STRING}
            </b>
          ) : null}
       

          {(pageType === InstDetailsPageType.NON_DEMAND &&
            nonDemandReceiptInstAddress) ||
          (pageType !== InstDetailsPageType.NON_DEMAND && printInstAddress) ? (
            <span className="prs__top--inst-details--address">
              {[
                InstFormData.inst_address,
                InstFormData.inst_place,
                InstFormData.inst_pin
              ]
                .filter(Boolean)
                .join(", ")
                .trim()}
            </span>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default InstInfo;
