import React, { useContext, useEffect, useRef, useState } from "react";
import usePerTestReportConfig from "../../hooks/usePerTestReportConfig";
import { getDownloadUrl } from "../../../../../utils/DownloadFileForChat";
import useToken from "../../../../../customhooks/useToken";
import useInstitutionConfiguration from "../../../../../customhooks/useInstitutionConfiguration";
import { formatter, toStandardDate } from "../../../../../utils/UtilFunctions";
import useInstLogoDetails from "../../../../../customhooks/useInstLogoDetails";
import { FileUploadParams } from "../../../../../utils/Enum.types";
import useInstDetails from "../../../../../customhooks/general/useInstDetails";
import { AppContext } from "../../../../../context/context";
import { useParams } from "react-router-dom";
import useActiveAcademicYear from "../../../../Academics/hooks/useActiveAcademicYear";
import { useLazyQuery } from "@apollo/client";
import { GetAcdStudentsMarksStatisticsAtEntryLevel } from "../../../../Academics/queries/test/query";
import { responseType } from "../../../../../utils/Form.types";
import { EMPTY_STRING } from "../../../../../utils/constants";
import EduateLogo from "../../../../../images/EduateLogo.svg";
import useInstLabels from "../../../../../customhooks/general/useInstLabels";
import InstInformation from "./common/InstDetails";
import { Title } from "../../../../../stories/Title/Title";
import StudentDetails from "./common/StudentDetails";

import SecuredPercentage from "../../../../../images/Conversion.svg";
import AttendancePercentage from "../../../../../images/PercentageInMarksSheet.svg";
import Avatar from "../../../../../images/Avatar.svg";
import TableDetails, { PerTestTableFor } from "./common/TableDetails";
export interface SubjectMarks {
  subj_master_id: number;
  subj_desc: string;
  subj_code: string;
  std_marks_scored: number;
  marks_percentage: number;
  subj_total_max_marks: number;
  subj_total_min_marks: number;
  subj_max_marks_scored: number;
  subj_avg_marks_scored: number;
  is_std_present: boolean;
  scored_grade: string;
}
export interface StudentAcdDetails {
  std_adm_no: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  branch_desc: string;
  class_desc: string;
  section_desc: string;
  std_profile_filename: string;
  std_father_name: string;
  std_mother_name: string;
  std_dob: string;
}

export interface GetAcdStudentsMarksStatisticsAtEntryLevelDetails {
  student_id: number;
  acd_test_class_id: number;
  test_start_date: string;
  test_end_date: string;
  test_type_name: string;
  acd_test_name: string;
  test_total_max_marks: number;
  test_total_min_marks: number;
  test_top_percentage: number;
  test_avg_percentage: number;
  att_percentage: number;
  test_remarks: string;
  test_std_total_marks_scored: number;
  test_std_total_percentage: number;
  student_details: StudentAcdDetails;
  marks_details: SubjectMarks[];
}
export interface GetAcdStudentsMarksStatisticsAtEntryLevelData {
  GetAcdStudentsMarksStatisticsAtEntryLevel: GetAcdStudentsMarksStatisticsAtEntryLevelDetails[];
}
export interface StdStatisticsInput {
  inst_id: number | string;
  acd_test_class_id: number;
  entry_level: string;
  entry_id: number;
  test_type_id: number;
}
export interface GetAcdStudentsMarksStatisticsAtEntryLevelVars {
  token: string;
  inst_id: string | number;
  acd_yr_id: number;
  student_ids: number[];
  input: StdStatisticsInput;
  acd_subj_combo_id: number | string;
}
export interface Props {
  testConductId: responseType | null;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  selectedStudents: number[];
  setStudentIdSet: React.Dispatch<React.SetStateAction<Set<number>>>;
  forCombination?: boolean;
}

export interface stdDataType
  extends GetAcdStudentsMarksStatisticsAtEntryLevelDetails {
  profile_pic: string;
}
const PerTestTemplate1 = ({
  testConductId,
  setModalFlag,
  selectedStudents,
  setStudentIdSet,
  forCombination,
}: Props) => {
  const { token } = useToken();
  const { entry_level } = useInstitutionConfiguration();
  const { format } = formatter;

  const instLogoDetails = {
    LogoOrSign: useInstLogoDetails({ filetype: FileUploadParams.INST_LOGO })
      .LogoOrSign,
    PrincipalSign: useInstLogoDetails({
      filetype: FileUploadParams.PRINCIPAL_SIGNATURE,
    }).LogoOrSign,
    Photo1: useInstLogoDetails({ filetype: FileUploadParams.PHOTO_1 })
      .LogoOrSign,
    Photo2: useInstLogoDetails({ filetype: FileUploadParams.PHOTO_2 })
      .LogoOrSign,
  };
  const { PrincipalSign } = instLogoDetails;

  const [stdData, setStdData] = useState<stdDataType[]>([]);

  const { InstFormData } = useInstDetails(1);
  const { state } = useContext(AppContext);
  const { InstId, entryId } = useParams();
  const { activeAcademicYearData } = useActiveAcademicYear();
  const [GetStats, { data: statsData, error }] = useLazyQuery<
    GetAcdStudentsMarksStatisticsAtEntryLevelData,
    GetAcdStudentsMarksStatisticsAtEntryLevelVars
  >(GetAcdStudentsMarksStatisticsAtEntryLevel);

  useEffect(() => {
    if (
      token &&
      testConductId &&
      entryId &&
      selectedStudents &&
      entry_level &&
      activeAcademicYearData.data &&
      !activeAcademicYearData.loading &&
      state.ActiveAcdYr
    ) {
      GetStats({
        variables: {
          token,
          inst_id: InstId!,
          student_ids: selectedStudents,
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          input: {
            inst_id: InstId!,
            acd_test_class_id: testConductId ? testConductId.value : 0,
            entry_level: entry_level,
            entry_id: entryId ? Number(entryId) : 0,
            test_type_id: 0,
          },
          acd_subj_combo_id:
            forCombination && state.acdCombinationId
              ? state.acdCombinationId
              : 0,
        },
      });
    } // eslint-disable-next-line
  }, [
    token,
    testConductId,
    entryId,
    selectedStudents,
    entry_level,
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
    GetStats,
    state.ActiveAcdYr,
    forCombination,
    statsData,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      if (statsData) {
        const promise: Promise<stdDataType[]> = new Promise(
          async (res, rej) => {
            let tempStudentList: stdDataType[] = [];
            for (
              let index = 0;
              index <
              statsData.GetAcdStudentsMarksStatisticsAtEntryLevel.length;
              index++
            ) {
              const res =
                statsData.GetAcdStudentsMarksStatisticsAtEntryLevel[index];

              if (res.student_details.std_profile_filename) {
                const filename = `${InstFormData.inst_name}/students/${res.student_id}/std_profile_pic/std_profile_pic`;
                const url = await getDownloadUrl(filename, false);

                if (url) {
                  tempStudentList.push({
                    ...res,
                    profile_pic: url,
                  } as stdDataType);
                } else {
                  tempStudentList.push({ ...res, profile_pic: "" });
                }
              } else {
                tempStudentList.push({ ...res, profile_pic: "" });
              }
            }
            if (
              statsData.GetAcdStudentsMarksStatisticsAtEntryLevel.length ===
              tempStudentList.length
            ) {
              res(tempStudentList);
            }
          }
        );
        promise.then((res) => {
          if (res.length) {
            setStdData(res);
          }
        });
      }
    };
    fetchData();
  }, [statsData]);

  const {
    parentSignPerTest,
    attendanceStatsPerTest,
    remarksPerTest,
    classTeacherSignPerTest,
    overAllPercentagePerTest,
    signatureTypePerTest,
    signatureFromInstPerTest,
    ConductPerTest,
    titlePerTest,

    gradeForEachSubPerTest,
  } = usePerTestReportConfig();

  return (
    <>
      {stdData.map((data, index) => {
        return (
          <>
            <div className="progress-report__print--form">
              <InstInformation />

              {titlePerTest?.config_boolean_value ? (
                <Title>{titlePerTest.config_string_value}</Title>
              ) : null}

              <StudentDetails
                setStudentIdSet={setStudentIdSet}
                selectedStudents={[data.student_id]}
              />
              <div className="progress-report__test">
                <span className="progress-report__test--name">
                  {data.acd_test_name}
                </span>
                <span className="progress-report__test--duration">
                  &nbsp; ({toStandardDate(data.test_start_date!)} -{" "}
                  {toStandardDate(data.test_end_date)})
                </span>
              </div>
              {/* <div className="progress-report__group">Part A</div> */}
              <div className="progress-report__tableblock">
                <TableDetails
                  selectedStudents={[data.student_id]}
                  testConductId={testConductId}
                  forCombination={forCombination}
                  pageType={PerTestTableFor.MARKS_DETAILS}
                />
              </div>

              <TableDetails
                selectedStudents={[data.student_id]}
                testConductId={testConductId}
                forCombination={forCombination}
                pageType={PerTestTableFor.OVERALL_PERFORMANCE}
              />

              {/* <div className="progress-report__percentage--details">
                    <div>
                      <img src={OverAllGrade} alt="" />
                      <span> OverAll Grade :</span>
                    </div>
                    <b>A</b>
                  </div> */}
              {attendanceStatsPerTest?.config_boolean_value && (
                <div className="progress-report__percentage--details">
                  <div>
                    <img src={AttendancePercentage} alt="" />
                    <span> Attendance Percentage</span>
                  </div>
                  <b>:{format(data.att_percentage)}%</b>
                </div>
              )}

              {/* <div className="progress-report__group">Part B</div>
                  <div className="progress-report__tableblock">
                    <TableContainer className="progress-report__table2">
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Sl</TableCell>
                            <TableCell>Subject Name</TableCell>
                            <TableCell>Grade</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell id="td-center">1</TableCell>
                            <TableCell>Drawing</TableCell>
                            <TableCell className="font-green">A</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>*/}
   

              <TableDetails
                selectedStudents={[data.student_id]}
                testConductId={testConductId}
                forCombination={forCombination}
                pageType={PerTestTableFor.SIGNATURE}
              />
            </div>
            <div className="page-break" />
          </>
        );
      })}
    </>
  );
};

export default PerTestTemplate1;
