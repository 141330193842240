import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import {
  GetAcdGradeScalesByInstIdData,
  GetAcdGradeScalesByInstIdVars
} from "../../../Academics/types/grade";
import { GetAcdGradeScalesByInstId } from "../../../Academics/queries/grade";
import useToken from "../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../../context/context";
import { useLazyQuery } from "@apollo/client";

const GradingScale = () => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { state } = useContext(AppContext);

  const [GetAcdGradeScaleDetails, { data: gradeDetails }] = useLazyQuery<
    GetAcdGradeScalesByInstIdData,
    GetAcdGradeScalesByInstIdVars
  >(GetAcdGradeScalesByInstId, {
    variables: {
      token,
      inst_id: InstId!,
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0
    }
  });
  useEffect(() => {
    if (token && InstId) {
      GetAcdGradeScaleDetails();
    }
  }, [token, InstId, GetAcdGradeScaleDetails]);
  return (
    <>
      <TableContainer className="pr-temp1-bs__top--table">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell id="td-center">Percentage</TableCell>
              <TableCell id="td-center">Grade</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {gradeDetails?.GetAcdGradeScalesByInstId.map((res, index) => {
              return (
                <TableRow key={index}>
                  <TableCell id="td-center">
                    {res.marks_min} - {res.marks_max} %
                  </TableCell>
                  <TableCell id="td-center">{res.grade}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default GradingScale;
