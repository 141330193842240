import { forwardRef, useContext, useEffect, useRef } from "react";
import ReactToPrint from "react-to-print";
import { Button } from "../../../../../stories/Button/Button";
import { StudentDetails } from "../../../../../customhooks/useStudentDatabyId";

import StandardReceiptTemplate from "../templates/Index";
import usePrintConfig from "./hooks/usePrintConfiguration";
import { AppContext } from "../../../../../context/context";
import { BankDetails } from "../../../../Accounts/StudentDetails/Challan/CreateChallan";
import EduateLogo from "../../../../../images/EduateLogo.svg";

import { useLazyQuery } from "@apollo/client";
import { PayRollEmpMasterNode } from "../../../../HR/queries/employee/query";
import { EmpData } from "../../../../HR/hooks/useEmployeeAttsByMonth";
import { CommonNodeVars } from "../../../../HR/Types/masterDataTypes";
import useToken from "../../../../../customhooks/useToken";

interface Data {
  particular: string;
  amount: number;
}
export interface PrintReceiptProps {
  tableData: Data[];
  v_date: string;
  v_no: string;
  transaction_no: string;
  studentDetails?: StudentDetails | null;
  printBoth?: boolean;
  bankDetailsForPrint?: BankDetails;
}

const FeeReceiptPrint = ({
  tableData,
  v_date,
  v_no,
  transaction_no,
  studentDetails,
}: PrintReceiptProps) => {
  const componentRef = useRef<HTMLDivElement>(null);
  const { state } = useContext(AppContext);
  const { token } = useToken();
  // State to store the number of copies to print
  const {
    USE_NO_COPIES,
    USE_INST_STD_LABEL,

    USE_BOTTOM_MARGIN,
    USE_TOP_MARGIN,
    USE_LEFT_MARGIN,
    USE_RIGHT_MARGIN,
    USE_GENERATED_BY,
    USE_SIMPLIFIED_BY,
  } = usePrintConfig();

  const [GetEmpDetails, { data: empDetails }] = useLazyQuery<
    EmpData,
    CommonNodeVars
  >(PayRollEmpMasterNode, {
    variables: {
      id: Number(state.empLoginId),
      token,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (token) {
      GetEmpDetails();
    }
  }, [token, empDetails, GetEmpDetails]);

  const ComponentToPrint = forwardRef<HTMLDivElement, PrintReceiptProps>(
    ({ tableData, v_date, v_no, transaction_no, studentDetails }, ref) => {
      return (
        <>
          <style type="text/css" media="print" scoped>
            {`
              
              @page {

           
         height:100% !important;
         width:100% !important;
             margin: 0 !important;
              }
             body {
              height:100%;
              margin:0;
               padding:${USE_TOP_MARGIN}px ${USE_RIGHT_MARGIN}px ${USE_BOTTOM_MARGIN}px ${USE_LEFT_MARGIN}px;
              }
.print-receipts__ss{
height:100%;
width:100% !important;

}
.prs{
height:100%;

}
.print-receipts __ff{
height:calc(100% - 45px);
}
 
            `}
          </style>
          <div className="print-receipts__ff" ref={ref}>
            <StandardReceiptTemplate
              tableData={tableData}
              v_date={v_date}
              v_no={v_no}
              studentDetails={studentDetails}
              transaction_no={transaction_no}
            />
          </div>
        </>
      );
    }
  );
  return (
    <>
      <div className="print-receipts">
        <div ref={componentRef} className="print-receipts__ss">
          {state.claims && state.claims.STUDENT ? (
            <div className={"print-receipts__dd"}>
              <ComponentToPrint
                tableData={tableData}
                v_date={v_date}
                v_no={v_no}
                studentDetails={studentDetails}
                transaction_no={transaction_no}
              />

              {USE_INST_STD_LABEL && (
                <>
                  <b className="prs__bottom--copy">Student Copy</b>
                </>
              )}
            </div>
          ) : (
            [...Array(USE_NO_COPIES)].map((_, index) => (
              <div className="print-receipts__dd" key={index}>
                <ComponentToPrint
                  tableData={tableData}
                  v_date={v_date}
                  v_no={v_no}
                  transaction_no={transaction_no}
                  studentDetails={studentDetails}
                />
                {USE_INST_STD_LABEL && (
                  <>
                    {index === 0 ? (
                      <b className="prs__bottom--copy">Institution Copy</b>
                    ) : (
                      <b className="prs__bottom--copy">Student Copy</b>
                    )}
                  </>
                )}
                <div className="prs__bottom--flex">
                  {USE_SIMPLIFIED_BY ? (
                    <div className="prs__bottom--simplify">
                      <img src={EduateLogo} alt="" />{" "}
                      <span>Simplified By My-Eduate</span>
                    </div>
                  ) : null}

                  {USE_GENERATED_BY ? (
                    <div className="prs__bottom--simplify">
                      <b>Generated by</b>
                      <span>
                        {empDetails
                          ? empDetails.node.emp_first_name +
                            empDetails.node.emp_middle_name +
                            empDetails.node.emp_last_name
                          : "---"}
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>
            ))
          )}
        </div>

        <ReactToPrint
          trigger={() => <Button mode="print" />}
          content={() => componentRef.current}
        />
      </div>
    </>
  );
};

export default FeeReceiptPrint;
