import React, { useContext, useState } from "react";
import ReactModal from "react-modal";
import Close from "../../../images/Close.svg";
import BrowseFile from "../../../images/BrowseFiles.svg";
import Image from "../../../images/DownloadImage.svg";

import { useParams } from "react-router-dom";
import {
  PicPreviewModalStyles,
  WaiveOffModalStyles,
} from "../../../styles/ModalStyles";
import { previewStudentType } from "./BulkProfileUpload/BulkProfilePicUpload";
import useFileSizeDetails from "../../../customhooks/useFileUploadConstraints";
import { AppContext } from "../../../context/context";
import { Title } from "../../../stories/Title/Title";
import { Button } from "../../../stories/Button/Button";
interface Props {
  details: previewStudentType;
  setPreviewStudent: React.Dispatch<React.SetStateAction<previewStudentType>>;

  changePic: (adm_no: string, image: File) => void;
}
const PicPreview = ({ details, changePic, setPreviewStudent }: Props) => {
  const { ComponentWarning, maxFileSizeInBits } = useFileSizeDetails();
  const { instId, studentId } = useParams();
  const [file, setFile] = useState<File | null>(null);
  // eslint-disable-next-line
  const [uploadLoading, setUploading] = useState(false);
  const { state } = useContext(AppContext);

  const [imageString, setImageString] = useState("");

  const filetype = file ? file.name.split(".").pop() : "";

  const handleImagePreview = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length) {
      if (e.target.files[0].size > maxFileSizeInBits.mediaSize) {
        alert("huge file");
        setFile(null);
        return;
      }
      let image_as_files = e.target.files[0];
      setFile(image_as_files);

      const imageUrl = URL.createObjectURL(image_as_files);
      setImageString(imageUrl);
    }
  };

  return (
    <ReactModal
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      isOpen={details.flag}
      style={PicPreviewModalStyles}>
      <div className="profile-pic">
        <div className="profile-pic__title">
          <Title>Profile Pic Preview</Title>

          <img
            src={Close}
            alt="/"
            className="modal-close-icon"
            onClick={() => {
              setPreviewStudent({
                adm_no: "",
                flag: false,
                image: null,
                std_name: "",
              });
            }}
          />
        </div>
        <Title variant="subtitle1">
          {details.std_name} ({details.adm_no})
        </Title>

        <div className="profile-pic__preview">
          <img
            src={
              imageString
                ? imageString
                : details.image
                ? URL.createObjectURL(details.image)
                : Image
            }
            alt="/"
          />
        </div>
        <div className="profile-pic__warning">
          {ComponentWarning.mediaSizeComponent}
        </div>
        <div className="profile-pic__btns">
          <label className="profile-pic__browse">
            <img src={BrowseFile} alt="/" className="browse-icon" />
            Change Pic
            <input
              type="file"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleImagePreview(e)
              }
              className="profile-pic__upload-btn--input"
              accept={`${".jpg, .jpeg, .png"}`}
            />
          </label>
          <Button
            mode="save"
            disabled={file === null}
            onClick={() => {
              if (file) changePic(details.adm_no, file);
            }}
          />

          <Button
            mode="clear"
            onClick={() => {
              if (file && details.image) {
                changePic(details.adm_no, details.image);
                setFile(null);
                setImageString("");
              }
            }}
          />
          <Button
            mode="cancel"
            onClick={() => {
              setPreviewStudent({
                adm_no: "",
                flag: false,
                image: null,
                std_name: "",
              });
            }}
          />
        </div>
      </div>
    </ReactModal>
  );
};

export default PicPreview;
