import { gql } from "@apollo/client";

export const UpdateStdSectionByIds = gql`
  mutation UpdateStdSectionByIds(
    $token: String!
    $student_ids: [ID!]!
    $section_id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    UpdateStdSectionByIds(
      token: $token
      student_ids: $student_ids
      section_id: $section_id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const UpdateStudentsCategory = gql`
  mutation UpdateStudentsCategory(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $fin_yr_id: ID!
    $acd_yr_id: ID!
    $student_ids: [ID!]!
    $category_id: ID!
  ) {
    UpdateStudentsCategory(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      fin_yr_id: $fin_yr_id
      acd_yr_id: $acd_yr_id
      student_ids: $student_ids
      category_id: $category_id
    )
  }
`;
export const UpdateStudentOtherDetailsByStudentId = gql`
  mutation UpdateStudentOtherDetailsByStudentId(
    $token: String!
    $input: UpdateMstStudentOtherDetailsInput!
    $student_id: ID!
    $user_details: SubscribedUser!
    $inst_id: ID!
  ) {
    UpdateStudentOtherDetailsByStudentId(
      token: $token
      student_id: $student_id
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    ) {
      std_prev_tc_details
      std_adm_eli_no
    }
  }
`;

export const updateStudentById = gql`
  mutation UpdateStudentById(
    $token: String!
    $id: ID!
    $user_details: SubscribedUser!
    $inst_id: ID!
    $input: UpdateMstStudentInput!
  ) {
    UpdateStudentById(
      token: $token
      id: $id
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    ) {
      id
      first_name
      middle_name
      last_name
      std_profile_filename
    }
  }
`;

export const updateStudentPersonal = gql`
  mutation UpdateStudentDetailsByStudentId(
    $token: String!
    $input: UpdateMstStudentDetailsInput!
    $student_id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    UpdateStudentDetailsByStudentId(
      token: $token
      student_id: $student_id
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    ) {
      std_blood_group
      std_aadhar
      std_mother_tongue
    }
  }
`;
export const UpdateStudentParentAssoc = gql`
  mutation UpdateStudentParentAssoc(
    $token: String!
    $student_id: ID!
    $user_details: SubscribedUser!
    $inst_id: ID!
    $input: [StudentParentAssoci!]!
  ) {
    UpdateStudentParentAssoci(
      token: $token
      input: $input
      student_id: $student_id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

export const ChangeStudentStatusToNE = gql`
  mutation ChangeStudentStatusToNE(
    $token: String!
    $user_details: SubscribedUser!
    $inst_id: ID!
    $input: ChangeMstStudentNe!
  ) {
    ChangeStudentStatusToNE(
      token: $token
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const StudentNEStatusCancel = gql`
  mutation CancelStudentNEStatus(
    $token: String!
    $id: ID!
    $user_details: SubscribedUser!
    $inst_id: ID!
    $input: CancelMstStudentNe!
  ) {
    CancelStudentNEStatus(
      token: $token
      id: $id
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const UpdateStudentsRegNum = gql`
  mutation UpdateStudentsRegNum(
    $token: String!
    $user_details: SubscribedUser!
    $inst_id: ID!
    $std_reg_data: [StdRegData!]!
  ) {
    UpdateStudentsRegNum(
      token: $token
      std_reg_data: $std_reg_data
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const UpdateStudentsRollNum = gql`
  mutation UpdateStudentsRollNum(
    $token: String!
    $std_roll_data: [StdRollData!]!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    UpdateStudentsRollNum(
      token: $token
      std_roll_data: $std_roll_data
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const UpdateStudentsAdmNum = gql`
  mutation UpdateStudentsAdmNum(
    $token: String!
    $std_adm_data: [StdAdmData!]!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    UpdateStudentsAdmNum(
      token: $token
      std_adm_data: $std_adm_data
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const ChangeStudentBranch = gql`
  mutation ChangeStudentBranch(
    $token: String!
    $module_name: String!
    $student_id: ID!
    $user_details: SubscribedUser!
    $inst_id: ID!
    $input: StdBranchChangeInput!
  ) {
    ChangeStudentBranch(
      token: $token
      student_id: $student_id
      module_name: $module_name
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const UpdateStudentEnquiry = gql`
  mutation UpdateStudentEnquiry(
    $token: String!
    $id: ID!
    $user_details: SubscribedUser!
    $inst_id: ID!
    $input: UpdateMstStudentEnquiryInput!
  ) {
    UpdateStudentEnquiry(
      token: $token
      id: $id
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

export const DeleteStudentEnquiryById = gql`
  mutation DeleteStudentEnquiryById(
    $token: String!
    $id: ID!
    $user_details: SubscribedUser!
    $inst_id: ID!
  ) {
    DeleteStudentEnquiryById(
      token: $token
      id: $id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const UpdateStudentAvailedServices = gql`
  mutation UpdateStudentAvailedServices(
    $token: String!
    $user_details: SubscribedUser!
    $inst_id: ID!
    $input: [AvailedServices!]!
  ) {
    UpdateStudentAvailedServices(
      token: $token
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

export const UpdateStudentPassout = gql`
  mutation UpdateStudentPassout(
    $token: String!
    $id: ID!
    $user_details: SubscribedUser!
    $inst_id: ID!
    $input: UpdateMstStudentPassoutInput!
  ) {
    UpdateStudentPassout(
      token: $token
      id: $id
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

export const DeleteStudentPassout = gql`
  mutation DeleteStudentPassout(
    $token: String!
    $id: ID!
    $user_details: SubscribedUser!
    $inst_id: ID!
  ) {
    DeleteStudentPassout(
      token: $token
      id: $id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const ImportUpdatedStudents = gql`
  mutation ImportStudentAdditionalDetails(
    $token: String!
    $student_id: ID!
    $user_details: SubscribedUser!
    $inst_id: ID!
    $input: StudentAdditionalDetails!
  ) {
    ImportStudentAdditionalDetails(
      token: $token
      student_id: $student_id
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const DeleteStudents = gql`
  mutation DeleteStudents(
    $token: String!
    $id: [ID!]!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    DeleteStudents(
      token: $token
      student_ids: $id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const AddStudentEnquiryEmpAssoci = gql`
  mutation AddStudentEnquiryEmpAssoci(
    $token: String!
    $inst_id: ID!
    $fin_yr_id: ID!
    $acd_yr_id: ID!
    $enq_student_ids: [ID!]!
    $pr_emp_id: ID!
    $user_details: SubscribedUser!
  ) {
    AddStudentEnquiryEmpAssoci(
      token: $token
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
      acd_yr_id: $acd_yr_id
      enq_student_ids: $enq_student_ids
      pr_emp_id: $pr_emp_id
      user_details: $user_details
    )
  }
`;
export const UpdateEmpStatus = gql`
  mutation UpdateEmpStatus(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $pr_emp_ids: [ID!]!
    $status: String!
  ) {
    UpdateEmpStatus(
      token: $token
      pr_emp_ids: $pr_emp_ids
      status: $status
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
