import { useLazyQuery, useMutation } from "@apollo/client";
import React, { ChangeEvent, useEffect, useState } from "react";
import useToken from "../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import { msgType } from "../../../../utils/Form.types";
import { Operation } from "../../../../utils/Enum.types";
import { GetAcdReportCardConfig } from "../../queries/reportcard";
import {
  GetAcdReportCardConfigData,
  GetAcdReportCardConfigList,
  GetAcdReportCardConfigVars,
} from "../../types/Progresscard";
import { GetDocumentConfigKeys } from "../../../Print/Documents/Types";
import { UpdateAcdReportCardConfig } from "../../queries/reportcard/mutation";
import { EMPTY_STRING } from "../../../../utils/constants";
import { Title } from "../../../../stories/Title/Title";
import { Label } from "../../../../stories/Label/Label";
import { FormControlLabel, FormGroup } from "@mui/material";
import { AntSwitch } from "../../../../pages/Switch";
import Input from "../../../../stories/Input/Input";
import { Button } from "../../../../stories/Button/Button";
import MessageModal from "../../../../pages/MessageModal";
import LoadingModal from "../../../../pages/LoadingModal";
import { Select } from "../../../../stories/Select/Select";
import {
  SoftwareConfigTypeList,
  SoftwareConfigTypeVar,
} from "../../../../Types/configtypes";
import { GetSwConfigReferenceData } from "../../../../queries/institution/configuration/query/SoftwreConfig";
interface props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

const Configurations = ({ setModalFlag }: props) => {
  const { token } = useToken();
  const { InstId, reportId } = useParams();
  const { user_details } = useLoggedInUserDetails();
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [GetSwConfigReference, { data: OptionsData }] = useLazyQuery<
    SoftwareConfigTypeList,
    SoftwareConfigTypeVar
  >(GetSwConfigReferenceData, { variables: { token } });

  // eslint-disable-next-line
  const [GetAcdReportConfigDetails, { data: ConfigReferenceData }] =
    useLazyQuery<GetAcdReportCardConfigData, GetAcdReportCardConfigVars>(
      GetAcdReportCardConfig,
      {
        variables: {
          token,
          inst_id: Number(InstId),
          acd_report_card_id: Number(reportId),
        },
      }
    );
  const [pageSwDetails, setPageSwConfigList] = useState<
    GetAcdReportCardConfigList[]
  >([]);

  useEffect(() => {
    if (token) {
      GetAcdReportConfigDetails({
        variables: {
          token,
          inst_id: Number(InstId),
          acd_report_card_id: Number(reportId),
        },
      }).then(({ data }) => {
        if (data) {
          setPageSwConfigList(data.GetAcdReportCardConfig);
        }
      });
    }
  }, [token, InstId, reportId, GetAcdReportConfigDetails]);

  const handleChangeValue = (
    key: string,
    type:
      | "BOOL"
      | "INT"
      | "primary_list_key"
      | "secondary_list_key"
      | "VARCHAR",
    switchValue?: boolean | null,
    selectedValue?: string | null
  ) => {
    if (type === "BOOL") {
      const newState =
        pageSwDetails &&
        // eslint-disable-next-line
        pageSwDetails.map((obj) =>
          obj.config_key === key
            ? { ...obj, config_boolean_value: switchValue! }
            : obj
        );
      setPageSwConfigList(newState);
    }

    if (type === "INT") {
      const newState = pageSwDetails.map((obj) =>
        obj.config_key === key
          ? { ...obj, config_integer_value: Number(selectedValue)! }
          : obj
      );
      setPageSwConfigList(newState);
    }
    if (type === "primary_list_key") {
      const config_key = key + "_" + selectedValue!;
      const newState = pageSwDetails.map((obj) =>
        obj.config_key === key
          ? {
              ...obj,
              config_secondary_list_key: config_key,
              config_string_value: selectedValue!,
            }
          : obj
      );
      setPageSwConfigList(newState);
    }

    if (type === "secondary_list_key") {
      const newState = pageSwDetails.map((obj) =>
        obj.config_key === key
          ? { ...obj, config_string_value_2: selectedValue! }
          : obj
      );
      setPageSwConfigList(newState);
    }

    if (type === "VARCHAR") {
      const newState = pageSwDetails.map((obj) =>
        obj.config_key === key
          ? { ...obj, config_string_value: selectedValue! }
          : obj
      );
      setPageSwConfigList(newState);
    }
  };

  const [UpdateAcdReportConfigDetails, { loading: updationLoading }] =
    useMutation(UpdateAcdReportCardConfig, {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    });
  const handleUpdateSwConfig = () => {
    if (InstId && reportId)
      UpdateAcdReportConfigDetails({
        variables: {
          token,
          inst_id: InstId,
          input: pageSwDetails.map((pageSwDetail) => ({
            id: pageSwDetail.id,
            config_key: pageSwDetail.config_key,
            config_boolean_value: pageSwDetail.config_boolean_value,
            config_integer_value: pageSwDetail.config_integer_value,
            config_double_value: pageSwDetail.config_double_value,
            config_string_value: pageSwDetail.config_string_value,
            config_string_value_2: pageSwDetail.config_string_value_2,
          })),
          user_details,
        },
        refetchQueries: [
          {
            query: GetAcdReportCardConfig,
            variables: {
              token,
              inst_id: Number(InstId),
              acd_report_card_id: Number(reportId),
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: true,
            message: "Global Configuration Saved Successfully",
            operation: Operation.CREATE,
          });
        }
        //   handleClear();
      });
  };
  const handleClose = () => {
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  const instTypeData = (type: string) => {
    const typeData = OptionsData?.GetSwConfigReferenceData?.find(
      (data) => data.list_item_key === type
    );
    return typeData?.list_item_list
      .split(";")
      .map((d) => ({
        label: d.split(":")[0],
        value: d.split(":")[1],
      }))
      .filter(({ value }) => value !== undefined);
  };

  useEffect(() => {
    if (token) {
      GetSwConfigReference();
    }
  }, [token, GetSwConfigReference]);
  return (
    <>
      <div className="document-config">
        <Title>Configurations</Title>
        <div className="document-config__parameters">
          {pageSwDetails.map((list, index) => (
            <div className="document-config__parameters--list" key={index}>
              <Label>
                {index + 1} &nbsp;
                {list.config_form_label_caption}
              </Label>

              {list.config_key_data_storage_type === "BOOLEAN" ? (
                <FormGroup>
                  <FormControlLabel
                    label=""
                    labelPlacement="start"
                    control={
                      <AntSwitch
                        checked={list.config_boolean_value}
                        onClick={() => {
                          const newConfigValue = !list.config_boolean_value;
                          handleChangeValue(
                            list.config_key,
                            "BOOL",
                            newConfigValue,
                            null
                          );
                        }}
                      />
                    }
                  />
                </FormGroup>
              ) : null}
              {list.config_depends_on_primary_list_key === false &&
              list.config_key_data_storage_type === "BOOLEAN+VARCHAR" ? (
                <FormGroup>
                  <FormControlLabel
                    label=""
                    labelPlacement="start"
                    control={
                      <AntSwitch
                        checked={list.config_boolean_value}
                        onClick={() => {
                          const newConfigValue = !list.config_boolean_value;
                          handleChangeValue(
                            list.config_key,
                            "BOOL",
                            newConfigValue,
                            null
                          );
                        }}
                      />
                    }
                  />
                  {list.config_depends_on_primary_list_key === false &&
                  list.config_boolean_value ? (
                    <Input
                      placeholder=""
                      value={list.config_string_value}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeValue(
                          list.config_key,
                          "VARCHAR",
                          null,
                          e.target.value
                        )
                      }
                    />
                  ) : null}
                </FormGroup>
              ) : null}
              {list.config_depends_on_primary_list_key === false &&
              list.config_key_data_storage_type === "VARCHAR" ? (
                <Input
                  value={list.config_string_value}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeValue(
                      list.config_key,
                      "VARCHAR",
                      null,
                      e.target.value
                    )
                  }
                />
              ) : null}
              {list.config_key_data_storage_type === "INT" ? (
                <Input
                  value={list.config_integer_value}
                  type="number"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeValue(
                      list.config_key,
                      "INT",
                      null,
                      e.target.value
                    )
                  }
                />
              ) : null}

              {list.config_key_data_storage_type === "VARCHAR" &&
              list.config_depends_on_primary_list_key === true ? (
                <div>
                  <Select
                    value={list.config_string_value}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChangeValue(
                        list.config_key,
                        "primary_list_key",
                        null,
                        e.target.value
                      );
                    }}>
                    {instTypeData(list.config_primary_list_key)?.map(
                      ({ label, value }) => {
                        return (
                          <React.Fragment key={value}>
                            <option value={value}>{label}</option>
                          </React.Fragment>
                        );
                      }
                    )}
                  </Select>
                </div>
              ) : null}
              {list.config_key_data_storage_type === "BOOLEAN+VARCHAR" &&
              list.config_depends_on_primary_list_key === true ? (
                <>
                  <FormGroup>
                    <FormControlLabel
                      label=""
                      labelPlacement="start"
                      control={
                        <AntSwitch
                          checked={list.config_boolean_value}
                          onClick={() => {
                            const newConfigValue = !list.config_boolean_value;
                            handleChangeValue(
                              list.config_key,
                              "BOOL",
                              newConfigValue,
                              null
                            );
                          }}
                        />
                      }
                    />
                    {list.config_boolean_value ? (
                      <Select
                        value={list.config_string_value}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleChangeValue(
                            list.config_key,
                            "primary_list_key",
                            null,
                            e.target.value
                          );
                        }}>
                        {instTypeData(list.config_primary_list_key)?.map(
                          ({ label, value }) => {
                            return (
                              <React.Fragment key={value}>
                                <option value={value}>{label}</option>
                              </React.Fragment>
                            );
                          }
                        )}
                      </Select>
                    ) : null}
                  </FormGroup>
                </>
              ) : null}
            </div>
          ))}
        </div>
        <Button mode="save" onClick={handleUpdateSwConfig} />
        <Button mode="cancel" onClick={() => setModalFlag(false)} />
      </div>
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <LoadingModal flag={updationLoading} />
    </>
  );
};

export default Configurations;
