import { gql } from "@apollo/client";

export const AddMsgChannelandGroup = gql`
  mutation AddChannelandGroup(
    $token: String!
    $input: MsgChannelInputParams!
    $user_details: SubscribedUser!
  ) {
    AddChannelandGroup(
      token: $token
      input: $input
      user_details: $user_details
    )
  }
`;
export const AddChannelmessage = gql`
  mutation AddChannelmessage(
    $token: String!
    $input: CreateMsgChannelMessageInput!
    $user_details: SubscribedUser!
  ) {
    AddChannelMessage(
      token: $token
      input: $input
      user_details: $user_details
    ) {
      id
      msg_content
      msg_media_content
      msg_media_type
    }
  }
`;

export const AddIndividualStdMessageWithTags = gql`
  mutation AddIndividualStdMessageWithTags(
    $token: String!
    $input: IndividualStdMsgs!
    $user_details: SubscribedUser!
    $std_ids: [ID!]!
    $inst_id: ID!
  ) {
    AddIndividualStdMessageWithTags(
      token: $token
      input: $input
      user_details: $user_details
      std_ids: $std_ids
      inst_id: $inst_id
    )
  }
`;
export const UpdateAcdInstReOpeningDate = gql`
  mutation UpdateAcdInstReOpeningDate(
    $token: String!
    $user_details: SubscribedUser!
    $inst_id: ID!
    $acd_yr_id: ID!
    $re_opening_date: Time!
  ) {
    UpdateAcdInstReOpeningDate(
      token: $token
      acd_yr_id: $acd_yr_id
      user_details: $user_details
      re_opening_date: $re_opening_date
      inst_id: $inst_id
    )
  }
`;

export const DeleteChannelMessage = gql`
  mutation DeleteChannelMessage(
    $token: String!
    $msg_id: ID!
    $user_details: SubscribedUser!
  ) {
    DeleteChannelMessage(
      token: $token
      msg_id: $msg_id
      user_details: $user_details
    )
  }
`;
export const UpdateFinYrToActive = gql`
  mutation UpdateFinYrToActive(
    $token: String!
    $inst_id: ID!
    $fin_yr_id: ID!
    $user_details: SubscribedUser!
  ) {
    UpdateFinYrToActive(
      token: $token
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
      user_details: $user_details
    )
  }
`;
export const UpdateChannelMessage = gql`
  mutation UpdateChannelMessage(
    $token: String!
    $msg_id: ID!
    $input: UpdateMsgChannelMessageInput!
    $user_details: SubscribedUser!
  ) {
    UpdateChannelMessage(
      token: $token
      input: $input
      msg_id: $msg_id
      user_details: $user_details
    ) {
      id
      msg_content
      msg_media_content
      msg_media_type
      msg_channel_id
    }
  }
`;
export const UpdateChannel = gql`
  mutation UpdateChannel(
    $token: String!
    $msg_channel_id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: UpdateMsgChannelMasterInput!
  ) {
    UpdateChannel(
      token: $token
      msg_channel_id: $msg_channel_id
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const DeleteChannel = gql`
  mutation DeleteChannel(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    DeleteChannel(
      token: $token
      msg_channel_id: $id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

export const AddAssignmentChannelMessage = gql`
  mutation AddAssignmentChannelMessage(
    $token: String!
    $user_details: SubscribedUser!
    $entry_level: String!
    $entry_id: ID!
    $subj_master_id: ID!
    $input: CreateMsgChannelMessageInput!
    $per_std_subj_allocation: Boolean!
  ) {
    AddAssignmentChannelMessage(
      token: $token
      user_details: $user_details
      entry_level: $entry_level
      entry_id: $entry_id
      subj_master_id: $subj_master_id
      input: $input
      per_std_subj_allocation: $per_std_subj_allocation
    ) {
      id
      msg_content
      msg_media_content
      msg_media_type
      msg_channel_id
    }
  }
`;
