import { gql } from "@apollo/client";
export const GetDeptsByInstId = gql`
  query GetDeptsByInstId($inst_id: ID!, $token: String!) {
    GetDeptsByInstId(token: $token, inst_id: $inst_id) {
      id
      dept_desc
      dept_idx
      dept_short_desc
    }
  }
`;
export const GetDeptsForScanByInstId = gql`
  query GetDeptsForScanByInstId($inst_id: ID!) {
    GetDeptsForScanByInstId(inst_id: $inst_id) {
      id
      dept_desc
      dept_idx
      dept_short_desc
    }
  }
`;
export const GetAcdBranchYrs = gql`
  query GetAcdBranchYrs($token: String!, $inst_id: ID!, $acd_branch_id: ID!) {
    GetAcdBranchYrs(
      inst_id: $inst_id
      token: $token
      acd_branch_id: $acd_branch_id
    ) {
      id
      acd_yr
      acd_st_date
      acd_end_date
      re_opening_date
      acd_is_curr_yr
      inst_id
      acd_branch_id
    }
  }
`;

export const ByNode = gql`
  query DepartmentDetailsByNode($dept_id: ID!, $token: String!) {
    node(id: $dept_id, token: $token) {
      ... on MstInstDept {
        id
        inst_id
        dept_desc
        dept_short_desc
      }
    }
  }
`;
