import { forwardRef, useContext, useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import { Button } from "../../../../../stories/Button/Button";
import useStudentDatabyId, {
  StudentDetails,
} from "../../../../../customhooks/useStudentDatabyId";
import { AppContext } from "../../../../../context/context";
import { BankDetails } from "../../../../Accounts/StudentDetails/Challan/CreateChallan";
import EduateLogo from "../../../../../images/EduateLogo.svg";
import useNonDemandReceiptConfig from "./hooks/useNonDemandReceiptConfig";
import Settings from "../../../../../images/Settings.svg";
import { PrintConfigModalStyles } from "../../../../../styles/ModalStyles";
import Configurations from "../../../../Configurations/PerModuleConfiguration";
import Close from "../../../../../images/Close.svg";
import Modal from "react-modal";
import { SwConfigQueryType } from "../../../../HR/enums/Enum.types";
import { ModuleName, PageFor } from "../../../../../utils/Enum.types";
import useAcctVoucherDetailsByDates from "../../../../Accounts/hooks/useAcctVoucherDetailsByDates";
import { EMPTY_STRING } from "../../../../../utils/constants";
import NonDemandReceiptPrint from "../templates/NonDemandReceipt";
import { useLazyQuery } from "@apollo/client";
import { CommonNodeVars } from "../../../../HR/Types/masterDataTypes";
import { EmpData } from "../../../../HR/hooks/useEmployeeAttsByMonth";
import { PayRollEmpMasterNode } from "../../../../HR/queries/employee/query";
import useToken from "../../../../../customhooks/useToken";
interface Data {
  particular: string;
  amount: number;
}
export interface PrintReceiptProps {
  tableData: Data[];
  v_date: string;
  v_no: string;
  transaction_no: string;
  studentDetails?: StudentDetails | null;
  printBoth?: boolean;
  bankDetailsForPrint?: BankDetails;
}

const PrintNonDemandReceipt = ({
  tableData,
  v_date,
  v_no,
  transaction_no,
  studentDetails,
}: PrintReceiptProps) => {
  const componentRef = useRef<HTMLDivElement>(null);
  const { studentFormData } = useStudentDatabyId();
  const { state } = useContext(AppContext);
  const { token } = useToken();
  const [printConfigModal, setPrintConfigModal] = useState(false);

  // State to store the number of copies to print
  const {
    USE_NON_DEMAND_NO_COPIES,

    USE_NON_DEMAND_BOTTOM_MARGIN,
    USE_NON_DEMAND_TOP_MARGIN,
    USE_NON_DEMAND_INST_STD_LABEL,
    USE_NON_DEMAND_LEFT_MARGIN,
    USE_NON_DEMAND_RIGHT_MARGIN,
    USE_NON_DEMAND_GENERATED_BY,
    USE_SIMPLIFIED_BY,
  } = useNonDemandReceiptConfig();
  const [GetEmpDetails, { data: empDetails }] = useLazyQuery<
    EmpData,
    CommonNodeVars
  >(PayRollEmpMasterNode, {
    variables: {
      id: Number(state.empLoginId),
      token,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (token) {
      GetEmpDetails();
    }
  }, [token, empDetails, GetEmpDetails]);
  const ComponentToPrint = forwardRef<HTMLDivElement, PrintReceiptProps>(
    ({ tableData, v_date, v_no, transaction_no, studentDetails }, ref) => {
      return (
        <>
          <style type="text/css" media="print">
            {`
              
              @page {
         
             margin: 0;
              }
              body {
                padding: ${USE_NON_DEMAND_TOP_MARGIN}px ${USE_NON_DEMAND_RIGHT_MARGIN}px ${USE_NON_DEMAND_BOTTOM_MARGIN}px ${USE_NON_DEMAND_LEFT_MARGIN}px;
              }
.print-receipts__ss{
height:100%;
}
  
            `}
          </style>
          <div className="print-receipts__ff" ref={ref}>
            <NonDemandReceiptPrint
              tableData={tableData}
              v_date={v_date}
              v_no={v_no}
              studentDetails={studentDetails}
              transaction_no={transaction_no}
            />
          </div>
        </>
      );
    }
  );
  return (
    <>
      <img
        src={Settings}
        alt="/"
        id="settings-icon"
        className="configurations-image"
        onClick={() => setPrintConfigModal(!printConfigModal)}
      />
      <div className="print-receipts">
        <div ref={componentRef} className="print-receipts__ss">
          {state.claims && state.claims.STUDENT ? (
            <div className={"print-receipts__dd"}>
              <ComponentToPrint
                tableData={tableData}
                v_date={v_date}
                v_no={v_no}
                studentDetails={studentDetails}
                transaction_no={transaction_no}
              />
              {USE_NON_DEMAND_INST_STD_LABEL && (
                <>
                  <b className="prs__bottom--copy">Student Copy</b>
                </>
              )}
              <div className="prs__bottom--flex">
                {USE_SIMPLIFIED_BY ? (
                  <div className="prs__bottom--simplify">
                    <img src={EduateLogo} alt="" />{" "}
                    <span>Simplified By My-Eduate</span>
                  </div>
                ) : null}

                {USE_NON_DEMAND_GENERATED_BY ? (
                  <div className="prs__bottom--simplify">
                    <b>Generated by</b>
                    <span>
                      {empDetails
                        ? empDetails.node.emp_first_name +
                          empDetails.node.emp_middle_name +
                          empDetails.node.emp_last_name
                        : "---"}
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
          ) : (
            [...Array(USE_NON_DEMAND_NO_COPIES)].map((_, index) => (
              <div className="print-receipts__dd" key={index}>
                <ComponentToPrint
                  tableData={tableData}
                  v_date={v_date}
                  v_no={v_no}
                  transaction_no={transaction_no}
                  studentDetails={studentDetails}
                />
                {USE_NON_DEMAND_INST_STD_LABEL && (
                  <>
                    {index === 0 ? (
                      <b className="prs__bottom--copy">Institution Copy</b>
                    ) : (
                      <b className="prs__bottom--copy">Student Copy</b>
                    )}
                  </>
                )}
                <div className="prs__bottom--flex">
                  <div className="prs__bottom--simplify">
                    <img src={EduateLogo} alt="" />{" "}
                    <span>Simplified By My-Eduate</span>
                  </div>
                  {USE_NON_DEMAND_GENERATED_BY ? (
                    <div className="prs__bottom--simplify">
                      <b>Generated by</b>
                      <span>
                        {" "}
                        {empDetails
                          ? empDetails.node.emp_first_name +
                            empDetails.node.emp_middle_name +
                            empDetails.node.emp_last_name
                          : "---"}
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>
            ))
          )}
        </div>

        <ReactToPrint
          trigger={() => <Button mode="print" />}
          content={() => componentRef.current}
          documentTitle={studentFormData.std_name}
        />
      </div>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={printConfigModal}
        style={PrintConfigModalStyles}
        ariaHideApp={false}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Configurations
              config_query_type={SwConfigQueryType.INST_BY_MODULE}
              str_value={ModuleName.NON_DEMAND}
              int_value={0}
              setModalFlag={setPrintConfigModal}
              pageType={PageFor.MODAL}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setPrintConfigModal(!printConfigModal)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PrintNonDemandReceipt;
