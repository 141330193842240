import { gql } from "@apollo/client";

// export const GetAcdStudentsAttendance = gql`
//   query GetAcdStudentsAttendance(
//     $token: String!
//     $input: StdAttendanceQuery
//     $name: String
//     $first: Int
//     $after: Cursor
//     $orderBy: AcdAttendanceStudentOrder
//   ) {
//     GetAcdStudentsAttendance(
//       token: $token
//       input: $input
//       first: $first
//       after: $after
//       orderBy: $orderBy
//       where: {
//         or: [{ hasMstStudentWith: { or: [{ firstNameContainsFold: $name }] } }]
//       }
//     ) {
//       totalCount
//       pageInfo {
//         endCursor
//         hasNextPage
//       }
//       edges {
//         node {
//           id
//           cal_month
//           att_total_days_present
//           att_total_days_absent_full
//           att_total_days_absent_half
//           att_total_days_absent
//           day_2
//           day_3
//           day_4
//           day_1
//           day_5
//           day_6
//           day_7
//           day_8
//           day_9
//           day_10
//           day_11
//           day_12
//           day_13
//           day_14
//           day_15
//           day_16
//           day_17
//           day_18
//           day_19
//           day_20
//           day_21
//           day_22
//           day_23
//           day_24
//           day_25
//           day_26
//           day_27
//           day_28
//           day_29
//           day_30
//           day_31
//           student_id
//           student_details {
//             first_name
//             middle_name
//             last_name
//             std_adm_no
//             std_reg_no
//           }
//         }
//       }
//     }
//   }
// `;

export const GetAcdInstGeneralHolidays = gql`
  query GetAcdInstGeneralHolidays(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $input: InstGenHolidayQuery!
    $orderBy: AcdInstGeneralHolidaysOrder
    $holidayDesc: String
  ) {
    GetAcdInstGeneralHolidays(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      input: $input
      orderBy: $orderBy
      where: { or: [{ holidayDescContainsFold: $holidayDesc }] }
    ) {
      totalCount
      pageInfo {
        endCursor
      }
      edges {
        node {
          id
          holiday_date
          holiday_desc
          holiday_duration
          holiday_type
        }
      }
    }
  }
`;

export const GetAcdInstHolidayConfig = gql`
  query GetAcdInstHolidayConfig(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
  ) {
    GetAcdInstHolidayConfig(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
    ) {
      id
      week_day
      day_idx
      week_day_status
      week_idx
    }
  }
`;
export const GetAcdStudentsAttendance = gql`
  query GetAcdStudentsAttendance($token: String!, $input: StdAttendanceQuery) {
    GetAcdStudentsAttendance(token: $token, input: $input) {
      id
      cal_month
      att_total_days_present
      att_total_days_absent_full
      att_total_days_absent_half
      att_total_days_absent
      day_2
      day_3
      day_4
      day_1
      day_5
      day_6
      day_7
      day_8
      day_9
      day_10
      day_11
      day_12
      day_13
      day_14
      day_15
      day_16
      day_17
      day_18
      day_19
      day_20
      day_21
      day_22
      day_23
      day_24
      day_25
      day_26
      day_27
      day_28
      day_29
      day_30
      day_31
      student_id
      student_details {
        first_name
        middle_name
        last_name
        std_adm_no
        std_reg_no
      }
    }
  }
`;
export const GetAcdStdsForAttendance = gql`
  query GetAcdStdsForAttendance($token: String!, $input: StdAttendanceQuery) {
    GetAcdStdsForAttendance(token: $token, input: $input) {
      id
      first_name
      middle_name
      last_name
      std_type
      std_studying
      std_status
      std_sex
      std_reg_no
      std_adm_no
      std_sts_no
      std_doa
      std_fresher
      std_curr_fin_yr
      std_curr_acd_yr
      std_dob
      std_email
      std_mobile
      std_father_name
      std_mother_name
      std_father_mobile
      std_mother_mobile
      std_profile_filename
    }
  }
`;
export const GetAcdInstGeneralHolidayTypeCount = gql`
  query GetAcdInstGeneralHolidayTypeCount(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $start_date: Time!
    $end_date: Time!
  ) {
    GetAcdInstGeneralHolidayTypeCount(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      start_date: $start_date
      end_date: $end_date
    ) {
      general_holiday_count
      unexpected_holiday_count
      weekend_holiday_count
      vacation_holiday_count
    }
  }
`;
export const GetAcdInstMonthlyWorkingCalendar = gql`
  query GetAcdInstMonthlyWorkingCalendar(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
  ) {
    GetAcdInstMonthlyWorkingCalendar(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
    ) {
      id
      cal_month
      month_start_date
      month_end_date
      no_of_days
      no_of_weekend_days
      no_of_general
      no_of_unexpected
      total_no_of_holidays
      total_no_of_workdays
      calendar_entered
      no_of_vacation
    }
  }
`;
export const GetAcdStudentAttendanceByStdId = gql`
  query GetAcdStudentAttendanceByStdId(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $student_id: ID!
    $cal_month: Time!
  ) {
    GetAcdStudentAttendanceByStdId(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      student_id: $student_id
      cal_month: $cal_month
    ) {
      id
      cal_month
      att_total_days_present
      att_total_days_absent_full
      att_total_days_absent_half
      att_total_days_absent
      day_2
      day_3
      day_4
      day_1
      day_5
      day_6
      day_7
      day_8
      day_9
      day_10
      day_11
      day_12
      day_13
      day_14
      day_15
      day_16
      day_17
      day_18
      day_19
      day_20
      day_21
      day_22
      day_23
      day_24
      day_25
      day_26
      day_27
      day_28
      day_29
      day_30
      day_31
    }
  }
`;
export const GetAcdInstGeneralHolidayConfiguredWeekends = gql`
  query GetAcdInstGeneralHolidayConfiguredWeekends(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $day_idx: Int!
    $week_idx: Int!
  ) {
    GetAcdInstGeneralHolidayConfiguredWeekends(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      day_idx: $day_idx
      week_idx: $week_idx
    ) {
      id
      holiday_date
      holiday_desc
      holiday_duration
      holiday_type
      day_idx
      week_idx
    }
  }
`;
export const GetAcdStdAttOverAll = gql`
  query GetAcdStdAttOverAll(
    $token: String!
    $input: SubjMonthlyAttQuery
    $name: String
    $first: Int
    $after: Cursor
    $orderBy: AcdAttendanceStdOverAllOrder
  ) {
    GetAcdStdAttOverAll(
      token: $token
      input: $input
      first: $first
      after: $after
      orderBy: $orderBy
      where: {
        or: [{ hasMstStudentWith: { or: [{ firstNameContainsFold: $name }] } }]
      }
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }

      edges {
        node {
          total_session
          total_session_present
          total_session_absent
          over_all_per
          student_details {
            first_name
            middle_name
            last_name
            std_adm_no
            std_reg_no
            id
            dept {
              dept_desc
            }
            branch {
              branch_desc
            }
            class {
              class_desc
            }
            semester {
              sem_desc
            }
            section {
              section_desc
            }
          }
        }
      }
    }
  }
`;
export const GetAcdStdSubjAttOverAll = gql`
  query GetAcdStdSubjAttOverAll(
    $token: String!
    $input: SubjMonthlyAttQuery
    $name: String
    $first: Int
    $after: Cursor
    $orderBy: AcdAttendanceSubjYearlyOrder
  ) {
    GetAcdStdSubjAttOverAll(
      token: $token
      input: $input
      first: $first
      after: $after
      orderBy: $orderBy
      where: {
        or: [{ hasMstStudentWith: { or: [{ firstNameContainsFold: $name }] } }]
      }
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          std_roll_no
          total_session
          total_session_present
          total_session_absent
          over_all_per
          acd_dept_id
          acd_branch_id
          acd_class_id
          acd_semester_id
          acd_section_id
          student_id
          acd_yr_id
          inst_id
          subj_master_id
          student_details {
            first_name
            middle_name
            last_name
            std_adm_no
            std_reg_no
          }
          subject_details {
            id
            subj_code
            subj_desc
          }
        }
      }
    }
  }
`;
export const GetAcdAttStdDailyStatus = gql`
  query GetAcdAttStdDailyStatus($token: String!, $input: StdAttStatusQuery) {
    GetAcdAttStdDailyStatus(token: $token, input: $input) {
      day_1
      day_2
      day_3
      day_4
      day_5
      day_6
      day_7
      day_8
      day_9
      day_10
      day_11
      day_12
      day_13
      day_14
      day_15
      day_16
      day_17
      day_18
      day_19
      day_20
      day_21
      day_22
      day_23
      day_24
      day_25
      day_26
      day_27
      day_28
      day_29
      day_30
      day_31
    }
  }
`;
export const GetAcdAttStdTodaysStatus = gql`
  query GetAcdAttStdTodaysStatus($token: String!, $input: StdAttStatusQuery) {
    GetAcdAttStdTodaysStatus(token: $token, input: $input)
  }
`;

export const GetAcdStdAttSubjMonthlyForTheDay = gql`
  query GetAcdStdAttSubjMonthlyForTheDay(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $date_of_attendance: Time!
    $student_id: ID!
    $per_std_subj_allocation: Boolean!
  ) {
    GetAcdStdAttSubjMonthlyForTheDay(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      date_of_attendance: $date_of_attendance
      student_id: $student_id
      per_std_subj_allocation: $per_std_subj_allocation
    ) {
      subj_master_id
      subj_code
      subj_desc
      session_details {
        session_id
        session_desc
        start_time
        end_time
        att_status
      }
    }
  }
`;
export const GetAcdStdAttBySession = gql`
  query GetAcdStdAttBySession(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $date_of_attendance: Time!
    $student_id: ID!
    $session_id: ID!
    $subj_master_id: ID!
    $per_std_subj_allocation: Boolean!
  ) {
    GetAcdStdAttBySession(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      date_of_attendance: $date_of_attendance
      student_id: $student_id
      session_id: $session_id
      subj_master_id: $subj_master_id
      per_std_subj_allocation: $per_std_subj_allocation
    )
  }
`;
export const GetAcdStdAttSubjMonthlyForCal = gql`
  query GetAcdStdAttSubjMonthlyForCal(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $date_of_attendance: Time!
    $student_id: ID!
    $per_std_subj_allocation: Boolean!
  ) {
    GetAcdStdAttSubjMonthlyForCal(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      date_of_attendance: $date_of_attendance
      student_id: $student_id
      per_std_subj_allocation: $per_std_subj_allocation
    ) {
      cal_month
      day_1
      day_2
      day_3
      day_4
      day_5
      day_6
      day_7
      day_8
      day_9
      day_10
      day_11
      day_12
      day_13
      day_14
      day_15
      day_16
      day_17
      day_18
      day_19
      day_20
      day_21
      day_22
      day_23
      day_24
      day_25
      day_26
      day_27
      day_28
      day_29
      day_30
      day_31
    }
  }
`;
export const GetAcdStdAttSubjMonthlyPerSessionForCal = gql`
  query GetAcdStdAttSubjMonthlyPerSessionForCal(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $date_of_attendance: Time!
    $student_id: ID!
    $subj_master_id: ID!
    $session_id: ID!
    $per_std_subj_allocation: Boolean!
  ) {
    GetAcdStdAttSubjMonthlyPerSessionForCal(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      date_of_attendance: $date_of_attendance
      student_id: $student_id
      subj_master_id: $subj_master_id
      session_id: $session_id
      per_std_subj_allocation: $per_std_subj_allocation
    ) {
      cal_month
      day_1
      day_2
      day_3
      day_4
      day_5
      day_6
      day_7
      day_8
      day_9
      day_10
      day_11
      day_12
      day_13
      day_14
      day_15
      day_16
      day_17
      day_18
      day_19
      day_20
      day_21
      day_22
      day_23
      day_24
      day_25
      day_26
      day_27
      day_28
      day_29
      day_30
      day_31
    }
  }
`;
export const GetAcdAttendanceSubjMonthly = gql`
  query GetAcdAttendanceSubjMonthly(
    $token: String!
    $input: SubjMonthlyAttQuery
    $first: Int
    $after: Cursor
    $orderBy: AcdAttendanceSubjMonthlyOrder
    $per_std_subj_allocation: Boolean!
    $name: String
  ) {
    GetAcdAttendanceSubjMonthly(
      token: $token
      input: $input
      first: $first
      after: $after
      orderBy: $orderBy
      per_std_subj_allocation: $per_std_subj_allocation
      where: {
        or: [
          {
            hasMstStudentWith: [
              {
                or: [
                  { firstNameContainsFold: $name }
                  { middleNameContainsFold: $name }
                  { lastNameContainsFold: $name }
                  { stdAdmNoContainsFold: $name }
                  { stdRegNoContainsFold: $name }
                ]
              }
            ]
          }
        ]
      }
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          cal_month
          std_roll_no
          day_1
          day_2
          day_3
          day_4
          day_5
          day_6
          day_7
          day_8
          day_9
          day_10
          day_11
          day_12
          day_13
          day_14
          day_15
          day_16
          day_17
          day_18
          day_19
          day_20
          day_21
          day_22
          day_23
          day_24
          day_25
          day_26
          day_27
          day_28
          day_29
          day_30
          day_31
          acd_dept_id
          acd_branch_id
          acd_class_id
          acd_semester_id
          acd_section_id
          student_id
          acd_yr_id
          subj_master_id
          inst_id
          session_id
          student_details {
            id
            first_name
            middle_name
            last_name
            std_reg_no
            std_adm_no
          }
          subject_details {
            id
            subj_code
            subj_desc
            subj_idx
            teacher_count
            subj_board_code
          }
          session_details {
            id
            session_desc
            start_time
            end_time
          }
        }
      }
    }
  }
`;
export const GetAcdAttStdAbsentDetailsForTheDay = gql`
  query GetAcdAttStdAbsentDetailsForTheDay(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $student_id: ID!
    $date_of_attendance: Time!
  ) {
    GetAcdAttStdAbsentDetailsForTheDay(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      student_id: $student_id
      date_of_attendance: $date_of_attendance
    ) {
      id
      date_of_attendance
      att_status
      comments
      approved_by
      student_id
      acd_yr_id
      inst_id
    }
  }
`;

export const GetAcdInstTreeForTestReport = gql`
  query GetAcdInstTreeForTestReport(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $id: ID!
    $query_type: InstTreeQueryType!
    $test_name_id: ID!
  ) {
    GetAcdInstTreeForTestReport(
      token: $token
      inst_id: $inst_id
      id: $id
      acd_yr_id: $acd_yr_id
      query_type: $query_type
      test_name_id: $test_name_id
    ) {
      acd_level_1
      acd_level_1_name
      acd_level_1_desc
      acd_level_1_id
      sub_acd_level_total_count
      acd_level_2_details {
        acd_level_2
        acd_level_2_name
        acd_level_2_desc
        acd_level_2_id
        contains_topper_details
        sub_acd_level_total_count
        acd_test_class_id

        allotted_level
        allotted_id

        first_rank_info {
          std_id
          first_name
          middle_name
          last_name
          std_profile_filename
          percent_marks_scored
          entry_level_name
          std_adm_no
        }
        second_rank_info {
          std_id
          first_name
          middle_name
          last_name
          std_profile_filename
          percent_marks_scored
          entry_level_name
          std_adm_no
        }
        third_rank_info {
          std_id
          first_name
          middle_name
          last_name
          std_profile_filename
          percent_marks_scored
          entry_level_name
          std_adm_no
        }

        acd_level_3_details {
          acd_level_3
          acd_level_3_name
          acd_level_3_desc
          acd_level_3_id
          contains_topper_details
          acd_test_class_id

          allotted_level
          allotted_id
          sub_acd_level_total_count

          first_rank_info {
            std_id
            first_name
            middle_name
            last_name
            std_profile_filename
            percent_marks_scored
            entry_level_name
            std_adm_no
          }
          second_rank_info {
            std_id
            first_name
            middle_name
            last_name
            std_profile_filename
            percent_marks_scored
            entry_level_name
            std_adm_no
          }
          third_rank_info {
            std_id
            first_name
            middle_name
            last_name
            std_profile_filename
            percent_marks_scored
            entry_level_name
            std_adm_no
          }
          acd_level_4_details {
            acd_level_4
            acd_level_4_name
            acd_level_4_desc
            acd_level_4_id
            acd_test_class_id

            allotted_level
            allotted_id
            contains_topper_details

            first_rank_info {
              std_id
              first_name
              middle_name
              last_name
              std_profile_filename
              percent_marks_scored
              entry_level_name
              std_adm_no
            }
            second_rank_info {
              std_id
              first_name
              middle_name
              last_name
              std_profile_filename
              percent_marks_scored
              entry_level_name
              std_adm_no
            }
            third_rank_info {
              std_id
              first_name
              middle_name
              last_name
              std_profile_filename
              percent_marks_scored
              entry_level_name
              std_adm_no
            }
            sub_acd_level_total_count
            acd_level_5_details {
              acd_level_5
              acd_level_5_name
              acd_level_5_desc
              acd_level_5_id
              contains_topper_details
              acd_test_class_id

              allotted_level
              allotted_id

              first_rank_info {
                std_id
                first_name
                middle_name
                last_name
                std_profile_filename
                percent_marks_scored
                entry_level_name
                std_adm_no
              }
              second_rank_info {
                std_id
                first_name
                middle_name
                last_name
                std_profile_filename
                percent_marks_scored
                entry_level_name
                std_adm_no
              }
              third_rank_info {
                std_id
                first_name
                middle_name
                last_name
                std_profile_filename
                percent_marks_scored
                entry_level_name
                std_adm_no
              }
            }
          }
        }
      }
    }
  }
`;

export const GetAcdTestPerformance = gql`
  query GetAcdTestPerformance(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $test_name_id: ID!
    $allotted_level: String!
    $allotted_id: ID!
  ) {
    GetAcdTestPerformance(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      test_name_id: $test_name_id
      allotted_level: $allotted_level
      allotted_id: $allotted_id
    ) {
      test_perfromance {
        id
        num_of_std_test_taken
        num_of_std_passed
        num_of_std_failed
        num_of_std_mt_80_per
        num_of_std_mt_70_per
        num_of_std_mt_60_per
        num_of_std_mt_50_per
        num_of_std_mt_40_per
        num_of_std_lt_40_per
      }
      first_rank_info {
        std_id
        first_name
        middle_name
        last_name
        std_profile_filename
        percent_marks_scored
        entry_level_name
        std_adm_no
      }
      second_rank_info {
        std_id
        first_name
        middle_name
        last_name
        std_profile_filename
        percent_marks_scored
        entry_level_name
        std_adm_no
      }
      third_rank_info {
        std_id
        first_name
        middle_name
        last_name
        std_profile_filename
        percent_marks_scored
        entry_level_name
        std_adm_no
      }
    }
  }
`;
export const GetAcdTestSubjectPerformance = gql`
  query GetAcdTestSubjectPerformance(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $acd_test_class_id: ID!
    $subj_master_id: ID!
  ) {
    GetAcdTestSubjectPerformance(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      acd_test_class_id: $acd_test_class_id
      subj_master_id: $subj_master_id
    ) {
      id
      num_of_std_test_taken
      num_of_std_passed
      num_of_std_failed
      num_of_std_mt_80_per
      num_of_std_mt_70_per
      num_of_std_mt_60_per
      num_of_std_mt_50_per
      num_of_std_mt_40_per
      num_of_std_lt_40_per
    }
  }
`;

export const GetAcdStdMonthlyAttAbsentDetails = gql`
  query GetAcdStdMonthlyAttAbsentDetails(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $student_id: ID!
    $subj_master_id: ID!
  ) {
    GetAcdStdMonthlyAttAbsentDetails(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      student_id: $student_id
      subj_master_id: $subj_master_id
    ) {
      cal_month
      absent_details {
        date_of_attendance
        session_count
        comments
      }
    }
  }
`;
export const GetAcdAttDailyMarkedStatus = gql`
  query GetAcdAttDailyMarkedStatus(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $attendance_date: Time!
  ) {
    GetAcdAttDailyMarkedStatus(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      attendance_date: $attendance_date
    ) {
      pr_emp_id
      pr_emp_name
      emp_profile_filename
      att_marked_details {
        subj_master_id
        subj_code
        subj_desc
        session_id
        session_desc
        session_idx
      }
    }
  }
`;
export const GetAcdInstTreeForTestFinalize = gql`
  query GetAcdInstTreeForTestFinalize(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $id: ID!
    $query_type: InstTreeQueryType!
    $test_name_id: ID!
  ) {
    GetAcdInstTreeForTestFinalize(
      token: $token
      inst_id: $inst_id
      id: $id
      acd_yr_id: $acd_yr_id
      query_type: $query_type
      test_name_id: $test_name_id
    ) {
      acd_level_1
      acd_level_1_name
      acd_level_1_desc
      acd_level_1_id
      sub_acd_level_total_count
      acd_level_2_details {
        acd_level_2
        acd_level_2_name
        acd_level_2_desc
        acd_level_2_id
        is_test_planned
        is_marks_entry_started
        is_marks_entry_completed
        per_std_subj_allocation
        is_report_generated
        publish_results
        contains_topper_details
        marks_entry_start_date
        marks_entry_end_date
        acd_test_class_id
        allotted_level
        allotted_id
        sub_acd_level_total_count
        acd_level_3_details {
          acd_level_3
          acd_level_3_name
          acd_level_3_desc
          acd_level_3_id
          is_test_planned
          is_marks_entry_started
          is_marks_entry_completed
          per_std_subj_allocation
          is_report_generated
          publish_results
          contains_topper_details
          marks_entry_start_date
          marks_entry_end_date
          acd_test_class_id
          allotted_level
          allotted_id
          sub_acd_level_total_count
          acd_level_4_details {
            acd_level_4
            acd_level_4_name
            acd_level_4_desc
            acd_level_4_id
            is_test_planned
            is_marks_entry_started
            is_marks_entry_completed
            per_std_subj_allocation
            is_report_generated
            publish_results
            contains_topper_details
            marks_entry_start_date
            marks_entry_end_date
            acd_test_class_id
            allotted_level
            allotted_id
            sub_acd_level_total_count
            acd_level_5_details {
              acd_level_5
              acd_level_5_name
              acd_level_5_desc
              acd_level_5_id
              is_test_planned
              is_marks_entry_started
              is_marks_entry_completed
              per_std_subj_allocation
              is_report_generated
              publish_results
              contains_topper_details
              marks_entry_start_date
              marks_entry_end_date
              acd_test_class_id
              allotted_level
              allotted_id
              sub_acd_level_total_count
            }
          }
        }
      }
    }
  }
`;
