import React, { useContext, useEffect, useState } from "react";
import Close from "../../../images/Close.svg";
import { Title } from "../../../stories/Title/Title";
import useStudentTableJson from "../../../json/useStudentTableJson";
import {
  ColumnVisibilityFor,
  Direction,
  Operation,
  SortBy,
} from "../../../utils/Enum.types";
import { TableHeaderProps } from "../../../utils/types";
import {
  GridAlignment,
  GridCellParams,
  GridColDef,
  GridColumnVisibilityModel,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import { Checkbox, TextField } from "@mui/material";
import { Keys } from "../../../utils/Enum.keys";
import useInstLabels from "../../../customhooks/general/useInstLabels";
import {
  getModifiedScrollHeight,
  handleMUISelectEvent,
  isOptionEqualToValue,
} from "../../../utils/UtilFunctions";
import Input from "../../../stories/Input/Input";
import { msgType, responseType } from "../../../utils/Form.types";
import useDropdownData from "../../../customhooks/useDropdownData";
import {
  HEADER_TEXT_ALIGN,
  MARKS_TEXT_ALIGN,
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../styles/DataGridTableStyles";
import { Button } from "../../../stories/Button/Button";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GetAcctStdDemandData,
  StudentDemandEdges,
  vars,
} from "../hooks/useStudentsbyDemandAmount";
import { GetAcctStdDemandForAgents } from "../../../queries/students/list/newApi";
import { AppContext } from "../../../context/context";
import {
  emptyMessageType,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../utils/constants";
import { useParams } from "react-router-dom";
import { AcctStudentQueryType } from "../common/QueryTypes";
import useToken from "../../../customhooks/useToken";
import { userDetails } from "../../../Types/Accounting/other";
import { AssoicateAgentAndStds } from "../queries/Agents/mutations";
import useLoggedInUserDetails from "../hooks/useLoggedInUserDetails";
import MessageModal from "../../../pages/MessageModal";
import LoadingModal from "../../../pages/LoadingModal";
import useInstMasterDataByInstId from "../../../customhooks/useInstMasterDataByInstId";
import { payloadTypes } from "../../../context/reducer";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../styles/AutocompleteListStyles";

interface Props {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}

interface AssoicateAgentAndStdsData {
  AssoicateAgentAndStds: boolean;
}

interface AssoicateAgentAndStdsVars {
  token: string;
  inst_id: string;
  agent_id: number;
  user_details: userDetails;
  stdIds: number[];
}

const Allocate = ({ setModal }: Props) => {
  const { InstId } = useParams();

  const { state, dispatch } = useContext(AppContext);

  const { token } = useToken();

  const { Student } = useStudentTableJson({
    jsonFor: ColumnVisibilityFor.SELECT_STD_AGENTS,
  });

  const { user_details } = useLoggedInUserDetails();

  const [entryId, setEntryId] = useState(0);

  const [searchStudent, setSearchStudent] = useState("");

  const [students, setStudents] = useState<StudentDemandEdges[]>([]);

  const [rows, setRows] = useState<GridValidRowModel[]>([]);

  const [message, setMessage] = useState<msgType>(emptyMessageType);

  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);
  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [classSelected, setClassSelected] = useState<responseType | null>(null);
  const [semesterSelected, setSemesterSelected] = useState<responseType | null>(
    null
  );
  const [sectionSelected, setSectionSelected] = useState<responseType | null>(
    null
  );
  const [categorySelected, setCategorySelected] = useState<responseType | null>(
    null
  );
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [selectedStudents, setSelectedStudents] = useState<number[]>([]);
  const [studentIdSet, setStudentIdSet] = useState<Set<number>>(new Set());

  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useDropdownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0
  );

  const { categoryDropDown } = useInstMasterDataByInstId();

  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
    USE_CATEGORY_KEY,
  } = useInstitutionConfiguration();
  const [AssociateStudents, { loading: AssocLoading }] = useMutation<
    AssoicateAgentAndStdsData,
    AssoicateAgentAndStdsVars
  >(AssoicateAgentAndStds, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  useEffect(() => {
    if (sectionSelected && USE_SECTION_KEY) {
      setEntryId(sectionSelected.value);
    } else if (semesterSelected && USE_SEMESTER_KEY) {
      setEntryId(semesterSelected.value);
    } else if (classSelected && USE_CLASS_KEY) {
      setEntryId(classSelected.value);
    } else if (branchSelected && USE_BRANCH_KEY) {
      setEntryId(branchSelected.value);
    } else if (departmentSelected && USE_DEPARTMENT_KEY) {
      setEntryId(departmentSelected.value);
    } else {
      setEntryId(Number(InstId));
    }
  }, [
    sectionSelected,
    USE_SECTION_KEY,
    semesterSelected,
    USE_SEMESTER_KEY,
    classSelected,
    USE_CLASS_KEY,
    branchSelected,
    USE_BRANCH_KEY,
    departmentSelected,
    USE_DEPARTMENT_KEY,
    InstId,
  ]);

  const [GetAccountStudents, { data, loading, fetchMore }] = useLazyQuery<
    GetAcctStdDemandData,
    vars
  >(GetAcctStdDemandForAgents, {
    variables: {
      after: null,
      inst_id: InstId!,
      balance: null,
      direction: Direction.ASC,
      fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
      first: ROWS_PER_PAGE,
      input: {
        ids: [
          entryId,
          categorySelected ? categorySelected.value : 0,
          state.agentId,
        ],
        std_demand_query_type:
          AcctStudentQueryType.STDS_NOT_ASSOICATED_TO_AGENT,
        std_status: ["CUR"],
      },
      name: searchStudent,
      status: "",
      stdFresher: false,
      token,
      sortBy: SortBy.STUDENT_ID,
    },
  });

  const {
    departmentLabel,
    branchLabel,
    classLabel,
    semesterLabel,
    sectionLabel,
    categoryLabel,
  } = useInstLabels();

  const handleSelectAll = () => {
    if (!selectAll) {
      const allStudentIds = rows
        .map((student) => student.std_id)
        .filter((id) => Number.isInteger(id)) as number[];
      setSelectedStudents(allStudentIds);
      setSelectAll(true);
    } else {
      setSelectedStudents([]);
      setSelectAll(false);
    }
  };

  const hanldeStudentClick = (
    params: GridCellParams,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (params.field === "select") {
      const selectedRow = rows.find((row) => row.id === params.id);
      const tempSet: Set<number> = new Set(studentIdSet);
      const checked = e.target.checked;
      if (selectedRow && selectedRow.std_id) {
        if (checked) {
          tempSet.add(selectedRow.std_id);
        } else {
          tempSet.delete(selectedRow.std_id);
        }
      }

      setStudentIdSet(tempSet);
      setSelectedStudents(Array.from(tempSet));
      // Update selected students
    }
  };

  const dynamicHeaders: TableHeaderProps[] = Student.Table_Headers?.map(
    (header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
      hideable: header.hideable,
    })
  );

  const columns: GridColDef[] = [
    {
      field: "select",
      cellClassName: "td-actions-print ",
      headerClassName: "td-actions-print-header",
      headerAlign: HEADER_TEXT_ALIGN,
      align: MARKS_TEXT_ALIGN,
      renderHeader: (params) => {
        return (
          <>
            <Checkbox checked={selectAll} onChange={() => handleSelectAll()} />
            Select All
          </>
        );
      },
      renderCell: (params) => {
        const studentId = params.row.std_id || "";

        return (
          <>
            {studentIdSet.has(studentId)}
            <Checkbox
              checked={selectAll || studentIdSet.has(Number(studentId))}
              onChange={(e) => hanldeStudentClick(params, e)}
            />
          </>
        );
      },
    },
    ...dynamicHeaders,
  ];

  const [columnVisibilityModel, setColumnVisibilityModel] =
    React.useState<GridColumnVisibilityModel>({
      std_adm_no: true,
      std_reg_no: true,
      std_name: true,
      std_father_name: true,
      std_mother_name: false,
      std_sex: false,
      std_doa: false,
      std_dob: false,
      std_email: true,
      std_mobile: true,
      dept: true,
      branch: true,
      class: true,
      sem: true,
      sec: true,
      category: true,
    });

  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[1] as Element;

      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;

        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: FETCH_MORE_DATA,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetAcctStdDemand?.edges;
                const pageInfo = fetchMoreResult.GetAcctStdDemand.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck = prevResult.GetAcctStdDemand.edges.filter(
                  ({ node: { id } }) =>
                    newEdges.findIndex(
                      ({ node: { id: newId } }) => newId === id
                    ) !== -1
                );

                if (duplicateCheck?.length > 0) return prevResult;

                return {
                  GetAcctStdDemand: {
                    edges: [...students, ...newEdges],
                    pageInfo,
                    totalCount: data ? data.GetAcctStdDemand.totalCount! : 0,
                  },
                };
              },
            });
          }
        }
      };

      if (scrollTable && rows && rows?.length > 0)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    [rows]
  );
  useEffect(() => {
    if (token && entryId && state.ActiveFinYr && state.agentId) {
      GetAccountStudents();
    }
  }, [
    token,
    GetAccountStudents,
    InstId,
    entryId,
    searchStudent,
    state.ActiveFinYr,
    state.agentId,
    categorySelected,
  ]);

  useEffect(() => {
    const savedVisibilityModel = localStorage.getItem(
      ColumnVisibilityFor.SELECT_STD_AGENTS
    );
    if (savedVisibilityModel) {
      setColumnVisibilityModel(JSON.parse(savedVisibilityModel));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      ColumnVisibilityFor.SELECT_STD_AGENTS,
      JSON.stringify(columnVisibilityModel)
    );
  }, [columnVisibilityModel]);
  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetAcctStdDemand.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = students.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            std_adm_no: node.mst_student.std_adm_no,
            std_reg_no: node.mst_student.std_reg_no,
            std_name: `${node.mst_student.first_name} ${node.mst_student.middle_name} ${node.mst_student.last_name}`,
            std_father_name: node.mst_student.std_father_name,
            std_mother_name: node.mst_student.std_mother_name,
            std_sex: node.mst_student.std_sex,
            std_doa: node.mst_student.std_doa,
            std_dob: node.mst_student.std_dob,
            std_email: node.mst_student.std_email,
            std_mobile: node.mst_student.std_mobile,
            dept: node.mst_student.dept.dept_desc,
            branch: node.mst_student.branch.branch_desc,
            class: node.mst_student.class.class_desc,
            sem: node.mst_student.semester.sem_desc,
            sec: node.mst_student.section.section_desc,
            category: node.mst_student.category.cat_desc,
            std_id: node.mst_student.id,
          }))
        );
      } else {
        setStudents(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            std_adm_no: node.mst_student.std_adm_no,
            std_reg_no: node.mst_student.std_reg_no,
            std_name: `${node.mst_student.first_name} ${node.mst_student.middle_name} ${node.mst_student.last_name}`,
            std_father_name: node.mst_student.std_father_name,
            std_mother_name: node.mst_student.std_mother_name,
            std_sex: node.mst_student.std_sex,
            std_doa: node.mst_student.std_doa,
            std_dob: node.mst_student.std_dob,
            std_email: node.mst_student.std_email,
            std_mobile: node.mst_student.std_mobile,
            dept: node.mst_student.dept.dept_desc,
            branch: node.mst_student.branch.branch_desc,
            class: node.mst_student.class.class_desc,
            sem: node.mst_student.semester.sem_desc,
            sec: node.mst_student.section.section_desc,
            category: node.mst_student.category.cat_desc,
            std_id: node.mst_student.id,
          }))
        );
      }
      setEndCursor(data.GetAcctStdDemand.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);

  const handleSubmit = () => {
    if (state.agentId) {
      AssociateStudents({
        variables: {
          agent_id: state.agentId,
          inst_id: InstId!,
          stdIds: Array.from(studentIdSet),
          token,
          user_details,
        },
        refetchQueries: [
          {
            query: GetAcctStdDemandForAgents,
            variables: {
              after: null,
              balance: 0,
              direction: Direction.ASC,
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              first: ROWS_PER_PAGE,
              input: {
                ids: [entryId, 0, state.agentId],
                std_demand_query_type: AcctStudentQueryType.BY_AGENT_ID,
              },
              name: searchStudent,
              status: "",
              stdFresher: false,
              token,
              sortBy: SortBy.STUDENT_ID,
            },
          },
          {
            query: GetAcctStdDemandForAgents,
            variables: {
              after: null,
              balance: null,
              direction: Direction.ASC,
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              first: ROWS_PER_PAGE,
              input: {
                ids: [entryId, 0, state.agentId],
                std_demand_query_type:
                  AcctStudentQueryType.STDS_NOT_ASSOICATED_TO_AGENT,
              },
              name: searchStudent,
              status: "",
              stdFresher: false,
              token,
              sortBy: SortBy.STUDENT_ID,
            },
          },
        ],
      }).then(({ data }) => {
        if (data && data.AssoicateAgentAndStds) {
          setMessage({
            flag: true,
            message: `Students successfully associated for Agent`,
            operation: Operation.CREATE,
          });
        }
      });
    } else {
      setMessage({
        flag: true,
        message: "Please select Agent",
        operation: Operation.NONE,
      });
    }
  };
  return (
    <>
      <div className="agent-std-assoc__allocate">
        <div className="agent-std-assoc__allocate--title">
          <Title> Select Students</Title>
          <img src={Close} alt="" onClick={() => setModal(false)} />
        </div>
        <div className="agent-std-assoc__allocate--filters row g-0">
          <div className="col-2">
            <Input
              id="search"
              placeholder="Search..."
              onChange={(e) => setSearchStudent(e.target.value)}
            />
          </div>
          {USE_DEPARTMENT_KEY ? (
            <div className="col-2">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={departmentDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(
                    option as responseType,
                    departmentSelected
                  )
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (departmentSelected) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setDepartmentSelected(null);
                  }
                }}
                openOnFocus
                value={departmentSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setDepartmentSelected(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setDepartmentSelected(null);
                  }
                  setBranchSelected(null);
                  setClassSelected(null);
                  setSemesterSelected(null);
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={departmentLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_BRANCH_KEY ? (
            <div className="col-2">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={branchDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, branchSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (branchSelected) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setBranchSelected(null);
                  }
                }}
                openOnFocus
                value={branchSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setBranchSelected(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setBranchSelected(null);
                  }
                  setClassSelected(null);
                  setSemesterSelected(null);
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={branchLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_CLASS_KEY ? (
            <div className="col-2">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={classDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, classSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (classSelected) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setClassSelected(null);
                  }
                }}
                openOnFocus
                value={classSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setClassSelected(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setClassSelected(null);
                  }
                  setSemesterSelected(null);
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={classLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                    fullWidth
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SEMESTER_KEY ? (
            <div className="col-2">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={semesterDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, semesterSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (semesterSelected) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSemesterSelected(null);
                  }
                }}
                openOnFocus
                value={semesterSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSemesterSelected(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setSemesterSelected(null);
                  }
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={semesterLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    fullWidth
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SECTION_KEY ? (
            <div className="col-2">
              <LabeledAutocomplete
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, sectionSelected)
                }
                className={labelClasses.inputRoot}
                options={sectionDropDown}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (sectionSelected) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSectionSelected(null);
                  }
                }}
                openOnFocus
                value={sectionSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSectionSelected(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setSectionSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={sectionLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_CATEGORY_KEY ? (
            <div className="col-2">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={categoryDropDown}
                openOnFocus
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, categorySelected)
                }
                value={categorySelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setCategorySelected(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setCategorySelected(null);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setCategorySelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={categoryLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                    fullWidth
                  />
                )}
              />
            </div>
          ) : null}
        </div>
        <div className={`agent-std-assoc__allocate--tableblock`}>
          <StyledDatagrid
            columns={columns}
            rows={rows}
            rowHeight={TABLE_ROW_HEIGHT}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
            disableRowSelectionOnClick
            hideFooter
          />
        </div>
        <Button mode="save" onClick={handleSubmit} />
        <Button
          mode="cancel"
          onClick={() => {
            dispatch({
              payload: {
                agentId: 0,
              },
              type: payloadTypes.SET_AGENT_ID,
            });
            setModal(false);
          }}
        />
      </div>

      <MessageModal
        handleClose={() => {
          setMessage(emptyMessageType);
          dispatch({
            payload: {
              agentId: 0,
            },
            type: payloadTypes.SET_AGENT_ID,
          });
          setModal(false);
        }}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />

      <LoadingModal flag={AssocLoading} />
    </>
  );
};

export default Allocate;
