import React, { Fragment, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { AcdStudentDetails } from "../../../Academics/hooks/useAcdStudentDetails";

import useStudentMarksStatistics, {
  SelectedStudentsProps,
} from "../hooks/useStudentMarksStatistics";
import { stdMarksType } from "../Template1/FrontSide";
import useReportCardConfig from "../../../Academics/Reports/CompleteReports/ReportCards/hooks/useReportCardConfig";
import useInstLogoDetails from "../../../../customhooks/useInstLogoDetails";
import { FileUploadParams } from "../../../../utils/Enum.types";

export const enum MarksDetailsTableFor {
  ACADEMIC = "ACADEMIC",
  NON_ACADEMIC = "NON_ACADEMIC",
  OTHER_DETAILS = "OTHER_DETAILS",
  SIGNATURES = "SIGNATURES",
  ATTENDANCE = "ATTENDANCE",
}

interface ReportCardMarksDetailsProps extends SelectedStudentsProps {
  type: MarksDetailsTableFor;
  studentData: AcdStudentDetails;
}

const MarksDetails = ({
  selectedStudents,
  setStudentIdSet,
  type,
}: ReportCardMarksDetailsProps) => {
  const { data } = useStudentMarksStatistics({
    selectedStudents,
    setStudentIdSet,
  });
  const [stdMarks, setStdMarks] = useState<stdMarksType[]>([]);
  useEffect(() => {
    if (data) {
      let items: stdMarksType[] = data
        ? (data.GetAcdStudentsMarksStatisticsForReportCard.map((mark) => {
            let tests: string[] = [];
            let subj_max_marks: number[] = [];

            let total_std_marks: number[] = [];
            let total_std_percentage: number[] = [];
            let std_rank: number[] = [];
            let std_remarks: string[] = [];
            let std_working_days: number = 0;
            let std_present_days: number = 0;
            let std_overall_grade: string[] = [];
            let no_acd_tests: string[] = [];
            let no_non_acd_tests: string[] = [];
            let no_non_acd_remarks: string[] = [];
            let acd_subj_grades: string[] = [];

            let subjects: Map<string, { [key: string]: number }> = new Map();
            let no_acd_subs: Map<string, { [key: string]: number }> = new Map();
            let no_non_acd_subs: Map<string, { [key: string]: string }> =
              new Map();
            let no_non_acd_comments: Map<string, { [key: string]: string }> =
              new Map();
            let acd_grades: Map<string, { [key: string]: string }> = new Map();
            std_working_days = mark.cal_days_count;
            std_present_days = mark.std_present_count;
            // std_absent_days = mark.std_absent_count;

            mark.acd_test_marks_details.forEach((test) => {
              if (!test.is_non_academic && test.marks_details.length > 0) {
                subj_max_marks = [
                  ...subj_max_marks,
                  test.marks_details[0].subj_total_max_marks,
                ];
              }

              if (test.is_non_academic === false) {
                test.marks_details.forEach((sub_marks) => {
                  if (subjects.has(sub_marks.subj_desc)) {
                    subjects.set(sub_marks.subj_desc, {
                      ...subjects.get(sub_marks.subj_desc),
                      [test.acd_test_name]: sub_marks.is_std_present
                        ? sub_marks.std_marks_scored
                        : "A",
                    });
                  } else {
                    subjects.set(sub_marks.subj_desc, {
                      [test.acd_test_name]: sub_marks.is_std_present
                        ? sub_marks.std_marks_scored
                        : "A",
                    });
                  }

                  if (acd_grades.has(sub_marks.subj_desc)) {
                    acd_grades.set(sub_marks.subj_desc, {
                      ...acd_grades.get(sub_marks.subj_desc),
                      [test.acd_test_name]: sub_marks.scored_grade,
                    });
                  } else {
                    acd_grades.set(sub_marks.subj_desc, {
                      [test.acd_test_name]: sub_marks.scored_grade,
                    });
                  }
                });
                tests = [...tests, test.acd_test_name];
                total_std_marks = [
                  ...total_std_marks,
                  test.test_std_total_marks_scored,
                ];
                total_std_percentage = [
                  ...total_std_percentage,
                  test.test_std_total_percentage,
                ];
                std_overall_grade = [...std_overall_grade, test.overall_grade];
                std_rank = [...std_rank, test.entry_level_rank];
                std_remarks = [...std_remarks, test.test_remarks];
                acd_subj_grades = [...acd_subj_grades, test.acd_test_name];
              } else if (test.is_non_academic === true) {
                test.marks_details.forEach((sub_marks) => {
                  if (no_non_acd_subs.has(sub_marks.subj_desc)) {
                    no_non_acd_subs.set(sub_marks.subj_desc, {
                      ...no_non_acd_subs.get(sub_marks.subj_desc),
                      [test.acd_test_name]: sub_marks.scored_grade,
                    });
                  } else {
                    no_non_acd_subs.set(sub_marks.subj_desc, {
                      [test.acd_test_name]: sub_marks.scored_grade,
                    });
                  }

                  if (no_non_acd_comments.has(sub_marks.subj_desc)) {
                    no_non_acd_comments.set(sub_marks.subj_desc, {
                      ...no_non_acd_comments.get(sub_marks.subj_desc),
                      [test.acd_test_name]: sub_marks.teacher_comments,
                    });
                  } else {
                    no_non_acd_comments.set(sub_marks.subj_desc, {
                      [test.acd_test_name]: sub_marks.teacher_comments,
                    });
                  }
                });
                no_non_acd_tests = [...no_non_acd_tests, test.acd_test_name];
                no_non_acd_remarks = [
                  ...no_non_acd_remarks,
                  test.acd_test_name,
                ];
              }
            });

            return {
              std_name: mark.student_details,
              std_id: mark.student_id,
              tests: tests,
              std_overall_grade: std_overall_grade,
              total_std_marks: total_std_marks,
              total_std_percentage: total_std_percentage,
              std_rank: std_rank,
              std_remarks: std_remarks,
              std_working_days: std_working_days,
              std_present_days: std_present_days,
              // std_absent_days: std_absent_days,
              sub_marks: subjects,
              no_acd_tests: no_acd_tests,
              no_sub_marks: no_acd_subs,
              no_non_acd_tests: no_non_acd_tests,
              no_non_acd_remarks: no_non_acd_remarks,
              acd_subj_grades: acd_subj_grades,
              no_non_acd_subs: no_non_acd_subs,
              no_non_acd_comments: no_non_acd_comments,
              acd_grades: acd_grades,
              subj_max_marks: subj_max_marks,
            };
          }) as unknown as stdMarksType[])
        : [];

      setStdMarks(items);
    }
  }, [data]);

  const {
    attendanceStats,
    heightWeight,
    gradeForEachSub,
    remarksPerTest,
    overAllPercentage,
    overAllGrade,
    overAllRank,
    classTeacherSign,
    parentSign,
    signatureType,
    otherDetails,
    signatureFromInst,
    Conduct,
    attendanceDetails,
  } = useReportCardConfig();

  const { LogoOrSign: PrincipalSign } = useInstLogoDetails({
    filetype: FileUploadParams.PRINCIPAL_SIGNATURE,
  });

  return (
    <>
      {stdMarks.length
        ? stdMarks.map((std, index) => {
            const tests = stdMarks[index]?.tests;
            const subj_max_marks = stdMarks[index]?.subj_max_marks;
            const std_overall_grade = stdMarks[index]?.std_overall_grade;
            const total_std_marks = stdMarks[index]?.total_std_marks;
            const total_std_percentage = stdMarks[index]?.total_std_percentage;
            const std_rank = stdMarks[index]?.std_rank;
            const std_remarks = stdMarks[index]?.std_remarks;
            const std_working_days = stdMarks[index]?.std_working_days;
            const std_present_days = stdMarks[index]?.std_present_days;
            // const std_absent_days = stdMarks[index]?.std_absent_days;
            const sub_marks = stdMarks[index].sub_marks;
            const nonAcdSubjects = stdMarks[index].no_non_acd_tests;
            const nonAcademicMarks = stdMarks[index].no_non_acd_subs;
            const nonAcademicComments = stdMarks[index].no_non_acd_comments;
            const Academicgrades = stdMarks[index].acd_grades;
            console.log(std_remarks, "std_present_days");

            return (
              <>
                {type === MarksDetailsTableFor.ACADEMIC ? (
                  <div className="bgs-pn-pr__acd-details">
                    <TableContainer className="bgs-pn-pr__acd-details--table">
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell id="td-center" rowSpan={2}>
                              Subject Name
                            </TableCell>

                            {tests.map((th: string, index: number) => {
                              return (
                                <TableCell
                                  key={index}
                                  id="td-center"
                                  colSpan={
                                    gradeForEachSub?.config_boolean_value
                                      ? 2
                                      : 1
                                  }>
                                  {th}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                          <TableRow>
                            {tests.map((_, index) => (
                              <React.Fragment key={index}>
                                <TableCell align="center">Marks</TableCell>
                                {gradeForEachSub?.config_boolean_value && (
                                  <TableCell align="center"> Grade</TableCell>
                                )}
                              </React.Fragment>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>Maximum Marks</TableCell>
                            {subj_max_marks &&
                              subj_max_marks.map((test_name, index) => (
                                <React.Fragment key={index}>
                                  <TableCell id="td-center">
                                    {test_name}
                                  </TableCell>
                                  {gradeForEachSub?.config_boolean_value && (
                                    <TableCell id="td-center"></TableCell>
                                  )}
                                </React.Fragment>
                              ))}
                          </TableRow>

                          {Array.from(sub_marks).map(
                            ([subject, marks], index) => {
                              const grades = Academicgrades.get(subject);

                              return (
                                <TableRow key={index}>
                                  <TableCell>{subject}</TableCell>
                                  {tests &&
                                    tests.map((test_name, index) => (
                                      <>
                                        <TableCell key={index} id="td-center">
                                          {marks[test_name]}
                                        </TableCell>
                                        {gradeForEachSub?.config_boolean_value && (
                                          <TableCell id="td-center">
                                            {grades
                                              ? grades[test_name] ?? "-"
                                              : "-"}{" "}
                                          </TableCell>
                                        )}
                                      </>
                                    ))}
                                </TableRow>
                              );
                            }
                          )}

                          <TableRow>
                            <TableCell>Total</TableCell>
                            {total_std_marks &&
                              total_std_marks.map((test_name, index) => (
                                <>
                                  <TableCell
                                    colSpan={
                                      gradeForEachSub?.config_boolean_value
                                        ? 2
                                        : 1
                                    }
                                    id="td-center"
                                    key={index}>
                                    {test_name}
                                  </TableCell>
                                </>
                              ))}
                          </TableRow>
                          {overAllPercentage?.config_boolean_value && (
                            <TableRow>
                              <TableCell>
                                {overAllPercentage.config_string_value}
                              </TableCell>
                              {total_std_percentage &&
                                total_std_percentage.map((test_name, index) => (
                                  <>
                                    <TableCell
                                      colSpan={
                                        gradeForEachSub?.config_boolean_value
                                          ? 2
                                          : 1
                                      }
                                      key={index}
                                      id="td-center">
                                      {`${test_name} %`}
                                    </TableCell>
                                  </>
                                ))}
                            </TableRow>
                          )}
                          {overAllGrade?.config_boolean_value && (
                            <TableRow>
                              <TableCell>Grade</TableCell>
                              {std_overall_grade &&
                                std_overall_grade.map((test_name, index) => (
                                  <>
                                    <TableCell
                                      colSpan={
                                        gradeForEachSub?.config_boolean_value
                                          ? 2
                                          : 1
                                      }
                                      key={index}
                                      id="td-center">
                                      {test_name}
                                    </TableCell>
                                  </>
                                ))}
                            </TableRow>
                          )}

                          {overAllRank?.config_boolean_value && (
                            <TableRow>
                              <TableCell>Rank</TableCell>
                              {std_rank &&
                                std_rank.map((test_name, index) => (
                                  <>
                                    <TableCell
                                      colSpan={
                                        gradeForEachSub?.config_boolean_value
                                          ? 2
                                          : 1
                                      }
                                      key={index}
                                      id="td-center">
                                      {test_name}
                                    </TableCell>
                                  </>
                                ))}
                            </TableRow>
                          )}
                          {Conduct?.config_boolean_value && (
                            <TableRow>
                              <TableCell>
                                {Conduct.config_string_value}
                              </TableCell>
                              {std_remarks &&
                                std_remarks.map((test_name, index) => (
                                  <>
                                    <TableCell
                                      colSpan={
                                        gradeForEachSub?.config_boolean_value
                                          ? 2
                                          : 1
                                      }
                                      key={index}
                                      id="td-center"></TableCell>
                                  </>
                                ))}
                            </TableRow>
                          )}
                          {remarksPerTest?.config_boolean_value && (
                            <TableRow>
                              <TableCell>
                                {remarksPerTest.config_string_value}
                              </TableCell>
                              {std_remarks &&
                                std_remarks.map((test_name, index) => (
                                  <>
                                    <TableCell
                                      colSpan={
                                        gradeForEachSub?.config_boolean_value
                                          ? 2
                                          : 1
                                      }
                                      key={index}
                                      id="td-center">
                                      {test_name}
                                    </TableCell>
                                  </>
                                ))}
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                ) : type === MarksDetailsTableFor.NON_ACADEMIC ? (
                  <TableContainer className="bgs-pn-pr__non-acd--table">
                    <Table>
                      <TableHead>
                        <TableRow className="bgs-pn-pr__non-acd--row">
                          {nonAcdSubjects.map((th: string, index: number) => {
                            return (
                              <Fragment key={index}>
                                <TableCell id="td-center">{th}</TableCell>
                                <TableCell id="td-center">Grade</TableCell>

                                {/* <TableCell id="td-center">
                                  Grade Remarks
                                </TableCell> */}
                              </Fragment>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Array.from(nonAcademicMarks).map(
                          ([subject, marks], index) => {
                            const comments = nonAcademicComments.get(subject);
                            return (
                              <TableRow key={index}>
                                <TableCell>{subject}</TableCell>
                                {nonAcdSubjects.map((test_name, index) => (
                                  <>
                                    <TableCell
                                      key={`grade-${index}`}
                                      id="td-center">
                                      {marks[test_name] ?? "-"}
                                    </TableCell>
                                    {/* <TableCell
                                      key={`remark-${index}`}
                                      id="td-center">
                                      {comments
                                        ? comments[test_name] ?? "-"
                                        : "-"}{" "}
                                    </TableCell> */}
                                  </>
                                ))}
                              </TableRow>
                            );
                          }
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : type === MarksDetailsTableFor.OTHER_DETAILS ? (
                  <TableContainer className="bgs-pn-pr__non-acd--table">
                    <Table>
                      <TableHead>
                        <TableRow className="bgs-pn-pr__non-acd--row">
                          <TableCell
                            key={index}
                            id="td-center"
                            colSpan={4}
                            style={{ height: "25px" }}>
                            {otherDetails?.config_string_value}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {heightWeight?.config_boolean_value && (
                          <TableRow>
                            <TableCell>Height</TableCell>
                            <TableCell id="td-center">-</TableCell>
                            <TableCell>Weight</TableCell>
                            <TableCell id="td-center">-</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : type === MarksDetailsTableFor.ATTENDANCE ? (
                  <TableContainer className="bgs-pn-pr__non-acd--table">
                    <Table>
                      <TableHead>
                        <TableRow className="bgs-pn-pr__non-acd--row">
                          <TableCell
                            key={index}
                            id="td-center"
                            colSpan={4}
                            style={{ height: "25px" }}>
                            {attendanceDetails?.config_string_value}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {attendanceStats?.config_boolean_value && (
                          <>
                            <TableRow>
                              <TableCell rowSpan={2}>
                                Total Working Days
                              </TableCell>
                              <TableCell id="td-center" className="td-sl-no">
                                {attendanceStats.config_string_value ===
                                "Auto" ? (
                                  <>{std_working_days}</>
                                ) : (
                                  <></>
                                )}
                              </TableCell>
                              <TableCell>No. of Days Present</TableCell>
                              <TableCell id="td-center" className="td-sl-no">
                                {attendanceStats.config_string_value ===
                                "Auto" ? (
                                  <>{std_present_days}</>
                                ) : (
                                  <></>
                                )}
                              </TableCell>
                            </TableRow>
                            {/* <TableRow>
                               <TableCell>No. of Absent Days</TableCell>
                               <TableCell id="td-center">
                                 {std_absent_days}
                               </TableCell>
                             </TableRow> */}
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : type === MarksDetailsTableFor.SIGNATURES ? (
                  <>
                    {signatureType?.config_string_value !== "O" ? (
                      <TableContainer className="bgs-pn-pr__non-acd--table">
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell key={index} id="td-center">
                                Signature
                              </TableCell>
                              {tests.map((th: string, index: number) => {
                                return (
                                  <>
                                    <TableCell
                                      key={index}
                                      id="td-center"
                                      colSpan={2}>
                                      {th}
                                    </TableCell>
                                  </>
                                );
                              })}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {classTeacherSign?.config_boolean_value && (
                              <TableRow className="bgs-pn-pr__signatures">
                                <TableCell  className="bgs-pn-pr__signatures--sign">
                                  {classTeacherSign.config_string_value}
                                </TableCell>
                                {tests.map((res) => {
                                  return (
                                    <TableCell></TableCell>
                                  );
                                })}
                              </TableRow>
                            )}
                            {signatureFromInst?.config_boolean_value && (
                              <TableRow className="bgs-pn-pr__signatures">
                                <TableCell  className="bgs-pn-pr__signatures--sign">
                                  {signatureFromInst.config_string_value}
                                </TableCell>
                                {tests.map((res) => {
                                  return (
                                    <TableCell></TableCell>
                                  );
                                })}
                              </TableRow>
                            )}

                            {parentSign?.config_boolean_value && (
                              <TableRow className="bgs-pn-pr__signatures">
                                <TableCell  className="bgs-pn-pr__signatures--sign">
                                  {parentSign?.config_string_value}
                                </TableCell>
                                {tests.map((res) => {
                                  return (
                                    <TableCell></TableCell>
                                  );
                                })}
                              </TableRow>
                            )}

                            <TableRow className="bgs-pn-pr__signatures">
                              <TableCell className="bgs-pn-pr__signatures--sign">Principal</TableCell>
                              {tests.map((res) => {
                                return (
                                  <TableCell >
                                    {PrincipalSign.defaultLogo ? (
                                      <img
                                        src={PrincipalSign.defaultLogo}
                                        alt="/"
                                        className="bgs-pn-pr__signatures--principal"
                                      />
                                    ) : null}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <TableContainer className="bgs-pn-pr__non-acd--table">
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell key={index} id="td-center" colSpan={2}>
                                Signature
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {classTeacherSign?.config_boolean_value && (
                              <TableRow className="bgs-pn-pr__signatures">
                                <TableCell className="bgs-pn-pr__signatures--sign">
                                  {classTeacherSign.config_string_value}
                                </TableCell>

                                <TableCell></TableCell>
                              </TableRow>
                            )}
                            {signatureFromInst?.config_boolean_value && (
                              <TableRow className="bgs-pn-pr__signatures">
                                <TableCell className="bgs-pn-pr__signatures--sign">
                                  {signatureFromInst.config_string_value}
                                </TableCell>

                                <TableCell></TableCell>
                              </TableRow>
                            )}

                            {parentSign?.config_boolean_value && (
                              <TableRow className="bgs-pn-pr__signatures">
                                <TableCell className="bgs-pn-pr__signatures--sign">
                                  {parentSign?.config_string_value}
                                </TableCell>

                                <TableCell></TableCell>
                              </TableRow>
                            )}

                            <TableRow className="bgs-pn-pr__signatures">
                              <TableCell className="bgs-pn-pr__signatures--sign">Principal</TableCell>

                              <TableCell colSpan={2}>
                                {PrincipalSign.defaultLogo ? (
                                  <img
                                    src={PrincipalSign.defaultLogo}
                                    alt="/"
                                    className="bgs-pn-pr__signatures--principal"
                                  />
                                ) : null}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </>
                ) : null}
              </>
            );
          })
        : null}
    </>
  );
};

export default MarksDetails;
