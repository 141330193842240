import React from "react";
import { useState } from "react";
// eslint-disable-next-line
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  Operation,
  PageFor,
  PageLabel,
  StudentDetailedReportType,
  StudentListFor,
  StudentReportType,
  StudentsWhoLeftInstitutionTypes,
} from "../../utils/Enum.types";
import CompleteStudentReport from "../../Modules/Master/Student/CompleteReports/Reports";
import StudentList from "../../Modules/Master/Student/List";
import StudentReservedList from "../../Modules/Master/Student/StudentReservation/StudentReservedList";
import StudentReserved from "../../Modules/Master/Student/StudentReservation/Index";
import DeleteMasterStudent from "../../Modules/Master/Student/DeleteStudent";
import AllocateSections from "../../Modules/Master/Student/Sections/Index";

import AllotRegNo from "../../Modules/Master/Student/AllotRegNo";
import StudentStatus from "../../Modules/Master/Student/Status/Index";
import EditAdmissionNumber from "../../Modules/Master/Student/EditAdmNumber/Index";
import StudentDocuments from "../../Modules/Master/Student/Documents/Index";
import StudentServices from "../../Modules/Master/Student/Services/Index";
import StudentBasicDataImport from "../../Modules/Master/Student/ImportData/StudentBasicDataImport";
import StudentEnableLogin from "../../Modules/Master/Student/EnableLogin/Index";
import DetainedStudent from "../../Modules/Master/Student/DetainedStudent/Index";
import DeleteStudent from "../../Modules/Accounts/StudentDetails/DeleteStudent/DeleteStudent";
import StudentResetPassword from "../../Modules/Master/Student/ResetPassword";
import BasicData from "../../Modules/Master/Student/StudentPreview/BasicData";
import StudentPersonalData from "../../Modules/Master/Student/StudentPreview/StudentPersonalData";
import PreviousInstDetails from "../../Modules/Master/Student/StudentPreview/PreviousInstDetails";
import StudentParentAssoc from "../../Modules/Master/Student/StudentPreview/StudentParentAssoc";
import StudentImport from "../../Modules/Master/Student/ImportData/StudentImport";
// student creation pages
import CreateStudentBaicDetails from "../../Modules/Master/Student/Registration/BasicDetails";
import CreateStudentPersonalData from "../../Modules/Master/Student/Registration/PersonalData";
import CreateStudentPreviousInstData from "../../Modules/Master/Student/Registration/PreviousInstReg";
import CreateStudentParentRelationship from "../../Modules/Master/Student/Registration/StudentParentAssociation";
import AttachStudentDemand from "../../Modules/Master/Student/Registration/FeeDemandDisplay";
import AluminiStudentList from "../../Modules/Master/Student/AluminiStudentList";
import StudentDetailedReport from "../../Modules/Master/Student/Reports/Index";
import StudentSummary from "../../Modules/Master/Dashboard/StudentSummary";
import RollNo from "../../Modules/Master/Student/RollNo";
import InterChangeCategory from "../../Modules/Master/Student/Category/Index";
import StudentsDeviceList from "../../Modules/Master/Student/DeviceLists";
import BulkProfilePicUpload from "../../Modules/Master/Student/BulkProfileUpload/BulkProfilePicUpload";
import DocsUpload from "../../Modules/Master/Student/Registration/DocsUpload";
import DocsPreview from "../../Modules/Master/Student/StudentPreview/DocsPreview";
import NxtBasicDetails from "../../Modules/Master/Student/StudentReservation/NxtBasicDetails";
import NxtPersonalDetails from "../../Modules/Master/Student/StudentReservation/NxtPersonalDetails";
import NxtPrevInstData from "../../Modules/Master/Student/StudentReservation/NxtPrevInstData";
import NxtDocsUpload from "../../Modules/Master/Student/StudentReservation/NxtDocsUpload";
import BasicDataPreview from "../../Modules/Master/Student/StudentReservation/BasicDataPreview";
import PersonalDataPreview from "../../Modules/Master/Student/StudentReservation/PersonalDataPreview";
import NxtDocsPreview from "../../Modules/Master/Student/StudentReservation/NxtDocsPreview";
import NxtPreviousInstDetails from "../../Modules/Master/Student/StudentReservation/NxtPrevInstDetails";
import Allocate from "../../Modules/Master/Student/Sections/Allocate";
import InterChangeSections from "../../Modules/Master/Student/Sections/Interchange";
import SiblingAssociationList from "../../Modules/Master/Student/SiblingAssociation/List";
import SiblingAssociation from "../../Modules/Master/Student/SiblingAssociation/Association";
import BulkProfileTree from "../../Modules/Master/Student/BulkProfileUpload/BulkProfTree";
const StudentsRoutes = () => {
  const [modalFlag, setModalFlag] = useState(false);
  return (
    <Routes>
      <Route
        path="/"
        element={
          <StudentList
            pageType={PageFor.GENERAL}
            studentListFor={StudentListFor.GENERAL}
            queryType={StudentReportType.GENERAL}
            setStudentModal={setModalFlag}
            studentModal={modalFlag}
            label={PageLabel.TRUE}
          />
        }
      />
      <Route
        path="/studentcount/deviceList"
        element={
          <StudentsDeviceList
            studentListFor={StudentListFor.GENERAL}
            queryType={StudentReportType.GENERAL}
            setStudentModal={setModalFlag}
            studentModal={modalFlag}
            label={PageLabel.TRUE}
          />
        }
      />
      <Route
        path="/studentcount/tableview"
        element={
          <StudentSummary
            pageType={PageFor.GENERAL}
            setModalFlag={setModalFlag}
          />
        }
      />
      <Route path="/studentImport" element={<StudentImport />} />
      <Route
        path="/reservations"
        element={
          <StudentReservedList
            pageType={PageFor.GENERAL}
            setReservationModal={setModalFlag}
          />
        }
      />
      <Route path="/delete" element={<DeleteMasterStudent />} />
      <Route
        path="/reservestudent"
        element={
          <StudentReserved
            operation={Operation.CREATE}
            setModalFlag={setModalFlag}
          />
        }
      />
      <Route
        path="/1/:studentId/nxtBasicDetails"
        element={<NxtBasicDetails operation={Operation.CREATE} step={1} />}
      />
      <Route
        path="/2/:studentId/nxtPersonalDetails"
        element={<NxtPersonalDetails operation={Operation.CREATE} step={2} />}
      />
      <Route
        path="/3/:studentId/nxtPrevInstDetails"
        element={<NxtPrevInstData type={Operation.CREATE} step={3} />}
      />
      <Route
        path="/4/:studentId/nxtDefaultDocs"
        element={<NxtDocsUpload type={Operation.CREATE} step={4} />}
      />

      <Route path="/allocatesection" element={<AllocateSections />} />
      <Route path="/:allotedID/notAllocated" element={<Allocate />} />
      <Route path="/:allotedID/interchange" element={<InterChangeSections />} />

      <Route path="/interchange" element={<InterChangeSections />} />

      <Route path="/interchangeCategory" element={<InterChangeCategory />} />

      <Route path="/completeReport" element={<CompleteStudentReport />} />

      <Route path="/allotregno" element={<AllotRegNo />} />
      <Route path="/allotrollno" element={<RollNo />} />

      {/* we need to send non eligible flag in order to make them eligible please dont change anything */}
      <Route path="/makeeligible" element={<StudentStatus />} />

      <Route path="/editadmissionnumber" element={<EditAdmissionNumber />} />
      <Route path="/documents" element={<StudentDocuments />} />
      <Route path="/bulkProfileTree" element={<BulkProfileTree />} />
      <Route
        path="/:entryId/bulk_profile_pic"
        element={<BulkProfilePicUpload />}
      />

      <Route path="/services" element={<StudentServices />} />
      <Route
        path="/siblingList"
        element={
          <SiblingAssociationList
            pageType={PageFor.GENERAL}
            studentListFor={StudentListFor.GENERAL}
            queryType={StudentReportType.GENERAL}
            setStudentModal={setModalFlag}
            studentModal={modalFlag}
            label={PageLabel.TRUE}
          />
        }
      />
      <Route path="/:studentId/association" element={<SiblingAssociation />} />
      <Route path="/importdata" element={<StudentBasicDataImport />} />

      <Route
        path="/enableLogin"
        element={<StudentEnableLogin queryType={StudentReportType.GENERAL} />}
      />
      <Route path="/detainedlist" element={<DetainedStudent />} />

      <Route
        path="/deletestudent"
        element={
          <DeleteStudent
            pageType={StudentsWhoLeftInstitutionTypes.DELETE_STUDENT}
          />
        }
      />
      <Route
        path="/reports/student/detailed"
        element={
          <StudentDetailedReport
            type={StudentDetailedReportType.STUDENT_LIST}
          />
        }
      />
      <Route
        path="/reports/student/biodata"
        element={
          <StudentDetailedReport
            type={StudentDetailedReportType.STUDENT_BIO_DATA}
          />
        }
      />
      <Route
        path="/reports/student/mailinglabel"
        element={
          <StudentDetailedReport
            type={StudentDetailedReportType.MAILING_LABEL}
          />
        }
      />
      <Route
        path="/reports/student/religionwise"
        element={
          <StudentDetailedReport
            type={StudentDetailedReportType.RELIGION_WISE}
          />
        }
      />
      <Route
        path="/reports/student/castewise"
        element={
          <StudentDetailedReport type={StudentDetailedReportType.CASTE_WISE} />
        }
      />
      <Route
        path="/reports/student/agewise"
        element={
          <StudentDetailedReport type={StudentDetailedReportType.AGE_WISE} />
        }
      />
      <Route
        path="/reports/student/yearWise"
        element={
          <StudentDetailedReport
            type={StudentDetailedReportType.YEAR_OF_JOINING}
          />
        }
      />
      <Route path="/resetpassword" element={<StudentResetPassword />} />

      <Route
        path="/:studentId/basicdata"
        element={<BasicData pageType={PageFor.GENERAL} />}
      />
      <Route
        path="/:studentId/NxtBasicdata"
        element={<BasicDataPreview pageType={PageFor.GENERAL} />}
      />
      <Route
        path="/:studentId/personaldata"
        element={<StudentPersonalData pageType={PageFor.GENERAL} />}
      />
      <Route
        path="/:studentId/NxtPersonaldata"
        element={<PersonalDataPreview pageType={PageFor.GENERAL} />}
      />
      <Route
        path="/:studentId/previousschooldata"
        element={<PreviousInstDetails pageType={PageFor.GENERAL} />}
      />
      <Route
        path="/:studentId/NxtPreviousschooldata"
        element={<NxtPreviousInstDetails pageType={PageFor.GENERAL} />}
      />
      <Route
        path="/:studentId/stuparentassoc"
        element={<StudentParentAssoc pageType={PageFor.GENERAL} />}
      />
      <Route
        path="/:studentId/docs_upload_preview"
        element={
          <DocsPreview pageType={PageFor.GENERAL} setModal={setModalFlag} />
        }
      />
      <Route
        path="/:studentId/nxtDocsPreview"
        element={
          <NxtDocsPreview pageType={PageFor.GENERAL} setModal={setModalFlag} />
        }
      />
      <Route
        path="/1/:studentId/basicDetails"
        element={
          <CreateStudentBaicDetails operation={Operation.CREATE} step={1} />
        }
      />
      <Route
        path="/2/:studentId/personalDetails"
        element={
          <CreateStudentPersonalData operation={Operation.CREATE} step={2} />
        }
      />
      <Route
        path="/3/:studentId/prevInstDetails"
        element={
          <CreateStudentPreviousInstData type={Operation.CREATE} step={3} />
        }
      />
      <Route
        path="/4/:studentId/studentParentAssociation"
        element={
          <CreateStudentParentRelationship type={Operation.CREATE} step={4} />
        }
      />
      <Route
        path="/5/:studentId/defaultDocs"
        element={<DocsUpload type={Operation.CREATE} step={5} />}
      />
      <Route
        path="/6/:studentId/studentFeeDemand"
        element={<AttachStudentDemand type={Operation.CREATE} step={6} />}
      />
      <Route
        path="/:studentId/personalDetailsUpdate"
        element={
          <CreateStudentPersonalData operation={Operation.UPDATE} step={2} />
        }
      />
      <Route
        path="/:studentId/NxtPersonalDetailsUpdate"
        element={<NxtPersonalDetails operation={Operation.UPDATE} step={2} />}
      />

      <Route
        path="/:studentId/prevInstDetailsUpdate"
        element={
          <CreateStudentPreviousInstData type={Operation.UPDATE} step={3} />
        }
      />
      <Route
        path="/:studentId/NxtPrevInstDetailsUpdate"
        element={<NxtPrevInstData type={Operation.UPDATE} step={3} />}
      />

      <Route
        path="/:studentId/studentParentAssociationUpdate"
        element={
          <CreateStudentParentRelationship type={Operation.UPDATE} step={4} />
        }
      />
      <Route
        path="/5/:studentId/defaultDocsUpdate"
        element={<DocsUpload type={Operation.UPDATE} step={5} />}
      />
      <Route
        path="/5/:studentId/nxtDefaultDocsUpdate"
        element={<DocsUpload type={Operation.UPDATE} step={5} />}
      />
      <Route
        path="/alumni"
        element={
          <AluminiStudentList
            type={PageFor.GENERAL}
            setStudentModal={setModalFlag}
          />
        }
      />
    </Routes>
  );
};

export default StudentsRoutes;
