import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-modal";
import { TextField } from "@mui/material";
import HRImport from "../../Imports/EmployeesImport";
import Close from "../../../../images/Close.svg";
import { Button } from "../../../../stories/Button/Button";
import Input from "../../../../stories/Input/Input";
import { Title } from "../../../../stories/Title/Title";
import Home from "../../Home/Index";
import { TableHeaderProps } from "../../../../utils/types";
import { HRTitleProps } from "../../../../Types/Titles";
import { optionsType, responseType } from "../../../../utils/Form.types";
import { PageFor } from "../../../../utils/Enum.types";
import { Keys } from "../../../../utils/Enum.keys";
import {
  EMPTY_RESPONSETYPE_OBJECT,
  EMPTY_STRING,
  empType,
  FETCH_MORE_DATA,
  NOTALLOCATED,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";
import { GridColDef, GridAlignment } from "@mui/x-data-grid-pro";
import {
  getModifiedScrollHeight,
  isOptionEqualToValue,
  toStandardDate,
} from "../../../../utils/UtilFunctions";

import useEmpMasterData from "../../hooks/useEmpMasterData";
import useEmployee, {
  PayRollEmpEdges,
  empQueryTypes,
} from "../../hooks/useEmployee";

import usePayRollMastersConfig from "../../hooks/usePayRollMastersConfig";
import { HYPHEN } from "../../constants";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import { payloadTypes } from "../../../../context/reducer";
import { AppContext } from "../../../../context/context";

import { PayRollMasters } from "../../enums/Enum.types";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";
import useHRtable from "../../json/useHRtable";
import {
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../../styles/DataGridTableStyles";

const { HRTitles } = require("../../json/title.json");

export const enum ComponentForEmployee {
  GENERAL = "GENERAL",
  ORGANIZATIONAL = "ORGANIZATIONAL",
  EDIT = "EDIT",
  AUTOCOMPLETE = "AUTOCOMPLETE",
}

interface Props {
  pageType: PageFor;
  setModalFlag?: React.Dispatch<React.SetStateAction<boolean>>;
  queryType?: empQueryTypes;
  type?: ComponentForEmployee;
}

const Index = ({ pageType, setModalFlag, queryType, type }: Props) => {
  const { InstId } = useParams();
  const navigate = useNavigate();
  const { dispatch } = useContext(AppContext);
  const {
    USE_HR_CATEGORY,
    USE_HR_DEPARTMENT,
    USE_HR_DESIGNATION,
    USE_HR_GRADE,
    USE_HR_JOBTYPE,
  } = usePayRollMastersConfig();
  const [searchData, setSearchData] = useState("");
  const { HR_Table } = useHRtable();

  const [importModal, setImportModal] = useState(false);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [employees, setEmployees] = useState<PayRollEmpEdges[]>([]);
  const [departmentId, setDepartmentId] = useState<responseType | null>(null);
  const [designationId, setDesignationId] = useState<responseType | null>(null);
  const [categoryId, setCategoryId] = useState<responseType | null>(null);
  const [gradeId, setGradeId] = useState<responseType | null>(null);
  const [jobTypeId, setJobTypeId] = useState<responseType | null>(null);
  const [empTypeSelected, setempTypeSelected] = useState<optionsType | null>(
    null
  );
  const {
    empDetails: { data, loading, fetchMore },
  } = useEmployee(
    ROWS_PER_PAGE,
    pageType === PageFor.MODAL
      ? queryType!
      : empTypeSelected
      ? empQueryTypes.EMPS_BY_STATUS
      : empQueryTypes.GENERAL,
    searchData,
    0,
    departmentId ? departmentId.value : 0,
    designationId ? designationId.value : 0,
    categoryId ? categoryId.value : 0,
    gradeId ? gradeId.value : 0,
    jobTypeId ? jobTypeId.value : 0,
    [],
    EMPTY_STRING,
    0,
    false,
    empTypeSelected ? empTypeSelected.value : EMPTY_STRING
  );

  const {
    departmentDropDown,
    designationDropDown,
    jobTypeDropDown,
    gradeDropDown,
    categoryDropDown,
  } = useEmpMasterData();

  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;
      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: FETCH_MORE_DATA,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetPayRollEmp?.edges;
                const pageInfo = fetchMoreResult.GetPayRollEmp.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck = prevResult.GetPayRollEmp.edges.filter(
                  ({ node: { id } }) =>
                    newEdges.findIndex(
                      ({ node: { id: newId } }) => newId === id
                    ) !== -1
                );

                if (duplicateCheck?.length > 0) return prevResult;

                return {
                  GetPayRollEmp: {
                    edges: [...employees, ...newEdges],
                    pageInfo,
                    totalCount: data ? data.GetPayRollEmp.totalCount! : 0,
                  },
                };
              },
            });
          }
        }
      };
      if (scrollTable && employees && employees.length > 0)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    [employees]
  );

  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetPayRollEmp.edges.map((edge) => ({
        ...edge,
        node: {
          ...edge.node,
          isChecked: true, // set default value of isChecked to true
        },
      }));

      if (endCursor) {
        // If we have already fetched some data, we need to check if there
        // are any duplicates in the new data, and update their isChecked
        // property based on the existing data.
        // const filteredStudents = students.filter(
        //   (student) => !student.node.isChecked
        // );

        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = employees.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
                // isChecked: filteredStudent.node.isChecked,
              },
            };
          }
          return newStudent;
        });
        setEmployees(updatedNewData);
      } else {
        setEmployees(newData);
      }
      setHasNextPage(data.GetPayRollEmp.pageInfo.hasNextPage);
      setEndCursor(data.GetPayRollEmp.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);

  const dynamicHeaders: TableHeaderProps[] =
    HR_Table.EmployeeList.Table_Headers.map((header) => ({
      headerName: header.headerName,
      cellClassName: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
      hideable: header.hideable,
    }));
  const columns: GridColDef[] = [...dynamicHeaders];

  const tableData = useMemo(() => {
    const tableData = employees.map((edge, index) => ({
      id: index + 1,
      emp_id: edge.node.emp_id,
      name:
        edge.node.emp_first_name +
        " " +
        edge.node.emp_middle_name +
        " " +
        edge.node.emp_last_name,
      dept:
        edge.node.pr_dept_details.dept_desc === NOTALLOCATED
          ? HYPHEN
          : edge.node.pr_dept_details.dept_desc,
      job_type:
        edge.node.pr_job_type_details.job_type_desc === NOTALLOCATED
          ? HYPHEN
          : edge.node.pr_job_type_details.job_type_desc,
      experience: edge.node.emp_experience,
      designation:
        edge.node.pr_designation_details.designation_desc === NOTALLOCATED
          ? HYPHEN
          : edge.node.pr_designation_details.designation_desc,
      category:
        edge.node.pr_category_details.category_desc === NOTALLOCATED
          ? HYPHEN
          : edge.node.pr_category_details.category_desc,
      date_of_joining: toStandardDate(edge.node.emp_doj),
      status: edge.node.emp_status,
      nodeId: edge.node.id,
    }));
    return tableData;
  }, [employees]);

  return (
    <>
      {pageType === PageFor.GENERAL &&
        type === ComponentForEmployee.GENERAL && (
          <Home DashBoardRequired={false} />
        )}
      {type === ComponentForEmployee.ORGANIZATIONAL ? null : (
        <Title>
          {HRTitles.Employee_List.Titles.map(
            (title: HRTitleProps, index: React.Key) => {
              return <React.Fragment key={index}>{title.List}</React.Fragment>;
            }
          )}
        </Title>
      )}

      <div
        className={
          pageType === PageFor.MODAL ? "employee-list__modal" : "employee-list"
        }>
        <div className="row g-0 employee-list__details">
          <div className="col-1">
            <Input
              id="search"
              placeholder="Search..."
              type="text"
              onChange={(e) => setSearchData(e.target.value)}
            />
          </div>

          {USE_HR_CATEGORY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={categoryDropDown!}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setCategoryId(newValue as responseType);
                    setHasNextPage(true);
                  } else setCategoryId(EMPTY_RESPONSETYPE_OBJECT);
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE)
                    setCategoryId(EMPTY_RESPONSETYPE_OBJECT);
                }}
                openOnFocus
                freeSolo
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Category"
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    fullWidth
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}

          {USE_HR_DEPARTMENT ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={departmentDropDown!}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, departmentId)
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    setDepartmentId(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setDepartmentId(null);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setDepartmentId(null);
                  }
                }}
                openOnFocus
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Departments"
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    fullWidth
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_HR_DESIGNATION ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={designationDropDown!}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, designationId)
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    setDesignationId(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setDesignationId(null);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setDesignationId(null);
                  }
                }}
                openOnFocus
                freeSolo
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Designation"
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    fullWidth
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_HR_GRADE ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={gradeDropDown!}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, gradeId)
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    setGradeId(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setGradeId(null);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setGradeId(null);
                  }
                }}
                openOnFocus
                freeSolo
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Grade"
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    fullWidth
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_HR_JOBTYPE ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={jobTypeDropDown!}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, jobTypeId)
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    setJobTypeId(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setJobTypeId(null);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setJobTypeId(null);
                  }
                }}
                openOnFocus
                freeSolo
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Job Type"
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    fullWidth
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={empType}
              value={empTypeSelected}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option as optionsType, jobTypeId)
              }
              onChange={(e, newValue) => {
                if (newValue) {
                  setempTypeSelected(newValue as optionsType);
                  setHasNextPage(true);
                } else {
                  setempTypeSelected(null);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === Keys.BACKSPACE) {
                  setempTypeSelected(null);
                }
              }}
              openOnFocus
              freeSolo
              forcePopupIcon
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Status"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  fullWidth
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
          <div className="col"></div>
          {pageType === PageFor.GENERAL &&
            type === ComponentForEmployee.GENERAL && (
              <div className="col-1">
                <Button
                  className="employee-list__details--addnew"
                  mode="addnew"
                  autoFocus
                  onClick={() =>
                    navigate(`/${InstId}/hr/employee/1/0/registration`)
                  }
                />
              </div>
            )}
        </div>
        <div className="employee-list__tableblock">
          <StyledDatagrid
            columns={columns}
            rows={tableData}
            disableRowSelectionOnClick
            disableChildrenSorting
            rowHeight={TABLE_ROW_HEIGHT}
            hideFooter
            onCellClick={(params) => {
              if (params.field === "name") {
                if (pageType === PageFor.GENERAL) {
                  navigate(
                    `/${InstId}/hr/employee/${params.row.nodeId}/preview/basicdetails`
                  );
                } else if (type === ComponentForEmployee.EDIT) {
                  dispatch({
                    type: payloadTypes.SET_EMPLOYEE_ID,
                    payload: { employeeId: params.row.nodeId },
                  });
                }
                if (type === ComponentForEmployee.AUTOCOMPLETE) {
                  dispatch({
                    type: payloadTypes.SET_SEARCH_EMPLOYEE_ID,
                    payload: { searchEmployeeId: params.row.nodeId },
                  });
                }
                setModalFlag?.(false);
              }
            }}
          />
        </div>
        {pageType === PageFor.GENERAL ? (
          <div className="row g-0">
            <div className="col">
              <Button
                type="button"
                mode="excel"
                onClick={() => setImportModal(!importModal)}>
                Import Employees
              </Button>
              <Button type="button" mode="export" />

              <Button mode="back" onClick={() => navigate(-1)} />
            </div>
            <div className="col student-total-count flex-end">
              Total Receipts : <b>{data ? data.GetPayRollEmp.totalCount : 0}</b>
            </div>
          </div>
        ) : (
          <Button mode="cancel" onClick={() => setModalFlag?.(false)} />
        )}
      </div>

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={importModal}
        style={StudentModalStyles}
        ariaHideApp={false}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <HRImport MasterType={PayRollMasters.CATEGORY} />
          </div>
          <div className="modal-flex__image">
            <img
              onClick={() => setImportModal(!importModal)}
              src={Close}
              alt="/"
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Index;
