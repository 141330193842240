import { gql } from "@apollo/client";
export const GetAcctDemandMainStudentDemand = gql`
  query GetAcctDemandMainStudentDemand(
    $token: String!
    $inst_id: ID!
    $branch_id: ID!
    $class_id: ID!
    $fin_yr_id: ID!
  ) {
    GetAcctDemandMainStudentDemand(
      token: $token
      inst_id: $inst_id
      branch_id: $branch_id
      class_id: $class_id
      fin_yr_id: $fin_yr_id
    ) {
      first_name
      middle_name
      last_name
      std_adm_no
      std_fresher
      cat_desc
      class_desc
      total_demand_new_std
      total_demand_exs_std
      total_demand_all_std
      acct_demand_main_id
    }
  }
`;
export const GetAcctDemandMainPendingStudentDemand = gql`
  query GetAcctDemandMainPendingStudentDemand(
    $token: String!
    $inst_id: ID!
    $branch_id: ID!
    $class_id: ID!
    $category_id: ID!
    $fin_yr_id: ID!
  ) {
    GetAcctDemandMainPendingStudentDemand(
      token: $token
      inst_id: $inst_id
      branch_id: $branch_id
      class_id: $class_id
      fin_yr_id: $fin_yr_id
      category_id: $category_id
    ) {
      first_name
      middle_name
      last_name
      std_adm_no
      std_fresher
      cat_desc
      class_desc
      total_demand_new_std
      total_demand_exs_std
      total_demand_all_std
      acct_demand_main_id
    }
  }
`;

export const GetAcctStdDemandSummation = gql`
  query GetAcctStdDemandSummation(
    $token: String!
    $inst_id: ID!
    $fin_yr_id: ID!
    $input: StdDemandQueryData!
  ) {
    GetAcctStdDemandSummation(
      token: $token
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
      input: $input
    ) {
      std_demand_ob
      std_demand_amt
      std_demand_concession
      std_demand_receivable
      std_demand_received
      std_demand_refunds
      std_demand_bal
    }
  }
`;
export const GetStudentPendingDemandCount = gql`
  query GetStudentPendingDemandCount(
    $token: String!
    $fin_yr_id: ID!
    $inst_id: ID!
  ) {
    GetStudentPendingDemandCount(
      token: $token
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
    ) {
      class_details {
        branch_details {
          branch_desc
          id
        }
        class_desc
        id
      }
      count
    }
  }
`;

export const GetStdCompleteFeeSummary = gql`
  query GetStdCompleteFeeSummary(
    $token: String!
    $inst_id: ID!
    $student_id: ID!
  ) {
    GetStdCompleteFeeSummary(
      token: $token
      inst_id: $inst_id
      student_id: $student_id
    ) {
      fin_yr_id
      fin_yr
      std_yearly_fee_details {
        std_demand_receipts {
          id
          v_no
          v_date
          v_transcation_cash_or_bank
          v_std_amt_receipt
          v_type
          v_std_amt_fine
          v_std_amt_deposit
          update_remarks
          v_transcation_type
          v_std_deposit_adjusted
          v_std_demand_receipt
          v_std_deposit
          v_std_amt_total
        }
        std_demand_details {
          id
          fee_demand
          fee_received
          fee_bal
          fee_ob
          fee_receivable
          acct_ldgr_details {
            id
            ldgr_desc
          }
        }
        std_demand_by_fin_yr {
          std_demand_amt
          std_demand_received
          std_demand_bal
          std_demand_ob
          std_demand_receivable
        }
      }
    }
  }
`;
