import React, { useContext } from "react";
import { PrintReceiptProps } from "../Standard/FeeReceiptPrint";
import useStudentDatabyId from "../../../../../customhooks/useStudentDatabyId";
import usePrintConfig from "../Standard/hooks/usePrintConfiguration";
import useInstLabels from "../../../../../customhooks/general/useInstLabels";
import { AppContext } from "../../../../../context/context";
import usePassedOutStudent from "../../../../../customhooks/usePassedOutStudent";
import useEnquiryStudentDetails from "../../../../../customhooks/useEnquiryStudentDetails";
import useStudentReceiptDetails from "../../../../Accounts/hooks/useStudentReceiptDetails";
import { EMPTY_STRING } from "../../../../../utils/constants";
import usePassedOutStudentReceiptDetails from "../../../../Accounts/hooks/usePassedOutStudentReceiptDetails";
import useActiveFinancialYear from "../../../../Accounts/hooks/useActiveFinancialYear";
import { toInputStandardDate } from "../../../../../utils/UtilFunctions";
import dayjs from "dayjs";
import { InstDetailsPageType, InstInfoProps } from "./InstDetails";
import useNonDemandReceiptConfig from "../Standard/hooks/useNonDemandReceiptConfig";

const StudentInfo = ({
  tableData,
  v_date,
  v_no,
  transaction_no,
  studentDetails,
  pageType,
}: InstInfoProps) => {
  const { studentFormData, studentData } = useStudentDatabyId();
  const { state } = useContext(AppContext);
  const { passedOutStudentFormData } = usePassedOutStudent();
  const {
    enquiryStudentData: { EnquiryQueryDetails },
  } = useEnquiryStudentDetails();
  const { studentReceiptDetails } = useStudentReceiptDetails();
  const { passedOutStudentReceiptDetails } =
    usePassedOutStudentReceiptDetails();
  const { ActiveFinancicalYear } = useActiveFinancialYear();

  const {
    USE_ADM_NO,
    USE_CLASS_NAME,
    USE_FATHER_NAME,
    USE_MOTHER_NAME,
    USE_REG_NO,
    USE_SECTION_NAME,
  } = usePrintConfig();

  const {
    USE_NON_DEMAND_ADM_NO,
    USE_NON_DEMAND_CLASS_NAME,
    USE_NON_DEMAND_FATHER_NAME,
    USE_NON_DEMAND_REG_NO,
    USE_NON_DEMAND_SECTION_NAME,
    USE_NON_DEMAND_MOTHER_NAME,
  } = useNonDemandReceiptConfig();
  const { classLabel, sectionLabel } = useInstLabels();

  const showSection: boolean = ActiveFinancicalYear.data
    ? (dayjs(toInputStandardDate(v_date)).isAfter(
        toInputStandardDate(
          ActiveFinancicalYear.data.GetFinYrActiveByInstId.fin_st_date
        )
      ) &&
        dayjs(toInputStandardDate(v_date)).isBefore(
          toInputStandardDate(
            ActiveFinancicalYear.data.GetFinYrActiveByInstId.fin_end_date
          )
        )) ||
      dayjs(toInputStandardDate(v_date)).isSame(
        toInputStandardDate(
          ActiveFinancicalYear.data.GetFinYrActiveByInstId.fin_end_date
        )
      ) ||
      dayjs(toInputStandardDate(v_date)).isSame(
        toInputStandardDate(
          ActiveFinancicalYear.data.GetFinYrActiveByInstId.fin_st_date
        )
      )
    : false;

  return (
    <>
      <div className="prs__top--std-details">
        <div className="row g-0 prs__top--std-details--grids">
          <div className="col">
            <div className="prs__top--std-details--data">
              <span>Name</span> <span>:</span>
              <b>
                {studentDetails
                  ? `${studentDetails.first_name} ${studentDetails.middle_name} ${studentDetails.last_name}`
                  : state.studentId || state.stdIdForChallan
                  ? studentFormData.std_name
                  : state.aluminiStudentId || state.passOutStdIdForChallan
                  ? passedOutStudentFormData.std_name
                  : `${EnquiryQueryDetails.data?.node.first_name} ${EnquiryQueryDetails.data?.node.middle_name}  ${EnquiryQueryDetails.data?.node.last_name}`}
              </b>
            </div>
            {(pageType === InstDetailsPageType.NON_DEMAND &&
              USE_NON_DEMAND_ADM_NO) ||
            (pageType !== InstDetailsPageType.NON_DEMAND && USE_ADM_NO) ? (
              <div className="prs__top--std-details--data">
                <span>Adm No.</span> <span>:</span>
                <span>
                  {studentDetails
                    ? studentDetails.std_adm_no
                    : state.studentId || state.stdIdForChallan
                    ? studentFormData.adm_no
                    : "-"}
                </span>
              </div>
            ) : (pageType === InstDetailsPageType.NON_DEMAND &&
                USE_NON_DEMAND_REG_NO) ||
              (pageType !== InstDetailsPageType.NON_DEMAND && USE_REG_NO) ? (
              <div className="prs__top--std-details--data">
                <span>Reg No.</span> <span>:</span>
                <span>
                  {studentDetails
                    ? studentDetails.std_reg_no
                    : state.studentId || state.stdIdForChallan
                    ? studentFormData.reg_number
                    : "-"}
                </span>
              </div>
            ) : null}

            {(pageType === InstDetailsPageType.NON_DEMAND &&
              USE_NON_DEMAND_CLASS_NAME) ||
            (pageType !== InstDetailsPageType.NON_DEMAND && USE_CLASS_NAME) ? (
              <div className="prs__top--std-details--data">
                <span>{classLabel}</span> <span>:</span>
                <span>
                  {studentDetails
                    ? studentReceiptDetails
                      ? studentReceiptDetails.class_details.class_desc
                      : EMPTY_STRING
                    : state.studentId || state.stdIdForChallan
                    ? studentReceiptDetails
                      ? studentReceiptDetails.class_details.class_desc
                      : studentFormData.class
                    : state.aluminiStudentId || state.passOutStdIdForChallan
                    ? passedOutStudentReceiptDetails
                      ? passedOutStudentReceiptDetails.class_details.class_desc
                      : passedOutStudentFormData.class_desc
                    : EnquiryQueryDetails.data?.node.class_details.class_desc}
                </span>
              </div>
            ) : null}
          </div>
          <div className="col ">
            {(pageType === InstDetailsPageType.NON_DEMAND &&
              USE_NON_DEMAND_FATHER_NAME) ||
            (pageType !== InstDetailsPageType.NON_DEMAND && USE_FATHER_NAME) ? (
              <div className="prs__top--std-details--data ">
                <span>Father Name</span> <span>:</span>
                <span>
                  {studentDetails
                    ? studentDetails.std_mother_name
                    : state.studentId || state.stdIdForChallan
                    ? studentFormData.father_name
                    : EnquiryQueryDetails.data?.node.std_father_name}
                </span>
              </div>
            ) : null}
            {(pageType === InstDetailsPageType.NON_DEMAND &&
              USE_NON_DEMAND_MOTHER_NAME) ||
            (pageType !== InstDetailsPageType.NON_DEMAND && USE_MOTHER_NAME) ? (
              <div className="prs__top--std-details--data ">
                <span>Mother Name</span> <span>:</span>
                <span>
                  {studentDetails
                    ? studentDetails.std_mother_name
                    : state.studentId || state.stdIdForChallan
                    ? studentFormData.mother_name
                    : EnquiryQueryDetails.data?.node.std_mother_name}
                </span>
              </div>
            ) : null}

            {(pageType === InstDetailsPageType.NON_DEMAND &&
              USE_NON_DEMAND_SECTION_NAME) ||
            (pageType !== InstDetailsPageType.NON_DEMAND &&
              USE_SECTION_NAME) ? (
              <div className="prs__top--std-details--data ">
                {studentDetails && showSection ? (
                  <>
                    <span>{sectionLabel}</span> <span>:</span>
                    <span>{studentDetails.section.section_desc}</span>
                  </>
                ) : state.studentId && showSection ? (
                  <>
                    <span>{sectionLabel}</span> <span>:</span>
                    <span>{studentFormData.section}</span>
                  </>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentInfo;
