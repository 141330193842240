import React, { useContext, useEffect, useState } from "react";
import useReportCardConfig from "../../../Academics/Reports/CompleteReports/ReportCards/hooks/useReportCardConfig";
import {
  StudentAcademicData,
  StudentAcademicVars,
} from "../../../Academics/hooks/useAcdStudentDetails";
import { GetAcdStudentDetails } from "../../../Academics/queries/student";
import useToken from "../../../../customhooks/useToken";
import { SelectedStudentsProps } from "../hooks/useStudentMarksStatistics";
import { useLazyQuery } from "@apollo/client";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import { EMPTY_STRING } from "../../../../utils/constants";
import { AppContext } from "../../../../context/context";
import { getDownloadUrl } from "../../../../utils/DownloadFile";
import Avatar from "../../../../images/Avatar.svg";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import useActiveAcademicYear from "../../../Academics/hooks/useActiveAcademicYear";
import { toStandardDate } from "../../../../utils/UtilFunctions";
const StudentDetails = ({
  selectedStudents,
  setStudentIdSet,
}: SelectedStudentsProps) => {
  const { token } = useToken();
  const [imageString, setImageString] = useState("");
  const { InstDetails } = useInstDetails(1);
  const { state } = useContext(AppContext);
  const { activeAcademicYearData } = useActiveAcademicYear();

  const {
    stdName,
    section,
    fatherName,
    motherName,
    rollNo,

    stdImage,
    dob,
  } = useReportCardConfig();
  const [GetStudentDetailsByIds, { data: studentData }] = useLazyQuery<
    StudentAcademicData,
    StudentAcademicVars
  >(GetAcdStudentDetails, {
    variables: { token, ids: selectedStudents },
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (token && selectedStudents) {
      GetStudentDetailsByIds();
    }
  }, [token, GetStudentDetailsByIds, selectedStudents]);
  useEffect(() => {
    if (studentData) {
      if (
        studentData?.nodes[0].std_profile_filename !== EMPTY_STRING &&
        InstDetails.data
      ) {
        const studentProfiePicUrl = `${InstDetails.data?.nodes[0]?.inst_name}/students/${selectedStudents[0]}/std_profile_pic/std_profile_pic`;

        getDownloadUrl(studentProfiePicUrl, false, setImageString);
      }
    }
  }, [studentData, InstDetails.data, state.studentId]);
  const { classLabel, sectionLabel } = useInstLabels();

  return (
    <div className="bgs-pn-pr__std-details">
      <div style={{ display: "flex", columnGap: 10 }}>
        {stdImage && stdImage.config_boolean_value ? (
          <div className="bgs-pn-pr__std-details--image">
            {imageString ? (
              <img
                src={imageString}
                alt=""
                className="bgs-pn-pr__inst-details--logo"
              />
            ) : (
              <img
                src={Avatar}
                alt=""
                className="bgs-pn-pr__inst-details--logo"
              />
            )}
          </div>
        ) : null}
        <div>
          {stdName?.config_boolean_value && (
            <>
              <div className="bgs-pn-pr__std-details--grid">
                <label>{stdName.config_string_value}</label>
                <span>:</span>
                <input
                  type="text"
                  value={
                    studentData?.nodes[0].first_name +
                    " " +
                    studentData?.nodes[0].middle_name +
                    " " +
                    studentData?.nodes[0].last_name
                  }
                />
              </div>
            </>
          )}

          {rollNo?.config_boolean_value && (
            <div className="bgs-pn-pr__std-details--grid">
              <label>{rollNo.config_string_value}</label>
              <span>:</span>
              <input
                type="text"
                value={studentData && studentData.nodes[0].std_adm_no}
              />
            </div>
          )}
          {fatherName?.config_boolean_value && (
            <div className="bgs-pn-pr__std-details--grid">
              <label>{fatherName.config_string_value}</label>
              <span>:</span>
              <input
                type="text"
                value={studentData && studentData.nodes[0].std_father_name}
              />
            </div>
          )}
          {motherName?.config_boolean_value && (
            <div className="bgs-pn-pr__std-details--grid">
              <label>{motherName.config_string_value}</label>
              <span>:</span>
              <input
                type="text"
                value={studentData && studentData.nodes[0].std_mother_name}
              />
            </div>
          )}
        </div>
      </div>
      <div>
        {section?.config_boolean_value && (
          <>
            <div className="bgs-pn-pr__std-details--grid">
              <label>{classLabel}</label>
              <span>:</span>
              <input
                type="text"
                value={studentData && studentData.nodes[0].acd_class.class_desc}
              />
            </div>
            <div className="bgs-pn-pr__std-details--grid">
              <label>{sectionLabel}</label>
              <span>:</span>
              <input
                type="text"
                value={
                  studentData && studentData.nodes[0].acd_section.section_desc
                }
              />
            </div>
          </>
        )}
        <div className="bgs-pn-pr__std-details--grid">
          <label>Year</label>
          <span>:</span>
          <input
            type="text"
            value={activeAcademicYearData.data?.GetAcdYrActiveByInstId.acd_yr}
          />
        </div>
        {dob?.config_boolean_value && (
          <div className="bgs-pn-pr__std-details--grid">
            <label>{dob.config_string_value}</label>
            <span>:</span>
            <input
              type="text"
              value={toStandardDate(
                studentData ? studentData.nodes[0].std_dob : EMPTY_STRING
              )}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default StudentDetails;
