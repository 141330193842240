import React, { useContext, useState } from "react";
import Home from "../../Master/configurations/Home/Index";
import { Title } from "../../../stories/Title/Title";
import { Button } from "../../../stories/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { Operation, PageFor } from "../../../utils/Enum.types";
import { useMutation } from "@apollo/client";
import { ReCalculateAcdSummaryCounts } from "../../../queries/institution/mutations/new";
import { msgType } from "../../../utils/Form.types";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import useToken from "../../../customhooks/useToken";
import { AppContext } from "../../../context/context";
import LoadingModal from "../../../pages/LoadingModal";
import MessageModal from "../../../pages/MessageModal";
import Close from "../../../images/Close.svg";

interface Props {
  pageType: PageFor;
  setResetModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const Admin = ({ pageType, setResetModal }: Props) => {
  const { token } = useToken();
  const { state } = useContext(AppContext);

  const navigate = useNavigate();
  const { InstId } = useParams();
  const { user_details } = useLoggedInUserDetails();

  const [message, setMessage] = useState<msgType>({
    flag: false,
    message: "",
    operation: Operation.NONE,
  });
  const [RecalculateSummary, { loading: reCalSummaryLoading }] = useMutation(
    ReCalculateAcdSummaryCounts,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const handleRecalculateSummaryCount = () => {
    RecalculateSummary({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      },
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Successfully Recalculated Summary Count",
          operation: Operation.CREATE,
        });
      }
    });
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setResetModal(false);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  return (
    <>
      {pageType === PageFor.GENERAL && <Home DashBoardRequired={false} />}

      <div className="admin-config">
        <div className="admin-config__title">
          <Title>Admin Tools</Title>
          <img src={Close} alt="/" />
        </div>
        <div className="admin-config__block">
          <p>
            This page is intended for admin users only. It allows critical
            activities such as resetting or deleting account data, which cannot
            be recovered. Ensure you take a backup of all necessary data before
            proceeding, as once deleted, the data cannot be restored
          </p>
        </div>
        <div>
          <Button
            mode="yes"
            onClick={() =>
              pageType === PageFor.MODAL
                ? handleRecalculateSummaryCount()
                : navigate(`/${InstId}/institutionconfiguration/admintools`)
            }
          />
          <Button mode="cancel" onClick={() => setResetModal(false)} />
        </div>
      </div>
      <LoadingModal flag={reCalSummaryLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default Admin;
