import React, {
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { Title } from "../../../../stories/Title/Title";
import TotalStudents from "../../../../images/TotalMale.svg";
import Present from "../../../../images/Present.svg";
import Absent from "../../../../images/Absent.svg";
import AttendancePercentage from "../../../../images/AttendancePercentage.svg";
import { Button } from "../../../../stories/Button/Button";
import Input from "../../../../stories/Input/Input";
import Modal from "react-modal";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from "@mui/material";
import { TableHeaderProps } from "../../../../Types/Tables";
import { useNavigate, useParams } from "react-router-dom";
import { AntSwitch } from "../../../../pages/Switch";
import {
  AttendanceDrawer,
  PerStudentAttDrawer
} from "../../../../styles/DrawerStyles";
import ViewPerStdAttendance from "./ViewPerStdAttendance";
import MonthlyOverview from "./MonthlyOverview";
import { AttendenceQueryType } from "../../hooks/useAcdStudentAttendance";
import { AppContext } from "../../../../context/context";
import Close from "../../../../images/Close.svg";
import {
  AcdSubjectAllocationData,
  AttendanceOverViewPageFor,
  AttendanceStatus,
  Direction,
  InstConfigKey,
  InstitutionConfigurationTypes,
  Operation,
  PageFor,
  SortBy,
  UserType
} from "../../../../utils/Enum.types";
import LoadingModal from "../../../../pages/LoadingModal";
import {
  EMPTY_STRING,
  muiCheckboxClassName
} from "../../../../utils/constants";

import useActiveAcademicYear from "../../hooks/useActiveAcademicYear";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import {
  isOptionEqualToValue,
  toIsoDate,
  toStandardDate
} from "../../../../utils/UtilFunctions";
import { Keys } from "../../../../utils/Enum.keys";
import { msgType } from "../../../../utils/Form.types";
import {
  DeleteAttendanceForStudents,
  MarkAttendanceForStudents
} from "../../queries/holidays/mutation";
import { useLazyQuery, useMutation } from "@apollo/client";
import useToken from "../../../../customhooks/useToken";
import Home from "../../Home/Index";
import MessageModal from "../../../../pages/MessageModal";
import AbsenteesList from "./AbsenteesList";
import {
  GetAcdAttStdDailyStatus,
  GetAcdAttStdTodaysStatus,
  GetAcdStdsForAttendance,
  GetAcdStudentsAttendance
} from "../../queries/holidays/list";
import {
  GetAcdAttStdAttStatusVars,
  GetAcdAttStdTodaysStatusData,
  GetAcdStdsFromAttData,
  GetAcdStdsFromAttVars
} from "../../types/attendance";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import { GetAcdStudentAttendanceCount } from "../../queries/general";
import {
  StudentAcdNode,
  StudentListEdges
} from "../../hooks/useAcdStudentsData";
import CalendarForInput, {
  CustomisedInputFor
} from "../../../../utils/CalendarForInput";
import useHolidaysList from "../../../../customhooks/useHolidaysList";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import { PageInfo } from "../../../Channel/Types";
import { AcdSubjectMaster } from "../../types/test";
import { AcdStdAttSubjQueryType } from "./SessionAttendance";
import {
  StudentModalStyles,
  WaiveOffModalStyles
} from "../../../../styles/ModalStyles";
import { Label } from "../../../../stories/Label/Label";
import {
  SoftwareConfigList,
  SoftwareVars
} from "../../../../Types/configtypes";
import { GetSwConfigReferenceDataByKey } from "../../../../queries/institution/configuration/query/SoftwreConfig";
import { OptionList } from "../../../UserRights/AssignRights/ChatConfiguration";
import TextArea from "../../../../stories/TextArea/TextArea";
import { payloadTypes } from "../../../../context/reducer";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import {
  labelClasses,
  LabeledAutocomplete
} from "../../../../styles/AutocompleteListStyles";
import Message from "../../../../images/SendMessage.svg";
import MessageListModal from "../../../Channel/MessageList";

import AttendanceDeleteModal from "../../../../pages/AttendanceDeleteModal";
import MoreBirthdayList from "../../Dashboard/Admin/MoreBirthdayList";
import useStudentDataToGetBirthday from "../../../../customhooks/useStudentDataToGetBirthday";
import { StudentAcdType } from "../../../../utils/studentAcdEnum.types";
export interface CustomDrawerProps {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
}
const { Academics_Table } = require("../../json/table.json");
export type GetAcdStudentAttendanceCountVars = {
  token: string;
  inst_id: string;
  acd_yr_id: number;
  date_of_attendance: string;
  entry_level: string;
  entry_id: string;
};

export type GetAcdStudentAttendanceCountData = {
  GetAcdStudentAttendanceCount: {
    present_count: number;
    absent_count: number;
    total_std_count: number;
    att_percentage: number;
  };
};
interface GetAcdAttendanceSubjMonthlyNode {
  id: number;
  cal_month: string;
  att_subj_total: number;
  att_subj_total_present: number;
  att_subj_total_absent: number;
  std_roll_no: number;
  no_session_day_1: number;
  subj_day_1: string;
  session_code_day_1: string;
  no_session_day_2: number;
  subj_day_2: string;
  session_code_day_2: string;
  no_session_day_3: number;
  subj_day_3: string;
  session_code_day_3: string;
  no_session_day_4: number;
  subj_day_4: string;
  session_code_day_4: string;
  no_session_day_5: number;
  subj_day_5: string;
  session_code_day_5: string;
  no_session_day_6: number;
  subj_day_6: string;
  session_code_day_6: string;
  no_session_day_7: number;
  subj_day_7: string;
  session_code_day_7: string;
  no_session_day_8: number;
  subj_day_8: string;
  session_code_day_8: string;
  no_session_day_9: number;
  subj_day_9: string;
  session_code_day_9: string;
  no_session_day_10: number;
  subj_day_10: string;
  session_code_day_10: string;
  no_session_day_11: number;
  subj_day_11: string;
  session_code_day_11: string;
  no_session_day_12: number;
  subj_day_12: string;
  session_code_day_12: string;
  no_session_day_13: number;
  subj_day_13: string;
  session_code_day_13: string;
  no_session_day_14: number;
  subj_day_14: string;
  session_code_day_14: string;
  no_session_day_15: number;
  subj_day_15: string;
  session_code_day_15: string;
  no_session_day_16: number;
  subj_day_16: string;
  session_code_day_16: string;
  no_session_day_17: number;
  subj_day_17: string;
  session_code_day_17: string;
  no_session_day_18: number;
  subj_day_18: string;
  session_code_day_18: string;
  no_session_day_19: number;
  subj_day_19: string;
  session_code_day_19: string;
  no_session_day_20: number;
  subj_day_20: string;
  session_code_day_20: string;
  no_session_day_21: number;
  subj_day_21: string;
  session_code_day_21: string;
  no_session_day_22: number;
  subj_day_22: string;
  session_code_day_22: string;
  no_session_day_23: number;
  subj_day_23: string;
  session_code_day_23: string;
  no_session_day_24: number;
  subj_day_24: string;
  session_code_day_24: string;
  no_session_day_25: number;
  subj_day_25: string;
  session_code_day_25: string;
  no_session_day_26: number;
  subj_day_26: string;
  session_code_day_26: string;
  no_session_day_27: number;
  subj_day_27: string;
  session_code_day_27: string;
  no_session_day_28: number;
  subj_day_28: string;
  session_code_day_28: string;
  no_session_day_29: number;
  subj_day_29: string;
  session_code_day_29: string;
  no_session_day_30: number;
  subj_day_30: string;
  session_code_day_30: string;
  no_session_day_31: number;
  subj_day_31: string;
  session_code_day_31: string;
  acd_dept_id: number;
  acd_branch_id: number;
  acd_class_id: number;
  acd_semester_id: number;
  acd_section_id: number;
  student_id: number;
  acd_yr_id: number;
  subj_master_id: number;
  inst_id: number;
  student_details: StudentAcdNode;
  subject_details: AcdSubjectMaster;
}
interface GetAcdAttendanceSubjMonthlyEdges {
  node: GetAcdAttendanceSubjMonthlyNode;
}
interface GetAcdAttendanceSubjMonthlyResult {
  totalCount: number;
  pageInfo: PageInfo;
  edges: GetAcdAttendanceSubjMonthlyEdges[];
}
export interface GetAcdAttendanceSubjMonthlyData {
  GetAcdAttendanceSubjMonthly: GetAcdAttendanceSubjMonthlyResult;
}
export interface GetAcdAttendanceSubjMonthlyVars {
  token: string;
  input: {
    att_query_type: AcdStdAttSubjQueryType;
    entry_id: string;
    entry_level: string;
    cal_month: string;
    inst_id: string | number;
    acd_yr_id: number;
    subj_master_id: number;
    date_of_attendance: string;
  };
  first: number;
  after: string | null;
  per_std_subj_allocation: boolean;
  orderBy: {
    direction: Direction;
    field: SortBy;
  };
}
export interface GetAcdAttendanceSubjMonthlyForStdVars {
  token: string;
  input: {
    att_query_type: AcdStdAttSubjQueryType;
    entry_id: number;
    entry_level: string;
    cal_month: string;
    inst_id: string | number;
    acd_yr_id: number;
    subj_master_id: number;
    session_id: number;
    student_id: number;
    date_of_attendance: string;
  };
  per_std_subj_allocation: boolean | undefined;
  first: number;
  after: string | null;
  orderBy: {
    direction: Direction;
    field: SortBy;
  };
  name: string;
}

interface GetAcdStudentsAttendanceVars {
  token: string;
  input: {
    att_query_type: string;
    entry_id: string | number;
    cal_month: string;
    inst_id: string | number;
    acd_yr_id: number;
    date_of_attendance: string;
    subj_master_id: number;
    per_std_subj_allocation: boolean;
  };
}

interface GetAcdStudentsAttendanceData {
  id: number;
  cal_month: string;
  att_total_days_present: number;
  att_total_days_absent_full: number;
  att_total_days_absent_half: number;
  att_total_days_absent: number;
  std_roll_no: number;
  day_2: string;
  day_3: string;
  day_4: string;
  day_1: string;
  day_5: string;
  day_6: string;
  day_7: string;
  day_8: string;
  day_9: string;
  day_10: string;
  day_11: string;
  day_12: string;
  day_13: string;
  day_14: string;
  day_15: string;
  day_16: string;
  day_17: string;
  day_18: string;
  day_19: string;
  day_20: string;
  day_21: string;
  day_22: string;
  day_23: string;
  day_24: string;
  day_25: string;
  day_26: string;
  day_27: string;
  day_28: string;
  day_29: string;
  day_30: string;
  day_31: string;
  student_id: number;
  acd_yr_id: number;
  acd_class_id: number;
  acd_semester_id: number;
  acd_section_id: number;
  class_mth_cal_id: number;
  student_details: StudentAcdNode;
}
interface GetAcdStudentsAttendanceResult {
  GetAcdStudentsAttendance: GetAcdStudentsAttendanceData[];
}
interface Props {
  pageType: AttendanceOverViewPageFor;
}

const Index = ({ pageType }: Props) => {
  const navigate = useNavigate();
  const { InstId, entryId } = useParams();
  const { user_details } = useLoggedInUserDetails();
  const { state, dispatch } = useContext(AppContext);
  const [date, setDate] = useState(new Date());
  const [markAllAbsent, setMarkAllAbsent] = useState(false);
  const [moreDetails, setMoreDetails] = useState(false);
  const [messageModal, setMessageModal] = useState(false);
  const { token } = useToken();
  const [students, setStudents] = useState<StudentListEdges[]>([]);
  const [reasonSelected, setReasonSelected] = useState<OptionList | null>(null);
  const [approverSelected, setApproverSelected] = useState<OptionList | null>(
    null
  );
  const { entry_level } = useInstitutionConfiguration();

  const [stdId, setStdId] = useState(0);
  const [comments, setComments] = useState("");

  const [GetStdDataAttendance, { data: stdAttData }] = useLazyQuery<
    GetAcdStudentsAttendanceResult,
    GetAcdStudentsAttendanceVars
  >(GetAcdStudentsAttendance);
  useEffect(() => {
    if (token && entryId && state.ActiveAcdYr && entry_level) {
      GetStdDataAttendance({
        variables: {
          token,
          input: {
            att_query_type:
              entry_level === AcdSubjectAllocationData.CLASS
                ? AttendenceQueryType.ATT_STD_BY_CLASS_ID
                : entry_level === AcdSubjectAllocationData.SEMESTER
                ? AttendenceQueryType.ATT_STD_BY_SEMESTER_ID
                : AttendenceQueryType.ATT_STD_BY_SECTION_ID,
            entry_id: entryId!,
            cal_month: toIsoDate(date.toString()),
            inst_id: InstId!,
            acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
            date_of_attendance: toIsoDate(date.toString()),
            subj_master_id: 0,
            per_std_subj_allocation: false
          }
        }
      });
    }
  }, [
    token,
    entryId,
    GetStdDataAttendance,
    state.ActiveAcdYr,
    entry_level,
    InstId,
    date
  ]);

  const [monthlyOverView, setMonthlyOverView] = useState(false);
  const [absentiesList, setAbsentiesList] = useState(false);
  const [perStudentAttendance, setPerStudentAttendance] = useState(false);

  const [searchStudentName, setSearchStudentName] = useState("");
  const [absentModal, setAbsentModal] = useState(false);
  const [institutionWorkingtypeData, setInstitutionWorkingTypeData] = useState<
    OptionList[]
  >([]);
  const [deleteModal, setDeleteModal] = useState(false);

  const [approvedDropDown, setApprovedDropDown] = useState<OptionList[]>([]);

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE
  });

  const studentRefs = useRef<HTMLButtonElement[]>([]);
  const [GetConfigData] = useLazyQuery<SoftwareConfigList, SoftwareVars>(
    GetSwConfigReferenceDataByKey
  );
  const [GetApprovedConfigData] = useLazyQuery<
    SoftwareConfigList,
    SoftwareVars
  >(GetSwConfigReferenceDataByKey);

  const { serverDate } = useServerDateandTime();

  const { activeAcademicYearData } = useActiveAcademicYear();

  const { configData: absentFlagData } = useSwConfigData(
    InstitutionConfigurationTypes.OPTION_TO_MARK_ALL_STD_ABSENT
  );
  const absentFlag =
    absentFlagData &&
    absentFlagData.data &&
    absentFlagData.data.GetSwConfigVariables.length &&
    absentFlagData.data.GetSwConfigVariables[0].config_boolean_value;
  const dates = useHolidaysList(
    date,
    activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!
  );

  const [GetAttCount, { data: countData }] = useLazyQuery<
    GetAcdStudentAttendanceCountData,
    GetAcdStudentAttendanceCountVars
  >(GetAcdStudentAttendanceCount);

  const [GetTodaysAttStatus, { data: attStatus }] = useLazyQuery<
    GetAcdAttStdTodaysStatusData,
    GetAcdAttStdAttStatusVars
  >(GetAcdAttStdTodaysStatus, {
    variables: {
      token,
      input: {
        acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
        entry_id: entryId!,
        entry_level,
        date_of_attendance: toIsoDate(date.toString()),
        inst_id: InstId!
      }
    }
  });

  const [MarkAttendence, { loading: creationLoading }] = useMutation(
    MarkAttendanceForStudents,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE
        })
    }
  );
  const [DeleteAttendance, { loading: deleteLoading }] = useMutation(
    DeleteAttendanceForStudents,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE
        })
    }
  );
  const [GetStudentAcdList, { data: stdData, loading: stdLoading }] =
    useLazyQuery<GetAcdStdsFromAttData, GetAcdStdsFromAttVars>(
      GetAcdStdsForAttendance
    );
  useEffect(() => {
    if (token && state.ActiveAcdYr) {
      GetStudentAcdList({
        variables: {
          token,
          input: {
            att_query_type:
              entry_level === AcdSubjectAllocationData.CLASS
                ? AttendenceQueryType.ATT_STD_BY_CLASS_ID
                : entry_level === AcdSubjectAllocationData.SEMESTER
                ? AttendenceQueryType.ATT_STD_BY_SEMESTER_ID
                : AttendenceQueryType.ATT_STD_BY_SECTION_ID,
            entry_id: entryId!,
            cal_month: toIsoDate(date.toString()),
            inst_id: InstId!,
            acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
            date_of_attendance: toIsoDate(date.toString()),
            subj_master_id: 0,
            per_std_subj_allocation: false
          }
        }
      });
    }
  }, [GetStudentAcdList, token, state.ActiveAcdYr, date]);

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      studentRefs.current = [];
      setMarkAllAbsent(false);
      if (pageType === AttendanceOverViewPageFor.ADMIN) {
        navigate(`/${InstId}/academics/attendance/foradmin`);
      }
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE
    });
  };

  const AddRefs = (e: HTMLButtonElement) => {
    if (e && !studentRefs.current.includes(e)) {
      studentRefs.current.push(e);
    }
  };

  const handleMarkingAttendence = () => {
    MarkAttendence({
      variables: {
        token,
        acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id,
        entry_level,
        entry_id: entryId,
        date_of_attendance: toIsoDate(date.toString()),
        emp_id: state.claims!.EDUATE ? 0 : state.empLoginId,
        inst_id: InstId,
        user_details,
        marked_date: toIsoDate(serverDate),
        input: students
          .filter((res) => !res.isChecked)
          .map((data) => ({
            student_id: data.id,
            att_status: AttendanceStatus.A,
            comments:
              data.att_status === "OTHERS" ? data.comments : data.att_status,
            approved_by: EMPTY_STRING
          }))
      },
      refetchQueries: [
        {
          query: GetAcdAttStdTodaysStatus,
          variables: {
            token,
            input: {
              acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
              entry_id: entryId!,
              entry_level,
              date_of_attendance: toIsoDate(date.toString()),
              inst_id: InstId!
            }
          }
        },

        {
          query: GetAcdStdsForAttendance,
          variables: {
            token,
            input: {
              att_query_type:
                entry_level === AcdSubjectAllocationData.CLASS
                  ? AttendenceQueryType.ATT_STD_BY_CLASS_ID
                  : entry_level === AcdSubjectAllocationData.SEMESTER
                  ? AttendenceQueryType.ATT_STD_BY_SEMESTER_ID
                  : AttendenceQueryType.ATT_STD_BY_SECTION_ID,
              entry_id: entryId!,
              cal_month: toIsoDate(date.toString()),
              inst_id: InstId!,
              acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
              date_of_attendance: toIsoDate(date.toString()),
              subj_master_id: 0,
              per_std_subj_allocation: false
            }
          }
        },
        {
          query: GetAcdStudentsAttendance,
          variables: {
            token,
            input: {
              att_query_type:
                entry_level === AcdSubjectAllocationData.CLASS
                  ? AttendenceQueryType.ATT_STD_BY_CLASS_ID
                  : entry_level === AcdSubjectAllocationData.SEMESTER
                  ? AttendenceQueryType.ATT_STD_BY_SEMESTER_ID
                  : AttendenceQueryType.ATT_STD_BY_SECTION_ID,
              entry_id: entryId!,
              cal_month: toIsoDate(date.toString()),
              inst_id: InstId!,
              acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
              date_of_attendance: toIsoDate(date.toString()),
              subj_master_id: 0,
              per_std_subj_allocation: false
            }
          }
        },
        {
          query: GetAcdStudentAttendanceCount,
          variables: {
            token,
            inst_id: InstId!,
            acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
            date_of_attendance: toIsoDate(date.toString()),
            entry_level,
            entry_id: entryId!
          }
        },
        {
          query: GetAcdAttStdDailyStatus,
          variables: {
            input: {
              acd_yr_id:
                activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
              entry_id: entryId!,
              entry_level: entry_level,
              date_of_attendance: toIsoDate(date.toString()!),
              inst_id: InstId!
            },
            token
          }
        }
      ]
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: `Sucessfully Updated Attendence for the day ${toStandardDate(
            date.toString()
          )}`,
          operation: Operation.CREATE
        });
      }
    });
  };
  const handleDeleteAttendance = () => {
    DeleteAttendance({
      variables: {
        token,
        acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id,
        entry_level,
        entry_id: entryId,
        date_of_attendance: toIsoDate(date.toString()),
        emp_id: state.claims!.EDUATE ? 0 : state.empLoginId,
        inst_id: InstId,
        user_details
      },
      refetchQueries: [
        {
          query: GetAcdAttStdTodaysStatus,
          variables: {
            token,
            input: {
              acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
              entry_id: entryId!,
              entry_level,
              date_of_attendance: toIsoDate(date.toString()),
              inst_id: InstId!
            }
          }
        },

        {
          query: GetAcdStdsForAttendance,
          variables: {
            token,
            input: {
              att_query_type:
                entry_level === AcdSubjectAllocationData.CLASS
                  ? AttendenceQueryType.ATT_STD_BY_CLASS_ID
                  : entry_level === AcdSubjectAllocationData.SEMESTER
                  ? AttendenceQueryType.ATT_STD_BY_SEMESTER_ID
                  : AttendenceQueryType.ATT_STD_BY_SECTION_ID,
              entry_id: entryId!,
              cal_month: toIsoDate(date.toString()),
              inst_id: InstId!,
              acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
              date_of_attendance: toIsoDate(date.toString()),
              subj_master_id: 0,
              per_std_subj_allocation: false
            }
          }
        },
        {
          query: GetAcdStudentsAttendance,
          variables: {
            token,
            input: {
              att_query_type:
                entry_level === AcdSubjectAllocationData.CLASS
                  ? AttendenceQueryType.ATT_STD_BY_CLASS_ID
                  : entry_level === AcdSubjectAllocationData.SEMESTER
                  ? AttendenceQueryType.ATT_STD_BY_SEMESTER_ID
                  : AttendenceQueryType.ATT_STD_BY_SECTION_ID,
              entry_id: entryId!,
              cal_month: toIsoDate(date.toString()),
              inst_id: InstId!,
              acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
              date_of_attendance: toIsoDate(date.toString()),
              subj_master_id: 0,
              per_std_subj_allocation: false
            }
          }
        },
        {
          query: GetAcdStudentAttendanceCount,
          variables: {
            token,
            inst_id: InstId!,
            acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
            date_of_attendance: toIsoDate(date.toString()),
            entry_level,
            entry_id: entryId!
          }
        },
        {
          query: GetAcdAttStdDailyStatus,
          variables: {
            input: {
              acd_yr_id:
                activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
              entry_id: entryId!,
              entry_level: entry_level,
              date_of_attendance: toIsoDate(date.toString()!),
              inst_id: InstId!
            },
            token
          }
        }
      ]
    }).then(({ data }) => {
      if (data) {
        setDeleteModal(false);
      }
    });
    setMessage({
      flag: true,
      message: `Sucessfully Updated Attendence for the day ${toStandardDate(
        date.toString()
      )}`,
      operation: Operation.CREATE
    });
  };
  const handleEditItem = (id: number) => {
    const index = students.findIndex((d) => d.id === id);

    if (index !== -1) {
      const newData: StudentListEdges = {
        ...students[index],
        isChecked: !students[index].isChecked
      };

      if (!newData.isChecked) {
        newData.att_status = reasonSelected?.value || "";
        newData.comments = comments || "";
        newData.approved_by = approverSelected?.value || "";
      } else {
        newData.att_status = "";
        newData.comments = "";
        newData.approved_by = "";
      }

      const newStudents = [...students];
      newStudents[index] = newData; // Correct assignment
      setStudents(newStudents);
    }
    setReasonSelected(null);
    setComments("");
    setApproverSelected(null);
    setAbsentModal(!absentModal);
  };
  useEffect(() => {
    if (stdData && !stdLoading && token) {
      const updatedStudents = stdData.GetAcdStdsForAttendance.map(
        (student: StudentListEdges) => ({
          ...student,
          isChecked: markAllAbsent ? false : true
        })
      );
      setStudents(updatedStudents);
    } // eslint-disable-next-line
  }, [stdData, stdLoading, token, markAllAbsent]);
  useEffect(() => {
    if (serverDate) {
      setDate(new Date(serverDate));
    }
  }, [serverDate]);

  useEffect(() => {
    if (token) {
      GetConfigData({
        variables: { config_key: InstConfigKey.STD_LEAVE_OPTIONS, token }
      }).then(({ data }) => {
        if (data?.GetSwConfigReferenceDataByKey) {
          setInstitutionWorkingTypeData(
            data.GetSwConfigReferenceDataByKey.list_item_list
              .split(";")
              ?.map((d) => ({
                label: d?.split(":")[0],
                value: d?.split(":")[1]
              }))
          );
        }
      });
      GetApprovedConfigData({
        variables: { config_key: InstConfigKey.LEAVE_APPROVED_BY, token }
      }).then(({ data }) => {
        if (data?.GetSwConfigReferenceDataByKey) {
          setApprovedDropDown(
            data.GetSwConfigReferenceDataByKey.list_item_list
              .split(";")
              ?.map((d) => ({
                label: d?.split(":")[0],
                value: d?.split(":")[1]
              }))
          );
        }
      });
    }
  }, [token, GetConfigData, GetApprovedConfigData]);

  const CustomDrawer = ({ open, onClose, children }: CustomDrawerProps) => {
    return (
      <AttendanceDrawer anchor="right" open={open} onClose={onClose}>
        {children}
      </AttendanceDrawer>
    );
  };

  useEffect(() => {
    if (
      token &&
      date &&
      state.ActiveAcdYr &&
      activeAcademicYearData.data &&
      !activeAcademicYearData.loading &&
      entry_level
    ) {
      GetTodaysAttStatus();
    }
  }, [
    token,
    GetTodaysAttStatus,
    date,
    state.ActiveAcdYr,
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
    entry_level
  ]);
  useEffect(() => {
    if (
      token &&
      date &&
      state.ActiveAcdYr &&
      activeAcademicYearData.data &&
      !activeAcademicYearData.loading &&
      entry_level
    ) {
      GetAttCount({
        variables: {
          token,
          inst_id: InstId!,
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          date_of_attendance: toIsoDate(date.toString()),
          entry_level,
          entry_id: entryId!
        }
      });
    }
  }, [
    GetAttCount,
    token,
    date,
    state.ActiveAcdYr,
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
    entry_level
  ]);
  const { data: BirthDayList } = useStudentDataToGetBirthday(
    StudentAcdType.CUR_STD_HAS_DOB_TODAY_AT_ENTRY_LEVEL
  );

  return (
    <>
      <div className="attendance-overview">
        {pageType === AttendanceOverViewPageFor.DAILY_ACTIVITIES ? null : (
          <Home DashBoardRequired={false} />
        )}

        <div
          className={
            pageType === AttendanceOverViewPageFor.DAILY_ACTIVITIES
              ? "attendance-overview__data"
              : "attendance-overview__data--admin"
          }
        >
          <div className="row g-0">
            <div className="col-2">
              <Input
                id="search"
                type="text"
                placeholder="Search..."
                onChange={(e) => setSearchStudentName(e.target.value)}
              />
            </div>
            <div className="col">
              {attStatus &&
              attStatus.GetAcdAttStdTodaysStatus &&
              attStatus.GetAcdAttStdTodaysStatus !== undefined &&
              attStatus.GetAcdAttStdTodaysStatus ? null : (
                <>
                  {absentFlag && (
                    <b className="daily-activities__select-block--title">
                      Mark All Absent &nbsp;
                      <AntSwitch
                        checked={markAllAbsent}
                        onClick={() => setMarkAllAbsent(!markAllAbsent)}
                      />
                    </b>
                  )}
                </>
              )}
            </div>
            <div className="col-2 attendance-overview__date">
              <CalendarForInput
                date={date}
                setDate={setDate}
                dates={dates}
                allowFutureDatesAccess={false}
                inputFor={CustomisedInputFor.GENERAL}
                label="Date"
              />
            </div>
          </div>

          <div className="attendance-overview__data--block row g-0">
            <div className="col attendance-overview__data--block--left h-100">
              <div className="attendance-overview__tableblock">
                <TableContainer className="attendance-overview__table">
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        {Academics_Table.DailyActivities.Attendance.map(
                          (th: TableHeaderProps, index: React.Key) => {
                            return (
                              <TableCell key={index}>{th.labelName}</TableCell>
                            );
                          }
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {attStatus &&
                      attStatus?.GetAcdAttStdTodaysStatus !== undefined &&
                      attStatus.GetAcdAttStdTodaysStatus
                        ? stdAttData &&
                          stdAttData.GetAcdStudentsAttendance !== undefined &&
                          stdAttData.GetAcdStudentsAttendance.filter(
                            ({
                              student_details: {
                                first_name,
                                middle_name,
                                last_name,
                                std_adm_no,
                                std_reg_no
                              }
                            }) =>
                              `${first_name} ${middle_name} ${last_name}`
                                .toLocaleLowerCase()
                                .includes(
                                  searchStudentName.toLocaleLowerCase()
                                ) ||
                              std_adm_no
                                .toLocaleLowerCase()
                                .includes(
                                  searchStudentName.toLocaleLowerCase()
                                ) ||
                              std_reg_no
                                .toLocaleLowerCase()
                                .includes(
                                  searchStudentName.toLocaleLowerCase()
                                ) ||
                              searchStudentName === ""
                          ).map((edge: any, index: number) => {
                            return (
                              <TableRow key={index}>
                                <TableCell
                                  className="attendance-overview__table--slno"
                                  id="td-center"
                                >
                                  {index + 1} &nbsp;{" "}
                                  {/* <img src={Avatar} alt="/" /> */}
                                </TableCell>
                                <TableCell className="attendance-overview__table--admno">
                                  {
                                    (edge?.student_details as StudentListEdges)
                                      .std_adm_no
                                  }
                                </TableCell>
                                <TableCell className="attendance-overview__table--regno">
                                  {
                                    (edge?.student_details as StudentListEdges)
                                      .std_reg_no
                                  }
                                </TableCell>
                                <TableCell
                                  className="attendance-overview__table--name"
                                  onClick={() => {
                                    if (attStatus?.GetAcdAttStdTodaysStatus) {
                                      dispatch({
                                        type: payloadTypes.SET_STUDENT_ID,
                                        payload: {
                                          studentId: edge?.student_id as number
                                        }
                                      });
                                      setPerStudentAttendance(
                                        !perStudentAttendance
                                      );
                                    }
                                  }}
                                >
                                  {`${
                                    (edge?.student_details as StudentListEdges)
                                      .first_name
                                  } ${
                                    (edge?.student_details as StudentListEdges)
                                      .middle_name
                                  } ${
                                    (edge?.student_details as StudentListEdges)
                                      .last_name
                                  }`}
                                </TableCell>

                                <TableCell
                                  className={`attendance-overview__table--status font-${
                                    (edge as unknown as Record<string, string>)[
                                      `day_${date.getDate()}`
                                    ] === AttendanceStatus.P
                                      ? "green"
                                      : "red"
                                  }`}
                                  id="td-center"
                                >
                                  {
                                    (edge as unknown as Record<string, string>)[
                                      `day_${date.getDate()}`
                                    ]
                                  }
                                </TableCell>
                              </TableRow>
                            );
                          })
                        : students
                            .filter(
                              ({
                                first_name,
                                middle_name,
                                last_name,
                                std_adm_no,
                                std_reg_no
                              }) =>
                                `${first_name} ${middle_name} ${last_name}`
                                  .toLocaleLowerCase()
                                  .includes(
                                    searchStudentName.toLocaleLowerCase()
                                  ) ||
                                std_adm_no
                                  .toLocaleLowerCase()
                                  .includes(
                                    searchStudentName.toLocaleLowerCase()
                                  ) ||
                                std_reg_no
                                  .toLocaleLowerCase()
                                  .includes(
                                    searchStudentName.toLocaleLowerCase()
                                  ) ||
                                searchStudentName === ""
                            )
                            .map((edge: any, index: number) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell
                                    className="attendance-overview__table--slno"
                                    id="td-center"
                                  >
                                    {index + 1} &nbsp;{" "}
                                    {/* <img src={Avatar} alt="/" /> */}
                                  </TableCell>
                                  <TableCell className="attendance-overview__table--admno">
                                    {edge.std_adm_no}
                                  </TableCell>
                                  <TableCell className="attendance-overview__table--regno">
                                    {edge.std_reg_no}
                                  </TableCell>
                                  <TableCell className="attendance-overview__table--name">
                                    {`${edge.first_name} ${edge.middle_name} ${edge.last_name}`}
                                  </TableCell>

                                  <TableCell
                                    className="attendance-overview__table--status"
                                    id="td-center"
                                  >
                                    <AntSwitch
                                      checked={edge?.isChecked as boolean}
                                      onClick={() => {
                                        setAbsentModal(!absentModal);
                                        setStdId(edge?.id);
                                      }}
                                      ref={AddRefs}
                                      onKeyDown={(e: React.KeyboardEvent) => {
                                        if (e.key === Keys.ENTER) {
                                          if (
                                            studentRefs.current[index + 1] &&
                                            index !== students.length - 1
                                          ) {
                                            (
                                              studentRefs.current[
                                                index + 1
                                              ].getElementsByClassName(
                                                muiCheckboxClassName
                                              )[0] as HTMLInputElement
                                            ).focus();
                                          }
                                        }
                                      }}
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
            {BirthDayList
              ? BirthDayList.GetAcdStudents.totalCount > 0 && (
                  <div className="col-3 attendance-overview__data--block--mid ">
                    <div className="attendance-overview__b-list">
                      <div className="attendance-overview__b-list--header">
                        <span>Today Bithday's List</span>

                        <b>0</b>
                      </div>
                      <ul className="attendance-overview__b-list--ul">
                        {BirthDayList
                          ? BirthDayList.GetAcdStudents.edges.map(
                              (res, index) => {
                                return (
                                  <li key={index}>
                                    <div className="attendance-overview__b-list--ul--flex">
                                      <span className="font-grey">
                                        {index + 1}.
                                      </span>
                                      <div className="attendance-overview__b-list--ul--name">
                                        <span>
                                          {res.node.first_name +
                                            res.node.middle_name +
                                            res.node.last_name}
                                        </span>
                                        <div>
                                          <span className="font-grey">
                                            DOB:{" "}
                                          </span>
                                          <b>
                                            {toStandardDate(res.node.std_dob)}
                                          </b>
                                        </div>
                                      </div>
                                    </div>
                                    <img
                                      src={Message}
                                      alt=""
                                      onClick={() => {
                                        dispatch({
                                          type: payloadTypes.SET_STUDENT_ID,
                                          payload: { studentId: res.node.id }
                                        });
                                        setMessageModal(!messageModal);
                                      }}
                                    />
                                  </li>
                                );
                              }
                            )
                          : null}
                      </ul>
                      <div className="attendance-overview__data--block--buttons">
                        <Button
                          mode="view"
                          onClick={() => setMoreDetails(!moreDetails)}
                        >
                          {" "}
                          More Details
                        </Button>
                      </div>
                    </div>
                  </div>
                )
              : null}

            <div className="col-3 attendance-overview__data--block--right">
              <div className="attendance-overview__data--block--header">
                Attendance Overview
              </div>
              <div className="attendance-overview__data--block--body">
                <div className="attendance-overview__blocks">
                  <div className="attendance-overview__blocks--cards">
                    <img src={TotalStudents} alt="/" />
                    <span>Total Students</span>
                    <b className="attendance-overview__blocks--cards--students">
                      {countData?.GetAcdStudentAttendanceCount.total_std_count}
                    </b>
                  </div>
                  <div className="attendance-overview__blocks--cards">
                    <img src={Present} alt="/" />

                    <span>Total Present</span>
                    <b className="attendance-overview__blocks--cards--present">
                      {countData?.GetAcdStudentAttendanceCount.present_count}
                    </b>
                  </div>
                  <div className="attendance-overview__blocks--cards">
                    <img src={Absent} alt="/" />

                    <span>Total Absent</span>
                    <b className="attendance-overview__blocks--cards--absent">
                      {countData?.GetAcdStudentAttendanceCount.absent_count}
                    </b>
                  </div>
                </div>
                <div className="attendance-overview__blocks--cards">
                  <img src={AttendancePercentage} alt="/" />

                  <span>Atten. Percentage</span>
                  <b className="attendance-overview__blocks--cards--percentage">
                    {countData?.GetAcdStudentAttendanceCount.att_percentage}
                  </b>
                </div>
              </div>

              <div className="attendance-overview__data--block--buttons">
                <Button
                  mode="absent"
                  onClick={() => setAbsentiesList(!absentiesList)}
                >
                  View Absenties List
                </Button>
                <Button
                  mode="view-graph"
                  onClick={() => setMonthlyOverView(!monthlyOverView)}
                >
                  Monthly Overview
                </Button>
              </div>
            </div>
          </div>

          <div className="col">
            {/* {pageType === AttendanceOverViewPageFor.DAILY_ACTIVITIES ? (
             
            ) : (
              <div className="row g-0 attendance-overview__filters">
                {USE_DEPARTMENT_KEY && (
                  <div className="col-2">
                    <TextField
                      label={departmentLabel}
                      value={configdata?.GetAcdInstConfigNames.acd_dept_desc}
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                      disabled
                      className="daily-activities__textfield"
                    />
                  </div>
                )}
                {USE_BRANCH_KEY && (
                  <div className="col-2">
                    <TextField
                      label={branchLabel}
                      value={configdata?.GetAcdInstConfigNames.acd_branch_desc}
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                      disabled
                      className="daily-activities__textfield"
                    />
                  </div>
                )}
                {USE_CLASS_KEY && (
                  <div className="col-2">
                    <TextField
                      label={classLabel}
                      value={configdata?.GetAcdInstConfigNames.acd_class_desc}
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                      disabled
                      className="daily-activities__textfield"
                    />
                  </div>
                )}
                {USE_SEMESTER_KEY && (
                  <div className="col-2">
                    <TextField
                      label={semesterLabel}
                      value={
                        configdata?.GetAcdInstConfigNames.acd_semester_desc
                      }
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                      disabled
                      className="daily-activities__textfield"
                    />
                  </div>
                )}
                {USE_SECTION_KEY && (
                  <div className="col-2">
                    <TextField
                      label={sectionLabel}
                      value={configdata?.GetAcdInstConfigNames.acd_section_desc}
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                      disabled
                      className="daily-activities__textfield"
                    />
                  </div>
                )}
              </div>
            )} */}
          </div>
          <div className="col-2"></div>
          {/* <div className="col-2 attendance-overview__rightblock"> */}
          {/* <div className="attendance-overview__rightblock--date">
              <TextField
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                inputProps={{
                  max: toInputStandardDate(serverDate),
                }}
                label="Date"
                type="date"
                value={toInputStandardDate(date.toString())}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setDate(new Date(e.target.value));
                }}
                className="attendance-overview__rightblock--textfield"
              />
            </div> */}

          {/* </div> */}
        </div>
        <div
          className={
            pageType === AttendanceOverViewPageFor.DAILY_ACTIVITIES
              ? ""
              : "button-left"
          }
        >
          {attStatus &&
          attStatus?.GetAcdAttStdTodaysStatus !== undefined &&
          attStatus.GetAcdAttStdTodaysStatus ? null : (
            <>
              <Button mode="save" onClick={handleMarkingAttendence}>
                &nbsp;Attendance
              </Button>
            </>
          )}
          {attStatus &&
          attStatus.GetAcdAttStdTodaysStatus !== undefined &&
          attStatus.GetAcdAttStdTodaysStatus ? (
            <>
              <Button
                mode="delete"
                onClick={() => setDeleteModal(!deleteModal)}
              >
                &nbsp;Attendance
              </Button>
            </>
          ) : null}

          <Button mode="back" onClick={() => navigate(-1)} />
        </div>
        <CustomDrawer
          open={monthlyOverView}
          onClose={() => setMonthlyOverView(!monthlyOverView)}
        >
          <MonthlyOverview
            selectedDate={date}
            setSelectedDate={setDate}
            setDrawerFlag={setMonthlyOverView}
            pageType={PageFor.GENERAL}
          />
        </CustomDrawer>
        <CustomDrawer
          open={absentiesList}
          onClose={() => setAbsentiesList(!absentiesList)}
        >
          <AbsenteesList setModalFlag={setAbsentiesList} date={date} />
        </CustomDrawer>
        <PerStudentAttDrawer
          anchor="right"
          open={perStudentAttendance}
          onClose={() => setPerStudentAttendance(!perStudentAttendance)}
        >
          <ViewPerStdAttendance
            selectedDate={date}
            setSelectedDate={setDate}
            setPerStudentAttendance={setPerStudentAttendance}
            perStudentAttendance={perStudentAttendance}
            pageType={PageFor.GENERAL}
          />
        </PerStudentAttDrawer>
      </div>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={absentModal}
        ariaHideApp={false}
        style={WaiveOffModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Title>Absent Remarks</Title>
            <Label>Reason</Label>
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={institutionWorkingtypeData}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option as OptionList, reasonSelected)
              }
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                }
                if (e.key === Keys.BACKSPACE) {
                  setReasonSelected(null);
                }
              }}
              openOnFocus
              value={reasonSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setReasonSelected(newValue as OptionList);
                } else {
                  setReasonSelected(null);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  slotProps={{
                    inputLabel: {
                      shrink: true
                    }
                  }}
                  id="outlined Departments"
                  className={labelClasses.formControlRoot}
                />
              )}
            />
            {reasonSelected?.label === "Others" && (
              <>
                <Label>Comments</Label>
                <TextArea
                  rows={3}
                  draggable={false}
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                  // onKeyDown={(e) => {
                  //   if (e.key === Keys.ENTER) {
                  //     e.preventDefault();
                  //     saveButtonRef.current?.focus();
                  //   }
                  // }}
                  // textAreaRef={remarkRef}
                  resize="none"
                />
              </>
            )}

            <br />
            <Button
              mode="save"
              disabled={reasonSelected?.value ? false : true}
              onClick={() => handleEditItem(stdId)}
            />
            <Button
              mode="cancel"
              onClick={() => setAbsentModal(!absentModal)}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt=""
              onClick={() => setAbsentModal(!absentModal)}
            />
          </div>
        </div>
      </Modal>
      <LoadingModal flag={creationLoading || deleteLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <AttendanceDeleteModal
        id={0}
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={handleDeleteAttendance}
        date={date}
      />

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={messageModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <MessageListModal
              pageType={PageFor.MODAL}
              setMessageModal={setMessageModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setMessageModal(!messageModal)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={moreDetails}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <MoreBirthdayList
          setModal={setMoreDetails}
          userType={UserType.TEACHER}
        />
      </Modal>
    </>
  );
};

export default Index;
