import { useLazyQuery } from "@apollo/client";
import { TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  EnquiryStudentDetailsByMasterVoucherId,
  PassedOutStudentDetailsByMasterVoucherId,
  StudentDetailsByMasterVoucherId,
} from "../../../../queries/common";
import { Title } from "../../../../stories/Title/Title";
import {
  EMPTY_STRING,
  emptyMessageType,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
  ROWS_PER_PAGE_30,
  TODAY_DATE,
} from "../../../../utils/constants";
import {
  filterVouchersByType,
  formatter,
  getModifiedScrollHeight,
  handleFormEvent,
  toInputStandardDate,
  toIsoDate,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import {
  BankOrCash,
  DebitOrCredit,
  Operation,
  PageFor,
  ReceiptTypes,
  TableHeaders,
  studentData,
} from "../../../../utils/Enum.types";
import { Button } from "../../../../stories/Button/Button";
import Input from "../../../../stories/Input/Input";
import useToken from "../../../../customhooks/useToken";
import { filterOptions } from "../FeeReceiptBook/List/Index";
import useAcctVoucherDetailsByDates, {
  GetAcctVouchersEdges,
  voucherMasterDetails,
} from "../../hooks/useAcctVoucherDetailsByDates";
import { nodevars, VouchernodeData } from "../../../../Types/Accounting";
import useAcctLedgerData from "../../hooks/useAcctLedgerData";
import { AcctLedgerQueryType } from "../../common/QueryTypes";
import { payloadTypes } from "../../../../context/reducer";
import StudentDemandList from "../AccountLedgerBook/Modals/StudentVoucherView";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import Close from "../../../../images/Close.svg";
import Modal from "react-modal";
import { AppContext } from "../../../../context/context";

import { optionsType } from "../../../../utils/Form.types";
import { VoucherQueryTypes } from "../../common/Enum.types";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";
import {
  GridAlignment,
  GridCellParams,
  GridColDef,
  GridPinnedColumnFields,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  AMOUNT_TEXT_ALIGN,
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../../styles/DataGridTableStyles";
import {
  ExportAcctStdtoExcelData,
  ExportAcctStdtoExcelVars,
} from "../../../Master/Reports/InstitutionLevel/useExportMutation";
import { ExportAcctStdtoExcel } from "../../../../queries/xls";
import { getDownloadUrl } from "../../../../utils/DownloadFile";
import { SortBy } from "../../../Master/Reports/InstitutionLevel/GlobalStates/types";
import MessageModal from "../../../../pages/MessageModal";
import LoadingModal from "../../../../pages/LoadingModal";
import dayjs from "dayjs";
type LedgerTotal = Record<number, number>;
interface Props {
  pageType: PageFor;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

const BankAndCashReport = ({ pageType, setModalFlag }: Props) => {
  const { format } = formatter;
  const { token } = useToken();
  const { InstId } = useParams();

  const { dispatch, state } = useContext(AppContext);
  const navigate = useNavigate();

  const [filter, setFilter] = useState("");
  const [adjustmentFlag, setAdjustmentFlag] = useState(false);
  const [v_no, setV_no] = useState("");
  const [studentDetails, setStudentDetails] = useState<voucherMasterDetails[]>(
    []
  );
  const [message, setMessage] = useState(emptyMessageType);

  const [ExportAcct, { loading: exportLoading }] = useLazyQuery<
    ExportAcctStdtoExcelData,
    ExportAcctStdtoExcelVars
  >(ExportAcctStdtoExcel);

  const [passedOutStudentDetails, setPassedStudentDetails] = useState<
    voucherMasterDetails[]
  >([]);

  const [enquiryStudentDetails, setEnquiryStudentDetails] = useState<
    voucherMasterDetails[]
  >([]);

  const [vouchers, setVouchers] = useState<GetAcctVouchersEdges[]>([]);
  const [rows, setRows] = useState<GridValidRowModel[]>([]);

  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [startDate, setStartDate] = useState(
    toInputStandardDate(dayjs(TODAY_DATE).toString())
  );
  const [endDate, setEndDate] = useState(toInputStandardDate(TODAY_DATE));
  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newStartDate = e.target.value;
    const maxEndDate = dayjs(newStartDate).add(3, "month");

    if (dayjs(endDate).isAfter(maxEndDate)) {
      alert("End date cannot be more than 3 months after the start date.");
    } else {
      setStartDate(newStartDate);
    }
  };
  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEndDate = e.target.value;
    const minStartDate = dayjs(newEndDate).subtract(3, "month");

    if (dayjs(startDate).isBefore(minStartDate)) {
      alert("Start date cannot be more than 3 months before the end date.");
    } else {
      setEndDate(newEndDate);
    }
  };

  const [studentReceiptModal, setStudentReceiptModal] = useState(false);

  const [GetStudentDetails] = useLazyQuery<VouchernodeData, nodevars>(
    StudentDetailsByMasterVoucherId
  );
  const [GetPassedStudentDetails] = useLazyQuery<VouchernodeData, nodevars>(
    PassedOutStudentDetailsByMasterVoucherId
  );

  const [GetEnqStudentDetails] = useLazyQuery<VouchernodeData, nodevars>(
    EnquiryStudentDetailsByMasterVoucherId
  );

  const { VoucherDetails } = useAcctVoucherDetailsByDates(
    ROWS_PER_PAGE_30,
    endDate,
    startDate,
    filter === BankOrCash.CASH
      ? VoucherQueryTypes.STUDENT_ALL_RECEIPTS_WITHOUT_REFUNDS_ONLY_CASH
      : filter === BankOrCash.BANK
      ? VoucherQueryTypes.STUDENT_ALL_RECEIPTS_WITHOUT_REFUNDS_ONLY_BANK
      : adjustmentFlag
      ? VoucherQueryTypes.STUDENT_ALL_RECEIPTS_WITHOUT_REFUNDS_ONLY_ADJ
      : VoucherQueryTypes.STUDENT_ALL_RECEIPTS_WITHOUT_REFUNDS,
    v_no,
    null,
    filter
  );

  const { acctLedgers } = useAcctLedgerData(
    EMPTY_STRING,
    AcctLedgerQueryType.ACCT_LDGRS_BANK,
    ROWS_PER_PAGE
  );
  const { data, fetchMore, loading } = VoucherDetails;
  const hanldeExport = () => {
    try {
      ExportAcct({
        variables: {
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          input: {
            float_data: [],
            ids: [Number(InstId), 0],
            int_data: [],
            std_status: [],
            str_data: [],
          },
          voucher_input: {
            acct_ldgr_id: 0,
            vo_start_date: toIsoDate(startDate),
            vo_end_date: toIsoDate(endDate),
            vo_type: EMPTY_STRING,
            v_book_type: EMPTY_STRING,
          },
          inst_id: InstId!,
          parameters: [],
          query_type:
            filter === BankOrCash.CASH
              ? VoucherQueryTypes.STUDENT_ALL_RECEIPTS_DAILY_ONLY_CASH
              : filter === BankOrCash.BANK
              ? VoucherQueryTypes.STUDENT_ALL_RECEIPTS_DAILY_ONLY_BANK
              : adjustmentFlag
              ? VoucherQueryTypes.STUDENT_ALL_RECEIPTS_DAILY_ONLY_ADJ
              : VoucherQueryTypes.STUDENT_ALL_RECEIPTS_DAILY,
          sort_by: SortBy.STD_NAME,
          token,
        },
      }).then(async ({ data, error }) => {
        if (data && data.ExportAcctStdtoExcel) {
          const res = await getDownloadUrl(data.ExportAcctStdtoExcel, true);
          if (res) {
            setMessage({
              flag: true,
              message: "Downloading...",
              operation: Operation.CREATE,
            });
          }
        } else {
          setMessage({
            flag: true,
            message: "Failed downloading file",
            operation: Operation.NONE,
          });
        }

        if (error && error.message) {
          setMessage({
            flag: true,
            message: error.message,
            operation: Operation.NONE,
          });
        }
      });
    } catch (e) {
      setMessage({
        flag: true,
        message: "Failed to download",
        operation: Operation.NONE,
      });
    }
  };

  const bankTotals: LedgerTotal = acctLedgers.data
    ? acctLedgers.data.GetAcctLdgrs.edges.reduce((acc, banks) => {
        const debitData = vouchers
          ?.flatMap((edge) => edge.node.acct_voucher_details)
          .filter(
            (d) =>
              d.vo_cr_db === DebitOrCredit.DEBIT &&
              d.acct_ldgr.id === banks.node.id
          );
        if (debitData && debitData.length > 0) {
          const ledgerID = banks.node.id;
          acc[ledgerID] = debitData.reduce(
            (sum, ban) => sum + ban.vo_db_total,
            0
          );
        }

        return acc;
      }, {} as LedgerTotal)
    : {};

  const totalCash =
    (vouchers
      ?.filter(
        (edge) => edge.node.v_transcation_cash_or_bank === BankOrCash.CASH
      )
      .reduce((sum, edge) => sum + edge.node.v_total_db_amt, 0) as number) || 0;
  const totalAdjustment =
    (vouchers
      ?.filter(
        (edge) => edge.node.v_transcation_cash_or_bank === BankOrCash.ADJ
      )
      .reduce((sum, edge) => sum + edge.node.v_total_db_amt, 0) as number) || 0;

  const filteredStudentsReceipts = filterVouchersByType(
    ReceiptTypes.STUDENT_RECEIPT,
    VoucherDetails.data!
  );
  const filteredPassedStudentReceipts = VoucherDetails.data
    ? VoucherDetails.data.GetAcctVouchers.edges.filter(
        ({ node }) =>
          node.v_type === ReceiptTypes.STUDENT_RECEIPT &&
          node.v_std_passout_receipt
      )
    : [];

  const filteredEnquiryStudentReceipts = VoucherDetails.data
    ? VoucherDetails.data.GetAcctVouchers.edges.filter(
        ({ node }) =>
          node.v_type === ReceiptTypes.STUDENT_RECEIPT && node.v_std_enquiry
      )
    : [];
  const FetchStudentDetails = (
    voucher: voucherMasterDetails,
    type: studentData
  ) => {
    const student =
      studentDetails &&
      studentDetails.find((studentDetail) => studentDetail.id === voucher.id);
    const enqStudent = enquiryStudentDetails.find(
      (enqStudentDetails) => enqStudentDetails.id === voucher.id
    );
    const passedOutStudent =
      passedOutStudentDetails &&
      passedOutStudentDetails.find(
        (passedOutStudentDetails) => passedOutStudentDetails.id === voucher.id
      );

    if (
      student &&
      voucher.student_id &&
      voucher.v_std_enquiry === false &&
      voucher.v_std_passout_receipt === false
    ) {
      switch (type) {
        case studentData.name:
          return (
            student.mst_student.first_name +
            " " +
            student.mst_student.middle_name +
            " " +
            student.mst_student.last_name +
            " " +
            `(${voucher.branch_details.branch_desc} / ${voucher.class_details.class_desc} / ${student.acct_voucher_details[0].acct_ldgr.ldgr_desc})`
          );
        case studentData.admNo:
          return student.mst_student.std_adm_no!;
        case studentData.parent:
          return student.mst_student.std_father_name!;
        case studentData.reg:
          return student.mst_student.std_reg_no!;
        case studentData.id:
          return student.mst_student.id!;
        default:
          break;
      }
    }
    if (
      enqStudent &&
      voucher.student_id === 0 &&
      voucher.v_std_enquiry &&
      voucher.v_std_passout_receipt === false
    ) {
      switch (type) {
        case studentData.name:
          return (
            enqStudent.enq_std_details.first_name +
            " " +
            enqStudent.enq_std_details.middle_name +
            " " +
            enqStudent.enq_std_details.last_name +
            " " +
            `(${voucher.branch_details.branch_desc} / ${voucher.class_details.class_desc} / ${enqStudent.acct_voucher_details[0].acct_ldgr.ldgr_desc})`
          );
        case studentData.admNo:
          return "-";
        case studentData.parent:
          return enqStudent.enq_std_details.std_father_name!;
        case studentData.reg:
          return "-";
        case studentData.id:
          return enqStudent.enq_std_details.id!;
        default:
          break;
      }
    }
    if (
      passedOutStudent &&
      voucher.student_id === 0 &&
      voucher.v_std_enquiry === false &&
      voucher.v_std_passout_receipt
    ) {
      switch (type) {
        case studentData.name:
          return (
            passedOutStudent?.passout_std_details.std_name +
            " " +
            `(${voucher.branch_details.branch_desc} / ${voucher.class_details.class_desc} / ${passedOutStudent?.acct_voucher_details[0].acct_ldgr.ldgr_desc})`
          );
        case studentData.admNo:
          return "-";
        case studentData.parent:
          return "-";
        case studentData.reg:
          return "-";
        case studentData.id:
          return passedOutStudent?.passout_std_details.id!;
        default:
          break;
      }
    }
  };

  const showBank =
    (filter === BankOrCash.BANK || filter === EMPTY_STRING) && !adjustmentFlag;
  const showCash =
    (filter === BankOrCash.CASH || filter === EMPTY_STRING) && !adjustmentFlag;

  const fieldName = (header_name: string) => {
    const convertToSmallCharacters = header_name.toLocaleLowerCase();
    //convert to camel case
    return convertToSmallCharacters.replaceAll(" ", "_");
  };

  const bankLedgers = acctLedgers.responseType.map((acct_ledger) => ({
    headerName: acct_ledger.label,
    field: fieldName(acct_ledger.label),
    align: AMOUNT_TEXT_ALIGN,
    show: true,
  }));

  const headers = [
    {
      headerName: "Date",
      field: "date",
      align: "center",
      show: true,
    },
    {
      headerName: "Receipt.No",
      field: "receipt_no",
      align: "center",
      show: true,
    },
    {
      headerName: "Admission Number",
      field: "adm_no",
      align: "center",
      show: true,
    },
    {
      headerName: "Register Number",

      field: "reg_no",
      align: "center",
      show: true,
    },
    {
      headerName: "Student Name",
      field: "name",
      align: "center",
      show: true,
      flex: 1,
      hideable: false,
    },
    {
      headerName: "Received From",

      field: "received_from",
      align: AMOUNT_TEXT_ALIGN,
      show: true,
    },
    {
      headerName: "Adjustment",

      field: "adjustment",
      align: AMOUNT_TEXT_ALIGN,
      show: filter === EMPTY_STRING,
    },
    {
      headerName: "Cash",

      field: "cash",
      align: AMOUNT_TEXT_ALIGN,
      show: showCash,
    },
    ...(showBank ? bankLedgers : []),
  ].filter((field) => field.show);

  const tableHeaders: GridColDef[] = headers.map((head) => ({
    headerName: head.headerName,
    field: head.field,
    headerAlign: "center" as GridAlignment,
    align: head.align as GridAlignment,
    type: "string",
    // @ts-ignore
    flex: head.flex,
    hideable: head.hideable,
  }));

  useEffect(() => {
    if (data && !loading && token) {
      const newData = data.GetAcctVouchers.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newVoucher) => {
          const filteredStudent = vouchers.find(
            (voucher) => voucher.node.id === newVoucher.node.id
          );
          if (filteredStudent) {
            return {
              ...newVoucher,
              node: {
                ...newVoucher.node,
              },
            };
          }
          return newVoucher;
        });
        setVouchers(updatedNewData);
      } else {
        setVouchers(newData);
      }
      setEndCursor(data.GetAcctVouchers.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading, token]);
  useEffect(() => {
    if (filteredStudentsReceipts?.length) {
      GetStudentDetails({
        variables: {
          token,
          ids: filteredStudentsReceipts?.map((data) => data.node.id)!,
        },
      }).then(({ data }) => {
        setStudentDetails(data?.nodes!);
      });
    } // eslint-disable-next-line
  }, [GetStudentDetails, filteredStudentsReceipts?.length, token]);

  useEffect(() => {
    if (filteredPassedStudentReceipts?.length) {
      GetPassedStudentDetails({
        variables: {
          token,
          ids: filteredPassedStudentReceipts?.map((data) => data.node.id)!,
        },
      }).then(({ data }) => {
        if (data && data.nodes) setPassedStudentDetails(data.nodes);
      });
    } // eslint-disable-next-line
  }, [GetPassedStudentDetails, filteredPassedStudentReceipts?.length, token]);
  useEffect(() => {
    if (filteredEnquiryStudentReceipts.length) {
      GetEnqStudentDetails({
        variables: {
          token,
          ids: filteredEnquiryStudentReceipts.map((data) => data.node.id)!,
        },
      }).then(({ data }) => {
        if (data && data.nodes) setEnquiryStudentDetails(data.nodes);
      });
    } // eslint-disable-next-line
  }, [GetEnqStudentDetails, filteredEnquiryStudentReceipts.length, token]);
  const handleViewReceiptDetails = (node: { [key: string]: number }) => {
    dispatch({
      type: payloadTypes.SET_RECEPIT_ID,
      payload: {
        receiptId: node.v_id,
      },
    });

    if (node.v_std_receipt && node.student_id) {
      dispatch({
        type: payloadTypes.SET_ALUMINI_STUDENT_ID,
        payload: {
          aluminiStudentId: 0,
        },
      });
      dispatch({
        type: payloadTypes.SET_STD_ENQUIRY_ID,
        payload: {
          studentEnquiryId: 0,
        },
      });
      dispatch({
        type: payloadTypes.SET_STUDENT_ID,
        payload: {
          studentId: node.student_id,
        },
      });
    }

    if (node.v_std_passout_receipt && node.passout_student_id) {
      dispatch({
        type: payloadTypes.SET_STUDENT_ID,
        payload: {
          studentId: 0,
        },
      });
      dispatch({
        type: payloadTypes.SET_STD_ENQUIRY_ID,
        payload: {
          studentEnquiryId: 0,
        },
      });
      dispatch({
        type: payloadTypes.SET_ALUMINI_STUDENT_ID,
        payload: {
          aluminiStudentId: node.passout_student_id,
        },
      });
    }

    if (node.v_std_enquiry && node.enquiry_student_id) {
      dispatch({
        type: payloadTypes.SET_STUDENT_ID,
        payload: {
          studentId: 0,
        },
      });
      dispatch({
        type: payloadTypes.SET_ALUMINI_STUDENT_ID,
        payload: {
          aluminiStudentId: 0,
        },
      });
      dispatch({
        type: payloadTypes.SET_STD_ENQUIRY_ID,
        payload: {
          studentEnquiryId: node.enquiry_student_id,
        },
      });
    }
    setStudentReceiptModal(!studentReceiptModal);
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: TableHeaders.SLNO,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-sl-no",
      align: SLNO_TEXT_ALIGN,
    },
    ...tableHeaders,
  ];

  const createRowList = (
    node: voucherMasterDetails,
    index: number,
    ledgerFields: {
      [key: string]: string;
    } | null
  ) => {
    const row = {
      id: index + 1,
      date: toStandardDate(node.v_date),
      receipt_no: node.v_no,
      adm_no: FetchStudentDetails(node, studentData.admNo),
      reg_no: FetchStudentDetails(node, studentData.reg),
      name: FetchStudentDetails(node, studentData.name),
      received_from:
        node.v_transcation_cash_or_bank === BankOrCash.BANK
          ? `${node.v_transcation_type} - ${node.v_transcation_no}`
          : node.v_transcation_type,
      ...(filter === EMPTY_STRING
        ? {
            adjustment:
              node.v_transcation_cash_or_bank === BankOrCash.ADJ
                ? format(node.v_total_db_amt)
                : "-",
          }
        : {}),
      ...(showCash
        ? {
            cash:
              node.v_transcation_cash_or_bank === BankOrCash.BANK ||
              node.v_transcation_cash_or_bank === BankOrCash.ADJ
                ? "-"
                : format(node.v_total_db_amt),
          }
        : {}),
      v_id: node.id,
      student_id: node.student_id,
      v_std_receipt: node.v_std_receipt,
      v_std_passout_receipt: node.v_std_passout_receipt,
      passout_student_id: node.passout_student_id,
      v_std_enquiry: node.v_std_enquiry,
      enquiry_student_id: node.enquiry_student_id,
    };

    return ledgerFields ? { ...row, ...ledgerFields } : row;
  };
  const getLedgerDetails = (
    node: voucherMasterDetails | null,
    isFooter: boolean
  ) => {
    var ledger_fields = {};

    if (acctLedgers.responseType.length && acctLedgers.data) {
      ledger_fields = acctLedgers.data.GetAcctLdgrs.edges.reduce(
        (acc, { node: subNode }) => {
          var value: number = 0;
          if (isFooter) {
            value = bankTotals[subNode.id];
          } else {
            if (node) {
              const foundLedger = node.acct_voucher_details.find(
                (ban) =>
                  ban.vo_cr_db === DebitOrCredit.DEBIT &&
                  ban.acct_ldgr.id === subNode.id
              );
              value = foundLedger ? foundLedger.vo_db_total : 0;
            }
          }
          acc = {
            ...acc,
            [fieldName(subNode.ldgr_desc)]: isFooter
              ? format(value)
              : value
              ? format(value)
              : "-",
          };

          return acc;
        },
        {}
      );
    }

    return ledger_fields;
  };
  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetAcctVouchers.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newRow) => {
          const filteredStudent = vouchers.find(
            (row) => row.node.id === newRow.node.id
          );
          if (filteredStudent) {
            return {
              ...newRow,
              node: {
                ...newRow.node,
              },
            };
          }
          return newRow;
        });
        setVouchers(updatedNewData);
        const modifiedData = showBank
          ? updatedNewData.map(({ node }, index) => {
              const ledger_fields = getLedgerDetails(node, false);
              return createRowList(node, index, ledger_fields);
            })
          : updatedNewData.map(({ node }, index) => {
              return createRowList(node, index, null);
            });
        setRows(modifiedData);
      } else {
        setVouchers(newData);
        const modifiedData = showBank
          ? newData.map(({ node }, index) => {
              const ledger_fields = getLedgerDetails(node, false);
              return createRowList(node, index, ledger_fields);
            })
          : newData.map(({ node }, index) => {
              return createRowList(node, index, null);
            });
        setRows(modifiedData);
      }
      setEndCursor(data.GetAcctVouchers.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [
    data,
    loading,
    studentDetails,
    enquiryStudentDetails,
    passedOutStudentDetails,
  ]);
  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;

      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: FETCH_MORE_DATA,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetAcctVouchers.edges;
                const pageInfo = fetchMoreResult.GetAcctVouchers.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck = prevResult.GetAcctVouchers.edges
                  ? prevResult.GetAcctVouchers.edges.filter(
                      ({ node: { id } }) =>
                        newEdges.findIndex(
                          ({ node: { id: newId } }) => newId === id
                        ) !== -1
                    )
                  : [];

                if (duplicateCheck.length > 0) return prevResult;

                return {
                  GetAcctVouchers: {
                    edges: [...vouchers, ...newEdges],
                    pageInfo,
                    totalCount: data ? data.GetAcctVouchers.totalCount! : 0,
                  },
                };
              },
            });
          }
        }
      };

      if (scrollTable && rows.length)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    [rows]
  );
  const getFooter = () => {
    const row = {
      id: "",
      date: "",
      receipt_no: "",
      adm_no: "",
      reg_no: "",
      name: "",
      received_from: "Total",
      ...(filter === EMPTY_STRING && adjustmentFlag
        ? { adjustment: format(totalAdjustment) }
        : { adjustment: format(totalAdjustment) }),
      ...(showCash && !adjustmentFlag ? { cash: format(totalCash) } : {}),
    };
    const modifiedData = showBank
      ? {
          ...row,
          ...getLedgerDetails(null, true),
        }
      : row;

    return [modifiedData];
  };

  const pinnedRows = {
    bottom: getFooter(),
  };
  const getCellClassName = (params: GridCellParams) => {
    const pinnedRowIds = pinnedRows.bottom
      ? pinnedRows.bottom.map((row) => row.id)
      : [];

    const isPinnedRow = pinnedRowIds.includes(params.row.id);

    if (isPinnedRow) {
      switch (params.field) {
        case "received_from":
        case "id":
        case "date":
        case "receipt_no":
        case "adm_no":
        case "reg_no":
        case "name":
          return "hide-td";

        default:
          return "totalcount";
      }
    }

    return "";
  };

  const [pinnedColumns, setPinnedColumns] =
    React.useState<GridPinnedColumnFields>({
      left: ["id", "date", "receipt_no", "adm_no", "reg_no", "name"],
    });
  const handlePinnedColumnsChange = React.useCallback(
    (updatedPinnedColumns: GridPinnedColumnFields) => {
      setPinnedColumns(updatedPinnedColumns);
    },
    []
  );

  return (
    <>
      {pageType === PageFor.MODAL && <Title>Day Wise Collection Report</Title>}
      <div
        className={`feecollected${
          pageType === PageFor.MODAL ? "--modal" : ""
        }`}>
        {/* <div className="feecollected__tableblock">
          <List />
        </div> */}

        <div className="row g-0 feecollected__select-options">
          <div className="col-2">
            <Input
              id="search"
              type="text"
              placeholder="Search Receipt no"
              className="feecollected__reportblock--filter-options--search"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setV_no(e.target.value)
              }
              onKeyDown={handleFormEvent}
            />
          </div>

          <div className="col-2">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={filterOptions}
              openOnFocus
              autoHighlight
              onChange={(e, newValue) => {
                if (newValue) {
                  const data = newValue as optionsType;
                  if (data.value !== BankOrCash.ADJ) {
                    setFilter(data.value);
                    setAdjustmentFlag(false);
                    setHasNextPage(true);
                  } else {
                    setFilter(EMPTY_STRING);
                    setAdjustmentFlag(true);
                  }
                } else {
                  setFilter(EMPTY_STRING);
                  setAdjustmentFlag(false);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="outlined-uncontrolled"
                  label="Receipt Types"
                  autoFocus
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  fullWidth
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
          <div className="col">
            <Title variant="subtitle1">Day Wise Collection Report</Title>
          </div>

          <div className="col-3 feecollected__reportblock--date">
            <TextField
              label="From"
              type="date"
              value={startDate}
              onChange={handleStartDateChange}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
                input: {
                  inputProps: {
                    max: toInputStandardDate(TODAY_DATE),
                  },
                },
              }}
              className="feecollected__reportblock--textfield"
            />
            <TextField
              label="To"
              type="date"
              value={endDate}
              onChange={handleEndDateChange}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
                input: {
                  inputProps: {
                    max: toInputStandardDate(TODAY_DATE),
                  },
                },
              }}
              className="feecollected__reportblock--textfield"
            />
          </div>
        </div>

        <div className="feecollected__table">
          <StyledDatagrid
            columns={columns}
            rows={rows}
            onRowClick={(params) => {
              if (params.row.receipt_no) {
                handleViewReceiptDetails(params.row);
              }
            }}
            disableRowSelectionOnClick
            disableChildrenSorting
            rowHeight={TABLE_ROW_HEIGHT}
            pinnedRows={pinnedRows}
            getCellClassName={getCellClassName}
            hideFooter
            pinnedColumns={pinnedColumns}
            onPinnedColumnsChange={handlePinnedColumnsChange}
          />
        </div>
      </div>
      <div className="row g-0">
        <div className="col">
          <Button onClick={hanldeExport} mode="export" />

          {pageType === PageFor.GENERAL ? (
            <Button mode="back" onClick={() => navigate(-1)} />
          ) : (
            <Button mode="cancel" onClick={() => setModalFlag(false)} />
          )}
        </div>

        <div className="col-2 feecollected__total">
          <div className="student-total-count">
            Total Records :<b>{data?.GetAcctVouchers.totalCount!}</b>
          </div>
        </div>
      </div>

      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={studentReceiptModal}
        style={StudentModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentDemandList setModalFlag={setStudentReceiptModal} />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStudentReceiptModal(!studentReceiptModal)}
            />
          </div>
        </div>
      </Modal>
      <MessageModal
        handleClose={() => setMessage(emptyMessageType)}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
      <LoadingModal flag={exportLoading} />
    </>
  );
};

export default BankAndCashReport;
