import { TextField } from "@mui/material";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  formatter,
  GetNoOfDaysDelayed,
  toIsoDate,
} from "../../../../utils/UtilFunctions";
import { Button } from "../../../../stories/Button/Button";
import { msgType } from "../../../../utils/Form.types";
import {
  DebitOrCredit,
  InstitutionConfigurationTypes,
  Operation,
  YesNo,
} from "../../../../utils/Enum.types";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GetAcctStdDemandDetails } from "../../../../queries/students/list/byId";
import useStudentDemandDetails, {
  studentDemandDetailsData,
  studentDemandDetailsVars,
} from "../../../Accounts/hooks/useStudentDemandDetails";
import {
  DemandDetailsQueryType,
  LedgerType,
} from "../../../Accounts/common/QueryTypes";
import { AppContext } from "../../../../context/context";
import useToken from "../../../../customhooks/useToken";
import { EMPTY_STRING } from "../../../../utils/constants";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import {
  MakeAcctMakeIciciOnlinePaymentData,
  MakeAcctMakeIciciOnlinePaymentVars,
} from "../../../Accounts/StudentDetails/FeeReceipt/useOnlinePayment";
import { AcctMakeIciciOnlinePayment } from "../../../../queries/common";
import { useParams } from "react-router-dom";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import { Title } from "../../../../stories/Title/Title";
import loading from "../../../../images/Loading.gif";
import { Keys } from "../../../../utils/Enum.keys";
import { GlobalPageConfigData } from "../../../../Types/configtypes";
import { InstitutionAcctConfigurationTypes } from "../../../Accounts/common/Enum.types";
import { studentRecepit } from "../../../../Types/Accounting";
import useFineSlab from "../../../Accounts/hooks/useFineSlab";
import { FineType } from "../../../Accounts/Fines/Index";
interface Props {
  balance: number;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  setMessage?: React.Dispatch<React.SetStateAction<msgType>>;
}
const ConsolidatedAmountPay = ({
  balance,
  setModalFlag,
  setMessage,
}: Props) => {
  const { format } = formatter;
  const { token } = useToken();

  const [consolidatePayableAmount, setConsolidatePayableAmount] = useState(0);
  const { serverDate } = useServerDateandTime();
  const { state } = useContext(AppContext);
  const { InstId } = useParams();
  const [paymentProgress, setPaymentProgress] = useState(false);
  const saveButtonRef = useRef<HTMLButtonElement>(null);
  const payMode = "9";
  const { user_details } = useLoggedInUserDetails();
  const [totalFineAmt, setTotalFineAmt] = useState(0);

  const {
    configData: { data },
  } = useSwConfigData([
    InstitutionConfigurationTypes.PAYMENT_GATEWAY,
    InstitutionAcctConfigurationTypes.ENABLE_PARTIAL_STD_RECEIPT_PAYMENT,
    InstitutionAcctConfigurationTypes.ENABLE_FEE_FINE,
    InstitutionConfigurationTypes.MINIMUM_STUDENT_FEE,
  ]);
  const [items, setItems] = useState<studentRecepit[]>([]);
  const { StudentDemandDetails } = useStudentDemandDetails(
    DemandDetailsQueryType.DEMAND_DETAILS_GT_0,
    false,
    EMPTY_STRING
  );

  const { finesData } = useFineSlab();

  const getFine = (dueDate: string, totalAmount?: number) => {
    if (!finesData?.data?.GetAcctFineSlabByInstId?.length) return 0;

    const { GetAcctFineSlabByInstId } = finesData.data;
    const { acct_fine_type, roi, days, fine_amt, recurring } =
      GetAcctFineSlabByInstId[0];

    const fineAmount = parseFloat(fine_amt) || 0;
    const rateOfInterest = parseFloat(roi) || 0;

    const currentDate = new Date();
    const dueDateObj = new Date(dueDate);
    const noOfDaysDelayed = Math.max(
      0,
      Math.floor(
        (currentDate.getTime() - dueDateObj.getTime()) / (1000 * 60 * 60 * 24)
      )
    );

    if (noOfDaysDelayed > 0) {
      switch (acct_fine_type) {
        case FineType.PERCENTAGE: {
          if (!totalAmount) return 0;

          const interest = totalAmount * (rateOfInterest / 100);
          const dailyInterest = interest / days;
          const totalFine = dailyInterest * noOfDaysDelayed;

          return parseFloat(totalFine.toFixed(2));
        }

        case FineType.RECURRING: {
          if (recurring) {
            const periods = Math.ceil(noOfDaysDelayed / days);
            return fineAmount * periods;
          } else {
            return fineAmount;
          }
        }

        case FineType.PER_DAY: {
          return fineAmount * noOfDaysDelayed;
        }

        default:
          return fineAmount;
      }
    }

    return 0;
  };

  const due_date = StudentDemandDetails.data
    ? StudentDemandDetails.data.GetAcctStdDemandDetails.filter((demand) =>
        GetNoOfDaysDelayed(new Date(demand.fee_due_date!))
      )
    : [];

  const demandLedgersDayDelayed = StudentDemandDetails.data
    ? StudentDemandDetails.data.GetAcctStdDemandDetails.map((demand) => {
        return GetNoOfDaysDelayed(new Date(demand.fee_due_date!));
      })
    : [];

  const maxDelayedDays = demandLedgersDayDelayed.length
    ? Math.max(...demandLedgersDayDelayed)
    : 0;
  const totals = items.reduce(
    (acc, item) => {
      acc.totalOutStandingBalance += item.fee_ob;
      acc.totalDemandAmount += item.fee_demand;
      acc.totalconcession += item.fee_concession;
      acc.totalbalance += item.fee_bal;
      acc.totalPayable += item.fee_receivable;
      acc.totalpaidAmount += item.fee_received;
      acc.totalacademicAmount += item.rcpt_amt!;

      if (GetNoOfDaysDelayed(new Date(item.fee_due_date!))) {
        acc.totalFinFeeBalance += item.initialBalance;
      }

      return acc;
    },
    {
      totalOutStandingBalance: 0,
      totalDemandAmount: 0,
      totalconcession: 0,
      totalbalance: 0,
      totalPayable: 0,
      totalpaidAmount: 0,
      totalacademicAmount: 0,
      totalFinFeeBalance: 0,
    }
  );

  const waiveOffAmtMemoized = useMemo(() => {
    if (!finesData?.data?.GetAcctFineSlabByInstId?.length) return 0;

    const { GetAcctFineSlabByInstId } = finesData.data;
    const { acct_fine_type } = GetAcctFineSlabByInstId[0];

    switch (acct_fine_type) {
      case FineType.RECURRING:
      case FineType.PER_DAY:
        return items.reduce(
          (sum, item) => sum + getFine(item.fee_due_date!, item.initialBalance),
          0
        );

      case FineType.FLAT: {
        const overdueLedger = items.find((item) => {
          if (!item.fee_due_date) return false;
          const dueDate = new Date(item.fee_due_date);
          return dueDate < new Date();
        });

        if (overdueLedger) {
          return getFine(
            overdueLedger.fee_due_date!,
            overdueLedger.initialBalance
          );
        }

        return 0;
      }

      case FineType.PERCENTAGE:
        return items.reduce(
          (sum, item) =>
            sum +
            (item.fee_due_date?.length && item.initialBalance
              ? getFine(item.fee_due_date!, item.initialBalance)
              : 0),
          0
        );

      default:
        return 0;
    }
  }, [finesData.data, items]);

  useEffect(() => {
    if (items.length > 0 && state.studentId) {
      setTotalFineAmt(waiveOffAmtMemoized);
    }
  }, [items, state.studentId]);
  useEffect(() => {
    if (StudentDemandDetails.data) {
      const data = StudentDemandDetails.data;
      const studentFeeData: studentRecepit[] =
        data.GetAcctStdDemandDetails.filter((item) => item !== null).map(
          (item) => {
            return {
              fee_ob: item.fee_ob,
              fee_demand: item.fee_demand,
              fee_concession: item.fee_concession,
              fee_receivable: item.fee_receivable,
              fee_received: item.fee_received,
              fee_refunds: item.fee_refunds,
              fee_bal: item.fee_bal,
              v_no: "",
              fee_due_date: item.fee_due_date,
              acct_ldgr_id: item.acct_ldgr_id,
              id: item.id,
              cr_db: DebitOrCredit.CREDIT,
              recevied_amount: 0,
              newItem: YesNo.NO,
              legederType: LedgerType.GENERAL,
              rcpt_amt: 0,
              acct_ldgr_details: item?.acct_ldgr_details,
              initialBalance: item.fee_bal,
              student_id: item.student_id,
              // fine_amt:
              //   new Date(item?.fee_due_date!).getTime() <
              //   new Date(rcptDate).getTime()!
              //     ? getFine(GetNoOfDaysDelayed(new Date(item.fee_due_date!)))! *
              //       GetNoOfDaysDelayed(new Date(item.fee_due_date!))
              //     : 0,
              fine_amt: 0,
            };
          }
        );

      setItems(state.studentId ? studentFeeData : []);
    }

    // eslint-disable-next-line
  }, [
    StudentDemandDetails.data,
    // eslint-disable-next-line
    state.studentId,
  ]);

  const filterDataByConfigKey = (data: GlobalPageConfigData[]) => {
    let enablePaymentGateway;
    let defaultBank = 0;
    let selectedPaymentGateway = "";
    let encryptionKey = "";
    let PartialFee;
    let enableFine = false;
    let minimumStdFee = 0;
    if (data) {
      data.forEach((item) => {
        switch (item.config_key) {
          case InstitutionAcctConfigurationTypes.PAYMENT_GATEWAY:
            enablePaymentGateway = item.config_boolean_value;
            defaultBank = item.config_integer_value;
            selectedPaymentGateway = item.config_string_value;
            encryptionKey = item.config_string_value_2;
            break;
          case InstitutionAcctConfigurationTypes.ENABLE_PARTIAL_STD_RECEIPT_PAYMENT:
            PartialFee = item.config_boolean_value;
            break;
          case InstitutionAcctConfigurationTypes.ENABLE_FEE_FINE:
            enableFine = item.config_boolean_value;
            break;
          case InstitutionConfigurationTypes.MINIMUM_STUDENT_FEE:
            minimumStdFee = item.config_integer_value;
            break;
          default:
            break;
        }
      });
    }
    return {
      enablePaymentGateway,
      defaultBank,
      selectedPaymentGateway,
      encryptionKey,
      PartialFee,
      enableFine,
      minimumStdFee,
    };
  };
  const {
    defaultBank,
    enablePaymentGateway,
    encryptionKey,
    PartialFee,
    selectedPaymentGateway,
    enableFine,
    minimumStdFee,
  } = filterDataByConfigKey(data?.GetSwConfigVariables!);

  const [MakeAcctMakeIciciOnlinePayment] = useMutation<
    MakeAcctMakeIciciOnlinePaymentData,
    MakeAcctMakeIciciOnlinePaymentVars
  >(AcctMakeIciciOnlinePayment, {
    onError: (e) => {
      setMessage?.({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      });
      setPaymentProgress(false);
    },
  });

  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.MINIMUM_STUDENT_FEE
  );
  const [GetStudentDemandDetails] = useLazyQuery<
    studentDemandDetailsData,
    studentDemandDetailsVars
  >(GetAcctStdDemandDetails, {
    fetchPolicy: "network-only",
    onError: (e) => {
      setMessage?.({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      });
      setPaymentProgress(false);
    },
  });

  const handleSubmit = () => {
    if (consolidatePayableAmount > balance) {
      alert("Amount is greater than Balance amount");
      return;
    }

    if (PartialFee === false && consolidatePayableAmount !== balance) {
      alert("Amount cannot be partially paid");
      return;
    }
    if (consolidatePayableAmount <= balance && state.ActiveFinYr) {
      setPaymentProgress(true);
      GetStudentDemandDetails({
        variables: {
          is_capitation_fee: false,
          query_type: DemandDetailsQueryType.DEMAND_DETAILS_GT_0,
          student_id: state.studentId,
          token,
          fin_yr_id: state.ActiveFinYr.id,
          voucher_book_type: EMPTY_STRING,
        },
      }).then(({ data }) => {
        if (data && data.GetAcctStdDemandDetails) {
          const studentDemandDetails = data.GetAcctStdDemandDetails;
          let balanceConsolidatedAmt = consolidatePayableAmount;

          if (
            minimumStdFee !== 0 &&
            minimumStdFee > consolidatePayableAmount + totalFineAmt
          ) {
            setPaymentProgress(false);
            alert(`Minimimum of transaction is required ${minimumStdFee}`);
            return;
          }
          const input = studentDemandDetails.map(
            ({ acct_ldgr_details, fee_bal }, index) => {
              let itemAmt = 0;
              if (balanceConsolidatedAmt) {
                if (balanceConsolidatedAmt >= fee_bal) {
                  itemAmt = fee_bal;
                } else {
                  itemAmt = balanceConsolidatedAmt;
                }
              }

              balanceConsolidatedAmt -= itemAmt;
              return {
                vo_date: toIsoDate(serverDate),
                vo_cr_db: DebitOrCredit.CREDIT,
                vo_sl_no: index + 1,
                vo_cr: itemAmt,
                vo_db: 0,
                vo_cr_total: Math.round(
                  consolidatePayableAmount + totalFineAmt
                ),
                vo_db_total: 0,
                acct_ldgr_id: acct_ldgr_details.id,
              };
            }
          );

          if (consolidatePayableAmount > 0) {
            if (serverDate && defaultBank) {
              MakeAcctMakeIciciOnlinePayment({
                variables: {
                  token,
                  inst_id: InstId ? InstId : state.InstId.toString(),
                  user_details,
                  student_id: state.studentId,
                  transaction_amount: Math.round(
                    consolidatePayableAmount + totalFineAmt
                  ).toString(),
                  payment_mode: payMode,
                  mobile_payment: false,
                  v_std_amt_fine: Math.round(totalFineAmt),
                  input: input.filter(({ vo_cr }) => vo_cr),
                },
                fetchPolicy: "network-only",
              }).then(({ data }) => {
                if (data && data.AcctMakeIciciOnlinePayment) {
                  const {
                    encrypted_mandatory_fields,
                    encrypted_payment_mode,
                    encrypted_reference_no,
                    encrypted_return_url,
                    encrypted_sub_merchant_id,
                    encrypted_transaction_amount,
                  } = data.AcctMakeIciciOnlinePayment;

                  if (
                    encrypted_mandatory_fields &&
                    encrypted_payment_mode &&
                    encrypted_reference_no &&
                    encrypted_return_url &&
                    encrypted_sub_merchant_id &&
                    encrypted_transaction_amount &&
                    encryptionKey &&
                    enablePaymentGateway &&
                    selectedPaymentGateway
                  ) {
                    window.location.href = `https://eazypayuat.icicibank.com/EazyPG?merchantid=600343&mandatory%20fields=${encrypted_mandatory_fields}&optional%20fields=&returnurl=${encrypted_return_url}&Reference%20No=${encrypted_reference_no}&submerchantid=${encrypted_sub_merchant_id}&transaction%20amount=${encrypted_transaction_amount}&paymode=${encrypted_payment_mode}`;
                  }
                } else {
                  setPaymentProgress(false);
                }
              });
            } else {
              setPaymentProgress(false);
              if (serverDate === EMPTY_STRING) {
                setMessage?.({
                  flag: true,
                  message: "Invalid Today Date",
                  operation: Operation.NONE,
                });
              }
              if (defaultBank < 1) {
                setMessage?.({
                  flag: true,
                  message: "Invalid Bank Details",
                  operation: Operation.NONE,
                });
              }
            }
          } else {
            setPaymentProgress(false);
            setMessage?.({
              flag: true,
              message: "Amount not found",
              operation: Operation.NONE,
            });
          }
        } else {
          setPaymentProgress(false);
        }
      });
    }
  };

  return (
    <>
      <Title>
        {paymentProgress ? "Payment in progress" : "Enter Paying Amount"}
      </Title>
      <div className="consolidated-pay-modal">
        <span className="consolidated-pay-modal--balance">
          {paymentProgress ? "Total Amount Paying is" : "Balance Amount"}
        </span>
        <b
          className={`consolidated-pay-modal${
            paymentProgress ? "--paying" : "--balance"
          }-amt`}>
          ₹{format(balance)}
        </b>
        {enableFine ? (
          <>
            <span className="consolidated-pay-modal--balance">Fine Amount</span>
            <b
              className={`consolidated-pay-modal${
                paymentProgress ? "--paying" : "--balance"
              }-amt`}>
              ₹{Math.round(totalFineAmt)}
            </b>
          </>
        ) : null}

        <div className="row g-0 justify-content-center">
          {paymentProgress ? (
            <div className="loading-time">
              <span>Loading...</span>
              <br />
              <img src={loading} alt="loading" className="loading-gif" />
            </div>
          ) : (
            <div className="col-6">
              <TextField
                label="Enter Amount"
                className="consolidated-pay-modal--textfield"
                value={consolidatePayableAmount}
                onChange={(e) => {
                  if (Number(e.target.value) >= 0)
                    setConsolidatePayableAmount(Number(e.target.value));
                }}
                autoFocus
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    if (saveButtonRef.current) saveButtonRef.current.focus();
                  }
                }}
              />
            </div>
          )}
        </div>
      </div>
      <div className="consolidated-pay-modal--buttons">
        <Button onClick={handleSubmit} type="submit" buttonref={saveButtonRef}>
          Proceed to pay
        </Button>
        <Button
          mode="cancel"
          type="button"
          onClick={() => {
            setModalFlag(false);
          }}
        />
      </div>
    </>
  );
};

export default ConsolidatedAmountPay;
