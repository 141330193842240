import Institution from "../../../images/TotalInstitutions.svg";
import Input from "../../../stories/Input/Input";
import { Label } from "../../../stories/Label/Label";
import { instTotalStats } from "../../Accounts/Dashboard/types";
import { formatter } from "../../../utils/UtilFunctions";
import { useNavigate } from "react-router-dom";
import useAcctTableJson from "../../Accounts/json/useAcctTableJson";
import { TableHeaderProps } from "../../../utils/types";
import {
  GridAlignment,
  GridCellParams,
  GridColDef,
  GridPinnedColumnFields,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../styles/DataGridTableStyles";
import React from "react";

interface Props {
  instStats: instTotalStats[];
}
const Accounts = ({ instStats }: Props) => {
  const { format } = formatter;
  const navigate = useNavigate();
  const { Accounts_Table } = useAcctTableJson();
  const {
    totalDemandAmount,
    totalReceived,
    totalbalance,
    totalBankBalance,
    totalCashBalance,
    totalBalance,
  } = instStats
    .filter((d) => d !== null)
    .reduce(
      (acc, item) => {
        acc.totalDemandAmount += item.total_demand;
        acc.totalbalance += item.total_demand_balance;
        acc.totalReceived += item.total_received!;

        acc.totalBankBalance += item.fee_collection_bank;
        acc.totalCashBalance += item.fee_collection_cash;
        acc.totalBalance += item.total_fee_collection;
        return acc;
      },
      {
        totalDemandAmount: 0,
        totalReceived: 0,
        totalbalance: 0,

        totalBankBalance: 0,
        totalCashBalance: 0,
        totalBalance: 0,
      }
    );

  const dynamicHeaders: TableHeaderProps[] =
    Accounts_Table.ProprietorDashboard.Table_Headers.map((th) => ({
      headerName: th.headerName,
      field: th.field,
      headerAlign: "center" as GridAlignment,
      align: th.align,
      flex: th.flex,
      hideable: th.hideable,
    }));
  const columns: GridColDef[] = [...dynamicHeaders];
  const rows: GridValidRowModel[] =
    instStats
      .filter((d) => d !== null)
      .map((inst, index) => {
        return {
          id: index + 1,
          inst_name: inst.inst_name,
          inst_id: inst.inst_id,
          bank_balance:
            inst.fee_collection_bank < 0
              ? `(${format(Math.abs(inst.fee_collection_bank))})`
              : format(inst.fee_collection_bank),
          cash_balance:
            inst.fee_collection_cash < 0
              ? `(${format(Math.abs(inst.fee_collection_cash))})`
              : format(inst.fee_collection_cash),
          total_collection:
            inst.total_fee_collection < 0
              ? `(${format(Math.abs(inst.total_fee_collection))})`
              : format(inst.total_fee_collection),
          demand: format(inst.total_demand),
          received: format(inst.total_received),
          demand_balance: format(inst.total_demand_balance),
        };
      }) || [];

  const getRow = (): Record<string, string>[] => {
    const row: Record<string, string> = {
      id: "",
      inst_name: "Total",
      bank_balance:
        totalBankBalance < 0
          ? `(${format(Math.abs(totalBankBalance))})`
          : format(totalBankBalance),
      cash_balance:
        totalCashBalance < 0
          ? `(${format(Math.abs(totalCashBalance))})`
          : format(totalCashBalance),
      total_collection:
        totalBalance < 0
          ? `(${format(Math.abs(totalBalance))})`
          : format(totalBalance),
      demand: format(totalDemandAmount),
      received: format(totalReceived),
      demand_balance: format(totalbalance),
    };

    return [row];
  };

  const pinnedRows = {
    bottom: getRow(),
  };
  const getCellClassName = (params: GridCellParams) => {
    const pinnedRowIds = pinnedRows.bottom
      ? pinnedRows.bottom.map((row) => row.id)
      : [];

    const isPinnedRow = pinnedRowIds.includes(params.row.id);

    if (isPinnedRow) {
      switch (params.field) {
        case "inst_name":
          return "total";

        case "id":
          return "total";
        default:
          return "totalcount";
      }
    }

    return "";
  };

  const [pinnedColumns, setPinnedColumns] =
    React.useState<GridPinnedColumnFields>({
      right: ["demand", "received", "demand_balance"],
    });
  const handlePinnedColumnsChange = React.useCallback(
    (updatedPinnedColumns: GridPinnedColumnFields) => {
      setPinnedColumns(updatedPinnedColumns);
    },
    []
  );
  return (
    <>
      <div className="proprietor-dashboard">
        <div className="row g-0 proprietor-dashboard__row">
          <div className="col-2">
            <Input id="search" placeholder="Search" />
          </div>
          <div className="col"></div>
          <div className="col-3 proprietor-dashboard__row--flex">
            <div className="proprietor-dashboard__row--total">
              <img src={Institution} alt="/" />
              <Label>Total Institution</Label>
              <Label variant="LabelBold">
                {instStats.filter((d) => d !== null).length}
              </Label>
            </div>
          </div>
        </div>
        <div className="proprietor-dashboard__tableblock">
          <StyledDatagrid
            columns={columns}
            rows={rows}
            onCellClick={(params) => {
              if (
                params.field === "inst_name" &&
                params.row.inst_id !== undefined
              ) {
                navigate(`/${params.row.inst_id}/accounts`);
              }
            }}
            disableRowSelectionOnClick
            disableChildrenSorting
            rowHeight={TABLE_ROW_HEIGHT}
            pinnedRows={pinnedRows}
            getCellClassName={getCellClassName}
            hideFooter
            pinnedColumns={pinnedColumns}
            onPinnedColumnsChange={handlePinnedColumnsChange}
          />
        </div>
      </div>
    </>
  );
};

export default Accounts;
