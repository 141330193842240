import * as Yup from "yup";

export const Number_Regex = /^\d+$/;
export const MobileNumber_Regex = /^[\d+]+$/;
export const PassOutYearRegex = /^[-/\d]+$/;
export const AluminiPassOutYearRegex = /^(\d{4}[./-](\d{2}|\d{4}))|^(\d{4})$/;
export const Decimal_Regex = /^\d*\.?\d*$/;
export const TelNos_Regex = /^[^-\s][\d\s,/,-]*$/;
export const AllowSpecial_Regex =
  /^[^-\s][a-zA-Z0-9\s~@#$^*()_+=/[\]{}|\\,.?: -]*$/;
export const noSpaceAndNoFirst_Number_Regex = /^[^-\s0-9][a-zA-Z0-9_\s-]*$/;
export const noSpace_Regex = /^[^-\s][a-zA-Z0-9_\s-]*$/;

export const noSpace_Regex_includes_dot =
  /^[^-\s][a-zA-Z0-9_.~@#$^*()_+'/&=\s-]*$/;
export const Url_Regex =
  /(^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w[a-zA-Z-_%@?]+)*([^\w[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$|^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$)/;
export const AlphaNumeric_Regex =
  /^[^-\s~@#$^*()_+=/[\]{}|\\,.?: -][a-zA-Z0-9\s]*$/;
export const OnlyAlphabets =
  /^[^-\s0-9~@#$^*()_+-=/[\]{}|\\,.?: -][a-zA-Z\s]*$/;
export const IfscRegex = /^[A-Za-z]{4}[a-zA-Z0-9]{7}$/;
export const PAN_Regex = /[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/;
const GST_Regex =
  /^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}Z|z[0-9A-Za-z]{1}$/;
export const Adhar_Card_Regex = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
export const VoterId_Regex = /^[A-Z]{3}\d{7}$/;
export const DrivingLicense_Regex =
  /^(([A-Z]{2}[0-9]{2})|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/;
export const Passport_Regex = /^[A-PR-WYa-pr-wy][1-9]\d?\d{4}[1-9]$/;
export const PFNo_Regex = /^[A-Za-z]{2}\d{6}\d{3}$/;
export const ESI_Regex =
  /^[A-Z]{2}[0-9]{2} [A-Z]{1}[0-9]{4}[A-Z]{1} [0-9]{1}[Z]{1}[0-9]{1}$/;
export const _isPhone = {
  mask: /^[0-9]{10}$/,
};
export const _genderRegex = { mask: /^(male|female)$/i };
export const _isEmail = {
  // eslint-disable-next-line
  mask: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
};

export const customer_institution_validation = Yup.object({
  inst_phone: Yup.string().matches(TelNos_Regex),
  inst_name: Yup.string()
    .required("Name is required")
    .max(100, "Only 100 characters allowed"),
  inst_contact_person: Yup.string().matches(noSpaceAndNoFirst_Number_Regex),
  inst_mobile: Yup.string().min(10, "Invalid Mobile").matches(Number_Regex),
  inst_short_name: Yup.string()
    .max(10, "Only 10 characters allowed")
    .matches(noSpaceAndNoFirst_Number_Regex),
  inst_email: Yup.string().email(),
  inst_address: Yup.string().max(200),
  inst_place: Yup.string().matches(noSpaceAndNoFirst_Number_Regex),
  inst_state: Yup.string(),
  inst_url: Yup.string().matches(Url_Regex),

  inst_pin: Yup.string().max(6, "Invalid pin").matches(Number_Regex),
});
export const customer_validation = Yup.object({
  cust_name: Yup.string()
    .required("Name is required")
    .max(100, "Only 100 characters allowed"),
  cust_phone: Yup.string().matches(TelNos_Regex),
  cust_contact_person: Yup.string().matches(noSpaceAndNoFirst_Number_Regex),
  cust_mobile: Yup.string().min(10, "Invalid Mobile").matches(Number_Regex),
  cust_email: Yup.string().email(),
  cust_address: Yup.string().max(200),
  cust_url: Yup.string().matches(Url_Regex),
  cust_pin: Yup.string().max(6, "Invalid pin").matches(Number_Regex),
});

export const academic_validation = Yup.object({
  AcdYear: Yup.string().matches(AllowSpecial_Regex),
  startDate: Yup.date().required("Start date is required"),
  endDate: Yup.date()
    .required("End date is required")
    .min(Yup.ref("startDate"), "End date can't be before start date"),
});
export const payroll_academic_validation = Yup.object({
  payroll_yr: Yup.string().matches(AllowSpecial_Regex),
  payroll_st_date: Yup.date().required("Start date is required"),
  payroll_end_date: Yup.date()
    .required("End date is required")
    .min(Yup.ref("payroll_st_date"), "End date can't be before start date"),
});

export const finacial_validation = Yup.object({
  fynyear: Yup.string()
    .required("Financial year is required")
    .matches(AllowSpecial_Regex),
  startDate: Yup.date().required("Start date is required"),
});

export const category_validation = Yup.object({
  cat_desc: Yup.string()
    .required("Description is required")
    .matches(AllowSpecial_Regex),
});

export const studentValidation = Yup.object({
  first_name: Yup.string()
    .required("First Name is required")
    .matches(OnlyAlphabets),
  middle_name: Yup.string().matches(OnlyAlphabets),
  last_name: Yup.string().matches(OnlyAlphabets),
  father_name: Yup.string()
    .matches(OnlyAlphabets)
    .required("Father Name is required"),
  mother_name: Yup.string().matches(OnlyAlphabets),
  dob: Yup.string().required("Date of birth is required"),
  doa: Yup.string().required("Date of admission is required"),
});
export const updateBasicDetailsSchema = Yup.object({
  first_name: Yup.string()
    .required("Firstname is required")
    .matches(OnlyAlphabets),
  middle_name: Yup.string().matches(OnlyAlphabets),
  last_name: Yup.string().matches(OnlyAlphabets),
  father_name: Yup.string()
    .matches(OnlyAlphabets)
    .required("Father Name is required"),
});
export const studentAluminiValidation = Yup.object({
  std_name: Yup.string().required("Name is required").matches(OnlyAlphabets),
  std_passout_yr: Yup.string()
    .required("Pass out Year is required")
    .matches(AluminiPassOutYearRegex),
  std_email: Yup.string().email(),
  std_mobile: Yup.string().min(10, "Invalid mobile").matches(Number_Regex),
});

export const studentOtherDetailsSchema = Yup.object({
  std_phone: Yup.string().matches(TelNos_Regex),
  std_aadhar: Yup.string().max(12, "Invalid Aadhar").matches(Adhar_Card_Regex),
  std_pincode: Yup.string().max(6, "Invalid PinCode"),
  std_corr_phone: Yup.string().matches(TelNos_Regex),
  std_annual_income: Yup.string().matches(Number_Regex),
  std_sts_no: Yup.string().matches(AllowSpecial_Regex),
});
export const previousSchoolSchema = Yup.object({
  std_prev_tc_details: Yup.string(),
  std_prev_inst: Yup.string().matches(noSpaceAndNoFirst_Number_Regex),
  std_prev_studied_board: Yup.string().matches(noSpaceAndNoFirst_Number_Regex),
  std_prev_total_per: Yup.string()
    .max(5, "Invalid Percentage")
    .matches(Decimal_Regex, "Invalid Percentage"),
  std_prev_tech_total_per: Yup.string()
    .max(5, "Invalid Percentage")
    .matches(Decimal_Regex, "Invalid Percentage"),
  std_prev_10_std_marks: Yup.string()
    .max(4, "Invalid Marks")
    .matches(Number_Regex),
  std_prev_10_std_per: Yup.string()
    .max(5, "Invalid Percentage")
    .matches(Decimal_Regex, "Invalid Percentage"),
  std_prev_12_std_marks: Yup.string()
    .max(4, "Invalid Marks")
    .matches(Number_Regex),
  std_prev_12_std_per: Yup.string()
    .max(5, "Invalid Percentage")
    .matches(Decimal_Regex, "Invalid Percentage"),
  std_bank_acct: Yup.string().min(9).max(21).matches(Number_Regex),
  std_adm_cet_rank: Yup.string().matches(Number_Regex),
  std_bank_ifsc: Yup.string().matches(IfscRegex).max(11, "Invalid IFS"),
  std_prev_tech_total_mark: Yup.string().matches(Number_Regex),
  std_adm_cet_paid_amt: Yup.string().matches(Number_Regex),
});
export const parentStudentSchema = Yup.object({
  student_name: Yup.string().matches(noSpaceAndNoFirst_Number_Regex),
  father_name: Yup.string().matches(noSpaceAndNoFirst_Number_Regex),
  mother_name: Yup.string().matches(noSpaceAndNoFirst_Number_Regex),
  guardian_name: Yup.string().matches(noSpaceAndNoFirst_Number_Regex),
});
export const departmentValidation = Yup.object({
  dept_desc: Yup.string()
    .required("Description is required")
    .matches(noSpace_Regex_includes_dot),
});

export const branchValidation = Yup.object({
  branch_desc: Yup.string()
    .required("Description is required")
    .matches(noSpace_Regex_includes_dot),
  shortdescription: Yup.string(),
});
export const branchAcdValidation = Yup.object({});
export const classValidation = Yup.object({
  class_desc: Yup.string()
    .required("Description is required")
    .matches(noSpace_Regex_includes_dot),
  shortdescription: Yup.string(),
});
export const semValidation = Yup.object({
  sem_desc: Yup.string()
    .required("Description is required")
    .matches(noSpace_Regex_includes_dot),
  shortdescription: Yup.string(),
});
export const sectionValidation = Yup.object({
  section_desc: Yup.string()
    .required("Description is required")
    .matches(noSpace_Regex_includes_dot),
  shortdescription: Yup.string(),
});

export const eduateValidation = Yup.object({
  firstname: Yup.string()
    .required("Firstname is required")
    .matches(OnlyAlphabets),
  email: Yup.string().email().required("Email is required"),
  mobile: Yup.string()
    .max(10, "Invalid Mobile")
    .required("Mobile is required")
    .matches(Number_Regex, "Invalid Mobile number"),
  pincode: Yup.string().max(6, "Invalid pin").matches(Number_Regex),
});

export const parentValidation = Yup.object({
  first_name: Yup.string()
    .required("Parent Name is required")
    .matches(OnlyAlphabets),

  parent_pin: Yup.string().max(6, "Invalid pin").matches(Number_Regex),
  parent_aadhar: Yup.string()
    .max(12, "Invalid Aadhar")
    .matches(Adhar_Card_Regex),
  parent_type: Yup.string().required("Parent Type is Required"),
});
export const staffValidation = Yup.object({
  firstname: Yup.string()
    .required("Firstname is required")
    .matches(OnlyAlphabets),
  email: Yup.string().email().required("Email is required"),
  mobile: Yup.string()
    .min(10, "Invalid Mobile")
    .required("Mobile is required")
    .matches(Number_Regex, "Invalid Mobile number")
    .matches(Number_Regex),
  pin: Yup.string().max(6, "Invalid pin").matches(Number_Regex),
  stafftype: Yup.string().required("type is Required"),
});

export const groupLedgerValidation = Yup.object({
  groupname: Yup.string().required("Description Required"),
});

export const accountingLedgerValidation = Yup.object({
  groupname: Yup.string().required("Description required"),
  groupLedgerId: Yup.string(),
  accountholder: Yup.string().matches(OnlyAlphabets),
  ifscode: Yup.string().matches(IfscRegex),
  branch: Yup.string().matches(noSpaceAndNoFirst_Number_Regex),
  accountnumber: Yup.string()
    .matches(Number_Regex)
    .min(9, "Invalid Account Number")
    .max(21, "Invalid Account Number"),
  bankname: Yup.string().matches(AlphaNumeric_Regex),
  contactperson: Yup.string().matches(OnlyAlphabets),
  phonenumber: Yup.string().matches(TelNos_Regex),
  mobile: Yup.string().matches(Number_Regex),
  email: Yup.string().email(),
  pan: Yup.string().matches(PAN_Regex),
  aadharnumber: Yup.string().matches(Number_Regex),
  gstnumber: Yup.string().matches(GST_Regex),
});

export const feeLedgerValidation = Yup.object({
  feeLedgerName: Yup.string().required(),
  feetype: Yup.string().required(),
  ledgerId: Yup.string().required(),
  ob: Yup.number(),
});

export const feeDemandValidation = Yup.object({
  branchId: Yup.string().required(),
  classId: Yup.string().required(),
  categoryId: Yup.string().required(),
});

export const PredefineDataInputValidate = Yup.object({
  type: Yup.string(),
  indexno: Yup.string(),
  value1: Yup.string(),
  value2: Yup.string(),
  value3: Yup.string(),
  value4: Yup.string(),
});

export const SessionsValidation = Yup.object({
  session_desc: Yup.string().required("Session description is required"),
  start_time: Yup.string().required("Start Time is required"),
  end_time: Yup.string().required("End Time is required"),
});
//Libraries Validation
export const MediaCategoryValidation = Yup.object({
  media_desc: Yup.string()
    .required("Media Desc is required")
    .matches(noSpace_Regex),
  media_abbrevation: Yup.string()
    .required("Media abbrevation is required")
    .matches(noSpace_Regex),
});
export const MiscActivitiesValidation = Yup.object({
  activity_name: Yup.string()
    .required("Misc Desc is required")
    .matches(noSpace_Regex),
});
export const DepartmentValidation = Yup.object({
  dept_name: Yup.string()
    .required("Department Desc is required")
    .matches(noSpace_Regex),
});
export const BookCategoryValidation = Yup.object({
  category_desc: Yup.string()
    .required("Book Desc is required")
    .matches(noSpace_Regex),
  category_abbrevation: Yup.string()
    .required("Book abbrevation is required")
    .matches(noSpace_Regex),
});
export const LibrarySubjectValidation = Yup.object({
  subject_desc: Yup.string()
    .required("Subject Desc is required")
    .matches(noSpace_Regex),
  subject_classification: Yup.string()
    .required("Subject classification is required")
    .matches(noSpace_Regex),
});
export const LibraryLanguageValidation = Yup.object({
  language: Yup.string()
    .required("Language  is required")
    .matches(noSpace_Regex),
  language_short_desc: Yup.string()
    .required("Language Short Desc is required")
    .matches(noSpace_Regex),
});
export const AttributeValidation = Yup.object({
  book_attribute: Yup.string()
    .required("Book attribute  is required")
    .matches(noSpace_Regex),
});
export const ShelfValidation = Yup.object({
  lib_rack_no: Yup.string()
    .required("Shelf No  is required")
    .matches(noSpace_Regex),

  lib_rack_desc: Yup.string()
    .required("Shelf Desc is required")
    .matches(noSpace_Regex),
});
export const AuthorValidation = Yup.object({
  author_name: Yup.string()
    .required("Author name  is required")
    .matches(noSpace_Regex),

  author_phone: Yup.string().matches(TelNos_Regex, "Invalid phone number"),
  author_email_id: Yup.string().email(),
});
export const PublisherValidation = Yup.object({
  publisher_name: Yup.string()
    .required("Publisher name  is required")
    .matches(noSpace_Regex),

  publisher_phone: Yup.string().matches(TelNos_Regex, "Invalid phone number"),
  publisher_contact_person_tel: Yup.string().matches(
    TelNos_Regex,
    "Invalid phone number"
  ),
});
export const VendorValidation = Yup.object({
  vendor_name: Yup.string()
    .required("Vendor name  is required")
    .matches(noSpace_Regex),

  vendor_phone: Yup.string().matches(TelNos_Regex, "Invalid phone number"),
  vendor_contact_person_phone: Yup.string().matches(
    TelNos_Regex,
    "Invalid phone number"
  ),
  vendor_pan: Yup.string().matches(PAN_Regex, "Invalid PAN"),
  vendor_gst: Yup.string().matches(GST_Regex, "Invalid GST"),
});
export const DonarValidation = Yup.object({
  donor_name: Yup.string()
    .required("Donar name  is required")
    .matches(noSpace_Regex),

  donor_tel: Yup.string().matches(TelNos_Regex, "Invalid Telephone number"),
});

export const ClassificationValidation = Yup.object({
  classification_desc: Yup.string()
    .required("Classification Desc is required")
    .matches(noSpace_Regex),
  book_published_year: Yup.string().matches(Number_Regex),
  book_pages: Yup.string().matches(Number_Regex),
  book_volume: Yup.string().matches(Number_Regex),
  classification_no: Yup.string().required("Classification No. is required"),
  lib_book_category_id: Yup.string().required("Book category id. is required"),
  lib_book_media_id: Yup.string().required("Media id. is required"),
  lib_book_subject_id: Yup.string().required("Subject id. is required"),
  lib_book_publisher_id: Yup.string().required("Publisher id. is required"),
  lib_book_language_id: Yup.string().required("Langauge id. is required"),
  lib_book_attribute_id: Yup.string().required("Attribute id. is required"),
  lib_book_author_id: Yup.string().required("Author id. is required"),
});
export const KeywordValidation = Yup.object({
  keyword: Yup.string().required("Keyword is required").matches(noSpace_Regex),
});
export const BookMasterKeywordValidation = Yup.object({
  classificationId: Yup.string()
    .required("Book classification id is required")
    .matches(noSpace_Regex),
});
export const NewBookEntryValidation = Yup.object({
  book_access_no: Yup.string().required("Accession No is required"),
  pur_bill_no: Yup.string().required("Purchase Bill No is required"),
  pur_qty: Yup.string().required("No of Books purchased required"),
  // lib_book_isbn_id: Yup.string()
  //   .matches(Number_Regex, "Invalid ISBN")
  //   .max(10, "Invalid ISBN")
  //   .required("ISBIN required"),
  pur_date: Yup.string().required("Purchase date is required"),
  pur_value: Yup.string().required("Purchase Cost is required"),
  pur_actual_value: Yup.string().required("Actual Cost is required"),
  lib_book_classification_id: Yup.string().required(
    "Classification No is required"
  ),
  media: Yup.string().required("Book Media is required").matches(noSpace_Regex),
  book_category: Yup.string()
    .required("Category is required")
    .matches(noSpace_Regex),
  subject: Yup.string().required("Subject is required").matches(noSpace_Regex),
  publisher: Yup.string()
    .required("Publisher  is required")
    .matches(noSpace_Regex),
  language: Yup.string()
    .required("Langauge is required")
    .matches(noSpace_Regex),
  attribute: Yup.string()
    .required("Attribute  is required")
    .matches(noSpace_Regex),
  author: Yup.string().required("Author is required").matches(noSpace_Regex),
  lib_book_vendor_id: Yup.string()
    .required("Vendor  is required")
    .matches(noSpace_Regex),
});

//Library FineSlab
export const LibraryFineSlab = Yup.object({
  fineTitle: Yup.string().matches(noSpace_Regex),
});
//Library FineSlab
export const BookPurchaseMasterValidation = Yup.object({
  pur_bill_no: Yup.string().required("Bill no is required"),
  pur_total_books: Yup.string()
    .required("Number of Books required")
    .matches(Number_Regex),
  pur_date: Yup.string().required("Date is required"),
  pur_value: Yup.string()
    .required("Number of Books required")
    .matches(Number_Regex),
  lib_book_vendor_id: Yup.string().required("Vendors is required"),
});

export const LeaveLedgerValidation = Yup.object({
  leave_desc: Yup.string().required("Leave description is required"),
  leave_short_code: Yup.string().required("Leave Short Code is required"),
});
//Student Enquiry
export const StudentEnquiryValidation = Yup.object({
  first_name: Yup.string()
    .required("First name is required")
    .matches(OnlyAlphabets),
  middle_name: Yup.string().matches(OnlyAlphabets),
  last_name: Yup.string().matches(OnlyAlphabets),
  std_mother_name: Yup.string().matches(OnlyAlphabets),
  std_father_name: Yup.string()
    .required("Father name is required")
    .matches(OnlyAlphabets),
  // std_mobile: Yup.string()
  //   .min(10, "Invalid mobile")
  //   .required("Mobile is required")
  //   .matches(Number_Regex),
  counselled_by: Yup.string()
    .required("Counselled By is required")
    .matches(OnlyAlphabets),
});
export const StudentEnquiryValidationForQrcode = Yup.object({
  first_name: Yup.string()
    .required("First name is required")
    .matches(OnlyAlphabets),
  middle_name: Yup.string().matches(OnlyAlphabets),
  last_name: Yup.string().matches(OnlyAlphabets),
  std_mother_name: Yup.string().matches(OnlyAlphabets),
  std_father_name: Yup.string()
    .required("Father name is required")
    .matches(OnlyAlphabets),
  std_previous_school: Yup.string().required(
    "Previous Institution is required"
  ),
  std_previous_place: Yup.string()
    .required("Previous Place is required")
    .matches(OnlyAlphabets),
  // std_mobile: Yup.string()
  //   .min(10, "Invalid mobile")
  //   .required("Mobile is required")
  //   .matches(Number_Regex)
  //   .max(10, "Invalid mobile"),
});
export const PaymentVoucherValidation = Yup.object({
  payTo: Yup.string(),
  BillNo: Yup.string(),
  BillDate: Yup.string(),
});
//Transportation
export const RouteValidation = Yup.object({
  route_desc: Yup.string()
    .required("Route Desc is required")
    .matches(noSpace_Regex),
  route_st_date: Yup.string()
    .required("Route Start Date is required")
    .matches(noSpace_Regex),
});
//Eduate Module

export const EduateModuleValidation = Yup.object({
  Name: Yup.string().required("Module Name is required").matches(noSpace_Regex),
  Desc: Yup.string().required("Description is required").matches(noSpace_Regex),
});
export const ModuleConfigurationValidation = Yup.object({
  lic_type: Yup.string()
    .required("License Type is Required")
    .matches(noSpace_Regex),
  lic_exp_grace_period: Yup.string()
    .required("Grace Period is required")
    .matches(noSpace_Regex),
});

export const SwAcctLdgrValidation = Yup.object({
  ldgr_desc: Yup.string()
    .required("Ledger Description is Required")
    .matches(noSpace_Regex),
  gr_ldgr_desc: Yup.string().required("Group Ledger Description is Required"),
});

export const document_validation = Yup.object({
  document_name: Yup.string()
    .required("Description is required")
    .matches(OnlyAlphabets),
});
export const grade_validation = Yup.object({
  grade: Yup.string().required("Grade is required").matches(AllowSpecial_Regex),
  marks_max: Yup.number()
    .typeError("Maximum marks must be a number")
    .required("Maximum marks required")
    .positive("Maximum marks must be positive")
    .test(
      "is-decimal",
      "Maximum marks must be a valid decimal",
      (value) => value !== undefined && /^\d+(\.\d+)?$/.test(value.toString())
    ),
  marks_min: Yup.number()
    .typeError("Minimum marks must be a number")
    .required("Minimum marks required")
    .positive("Minimum marks must be positive")
    .test(
      "is-decimal",
      "Minimum marks must be a valid decimal",
      (value) => value !== undefined && /^\d+(\.\d+)?$/.test(value.toString())
    ),
});

export const report_card_validation = Yup.object({
  report_name: Yup.string()
    .required("Description is required")
    .matches(AllowSpecial_Regex),
});

export const updateAgentValidation = Yup.object({
  agent_address: Yup.string(),
  agent_city: Yup.string(),
  agent_state: Yup.string(),
  agent_country: Yup.string(),
  agent_mobile: Yup.string(),
  agent_email: Yup.string().email(),
  agent_pan_no: Yup.string().matches(PAN_Regex),
  agent_aadhar_no: Yup.string().matches(Adhar_Card_Regex),
  agent_gst_no: Yup.string().matches(GST_Regex),
  agent_bank_ac_no: Yup.string(),
  agent_bank_ifsc: Yup.string().matches(IfscRegex),
  agent_bank_name: Yup.string().matches(noSpaceAndNoFirst_Number_Regex),
  agent_bank_address: Yup.string(),
  agent_bank_city: Yup.string(),
  agent_bank_type: Yup.string(),
});
