import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useContext } from "react";
import { AppContext } from "../../../../../context/context";
import { TODAY_DATE } from "../../../../../utils/constants";
import { PrintReceiptProps } from "../Standard/FeeReceiptPrint";
import usePrintConfig from "../Standard/hooks/usePrintConfiguration";
import useStudentReceiptDetails from "../../../../Accounts/hooks/useStudentReceiptDetails";
import { BankOrCash, DebitOrCredit } from "../../../../../utils/Enum.types";

import { formatter, toStandardDate } from "../../../../../utils/UtilFunctions";
import usePassedOutStudentReceiptDetails from "../../../../Accounts/hooks/usePassedOutStudentReceiptDetails";
import { ToWords } from "to-words";
import useServerDateandTime from "../../../../Library/customHooks/useServerDateandTime";
import InstInfo, { InstDetailsPageType } from "../common/InstDetails";
import useStudentDatabyId from "../../../../../customhooks/useStudentDatabyId";
import StudentInfo from "../common/StudentDetails";
export interface Data {
  particular: string;
  amount: number;
}
const StandardReceiptTemplate = ({
  tableData,
  v_date,
  v_no,
  transaction_no,
  studentDetails,
}: PrintReceiptProps) => {
  const { state } = useContext(AppContext);
  const { format } = formatter;

  const toWords = new ToWords();
  const { serverDate } = useServerDateandTime();
  const { studentReceiptDetails } = useStudentReceiptDetails();
  const isNonDemandReceipt =
    studentReceiptDetails && studentReceiptDetails.v_std_non_demand_receipt;
  const { passedOutStudentReceiptDetails } =
    usePassedOutStudentReceiptDetails();

  const { studentData } = useStudentDatabyId();

  const filteredDebitTransactions = state.studentId
    ? studentReceiptDetails?.acct_voucher_details.filter(
        (c) => c.vo_cr_db === DebitOrCredit.DEBIT
      )
    : passedOutStudentReceiptDetails?.acct_voucher_details.filter(
        (c) => c.vo_cr_db === DebitOrCredit.DEBIT
      );

  const { totalacademicAmount } = tableData.reduce(
    (acc, item) => {
      acc.totalacademicAmount += item.amount;
      return acc;
    },
    {
      totalacademicAmount: 0,
    }
  );

  const {
    USE_CASH_DETAILS,
    USE_FEE_BALANCE,
    USE_PRINT_COMMON_TITLE,
    USE_ENABLE_COMMON_TITLE,
    USE_RECEIPT_FOOTER,
    USE_NARRATIONS,
    USE_RECEIPT_FOOTER_2,
    USE_TRANSACTION_NUMBER,
    USE_RECEIPT_TITLE,
  } = usePrintConfig();

  return (
    <div className={tableData.length > 5 ? "prs-a5-l" : "prs"}>
      <div className="prs__top">
        <InstInfo
          studentDetails={studentDetails}
          tableData={tableData}
          v_date={v_date}
          v_no={v_no}
          transaction_no={transaction_no}
          pageType={InstDetailsPageType.FEE_RECEIPT}
        />
        {USE_RECEIPT_TITLE ? (
          <b className="prs__top--receipt--name">{USE_RECEIPT_TITLE}</b>
        ) : null}

        <div className="prs__top--receipt">
          <div className="prs__top--receipt--number">
            <span>
              Receipt No : <b>{v_no}</b>
            </span>
          </div>

          <div className="prs__top--receipt--date">
            <span>Date : {toStandardDate(v_date ?? TODAY_DATE)}</span>
          </div>
        </div>
        <StudentInfo
          studentDetails={studentDetails}
          tableData={tableData}
          v_date={v_date}
          v_no={v_no}
          transaction_no={transaction_no}
          pageType={InstDetailsPageType.FEE_RECEIPT}
        />
      </div>
      <div className="prs__tableblock">
        <TableContainer className="prs__table">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className="prs__table--th-slno">Sl</TableCell>
                <TableCell className="prs__table--th-fee">
                  Particulars
                </TableCell>
                <TableCell>Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {USE_ENABLE_COMMON_TITLE &&
              (state.studentId || state.stdIdForChallan) &&
              !isNonDemandReceipt ? (
                <TableRow>
                  <TableCell id="td-center" className="prs__table--slno">
                    1
                  </TableCell>
                  <TableCell className="prs__table--fee">
                    {USE_PRINT_COMMON_TITLE}
                  </TableCell>
                  <TableCell id="td-right" className="prs__table--amount">
                    {format(totalacademicAmount)}
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {tableData &&
                    tableData.map((data, index) => {
                      return (
                        <TableRow key={index + 1}>
                          <TableCell
                            id="td-center"
                            className="prs__table--slno">
                            {index + 1}
                          </TableCell>
                          <TableCell className="prs__table--fee">
                            {data.particular}
                          </TableCell>
                          <TableCell
                            id="td-right"
                            className="prs__table--amount">
                            {format(data.amount)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="prs__table--total">
          <span className="prs__table--total--text">Total Paid</span>
          <div className="prs__table--amount">
            <span>{format(totalacademicAmount)}</span>
          </div>
        </div>
      </div>

      <div className="prs__bottom">
        <span className="prs__bottom--amount-in-words">
          ({toWords.convert(totalacademicAmount, { currency: true })})
        </span>
        {USE_FEE_BALANCE && state.studentId ? (
          <div className="prs__bottom--table-footer-balance">
            <span>Fee Balance (as on {toStandardDate(serverDate)}) : </span>
            <span className="prs__bottom--table-footer-balance-amount">
              {format(
                studentData.data?.nodes[0].acct_std_demand.std_demand_bal!
              )}
            </span>
          </div>
        ) : null}

        <div className="row g-0">
          <div className="col prs__bottom--payment-method">
            {USE_CASH_DETAILS ? (
              <b>
                {filteredDebitTransactions &&
                filteredDebitTransactions[0]?.acct_ldgr.ldgr_cash_bank_other ===
                  BankOrCash.CASH
                  ? filteredDebitTransactions[0].acct_ldgr.ldgr_desc
                  : ": " + state.studentId
                  ? studentReceiptDetails?.v_transcation_type
                  : passedOutStudentReceiptDetails?.v_transcation_type +
                    " (" +
                    state.studentId
                  ? studentReceiptDetails?.v_transcation_no
                  : passedOutStudentReceiptDetails?.v_transcation_no + ")"}
              </b>
            ) : null}

            {filteredDebitTransactions &&
            filteredDebitTransactions[0]?.acct_ldgr.ldgr_cash_bank_other ===
              BankOrCash.CASH ? null : (
              <>
                {USE_TRANSACTION_NUMBER && (
                  <div className="col">
                    <span>
                      Transaction No : <b>{transaction_no}</b>
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        {USE_NARRATIONS ? (
          <span className="prs__bottom--amount-in-words">
            Narration : {studentReceiptDetails?.v_transcation_narration}
          </span>
        ) : null}
        <div className="prs__bottom--signature">
          <b>Receiver's Signature</b>
        </div>
        {USE_RECEIPT_FOOTER && (
          <div className="prs__bottom--narration">
            <span>{USE_RECEIPT_FOOTER}</span>
          </div>
        )}
        {USE_RECEIPT_FOOTER_2 && (
          <div className="prs__bottom--narration">
            <span>{USE_RECEIPT_FOOTER_2}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default StandardReceiptTemplate;
