import useInstDetails from "../../../../customhooks/general/useInstDetails";
import useInstLogoDetails from "../../../../customhooks/useInstLogoDetails";
import { FileUploadParams } from "../../../../utils/Enum.types";
import useReportCardConfig from "../../../Academics/Reports/CompleteReports/ReportCards/hooks/useReportCardConfig";
import EduateLogo from "../../../../images/EduateLogo.svg";
import usePerTestReportConfig from "../hooks/usePerTestReportConfig";
const InstInformation = () => {
  const { InstFormData } = useInstDetails(1);

  const instLogoDetails = {
    LogoOrSign: useInstLogoDetails({ filetype: FileUploadParams.INST_LOGO })
      .LogoOrSign,
    PrincipalSign: useInstLogoDetails({
      filetype: FileUploadParams.PRINCIPAL_SIGNATURE
    }).LogoOrSign,
    Photo1: useInstLogoDetails({ filetype: FileUploadParams.PHOTO_1 })
      .LogoOrSign,
    Photo2: useInstLogoDetails({ filetype: FileUploadParams.PHOTO_2 })
      .LogoOrSign
  };
  const { LogoOrSign, Photo1, Photo2 } = instLogoDetails;

  const {
    instAddress,
    instLogo,
    instName,

    custName,

    header,
    affiliatedTo,
    photo1Config,
    photo2Config
  } = useReportCardConfig();


  const {
    instAddressPerTest,
    instLogoPerTest,
    instNamePerTest,
   
    custNamePerTest,
   
    photo1ConfigPerTest,
    photo2ConfigPerTest,
    titlePerTest,
    dobPerTest,
    affiliatedToPerTest,
    headerPerTest,
  } = usePerTestReportConfig();
  return (
    <>
      <div
        className="progress-report__inst--logos"
        style={
          photo1Config?.config_boolean_value &&
          photo2Config?.config_boolean_value
            ? { justifyContent: "space-between" }
            : photo1Config?.config_boolean_value ||
              photo2Config?.config_boolean_value
            ? { justifyContent: "space-between" }
            : { justifyContent: "center" }
        }
      >
        {photo1Config?.config_boolean_value === true &&
        photo2Config?.config_boolean_value === false ? (
          <>
            {instLogo?.config_boolean_value ? (
              <div className="progress-report__inst--logo">
                {LogoOrSign.defaultLogo ? (
                  <img
                    src={LogoOrSign.defaultLogo}
                    alt="/"
                    className="bgs-pn-pr__inst-details--logo"
                  />
                ) : (
                  <img
                    src={EduateLogo}
                    alt="/"
                    className="bgs-pn-pr__inst-details--logo"
                  />
                )}
              </div>
            ) : null}
          </>
        ) : (
          <>
            {photo1Config?.config_boolean_value && (
              <div className="progress-report__inst--logo">
                {Photo1.defaultLogo ? (
                  <img
                    src={Photo1.defaultLogo}
                    alt="/"
                    className="bgs-pn-pr__inst-details--logo"
                  />
                ) : (
                  <img
                    src={EduateLogo}
                    alt="/"
                    className="bgs-pn-pr__inst-details--logo"
                  />
                )}
              </div>
            )}
          </>
        )}

        {photo1Config?.config_boolean_value === false &&
          photo2Config?.config_boolean_value === true && (
            <>
              {instLogo?.config_boolean_value ? (
                <div className="progress-report__inst--logo">
                  {LogoOrSign.defaultLogo ? (
                    <img
                      src={LogoOrSign.defaultLogo}
                      alt="/"
                      className="bgs-pn-pr__inst-details--logo"
                    />
                  ) : (
                    <img
                      src={EduateLogo}
                      alt="/"
                      className="bgs-pn-pr__inst-details--logo"
                    />
                  )}
                </div>
              ) : null}
            </>
          )}
        <div>
          <div className="progress-report__inst--details">
            {header?.config_boolean_value ? (
              <span className="progress-report__inst--details--cust-name">
                {header.config_string_value}
              </span>
            ) : null}
            {(photo1Config?.config_boolean_value === false &&
              photo2Config?.config_boolean_value === true) ||
            (photo1Config?.config_boolean_value === true &&
              photo2Config?.config_boolean_value === false) ? (
              <></>
            ) : (
              <>
                {instLogo?.config_boolean_value ? (
                  <div className="progress-report__inst--logo">
                    {LogoOrSign.defaultLogo ? (
                      <img
                        src={LogoOrSign.defaultLogo}
                        alt="/"
                        className="bgs-pn-pr__inst-details--logo"
                      />
                    ) : (
                      <img
                        src={EduateLogo}
                        alt="/"
                        className="bgs-pn-pr__inst-details--logo"
                      />
                    )}
                  </div>
                ) : null}
              </>
            )}

            {custName?.config_boolean_value ? (
              <span className="progress-report__inst--details--cust-name">
                {InstFormData.cust_name}{" "}
              </span>
            ) : null}
            {instName?.config_boolean_value ? (
              <b className="progress-report__inst--details--inst-name">
                {InstFormData.inst_name_to_print}{" "}
              </b>
            ) : null}
            {affiliatedTo?.config_boolean_value ? (
              <span className="progress-report__inst--details--cust-name">
                {affiliatedTo.config_string_value}
              </span>
            ) : null}

            {instAddress?.config_boolean_value ? (
              <span className="progress-report__inst--details--inst-address">
                {[
                  InstFormData.inst_address,
                  InstFormData.inst_place,
                  InstFormData.inst_pin
                ]
                  .filter(Boolean)
                  .join(", ")
                  .trim()}
              </span>
            ) : null}
          </div>
        </div>
        {photo1Config?.config_boolean_value === true &&
          photo2Config?.config_boolean_value === false && (
            <>
              {photo1Config?.config_boolean_value && (
                <div className="progress-report__inst--logo">
                  {Photo1.defaultLogo ? (
                    <img
                      src={Photo1.defaultLogo}
                      alt="/"
                      className="bgs-pn-pr__inst-details--logo"
                    />
                  ) : (
                    <img
                      src={EduateLogo}
                      alt="/"
                      className="bgs-pn-pr__inst-details--logo"
                    />
                  )}
                </div>
              )}
            </>
          )}
        {photo2Config?.config_boolean_value ? (
          <div className="progress-report__inst--logo">
            {Photo2.defaultLogo ? (
              <img
                src={Photo2.defaultLogo}
                alt="/"
                className="bgs-pn-pr__inst-details--logo"
              />
            ) : null}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default InstInformation;
