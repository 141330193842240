import React, { useContext, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import { useMutation } from "@apollo/client";

import { msgType } from "../../utils/Form.types";
import { LicenseTypes, Operation } from "../../utils/Enum.types";
import useToken from "../../customhooks/useToken";
import { AppContext } from "../../context/context";
import useLoggedInUserDetails from "../Accounts/hooks/useLoggedInUserDetails";
import useInstModules, {
  InstModuleDetails,
} from "../UserRights/hooks/useInstModules";
import { toStandardCase } from "../../utils/UtilFunctions";
import { GetMstInstModules } from "../../queries/customerModules/query";
import { Title } from "../../stories/Title/Title";
import { AntSwitch } from "../../pages/Switch";
import { Button } from "../../stories/Button/Button";
import MessageModal from "../../pages/MessageModal";
import { UpdateMstInstModuleDetailsForMobile } from "../../queries/students/mutations/new";
import LoadingModal from "../../pages/LoadingModal";
import Navbar from "../../components/common/Navbar/Index";

const ModuleConfiguration = () => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { state } = useContext(AppContext);
  const navigate = useNavigate();
  const { user_details } = useLoggedInUserDetails();
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [inst_module_details, setInstModuleDetails] = useState<
    InstModuleDetails[]
  >([]);

  const { InstModuleData } = useInstModules();

  //Mutations

  const [UpdateModule, { loading: updationLoading }] = useMutation(
    UpdateMstInstModuleDetailsForMobile,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const handleSwitchToggle = (index: number) => {
    setInstModuleDetails((prevState) => {
      const updatedModuleDetails = prevState.map((module, idx) => {
        if (idx === index) {
          return {
            ...module,
            is_module_enabled_for_mobile: !module.is_module_enabled_for_mobile,
          };
        }
        return module;
      });
      return updatedModuleDetails;
    });
  };
  const HandleSubmit = () => {
    UpdateModule({
      variables: {
        token,
        inst_id: InstId || state.InstId,
        user_details,

        input: inst_module_details.map((module) => ({
          is_module_enabled_for_mobile: module.is_module_enabled_for_mobile,
          module_details_id: module.id,
        })),
      },
      refetchQueries: [
        {
          query: GetMstInstModules,
          variables: {
            token,
            inst_id: InstId || state.InstId,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: !message.flag,
          message: "Module Updated Successfully",
          operation: Operation.UPDATE,
        });
      }
    });
  };

  const handleBack = () => {
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  useEffect(() => {
    if (InstModuleData.data && !InstModuleData.loading) {
      const modules =
        InstModuleData.data.GetMstInstModules.inst_module_details.filter(
          (mod) => mod.is_module_enabled
        );

      setInstModuleDetails(modules);
    }
  }, [InstModuleData.data, InstModuleData.loading]);

  return (
    <>
      <Navbar NavType={LicenseTypes.INSTITUTION_CONFIG} />
      <Title>Module Configuration for Mobile Application</Title>

      <div className="eduate-module-config">
        <div className="eduate-module-config__tableblock">
          <TableContainer className="eduate-module__table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Sl No.</TableCell>
                  <TableCell>Modules Name</TableCell>
                  <TableCell>Module Enabled</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {inst_module_details.map((response, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell
                        className="eduate-module__table--slno"
                        id="td-center">
                        {index + 1}
                      </TableCell>
                      <TableCell>
                        {toStandardCase(response.eduate_module_details?.Name)}
                      </TableCell>
                      <TableCell
                        id="td-center"
                        className="eduate-module__table--actions">
                        <AntSwitch
                          checked={response.is_module_enabled_for_mobile}
                          onClick={() => handleSwitchToggle(index)}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <Button mode="save" onClick={HandleSubmit} />

        <Button mode="back" onClick={() => navigate(-1)} />
      </div>

      <LoadingModal flag={updationLoading} />

      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleBack}
        operation={message.operation!}
      />
    </>
  );
};

export default ModuleConfiguration;
