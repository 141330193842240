import React, { useEffect, useState } from "react";
import { Title } from "../../../../../stories/Title/Title";
import Close from "../../../../../images/Close.svg";
import VacationList from "../../../../../images/VacationList.svg";
import Input from "../../../../../stories/Input/Input";
import { Button } from "../../../../../stories/Button/Button";
import { Label } from "../../../../../stories/Label/Label";
import Compare from "../../../../../images/Compare.svg";
import { AcdYrType, FlagType } from "../Index";
import useAcdGeneralHolidays, {
  holiday_query_type,
} from "../../../hooks/useAcdGeneralHolidays";
import {
  MonthName,
  toIsoDate,
  toStandardDate,
} from "../../../../../utils/UtilFunctions";
import dayjs from "dayjs";
import { useMutation } from "@apollo/client";
import { DeleteAcdInstGeneralHolidayVacation } from "../../../queries/holidays/mutation";
import MessageModal from "../../../../../pages/MessageModal";
import { msgType } from "../../../../../utils/Form.types";
import { Operation } from "../../../../../utils/Enum.types";
import useToken from "../../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import { GetAcdInstGeneralHolidays } from "../../../queries/holidays/list";
import { EMPTY_STRING } from "../../../../../utils/constants";
import Delete from "../../../../../images/Delete.svg";
import useLoggedInUserDetails from "../../../../Accounts/hooks/useLoggedInUserDetails";
import DeleteModal from "../../../../../pages/DeleteModal";

interface Props {
  acdYr: AcdYrType;
  setDrawerFlag: React.Dispatch<React.SetStateAction<FlagType>>;
}
interface MapValue {
  startDate: string;
  endDate: string;
  montthString: string;
  id: number;
}
const Vacations = ({ acdYr, setDrawerFlag }: Props) => {
  const { user_details } = useLoggedInUserDetails();
  const [dateForDelete, setDateForDelete] = useState({
    startDate: "",
    endDate: "",
  });

  const [searchText, setSearchText] = useState("");
  const [message, setMessage] = useState<msgType>({
    flag: false,
    message: "",
    operation: Operation.NONE,
  });
  const { InstGeneralHolidays } = useAcdGeneralHolidays(
    acdYr ? acdYr.value : 0,
    acdYr.end_date.toString(),
    acdYr.start_date.toString(),
    searchText,
    holiday_query_type.VACATION_HOLIDAYS
  );
  const [holidaysMap, setHolidaysMap] = useState<Map<string, MapValue>>(
    new Map()
  );

  const [holidayDeleteModal, setHolidayDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const { token } = useToken();
  const { InstId } = useParams();
  const [DeleteByVacation] = useMutation(DeleteAcdInstGeneralHolidayVacation, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  useEffect(() => {
    if (InstGeneralHolidays.data && !InstGeneralHolidays.loading) {
      let convertToMap: Map<string, MapValue> = new Map();
      InstGeneralHolidays.data.GetAcdInstGeneralHolidays.edges.forEach(
        ({ node }) => {
          const alreadyExistingHolidays = convertToMap.get(node.holiday_desc);

          if (alreadyExistingHolidays) {
            let startDate = alreadyExistingHolidays.startDate;
            let endDate = alreadyExistingHolidays.endDate;
            if (dayjs(node.holiday_date).isBefore(startDate)) {
              startDate = node.holiday_date;
            }
            if (dayjs(node.holiday_date).isAfter(endDate)) {
              endDate = node.holiday_date;
            }
            convertToMap.set(node.holiday_desc, {
              endDate,
              startDate,
              montthString:
                MonthName(startDate) === MonthName(endDate)
                  ? `${MonthName(startDate)}`
                  : `${MonthName(startDate)} - ${MonthName(endDate)}`,
              id: node.id,
            });
          } else {
            convertToMap.set(node.holiday_desc, {
              startDate: node.holiday_date,
              endDate: node.holiday_date,
              montthString: `${MonthName(node.holiday_date)}`,
              id: node.id,
            });
          }
        }
      );
      setHolidaysMap(convertToMap);
    }
  }, [InstGeneralHolidays.data, InstGeneralHolidays.loading]);

  const HandleDelete = (id: number) => {
    DeleteByVacation({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        acd_yr_id: acdYr?.value,
        start_date: dateForDelete.startDate,
        end_date: dateForDelete.endDate,
      },
      refetchQueries: [
        {
          query: GetAcdInstGeneralHolidays,
          variables: {
            acd_yr_id: acdYr?.value,
            input: {
              end_date: dateForDelete.endDate,
              in_between_flag: true,
              start_date: dateForDelete.startDate!,
              holiday_query_type: holiday_query_type.ALL_HOLIDAYS,
            },
            inst_id: InstId,
            token,
            holidayDesc: EMPTY_STRING,
          },
        },
        {
          query: GetAcdInstGeneralHolidays,
          variables: {
            acd_yr_id: acdYr?.value,
            input: {
              end_date: dateForDelete.endDate!,
              in_between_flag: true,
              start_date: dateForDelete.startDate!,
              holiday_query_type:
                holiday_query_type.GENERAL_AND_UNEXPECTED_HOLIDAYS,
            },
            inst_id: InstId,
            token,
            holidayDesc: EMPTY_STRING,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setDrawerFlag((prev) => ({
          ...prev,
          flag: false,
        }));
        setMessage({
          flag: true,
          message: "Holiday Deleted Successfully",
          operation: Operation.DELETE,
        });
      }
    });
  };
  return (
    <>
      <div className="calendar-holiday-list">
        <div className="calendar-holiday-list--title">
          <Title variant="subtitle1">
            <img src={VacationList} alt="" /> &nbsp; All Vacations List
          </Title>
          <img
            src={Close}
            alt=""
            className="modal-close-icon"
            onClick={() =>
              setDrawerFlag((prev) => ({
                ...prev,
                flag: false,
              }))
            }
          />
        </div>
        <div className="row g-0 justify-content-center">
          <div className="col-5">
            <Input
              id="search"
              placeholder="Search"
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
        </div>
        <div className="calendar-holiday-list--data">
          <ul className="calendar-holiday-list--data--vacations-list">
            {Array.from(holidaysMap).map(([key, value], index) => {
              return (
                <React.Fragment key={index}>
                  <li className="calendar-holiday-list--data--month">
                    {value.montthString}
                  </li>

                  <li>
                    <Label>{index + 1}</Label>
                    <div className="calendar-holiday-list--data--vacations-list--events">
                      <div>
                        <span className="font-red">
                          {toStandardDate(value.startDate)}
                        </span>
                        &nbsp;
                        <img src={Compare} alt="" />
                        &nbsp;
                        <span className="font-red">
                          {toStandardDate(value.endDate)}
                        </span>
                        <br />
                        <b>{key}</b>
                      </div>
                    </div>
                    <img
                      src={Delete}
                      onClick={() => {
                        setDeleteId(value.id);
                        setDateForDelete({
                          startDate: value.startDate,
                          endDate: value.endDate,
                        });
                        setHolidayDeleteModal(!holidayDeleteModal);
                      }}
                      alt="/"
                    />
                  </li>
                </React.Fragment>
              );
            })}
          </ul>
        </div>
        <Button
          mode="cancel"
          onClick={() =>
            setDrawerFlag((prev) => ({
              ...prev,
              flag: false,
            }))
          }
        />
      </div>
      <DeleteModal
        modalFlag={holidayDeleteModal}
        setModalFlag={setHolidayDeleteModal}
        handleDelete={HandleDelete}
        id={deleteId}
      />
      <MessageModal
        handleClose={() =>
          setMessage({
            flag: false,
            message: "",
            operation: Operation.NONE,
          })
        }
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default Vacations;
