import Modal from "react-modal";
import { WaiveOffModalStyles } from "../styles/ModalStyles";
import { Button } from "../stories/Button/Button";
import Close from "../images/Close.svg";
import Warning from "../images/Warning.svg";
import { useState } from "react";
import Input from "../stories/Input/Input";
import { ACTIVE } from "../utils/constants";
import { Title } from "../stories/Title/Title";
interface Props {
  modalFlag: boolean;
  setModalFlag: (modalFlag: boolean) => void;
  id: number | number[];
  handleDelete?: (id: number) => void;
  handleMultipleDelete?: (ids: number[]) => void;
  children?: any;
}
const MakeFinYearActiveModal = ({
  modalFlag,
  setModalFlag,
  handleDelete,
  handleMultipleDelete,
  id,
  children,
}: Props) => {
  const [inputValue, setInputValue] = useState("");

  return (
    <>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={modalFlag}
        style={WaiveOffModalStyles}
        ariaHideApp={false}>
        <div className="delete-modal">
          <div className="delete-modal__title">
            <Title>
              <img src={Warning} alt="/" /> Are you sure?
            </Title>
            <img
              className="close-icon"
              src={Close}
              alt="/"
              onClick={() => {
                setInputValue("");
                setModalFlag(!modalFlag);
              }}
            />
          </div>
          <div className="delete-modal__body">
            <span>
              Do you wish to change the active Financial Year? All transactions
              will be recorded under the updated financial year
            </span>
            {/*Shiva:  br tag to be removed after css */}
            <br />
            <span>
              If yes, please type <b className="nodata">MAKE ACTIVE</b> to
              confirm.
            </span>
            <Input
              value={inputValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setInputValue(e.target.value);
              }}
            />
          </div>
          <Button
            autoFocus
            disabled={inputValue !== ACTIVE}
            onClick={() => {
              Array.isArray(id)
                ? handleMultipleDelete?.(id)
                : handleDelete?.(id);
              setInputValue("");
            }}
            mode="save"
          />
          <Button
            onClick={() => {
              setInputValue("");
              setModalFlag(!modalFlag);
            }}
            mode="cancel"
          />
        </div>
      </Modal>
      {children!}
    </>
  );
};

export default MakeFinYearActiveModal;
