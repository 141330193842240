import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useDropdownData from "../../../customhooks/useDropdownData";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import useInstMasterDataByInstId from "../../../customhooks/useInstMasterDataByInstId";
import { Button } from "../../../stories/Button/Button";
import Input from "../../../stories/Input/Input";
import { Title } from "../../../stories/Title/Title";
import { TableHeaderProps } from "../../../Types/Tables";
import {
  DEFAULT_TIME,
  EMPTY_STRING,
  emptyMessageType,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../utils/constants";
import { Keys } from "../../../utils/Enum.keys";
import {
  Operation,
  StudentAcctReportType,
  StudentStatus,
} from "../../../utils/Enum.types";
import { responseType } from "../../../utils/Form.types";
import {
  formatter,
  getModifiedScrollHeight,
  handleMUISelectEvent,
  isOptionEqualToValue,
  toIsoDate,
} from "../../../utils/UtilFunctions";
import Home from "../Home/Index";
import UseStudentsbyDemandAmount, {
  StudentDemandEdges,
} from "../hooks/useStudentsbyDemandAmount";
import StudentTotalCount from "../../Master/Student/Components/StudentTotalCount";
import useSummationDatabyNewApi from "../hooks/useSummationDatabyNewApi";
import useInstLabels from "../../../customhooks/general/useInstLabels";
import useAcctTableJson from "../json/useAcctTableJson";
import { AppContext } from "../../../context/context";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../styles/AutocompleteListStyles";
import InstitutionLevel from "../../../Modules/Master/Reports/InstitutionLevel/Index";
import { ExportAcctStdtoExcel } from "../../../queries/xls";
import {
  ExportAcctStdtoExcelData,
  ExportAcctStdtoExcelVars,
} from "../../Master/Reports/InstitutionLevel/useExportMutation";
import Modal from "react-modal";
import { useLazyQuery } from "@apollo/client";
import { VoucherQueryTypes } from "../common/Enum.types";
import { SortBy } from "../../Master/Reports/InstitutionLevel/GlobalStates/types";
import useToken from "../../../customhooks/useToken";
import { getDownloadUrl } from "../../../utils/DownloadFile";
import MessageModal from "../../../pages/MessageModal";
import LoadingModal from "../../../pages/LoadingModal";
import { StudentModalStyles } from "../../../styles/ModalStyles";

const StudentLedgerWiseReport = () => {
  const { Accounts_Table } = useAcctTableJson();
  const navigate = useNavigate();
  const { format } = formatter;
  const { token } = useToken();
  const { InstId } = useParams();
  const { state } = useContext(AppContext);
  const [entryId, setEntryId] = useState(0);
  const [message, setMessage] = useState(emptyMessageType);
  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);
  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [classSelected, setClassSelected] = useState<responseType | null>(null);
  const [semesterSelected, setSemesterSelected] = useState<responseType | null>(
    null
  );
  const [sectionSelected, setSectionSelected] = useState<responseType | null>(
    null
  );
  const [categorySelected, setCategorySelected] = useState<responseType | null>(
    null
  );
  const [status, setStatus] = useState("");
  const [customizeDetails, setCustomizeDetails] = useState(false);

  // const [selectedLedgers, setSelectedLedgers] = useState<responseType[]>([]);
  // const [searchLedger, setSearchLedger] = useState("");
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [students, setStudents] = useState<StudentDemandEdges[]>([]);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [searchData, setSearchData] = useState("");

  const [ExportAcct, { loading: excelLoading }] = useLazyQuery<
    ExportAcctStdtoExcelData,
    ExportAcctStdtoExcelVars
  >(ExportAcctStdtoExcel);

  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CATEGORY_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();

  const {
    departmentLabel,
    branchLabel,
    classLabel,
    semesterLabel,
    sectionLabel,
    categoryLabel,
  } = useInstLabels();

  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useDropdownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0
  );

  useEffect(() => {
    if (sectionSelected && USE_SECTION_KEY) {
      setEntryId(sectionSelected.value);
    } else if (semesterSelected && USE_SEMESTER_KEY) {
      setEntryId(semesterSelected.value);
    } else if (classSelected && USE_CLASS_KEY) {
      setEntryId(classSelected.value);
    } else if (branchSelected && USE_BRANCH_KEY) {
      setEntryId(branchSelected.value);
    } else if (departmentSelected && USE_DEPARTMENT_KEY) {
      setEntryId(departmentSelected.value);
    } else {
      setEntryId(Number(InstId));
    }
  }, [
    departmentSelected,
    branchSelected,
    classSelected,
    semesterSelected,
    sectionSelected,
    USE_SEMESTER_KEY,
    USE_CLASS_KEY,
    USE_BRANCH_KEY,
    USE_DEPARTMENT_KEY,
    USE_SECTION_KEY,
    InstId,
  ]);

  const { categoryDropDown } = useInstMasterDataByInstId();

  const { students: StudentsData } = UseStudentsbyDemandAmount(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0,
    sectionSelected ? sectionSelected.value : 0,
    categorySelected ? categorySelected.value : 0,
    searchData,
    ROWS_PER_PAGE,
    StudentAcctReportType.DEMAND_RAISED,
    0,
    status,
    null,
    null
  );
  const { StudentsSummationData } = useSummationDatabyNewApi(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0,
    sectionSelected ? sectionSelected.value : 0,
    categorySelected ? categorySelected.value : 0,
    StudentAcctReportType.DEMAND_RAISED,
    status
  );

  const { data, fetchMore, loading } = StudentsData;

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight + 1;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !loading) {
        fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetAcctStdDemand.edges;
            const pageInfo = fetchMoreResult.GetAcctStdDemand.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetAcctStdDemand.edges.filter(
              ({
                node: {
                  mst_student: { id },
                },
              }) =>
                newEdges.findIndex(
                  ({
                    node: {
                      mst_student: { id: newId },
                    },
                  }) => newId === id
                ) !== -1
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetAcctStdDemand: {
                edges: [...students, ...newEdges],
                pageInfo,
                totalCount: data?.GetAcctStdDemand.totalCount!,
              },
            };
          },
        });
      }
    }
  };

  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetAcctStdDemand.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = students.find(
            (student) =>
              student.node.mst_student.id === newStudent.node.mst_student.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
                // isChecked: filteredStudent.node.isChecked,
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData);
      } else {
        setStudents(newData);
      }
      setEndCursor(data.GetAcctStdDemand.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);
  const StudentsSummationDataCount =
    StudentsSummationData.data &&
    StudentsSummationData.data.GetAcctStdDemandSummation.length
      ? StudentsSummationData.data.GetAcctStdDemandSummation[0]
      : {
          std_demand_amt: 0,
          std_demand_concession: 0,
          std_demand_ob: 0,
          std_demand_receivable: 0,
          std_demand_received: 0,
          std_demand_refunds: 0,
          std_demand_bal: 0,
        };

  const handleExcelExport = () => {
    const parameters = [
      "std_adm_no",
      "std_reg_no",
      "std_father_name",
      "std_mobile",
      "ledger_name",
      "std_demand_ob",
      "std_demand_amt",
      "std_demand_concession",
      "std_demand_receivable",
      "std_demand_received",
      "std_demand_refunds",
      "std_demand_bal",
    ];
    ExportAcct({
      variables: {
        fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
        input: {
          float_data: [],
          ids: [entryId, categorySelected ? categorySelected.value : 0],
          int_data: [],
          std_status: status ? [status] : [],
          str_data: [],
        },
        inst_id: InstId!,
        query_type: VoucherQueryTypes.ACCT_STD_FEE_LEDGER_WISE_REPORT,
        sort_by: SortBy.STD_NAME,
        token,
        voucher_input: {
          acct_ldgr_id: 0,
          v_book_type: EMPTY_STRING,
          vo_end_date: toIsoDate(DEFAULT_TIME),
          vo_start_date: toIsoDate(DEFAULT_TIME),
          vo_type: EMPTY_STRING,
        },
        parameters,
      },
    }).then(async ({ data, error }) => {
      if (data && data.ExportAcctStdtoExcel) {
        const res = await getDownloadUrl(data.ExportAcctStdtoExcel, true);
        if (res) {
          setMessage({
            flag: true,
            message: "Successfully downloaded file",
            operation: Operation.CREATE,
          });
        }
      }
      if (error && error.message) {
        setMessage({
          flag: true,
          message: error.message,
          operation: Operation.NONE,
        });
      }
    });
  };

  return (
    <>
      <Home DashBoardRequired={false} />

      <Title>Ledger Wise Student Report </Title>
      <div className="ledger-wise-student-report">
        <form className="row g-0 ledger-wise-student-report__options">
          <div className="col-1 studentlist__select-flex studentlist__search">
            <Input
              id="search"
              type="text"
              placeholder="Search "
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchData(e.target.value);
              }}
              value={searchData}
            />
          </div>

          {USE_DEPARTMENT_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={departmentDropDown}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setDepartmentSelected(null);
                  }
                }}
                openOnFocus
                value={departmentSelected}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(
                    option as responseType,
                    departmentSelected
                  )
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    setDepartmentSelected(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setDepartmentSelected(null);
                  }
                  setBranchSelected(null);
                  setClassSelected(null);
                  setSemesterSelected(null);
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={departmentLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    id="outlined Departments"
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_BRANCH_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={branchDropDown}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setBranchSelected(null);
                  }
                }}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, branchSelected)
                }
                openOnFocus
                value={branchSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setBranchSelected(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setBranchSelected(null);
                  }
                  setClassSelected(null);
                  setSemesterSelected(null);
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={branchLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_CLASS_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={classDropDown}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setClassSelected(null);
                  }
                }}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, classSelected)
                }
                openOnFocus
                value={classSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setClassSelected(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setClassSelected(null);
                  }
                  setSemesterSelected(null);
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={classLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                    fullWidth
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SEMESTER_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={semesterDropDown}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSemesterSelected(null);
                  }
                }}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, semesterSelected)
                }
                openOnFocus
                value={semesterSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSemesterSelected(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setSemesterSelected(null);
                  }
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={semesterLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    fullWidth
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SECTION_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={sectionDropDown}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSectionSelected(null);
                  }
                }}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, sectionSelected)
                }
                openOnFocus
                value={sectionSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSectionSelected(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setSectionSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={sectionLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_CATEGORY_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={categoryDropDown}
                openOnFocus
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, categorySelected)
                }
                value={categorySelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setCategorySelected(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setCategorySelected(null);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setCategorySelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={categoryLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                    fullWidth
                  />
                )}
              />
            </div>
          ) : null}
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={Object.values(StudentStatus)}
              openOnFocus
              value={status}
              isOptionEqualToValue={(option) =>
                status === "" || option === status
              }
              onChange={(e, newValue) => {
                if (newValue) {
                  setStatus(newValue as StudentStatus);
                  setHasNextPage(true);
                } else {
                  setStatus(EMPTY_STRING);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === Keys.BACKSPACE) {
                  setStatus(EMPTY_STRING);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Status"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  className={labelClasses.formControlRoot}
                  fullWidth
                />
              )}
            />
          </div>
        </form>
        <div className="ledger-wise-student-report__tableblock">
          <TableContainer
            onScroll={handleScroll}
            className="ledger-wise-student-report__table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Accounts_Table.Reports.LedgerWiseStudentReport.Table_Headers.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return (
                        <TableCell key={index} align="center">
                          {th.labelName}
                        </TableCell>
                      );
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {students.map((record, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell
                        id="td-center"
                        className="ledger-wise-student-report__table--slno">
                        {index + 1}
                      </TableCell>

                      <TableCell className="ledger-wise-student-report__table--admno">
                        {record.node.mst_student.std_adm_no}
                      </TableCell>
                      <TableCell className="ledger-wise-student-report__table--admno">
                        {record.node.mst_student.std_reg_no}
                      </TableCell>
                      <TableCell className="ledger-wise-student-report__table--name">
                        {record.node.mst_student.first_name +
                          " " +
                          record.node.mst_student.middle_name +
                          " " +
                          record.node.mst_student.last_name}
                      </TableCell>
                      <TableCell>{record.node.class.class_desc}</TableCell>
                      <TableCell>
                        {record.node.mst_student.std_father_name}
                      </TableCell>
                      <TableCell>
                        {record.node.mst_student.std_mobile}
                      </TableCell>
                      <TableCell className="ledger-wise-student-report__table--name">
                        <ul>
                          {record.node.demand_details.map(
                            (acctLedger, index) => {
                              return (
                                <li
                                  key={acctLedger.acct_ldgr_details.id}
                                  className="ledger-wise-student-report__table--ledgers--ledgers">
                                  {`${index + 1})`}&nbsp;
                                  {acctLedger.acct_ldgr_details.ldgr_desc}
                                </li>
                              );
                            }
                          )}
                          <li className="ledger-wise-student-report__table--ledgers--total">
                            Total :
                          </li>
                        </ul>
                      </TableCell>

                      <TableCell
                        id="td-right"
                        className="ledger-wise-student-report__table--amount">
                        <ul>
                          {record.node.demand_details.map((demand, index) => {
                            return (
                              <li
                                key={index}
                                className="ledger-wise-student-report__table--ledgers--ledgers">
                                {format(demand.fee_ob)}
                              </li>
                            );
                          })}
                          <li className="ledger-wise-student-report__table--ledgers--total">
                            {format(record.node.std_demand_ob)}
                          </li>
                        </ul>
                      </TableCell>

                      <TableCell
                        id="td-right"
                        className="ledger-wise-student-report__table--amount">
                        <ul>
                          {record.node.demand_details.map((demand, index) => {
                            return (
                              <li
                                key={index}
                                className="ledger-wise-student-report__table--ledgers--ledgers">
                                {format(demand.fee_demand)}
                              </li>
                            );
                          })}
                          <li className="ledger-wise-student-report__table--ledgers--total">
                            {format(record.node.std_demand_amt)}
                          </li>
                        </ul>
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className="ledger-wise-student-report__table--amount">
                        <ul>
                          {record.node.demand_details.map((demand, index) => {
                            return (
                              <li
                                key={index}
                                className="ledger-wise-student-report__table--ledgers--ledgers">
                                {format(demand.fee_concession)}
                              </li>
                            );
                          })}
                          <li className="ledger-wise-student-report__table--ledgers--total">
                            {format(record.node.std_demand_concession)}
                          </li>
                        </ul>
                      </TableCell>

                      <TableCell
                        id="td-right"
                        className="ledger-wise-student-report__table--amount">
                        <ul>
                          {record.node.demand_details.map((demand, index) => {
                            return (
                              <li
                                key={index}
                                className="ledger-wise-student-report__table--ledgers--ledgers">
                                {format(demand.fee_receivable)}
                              </li>
                            );
                          })}
                          <li className="ledger-wise-student-report__table--ledgers--total">
                            {format(record.node.std_demand_receivable)}
                          </li>
                        </ul>
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className="ledger-wise-student-report__table--amount">
                        <ul>
                          {record.node.demand_details.map((demand, index) => {
                            return (
                              <li
                                key={index}
                                className="ledger-wise-student-report__table--ledgers--ledgers">
                                {format(demand.fee_received)}
                              </li>
                            );
                          })}
                          <li className="ledger-wise-student-report__table--ledgers--total">
                            {format(record.node.std_demand_received)}
                          </li>
                        </ul>
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className="ledger-wise-student-report__table--amount">
                        <ul>
                          {record.node.demand_details.map((demand, index) => {
                            return (
                              <li
                                key={index}
                                className="ledger-wise-student-report__table--ledgers--ledgers">
                                {format(demand.fee_refunds)}
                              </li>
                            );
                          })}
                          <li className="ledger-wise-student-report__table--ledgers--total">
                            {format(record.node.std_demand_refunds)}
                          </li>
                        </ul>
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className="ledger-wise-student-report__table--amount">
                        <ul>
                          {record.node.demand_details.map((demand, index) => {
                            return (
                              <li
                                key={index}
                                className="ledger-wise-student-report__table--ledgers--ledgers">
                                {format(demand.fee_bal)}
                              </li>
                            );
                          })}
                          <li className="ledger-wise-student-report__table--ledgers--total">
                            {format(record.node.std_demand_bal)}
                          </li>
                        </ul>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              {searchData.length === 0 &&
              StudentsSummationData &&
              StudentsSummationData.data &&
              StudentsSummationData.data.GetAcctStdDemandSummation.length ? (
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={8} id="td-right" className="total">
                      Grand Total:
                    </TableCell>
                    <TableCell id="td-right" className="totalcount">
                      {format(StudentsSummationDataCount.std_demand_ob)}
                    </TableCell>
                    <TableCell id="td-right" className="totalcount">
                      {format(StudentsSummationDataCount.std_demand_amt)}
                    </TableCell>
                    <TableCell id="td-right" className="totalcount">
                      {format(StudentsSummationDataCount.std_demand_concession)}
                    </TableCell>

                    <TableCell id="td-right" className="totalcount">
                      {format(StudentsSummationDataCount.std_demand_receivable)}
                    </TableCell>
                    <TableCell id="td-right" className="totalcount">
                      {format(StudentsSummationDataCount.std_demand_received)}
                    </TableCell>
                    <TableCell id="td-right" className="totalcount">
                      {format(StudentsSummationDataCount.std_demand_refunds)}
                    </TableCell>
                    <TableCell id="td-right" className="totalcount">
                      {format(StudentsSummationDataCount.std_demand_bal)}
                    </TableCell>
                  </TableRow>
                </TableFooter>
              ) : null}
            </Table>
          </TableContainer>
        </div>
        <div className="row g-0">
          <div className="col">
            <Button
              onClick={() => {
                setCustomizeDetails(!customizeDetails);
              }}
              mode="export"
            />

            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
          {data ? (
            <div className="col-2 ledger-wise-student-report__total">
              <StudentTotalCount
                totalCount={data ? data.GetAcctStdDemand.totalCount! : 0}
              />
            </div>
          ) : null}
        </div>
      </div>
      <MessageModal
        handleClose={() => setMessage(emptyMessageType)}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
      <Modal
        isOpen={customizeDetails}
        style={StudentModalStyles}
        ariaHideApp={false}>
        <InstitutionLevel
          reportType={VoucherQueryTypes.ACCT_STD_FEE_LEDGER_WISE_REPORT}
          forModal={true}
          setModalFlag={setCustomizeDetails}
        />
      </Modal>
      <LoadingModal flag={excelLoading} />
    </>
  );
};

export default StudentLedgerWiseReport;
