import { useContext, useState } from "react";
import Home from "./Home/Index";
import { Title } from "../../stories/Title/Title";
import { TextField } from "@mui/material";
import { toStandardDate } from "../../utils/UtilFunctions";
import { Button } from "../../stories/Button/Button";
import { AppContext } from "../../context/context";
import useEmpDetailsById from "../HR/hooks/useEmpDetailsById";
import usePayRollMastersConfig from "../HR/hooks/usePayRollMastersConfig";
import { useMutation } from "@apollo/client";
import { ReCreateUserInFirebase } from "./queries/mutation";
import useToken from "../../customhooks/useToken";
import { useNavigate, useParams } from "react-router-dom";
import LoadingModal from "../../pages/LoadingModal";
import MessageModal from "../../pages/MessageModal";
import { msgType, responseType } from "../../utils/Form.types";
import { Operation, PageFor } from "../../utils/Enum.types";
import { StudentModalStyles } from "../../styles/ModalStyles";
import Modal from "react-modal";
import Close from "../../images/Close.svg";
import DataFetch from "../../images/Edit.svg";

import {
  DEFAULT_TIME,
  EMPTY_STRING,
  ROWS_PER_PAGE,
} from "../../utils/constants";
import useLoggedInUserDetails from "../Accounts/hooks/useLoggedInUserDetails";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../styles/AutocompleteListStyles";
import { payloadTypes } from "../../context/reducer";
import useEmployee from "../HR/hooks/useEmployee";
import { empQueryTypes } from "../HR/hooks/useEmployeeAttsByMonth";
import LoginUser from "./LoginUser/Index";

const ReCreateUsers = () => {
  const { token } = useToken();
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppContext);
  const { user_details } = useLoggedInUserDetails();
  const [searchData, setSearchData] = useState("");
  const { InstId } = useParams();
  const [empmodal, setEmpModal] = useState(false);

  const [message, setMessage] = useState<msgType>({
    flag: false,
    message: "",
    operation: Operation.NONE,
  });

  const {
    empDetails: { data, loading: emploading },
  } = useEmployee(ROWS_PER_PAGE, empQueryTypes.JUST_LOGIN_ACCTS, searchData);

  const { employeeFormData } = useEmpDetailsById();
  const {
    USE_HR_CATEGORY,
    USE_HR_DEPARTMENT,
    USE_HR_DESIGNATION,
    USE_HR_JOBTYPE,
  } = usePayRollMastersConfig();

  const [AddRights, { loading }] = useMutation(ReCreateUserInFirebase, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const handleClose = () => {
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  const handleRights = () => {
    AddRights({
      variables: {
        token,
        inst_id: InstId!,
        user_details,
        pr_emp_id: state.employeeId,
      },
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Successfully assigned rights",
          operation: Operation.CREATE,
        });
      }
    });
  };
  const empDropDown = data
    ? data.GetPayRollEmp.edges.map((res) => ({
        label:
          res.node.emp_first_name +
          " " +
          res.node.emp_middle_name +
          " " +
          res.node.emp_last_name,
        value: res.node.id,
      }))
    : [];
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>ReCreate Firebase Users</Title>
      <div className="recreate-rights">
        <div className="row g-0 recreate-rights__select-options">
          <div className="col-4 recreate-rights__select-options--image-flex">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={empDropDown!}
              value={
                empDropDown &&
                empDropDown.find(({ value }) => value === state.employeeId)
              }
              onChange={(e, newValue) => {
                if (newValue) {
                  dispatch({
                    type: payloadTypes.SET_EMPLOYEE_ID,
                    payload: { employeeId: (newValue as responseType).value },
                  });
                } else {
                  dispatch({
                    type: payloadTypes.SET_EMPLOYEE_ID,
                    payload: { employeeId: 0 },
                  });
                }
              }}
              renderInput={(params) => (
                <TextField
                  label="Employee Name"
                  {...params}
                  onChange={(e) => {
                    setSearchData(e.target.value);
                  }}
                  fullWidth
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  className={labelClasses.formControlRoot}
                />
              )}
            />
            <img
              className="data-fetch-icon"
              src={DataFetch}
              alt="/"
              onClick={() => {
                setEmpModal(!empmodal);
              }}
            />

            {USE_HR_CATEGORY ? (
              <TextField
                label="Category."
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                value={employeeFormData.category}
                className="assign-user-rights__textfield"
                disabled
              />
            ) : null}
          </div>
          <div className="col recreate-rights__colspan">
            {USE_HR_DESIGNATION ? (
              <TextField
                label="Designation."
                className="recreate-rights__textfield"
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                value={employeeFormData.designation}
                disabled
              />
            ) : null}
            {USE_HR_DEPARTMENT ? (
              <TextField
                className="recreate-rights__textfield"
                label="Department."
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                value={employeeFormData.department}
                disabled
              />
            ) : null}
            {USE_HR_JOBTYPE ? (
              <TextField
                label="Job Type."
                className="recreate-rights__textfield"
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                value={employeeFormData.job_type}
                disabled
              />
            ) : null}
            <TextField
              label="Name."
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              disabled
              value={employeeFormData.emp_name}
              className="recreate-rights__textfield"
            />
            <TextField
              label="Date of Joining."
              className="recreate-rights__textfield--date"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              value={
                toStandardDate(employeeFormData.emp_doj) ===
                toStandardDate(DEFAULT_TIME)
                  ? EMPTY_STRING
                  : toStandardDate(employeeFormData.emp_doj)
              }
              disabled
            />

            <TextField
              label="Years of Exp."
              className="recreate-rights__textfield"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              value={employeeFormData.emp_experience}
              disabled
            />
            <TextField
              label="Qualification."
              className="recreate-rights__textfield"
              value={employeeFormData.emp_qualification}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              disabled
            />
          </div>
        </div>

        <div className="row g-0 recreate-rights__datablock">
          <div className="col recreate-rights__datablock--left"></div>
        </div>

        <Button mode="save" onClick={handleRights} />

        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      <LoadingModal flag={loading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />

      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={empmodal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <LoginUser pageType={PageFor.MODAL} setEmpModal={setEmpModal} />
          </div>
          <div className="modal-flex__image">
            <img src={Close} alt="/" onClick={() => setEmpModal(!empmodal)} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ReCreateUsers;
