import React, { useContext, useEffect, useRef, useState } from "react";
import {
  BankOrCash,
  DebitOrCredit,
  Operation,
  PageFor,
  ReceiptTypes,
  StudentAcctReportType,
  StudentListFor,
  VoucherBookKey,
} from "../../../../utils/Enum.types";
import { Title } from "../../../../stories/Title/Title";
import Edit from "../../../../images/Edit.svg";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";
import Modal from "react-modal";
import Close from "../../../../images/Close.svg";
import Settings from "../../../../images/Settings.svg";

import { TextField } from "@mui/material";
import useAcctStdAdmissionNumber from "../../hooks/useAcctStdAdmissionNumber";
import {
  DEFAULT_TIME,
  EMPTY_STRING,
  INPUT,
  NumberOfAdmissionNumberToBeDisplayed,
  TODAY_DATE,
} from "../../../../utils/constants";
import useStudentDatabyId from "../../../../customhooks/useStudentDatabyId";
import {
  isOptionEqualToValue,
  toInputStandardDate,
} from "../../../../utils/UtilFunctions";
import { Button } from "../../../../stories/Button/Button";
import Avatar from "../../../../images/Avatar.svg";
import DownArrow from "../../../../images/DownArrow.svg";
import useAcctLedgerData from "../../hooks/useAcctLedgerData";
import {
  AcctLedgerQueryType,
  DepositLedgerQueryType,
} from "../../common/QueryTypes";
import { msgType, responseType } from "../../../../utils/Form.types";
import { payloadTypes } from "../../../../context/reducer";
import { AppContext } from "../../../../context/context";
import { useMutation } from "@apollo/client";
import { AddAcctVoucherNonDemandAdjFromDeposit } from "../../queries/receipts/mutation/Index";
import useToken from "../../../../customhooks/useToken";
import { useNavigate, useParams } from "react-router-dom";
import useLoggedInUserDetails from "../../hooks/useLoggedInUserDetails";
import dayjs from "dayjs";
import useVoucherNumber from "../../hooks/useVoucherNumber";
import useStudentDepositDetails from "../../hooks/useStudentDepositDetails";
import LoadingModal from "../../../../pages/LoadingModal";
import MessageModal from "../../../../pages/MessageModal";
import {
  PrintModalStyles,
  StudentModalStyles,
} from "../../../../styles/ModalStyles";
import { Data } from "../../../Print/Accounts/FeeReceipts/templates/Index";
import { Keys } from "../../../../utils/Enum.keys";
import { Label } from "../../../../stories/Label/Label";
import DepositDetails from "../../../../images/DepositDetails.svg";
import {
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../../styles/DataGridTableStyles";
import useAcctTableJson from "../../json/useAcctTableJson";
import {
  GridAlignment,
  GridCellParams,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import Input from "../../../../stories/Input/Input";
import { getDownloadUrl } from "../../../../utils/DownloadFile";
import StudentList from "../../common/StudentList";
import PrintNonDemandReceipt from "../../../Print/Accounts/FeeReceipts/Standard/PrintNonDemandReceipt";
interface Props {
  pageType: PageFor;
  setDepositAdjustmentIndividualModal: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}
const IndividualAdjustDepositFee = ({
  pageType,
  setDepositAdjustmentIndividualModal,
}: Props) => {
  const { studentData, studentFormData } = useStudentDatabyId();
  const [searchData, setsearchData] = useState("");
  const [searchLdgrData, setsearchLdgrData] = useState("");
  const [amount, setAmount] = useState(0);
  const { StudentDepositData } = useStudentDepositDetails(
    DepositLedgerQueryType.DEPOSIT_GT_0_BY_STD_ID
  );

  const [tableData, setTableData] = useState<Data[]>([]);
  const [v_no, set_vo_no] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("");
  const [imageString, setImageString] = useState("");
  const [studentModal, setStudentModal] = useState(false);

  const [configurationModal, setConfigurationModal] = useState(false);
  const [voucherDate, setVoucherDate] = useState(
    toInputStandardDate(TODAY_DATE)
  );
  const [admNo, setAdmNo] = useState("");
  const { dispatch, state } = useContext(AppContext);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [printModal, SetPrintModal] = useState(false);
  const [v_date, set_v_Date] = useState("");

  const { voucherNumber } = useVoucherNumber(
    VoucherBookKey.STUDENT_COMMAN_RECEIPT_BOOK
  );
  const [feeDepositSelected, setFeeDepositSelected] =
    useState<responseType | null>(null);
  const [feeLedgerSelected, setFeeLedgerSelected] =
    useState<responseType | null>(null);
  const { studentAddmissionNumber } = useAcctStdAdmissionNumber(
    admNo,
    NumberOfAdmissionNumberToBeDisplayed,
    StudentAcctReportType.DEMAND_RAISED
  );

  const { acctLedgers: acctLedgersData } = useAcctLedgerData(
    searchLdgrData,
    AcctLedgerQueryType.STD_FEE_ACCT_LDGRS_WITHOUT_DEPOSITS,
    5
  );
  const depositAmopuntTotal =
    StudentDepositData &&
    StudentDepositData.data &&
    StudentDepositData.data.GetAcctStdDeposits.edges
      .map((res) => res.node.deposit_bal)
      .reduce((total, deposit) => total + deposit, 0);
  const [adjustFlag, setAdjustFlag] = useState(false);
  const depositFeeDropDown = StudentDepositData.data
    ? StudentDepositData.data.GetAcctStdDeposits.edges.map((res) => ({
        label: res.node.acct_ldgr_details.ldgr_desc,
        value: res.node.acct_ldgr_details.id,
      }))
    : [];

  const acctLedgersDropdown = acctLedgersData && acctLedgersData.responseType;
  const { token } = useToken();
  const { InstId } = useParams();
  const { user_details } = useLoggedInUserDetails();
  const naviagte = useNavigate();
  const feeDepositRef = useRef<HTMLSelectElement>(null);
  const feeDepositInputRef = feeDepositRef.current?.getElementsByTagName(
    INPUT
  )[0] as HTMLInputElement;
  const toLedgerRef = useRef<HTMLSelectElement>(null);
  const toLedgerInputRef = toLedgerRef.current?.getElementsByTagName(
    INPUT
  )[0] as HTMLInputElement;
  const enterAmountRef = useRef<HTMLInputElement>(null);
  const adjustButtonRef = useRef<HTMLButtonElement>(null);

  const [AddAcctVoucher, { loading }] = useMutation(
    AddAcctVoucherNonDemandAdjFromDeposit,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const HandleSubmit = () => {
    AddAcctVoucher({
      variables: {
        token,
        inst_id: InstId!,
        user_details,
        fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
        input: {
          acct_voucher_master: {
            fin_yr: state.ActiveFinYr ? state.ActiveFinYr.fin_yr : EMPTY_STRING,
            v_type: ReceiptTypes.STUDENT_RECEIPT,
            v_no: voucherNumber.data?.GetVoucherNumber.vo_number,
            v_date: dayjs(voucherDate).format(),
            v_total_cr_amt: amount,
            v_total_db_amt: amount,
            v_reconciled: false,
            v_reconciled_date: dayjs(DEFAULT_TIME).format(),
            v_book_type: VoucherBookKey.STUDENT_COMMAN_RECEIPT_BOOK,
            student_id: state.studentId,
            class_id: studentData.data?.nodes[0].class.id,
            semester_id: studentData.data?.nodes[0].semester.id,
            v_std_amt_receipt: amount,
            v_std_amt_deposit: amount,
            v_std_amt_fine: 0,
            v_std_amt_total: amount,
            v_std_amt_refunded: 0,
            v_std_amt_adjusted: 0,
            v_transcation_type: `Adjusted from ${feeDepositSelected?.label}`,
            v_transcation_cash_or_bank: BankOrCash.ADJ,
            v_transcation_no: "",
            v_transcation_date: dayjs(TODAY_DATE).format(),
            v_transcation_narration: EMPTY_STRING,
            party_bill_date: dayjs(TODAY_DATE).format(),
            acct_ldgr_id_cr: feeLedgerSelected ? feeLedgerSelected.value : 0,
            acct_ldgr_id_db: feeDepositSelected ? feeDepositSelected.value : 0,
          },

          acct_voucher_db: [
            {
              vo_cr_db: DebitOrCredit.DEBIT,
              vo_sl_no: 1,
              vo_cr: 0,
              vo_db: amount,
              vo_cr_total: 0,
              vo_db_total: amount,
              acct_ldgr_id: feeDepositSelected ? feeDepositSelected.value : 0,
            },
          ],
          acct_voucher_cr: [
            {
              vo_sl_no: 1,
              vo_cr_db: DebitOrCredit.CREDIT,
              vo_cr: amount,
              vo_db: 0,
              vo_cr_total: amount,
              vo_db_total: 0,
              acct_ldgr_id: feeLedgerSelected ? feeLedgerSelected.value : 0,
            },
          ],
        },
        stdIds: [state.studentId],
      },
    }).then(({ data }) => {
      if (data) {
        const { v_no, v_date, id } =
          data.AddAcctVoucherNonDemandAdjFromDeposit[0];

        dispatch({
          type: payloadTypes.SET_RECEPIT_ID,
          payload: { receiptId: id },
        });
        setTableData([
          {
            particular: feeLedgerSelected
              ? feeLedgerSelected.label
              : EMPTY_STRING,
            amount: Number(amount),
          },
        ]);
        set_v_Date(v_date);
        set_vo_no(v_no);
        SetPrintModal(!printModal);
        // setMessage({
        //   flag: true,
        //   message: "Successfully Adjusted Deposit Fee to Non Demand Ledger",
        //   operation: Operation.CREATE,
        // });
      }
    });
  };
  const handleClear = () => {
    setAdmNo("");
    setFeeLedgerSelected(null);
    setAmount(0);
    toInputStandardDate(EMPTY_STRING);
    setFeeDepositSelected(null);
    setAdjustFlag(false);
    dispatch({
      type: payloadTypes.SET_STUDENT_ID,
      payload: {
        studentId: 0,
      },
    });
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
      setConfigurationModal(!configurationModal);
      // setDepositAdjustmentIndividualModal(false);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  const { Accounts_Table } = useAcctTableJson();
  const dynamicHeaders: GridColDef[] =
    Accounts_Table.Receipts.TotalDeposits.Table_Headers?.map((header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
      hideable: header.hideable,
    }));
  const columns: GridColDef[] = [...dynamicHeaders];

  const rows: GridValidRowModel[] = (
    (StudentDepositData &&
      StudentDepositData.data &&
      StudentDepositData.data.GetAcctStdDeposits.edges) ||
    []
  ).map((data, index) => ({
    id: index + 1,
    deposits: data.node.acct_ldgr_details.ldgr_desc,
    balance: data.node.deposit_bal,
  }));
  const getRow = () => {
    if (rows.length) {
      return [
        {
          id: [],
          deposits: "Total Deposit Balance",
          balance: depositAmopuntTotal,
        },
      ];
    }
    return [];
  };

  const pinnedRows = {
    bottom: getRow(),
  };
  useEffect(() => {
    if (studentFormData.std_profile_filename !== EMPTY_STRING) {
      // eslint-disable-next-line
      const ers = getDownloadUrl(
        studentFormData.std_profile_filename,
        false,
        setImageString
      );
    }
  }, [studentFormData.std_profile_filename]);

  const getCellClassName = (params: GridCellParams) => {
    const pinnedRowIds = pinnedRows.bottom
      ? pinnedRows.bottom.map((row) => row.id)
      : [];

    const isPinnedRow = pinnedRowIds.includes(params.row.id);

    if (isPinnedRow) {
      switch (params.field) {
        case "balance":
          return "totalcount";

        default:
          return "total";
      }
    }

    return "";
  };
  return (
    <>
    {pageType === PageFor.MODAL && <Title>Adjustment (Non Demand) Individual</Title>}
      <div className={`adjust-deposit-fee__individual${pageType === PageFor.GENERAL ? " " :"--modal"}`}>
        <div className="row g-0 adjust-deposit-fee__individual--details">
          <div className="col">
            <div className="adjust-deposit-fee__individual--details--image-flex">
              <LabeledAutocomplete
                options={studentAddmissionNumber}
                className={labelClasses.inputRoot}
                openOnFocus
                freeSolo
                value={
                  state.studentId
                    ? studentAddmissionNumber?.find(
                        ({ value }) => value === state.studentId
                      )!
                    : null
                }
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === Keys.ENTER) {
                    if (feeDepositRef.current) {
                      feeDepositInputRef.focus();
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    dispatch({
                      type: payloadTypes.SET_STUDENT_ID,
                      payload: {
                        studentId: 0,
                      },
                    });
                    setAdmNo("");
                  }
                }}
                onChange={(e, newValue) => {
                  if (newValue) {
                    dispatch({
                      type: payloadTypes.SET_STUDENT_ID,
                      payload: {
                        studentId: (newValue as responseType).value,
                      },
                    });
                  } else {
                    dispatch({
                      type: payloadTypes.SET_STUDENT_ID,
                      payload: {
                        studentId: 0,
                      },
                    });
                    handleClear();
                  }
                }}
                popupIcon={<img src={DownArrow} alt="/" />}
                forcePopupIcon
                autoHighlight
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Admission No."
                    autoFocus
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    onChange={(e) => setAdmNo(e.target.value)}
                    fullWidth
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
              <img
                className="data-fetch-icon"
                src={Edit}
                alt="/"
                onClick={() => {
                  setStudentModal(!studentModal);
                }}
              />
            </div>
            <TextField
              className="adjust-deposit-fee__individual--details--textfield"
              label="Reg No."
              value={studentFormData.reg_number}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              disabled
            />
            <TextField
              className="adjust-deposit-fee__individual--details--textfield"
              value={studentFormData.branch}
              label="Branch"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              disabled
            />
          </div>
          <div className="col">
            <TextField
              value={studentFormData.first_name}
              className="adjust-deposit-fee__individual--details--textfield"
              label="First Name"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              disabled
            />
            <TextField
              value={studentFormData.father_name}
              className="adjust-deposit-fee__individual--details--textfield"
              label="Father Name"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              disabled
            />
            <TextField
              value={studentFormData.class}
              className="adjust-deposit-fee__individual--details--textfield"
              label="Class"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              disabled
            />
          </div>
          <div className="col">
            <TextField
              type="date"
              value={voucherDate}
              onChange={(e) => setVoucherDate(e.target.value)}
              className="adjust-deposit-fee__individual--details--textfield--date"
              label="Date"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
                input: {
                  inputProps: {
                    min: state.ActiveFinYr
                      ? toInputStandardDate(state.ActiveFinYr.fin_st_date)
                      : 0,

                    max: toInputStandardDate(TODAY_DATE),
                  },
                },
              }}
            />
            <div className="adjust-deposit-fee__individual--details--inputs">
              <LabeledAutocomplete
                options={depositFeeDropDown!}
                className={labelClasses.inputRoot}
                value={feeDepositSelected}
                ref={feeDepositRef}
                openOnFocus
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(
                    option as responseType,
                    feeDepositSelected
                  )
                }
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === Keys.ENTER) {
                    if (toLedgerRef.current) {
                      toLedgerInputRef.focus();
                    }
                  }
                }}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setFeeDepositSelected(newValue as responseType);
                  } else {
                    setFeeDepositSelected(null);
                  }
                }}
                popupIcon={<img src={DownArrow} alt="/" />}
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Fee Deposit"
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    onKeyDown={(e: React.KeyboardEvent) => {
                      if (e.key === Keys.ENTER) {
                        if (toLedgerRef.current) {
                          toLedgerInputRef.focus();
                        }
                      }
                    }}
                    onChange={(e) => setsearchData(e.target.value)}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
              <LabeledAutocomplete
                options={acctLedgersDropdown}
                className={labelClasses.inputRoot}
                value={feeLedgerSelected}
                ref={toLedgerRef}
                openOnFocus
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(
                    option as responseType,
                    feeLedgerSelected
                  )
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    if (enterAmountRef.current) {
                      enterAmountRef.current.focus();
                    }
                  }
                }}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setFeeLedgerSelected(newValue as responseType);
                  } else {
                    setFeeLedgerSelected(null);
                  }
                }}
                popupIcon={<img src={DownArrow} alt="/" />}
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="To Ledger"
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    onChange={(e) => setsearchLdgrData(e.target.value)}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
            <div className="adjust-deposit-fee__individual--details--label-grid">
              <TextField
                label="Enter Amount"
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (adjustButtonRef.current) {
                      adjustButtonRef.current.focus();
                    }
                  }
                }}
                inputRef={enterAmountRef}
                value={amount}
                onChange={(e) => setAmount(Number(e.target.value))}
              />
              <Button
                mode="adjustment-receipts"
                buttonref={adjustButtonRef}
                onClick={() => setAdjustFlag(true)}
                disabled={!amount}
              />
            </div>
          </div>
          <div className="col-1 adjust-deposit-fee__individual--details--image">
            {imageString ? (
              <img src={imageString} alt="" />
            ) : (
              <img src={Avatar} alt="" />
            )}
          </div>
        </div>

        <div className={`row g-0 adjust-deposit-fee__individual--data${pageType === PageFor.GENERAL ? "" :"--modal"}`}>
          {state.studentId ? (
            <>
              <div className="col adjust-deposit-fee__individual--data--deposit-details">
                <Title>
                  <img src={DepositDetails} alt="/" /> Deposit Details
                </Title>
                <div className="adjust-deposit-fee__individual--data--deposit-details--totalamtblock">
                  <Label> Total Deposit Balance</Label>
                  <b className="font-green">{depositAmopuntTotal}</b>
                </div>
              </div>
              <div className="col adjust-deposit-fee__individual--data--total-deposits">
                <Title>Total Deposits</Title>
                <div className="adjust-deposit-fee__individual--data--total-deposits--table">
                  <StyledDatagrid
                    columns={columns}
                    pinnedRows={pinnedRows}
                    getCellClassName={getCellClassName}
                    rowHeight={TABLE_ROW_HEIGHT}
                    rows={rows}
                    hideFooter
                  />
                </div>
              </div>

              <div className="col-4 adjust-deposit-fee__individual--data--adjust-deposit">
                <Title>Adjust Deposits</Title>
                <div className="label-grid">
                  <Label>Fee Deposit From</Label>
                  <Input
                    disabled
                    value={
                      feeDepositSelected
                        ? feeDepositSelected.label
                        : EMPTY_STRING
                    }
                  />
                </div>
                {/* <b className="nodata">
                  balance :
                  {StudentDepositData?.data?.GetAcctStdDeposits?.edges.find(
                    (res) =>
                      res.node.acct_ldgr_details.id ===
                      feeDepositSelected?.value
                  )?.node.deposit_bal || 0 - Number(amount)}
                </b> */}

                <div className="label-grid">
                  <Label>To Ledger</Label>
                  <Input
                    disabled
                    value={
                      feeLedgerSelected ? feeLedgerSelected.label : EMPTY_STRING
                    }
                  />
                  <Label>Amount Adjusted</Label>
                  {adjustFlag && (
                    <Input className="totalcount" disabled value={amount} />
                  )}
                </div>
              </div>
            </>
          ) : null}
        </div>

        <Button
          mode="save"
          onClick={HandleSubmit}
          disabled={!adjustFlag && amount === 0}
        />
        {pageType === PageFor.MODAL ? (
          <Button
            mode="cancel"
            onClick={() => setDepositAdjustmentIndividualModal(false)}
          />
        ) : (
          <Button mode="back" onClick={() => naviagte(-1)} />
        )}
      </div>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={printModal}
        style={PrintModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <PrintNonDemandReceipt
              tableData={tableData}
              v_date={v_date}
              v_no={v_no}
              transaction_no={referenceNumber}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => {
                SetPrintModal(!printModal);
                dispatch({
                  type: payloadTypes.SET_STUDENT_ID,
                  payload: { studentId: 0 },
                });
                handleClear()
                // handleClear();
              }}
            />
         
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={studentModal}
        style={StudentModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentList
              pageType={PageFor.MODAL}
              studentListFor={StudentListFor.ACCOUNTS}
              queryType={StudentAcctReportType.FEE_RECEIPT}
              setStudentModal={setStudentModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStudentModal(!studentModal)}
            />
          </div>
        </div>
      </Modal>
      <LoadingModal flag={loading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default IndividualAdjustDepositFee;
