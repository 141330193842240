import { gql } from "@apollo/client";

export const GetAcctFineSlabByInstId = gql`
  query GetAcctFineSlabByInstId($token: String!, $inst_id: ID!) {
    GetAcctFineSlabByInstId(token: $token, inst_id: $inst_id) {
      id
      acct_fine_desc
      acct_fine_type
      roi
      days
      fine_amt
      recurring
      inst_id
    }
  }
`;
export const GetAcctFineSlabById = gql`
  query GetAcctFineSlabById($token: String!, $id: ID!) {
    node(token: $token, id: $id) {
      id
      ... on AcctFineSlab {
        id
        acct_fine_desc
        acct_fine_type
        roi
        days
        fine_amt
        recurring
        inst_id
      }
    }
  }
`;
