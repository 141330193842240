import "./button.scss";
import Save from "../../images/Save.svg";
import Update from "../../images/Update.svg";
import Add from "../../images/Add.svg";
import DeleteButton from "../../images/DeleteButton.svg";
import Print from "../../images/Print.svg";
import View from "../../images/View.svg";
import Previous from "../../images/Previous.svg";
import Next from "../../images/Next.svg";
import ReOrder from "../../images/ReOrder.svg";
import Skip from "../../images/Skip.svg";
import Clear from "../../images/Clear.svg";
import Active from "../../images/Active.svg";
import Fetch from "../../images/Fetch.svg";
import Insert from "../../images/Insert.svg";
import Generate from "../../images/Generate.svg";
import Received from "../../images/Received.svg";
import Pending from "../../images/PendingDemand.svg";
import Transfer from "../../images/TransferAmount.svg";
import Rewrite from "../../images/ReWriteDemand.svg";
import PermissionLetter from "../../images/PermissionLetter.svg";
import LogSheet from "../../images/LogSheet.svg";
import No from "../../images/No.svg";
import viewgraph from "../../images/ViewGraphically.svg";

import AllTransaction from "../../images/AllTransaction.svg";
import ReConcile from "../../images/ReConcile.svg";

import CopyDemand from "../../images/CopyDemand.svg";
import Collected from "../../images/Collected.svg";
import Excel from "../../images/Excel.svg";
import DayEnd from "../../images/DayEnd.svg";
import BookIssue from "../../images/BookIssue.svg";
import BookReturn from "../../images/BookReturn.svg";
import BookReservation from "../../images/BookReserve.svg";
import BookRenewal from "../../images/BookRenewal.svg";
import Preview from "../../images/Eye.svg";
import Upload from "../../images/Upload.svg";
import WaiveOff from "../../images/WaiveOff.svg";
import AddNewFee from "../../images/AddNewFee.svg";
import MoveTo from "../../images/Transfer.svg";
import Configure from "../../images/Configure.svg";
import CashReceipts from "../../images/CashReceipts.svg";
import AdjustmentReceipts from "../../images/AdjustmentReceipts.svg";
import BankReceipts from "../../images/BankReceipts.svg";
import Cancel from "../../images/CancelButton.svg";
import Yes from "../../images/Yes.svg";
import RaiseStudentDemand from "../../images/RaiseStudentDemand.svg";
import ExtendCalendar from "../../images/ExtendCalendar.svg";
import Reset from "../../images/Reset.svg";
import Recalculate from "../../images/ReCalculate.svg";
import Absent from "../../images/AbsentList.svg";
import Select from "../../images/Select.svg";
import PayOnline from "../../images/PayOnline.svg";
import UserRights from "../../images/User_Rights.svg";
import DownloadPdf from "../../images/DownloadPdf.svg";
import TransferReceipt from "../../images/TransferReceipt.svg";
import Approve from "../../images/Approve.svg";
import Forward from "../../images/Forward.svg";
import Reject from "../../images/Rejected.svg";
import Assign from "../../images/Assign.svg";
import PayFee from "../../images/ProceedToPay.svg";
import Grade from "../../images/Grade.svg";
import Selected from "../../images/Collected.svg";
import Send from "../../images/Send.svg";
import Message from "../../images/Chat.svg";
import useUserRightsByEmpId from "../../Modules/UserRights/hooks/useUserRightsByEmpId";
// import { useContext } from "react";
// import { AppContext } from "../../context/context";

interface ButtonProps {
  autoFocus?: boolean;
  primary?: boolean;
  secondary?: boolean;

  backgroundColor?: string;

  mode?:
    | "addnew"
    | "create"
    | "cancel"
    | "back"
    | "edit"
    | "view"
    | "clear"
    | "demand"
    | "print"
    | "save"
    | "reorder"
    | "skip"
    | "ledger"
    | "account-ledger"
    | "delete"
    | "update"
    | "save-continue"
    | "finish"
    | "active"
    | "save-order"
    | "fetch"
    | "insert"
    | "yes"
    | "no"
    | "generate"
    | "received"
    | "pending"
    | "previous-receipt"
    | "transfer"
    | "passout-students"
    | "rewrite-demand"
    | "statement"
    | "demand-details"
    | "permission"
    | "log-sheet"
    | "re-concile"
    | "submit"
    | "pre-defined"
    | "page-config"
    | "copy-demand"
    | "copy-test"
    | "copy"
    | "move"
    | "predefined-type"
    | "default"
    | "ledger-report"
    | "collection-report"
    | "excel"
    | "day-end"
    | "issue-book"
    | "return-book"
    | "reserve-book"
    | "renewal-book"
    | "sign-in"
    | "sign-out"
    | "make-elligible"
    | "preview"
    | "upload"
    | "collect-fine"
    | "waive-off"
    | "add-new-fee"
    | "all-transaction"
    | "configure"
    | "show-summarized"
    | "all-receipts"
    | "cash-receipts"
    | "bank-receipts"
    | "adjustment-receipts"
    | "okay"
    | "extend"
    | "approve"
    | "forward"
    | "reject"
    | "reset"
    | "re-calculate"
    | "export"
    | "view-graph"
    | "absent"
    | "update-continue"
    | "continue"
    | "select"
    | "pay-online"
    | "remove"
    | "user-rights"
    | "assign-salary-structure"
    | "assign"
    | "previous"
    | "pdf"
    | "move-to"
    | "make-active"
    | "transfer-receipt"
    | "allocate"
    | "otp"
    | "pay-fee"
    | "start-posting"
    | "add-depositledgers"
    | "add-doctype"
    | "fill-diary"
    | "next"
    | "grade"
    | "pay-only-ob"
    | "pay-newdemand-also"
    | "fee-details"
    | "close"
    | "docs-upload"
    | "docs-download"
    | "docs-delete"
    | "send"
    | "selected"
    | "message";

  /**
   * Button contents
   */
  label?: string;
  /**
   * Optional click handler
   */
  onClick?: any;

  disabled?: boolean;
  type?: "submit" | "reset" | "button" | undefined;
  children?: any;
  onKeyDown?: any;
  className?: String;
  buttonref?: React.LegacyRef<HTMLButtonElement> | undefined;
}

/**
 * Primary UI component for user interaction
 */
export const Button = ({
  mode = "default",
  onClick,
  children,
  onKeyDown,
  autoFocus,
  type,
  disabled,
  className,
  buttonref,
}: ButtonProps) => {
  const {
    // USE_ACADEMICS,
    USE_ACCOUNTS,
    USE_ENQUIRY,
    USE_LIBRARY,
    // USE_MESSAGE_APP,
    USE_PAYROLL,
    USE_ADMISSIONS,
    // USE_TRANSPORT,
  } = useUserRightsByEmpId();
  // const { state } = useContext(AppContext);

  // const hanldeButtonDisable = () => {
  //   const modules = [
  //     USE_ACCOUNTS,
  //     USE_LIBRARY,
  //     USE_ACADEMICS,
  //     USE_ENQUIRY,
  //     USE_PAYROLL,
  //     USE_TRANSPORT,
  //     USE_MESSAGE_APP,
  //   ];
  //   let bool = false;
  //   for (const module of modules) {
  //     bool = module.flag;
  //     if (bool && state.claims?.EMPLOYEE) {
  //       const { view, add, delete: Delete, edit } = module.details || {};

  //       switch (mode) {
  //         case "view":
  //           return view === false;
  //         case "addnew":
  //         case "save":
  //           return add === false;
  //         case "upload":
  //         case "save-order":
  //
  //           return add === false;
  //         case "delete":
  //           return Delete === false;
  //         case "edit":
  //           return !(edit );
  //         default:
  //           return false;
  //       }
  //     }
  //     bool = false;
  //   }
  // };

  const hanldeButtonDisable = () => {
    if (USE_ACCOUNTS.flag && window.location.pathname.includes("/accounts")) {
      const { view, add, delete: Delete, edit } = USE_ACCOUNTS.details || {};

      switch (mode) {
        case "view":
          return view === false;
        case "addnew":
        case "upload":
        case "save-order":
        case "pay-only-ob":
        case "pay-newdemand-also":
          return add === false;
        case "save":
          return add === false;
        case "delete":
          return Delete === false;
        case "edit":
          return edit === false;
        case "add-depositledgers":
          return add === false;
        default:
          return false;
      }
    } else if (
      USE_LIBRARY.flag &&
      window.location.pathname.includes("/library")
    ) {
      const { view, add, delete: Delete, edit } = USE_LIBRARY.details || {};

      switch (mode) {
        case "view":
          return view === false;
        case "addnew":
        case "issue-book":
        case "return-book":
        case "renewal-book":
          return add === false;
        case "delete":
          return Delete === false;
        case "edit":
          return edit === false;
        case "save":
          return edit || add ? false : true;
        default:
          return false;
      }
    } else if (
      USE_ENQUIRY.flag &&
      window.location.pathname.includes("/enquiry")
    ) {
      const { view, add, delete: Delete, edit } = USE_ENQUIRY.details || {};
      switch (mode) {
        case "view":
          return view === false;
        case "addnew":
        case "upload":
        case "save-order":
          return add === false;
        case "save":
          if (window.location.pathname.includes("/enquiry/followup"))
            return edit ? false : true;
          return add === false;

        case "delete":
          return Delete === false;
        case "edit":
          return edit === false;

        default:
          return false;
      }
    } else if (USE_PAYROLL.flag && window.location.pathname.includes("/hr")) {
      const { view, add, delete: Delete, edit } = USE_PAYROLL.details || {};
      switch (mode) {
        case "view":
          return view === false;
        case "addnew":
        case "upload":
        case "save-order":
          return add === false;
        case "save":
          return add === false;

        case "delete":
          return Delete === false;
        case "edit":
        case "update":
        case "update-continue":
        case "assign-salary-structure":
          return edit === false;

        default:
          return false;
      }
    } else if (
      USE_ADMISSIONS.flag &&
      window.location.pathname.includes("/admissions")
    ) {
      const { view, add, delete: Delete, edit } = USE_ADMISSIONS.details || {};
      switch (mode) {
        case "view":
        case "docs-download":
          return view === false;
        case "addnew":
        case "upload":
        case "save-order":
        case "docs-upload":
          return add === false;
        case "save":
        case "active":
        case "reorder":
          return add === false;

        case "delete":
        case "docs-delete":
          return Delete === false;
        case "edit":
        case "update":
        case "update-continue":
        case "assign-salary-structure":
        case "add-doctype":
          return edit === false;

        default:
          return false;
      }
    }
  };

  return (
    <button
      disabled={disabled || hanldeButtonDisable()}
      type={type}
      autoFocus={autoFocus}
      onClick={onClick}
      ref={buttonref!}
      className={[
        "storybook-button",
        `storybook-button--${mode}`,
        className,
      ].join(" ")}
      onKeyDown={onKeyDown}>
      {mode && mode === "save" ? (
        <>
          <img src={Save} alt="/" />
          Save
        </>
      ) : mode === "edit" ? (
        <>
          <img src={Update} alt="/" />
          Edit
        </>
      ) : mode === "message" ? (
        <>
          <img src={Message} alt="/" />
          Message
        </>
      ) : mode === "send" ? (
        <>
          <img src={Send} alt="/" />
          Send Message
        </>
      ) : mode === "selected" ? (
        <>
          <img src={Selected} alt="/" />
          Select Multiple Students
        </>
      ) : mode === "addnew" ? (
        <>
          <img src={Add} alt="/" />
          Add
        </>
      ) : mode === "create" ? (
        <>
          <img src={Add} alt="/" />
          Create
        </>
      ) : mode === "otp" ? (
        <>
          <img src={Add} alt="/" />
          Request OTP
        </>
      ) : mode === "clear" ? (
        <>
          <img src={Clear} alt="/" />
          Clear
        </>
      ) : mode === "print" ? (
        <>
          <img src={Print} alt="/" />
          Print
        </>
      ) : mode === "skip" ? (
        <>
          <img src={Skip} alt="/" />
          Skip
        </>
      ) : mode === "next" ? (
        <>
          Next &nbsp;
          <img src={Next} alt="/" />
        </>
      ) : mode === "back" ? (
        <>
          <img src={Previous} alt="/" />
          Back
        </>
      ) : mode === "reorder" ? (
        <>
          <img src={ReOrder} alt="/" />
          ReOrder
        </>
      ) : mode === "cancel" ? (
        <>
          <img src={Cancel} alt="/" />
          Cancel
        </>
      ) : mode === "delete" ? (
        <>
          <img src={DeleteButton} alt="/" />
          Delete
        </>
      ) : mode === "fill-diary" ? (
        <>
          <img src={Assign} alt="/" />
          Fill the Diary
        </>
      ) : mode === "remove" ? (
        <>
          <img src={Cancel} alt="/" />
          Remove
        </>
      ) : mode === "view" ? (
        <>
          <img src={View} alt="/" />
          View
        </>
      ) : mode === "fee-details" ? (
        <>
          <img src={View} alt="/" />
          Fee Details
        </>
      ) : mode === "update-continue" ? (
        <>
          <img src={Update} alt="/" />
          Update & Continue
        </>
      ) : mode === "continue" ? (
        <>
          Continue
          <img src={Assign} alt="/" />
        </>
      ) : mode === "update" ? (
        <>
          <img src={Update} alt="/" />
          Update
        </>
      ) : mode === "save-continue" ? (
        <>
          <img src={Save} alt="/" />
          Save & Continue
        </>
      ) : mode === "select" ? (
        <>
          <img src={Select} alt="/" />
          Select
        </>
      ) : mode === "finish" ? (
        <>
          <img src={View} alt="/" /> Finish
        </>
      ) : mode === "active" ? (
        <>
          <img src={Active} alt="/" />
        </>
      ) : mode === "save-order" ? (
        <>
          <img src={Save} alt="/" />
          Save Order
        </>
      ) : mode === "ledger" ? (
        <>
          <>
            <img src={Add} alt="/" />
            Add Fee Ledger
          </>
        </>
      ) : mode === "account-ledger" ? (
        <>
          <>
            <img src={Add} alt="/" />
            Add Account Ledger
          </>
        </>
      ) : mode === "fetch" ? (
        <>
          <img src={Fetch} alt="/" />
          Fetch
        </>
      ) : mode === "demand" ? (
        <>
          <img src={RaiseStudentDemand} alt="/" />
          Raise Student Demand
        </>
      ) : mode === "insert" ? (
        <>
          <img src={Insert} alt="/" />
          Insert
        </>
      ) : mode === "yes" ? (
        <>
          <img src={Yes} alt="/" />
          Yes
        </>
      ) : mode === "okay" ? (
        <>
          <img src={Yes} alt="/" />
          Okay
        </>
      ) : mode === "approve" ? (
        <>
          <img src={Approve} alt="/" />
          Approve
        </>
      ) : mode === "forward" ? (
        <>
          <img src={Forward} alt="/" />
          Forward
        </>
      ) : mode === "reject" ? (
        <>
          <img src={Reject} alt="/" />
          Reject
        </>
      ) : mode === "no" ? (
        <>
          <img src={No} alt="/" />
          No
        </>
      ) : mode === "generate" ? (
        <>
          <img src={Generate} alt="/" />
          Generate
        </>
      ) : mode === "received" ? (
        <>
          <img src={Received} alt="/" />
          Received
        </>
      ) : mode === "pending" ? (
        <>
          <img src={Pending} alt="/" />
          get All students
        </>
      ) : mode === "previous-receipt" ? (
        <>
          <img src={Print} alt="/" />
          Print Previous Receipt
        </>
      ) : mode === "transfer" ? (
        <>
          <img src={Transfer} alt="/" /> Transfer
        </>
      ) : mode === "transfer-receipt" ? (
        <>
          <img src={TransferReceipt} alt="/" /> Transfer Receipt
        </>
      ) : mode === "move-to" ? (
        <>
          <img src={MoveTo} alt="/" /> Move to
        </>
      ) : mode === "make-active" ? (
        <>
          <img src={MoveTo} alt="/" /> Make Active
        </>
      ) : mode === "allocate" ? (
        <>
          <img src={MoveTo} alt="/" /> Allocate to
        </>
      ) : mode === "passout-students" ? (
        <>
          <img src={Add} alt="/" /> Add Alumini Students
        </>
      ) : mode === "rewrite-demand" ? (
        <>
          <img src={Rewrite} alt="/" />
          Re-Wrtie Demand
        </>
      ) : mode === "statement" ? (
        <>
          <img src={Print} alt="/" />
          Print A/C Statement
        </>
      ) : mode === "permission" ? (
        <>
          <img src={PermissionLetter} alt="/" />
          Permission Letter
        </>
      ) : mode === "demand-details" ? (
        <>
          <img src={Print} alt="/" />
          Print Demand Details
        </>
      ) : mode === "log-sheet" ? (
        <>
          <img src={LogSheet} alt="/" />
          Log Sheet
        </>
      ) : mode === "re-concile" ? (
        <>
          <img src={ReConcile} alt="/" />
          Auto ReConcil All Students Receipt
        </>
      ) : mode === "submit" ? (
        <>
          <img src={Save} alt="/" />
          Submit Details
        </>
      ) : mode === "pre-defined" ? (
        <>Pre Defined</>
      ) : mode === "page-config" ? (
        <>Page Configuration</>
      ) : mode === "copy-demand" ? (
        <>
          <img src={CopyDemand} alt="/" />
          Copy Demand Details
        </>
      ) : mode === "copy-test" ? (
        <>
          <img src={CopyDemand} alt="/" />
          Copy Test Details
        </>
      ) : mode === "copy" ? (
        <>
          <img src={CopyDemand} alt="/" />
        </>
      ) : mode === "move" ? (
        <>
          <img src={Save} alt="/" />
          Move
        </>
      ) : mode === "predefined-type" ? (
        <>
          <img src={Add} alt="/" /> Add Predefined Type
        </>
      ) : mode === "ledger-report" ? (
        <img src={Fetch} alt="/" />
      ) : mode === "collection-report" ? (
        <img src={Collected} alt="/" />
      ) : mode === "excel" ? (
        <img src={Excel} alt="/" />
      ) : mode === "grade" ? (
        <>
          {" "}
          <img src={Grade} alt="/" /> Grade Configuration
        </>
      ) : mode === "day-end" ? (
        <>
          <img src={DayEnd} alt="/" />
          Day End
        </>
      ) : mode === "issue-book" ? (
        <>
          <img src={BookIssue} alt="/" />
          Issue Book
        </>
      ) : mode === "return-book" ? (
        <>
          <img src={BookReturn} alt="/" />
          Return Book
        </>
      ) : mode === "reserve-book" ? (
        <>
          <img src={BookReservation} alt="/" />
          Reserve Book
        </>
      ) : mode === "renewal-book" ? (
        <>
          <img src={BookRenewal} alt="/" />
          Renewal Book
        </>
      ) : mode === "sign-in" ? (
        <>
          Login <img src={Assign} alt="" />{" "}
        </>
      ) : mode === "sign-out" ? (
        <>Log Out</>
      ) : mode && mode === "make-elligible" ? (
        <>
          <img src={Save} alt="/" />
          Make Eligible
        </>
      ) : mode && mode === "preview" ? (
        <>
          <img src={Preview} alt="/" />
          Preview
        </>
      ) : mode && mode === "previous" ? (
        <>
          <img src={Previous} alt="/" />
          Previous
        </>
      ) : mode && mode === "upload" ? (
        <>
          <img src={Upload} alt="/" />
          Upload
        </>
      ) : mode && mode === "collect-fine" ? (
        <>
          <img src={Received} alt="/" />
          Collect Fine
        </>
      ) : mode && mode === "waive-off" ? (
        <>
          <img src={WaiveOff} alt="/" />
          Waive-Off
        </>
      ) : mode && mode === "add-new-fee" ? (
        <>
          <img src={AddNewFee} alt="/" />
        </>
      ) : mode && mode === "all-transaction" ? (
        <>
          <img src={AllTransaction} alt="/" />
          All Transaction
        </>
      ) : mode && mode === "configure" ? (
        <>
          <img src={Configure} alt="/" />
          Configure
        </>
      ) : mode && mode === "show-summarized" ? (
        <>
          <img src={AllTransaction} alt="/" />
          Show Summarized
        </>
      ) : mode && mode === "bank-receipts" ? (
        <>
          <img src={BankReceipts} alt="/" />
          Bank Receipts
        </>
      ) : mode && mode === "cash-receipts" ? (
        <>
          <img src={CashReceipts} alt="/" />
          Cash Receipts
        </>
      ) : mode && mode === "all-receipts" ? (
        <>
          <img src={AllTransaction} alt="/" />
          All Receipts
        </>
      ) : mode && mode === "adjustment-receipts" ? (
        <>
          <img src={AdjustmentReceipts} alt="/" />
          Adjust Receipts
        </>
      ) : mode && mode === "extend" ? (
        <>
          <img src={ExtendCalendar} alt="/" />
        </>
      ) : mode && mode === "reset" ? (
        <>
          <img src={Reset} alt="/" />
        </>
      ) : mode && mode === "re-calculate" ? (
        <>
          <img src={Recalculate} alt="/" />
        </>
      ) : mode && mode === "export" ? (
        <>
          <img src={Excel} alt="/" />
          Export
        </>
      ) : mode && mode === "view-graph" ? (
        <img src={viewgraph} alt="/" />
      ) : mode && mode === "absent" ? (
        <img src={Absent} alt="/" />
      ) : mode && mode === "pay-online" ? (
        <>
          <img src={PayOnline} alt="/" />
          Pay Online
        </>
      ) : mode && mode === "user-rights" ? (
        <>
          <img src={UserRights} alt="/" />
          Assign Rights
        </>
      ) : mode && mode === "assign-salary-structure" ? (
        <>
          <img src={Save} alt="/" />
          Assign Salary Structure
        </>
      ) : mode && mode === "assign" ? (
        <>
          <img src={Assign} alt="/" />
          Assign
        </>
      ) : mode && mode === "pdf" ? (
        <>
          <img src={DownloadPdf} alt="/" />
          Download
        </>
      ) : mode === "pay-fee" ? (
        <>
          <img src={PayFee} alt="/" />
          Pay Fee
        </>
      ) : mode === "start-posting" ? (
        <>
          <img src={Transfer} alt="/" /> Start Posting
        </>
      ) : mode === "add-depositledgers" ? (
        <>
          <img src={Add} alt="/" /> Add Deposit Ledgers
        </>
      ) : mode === "add-doctype" ? (
        <>
          <img src={Add} alt="/" />
          Add Document Type
        </>
      ) : mode === "pay-only-ob" ? (
        <>
          <img src={PayFee} alt="/" />
          Pay only OB
        </>
      ) : mode === "pay-newdemand-also" ? (
        <>
          <img src={PayFee} alt="/" />
          Pay New Balance
        </>
      ) : mode === "close" ? (
        <>
          <img src={Cancel} alt="/" />
          Close
        </>
      ) : mode === "docs-upload" ? (
        <>
          Upload
          <img src={Upload} alt="/" />
        </>
      ) : mode === "docs-delete" ? (
        <>
          Delete
          <img src={DeleteButton} alt="/" />
        </>
      ) : mode === "docs-download" ? (
        <>
          <img src={Upload} alt="/" />
          Close
        </>
      ) : null}

      {children}
    </button>
  );
};
