import { gql } from "@apollo/client";

export const AddAcctDemandMainAndDetails = gql`
  mutation AddAcctDemandMainAndDetails(
    $token: String!
    $inst_id: ID!
    $fin_yr_id: ID!
    $category_id: ID!
    $class_id: [ID!]!
    $user_details: SubscribedUser!
    $branch_id: ID!
    $acct_demand_details: [CreateAcctDemandDetailsInput!]!
    $acct_demand_main: CreateAcctDemandMainInput!
  ) {
    AddAcctDemandMainAndDetails(
      token: $token
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
      category_id: $category_id
      class_id: $class_id
      branch_id: $branch_id
      user_details: $user_details
      input: {
        acct_demand_main: $acct_demand_main
        acct_demand_details: $acct_demand_details
      }
    )
  }
`;
export const UpdateAcctStdDemandDetailsDueDate = gql`
  mutation UpdateAcctStdDemandDetailsDueDate(
    $token: String!
    $inst_id: ID!
    $fin_yr_id: ID!
    $branch_id: ID!
    $class_id: ID!
    $category_id: ID!
    $input: [UpdateDemandDetailsDueDate!]!
    $user_details: SubscribedUser!
  ) {
    UpdateAcctStdDemandDetailsDueDate(
      token: $token
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
      category_id: $category_id
      class_id: $class_id
      branch_id: $branch_id
      user_details: $user_details
      input: $input
    )
  }
`;
export const updateDemandDetailsAndMainDetails = gql`
  mutation UpdateAcctDemandMainAndDetails(
    $token: String!
    $inst_id: ID!
    $update_acct_demand_details: [UpdateAcctDemandDetails!]!
    $update_acct_demand_main: UpdateAcctDemandMain!
    $user_details: SubscribedUser!
  ) {
    UpdateAcctDemandMainAndDetails(
      token: $token
      user_details: $user_details
      inst_id: $inst_id
      input: {
        update_acct_demand_main: $update_acct_demand_main
        update_acct_demand_details: $update_acct_demand_details
      }
    ) {
      id
    }
  }
`;
export const DeleteAcctDemandMain = gql`
  mutation DeleteAcctDemandMain(
    $token: String!
    $inst_id: ID!
    $fin_yr_id: ID!
    $branch_id: ID!
    $class_id: ID!
    $category_id: ID!
    $user_details: SubscribedUser!
  ) {
    DeleteAcctDemandMain(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      fin_yr_id: $fin_yr_id
      branch_id: $branch_id
      class_id: $class_id
      category_id: $category_id
    )
  }
`;
