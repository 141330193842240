import React from "react";
import Modal from "react-modal";
import Caution from "../../../images/Caution.svg";
import { WaiveOffModalStyles } from "../../../styles/ModalStyles";
import { Title } from "../../../stories/Title/Title";
import { Button } from "../../../stories/Button/Button";
import { Label } from "../../../stories/Label/Label";
import Close from "../../../images/Close.svg";
interface Props {
  isOpen: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleFinYearPost: () => void;
}
const ConfirmationModal = ({
  handleFinYearPost,
  isOpen,
  setOpenModal,
}: Props) => {
  return (
    <Modal
      shouldCloseOnOverlayClick={true}
      isOpen={isOpen}
      style={WaiveOffModalStyles}
      ariaHideApp={false}>
      <div className="modal-flex h-100">
        <div className="modal-flex__data h-100">
          <Title variant="subtitle1">
            <img src={Caution} alt="/" /> Are You Sure For Posting
          </Title>
          <Label>
            Are you sure with Proceeding With Financial Year Posting Please Re -
            Confirm
          </Label>
          <Button
            mode="yes"
            onClick={() => {
              handleFinYearPost();
              setOpenModal(false);
            }}
            autoFocus
          />
          <Button mode="cancel" onClick={() => setOpenModal(false)} />
        </div>
        <div className="modal-flex__image">
          <img src={Close} alt="/" onClick={() => setOpenModal(false)} />
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
