import { reauthenticateWithCredential, updatePassword } from "firebase/auth";
import React, { useContext, useRef, useState } from "react";
import { auth } from "../../firebase";
import { EmailAuthProvider } from "firebase/auth";
import { FirebaseError } from "firebase/app";
import { Title } from "../../stories/Title/Title";
import { Button } from "../../stories/Button/Button";
import MessageModal from "../../pages/MessageModal";
import { msgType } from "../../utils/Form.types";
import { EMPTY_STRING } from "../../utils/constants";
import { Operation, UserType } from "../../utils/Enum.types";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import {
  UpdateEmpResetPwdDate,
  UpdateStdResetPwdDate,
} from "../HR/queries/employee/mutation";
import { useMutation } from "@apollo/client";
import useToken from "../../customhooks/useToken";
import { AppContext } from "../../context/context";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { handleSignOut } from "../../components/common/Header";
import useLoggedInUserDetails from "../Accounts/hooks/useLoggedInUserDetails";
import { CustomTooltip, TOOLTIP_COLORS } from "../../styles/TooltipStyles";
import LoadingModal from "../../pages/LoadingModal";
import { Keys } from "../../utils/Enum.keys";
interface Props {
  setPasswordModal: React.Dispatch<React.SetStateAction<boolean>>;
  passwordMesg: string;
}
const ResetPassword = ({ setPasswordModal, passwordMesg }: Props) => {
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const { user_details } = useLoggedInUserDetails();
  const { user_type } = user_details;
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [passwordValidationMessage, setPasswordValidationMessage] =
    useState("");
  const newPasswordRef = useRef<HTMLInputElement>(null);
  const confirmPasswordRef = useRef<HTMLInputElement>(null);
  const saveRef = useRef<HTMLButtonElement>(null);

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [UpdatePwdDate, { loading: empLoading }] = useMutation(
    UpdateEmpResetPwdDate
  );
  const [UpdateStdPwdDate, { loading: stdLoading }] = useMutation(
    UpdateStdResetPwdDate
  );
  const isPasswordValid = (password: string) => {
    const passwordRegex =
      /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+].*[!@#$%^&*()_+]).*$/;
    return passwordRegex.test(password);
  };
  const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPasswordValue = e.target.value;
    setNewPassword(newPasswordValue);

    if (!isPasswordValid(newPasswordValue)) {
      setPasswordValidationMessage(
        "Password must be at least 8 characters long and include at least two of each of the following: lowercase letters, uppercase letters, numbers, and special characters."
      );
    } else {
      setPasswordValidationMessage("");
    }
  };
  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const confirmPasswordValue = e.target.value;
    setConfirmPassword(confirmPasswordValue);

    if (newPassword !== confirmPasswordValue) {
      setPasswordValidationMessage("Passwords do not match.");
    } else {
      setPasswordValidationMessage("");
    }
  };
  const updatePasswordWithReauthentication = () => {
    const currentUser = auth.currentUser;

    const credential = EmailAuthProvider.credential(
      currentUser?.email!,
      oldPassword
    );
    if (oldPassword === newPassword) {
      setMessage({
        flag: true,
        message:
          "Please choose a new password that is distinct from your previous password to ensure the safety of your account",
        operation: Operation.NONE,
      });
      return;
    }
    if (newPassword !== confirmPassword) {
      setMessage({
        flag: true,
        message: "Passwords do not match",
        operation: Operation.NONE,
      });
      return;
    }
    if (passwordValidationMessage.length !== 0) {
      setMessage({
        flag: true,
        message:
          "Password must be at least 8 characters long and include at least two of each of the following: lowercase letters, uppercase letters, numbers, and special characters.",
        operation: Operation.NONE,
      });
      return;
    }
    if (currentUser) {
      reauthenticateWithCredential(currentUser, credential)
        .then(() => {
          updatePassword(currentUser, newPassword)
            .then(() => {
              setMessage({
                flag: true,
                message: "Password updated successfully",
                operation: Operation.UPDATE,
              });
              if (user_type === UserType.STUDENT) {
                UpdateStdPwdDate({
                  variables: {
                    token,
                    inst_id: state.InstId,
                    student_id: state.studentId,
                    user_details,
                  },
                });
              } else {
                UpdatePwdDate({
                  variables: {
                    token,
                    inst_id: state.InstId,
                    user_details,
                    pr_emp_id: state.empLoginId,
                  },
                });
              }
            })
            .catch((e: FirebaseError) => {
              setMessage({
                flag: true,
                message: e.message,
                operation: Operation.NONE,
              });
            });
        })
        .catch((e: FirebaseError) => {
          setMessage({
            flag: true,
            message: e.message,
            operation: Operation.NONE,
          });
        });
    }
  };
  const handleClickShowPassword = (field: string) => {
    switch (field) {
      case "current":
        setShowCurrentPassword((show) => !show);
        break;
      case "new":
        setShowNewPassword((show) => !show);
        break;
      case "confirm":
        setShowConfirmPassword((show) => !show);
        break;
      default:
        break;
    }
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const handleClear = () => {
    setOldPassword(EMPTY_STRING);
    setNewPassword(EMPTY_STRING);
    setConfirmPassword(EMPTY_STRING);
  };
  const handleBack = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
      setPasswordModal?.(false);
      handleSignOut();
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  return (
    <>
      <Title>{passwordMesg}</Title>
      <div className="change-password">
        <TextField
          required
          value={oldPassword}
          autoFocus
          className="change-password__textfield"
          label="Current Password"
          name="password"
          type={showCurrentPassword ? "text" : "password"}
          onChange={(e) => setOldPassword(e.target.value)}
          slotProps={{
            inputLabel: {
              shrink: true,
            },
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword("current")}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    size="large"
                  >
                    {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            },
          }}
          onKeyDown={(e) => {
            if (e.key === Keys.ENTER) {
              if (newPasswordRef.current) {
                newPasswordRef.current?.focus();
              }
            }
          }}
        />
        <CustomTooltip
          title={passwordValidationMessage}
          arrow
          placement="right"
          slotProps={{
            tooltip: {
              sx: {
                bgcolor: TOOLTIP_COLORS.VARIABLE,
                "& .MuiTooltip-arrow": {
                  color: TOOLTIP_COLORS.VARIABLE,
                },
              },
            },
          }}
        >
          <TextField
            required
            value={newPassword}
            label="New Password"
            inputRef={newPasswordRef}
            className="change-password__textfield"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword("new")}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="large"
                    >
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
            onKeyDown={(e) => {
              if (e.key === Keys.ENTER) {
                if (confirmPasswordRef.current) {
                  confirmPasswordRef.current?.focus();
                }
              }
            }}
            name="password"
            type={showNewPassword ? "text" : "password"}
            onChange={handleNewPasswordChange}
          />
        </CustomTooltip>

        <TextField
          required
          value={confirmPassword}
          label="Confirm Password"
          inputRef={confirmPasswordRef}
          className="change-password__textfield"
          slotProps={{
            inputLabel: {
              shrink: true,
            },
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword("confirm")}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    size="large"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            },
          }}
          name="password"
          type={showConfirmPassword ? "text" : "password"}
          onChange={handleConfirmPasswordChange}
          onKeyDown={(e) => {
            if (e.key === Keys.ENTER) {
              if (saveRef.current) {
                saveRef.current.focus();
              }
            }
          }}
        />
      </div>
      <Button
        type="submit"
        mode="save"
        onClick={updatePasswordWithReauthentication}
        buttonref={saveRef}
      />
      <LoadingModal flag={empLoading || stdLoading} />
      <Button type="button" mode="clear" onClick={handleSignOut} />
      <MessageModal
        modalFlag={message.flag}
        value={message.message}
        handleClose={handleBack}
        operation={message.operation}
      />
    </>
  );
};

export default ResetPassword;
