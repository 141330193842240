import { useContext, useEffect, useState } from "react";
import { Title } from "../../../stories/Title/Title";
import More from "../../../images/More.svg";
import BankCollection from "../../../images/BankCollection.svg";
import FeeCollection from "../../../images/FeeCollection.svg";
import CashCollection from "../../../images/CashCollection.svg";
import BankExpense from "../../../images/BankExpense.svg";
import CashExpense from "../../../images/CashExpense.svg";
import CashBalance from "../../../images/CashBalance.svg";
import Payments from "../../../images/Payments.svg";
import Close from "../../../images/Close.svg";
import { Tooltip } from "@mui/material";
import { useLazyQuery } from "@apollo/client";
import {
  GetAcctDashBoardDetails,
  GetOwnersAcctDashBoardDetails,
} from "../queries/DashBoard";
import {
  AcctDashBoardDetails,
  GetAcctDashBoardDetailsData,
  GetAcctDashBoardDetailsVars,
  GetOwnersAcctDashBoardDetailsData,
  GetOwnersAcctDashBoardDetailsVars,
  instTotalStats,
} from "./types";
import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import {
  formatter,
  toIsoDate,
  toStandardDate,
} from "../../../utils/UtilFunctions";
import LoadingModal from "../../../pages/LoadingModal";
import useServerDateandTime from "../../Library/customHooks/useServerDateandTime";
import Balance from "../../../images/Balance.svg";
import BankBalance from "../../../images/BankBalance.svg";
import Modal from "react-modal";
import { StudentModalStyles } from "../../../styles/ModalStyles";
import CompleteTransactionModal from "../Reports/AccountLedgerBook/Modals/CompleteTransactionModal";
import { DashBoardFor, PageFor } from "../../../utils/Enum.types";
import PaymentVoucherRegister from "../Reports/PaymentAndGeneralRegister/Index";
import DemandSummary from "../FeeDemand/DemandSummary/TableView";

import { AcctDashboard, VoucherQueryTypes } from "../common/Enum.types";
import ForProprietor from "../../Proprietor/Dashboard/Accounts";
import { AppContext } from "../../../context/context";
import EmpBottomDetails from "./EmpBottomDetails";
import DashboardBalance from "./DashBoardBalance";
import { LedgersOrRegisters } from "../../../utils/types";
import usePollIntervalTime from "../../../customhooks/usePollIntervalTime";
import { payloadTypes } from "../../../context/reducer";
import DemandDetails from "./DemandDetails";

interface Props {
  type: LedgersOrRegisters;
  pageType: DashBoardFor;
  query_type: AcctDashboard;
}

const CompleteDetails = ({ type, pageType, query_type }: Props) => {
  const { InstId } = useParams();
  const [feeCollectionModal, setFeeCollectionModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [demandSummaryModal, setDemandSummaryModal] = useState(false);
  const { format } = formatter;
  const { token } = useToken();
  const { serverDate } = useServerDateandTime();
  const { state, dispatch } = useContext(AppContext);
  const [dashBoardDetails, setDahbordDetails] = useState<AcctDashBoardDetails>({
    total_fee_collection: 0,
    total_receipts_count: 0,
    fee_collection_bank: 0,
    fee_collection_cash: 0,
    cash_receipts_count: 0,
    bank_receipts_count: 0,
    total_payments: 0,
    total_payments_count: 0,
    payments_bank: 0,
    payments_cash: 0,
    cash_payments_count: 0,
    bank_payments_count: 0,
    total_balance: 0,
    bank_balance: 0,
    cash_balance: 0,
    total_demand: 0,
    total_received: 0,
    total_demand_balance: 0,
    upi_receipts_count: 0,
    cheque_receipts_count: 0,
    dd_receipts_count: 0,
    neft_receipts_count: 0,
    imps_receipts_count: 0,
    cardswipe_receipts_count: 0,
    total_std_demand: 0,
    total_std_ob: 0,
    total_std_concession: 0,
    total_std_refund: 0,
  });
  const [instStats, setInstStats] = useState<instTotalStats[]>([]);
  const pollIntervalTime = usePollIntervalTime();

  const [GetEmpDashBoardDetails, { data: EmpDashBoardData, loading }] =
    useLazyQuery<GetAcctDashBoardDetailsData, GetAcctDashBoardDetailsVars>(
      GetAcctDashBoardDetails,
      {
        variables: {
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          inst_id: InstId!,
          token,
          today_date: toIsoDate(serverDate),
          query_type,
        },
        pollInterval: pollIntervalTime,
      }
    );

  const [
    GetOwnerDashBoardDetails,
    { loading: ownerDashboardLoading, data: OwnerDashBoardData },
  ] = useLazyQuery<
    GetOwnersAcctDashBoardDetailsData,
    GetOwnersAcctDashBoardDetailsVars
  >(GetOwnersAcctDashBoardDetails, {
    variables: {
      token,
      today_date: toIsoDate(serverDate),
      cust_id: state.custId,
      query_type,
    },
    pollInterval: pollIntervalTime,
  });

  useEffect(() => {
    if (token && InstId && state.ActiveFinYr && query_type && serverDate) {
      GetEmpDashBoardDetails().then(({ data }) => {
        if (data && data.GetAcctDashBoardDetails) {
          setDahbordDetails(data.GetAcctDashBoardDetails);
        }
      });
    }
  }, [
    token,
    InstId,
    state.ActiveFinYr,
    GetEmpDashBoardDetails,
    query_type,
    state.claims,
    EmpDashBoardData,
    serverDate,
  ]);

  useEffect(() => {
    if (
      token &&
      state.custId &&
      state.claims &&
      state.claims.OWNER &&
      !InstId &&
      serverDate &&
      query_type
    ) {
      GetOwnerDashBoardDetails();
      if (OwnerDashBoardData) {
        const { cust_total_stats, inst_total_stats } =
          OwnerDashBoardData.GetOwnersAcctDashBoardDetails;
        setDahbordDetails(cust_total_stats);
        setInstStats(inst_total_stats);
      }
    }
  }, [
    token,
    state.custId,
    state.claims,
    GetOwnerDashBoardDetails,
    OwnerDashBoardData,
    InstId,
    serverDate,
    query_type,
  ]);

  useEffect(() => {
    dispatch({
      type: payloadTypes.SET_ACTIVE_FIN_YR,
      payload: {
        ActiveFinYr: null,
      },
    });
  }, []);

  return (
    <>
      <div className="accounts-dashboard__details">
        <div className="accounts-dashboard__details--block">
          <div className="accounts-dashboard__details--block--title">
            <Title variant="subtitle1">Fee Collection</Title>
            <img src={More} alt="/" />
          </div>
          <div className="accounts-dashboard__details--block--details">
            <img src={FeeCollection} alt="/" />
            <div className="accounts-dashboard__details--block--g-count--block">
              <span
                className={
                  dashBoardDetails.total_fee_collection > 99999
                    ? "font-14 accounts-dashboard__details--block--details--total"
                    : "accounts-dashboard__details--block--details--total"
                }>
                {format(dashBoardDetails.total_fee_collection ?? 0)}
              </span>
              <Tooltip title="Receipts" arrow placement="top">
                <span className="accounts-dashboard__details--block--details--receipts">
                  ({dashBoardDetails.total_receipts_count})
                </span>
              </Tooltip>
            </div>
          </div>
          <div className="accounts-dashboard__details--block--g-count">
            <div className="accounts-dashboard__details--block--g-count--flex separator">
              <img src={BankCollection} alt="/" />
              <div className="accounts-dashboard__details--block--g-count--block">
                <span className="accounts-dashboard__details--block--g-count--block--text">
                  Bank
                </span>
                <span
                  className={
                    dashBoardDetails.fee_collection_bank > 9999
                      ? "accounts-dashboard__details--block--g-count--block--total font-10"
                      : "accounts-dashboard__details--block--g-count--block--total"
                  }
                  onClick={() => {
                    if (state.claims?.EMPLOYEE)
                      setFeeCollectionModal(!feeCollectionModal);
                  }}>
                  {format(dashBoardDetails.fee_collection_bank ?? 0)}
                </span>
                <Tooltip title="Receipts" arrow placement="right">
                  <span className="accounts-dashboard__details--block--g-count--block--receipts">
                    ({dashBoardDetails.bank_receipts_count})
                  </span>
                </Tooltip>
              </div>
            </div>
            <div className="accounts-dashboard__details--block--g-count--flex">
              <img src={CashCollection} alt="/" />
              <div className="accounts-dashboard__details--block--g-count--block">
                <span className="accounts-dashboard__details--block--g-count--block--text">
                  Cash
                </span>
                <span
                  className={
                    dashBoardDetails.fee_collection_cash > 9999
                      ? "accounts-dashboard__details--block--g-count--block--total font-10"
                      : "accounts-dashboard__details--block--g-count--block--total"
                  }>
                  {format(dashBoardDetails.fee_collection_cash ?? 0)}
                </span>
                <Tooltip title="Receipts" arrow placement="top">
                  <span className="accounts-dashboard__details--block--g-count--block--receipts">
                    ({dashBoardDetails.cash_receipts_count})
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>

        <div className="accounts-dashboard__details--block">
          <div className="accounts-dashboard__details--block--title">
            <Title variant="subtitle1">Payments</Title>

            <img
              src={More}
              alt="/"
              // onClick={() => {
              //   if (state.claims?.EMPLOYEE) setPaymentModal(!paymentModal);
              // }}
            />
          </div>

          {/* values for  today expense*/}
          <div className="accounts-dashboard__details--block--details">
            <img src={Payments} alt="/" />
            <div className="accounts-dashboard__details--block--g-count--block">
              <span
                className={
                  dashBoardDetails.total_payments > 99999
                    ? "accounts-dashboard__details--block--details--total font-14"
                    : "accounts-dashboard__details--block--details--total"
                }>
                {format(dashBoardDetails.total_payments ?? 0)}
              </span>
              <Tooltip title="Receipts" arrow placement="top">
                <span className="accounts-dashboard__details--block--details--receipts">
                  ({dashBoardDetails.total_payments_count})
                </span>
              </Tooltip>
            </div>
          </div>
          <div className="accounts-dashboard__details--block--g-count">
            <div className="accounts-dashboard__details--block--g-count--flex separator">
              <img src={BankExpense} alt="/" />
              <div className="accounts-dashboard__details--block--g-count--block">
                <span className="accounts-dashboard__details--block--g-count--block--text">
                  Bank
                </span>
                <span
                  className={
                    dashBoardDetails.payments_bank > 9999
                      ? "accounts-dashboard__details--block--g-count--block--total font-10"
                      : "accounts-dashboard__details--block--g-count--block--total"
                  }>
                  {format(dashBoardDetails.payments_bank ?? 0)}
                </span>
                <Tooltip title="Receipts" arrow placement="top">
                  <span className="accounts-dashboard__details--block--g-count--block--receipts">
                    ({dashBoardDetails.bank_payments_count})
                  </span>
                </Tooltip>
              </div>
            </div>

            <div className="accounts-dashboard__details--block--g-count">
              <img src={CashExpense} alt="/" />
              <div className="accounts-dashboard__details--block--g-count--flex">
                <div className="accounts-dashboard__details--block--g-count--block">
                  <span className="accounts-dashboard__details--block--g-count--block--text">
                    Cash
                  </span>
                  <span
                    className={
                      dashBoardDetails.payments_cash > 9999
                        ? "accounts-dashboard__details--block--g-count--block--total font-10"
                        : "accounts-dashboard__details--block--g-count--block--total"
                    }>
                    {format(dashBoardDetails.payments_cash ?? 0)}
                  </span>
                  <Tooltip title="Receipts" arrow placement="top">
                    <span className="accounts-dashboard__details--block--g-count--block--receipts">
                      ({dashBoardDetails.cash_payments_count})
                    </span>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DashboardBalance
          title={`Closing Balance (As on ${toStandardDate(serverDate)})`}
          mainImageSrc={Balance}
          subtitle1="Bank"
          subtitle1ImgSrc={BankBalance}
          subtitle1Balance={dashBoardDetails.bank_balance}
          subtitle2="Cash"
          subtitle2ImgSrc={CashBalance}
          subtitle2balance={dashBoardDetails.cash_balance}
          totalBalance={dashBoardDetails.total_balance}
        />
      </div>

      {pageType === DashBoardFor.PROPRIETOR ? (
        <>
          <ForProprietor instStats={instStats} />
        </>
      ) : (
        <EmpBottomDetails dashBoardDetails={dashBoardDetails} />
      )}

      {/* Complete Ledger Transaction Details */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={feeCollectionModal}
        style={StudentModalStyles}>
        <div className="bank-book__table-section--transaction-details--tableblock">
          <div className="modal-flex h-100">
            <div className="modal-flex__data h-100">
              <CompleteTransactionModal
                setCompleteDetailsModal={setFeeCollectionModal}
                CompleteDetailsModal={feeCollectionModal}
                pageType={PageFor.MODAL}
                type={type}
              />
            </div>
            <div className="modal-flex__image">
              <img
                onClick={() => setFeeCollectionModal(!feeCollectionModal)}
                src={Close}
                alt="/"
                className="modal-close-icon"
              />
            </div>
          </div>
        </div>
      </Modal>
      {/* payment modal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={paymentModal}
        style={StudentModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <PaymentVoucherRegister
              type={VoucherQueryTypes.PAYMENT_VOUCHERS}
              pageType={PageFor.MODAL}
              setModalFlag={setPaymentModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              onClick={() => setPaymentModal(!paymentModal)}
              src={Close}
              alt="/"
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
      {/* demand Summary */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={demandSummaryModal}
        style={StudentModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <DemandSummary
              pageType={PageFor.MODAL}
              setModalFlag={setDemandSummaryModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              onClick={() => setDemandSummaryModal(!demandSummaryModal)}
              src={Close}
              alt="/"
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
      <LoadingModal flag={loading || ownerDashboardLoading} />
      <DemandDetails query_type={query_type} />
    </>
  );
};

export default CompleteDetails;
