import React, { useContext, useEffect, useRef, useState } from "react";
import Input from "../../../../../stories/Input/Input";
import { Label } from "../../../../../stories/Label/Label";
import {
  DebitOrCredit,
  PageNumbers,
  ReceiptTypes,
  PageFor,
  VoucherBookKey,
  StudentListFor,
  StudentAcctReportType,
  BankOrCash,
  Direction,
  SortBy,
  Operation,
  InstitutionType
} from "../../../../../utils/Enum.types";

import Modal from "react-modal";
import {
  ConfigurationsModalStyles,
  DeleteLedgerModalStyles,
  EditModalCustomStyles,
  StudentModalStyles
} from "../../../../../styles/ModalStyles";
import { Title } from "../../../../../stories/Title/Title";
import { Button } from "../../../../../stories/Button/Button";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import {
  ListAccountLedgerNameData,
  ListAccountLedgerNameVars
} from "../../../../../Types/Accounting";
import { useMutation, useLazyQuery } from "@apollo/client";
import { AppContext } from "../../../../../context/context";
import {
  DateRange,
  formatter,
  NameOfTheDay,
  toggleFullSceen,
  RefsByTagName,
  toInputStandardDate
} from "../../../../../utils/UtilFunctions";
import {
  DEFAULT_TIME,
  EMPTY_RESPONSETYPE,
  EMPTY_RESPONSETYPE_OBJECT,
  EMPTY_STRING,
  NumberOfAdmissionNumberToBeDisplayed,
  ROWS_PER_PAGE
} from "../../../../../utils/constants";
import { GetAccountLedgerNameById } from "../../../queries/FeeLedgers/query/Byid";
import { AddAcctVoucherMasterAndDetails } from "../../../queries/receipts/mutation/Index";
import dayjs from "dayjs";

import { TableHeaderProps } from "../../../../../Types/Tables";
import Home from "../../../Home/Index";
import FeeLedger from "../../../FeeLedger/Index";

import useDisplayConfigIcon from "../../../../../customhooks/useDisplayConfigIcon";
import ConfigurationSettings from "../../../../Master/configurations/general/Index";
import Avatar from "../../../../../images/Avatar.svg";
import Enter from "../../../../../images/Enter.svg";
import EditProfile from "../../../../../images/EditProfile.svg";
import AddPassoutStudentModal from "../../../../Master/Student/AddPassOutStudentModal";

import LoadingModal from "../../../../../pages/LoadingModal";
import Settings from "../../../../../images/Settings.svg";
import Enlarge from "../../../../../images/Enlarge.svg";
import Edit from "../../../../../images/Edit.svg";
import DeleteImg from "../../../../../images/Delete.svg";
import Close from "../../../../../images/Close.svg";

import useStudentDatabyId from "../../../../../customhooks/useStudentDatabyId";

import { msgType, responseType } from "../../../../../utils/Form.types";
import useAcctLedgerData from "../../../hooks/useAcctLedgerData";
import useToken from "../../../../../customhooks/useToken";
import { Keys } from "../../../../../utils/Enum.keys";
import MessageModal from "../../../../../pages/MessageModal";
import { getDownloadUrl } from "../../../../../utils/DownloadFile";
import { payloadTypes } from "../../../../../context/reducer";
import Delete from "../modals/Delete";
import useVoucherNumber from "../../../hooks/useVoucherNumber";
import { GetAcctVouchers } from "../../../queries/Vouchers/query";
import StudentList from "../../../common/StudentList";
import useAcctStdAdmissionNumber from "../../../hooks/useAcctStdAdmissionNumber";
import useServerDateandTime from "../../../../Library/customHooks/useServerDateandTime";
import useInstitutionConfiguration from "../../../../../customhooks/useInstitutionConfiguration";
import { AcctLedgerQueryType } from "../../../common/QueryTypes";
import useBillbooks from "../../../hooks/useBillbooks";
import useAcctLdgrsForCaptation from "../../../hooks/useAcctLdgrsForCaptation";
import { VoucherQueryTypes } from "../../../common/Enum.types";
import TextArea from "../../../../../stories/TextArea/TextArea";
import useAcctTableJson from "../../../json/useAcctTableJson";
import useInstLabels from "../../../../../customhooks/general/useInstLabels";
import useLoggedInUserDetails from "../../../hooks/useLoggedInUserDetails";
import {
  AddAcctVoucherMasterAndDetailsData,
  AddAcctVoucherMasterAndDetailsVars
} from "../../../../../Types/Accounting/mutations";
import useInstDetails from "../../../../../customhooks/general/useInstDetails";
import {
  labelClasses,
  LabeledAutocomplete
} from "../../../../../styles/AutocompleteListStyles";
import {
  FormAutocomplete,
  formClasses
} from "../../../../../styles/AutocompleteStyles";
import useSwConfigInstType from "../../../../Academics/hooks/useSwConfigInstType";

interface Props {
  pageType: PageFor;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}
interface itemList {
  account_ledger_id: number;
  feeAmount: number;
  sl_no: number;
  cr_db: string;
}

const Index = ({ pageType, setModalFlag }: Props) => {
  const { format } = formatter;
  let DefaultDate = new Date();
  const { institutionType } = useSwConfigInstType();

  const { Accounts_Table } = useAcctTableJson();
  const dates = DateRange(DefaultDate.toString());
  const { InstId } = useParams<{ InstId: string }>();
  const { dispatch, state } = useContext(AppContext);
  const { token } = useToken();
  const [imageString, setImageString] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const { InstDetails } = useInstDetails(1);

  const navigate = useNavigate();
  //use States for modal
  const [studentModal, setStudentModal] = useState(false);
  const [feeLedgerModal, setFeeledgerModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  //configuration modal
  const [configurationModal, setConfigurationModal] = useState(false);

  const { USE_CONFIG_KEY } = useDisplayConfigIcon(
    PageNumbers.NON_DEMAND_FEE_PAYABLE_PAGE
  );

  // eslint-disable-next-line
  const [searchData, setsearchData] = useState("");

  const [admNo, setAdmNo] = useState("");
  const { studentAddmissionNumber } = useAcctStdAdmissionNumber(
    admNo,
    NumberOfAdmissionNumberToBeDisplayed,
    StudentAcctReportType.GENERAL
  );
  const feeDescRef = useRef<HTMLSelectElement>(null);
  const feeDescInputRef = RefsByTagName(feeDescRef);
  const dueDateRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const modeOfTransactionRef = useRef<HTMLSelectElement>(null);
  const modeOfTransactionInputRef = modeOfTransactionRef.current?.childNodes[0]
    .childNodes[0].childNodes[0] as HTMLInputElement;

  const remarksRef = useRef<HTMLTextAreaElement>(null);
  const saveButtonRef = useRef<HTMLButtonElement>(null);

  const [id, setId] = useState(0);
  const [remarks, setRemarks] = useState("");
  const [feeAmount, setFeeAmount] = useState(0);
  const [account_ledger_id, SetAccountLedgerId] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE
  });

  //use state for arrays
  const [items, setItems] = useState<itemList[]>([]);

  let totalAmount = 0;

  //use queries
  const { studentData, studentFormData } = useStudentDatabyId();
  const [ledgerList, setLedgerList] = useState<responseType[]>([]);
  const { user_details } = useLoggedInUserDetails();

  const { NonDemandCapLedgers } = useAcctLdgrsForCaptation(
    AcctLedgerQueryType.CAPITATION_FEE_STD_LDGRS
  );
  const { acctLedgers: CashLedgers } = useAcctLedgerData(
    EMPTY_STRING,
    AcctLedgerQueryType.HIDDEN_CASH_ACCOUNT,
    ROWS_PER_PAGE
  );

  const { serverDate } = useServerDateandTime();
  const [GetAcctLedgerName, { data: AccountLedgerNameData }] = useLazyQuery<
    ListAccountLedgerNameData,
    ListAccountLedgerNameVars
  >(GetAccountLedgerNameById, {
    variables: {
      token,
      acct_ldgr_ids: items.map((item) => item.account_ledger_id)
    }
  });
  useEffect(() => {
    if (token) {
      GetAcctLedgerName();
    }
  }, [token, GetAcctLedgerName, items]);

  const { voucherBooks } = useBillbooks();
  const { GetStdVoucherBooks } = voucherBooks.data || {};
  const vKey = GetStdVoucherBooks?.find((book) => book.hide_this_book)
    ?.vo_book_key as VoucherBookKey;

  const { voucherNumber } = useVoucherNumber(vKey);
  const { USE_CATEGORY_KEY } = useInstitutionConfiguration();

  //lazy queries

  //use mutation
  const [
    GenerateStudentNonDemandReceipts,
    { loading: GenerateStudentNonDemandReceiptsLoading }
  ] = useMutation<
    AddAcctVoucherMasterAndDetailsData,
    AddAcctVoucherMasterAndDetailsVars
  >(AddAcctVoucherMasterAndDetails, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE
      })
  });

  const HandleItems = (e: React.FormEvent) => {
    e.preventDefault();
    const itemAlreadyExists = items.filter(
      (d) => d.account_ledger_id === account_ledger_id.value
    );
    if (itemAlreadyExists.length >= 1) {
      alert("duplicate fee Ledger entry");
      SetAccountLedgerId(EMPTY_RESPONSETYPE_OBJECT);
      setFeeAmount(0);

      feeDescInputRef?.select();
      feeDescInputRef?.focus();
      return;
    }
    const updatedData = ledgerList?.map((id) => {
      if (id.value === account_ledger_id.value) {
        const newData = {
          ...id,
          value: id.value,
          label: id.label,
          isChecked: !id.isChecked
        };
        return newData;
      }
      return id;
    });
    setLedgerList(updatedData);
    if (account_ledger_id && feeAmount) {
      setItems((items) => [
        ...items,
        {
          sl_no: items.length + 1,
          account_ledger_id: account_ledger_id.value,
          feeAmount,
          cr_db: DebitOrCredit.CREDIT
        }
      ]);
    }

    SetAccountLedgerId(EMPTY_RESPONSETYPE_OBJECT);
    setFeeAmount(0);
    feeDescInputRef?.select();
    feeDescInputRef?.focus();
  };

  const handleItemEdit = (feeAmount: number, acct_ldgr_id: number) => {
    const ledger = ledgerList.find((d) => d.value === acct_ldgr_id);
    SetAccountLedgerId(ledger!);
    setFeeAmount(feeAmount);
  };
  const HandleEditItem = (sl_number: number, e: React.FormEvent) => {
    e.preventDefault();
    const updatedData = items.map((item) => {
      if (item.sl_no === sl_number) {
        const newData = {
          ...item,
          feeAmount,
          account_ledger_id: account_ledger_id.value
        };
        return newData;
      }
      return item;
    });
    setEdit(!edit);
    setItems(updatedData);
    setFeeAmount(0);
    SetAccountLedgerId(EMPTY_RESPONSETYPE_OBJECT);
  };

  const handleItemDeleted = (i: number) => {
    items.splice(i, 1);
    setItems((items) => [...items]);
  };

  // eslint-disable-next-line
  items.map((item) => (totalAmount += item.feeAmount));
  const handleStudentReceipts = () => {
    if (CashLedgers.responseType[0].value) {
      GenerateStudentNonDemandReceipts({
        variables: {
          token,
          inst_id: InstId!,
          user_details,
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          input: [
            {
              acct_voucher_master: {
                fin_yr: state.ActiveFinYr
                  ? state.ActiveFinYr.fin_yr
                  : EMPTY_STRING,
                v_std_anonymous_deposit_adjusted: false,
                v_std_receipt_anonymous: false,
                v_std_refund_deposit: false,
                v_type: ReceiptTypes.STUDENT_RECEIPT,
                v_no: voucherNumber.data?.GetVoucherNumber.vo_number!,
                v_date: dayjs(serverDate).format(),
                v_total_cr_amt: totalAmount,
                v_total_db_amt: totalAmount,
                v_std_deposit_adjusted: false,
                v_std_receipt_ob: false,
                v_reconciled: false,
                v_reconciled_date: dayjs(DEFAULT_TIME).format(),
                v_std_receipt: true,
                v_std_enquiry: false,
                enquiry_student_id: 0,
                v_std_non_demand_receipt: true,
                v_std_refund: false,
                v_book_type: vKey,

                v_std_deposit: false,
                v_std_demand_receipt: false,
                v_std_scholarship_deposit: false,

                student_id: state.studentId ? state.studentId : 0,
                passout_student_id: state.aluminiStudentId
                  ? state.aluminiStudentId
                  : 0,
                v_std_passout_receipt: false,
                class_id: studentData.data?.nodes[0].class.id,
                semester_id: studentData.data?.nodes[0].semester.id,
                v_std_amt_receipt: totalAmount,
                v_std_amt_deposit: 0,
                v_std_amt_fine: 0,
                v_std_amt_total: totalAmount,
                v_std_amt_refunded: 0,
                v_std_amt_adjusted: 0,
                v_transcation_type: "Cash Book",
                v_transcation_cash_or_bank: BankOrCash.CASH,

                v_transcation_no: "",
                v_transcation_date: dayjs(serverDate).format(),
                v_transcation_narration: remarks,
                // paid_party_id: "",
                // party_bill_no: "",
                party_bill_date: dayjs(DEFAULT_TIME).format(),
                // party_name: "",
                annx_yesno: false,
                // annx_id: Math.random() * 1000,
                is_vouch_multi_entry: false,
                acct_ldgr_id_cr: items[0].account_ledger_id,
                acct_ldgr_id_db: CashLedgers.responseType[0].value
              },
              acct_voucher_db: CashLedgers.responseType.map(
                (dbData, index) => ({
                  vo_cr_db: DebitOrCredit.DEBIT,
                  vo_sl_no: index + 1,
                  vo_cr: 0,
                  vo_db: totalAmount,
                  vo_cr_total: 0,
                  vo_db_total: totalAmount,
                  acct_ldgr_id: dbData.value
                })
              ),
              acct_voucher_cr: items.map((crData, index) => ({
                vo_cr_db: crData.cr_db,
                vo_sl_no: index + 1,
                vo_cr: crData.feeAmount,
                vo_db: 0,
                vo_cr_total: totalAmount,
                vo_db_total: 0,
                acct_ldgr_id: crData.account_ledger_id
              }))
            }
          ]
        },
        refetchQueries: [
          {
            query: GetAcctVouchers,
            variables: {
              after: null,
              direction: Direction.ASC,
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              first: ROWS_PER_PAGE,
              sortBy: SortBy.V_DATE,
              token,
              name: EMPTY_STRING,
              input: {
                inst_id: InstId!,
                voucher_query_type: VoucherQueryTypes.STD_NON_DEMAND_RECEIPTS,
                vo_end_date: dayjs(dates?.lastDay!).format(),
                acct_ldgr_id: 0,
                vo_start_date: dayjs(dates?.firstDay!).format(),
                vo_type: EMPTY_STRING
              }
            }
          }
        ]
      }).then(({ data }) => {
        if (data) {
          handleClear();
          setMessage({
            message: `sucessfully raised receipt for student ${studentFormData?.std_name}`,
            flag: true,
            operation: Operation.CREATE
          });
        }
      });
    }
  };

  const HandleSelectFeeType = (id: number) => {
    const filterdData = ledgerList?.find(({ value }) => value === id);

    if (filterdData) SetAccountLedgerId(filterdData);
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") return;

    if (message.operation !== Operation.NONE && message.flag) handleClear();

    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE
    });
  };
  const handleClear = () => {
    dispatch({
      type: payloadTypes.SET_STUDENT_ID,
      payload: {
        studentId: 0
      }
    });
    setItems([]);

    setAdmNo("");
  };

  useEffect(() => {
    if (NonDemandCapLedgers.data) {
      setLedgerList(NonDemandCapLedgers.responseType);
    }
  }, [NonDemandCapLedgers]);
  useEffect(() => {
    if (
      studentData.data &&
      studentData.data?.nodes[0].std_profile_filename !== "" &&
      InstDetails.data
    ) {
      // eslint-disable-next-line
      const studentProfiePicUrl = `${InstDetails.data?.nodes[0]?.inst_name}/students/${state.studentId}/std_profile_pic/std_profile_pic`;
      // eslint-disable-next-line
      const ers = getDownloadUrl(studentProfiePicUrl, false, setImageString);
    }
  }, [studentData.data, InstDetails.data, state.studentId]);
  const { branchLabel, classLabel, categoryLabel } = useInstLabels();
  return (
    <>
      {pageType === PageFor.GENERAL ? <Home DashBoardRequired={false} /> : null}
      <div className="row g-0">
        <div className="col">
          <Title>Management Student Non Demand Fee Receipt</Title>
        </div>
        <div className="configuration-settings">
          {pageType === PageFor.GENERAL ? (
            <>
              {USE_CONFIG_KEY && (
                <img
                  src={Settings}
                  alt="/"
                  id="settings-icon"
                  onClick={() => setConfigurationModal(!configurationModal)}
                />
              )}
            </>
          ) : (
            <>
              <img src={Enlarge} alt="/" onClick={() => toggleFullSceen()} />
            </>
          )}
        </div>
      </div>
      <div
        className={
          pageType === PageFor.GENERAL
            ? "row g-0 non-demandfee-payable"
            : "row g-0 non-demandfee-payable--modal"
        }
      >
        <div className="row g-0 non-demandfee-payable__select">
          <div className="col-4 ">
            <div className="non-demandfee-payable__grid">
              {pageType === PageFor.GENERAL ? (
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={studentAddmissionNumber}
                  openOnFocus
                  value={
                    studentAddmissionNumber.find(
                      ({ label }) => label === studentFormData.adm_no
                    )! ?? EMPTY_RESPONSETYPE_OBJECT
                  }
                  onChange={(e, newValue) => {
                    if (newValue) {
                      dispatch({
                        type: payloadTypes.SET_STUDENT_ID,
                        payload: {
                          studentId: (newValue as responseType)?.value
                        }
                      });
                    } else {
                      dispatch({
                        type: payloadTypes.SET_STUDENT_ID,
                        payload: {
                          studentId: 0
                        }
                      });
                    }
                  }}
                  freeSolo
                  autoHighlight
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (e.key === Keys.ENTER) {
                      if (state.studentId) {
                        feeDescInputRef?.select();
                        feeDescInputRef?.focus();
                      }
                    }
                    if (e.key === Keys.BACKSPACE) {
                      dispatch({
                        type: payloadTypes.SET_STUDENT_ID,
                        payload: {
                          studentId: 0
                        }
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoFocus
                      onChange={(e) => setAdmNo(e.target.value)}
                      label="Admission No."
                      fullWidth
                      slotProps={{
                        inputLabel: {
                          shrink: true
                        }
                      }}
                      className={labelClasses.formControlRoot}
                    />
                  )}
                />
              ) : (
                <TextField
                  label="Admission No."
                  className="demand-fee__textfield"
                  slotProps={{
                    inputLabel: {
                      shrink: true
                    }
                  }}
                  value={studentFormData?.adm_no ?? EMPTY_STRING}
                  disabled
                />
              )}

              {pageType === PageFor.GENERAL ? (
                <img
                  className="data-fetch-icon"
                  src={Edit}
                  alt="/"
                  onClick={() => setStudentModal(!studentModal)}
                />
              ) : null}
            </div>
            {institutionType !== InstitutionType.SCHOOL && (
              <TextField
                label="Register Number"
                className="demand-fee__textfield"
                slotProps={{
                  inputLabel: {
                    shrink: true
                  }
                }}
                value={studentFormData?.reg_number ?? EMPTY_STRING}
                disabled
              />
            )}
            {USE_CATEGORY_KEY && (
              <TextField
                label={categoryLabel}
                className="demand-fee__textfield"
                slotProps={{
                  inputLabel: {
                    shrink: true
                  }
                }}
                value={studentFormData?.category ?? EMPTY_STRING}
                disabled
              />
            )}
          </div>

          <div className="col">
            <TextField
              label="Name"
              className="demand-fee__textfield"
              slotProps={{
                inputLabel: {
                  shrink: true
                }
              }}
              value={studentFormData?.std_name}
              disabled
            />

            <TextField
              label={branchLabel}
              className="demand-fee__textfield"
              slotProps={{
                inputLabel: {
                  shrink: true
                }
              }}
              value={studentFormData?.branch}
              disabled
            />
            <TextField
              label={classLabel}
              className="demand-fee__textfield"
              slotProps={{
                inputLabel: {
                  shrink: true
                }
              }}
              value={studentFormData?.class}
              disabled
            />
          </div>
          <div className="col-3 ">
            <div className="non-demandfee-payable__label-grid">
              <TextField
                type="date"
                label="Date"
                className="demand-fee__textfield--date"
                slotProps={{
                  inputLabel: {
                    shrink: true
                  }
                }}
                value={toInputStandardDate(serverDate)}
                disabled
              />
              <Label variant="present-day">{NameOfTheDay(serverDate)}</Label>
            </div>
            <div className="non-demandfee-payable__label-gridbtn">
              <Button
                onClick={() => setFeeledgerModal(!feeLedgerModal)}
                mode="ledger"
              />
            </div>
          </div>
          <div className="col-1 non-demandfee-payable__image h-100">
            {imageString === EMPTY_STRING ? (
              <img src={Avatar} alt="/" />
            ) : (
              <img src={imageString} alt="/" />
            )}
          </div>
        </div>
        <div className="row g-0 non-demandfee-payable__tableblock">
          <TableContainer className="non-demandfee-payable__table g-0">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Accounts_Table.Receipts.NonDemand.Table_Headers.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return <TableCell key={index}>{th.labelName}</TableCell>;
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow className="non-demandfee-payable__table--select-row">
                  <TableCell
                    id="td-center"
                    className="non-demandfee-payable__table--slno"
                  >
                    {items.length + 1}
                  </TableCell>
                  <TableCell className="non-demandfee-payable__table--desc">
                    <FormAutocomplete
                      className={formClasses.inputRoot}
                      options={
                        ledgerList?.filter(({ isChecked }) => !isChecked)! || []
                      }
                      openOnFocus
                      freeSolo
                      autoHighlight
                      ref={feeDescRef}
                      onKeyDown={(e: React.KeyboardEvent) => {
                        if (e.key === Keys.ENTER) {
                          if (
                            account_ledger_id.value !==
                              EMPTY_RESPONSETYPE[0].value &&
                            dueDateRef.current
                          ) {
                            dueDateRef.current?.select();
                            dueDateRef.current?.focus();
                          }
                          if (
                            account_ledger_id.value ===
                              EMPTY_RESPONSETYPE[0].value &&
                            items.length >= 1
                          ) {
                            modeOfTransactionInputRef?.focus();
                          }
                        }
                        if (e.key === Keys.BACKSPACE) {
                          SetAccountLedgerId(EMPTY_RESPONSETYPE_OBJECT);
                        }
                      }}
                      value={account_ledger_id}
                      onChange={(e, newValue) => {
                        if (newValue) {
                          const data = newValue as responseType;
                          SetAccountLedgerId({
                            label: data.label,
                            value: data.value,
                            isChecked: true
                          });
                        } else {
                          SetAccountLedgerId(EMPTY_RESPONSETYPE_OBJECT);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          autoFocus
                          {...params}
                          onChange={(e) => setsearchData(e.target.value)}
                          className={formClasses.formControlRoot}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell
                    id="td-right"
                    className="non-demandfee-payable__table--balance"
                  >
                    <input
                      value={feeAmount}
                      id="td-right"
                      disabled={!account_ledger_id.value}
                      type="number"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFeeAmount(Number(e.target.value))
                      }
                      ref={dueDateRef}
                      onKeyDown={(e: React.KeyboardEvent) => {
                        if (e.key === Keys.ENTER) {
                          if (feeAmount !== 0) {
                            buttonRef.current?.focus();
                          }
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    className="non-demandfee-payable__table--actions"
                  >
                    <button
                      ref={buttonRef}
                      onClick={(e: React.FormEvent) =>
                        edit ? HandleEditItem(id, e) : HandleItems(e)
                      }
                    >
                      {edit ? "Save" : <img src={Enter} alt="/" />}
                    </button>
                  </TableCell>
                </TableRow>
                {items.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell id="td-center">{index + 1}</TableCell>
                      <TableCell>
                        {AccountLedgerNameData?.GetAcctLdgrNamesByIds[index]
                          ?.id === item.account_ledger_id
                          ? AccountLedgerNameData?.GetAcctLdgrNamesByIds[index]
                              ?.ldgr_desc
                          : EMPTY_STRING}
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className="non-demandfee-payable__table--balance"
                      >
                        {format(item.feeAmount)}
                      </TableCell>
                      <TableCell id="td-center">
                        <img
                          src={EditProfile}
                          alt="/"
                          onClick={() => {
                            handleItemEdit(
                              item.feeAmount,
                              item.account_ledger_id
                            );
                            setEdit(!edit);
                            HandleSelectFeeType(item.account_ledger_id);
                            setId(item.sl_no);
                          }}
                        />

                        {items.length ? (
                          <img
                            id="delete-profile"
                            src={DeleteImg}
                            alt="/"
                            onClick={() => handleItemDeleted(index)}
                          />
                        ) : null}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={2} className="total">
                    Total :
                  </TableCell>
                  <TableCell id="td-right" className="totalcount">
                    {format(totalAmount)}
                  </TableCell>
                  <TableCell id="tfoot-td"></TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
        <form className="row g-0 non-demandfee-payable__receiptblock">
          <div className="col account-frames h-100">
            <div className="label-grid">
              <Label>Receipt No.</Label>
              <Input
                value={
                  voucherNumber.data?.GetVoucherNumber.vo_number! ??
                  EMPTY_STRING
                }
                disabled
              />
            </div>
            <div className="label-grid">
              <Label> Cash Ledgers</Label>
              <Input
                disabled
                value={
                  (CashLedgers?.responseType?.length &&
                    CashLedgers?.responseType[0]?.label) ||
                  EMPTY_STRING
                }
              />
            </div>
          </div>

          <div className="col account-frames label-grid h-100">
            <Label>Remarks :</Label>
            <TextArea
              textAreaRef={remarksRef}
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === Keys.ENTER) {
                  saveButtonRef.current?.focus();
                }
              }}
              onChange={(e) => setRemarks(e.target.value)}
              rows={3}
            />
          </div>
        </form>

        <Button
          mode="save"
          disabled={!items.length}
          onClick={handleStudentReceipts}
          buttonref={saveButtonRef}
        />
        <Button mode="delete" onClick={() => setDeleteModal(!deleteModal)} />

        <Button mode="previous-receipt" />
        <Button
          mode="clear"
          disabled={!state.studentId}
          onClick={handleClear}
        />
        {pageType === PageFor.GENERAL ? (
          <Button mode="back" onClick={() => navigate(-1)} type="button" />
        ) : (
          <Button
            mode="cancel"
            type="button"
            onClick={() => setModalFlag(false)}
          />
        )}
      </div>
      {/* studentmodal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={studentModal}
        ariaHideApp={false}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentList
              pageType={PageFor.MODAL}
              studentListFor={StudentListFor.ACCOUNTS}
              queryType={StudentAcctReportType.GENERAL}
              setStudentModal={setStudentModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setStudentModal(!studentModal)}
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
      {/* fee ledger Modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={feeLedgerModal}
        ariaHideApp={false}
        style={EditModalCustomStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <FeeLedger
              pageFor={PageFor.MODAL}
              setFeeledgerModal={setFeeledgerModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setFeeledgerModal(!feeLedgerModal)}
            />
          </div>
        </div>
      </Modal>
      {/* Pass out student Modal */}
      <AddPassoutStudentModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        operation={Operation.CREATE}
      />
      {/* configurationModal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={configurationModal}
        style={ConfigurationsModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ConfigurationSettings
              pageNumber={PageNumbers.NON_DEMAND_FEE_PAYABLE_PAGE}
              setModalFlag={setConfigurationModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        </div>
      </Modal>
      {/* deleteModal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={deleteModal}
        style={DeleteLedgerModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Delete
              v_type={VoucherQueryTypes.STD_NON_DEMAND_RECEIPTS}
              setModalFlag={setDeleteModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setDeleteModal(!deleteModal)}
            />
          </div>
        </div>
      </Modal>
      <LoadingModal flag={GenerateStudentNonDemandReceiptsLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default Index;
