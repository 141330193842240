import React, { useContext, useEffect, useState } from "react";
import { Title } from "../../../../stories/Title/Title";
import Home from "../../Home/Index";
import Avatar from "../../../../images/Avatar.svg";
import { TextField } from "@mui/material";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import Modal from "react-modal";
import { Button } from "../../../../stories/Button/Button";
import {
  InstitutionConfigurationTypes,
  Operation,
  PageFor,
  StudentAcctReportType,
  StudentListFor,
} from "../../../../utils/Enum.types";
import {
  GridAlignment,
  GridColDef,
  GridRenderCellParams,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { useNavigate } from "react-router-dom";
import View from "./View";
import Close from "../../../../images/Close.svg";
import Edit from "../../../../images/Edit.svg";
import useStudentDatabyId from "../../../../customhooks/useStudentDatabyId";
import {
  EMPTY_STRING,
  LateFeePayment,
  NumberOfAdmissionNumberToBeDisplayed,
  TODAY_DATE,
} from "../../../../utils/constants";
import {
  formatter,
  getFileName,
  toInputStandardDate,
  toIsoDate,
  toStandardDate,
} from "../../../../utils/UtilFunctions";

import useToken from "../../../../customhooks/useToken";
import { useLazyQuery, useMutation } from "@apollo/client";
import { AppContext } from "../../../../context/context";
import { Keys } from "../../../../utils/Enum.keys";
import { payloadTypes } from "../../../../context/reducer";
import { msgType, responseType } from "../../../../utils/Form.types";
import LoadingModal from "../../../../pages/LoadingModal";
import MessageModal from "../../../../pages/MessageModal";
import { GetStudentDocsByStudentId } from "../../../../queries/students/list/byId";
import useAcctStdAdmissionNumber from "../../hooks/useAcctStdAdmissionNumber";
import StudentList from "../../common/StudentList";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import { LateFeePermission } from "../../queries/latefeepayment/mutation";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import useUploadComponent from "../../../../customhooks/useUploadComponent";
import {
  GetStudentDocsByStudentIdData,
  GetStudentDocsByStudentIdVars,
} from "../../../Master/Student/Documents/Index";
import { getDownloadUrl } from "../../../../utils/DownloadFile";
import DownloadFiles from "../../../../images/DocumentsDownload.svg";
import TextArea from "../../../../stories/TextArea/TextArea";
import { Label } from "../../../../stories/Label/Label";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import useLoggedInUserDetails from "../../hooks/useLoggedInUserDetails";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";
import useAcctTableJson from "../../json/useAcctTableJson";
import { TableHeaderProps } from "../../../../utils/types";
import {
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../../styles/DataGridTableStyles";
import useStudentDemandDetails from "../../hooks/useStudentDemandDetails";
import { DemandDetailsQueryType } from "../../common/QueryTypes";
const PermissionLetter = () => {
  // usestates
  const [studentModal, setStudentModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  // const [items, setItems] = useState<FeeDetails[]>([]);

  const [admNo, setAdmNo] = useState("");
  const [remarks, setRemarks] = useState("");
  const [dueDate, setDueDate] = useState(TODAY_DATE);
  // eslint-disable-next-line
  const [imageString, setImageString] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [saveFlag, setSaveFlag] = useState(false);
  // params
  const navigate = useNavigate();

  // context
  const { state, dispatch } = useContext(AppContext);
  const { format } = formatter;
  const [newDueDates, setNewDueDates] = useState<
    { acctLedgerId: number; date: string }[]
  >([]);

  // hooks
  const { token } = useToken();

  const { studentData, studentFormData } = useStudentDatabyId();

  const { InstDetails } = useInstDetails(1);

  const { user_details } = useLoggedInUserDetails();
  const { StudentDemandDetails } = useStudentDemandDetails(
    DemandDetailsQueryType.DEMAND_DETAILS_GT_0,
    false,
    EMPTY_STRING
  );

  const { studentAddmissionNumber } = useAcctStdAdmissionNumber(
    admNo,
    NumberOfAdmissionNumberToBeDisplayed,
    StudentAcctReportType.DEMAND_RAISED
  );
  const {
    USE_CATEGORY_KEY,
    USE_DEPARTMENT_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();
  const { Accounts_Table } = useAcctTableJson();

  const [GetDocuments, { data }] = useLazyQuery<
    GetStudentDocsByStudentIdData,
    GetStudentDocsByStudentIdVars
  >(GetStudentDocsByStudentId);

  // mutations
  const [LateFee, { loading }] = useMutation(LateFeePermission, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const { component, setFile, uploadFile } = useUploadComponent(
    `${InstDetails.data?.nodes[0]?.inst_name}/students/${state.studentId}/permission_letters/${remarks}`,
    Operation.CREATE,
    EMPTY_STRING,
    InstitutionConfigurationTypes.ATTACHMENT_FILE_SIZE
  );
  // functions
  const handleClose = () => {
    setImageString("");
    dispatch({ type: payloadTypes.SET_STUDENT_ID, payload: { studentId: 0 } });
    setFile(null);
    setRemarks("");
    setDueDate(TODAY_DATE);
    setMessage({ flag: false, message: "", operation: Operation.NONE });
  };

  const handleLateFeepayment = async () => {
    setSaveFlag(false);
    if (state.studentId) {
      try {
        const uploadResult = await uploadFile();
        if (uploadResult) {
          LateFee({
            variables: {
              token,
              student_id: state.studentId,
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              input: {
                new_date_details: newDueDates.map((res) => ({
                  fee_due_date: toIsoDate(res.date),
                  acct_ldgr_id: res.acctLedgerId,
                })),
                remarks: remarks,
                upload_image: {
                  std_doc_type: LateFeePayment,
                  std_doc_filename: `${InstDetails.data?.nodes[0]?.inst_name}/students/${state.studentId}/permission_letters/${remarks}`,
                  std_doc_download: false,
                  std_doc_collected: true,
                  std_doc_original: false,
                  date_of_collection: toIsoDate(dueDate),
                  std_doc_remarks: remarks,
                },
              },
              inst_id: state.InstId,
              user_details,
            },
            refetchQueries: [
              {
                query: GetStudentDocsByStudentId,
                variables: {
                  student_id: state.studentId,
                  token,
                },
              },
            ],
          }).then(({ data }) => {
            if (data) {
              setMessage({
                flag: true,
                message: "Uploaded Permission Letter",
                operation: Operation.CREATE,
              });
            }
          });
        } else {
          setMessage({
            flag: true,
            message: "Image upload failed",
            operation: Operation.NONE,
          });
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setMessage({
        flag: true,
        message: `Student not found`,
        operation: Operation.NONE,
      });
    }
  };
  useEffect(() => {
    if (state.studentId) {
      GetDocuments({
        variables: {
          student_id: state.studentId,
          token,
        },
      });
    }
  }, [state.studentId, GetDocuments, token]);
  useEffect(() => {
    if (
      studentData.data &&
      studentData.data?.nodes[0].std_profile_filename !== "" &&
      InstDetails.data
    ) {
      // eslint-disable-next-line
      const studentProfiePicUrl = `${InstDetails.data?.nodes[0]?.inst_name}/students/${state.studentId}/std_profile_pic/std_profile_pic`;
      // eslint-disable-next-line
      const ers = getDownloadUrl(studentProfiePicUrl, false, setProfileImage);
    }
  }, [studentData.data, InstDetails.data, state.studentId]);
  const {
    branchLabel,
    classLabel,
    departmentLabel,
    semesterLabel,
    sectionLabel,
    categoryLabel,
  } = useInstLabels();

  const handleNewDueDateChange = (ledgerId: number, date: string) => {
    setNewDueDates((prev) => {
      const existingEntryIndex = prev.findIndex(
        (entry) => entry.acctLedgerId === ledgerId
      );
      if (existingEntryIndex >= 0) {
        // Update the existing entry
        const updated = [...prev];
        updated[existingEntryIndex].date = date;
        return updated;
      } else {
        // Add a new entry
        return [...prev, { acctLedgerId: ledgerId, date }];
      }
    });
  };

  const ledgerDynamicHeaders: TableHeaderProps[] =
    Accounts_Table.PermissionLedger.Table_Headers.map((header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
      hideable: header.hideable,
      renderCell: (params: GridRenderCellParams) => {
        if (header.field === "new_due_date") {
          const ledgerId = params.row.acct_ldgr_id;
          const existingEntry = newDueDates.find(
            (entry) => entry.acctLedgerId === ledgerId
          );
          return (
            <div className="extend-feedemand__table--input">
              <TextField
                type="date"
                value={existingEntry?.date || ""}
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                  input: {
                    inputProps: {
                      min: state.ActiveFinYr
                        ? toInputStandardDate(state.ActiveFinYr.fin_st_date)
                        : 0,

                      max: state.ActiveFinYr
                        ? toInputStandardDate(state.ActiveFinYr.fin_end_date)
                        : 0,
                    },
                  },
                }}
                onChange={(e) =>
                  handleNewDueDateChange(ledgerId, e.target.value)
                }
              />
            </div>
          );
        } else {
          return params.value;
        }
      },
    }));

  const ledgerColumns: GridColDef[] = [...ledgerDynamicHeaders];

  const ledgerRows: GridValidRowModel[] = (
    StudentDemandDetails.data?.GetAcctStdDemandDetails || []
  ).map((data, index) => {
    return {
      id: index + 1,
      acct_ldgr_id: data.acct_ldgr_id,
      fee_desc: data.acct_ldgr_details.ldgr_desc,
      balance_fee: format(data.fee_receivable),
      due_date: toStandardDate(data.fee_due_date!),
      new_due_date: "",
    };
  });

  const letterDynamicHeaders: TableHeaderProps[] =
    Accounts_Table.PermissionLetter.Table_Headers.map((header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
      hideable: header.hideable,
      renderCell: (params: GridRenderCellParams) => {
        if (header.field === "action") {
          return (
            <img
              src={DownloadFiles}
              alt="/"
              onClick={() => {
                setImageString("");
                getDownloadUrl(params.row.action, true, setImageString);
              }}
            />
          );
        } else {
          return params.value;
        }
      },
    }));
  const letterColumns: GridColDef[] = [...letterDynamicHeaders];

  const letterRows: GridValidRowModel[] = (
    (data &&
      data.GetStudentDocsByStudentId.filter(
        (d) => d.std_doc_type === LateFeePayment
      )) ||
    []
  ).map((item, index) => {
    return {
      id: index + 1,
      date: toStandardDate(
        item.date_of_collection ? item.date_of_collection : EMPTY_STRING
      ),
      permission_desc: getFileName(item.std_doc_filename),
      action: item.std_doc_filename,
    };
  });
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Permission Letter for Late Fee Payment</Title>
      <div className="late-fee-payment">
        <div className="late-fee-payment__details row g-0">
          <div className="col">
            <div className="late-fee-payment__details--image-flex">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={studentAddmissionNumber}
                value={
                  state.studentId
                    ? studentAddmissionNumber?.find(
                        ({ value }) => value === state.studentId
                      )!
                    : null
                }
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === Keys.BACKSPACE) {
                    dispatch({
                      type: payloadTypes.SET_STUDENT_ID,
                      payload: {
                        studentId: 0,
                      },
                    });
                    setAdmNo("");
                  }
                }}
                // disabled={state.studentId ? true : false}
                openOnFocus
                onChange={(e, newValue) => {
                  if (newValue) {
                    dispatch({
                      type: payloadTypes.SET_STUDENT_ID,
                      payload: {
                        studentId: (newValue as responseType)?.value,
                      },
                    });
                  } else {
                    dispatch({
                      type: payloadTypes.SET_STUDENT_ID,
                      payload: {
                        studentId: 0,
                      },
                    });
                  }
                }}
                freeSolo
                autoHighlight
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onChange={(e) => setAdmNo(e.target.value)}
                    label="Admission Number"
                    autoFocus
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    fullWidth
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
              <img
                className="data-fetch-icon"
                src={Edit}
                alt="/"
                onClick={() => setStudentModal(!studentModal)}
              />
            </div>
            <TextField
              className="late-fee-payment__details--textfield"
              label="Reg No."
              value={studentFormData.reg_number}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              disabled
            />
            <TextField
              className="late-fee-payment__details--textfield"
              label={classLabel}
              value={studentFormData.class}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              disabled
            />
          </div>
          <div className="col">
            <TextField
              className="late-fee-payment__details--textfield"
              label="Name"
              value={studentFormData.std_name}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              disabled
            />
            {USE_DEPARTMENT_KEY && (
              <TextField
                className="late-fee-payment__details--textfield"
                label={`${departmentLabel}`}
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                value={studentFormData.dept}
                disabled
              />
            )}
            <TextField
              className="late-fee-payment__details--textfield"
              label={branchLabel}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              value={studentFormData.branch}
              disabled
            />
          </div>
          <div className="col">
            {USE_SEMESTER_KEY && (
              <TextField
                className="late-fee-payment__details--textfield"
                label={semesterLabel}
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                value={studentFormData.semester}
                disabled
              />
            )}
            {USE_SECTION_KEY && (
              <TextField
                className="late-fee-payment__details--textfield"
                label={sectionLabel}
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                value={studentFormData.section}
                disabled
              />
            )}
            {USE_CATEGORY_KEY && (
              <TextField
                className="late-fee-payment__details--textfield"
                label={categoryLabel}
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                value={studentFormData.category}
                disabled
              />
            )}
          </div>
          <div className="col-1 late-fee-payment__details--image">
            <img src={profileImage ? profileImage : Avatar} alt="/" />
          </div>
        </div>

        <div className="late-fee-payment__tableblock">
          {state.studentId ? (
            <div className=" late-fee-payment__tableblock--permission-details">
              <Title>New Permission Details</Title>
              <div className="late-fee-payment__details--label-grid">
                <Label>Permission Date</Label>
                <TextField
                  className="late-fee-payment__details--textfield--date"
                  value={toInputStandardDate(dueDate)}
                  onChange={(e) => setDueDate(e.target.value)}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                    input: {
                      inputProps: {
                        min: state.ActiveFinYr
                          ? toInputStandardDate(state.ActiveFinYr.fin_st_date)
                          : 0,

                        max: toInputStandardDate(TODAY_DATE),
                      },
                    },
                  }}
                  type="date"
                />
                {/* <Label>Permission Description</Label>
                <Input
                  value={permissionDesc}
                  onChange={(e) => setPermissionDesc(e.target.value)}
                /> */}
                <Label>Remarks</Label>
                <TextArea
                  rows={2}
                  required
                  value={remarks}
                  onChange={(e) => setRemarks(e.target.value)}></TextArea>
              </div>
              {component}
            </div>
          ) : null}
          {state.studentId ? (
            <div className=" late-fee-payment__tableblock--permission-ledger">
              <Title>Permission Ledger</Title>
              <StyledDatagrid
                columns={ledgerColumns}
                rows={ledgerRows}
                hideFooter
                rowHeight={TABLE_ROW_HEIGHT}
              />
            </div>
          ) : null}
          {state.studentId ? (
            <div className=" late-fee-payment__tableblock--permission-letter">
              <Title>List of Permission Letters</Title>
              <StyledDatagrid
                columns={letterColumns}
                rows={letterRows}
                hideFooter
                rowHeight={TABLE_ROW_HEIGHT}
              />
            </div>
          ) : null}
        </div>

        <Button
          mode="save"
          disabled={!remarks || saveFlag}
          onClick={() => {
            handleLateFeepayment();
            setSaveFlag(true);
          }}
        />
        {/* <Button mode="view" onClick={() => setViewModal(!viewModal)} /> */}
        <Button mode="clear" onClick={handleClose} />
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>

      {/* studentmodal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={studentModal}
        ariaHideApp={false}
        style={StudentModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <>
              <StudentList
                pageType={PageFor.MODAL}
                studentListFor={StudentListFor.ACCOUNTS}
                queryType={StudentAcctReportType.DEMAND_RAISED}
                setStudentModal={setStudentModal}
              />
            </>
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setStudentModal(!studentModal)}
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
      {/* viewModal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={viewModal}
        ariaHideApp={false}
        style={StudentModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <>
              <View />
              <Button mode="cancel" onClick={() => setViewModal(!viewModal)} />
            </>
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setViewModal(!viewModal)}
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>

      <LoadingModal flag={loading} />

      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default PermissionLetter;
