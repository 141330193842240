import React, { useContext, useEffect, useState } from "react";
import { Button } from "../../../../stories/Button/Button";

import { Title } from "../../../../stories/Title/Title";

import Home from "../../Home/Index";
import { useNavigate, useParams } from "react-router-dom";
import { formatter, toIsoDate } from "../../../../utils/UtilFunctions";
import { useLazyQuery } from "@apollo/client";
import {
  GetAcctLdgrYearlyDataSummation,
  GetAcctStudentDemandFeeSummary,
} from "../../queries/FeeLedgers/query/Byid";
import {
  GetAcctLdgrYearlyDataSummationData,
  GetAcctLdgrYearlyDataSummationVars,
  GetAcctStudentDemandFeeSummaryDetailsData,
} from "../../../../Types/Accounting";
import DownArrow from "../../../../images/DownArrow.svg";
import RightArrow from "../../../../images/ArrowRight.svg";
import useToken from "../../../../customhooks/useToken";
import LoadingModal from "../../../../pages/LoadingModal";

import {
  InstitutionConfigurationTypes,
  Operation,
} from "../../../../utils/Enum.types";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import useAcctTableJson from "../../json/useAcctTableJson";
import {
  EMPTY_STRING,
  emptyMessageType,
  TODAY_DATE,
} from "../../../../utils/constants";
import { AppContext } from "../../../../context/context";
import {
  GridAlignment,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { TableHeaderProps } from "../../../../utils/types";
import {
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../../styles/DataGridTableStyles";
import { GetFinYearByFinId } from "../../../../queries/institution/financialyear/list/byid";
import {
  ExportAcctStdtoExcelData,
  ExportAcctStdtoExcelVars,
} from "../../../Master/Reports/InstitutionLevel/useExportMutation";
import { ExportAcctStdtoExcel } from "../../../../queries/xls";
import { VoucherQueryTypes } from "../../common/Enum.types";
import { getDownloadUrl } from "../../../../utils/DownloadFile";
import MessageModal from "../../../../pages/MessageModal";
import { SortBy } from "../../../Master/Reports/InstitutionLevel/GlobalStates/types";

const AllYearsLedgerSummary = () => {
  const navigate = useNavigate();
  const { InstId } = useParams();
  const { token } = useToken();
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
  const [message, setMessage] = useState(emptyMessageType);

  const { format } = formatter;
  const { state } = useContext(AppContext);

  const [finYearId, setFinYearId] = useState(0);
  const { Accounts_Table } = useAcctTableJson();
  const [GetAcctStdDemand, { data: Ledgers, loading: ledgerLoading }] =
    useLazyQuery<
      GetAcctStudentDemandFeeSummaryDetailsData,
      GetAcctLdgrYearlyDataSummationVars
    >(GetAcctStudentDemandFeeSummary, {
      variables: {
        fin_yr_id: finYearId ? finYearId : 0,
        inst_id: InstId!,
        token,
      },
      fetchPolicy: "network-only",
    });
  const [GetFinYearByNodes, { data: FinYearData }] =
    useLazyQuery(GetFinYearByFinId);
  const handleToggle = (index: number, id: number) => {
    setExpandedIndex(expandedIndex === index ? null : index);
    setFinYearId(id);
  };
  const [GetAcctLdgrYr, { data, loading, error }] = useLazyQuery<
    GetAcctLdgrYearlyDataSummationData,
    GetAcctLdgrYearlyDataSummationVars
  >(GetAcctLdgrYearlyDataSummation, {
    fetchPolicy: "network-only",
  });
  const [finYearMap, setFinYearMap] = useState<Record<string, string>>({});
  const { InstDetails } = useInstDetails(1);
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.USE_EDUATE_LOGO_IN_FILES
  );

  const [ExportAcct, { loading: exportLoading }] = useLazyQuery<
    ExportAcctStdtoExcelData,
    ExportAcctStdtoExcelVars
  >(ExportAcctStdtoExcel);

  const configLogo =
    configData && configData.data?.GetSwConfigVariables[0].config_boolean_value;
  const summaryData =
    Ledgers &&
    Ledgers.GetAcctStudentDemandFeeSummary.map((edge, index) => ({
      SlNo: index + 1,
      LdgrDesc: edge.acct_ldgr_details.ldgr_desc,
      OB: edge.fee_ldgr_ob,
      DemandAmt: edge.fee_ldgr_demand,
      Concession: edge.fee_ldgr_concession,
      Receivable: edge.fee_ldgr_receivable,
      Received: edge.fee_ldgr_received,
      Balance: edge.fee_ldgr_bal,
      Refunds: edge.fee_ldgr_refunds,
      FeeBalance: edge.fee_ldgr_dropout_demand,
    }));

  useEffect(() => {
    if (token && finYearId) {
      GetAcctStdDemand();
    }
  }, [token, GetAcctStdDemand, finYearId, InstId]);

  useEffect(() => {
    if (token) {
      GetAcctLdgrYr({
        variables: {
          fin_yr_id: 0,
          inst_id: InstId!,
          token,
        },
      }).then(({ data }) => {
        if (data) {
          GetFinYearByNodes({
            variables: {
              token,
              ids:
                data &&
                data.GetAcctLdgrYearlyDataSummation.map((res) => res.fin_yr_id),
            },
          });
        }
      });
    }
  }, [token, GetAcctLdgrYr, state.ActiveFinYr, GetFinYearByNodes, InstId]);
  useEffect(() => {
    if (FinYearData && FinYearData.nodes) {
      //@ts-ignore
      const mapping = FinYearData.nodes.reduce((acc, node) => {
        acc[node.id] = node.fin_yr;
        return acc;
      }, {} as Record<string, string>);
      setFinYearMap(mapping);
    }
  }, [FinYearData]);
  const hanldeExport = () => {
    try {
      ExportAcct({
        variables: {
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          input: {
            float_data: [],
            ids: [Number(InstId), 0],
            int_data: [],
            std_status: [],
            str_data: [],
          },
          voucher_input: {
            acct_ldgr_id: 0,
            vo_start_date: toIsoDate(TODAY_DATE),
            vo_end_date: toIsoDate(TODAY_DATE),
            vo_type: EMPTY_STRING,
            v_book_type: EMPTY_STRING,
          },
          inst_id: InstId!,
          parameters: [],
          query_type: VoucherQueryTypes.ACCT_STD_FEE_YEARLY_SUMMARY,
          sort_by: SortBy.STD_NAME,
          token,
        },
      }).then(async ({ data, error }) => {
        if (data && data.ExportAcctStdtoExcel) {
          const res = await getDownloadUrl(data.ExportAcctStdtoExcel, true);
          if (res) {
            setMessage({
              flag: true,
              message: "Downloading...",
              operation: Operation.CREATE,
            });
          }
        } else {
          setMessage({
            flag: true,
            message: "Failed downloading file",
            operation: Operation.NONE,
          });
        }

        if (error && error.message) {
          setMessage({
            flag: true,
            message: error.message,
            operation: Operation.NONE,
          });
        }
      });
    } catch (e) {
      setMessage({
        flag: true,
        message: "Failed to download",
        operation: Operation.NONE,
      });
    }
  };
  if (error) return <>{error.message}</>;

  const dynamicHeaders: TableHeaderProps[] =
    Accounts_Table.Reports.AllYearsLedgerSummary.Table_Headers.map(
      (header) => ({
        headerName: header.headerName,
        className: header.cellClassName,
        field: header.field,
        headerAlign: header.headerAlign as GridAlignment,
        align: header.align as GridAlignment,
        flex: header.flex,
        hideable: header.hideable,
      })
    );
  const columns: GridColDef[] = [...dynamicHeaders];

  const rows: GridValidRowModel[] =
    (Ledgers ? Ledgers.GetAcctStudentDemandFeeSummary : []).map(
      (res, index) => {
        return {
          id: index + 1,
          ledger_desc: res.acct_ldgr_details.ldgr_desc,
          ob: format(res.fee_ldgr_ob),
          demand_amount: format(res.fee_ldgr_demand),
          concession: format(res.fee_ldgr_concession),
          receivable: format(res.fee_ldgr_receivable),
          received: format(res.fee_ldgr_received),
          refunds: format(res.fee_ldgr_refunds),
          balance: format(res.fee_ldgr_bal),
        };
      }
    ) || [];

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>All Years Ledger Summary</Title>
      <div className="ledger-summary">
        <div className={`ledger-summary__tableblock`}>
          {data
            ? data.GetAcctLdgrYearlyDataSummation.map((res, index) => {
                const isExpanded = expandedIndex === index;

                return (
                  <React.Fragment key={index}>
                    <div className="all-yr-fee__data--block">
                      <div
                        className="all-yr-fee__data--header "
                        onClick={() => handleToggle(index, res.fin_yr_id)}>
                        <b className="all-yr-fee__data--header--b">
                          {finYearMap[res.fin_yr_id]}
                        </b>
                        <div className="all-yr-fee__data--header--flex">
                          <div className="all-yr-fee__data--header--grids">
                            <div className="all-yr-fee__data--header--block">
                              <span className="all-yr-fee__data--header--text">
                                OB
                              </span>
                              <b className="all-yr-fee__data--header--amount font-blue">
                                {res.fee_ldgr_ob ? format(res.fee_ldgr_ob) : 0}
                              </b>
                            </div>
                            <div className="all-yr-fee__data--header--block">
                              <span className="all-yr-fee__data--header--text">
                                Demand
                              </span>
                              <b className="all-yr-fee__data--header--amount font-green">
                                {res.fee_ldgr_demand
                                  ? format(res.fee_ldgr_demand)
                                  : 0}
                              </b>
                            </div>

                            <div className="all-yr-fee__data--header--block">
                              <span className="all-yr-fee__data--header--text">
                                Concession
                              </span>
                              <b className="all-yr-fee__data--header--amount font-red">
                                {res.fee_ldgr_concession
                                  ? format(res.fee_ldgr_concession)
                                  : 0}
                              </b>
                            </div>

                            <div className="all-yr-fee__data--header--block">
                              <span className="all-yr-fee__data--header--text">
                                Receivable
                              </span>
                              <b className="all-yr-fee__data--header--amount font-grey">
                                {res.fee_ldgr_receivable
                                  ? format(res.fee_ldgr_receivable)
                                  : 0}
                              </b>
                            </div>
                            <div className="all-yr-fee__data--header--block">
                              <span className="all-yr-fee__data--header--text">
                                Received
                              </span>
                              <b className="all-yr-fee__data--header--amount font-grey">
                                {res.fee_ldgr_received
                                  ? format(res.fee_ldgr_received)
                                  : 0}
                              </b>
                            </div>
                            <div className="all-yr-fee__data--header--block">
                              <span className="all-yr-fee__data--header--text">
                                Refunds
                              </span>
                              <b className="all-yr-fee__data--header--amount font-grey">
                                {res.fee_ldgr_refunds
                                  ? format(res.fee_ldgr_refunds)
                                  : 0}
                              </b>
                            </div>
                            <div className="all-yr-fee__data--header--block">
                              <span className="all-yr-fee__data--header--text">
                                Balance
                              </span>
                              <b className="all-yr-fee__data--header--amount font-grey">
                                {res.fee_ldgr_bal
                                  ? format(res.fee_ldgr_bal)
                                  : 0}
                              </b>
                            </div>
                          </div>
                          <div className="all-yr-fee__data--header--image">
                            {isExpanded ? (
                              <img src={DownArrow} alt="" />
                            ) : (
                              <img src={RightArrow} alt="" />
                            )}
                          </div>
                        </div>
                      </div>

                      {isExpanded ? (
                        <div className="all-yr-fee__data--contentAllYear">
                          <div className="all-yr-fee__data--contentAllYear--block">
                            <b>Ledger Summary</b>
                            <div
                              className={` all-yr-fee__data--contentAllYear--tableblock`}>
                              <StyledDatagrid
                                columns={columns}
                                rows={rows}
                                disableRowSelectionOnClick
                                disableChildrenSorting
                                rowHeight={TABLE_ROW_HEIGHT}
                                hideFooter
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </React.Fragment>
                );
              })
            : null}
        </div>
        <div className="row g-0">
          <div className="col">
            <Button onClick={hanldeExport} mode="export" />

            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
          <div className="col-2 ledger-summary__total">
            <div className="student-total-count">
              Total Years :&nbsp;
              <b>{data && data.GetAcctLdgrYearlyDataSummation.length!}</b>
            </div>
          </div>
        </div>
      </div>

      <LoadingModal flag={loading || ledgerLoading || exportLoading} />
      <MessageModal
        handleClose={() => setMessage(emptyMessageType)}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default AllYearsLedgerSummary;
