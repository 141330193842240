import React, { useState, useEffect, useContext } from "react";

import { FormControlLabel, FormGroup } from "@mui/material";
import { useParams } from "react-router-dom";

import { useLazyQuery, useMutation } from "@apollo/client";
import { EduateModule, Operation } from "../../../utils/Enum.types";
import useToken from "../../../customhooks/useToken";
import { msgType } from "../../../utils/Form.types";
import {
  GetSwEmpConfigVariablesData,
  GetSwEmpConfigVariablesVars,
  GlobalPageConfigData,
  SoftwareConfigList,
  SoftwareConfigTypeList,
  SoftwareConfigTypeVar,
  SoftwareVars,
} from "../../../Types/configtypes";
import {
  GetSwConfigReferenceData,
  GetSwConfigReferenceDataByKey,
  GetSwEmpConfigVariables,
} from "../../../queries/institution/configuration/query/SoftwreConfig";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import { UpdateSwEmpConfigVariables } from "../../../queries/institution/configuration/mutation";
import { Title } from "../../../stories/Title/Title";

import { Label } from "../../../stories/Label/Label";
import { AntSwitch } from "../../../pages/Switch";
import { Select } from "../../../stories/Select/Select";
import Input from "../../../stories/Input/Input";
import { Button } from "../../../stories/Button/Button";
import MessageModal from "../../../pages/MessageModal";
import LoadingModal from "../../../pages/LoadingModal";
import { AppContext } from "../../../context/context";

export interface OptionList {
  label: string;
  value: string;
}
interface Props {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  empRightsId: number;
}
const ChatConfiguration = ({ setModal, empRightsId }: Props) => {
  const { InstId } = useParams();
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [institutionWorkingtypeData, setInstitutionWorkingTypeData] = useState<
    OptionList[]
  >([]);
  const [pageSwDetails, setPageSwConfigList] = useState<GlobalPageConfigData[]>(
    []
  );

  const [GetInstitutionWorkingType] = useLazyQuery<
    SoftwareConfigList,
    SoftwareVars
  >(GetSwConfigReferenceDataByKey);
  const [GetConfigData, { data: ConfigReferenceData, loading: configLoading }] =
    useLazyQuery<SoftwareConfigTypeList, SoftwareConfigTypeVar>(
      GetSwConfigReferenceData,
      { variables: { token } }
    );
  const { user_details } = useLoggedInUserDetails();
  const [UpdateInstConfig, { loading: updationLoading }] = useMutation(
    UpdateSwEmpConfigVariables,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [GetSwModuleDetails, { loading }] = useLazyQuery<
    GetSwEmpConfigVariablesData,
    GetSwEmpConfigVariablesVars
  >(GetSwEmpConfigVariables);

  const instTypeData = (type: string) => {
    const typeData = ConfigReferenceData?.GetSwConfigReferenceData?.find(
      (data) => data.list_item_key === type
    );
    return typeData?.list_item_list
      .split(";")
      .map((d) => ({
        label: d.split(":")[0],
        value: d.split(":")[1],
      }))
      .filter(({ value }) => value !== undefined);
  };
  const handleUpdateSwConfig = () => {
    UpdateInstConfig({
      variables: {
        token,
        inst_id: InstId,
        pr_emp_id: empRightsId,
        input: pageSwDetails.map((pageSwDetail) => ({
          id: pageSwDetail.id,
          config_key: pageSwDetail.config_key,
          config_boolean_value: pageSwDetail.config_boolean_value,
          config_integer_value: pageSwDetail.config_integer_value,
          config_double_value: pageSwDetail.config_double_value,
          config_string_value: pageSwDetail.config_string_value,
          config_string_value_2: pageSwDetail.config_string_value_2,
        })),
        user_details,
      },
      refetchQueries: [
        {
          query: GetSwEmpConfigVariables,
          variables: {
            token,
            inst_id: InstId! || state.InstId,
            pr_emp_id: empRightsId,
            input: {
              query_type: "EMP_COMPLETE_CONFIG",
              str_value: "",
              int_value: 0,
            },
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Configuration Saved Successfully",
          operation: Operation.CREATE,
        });
      }
    });
  };

  const handleChangeValue = (
    key: string,
    type: string,
    switchValue?: boolean | null,
    selectedValue?: string | null
  ) => {
    if (type === "BOOL") {
      const newState =
        pageSwDetails &&
        // eslint-disable-next-line
        pageSwDetails.map((obj) =>
          obj.config_key === key
            ? { ...obj, config_boolean_value: switchValue! }
            : obj
        );
      setPageSwConfigList(newState);
    }

    if (type === "INT") {
      const newState = pageSwDetails.map((obj) =>
        obj.config_key === key
          ? { ...obj, config_integer_value: Number(selectedValue)! }
          : obj
      );
      setPageSwConfigList(newState);
    }
    if (type === "primary_list_key") {
      const config_key = key + "_" + selectedValue!;
      const newState = pageSwDetails.map((obj) =>
        obj.config_key === key
          ? {
              ...obj,
              config_secondary_list_key: config_key,
              config_string_value: selectedValue!,
            }
          : obj
      );
      setPageSwConfigList(newState);
      GetInstitutionWorkingType({
        variables: { config_key, token },
      }).then(({ data }) => {
        if (data?.GetSwConfigReferenceDataByKey) {
          setInstitutionWorkingTypeData(
            data.GetSwConfigReferenceDataByKey.list_item_list
              .split(";")
              ?.map((d) => ({
                label: d?.split(":")[0],
                value: d?.split(":")[1],
              }))
          );
        }
      });
    }

    if (type === "secondary_list_key") {
      const newState = pageSwDetails.map((obj) =>
        obj.config_key === key
          ? { ...obj, config_string_value_2: selectedValue! }
          : obj
      );
      setPageSwConfigList(newState);
    }

    if (type === "VARCHAR") {
      const newState = pageSwDetails.map((obj) =>
        obj.config_key === key
          ? { ...obj, config_string_value: selectedValue! }
          : obj
      );
      setPageSwConfigList(newState);
    }

    if (type === "VARCHAR2") {
      const newState = pageSwDetails.map((obj) =>
        obj.config_key === key
          ? { ...obj, config_string_value_2: selectedValue! }
          : obj
      );
      setPageSwConfigList(newState);
    }
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setModal?.(false);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  useEffect(() => {
    if (token && (InstId || state.InstId) && empRightsId) {
      GetSwModuleDetails({
        variables: {
          token,
          inst_id: InstId! || state.InstId,
          pr_emp_id: empRightsId,
          input: {
            query_type: "EMP_COMPLETE_CONFIG",
            str_value: "",
            int_value: 0,
          },
        },
      }).then(({ data }) => {
        if (data) {
          setPageSwConfigList(data.GetSwEmpConfigVariables);
        }
      });
    }
  }, [token, InstId, state.InstId, GetSwModuleDetails, empRightsId]);
  useEffect(() => {
    if (token) {
      GetConfigData();
    }
  }, [token, GetConfigData]);

  return (
    <>
      <div className={"edu-global-configuration__modal"}>
        <Title>Message-App Configuration</Title>
        <div className="edu-global-configuration__block">
          <div className="edu-global-configuration__block--details">
            {pageSwDetails
              .filter(
                (data) =>
                  data.config_key_used_for_module === EduateModule.MESSAGEAPP
              )
              .map((list, index) => (
                <div
                  className="edu-global-configuration__data--parameters"
                  key={index}>
                  <Label>
                    {index + 1} &nbsp;
                    {list.config_form_label_caption}
                  </Label>

                  {list.config_boolean_value &&
                  list.config_depends_on_primary_list_key &&
                  list.config_key_data_storage_type ===
                    "BOOLEAN+SELECT+VARCHAR" ? (
                    <>
                      <div>
                        <FormGroup>
                          <FormControlLabel
                            label=""
                            labelPlacement="start"
                            control={
                              <AntSwitch
                                checked={list.config_boolean_value}
                                onClick={() => {
                                  const newConfigValue =
                                    !list.config_boolean_value;
                                  handleChangeValue(
                                    list.config_key,
                                    "BOOL",
                                    newConfigValue,
                                    null
                                  );
                                }}
                              />
                            }
                          />
                        </FormGroup>
                      </div>
                      <div>
                        <div>
                          <Select
                            value={list.config_string_value}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              handleChangeValue(
                                list.config_key,
                                "primary_list_key",
                                null,
                                e.target.value
                              );
                            }}>
                            {instTypeData(list.config_primary_list_key)?.map(
                              ({ label, value }) => (
                                <React.Fragment key={value}>
                                  <option value={value}>{label}</option>
                                </React.Fragment>
                              )
                            )}
                          </Select>
                        </div>
                        <div>
                          <Select
                            value={list.config_string_value}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              handleChangeValue(
                                list.config_key,
                                "primary_list_key",
                                null,
                                e.target.value
                              );
                            }}>
                            {instTypeData(list.config_primary_list_key)?.map(
                              ({ label, value }) => (
                                <React.Fragment key={value}>
                                  <option value={value}>{label}</option>
                                </React.Fragment>
                              )
                            )}
                          </Select>
                        </div>
                        <div>
                          <Input
                            value={list.config_string_value_2}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              handleChangeValue(
                                list.config_key,
                                "VARCHAR2",
                                null,
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                    </>
                  ) : null}
                  {list.config_depends_on_primary_list_key &&
                  !list.config_depends_on_secondary_list_key &&
                  list.config_key_data_storage_type !== "BOOLEAN+SELECT" &&
                  list.config_key_data_storage_type !==
                    "BOOLEAN+SELECT+VARCHAR+ACCTLDGR" ? (
                    <div>
                      <Select
                        value={list.config_string_value}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleChangeValue(
                            list.config_key,
                            "primary_list_key",
                            null,
                            e.target.value
                          );
                        }}>
                        {instTypeData(list.config_primary_list_key)?.map(
                          ({ label, value }) => (
                            <React.Fragment key={value}>
                              <option value={value}>{label}</option>
                            </React.Fragment>
                          )
                        )}
                      </Select>
                    </div>
                  ) : null}

                  {list.config_depends_on_primary_list_key &&
                  list.config_depends_on_secondary_list_key &&
                  list.config_key_data_storage_type === "SELECT" ? (
                    <div>
                      <div>
                        <Select
                          value={list.config_string_value}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            handleChangeValue(
                              list.config_key,
                              "primary_list_key",
                              null,
                              e.target.value
                            );
                          }}>
                          {instTypeData(list.config_primary_list_key)?.map(
                            ({ label, value }) => (
                              <React.Fragment key={value}>
                                <option value={value}>{label}</option>
                              </React.Fragment>
                            )
                          )}
                        </Select>
                      </div>
                      <div>
                        <Label>Institution Working Type</Label>
                        <Select
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            handleChangeValue(
                              list.config_key,
                              "secondary_list_key",
                              null,
                              e.target.value
                            );
                          }}>
                          {institutionWorkingtypeData.map(
                            ({ label, value }) => (
                              <React.Fragment key={value}>
                                <option value={value}>{label}</option>
                              </React.Fragment>
                            )
                          )}
                        </Select>
                      </div>
                    </div>
                  ) : null}

                  {list.config_key_data_storage_type === "BOOLEAN" ? (
                    <FormGroup>
                      <FormControlLabel
                        label=""
                        labelPlacement="start"
                        control={
                          <AntSwitch
                            checked={list.config_boolean_value}
                            onClick={() => {
                              const newConfigValue = !list.config_boolean_value;
                              handleChangeValue(
                                list.config_key,
                                "BOOL",
                                newConfigValue,
                                null
                              );
                            }}
                          />
                        }
                      />
                    </FormGroup>
                  ) : null}
                  {list.config_key_data_storage_type === "BOOLEAN+VARCHAR" ? (
                    <FormGroup>
                      <FormControlLabel
                        label=""
                        labelPlacement="start"
                        control={
                          <AntSwitch
                            checked={list.config_boolean_value}
                            onClick={() => {
                              const newConfigValue = !list.config_boolean_value;
                              handleChangeValue(
                                list.config_key,
                                "BOOL",
                                newConfigValue,
                                null
                              );
                            }}
                          />
                        }
                      />
                      {list.config_boolean_value && (
                        <Input
                          placeholder=""
                          value={list.config_string_value}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChangeValue(
                              list.config_key,
                              "VARCHAR",
                              null,
                              e.target.value
                            )
                          }
                        />
                      )}
                    </FormGroup>
                  ) : null}
                  {list.config_key_data_storage_type === "BOOLEAN+SELECT" ? (
                    <FormGroup>
                      <FormControlLabel
                        label=""
                        labelPlacement="start"
                        control={
                          <AntSwitch
                            checked={list.config_boolean_value}
                            onClick={() => {
                              const newConfigValue = !list.config_boolean_value;
                              handleChangeValue(
                                list.config_key,
                                "BOOL",
                                newConfigValue,
                                null
                              );
                            }}
                          />
                        }
                      />
                      {list.config_depends_on_primary_list_key &&
                      !list.config_depends_on_secondary_list_key &&
                      list.config_boolean_value ? (
                        <div>
                          <Select
                            value={list.config_string_value}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              handleChangeValue(
                                list.config_key,
                                "primary_list_key",
                                null,
                                e.target.value
                              );
                            }}>
                            {/* {instTypeData(
                                          list.config_primary_list_key
                                        )?.map(({ label, value }) => (
                                          <React.Fragment key={value}>
                                            <option value={value}>
                                              {label}
                                            </option>
                                          </React.Fragment>
                                        ))} */}
                          </Select>
                        </div>
                      ) : null}
                    </FormGroup>
                  ) : null}
                  {list.config_depends_on_primary_list_key === false &&
                  list.config_key_data_storage_type === "VARCHAR" ? (
                    <Input
                      value={list.config_string_value}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeValue(
                          list.config_key,
                          "VARCHAR",
                          null,
                          e.target.value
                        )
                      }
                    />
                  ) : null}
                  {list.config_key_data_storage_type === "INT" ? (
                    <div className="label-grid">
                      <Input
                        type="number"
                        value={list.config_integer_value}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleChangeValue(
                            list.config_key,
                            "INT",
                            null,
                            e.target.value
                          )
                        }
                      />
                    </div>
                  ) : null}
                </div>
              ))}
          </div>
        </div>
        <Button mode="save" onClick={handleUpdateSwConfig} />

        <Button mode="cancel" onClick={() => setModal(false)} />
      </div>
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <LoadingModal flag={updationLoading || configLoading || loading} />
    </>
  );
};

export default ChatConfiguration;
