import React, { useContext, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Pointer from "../../../../../images/Pointer.svg";
import Input from "../../../../../stories/Input/Input";
import { EMPTY_STRING } from "../../../../../utils/constants";
import { ExcelAdmQueryType } from "../useExportMutation";
import { ExportProps } from "../Index";
import { useNavigate, useParams } from "react-router-dom";
import {
  Direction,
  ExcelReportPageType,
  ExportModuleType,
  SortBy,
} from "../../../../../utils/Enum.types";
import {
  LabeledAutocomplete,
  labelClasses,
} from "../../../../../styles/AutocompleteListStyles";
import {
  payloadType,
  VoucherOptionsType,
  VoucherType,
} from "../GlobalStates/types";
import { GetAcctLdgrs } from "../../../../Accounts/queries/FeeLedgers/query/Byid";
import { useLazyQuery } from "@apollo/client";
import {
  FeeLedgerData,
  FeeLedgerVars,
} from "../../../../Accounts/hooks/useAcctLedgerData";
import useToken from "../../../../../customhooks/useToken";
import { StatesContext } from "../GlobalStates/StatesProvider";
import { AcctLedgerQueryType } from "../../../../Accounts/common/QueryTypes";
import { responseType } from "../../../../../utils/Form.types";
import { VoucherQueryTypes } from "../../../../Accounts/common/Enum.types";

const ReportsList = ({ reportType, pageType }: ExportProps) => {
  const [searchText, setSearchText] = useState("");
  const { InstId, ledgerId } = useParams();

  const [acctLedgerOptions, setAcctLedgerOptions] = useState<responseType[]>(
    []
  );

  const VoucherTypeOptions = [
    {
      label: VoucherType.BANK,
      value: AcctLedgerQueryType.ACCT_LDGRS_BANK,
    },
    {
      label: VoucherType.CASH,
      value: AcctLedgerQueryType.ACCT_LDGRS_CASH,
    },
    {
      label: VoucherType.GENERAL,
      value: AcctLedgerQueryType.EXCEPT_CASH_AND_BANK,
    },
    {
      label: VoucherType.FEELEDGER,
      value: AcctLedgerQueryType.STD_FEE_ACCT_LDGRS_FOR_REPORT,
    },
  ];
  const { state, dispatch } = useContext(StatesContext);

  const { token } = useToken();

  const [GetAccountLedgers, { data }] = useLazyQuery<
    FeeLedgerData,
    FeeLedgerVars
  >(GetAcctLdgrs);

  const navigate = useNavigate();
  const ReportList = [
    {
      label: "Student List",
      moduleType: ExportModuleType.ADMISSIONS,
      value: ExcelAdmQueryType.ADM_STUDENT_LIST,
      navigate: `/${InstId}/admissions/reports/instlevel/student/adm_list`,
    },
    {
      label: "Religion Wise Student List",
      moduleType: ExportModuleType.ADMISSIONS,
      value: ExcelAdmQueryType.RELIGION_STUDENT_LIST,
      navigate: `/${InstId}/admissions/reports/instlevel/student/religion`,
    },
    {
      label: "Age Student List",
      moduleType: ExportModuleType.ADMISSIONS,
      value: ExcelAdmQueryType.AGE_STUDENT_LIST,
      navigate: `/${InstId}/admissions/reports/instlevel/student/age`,
    },
    {
      label: "Caste Wise List",
      moduleType: ExportModuleType.ADMISSIONS,
      value: ExcelAdmQueryType.CASTE_STUDENT_LIST,
      navigate: `/${InstId}/admissions/reports/instlevel/student/caste`,
    },

    {
      label: "SC / ST List",
      moduleType: ExportModuleType.ADMISSIONS,
      value: ExcelAdmQueryType.SC_ST_STUDENT_LIST,
      navigate: `/${InstId}/admissions/reports/instlevel/student/sc_st`,
    },

    //Accounts
    {
      label: "Fee Balance Report",
      moduleType: ExportModuleType.ACCOUNTS,
      value: VoucherQueryTypes.ACCT_STD_FEE_BALANCE_REPORT,
      navigate: `/${InstId}/accounts/reports/fee/balance`,
    },
    {
      label: "Fee Completely Paid Report",
      moduleType: ExportModuleType.ACCOUNTS,
      value: VoucherQueryTypes.ACCT_STD_FEE_COMPLETELY_PAID_REPORT,
      navigate: `/${InstId}/accounts/reports/fee/completely_paid`,
    },
    {
      label: "Fee Partially Paid Report",
      moduleType: ExportModuleType.ACCOUNTS,
      value: VoucherQueryTypes.ACCT_STD_FEE_PARTIALLY_PAID_REPORT,
      navigate: `/${InstId}/accounts/reports/fee/partially_paid`,
    },
    {
      label: "Fee Not Paid Report",
      moduleType: ExportModuleType.ACCOUNTS,
      value: VoucherQueryTypes.ACCT_STD_FEE_NOT_PAID_REPORT,
      navigate: `/${InstId}/accounts/reports/fee/not_paid`,
    },
    {
      label: "Fee by Agent Report",
      moduleType: ExportModuleType.ACCOUNTS,
      value: VoucherQueryTypes.ACCT_STD_FEE_BY_AGENT_REPORT,
      navigate: `/${InstId}/accounts/reports/fee/by_agent`,
    },
    {
      label: "Deposit Report",
      moduleType: ExportModuleType.ACCOUNTS,
      value: VoucherQueryTypes.ACCT_STD_DEPOSIT_REPORT,
      navigate: `/${InstId}/accounts/reports/deposit`,
    },
    {
      label: "Deposit Balance Report",
      moduleType: ExportModuleType.ACCOUNTS,
      value: VoucherQueryTypes.ACCT_STD_DEPOSIT_BALANCE_REPORT,
      navigate: `/${InstId}/accounts/reports/deposit/balance`,
    },
    {
      label: "Refund Report",
      moduleType: ExportModuleType.ACCOUNTS,
      value: VoucherQueryTypes.ACCT_STD_REFUND_REPORT,
      navigate: `/${InstId}/accounts/reports/refund`,
    },
    {
      label: "Concession Report",
      moduleType: ExportModuleType.ACCOUNTS,
      value: VoucherQueryTypes.ACCT_STD_CONCESSION_REPORT,
      navigate: `/${InstId}/accounts/reports/concession`,
    },
    {
      label: "Year of Joining Report",
      moduleType: ExportModuleType.ACCOUNTS,
      value: VoucherQueryTypes.ACCT_STD_BY_JOINING_YR,
      navigate: `/${InstId}/accounts/reports/yearOfJoining`,
    },
    {
      label: "Year of Joining Ledger Wise Report",
      moduleType: ExportModuleType.ACCOUNTS,
      value: VoucherQueryTypes.ACCT_STD_BY_JOINING_YR_LEDGER_WISE_REPORT,
      navigate: `/${InstId}/accounts/reports/yearOfJoiningLedgerWise`,
    },
    {
      label: "Fee Balance Report By Given Date",
      moduleType: ExportModuleType.ACCOUNTS,
      value: VoucherQueryTypes.ACCT_STD_FEE_BALANCE_REPORT_ON_GIVEN_DATE,
      navigate: `/${InstId}/accounts/reports/feeBalanceBydate`,
    },
    {
      label: "Ledger Wise Report",
      moduleType: ExportModuleType.ACCOUNTS,
      value: VoucherQueryTypes.ACCT_STD_FEE_LEDGER_WISE_REPORT,
      navigate: `/${InstId}/accounts/reports/stdLedgerWise`,
    },
    {
      label: "Ledger Wise Balance Report",
      moduleType: ExportModuleType.ACCOUNTS,
      value: VoucherQueryTypes.ACCT_STD_FEE_ONLY_BALANCE_DEMANDS,
      navigate: `/${InstId}/accounts/reports/stdLedgerWiseBalance`,
    },
    {
      label: "Today's Ledger Wise Fee Due",
      moduleType: ExportModuleType.ACCOUNTS,
      value: VoucherQueryTypes.ACCT_STD_FEE_DUE_BALANCE_ON_GIVEN_DATE,
      navigate: `/${InstId}/accounts/reports/todaysLedgerWiseDue`,
    },
  ];

  const moduleWiseFilter = ReportList.filter((rep) =>
    window.location.pathname.includes(rep.moduleType)
  );

  useEffect(() => {
    dispatch({
      type: payloadType.VOUCHER_TYPE,
      payload: {
        voucherType: null,
      },
    });
  }, []);

  useEffect(() => {
    if (token && state.voucherType) {
      GetAccountLedgers({
        variables: {
          token,
          id: null,
          after: null,
          input: {
            acct_ldgr_query_type: state.voucherType.value,
            ids: [Number(InstId)],
          },
          orderBy: [{ direction: Direction.DESC, field: SortBy.LDGR_DESC }],
          first: null,
          name: EMPTY_STRING,
        },
      }).then(({ data, error, variables }) => {
        if (data && data.GetAcctLdgrs) {
          const responseType = data.GetAcctLdgrs.edges.map((edge) => ({
            label: edge.node.ldgr_desc,
            value: edge.node.id,
          }));

          setAcctLedgerOptions(responseType);
        }
        if (error) {
          return error.clientErrors;
        }
      });
    }
  }, [GetAccountLedgers, token, InstId, data, state.voucherType]);

  return (
    <>
      {pageType === ExcelReportPageType.VOUCHER && (
        <LabeledAutocomplete
          className={labelClasses.inputRoot}
          options={VoucherTypeOptions}
          openOnFocus
          forcePopupIcon
          value={state.voucherType}
          onChange={(e, newValue) => {
            if (newValue) {
              dispatch({
                type: payloadType.VOUCHER_TYPE,
                payload: {
                  voucherType: newValue as VoucherOptionsType,
                },
              });
            } else {
              dispatch({
                type: payloadType.VOUCHER_TYPE,
                payload: {
                  voucherType: null,
                },
              });
            }
            navigate(`/${InstId}/accounts/reports/fee/voucher/0`);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              autoFocus
              className={labelClasses.formControlRoot}
              label="Select Report Types"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
            />
          )}
        />
      )}
      <TableContainer
        className={
          pageType === ExcelReportPageType.VOUCHER
            ? "inst-level-report__lt--table--voucher"
            : "inst-level-report__lt--table"
        }>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Report Types</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className="fee-receipt-book__typesblock--table--input">
                <Input
                  size="small"
                  id="search"
                  placeholder="Search..."
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </TableCell>
            </TableRow>

            {pageType === ExcelReportPageType.VOUCHER ? (
              <>
                {acctLedgerOptions
                  .filter((data) => {
                    const searchTerm = searchText.trim().toLowerCase();
                    const labelValue = data.label.toLowerCase();
                    const valueString = String(data.value).toLowerCase();

                    const searchWords = searchTerm.split(" ");

                    return searchWords.every(
                      (word) =>
                        labelValue.includes(word) || valueString.includes(word)
                    );
                  })
                  .map((data) => (
                    <TableRow
                      key={data.value}
                      className={
                        Number(ledgerId) === data.value
                          ? "selected-row"
                          : EMPTY_STRING
                      }
                      selected={Number(ledgerId) === data.value}>
                      <TableCell
                        onClick={() => {
                          navigate(
                            `/${InstId}/accounts/reports/fee/voucher/${data.value}`
                          );
                        }}
                        className="bank-book__table--ledger">
                        {data.label}
                      </TableCell>
                    </TableRow>
                  ))}
              </>
            ) : (
              <>
                {moduleWiseFilter
                  .filter((item) => {
                    const searchTerm = searchText.trim().toLowerCase();
                    const itemValue = item.value.toLowerCase();

                    const searchWords = searchTerm.split(" ");

                    return searchWords.every((word) =>
                      itemValue.includes(word)
                    );
                  })
                  .map((data) => (
                    <TableRow
                      key={data.value}
                      className={
                        reportType === data.value
                          ? "selected-row"
                          : EMPTY_STRING
                      }
                      selected={reportType === data.value}>
                      <TableCell
                        onClick={() => {
                          navigate(data.navigate);
                        }}
                        className="bank-book__table--ledger">
                        {reportType === data.value && (
                          <img src={Pointer} alt="/" className="pointer-icon" />
                        )}
                        {data.label}
                      </TableCell>
                    </TableRow>
                  ))}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ReportsList;
